import React from 'react';
import { Popup as LeafletPopup } from 'react-leaflet';

import { Box, Stack } from 'styled';

interface NotamPopupProps {
  className?: string;
  code: string;
  number?: string;
  description: string;
  translation: string;
}

const NotamPopup: React.FC<NotamPopupProps> = ({
  className,
  code,
  number,
  description,
  translation,
}: NotamPopupProps) => {
  if (process.env.REACT_APP_CONFIG_NOTAMS !== 'true') return null;
  const _number = !number ? '' : ` ${number}`;

  return (
    <LeafletPopup className={className}>
      <Stack direction="y" yspace="xs">
        <Box>
          <strong>POI: </strong>
          {code}
        </Box>
        <Box>
          <strong>NOTAM{_number}: </strong>
          {description}
        </Box>
        <Box>
          <strong>Translation: </strong>
          {translation}
        </Box>
      </Stack>
    </LeafletPopup>
  );
};

export default NotamPopup;
