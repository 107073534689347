import formatDistance from 'date-fns/formatDistance';
import React, { useEffect } from 'react';

import { Alert } from 'data/Alert/types';

import { useLocalStorage } from 'hooks';

import { updateTimestamp, useAlerts } from 'providers/AlertsProvider';
import { hideModal, showModal, useModalDispatch } from 'providers/ModalProvider';

import { AlertIcon, BlankSlate, Icon, LinkButton, Modal, RequiredPermission } from 'components';

import { Box, Card, TextLabel } from 'styled';

interface AlertListItemProps {
  alert: Alert;
  past?: boolean;
}

const AlertsModal: React.FC = props => {
  const modalDispatch = useModalDispatch();
  const [{ alerts, pastAlerts }, dispatch] = useAlerts();
  const filteredAlerts = alerts?.filter(alert => alert?.description && alert?.category) || [];
  const filteredPastAlerts =
    pastAlerts?.filter(alert => alert?.description && alert?.category) || [];

  useEffect(() => {
    return () => dispatch(updateTimestamp());
  }, [dispatch]);

  const AlertListItem: React.FC<AlertListItemProps> = ({ alert, past }: AlertListItemProps) => {
    const { setValue: setAlertIssue } = useLocalStorage('alertIssue');
    const { status, category, updatedAt, description } = alert;
    const statusColor = status?.toLowerCase() === 'warning' ? `warning` : 'error';
    const color = past ? 'neutralsGradient.5' : statusColor;

    const handleAddIssue = () => {
      // Save alert to use on new issue modal
      setAlertIssue(alert);

      // Close the alerts modal
      modalDispatch(hideModal());

      // Open new issue modal
      setTimeout(() => {
        modalDispatch(showModal('NewIssue'));
      }, 500);
    };

    return (
      <Card borderColor={color} mb="s">
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Box mr="base">
            {category && <AlertIcon name={category} color={color} description={description} />}
          </Box>
          <Box>
            {description && (
              <Box as="p" mr="xl" dangerouslySetInnerHTML={{ __html: description }}></Box>
            )}
            <TextLabel color="subdued">
              {updatedAt && formatDistance(updatedAt, new Date(), { addSuffix: true })}
            </TextLabel>
            {!past && (
              <RequiredPermission permission="feature:issues">
                <RequiredPermission permission="create:issues">
                  <Box position="absolute" right="xs" top="15%" onClick={handleAddIssue}>
                    <LinkButton>
                      <Icon name="plusCircle" size="28" color={color} />
                    </LinkButton>
                  </Box>
                </RequiredPermission>
              </RequiredPermission>
            )}
          </Box>
        </Box>
      </Card>
    );
  };

  return (
    <Modal title="Alerts" icon={{ name: 'bell', color: 'fg' }} {...props}>
      {filteredAlerts?.length || filteredPastAlerts?.length ? (
        <>
          {filteredAlerts?.map((alert, idx) => (
            <AlertListItem key={idx} alert={alert} />
          ))}
          {filteredPastAlerts?.length > 0 && (
            <TextLabel as="h2" color="subdued" mt="l">
              Past Alerts
            </TextLabel>
          )}
          {filteredPastAlerts?.map((alert, idx) => (
            <AlertListItem key={idx} alert={alert} past={true} />
          ))}
        </>
      ) : (
        <BlankSlate title="No alerts" mb="l">
          There are no active alerts.
        </BlankSlate>
      )}
    </Modal>
  );
};

export default AlertsModal;
