import { addHours } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

function getAirportTime(time: Date): Date {
  const timeZone =
    process.env.REACT_APP_AIRPORT_TIMEZONE || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const offset = getTimezoneOffset(timeZone, time);
  const offsetHours = offset / 60 / 60 / 1000;
  return addHours(time, offsetHours);
}

export default getAirportTime;
