import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const VerticalContent = styled.div<SystemProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;

  ${system}
`;

export default VerticalContent;
