import rem from 'utils/rem';

const ERROR_COLOR = '#fe5f55';
const PRIMARY_BRAND_COLOR = '#62D654';
const SECONDARY_BRAND_COLOR = '#0b5fb1';
const SUCCESS_COLOR = PRIMARY_BRAND_COLOR;
const WARNING_COLOR = '#ffc527';

interface NeutralsGradient {
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
  9: string;
  10: string;
  11: string;
  12: string;
  13: string;
  14: string;
  15: string;
  16: string;
  17: string;
}

export interface Colors {
  bg: string;
  bgDark: string;
  bgGradient: string;
  border: string;
  borderLight: string;
  borderSolid: string;
  error: string;
  fg: string;
  greenLight: string;
  neutralsGradient: NeutralsGradient;
  notamsOverlay: string;
  primary: string;
  primaryGradient: string;
  red: string;
  secondary: string;
  subdued: string;
  subduedGradient: string;
  subduedLight: string;
  success: string;
  trafficOverlay: string;
  warning: string;
  weatherOverlay: string;
  inputs: string;
  chart: {
    green: string;
    yellow: string;
    red: string;
    blue: string;
    gray: string;
    orange: string;
    pink: string;
    purple: string;
    teal: string;
  };
  airlines: {
    Alaska: string;
    Allegiant: string;
    American: string;
    Delta: string;
    Frontier: string;
    JetBlue: string;
    'New Pacific': string;
    Southwest: string;
    Spirit: string;
    'Sun Country': string;
    United: string;
    Volaris: string;
  };
}

export interface Theme {
  breakpoints: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  colors: Colors;
  fontFamily: string;
  fontSizes: {
    base: string;
    xxs: string;
    xs: string;
    s: string;
    l: string;
    xl: string;
    h1: string;
    h2: string;
    h3: string;
    h4: string;
  };
  fontWeights: {
    light: string;
    normal: string;
    bold: string;
  };
  lineHeights: {
    base: string;
  };
  radii: {
    base: string;
    l: string;
  };
  shadows: {
    base: string;
    l: string;
    tile: string;
  };
  space: {
    base: string;
    xs: string;
    s: string;
    m: string;
    l: string;
    xl: string;
  };
  zIndices: {
    baseOverlay: number;
    menu: number;
    tooltip: number;
    modal: number;
    modalOverlay: number;
    toast: number;
    leaflet: number;
  };
}

const theme: Theme = {
  breakpoints: {
    sm: rem('480px'),
    md: rem('1024px'),
    lg: rem('1415px'),
    xl: rem('1800px'),
  },
  colors: {
    primary: PRIMARY_BRAND_COLOR,
    primaryGradient: `linear-gradient(to bottom, #9ada66, ${PRIMARY_BRAND_COLOR})`,
    secondary: SECONDARY_BRAND_COLOR,
    error: ERROR_COLOR,
    success: SUCCESS_COLOR,
    warning: WARNING_COLOR,
    bg: '#17181F',
    bgDark: '#1b1b1b',
    bgGradient: `linear-gradient(to bottom, #222, #1b1b1b)`,
    fg: '#fff',
    border: 'rgba(255, 255, 255, 0.1)',
    borderSolid: '#2A2B38',
    borderLight: 'rgba(0, 0, 0, 0.1)',
    greenLight: '#74E567',
    red: '#F52D18',
    subdued: 'rgba(255, 255, 255, 0.5)',
    subduedLight: 'rgba(0, 0, 0, 0.5)',
    subduedGradient: `linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.5))`,
    notamsOverlay: '#E5E9FF',
    trafficOverlay: 'rgba(153, 153, 255, 0.5)',
    weatherOverlay: 'rgba(0, 8, 54, 1)',
    inputs: 'rgb(91, 164, 97)',
    neutralsGradient: {
      1: '#FFFFFF',
      2: '#F0F1F7',
      3: '#DFDFE6',
      4: '#C2C3CC',
      5: '#A3A4AB',
      6: '#8A8B91',
      7: '#707178',
      8: '#55565E',
      9: '#373944',
      10: '#2A2B38',
      11: '#17181F',
      12: '#08080A',
      13: 'rgba(255, 255, 255, 0.8)',
      14: 'rgba(255, 255, 255, 0.6)',
      15: 'rgba(255, 255, 255, 0.2)',
      16: 'rgba(255, 255, 255, 0.1)',
      17: 'rgba(42, 43, 56, 0.3)',
    },
    chart: {
      green: SUCCESS_COLOR,
      yellow: '#c1bc43',
      red: '#c14361',
      blue: '#4393c1',
      gray: '#cdcdcd',
      orange: '#c17143',
      pink: '#c143ac',
      purple: '#8a43c1',
      teal: '#43c1a4',
    },
    airlines: {
      Alaska: '#00385f',
      Allegiant: '#FF9800',
      American: '#CC3428',
      Delta: '#98002E',
      Frontier: '#2A6446',
      JetBlue: '#4C90CD',
      'New Pacific': '#88181A',
      Southwest: '#3A52A3',
      Spirit: '#FFED00',
      'Sun Country': '#f46c0b',
      United: '#039',
      Volaris: '#a12885',
    },
  },
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  fontSizes: {
    base: rem('14px'),
    xxs: rem('8px'),
    xs: rem('10px'),
    s: rem('12px'),
    l: rem('16px'),
    xl: rem('24px'),
    h1: rem('28px'),
    h2: rem('22px'),
    h3: rem('20px'),
    h4: rem('18px'),
  },
  fontWeights: {
    light: '300',
    normal: '400',
    bold: '700',
  },
  lineHeights: {
    base: '1.5',
  },
  radii: {
    base: rem('4px'),
    l: rem('10px'),
  },
  shadows: {
    base: '0 2px 3px rgba(0, 0, 0, 0.15)',
    l: '0 6px 12px -2px rgba(0, 0, 0, 0.25), 0 3px 7px -3px rgba(0, 0, 0, 0.3)',
    tile: '0px 2px 6px 1px rgba(0, 0, 0, 0.1), 0px 2px 4px 1px rgba(0, 0, 0, 0.15)',
  },
  space: {
    base: rem('16px'),
    xs: rem('5px'),
    s: rem('10px'),
    m: rem('14px'),
    l: rem('32px'),
    xl: rem('60px'),
  },
  zIndices: {
    toast: 500,
    leaflet: 400,
    menu: 50,
    tooltip: 40,
    modal: 30,
    modalOverlay: 20,
    baseOverlay: 10,
  },
};

export default theme;
