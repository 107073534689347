import { SeriesOptionsType } from 'highcharts';

import { ChartDatum } from 'statistics/types';

import { getXAxis, getYAxis, getYAxisRate } from './axes';
import { default as getOptions } from './options';
import { default as getSeries } from './series';

export default {
  bBags: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'B-Bags', chartOptions),
  },
  tDrive: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'T-Drive', chartOptions),
  },
  total: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Total', chartOptions),
  },
  bagsReceived: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Bags Received', chartOptions),
  },
  bagsCleared: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Bags Cleared', chartOptions),
  },
  readRateML1: {
    getXAxis,
    getYAxis: getYAxisRate,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'ML1 Read Rate %', chartOptions),
  },
  readRateML2: {
    getXAxis,
    getYAxis: getYAxisRate,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'ML2 Read Rate %', chartOptions),
  },
  readRateCB1: {
    getXAxis,
    getYAxis: getYAxisRate,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'CB1 Read Rate %', chartOptions),
  },
};
