import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Select = styled.select<SystemProps>`
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'%3E%3C/path%3E%3C/svg%3E");
  background-position: right ${themeGet('space.s')} top 50%;
  background-repeat: no-repeat;
  border: 1px solid ${themeGet('colors.border')};
  border-radius: ${themeGet('radii.base')};
  box-sizing: border-box;
  color: ${themeGet('colors.fg')};
  display: block;
  font-family: ${themeGet('fontFamily')};
  font-size: ${themeGet('fontSizes.s')};
  max-width: 100%;
  padding: ${themeGet('space.s')};
  width: 100%;

  &:focus {
    background-color: ${themeGet('colors.neutralsGradient.10')};
    border-color: ${themeGet('colors.primary')};
    outline: none;
  }

  &[disabled] {
    opacity: 0.6;
  }

  ${system}
`;

export default Select;
