import { omit } from 'lodash';
import React from 'react';

import { TILE_OPTIONS } from 'config/tiles';

import { useUpdateUserMetadata } from 'client/User';

import { hideModal, useModalDispatch } from 'providers/ModalProvider';
import { State as OptionsState, useTileOptionsState } from 'providers/TileOptionsProvider';
import { showError, showSuccess, useToastDispatch } from 'providers/ToastProvider';

import { Button, Modal } from 'components';
import { Props as ModalProps } from 'components/Modal';

import { Box } from 'styled';

interface Props extends ModalProps {
  optionKey: string;
  children?: any;
}

interface Filters {
  filters: any;
}

interface Options {
  [key: string]: keyof OptionsState | Filters;
}

const TileOptionsModal: React.FC<Props> = (props: Props) => {
  const tileOptions = useTileOptionsState();
  const toastDispatch = useToastDispatch();
  const modalDispatch = useModalDispatch();
  const [updateMetadata, { loading }] = useUpdateUserMetadata({
    onCompleted: () => {
      modalDispatch(hideModal());
      toastDispatch(showSuccess('Settings saved'));
    },
    onError: () => toastDispatch(showError('There was an error saving your settings')),
  });
  const Component = TILE_OPTIONS[props.optionKey];

  function handleSave(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();

    // Omit options we don't want saved to meta data
    const optionsToSave: Options = {};
    Object.keys(tileOptions).forEach(key => {
      optionsToSave[key] = {
        ...omit(tileOptions[key as keyof unknown], ['status', '__typename']),
        filters: {
          // @ts-expect-error
          ...omit(tileOptions[key].filters, ['__typename', 'needsUpdate', 'sortByColumn']),
        },
      };
    });

    updateMetadata({
      variables: {
        key: 'tileOptions',
        value: JSON.stringify(optionsToSave),
      },
    });
  }

  return (
    <Modal width="500px" {...props}>
      <Box my="l">{Component ? <Component /> : props.children}</Box>
      <Box alignItems="center" display="flex">
        <Button onClick={handleSave} loading={loading}>
          Save
        </Button>
        <Box ml="base">
          <Box as="p" color="subdued" fontSize="s">
            Changes are reflected when updating the fields above.
          </Box>
          <Box as="p" color="subdued" fontSize="s">
            Saving will store these settings to your user account.
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default TileOptionsModal;
