import theme from 'config/theme';

interface MarkerProps {
  code: string;
  number: string;
  description: string;
  color: keyof typeof theme.colors;
  translation: string;
}

export default class Marker {
  code: string;
  number: string;
  description: string;
  color: keyof typeof theme.colors;
  translation: string;

  constructor({ code, number, description, color, translation }: MarkerProps) {
    this.code = code;
    this.number = number;
    this.description = description;
    this.color = color;
    this.translation = translation;
  }
}
