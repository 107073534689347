import React from 'react';

import { Baggage } from 'client/Baggage/types';
import { Booking } from 'client/Bookings/types';
import { ArrivalFlight, DepartureFlight, CapacityDepartureFlight } from 'data/Flights/types';

import {
  ArrivalDetails,
  BaggageDetails,
  BookingDetails,
  CapacityDetails,
  CargoRevenueDetails,
  CommonUseBagTagDetails,
  CommonUseKioskDetails,
  CommonUseWorkstationDetails,
  DepartureDetails,
} from 'components/Details';
import { BagTagData, CommonUseKiosk, CommonUseWorkstation } from 'data/CommonUse/types';

export type CapacityDetailsData = {
  departures: CapacityDepartureFlight[];
  some: () => boolean;
};

export type ChartDetailsData =
  | ArrivalFlight[]
  | DepartureFlight[]
  | Booking[]
  | CapacityDetailsData
  | Baggage[]
  | CommonUseKiosk[]
  | CommonUseWorkstation[]
  | BagTagData[];

export interface DetailsComponentProps {
  data: ChartDetailsData;
}

interface Props {
  data: ChartDetailsData;
  component: string;
  some?: boolean;
}

const COMPONENTS: {
  [key: string]: React.FC<DetailsComponentProps>;
} = {
  ArrivalDetails: ({ data }: { data: ChartDetailsData }) => <ArrivalDetails data={data} />,
  BaggageDetails: ({ data }: { data: ChartDetailsData }) => <BaggageDetails data={data} />,
  BookingDetails: ({ data }: { data: ChartDetailsData }) => <BookingDetails data={data} />,
  CapacityDetails: ({ data }: { data: ChartDetailsData }) => <CapacityDetails data={data} />,
  CargoRevenueDetails: ({ data }: { data: ChartDetailsData }) => (
    <CargoRevenueDetails data={data} />
  ),
  CommonUseBagTagDetails: ({ data }: { data: ChartDetailsData }) => (
    <CommonUseBagTagDetails data={data} />
  ),
  CommonUseKioskDetails: ({ data }: { data: ChartDetailsData }) => (
    <CommonUseKioskDetails data={data} />
  ),
  CommonUseWorkstationDetails: ({ data }: { data: ChartDetailsData }) => (
    <CommonUseWorkstationDetails data={data} />
  ),
  DepartureDetails: ({ data }: { data: ChartDetailsData }) => <DepartureDetails data={data} />,
};

const ChartDetails: React.FC<Props> = ({ data, component }: Props) => {
  const Component = COMPONENTS[component];
  if (Component) return <Component data={data} />;
  return null;
};

export default ChartDetails;
