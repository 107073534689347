import React from 'react';

import { SortByOptions } from 'components';

const ThroughputOptions: React.FC = () => {
  return (
    <>
      <SortByOptions
        stateKey="throughput"
        options={[
          { value: 'airport', label: 'Airport' },
          { value: 'date', label: 'Date' },
          { value: 'totalYesterday', label: 'Total' },
        ]}
      />
    </>
  );
};

export default ThroughputOptions;
