import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Avatar = styled.img<SystemProps>`
  border-radius: 50%;
  display: block;

  ${system}
`;

export default Avatar;
