// @ts-nocheck
import { isPast } from 'date-fns';

import { COMPLETED } from 'data/Flights/constants';
import { ArrivalFlight } from 'data/Flights/types';

import CargoData from './CargoData';

class CargoArrivalsData extends CargoData {
  get completedFlights(): ArrivalFlight[] {
    return this.flights.filter(
      (flight: ArrivalFlight) =>
        flight?.statusText === COMPLETED || isPast(flight?.actualArrivalDate)
    );
  }
}

export default CargoArrivalsData;
