import styled, { css } from 'styled-components';

import system from './lib/system';
import { DownshiftButtonProps } from './lib/types';

export interface ResetButtonProps extends DownshiftButtonProps {
  hover?: string;
}

const ResetButton = styled.button<ResetButtonProps>`
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  ${props =>
    props.hover &&
    css`
      &:active,
      &:focus,
      &:hover {
        background-color: ${props.hover};
      }
    `};

  ${system}
`;

export default ResetButton;
