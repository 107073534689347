import React from 'react';

import { Box, Loader } from 'styled';
import { formatAirportTime } from 'utils';

interface Props {
  format?: Function;
  date: Date | number | string;
  loading?: boolean;
}

const UpdatedAt: React.FC<Props> = ({ date, format, loading }: Props) => {
  const defaults = {
    format: formatAirportTime(new Date(date), 'hh:mm a'),
  };

  return (
    <Box
      bg="rgba(0, 0, 0, 0.15)"
      color="subdued"
      fontSize="s"
      py="s"
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="38px"
    >
      {loading ? (
        <Loader color="borderSolid" />
      ) : (
        `Last updated: ${format ? format(date, formatAirportTime) : defaults.format}`
      )}
    </Box>
  );
};

export default UpdatedAt;
