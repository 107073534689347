import React from 'react';

import { SortByOptions } from 'components';

const AirfieldOptions: React.FC = () => {
  return (
    <>
      <SortByOptions
        stateKey="airfield"
        options={[
          { value: 'areaName', label: 'Area' },
          { value: 'description', label: 'Description' },
          { value: 'start', label: 'Start' },
          { value: 'end', label: 'End' },
          { value: 'statusName', label: 'Status' },
        ]}
      />
    </>
  );
};

export default AirfieldOptions;
