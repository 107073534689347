import sortBy from 'lodash/sortBy';
import React from 'react';

import { Notam } from 'tiles/Airfield/utils';

import { useNotams } from 'data/Notam';
import { NotamFilter } from 'data/Notam/types';

import { useRefreshedAt } from 'hooks';

import { SelectedTableItemProvider } from 'providers';

import { QueryCell, UpdatedAt } from 'components';

import { Box } from 'styled';

import { Map, NotamsTable, RefreshedAt } from './components';

interface Props {
  showTable?: boolean;
  showMap?: boolean;
  mapHeight?: string;
  filter: NotamFilter;
  tile?: boolean;
}

const Notams: React.FC<Props> = ({
  showTable,
  filter,
  showMap,
  mapHeight = '100%',
  tile = false,
}: Props) => {
  const { loading, error, notams } = useNotams({ filter });
  const { refreshedAt } = useRefreshedAt(loading);
  if (process.env.REACT_APP_CONFIG_NOTAMS !== 'true') return null;

  // Order by Runway > Taxiway > Taxilane
  // Then order by date/time
  const parsedNotams = sortBy(notams, [
    (o): number => {
      if (o.description.match(/RWY/i)) return 0;
      if (o.description.match(/TWY/i)) return 1;
      if (o.description.match(/TXL/i)) return 2;
      return 3;
    },
    'startTime',
  ]).map(data => new Notam(data));

  function render(): JSX.Element {
    if (showMap && !showTable) {
      return (
        <>
          <Map mapHeight={mapHeight} parsedNotams={parsedNotams} />
          <RefreshedAt refreshedAt={refreshedAt} />
        </>
      );
    }

    if (!showMap && showTable) {
      return (
        <>
          <NotamsTable notams={parsedNotams} tile={tile} mapEnabled={showMap && !tile} />
          <UpdatedAt date={refreshedAt} />
        </>
      );
    }
    return (
      <SelectedTableItemProvider>
        <Box
          display={{ md: 'grid' }}
          gridTemplateColumns={{ md: '0.75fr 1.25fr', lg: '0.75fr 1.25fr' }}
        >
          <Box position="relative">
            <Map
              mapHeight={{ _: mapHeight, md: '100%' }}
              mapMinHeight="750px"
              parsedNotams={parsedNotams}
            />
            <UpdatedAt date={refreshedAt} />{' '}
          </Box>
          <NotamsTable notams={parsedNotams} mapEnabled={true} />
        </Box>
      </SelectedTableItemProvider>
    );
  }

  return (
    <QueryCell content="NOTAMS" loading={loading} error={error} data={notams}>
      {render()}
    </QueryCell>
  );
};

export default Notams;
