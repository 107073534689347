// Using Feather icons (https://feathericons.com/) for now.
// Download the "20px" size with a "2px" stroke.
// Grab the HTML within the <svg> element and dump it in a key here.
// Name it after what it is.

const icons: { [key: string]: string } = {
  activity: `<path d="M22 12h-4l-3 9L9 3l-3 9H2"/>`,
  alertOctagon: `<polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line>`,
  alertTriangle: `<path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line>`,
  alignJustify: `<line x1="21" y1="10" x2="3" y2="10"/><line x1="21" y1="6" x2="3" y2="6"/><line x1="21" y1="14" x2="3" y2="14"/><line x1="21" y1="18" x2="3" y2="18"/>`,
  arrowDown: `<circle cx="12" cy="12" r="10"></circle><polyline points="8 12 12 16 16 12"></polyline><line x1="12" y1="8" x2="12" y2="16"></line>`,
  arrowDownAndLeft: `<path d="M17 7L7 17M17 17H7V7"/>`,
  arrowDownAndRight: `<line x1="7" y1="7" x2="17" y2="17"></line><polyline points="17 7 17 17 7 17"></polyline>`,
  arrowDownNoCircle: `<path d="M12 5v14M19 12l-7 7-7-7"/>`,
  arrowLeft: `<line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline>`,
  arrowRight: `<path d="M5 12h14M12 5l7 7-7 7"/>`,
  arrowUpAndLeft: `<line x1="17" y1="17" x2="7" y2="7"></line><polyline points="7 17 7 7 17 7"></polyline>`,
  arrowUpAndRight: `<path d="M7 17L17 7M7 7h10v10"/>`,
  arrowUpNoCircle: `<path d="M12 19V5M5 12l7-7 7 7"/>`,
  barChart: `<path d="M18 20V10M12 20V4M6 20v-6"/>`,
  bell: `<path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 01-3.46 0"/>`,
  book: `<path d="M4 19.5A2.5 2.5 0 016.5 17H20"/><path d="M6.5 2H20v20H6.5A2.5 2.5 0 014 19.5v-15A2.5 2.5 0 016.5 2z"/>`,
  briefcase: `<rect x="2" y="7" width="20" height="14" rx="2" ry="2"/><path d="M16 21V5a2 2 0 00-2-2h-4a2 2 0 00-2 2v16"/>`,
  calendar: `<rect x="3" y="4" width="18" height="18" rx="2" ry="2"/><path d="M16 2v4M8 2v4M3 10h18"/>`,
  check: `<path d="M20 6L9 17l-5-5"/>`,
  checkCircle: `<path d="M22 11.08V12a10 10 0 11-5.93-9.14"/><path d="M22 4L12 14.01l-3-3"/>`,
  chevronDown: `<path d="M6 9l6 6 6-6"/>`,
  chevronLeft: `<path d="M15 18l-6-6 6-6"/>`,
  chevronRight: `<path d="M9 18l6-6-6-6"/>`,
  chevronUp: `<path d="M18 15l-6-6-6 6"/>`,
  circle: `<circle cx="12" cy="12" r="10"/>`,
  clipboard: `<path d="M16 4h2a2 2 0 012 2v14a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2h2"/><rect x="8" y="2" width="8" height="4" rx="1" ry="1"/>`,
  clock: `<circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline>`,
  cloud: `<path d="M18 10h-1.26A8 8 0 109 20h9a5 5 0 000-10z"/>`,
  compass: `<circle cx="12" cy="12" r="10"/><path d="M16.24 7.76l-2.12 6.36-6.36 2.12 2.12-6.36 6.36-2.12z"/>`,
  corneredArrowUpLeft: `<path d="M9 14L4 9l5-5"/><path d="M20 20v-7a4 4 0 00-4-4H4"/>`,
  creditCard: `<rect x="1" y="4" width="22" height="16" rx="2" ry="2"/><path d="M1 10h22"/>`,
  dollarSign: `<line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>`,
  drizzle: `<line x1="8" y1="19" x2="8" y2="21"></line><line x1="8" y1="13" x2="8" y2="15"></line><line x1="16" y1="19" x2="16" y2="21"></line><line x1="16" y1="13" x2="16" y2="15"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="12" y1="15" x2="12" y2="17"></line><path d="M20 16.58A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25"></path>`,
  edit: `<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>`,
  ellipsis: `<circle cx="12" cy="12" r="1"/><circle cx="19" cy="12" r="1"/><circle cx="5" cy="12" r="1"/>`,
  eye: `<path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/>`,
  female: `<path d="M5.00002 2.35288C4.58887 2.35288 4.25557 2.68618 4.25557 3.09733C4.25557 3.50848 4.58887 3.84178 5.00002 3.84178C5.41117 3.84178 5.74447 3.50848 5.74447 3.09733C5.74447 2.68618 5.41117 2.35288 5.00002 2.35288ZM2.76667 3.09733C2.76667 1.86389 3.76658 0.863983 5.00002 0.863983C6.23346 0.863983 7.23337 1.86389 7.23337 3.09733C7.23337 4.33077 6.23346 5.33068 5.00002 5.33068C3.76658 5.33068 2.76667 4.33077 2.76667 3.09733ZM5.6843 7.41712C5.42648 6.81552 4.57362 6.81553 4.31579 7.41712L2.30044 12.1196H3.56432C3.97547 12.1196 4.30877 12.4529 4.30877 12.8641V15.6859C4.30877 16.097 3.97547 16.4303 3.56432 16.4303C3.15318 16.4303 2.81988 16.097 2.81988 15.6859V13.6085H1.17145C0.921212 13.6085 0.687712 13.4828 0.549957 13.2739C0.412201 13.065 0.388622 12.8008 0.487197 12.5708L2.94728 6.83061C3.72076 5.02583 6.27934 5.02584 7.05282 6.83062L9.51289 12.5708C9.61147 12.8008 9.58789 13.065 9.45014 13.2739C9.31238 13.4828 9.07888 13.6085 8.82864 13.6085H7.18022V15.6859C7.18022 16.097 6.84692 16.4303 6.43577 16.4303C6.02462 16.4303 5.69132 16.097 5.69132 15.6859V12.8641C5.69132 12.4529 6.02462 12.1196 6.43577 12.1196H7.69965L5.6843 7.41712Z"/>`,
  filter: `<path d="M22 3H2l8 9.46V19l4 2v-8.54L22 3z"/>`,
  feedStatus: `<ellipse cx="12" cy="5" rx="9" ry="3"/><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"/><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"/>`,
  frown: `<circle cx="12" cy="12" r="10"/><path d="M16 16s-1.5-2-4-2-4 2-4 2"/><line x1="9" y1="9" x2="9.01" y2="9"/><line x1="15" y1="9" x2="15.01" y2="9"/>`,
  gear: `<circle cx="12" cy="12" r="3"/><path d="M19.4 15a1.65 1.65 0 00.33 1.82l.06.06a2 2 0 010 2.83 2 2 0 01-2.83 0l-.06-.06a1.65 1.65 0 00-1.82-.33 1.65 1.65 0 00-1 1.51V21a2 2 0 01-2 2 2 2 0 01-2-2v-.09A1.65 1.65 0 009 19.4a1.65 1.65 0 00-1.82.33l-.06.06a2 2 0 01-2.83 0 2 2 0 010-2.83l.06-.06a1.65 1.65 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3a2 2 0 01-2-2 2 2 0 012-2h.09A1.65 1.65 0 004.6 9a1.65 1.65 0 00-.33-1.82l-.06-.06a2 2 0 010-2.83 2 2 0 012.83 0l.06.06a1.65 1.65 0 001.82.33H9a1.65 1.65 0 001-1.51V3a2 2 0 012-2 2 2 0 012 2v.09a1.65 1.65 0 001 1.51 1.65 1.65 0 001.82-.33l.06-.06a2 2 0 012.83 0 2 2 0 010 2.83l-.06.06a1.65 1.65 0 00-.33 1.82V9a1.65 1.65 0 001.51 1H21a2 2 0 012 2 2 2 0 01-2 2h-.09a1.65 1.65 0 00-1.51 1z"/>`,
  i: `<circle cx="12" cy="12" r="10"/><path d="M12 16v-4M12 8h.01"/>`,
  lightning: `<path d="M19 16.9A5 5 0 0018 7h-1.26a8 8 0 10-11.62 9"/><path d="M13 11l-4 6h6l-4 6"/>`,
  list: `<path d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01"/>`,
  loader: `<path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>`,
  lock: `<rect x="3" y="11" width="18" height="11" rx="2" ry="2"/><path d="M7 11V7a5 5 0 0110 0v4"/>`,
  logout: `<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1="21" y1="12" x2="9" y2="12" />`,
  magnifyingGlass: `<circle cx="11" cy="11" r="8"/><path d="M21 21l-4.35-4.35"/>`,
  mail: '<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/><polyline points="22,6 12,13 2,6"/>',
  male: '<path d="M4 2.129a.745.745 0 1 0 0 1.489.745.745 0 0 0 0-1.49Zm-2.234.744a2.234 2.234 0 1 1 4.467 0 2.234 2.234 0 0 1-4.467 0ZM.596 5.94c0-.41.333-.744.744-.744h5.32c.41 0 .744.333.744.744v4.468a.745.745 0 0 1-.745.744h-.478v4.311a.745.745 0 0 1-1.49 0v-5.055c0-.412.334-.745.745-.745h.479V6.685h-3.83v2.978h.48c.41 0 .744.333.744.745v5.055a.744.744 0 1 1-1.49 0v-4.31H1.34a.744.744 0 0 1-.744-.745V5.94Z" />',
  maximize: `<path d="M8 3H5a2 2 0 00-2 2v3m18 0V5a2 2 0 00-2-2h-3m0 18h3a2 2 0 002-2v-3M3 16v3a2 2 0 002 2h3"/>`,
  meh: `<circle cx="12" cy="12" r="10"/><line x1="8" y1="15" x2="16" y2="15"/><line x1="9" y1="9" x2="9.01" y2="9"/><line x1="15" y1="9" x2="15.01" y2="9"/>`,
  messageSquare: `<path d="M21 15a2 2 0 01-2 2H7l-4 4V5a2 2 0 012-2h14a2 2 0 012 2z"/>`,
  minimize: `<path d="M8 3v3a2 2 0 01-2 2H3m18 0h-3a2 2 0 01-2-2V3m0 18v-3a2 2 0 012-2h3M3 16h3a2 2 0 012 2v3"/>`,
  minus: `<path d="M5 12h14"/>`,
  moon: `<path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"/>`,
  parking: `<path d="M6.49976 2.06048C4.13282 2.06048 2.21404 3.97926 2.21404 6.34619V18.3462C2.21404 20.7131 4.13282 22.6319 6.49976 22.6319H18.4998C20.8667 22.6319 22.7855 20.7131 22.7855 18.3462V6.34619C22.7855 3.97926 20.8667 2.06048 18.4998 2.06048H6.49976ZM0.499756 6.34619C0.499756 3.03248 3.18605 0.346191 6.49976 0.346191H18.4998C21.8135 0.346191 24.4998 3.03248 24.4998 6.34619V18.3462C24.4998 21.6599 21.8135 24.3462 18.4998 24.3462H6.49976C3.18605 24.3462 0.499756 21.6599 0.499756 18.3462V6.34619ZM9.07119 6.34619C9.07119 5.8728 9.45494 5.48905 9.92833 5.48905H13.3569C14.4935 5.48905 15.5836 5.94058 16.3874 6.74431C17.1911 7.54803 17.6426 8.63812 17.6426 9.77476C17.6426 10.9114 17.1911 12.0015 16.3874 12.8052C15.5836 13.6089 14.4935 14.0605 13.3569 14.0605H10.7855V18.3462C10.7855 18.8196 10.4017 19.2033 9.92833 19.2033C9.45494 19.2033 9.07119 18.8196 9.07119 18.3462V6.34619ZM10.7855 12.3462H13.3569C14.0389 12.3462 14.6929 12.0753 15.1752 11.593C15.6574 11.1108 15.9283 10.4567 15.9283 9.77476C15.9283 9.09278 15.6574 8.43872 15.1752 7.95649C14.6929 7.47425 14.0389 7.20333 13.3569 7.20333H10.7855V12.3462Z"/>`,
  package: `<path d="M16.5 9.4l-9-5.19M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z"/><path d="M3.27 6.96L12 12.01l8.73-5.05M12 22.08V12"/>`,
  paperClip: `<path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>`,
  pencil: `<path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"/>`,
  pieChart: `<path d="M21.21 15.89A10 10 0 118 2.83M22 12A10 10 0 0012 2v10z"/>`,
  pin: `<path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle>`,
  plus: `<path d="M12 5v14M5 12h14"/>`,
  plusCircle: `<circle cx="12" cy="12" r="10"/><line x1="12" y1="8" x2="12" y2="16"/><line x1="8" y1="12" x2="16" y2="12"/>`,
  power: `<path d="M18.36 6.64a9 9 0 11-12.73 0M12 2v10"/>`,
  questionMark: `<circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 015.83 1c0 2-3 3-3 3M12 17h.01"/>`,
  scans: `<path d="M.25 1.096A.75.75 0 0 1 1 .346h3a.75.75 0 0 1 0 1.5H1.75v2.25a.75.75 0 1 1-1.5 0v-3Zm18 0a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-2.25H19a.75.75 0 0 1-.75-.75ZM5.5 7.846a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-1.5 0v-9a.75.75 0 0 1 .75-.75Zm3 0a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75Zm3 0a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75Zm3 0a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75Zm3 0a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-1.5 0v-9a.75.75 0 0 1 .75-.75Zm-9.75 8.25a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75ZM1 19.846a.75.75 0 0 1 .75.75v2.25H4a.75.75 0 0 1 0 1.5H1a.75.75 0 0 1-.75-.75v-3a.75.75 0 0 1 .75-.75Zm21 0a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1 0-1.5h2.25v-2.25a.75.75 0 0 1 .75-.75Z"/>`,
  rain: `<path d="M8 19v2M8 13v2M16 19v2M16 13v2M12 21v2M12 15v2M20 16.58A5 5 0 0018 7h-1.26A8 8 0 104 15.25"/>`,
  raisedHand: `<path d="M12 1.60713C10.5207 1.60713 9.32142 2.80637 9.32142 4.2857C9.32142 5.76504 10.5207 6.96427 12 6.96427C13.4793 6.96427 14.6786 5.76504 14.6786 4.2857C14.6786 2.80637 13.4793 1.60713 12 1.60713ZM7.82142 4.2857C7.82142 1.97794 9.69223 0.107132 12 0.107132C14.3078 0.107132 16.1786 1.97794 16.1786 4.2857C16.1786 6.59347 14.3078 8.46428 12 8.46428C9.69223 8.46428 7.82142 6.59347 7.82142 4.2857ZM3.53571 11.1428C3.53571 10.7286 3.87149 10.3928 4.28571 10.3928H19.7143C20.1285 10.3928 20.4643 10.7286 20.4643 11.1428C20.4643 11.5571 20.1285 11.8928 19.7143 11.8928H15.3214L15.3214 23.1428C15.3214 23.5571 14.9856 23.8928 14.5714 23.8928C14.1572 23.8928 13.8214 23.5571 13.8214 23.1428L13.8214 18.75H10.1786V23.1428C10.1786 23.5571 9.84278 23.8928 9.42856 23.8928C9.01435 23.8928 8.67856 23.5571 8.67856 23.1428V11.8928H4.28571C3.87149 11.8928 3.53571 11.5571 3.53571 11.1428ZM10.1786 11.8928V17.25H13.8214V11.8928H10.1786Z"/>`,
  rotateCWW: `<polyline points="1 4 1 10 7 10"></polyline><path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>`,
  security: `<path d="M15.75 1.846a2.25 2.25 0 0 0-2.25 2.25v19.5a.75.75 0 0 1-1.5 0v-19.5a3.75 3.75 0 0 1 3.75-3.75h7.5a.75.75 0 0 1 0 1.5h-7.5ZM4.222 2.65a.75.75 0 0 1 .557 0l3.75 1.5a.75.75 0 0 1 .423.96l-1.08 2.88a3.375 3.375 0 0 1-6.744 0L.048 5.11a.75.75 0 0 1 .424-.96l3.75-1.5Zm-1.44 5.946a1.875 1.875 0 0 0 3.437 0H2.782Zm3.823-1.5L7.29 5.27 4.5 4.154 1.71 5.27l.685 1.826h4.21ZM19.5 5.971a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Zm-3.375 1.875a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.1 12.694a3.75 3.75 0 0 1 2.651-1.098h1.5A3.75 3.75 0 0 1 9 15.346v2.25a2.25 2.25 0 0 1-1.5 2.122v1.628a3 3 0 0 1-6 0v-1.628A2.25 2.25 0 0 1 0 17.596v-2.25a3.75 3.75 0 0 1 1.1-2.652ZM3 19.407v1.94a1.5 1.5 0 0 0 3 0v-2.25a.75.75 0 0 1 .75-.75.75.75 0 0 0 .75-.75v-2.25c0-.137-.012-.272-.036-.404L3 19.407Zm3.678-5.8a2.25 2.25 0 0 0-1.428-.51h-1.5a2.25 2.25 0 0 0-2.25 2.25v2.25a.75.75 0 0 0 .487.702l4.691-4.691Zm9.42-.912a3.75 3.75 0 0 1 2.652-1.099h1.5a3.75 3.75 0 0 1 3.75 3.75v2.25a2.25 2.25 0 0 1-1.5 2.122v1.628a3 3 0 0 1-6 0v-1.628a2.25 2.25 0 0 1-1.5-2.122v-2.25a3.75 3.75 0 0 1 1.099-2.652Zm2.652.401a2.25 2.25 0 0 0-2.25 2.25v2.25a.75.75 0 0 0 .75.75.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 3 0v-2.25a.75.75 0 0 1 .75-.75.75.75 0 0 0 .75-.75v-2.25a2.25 2.25 0 0 0-2.25-2.25h-1.5Z"/>`,
  server: `<rect x="2" y="2" width="20" height="8" rx="2" ry="2"/><rect x="2" y="14" width="20" height="8" rx="2" ry="2"/><path d="M6 6h.01M6 18h.01"/>`,
  sleet: `<path d="M16 13v8M8 13v8M12 15v8M20 16.58A5 5 0 0018 7h-1.26A8 8 0 104 15.25"/>`,
  sliders: `<path d="M4 21v-7M4 10V3M12 21v-9M12 8V3M20 21v-5M20 12V3M1 14h6M9 8h6M17 16h6"/>`,
  smile: `<circle cx="12" cy="12" r="10"/><path d="M8 14s1.5 2 4 2 4-2 4-2"/><line x1="9" y1="9" x2="9.01" y2="9"/><line x1="15" y1="9" x2="15.01" y2="9"/>`,
  snow: `<path d="M20 17.58A5 5 0 0018 8h-1.26A8 8 0 104 16.25M8 16h.01M8 20h.01M12 18h.01M12 22h.01M16 16h.01M16 20h.01"/>`,
  sun: `<circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>`,
  thermometer: `<path d="M14 14.76V3.5a2.5 2.5 0 00-5 0v11.26a4.5 4.5 0 105 0z"/>`,
  toiletPaper: `<path d="M14.334 3.667h5.353c-2.667 8 4 9.333 0 14.667h-5.353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.667 6.333C3.985 6.333 1 5.14 1 3.667v14.667C1 19.806 3.985 21 7.667 21s6.667-1.194 6.667-2.666V3.667c0 1.473-2.985 2.666-6.667 2.666Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.667 6.333c3.682 0 6.667-1.194 6.667-2.666C14.334 2.194 11.349 1 7.667 1S1 2.194 1 3.667C1 5.14 3.985 6.333 7.667 6.333ZM7 3.667h1.333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`,
  traffic: `<path d="M22.46.576a.75.75 0 0 1 1.06-.02 3.196 3.196 0 0 1 .98 2.393c-.005.88-.531 1.522-.875 1.942a21.7 21.7 0 0 0-.044.054c-.408.501-.581.757-.581 1.06v.024a1.698 1.698 0 0 0 .52 1.277.75.75 0 1 1-1.04 1.08 3.196 3.196 0 0 1-.98-2.393c.005-.879.531-1.522.875-1.942l.044-.054c.408-.5.581-.756.581-1.06v-.023a1.698 1.698 0 0 0-.52-1.277.75.75 0 0 1-.02-1.06Zm-4.5 1.5a.75.75 0 0 1 1.06-.02A3.196 3.196 0 0 1 20 4.448c-.005.88-.531 1.522-.875 1.942a21.7 21.7 0 0 0-.044.054c-.408.501-.581.757-.581 1.06v.024a1.698 1.698 0 0 0 .52 1.277.75.75 0 1 1-1.04 1.08A3.196 3.196 0 0 1 17 7.494c.005-.879.531-1.522.875-1.942l.044-.054c.408-.5.581-.756.581-1.06v-.023a1.698 1.698 0 0 0-.52-1.277.75.75 0 0 1-.02-1.06ZM7.426 12.346a.75.75 0 0 0-.671.415l-1.293 2.585h8.825l-1.29-2.585a.751.751 0 0 0-.67-.415H7.426Zm9.884 3 3-3H23a.75.75 0 0 0 0-1.5h-3a.75.75 0 0 0-.53.22l-3.764 3.763-1.366-2.739a2.252 2.252 0 0 0-2.013-1.244h-4.9m9.884 4.5h3.439a3.75 3.75 0 0 1 3.75 3.75v1.5a2.25 2.25 0 0 1-2.25 2.25h-2.651a2.999 2.999 0 0 1-5.198 0H9.1a2.999 2.999 0 0 1-5.198 0H2.75a2.25 2.25 0 0 1-2.25-2.25v-1.5a3.75 3.75 0 0 1 3.271-3.72l1.643-3.286a2.25 2.25 0 0 1 2.013-1.244m-2.323 11.05a.741.741 0 0 0-.035-.1 1.5 1.5 0 1 1 2.865-.008.747.747 0 0 0-.041.116 1.5 1.5 0 0 1-2.789-.008Zm-1.604-.55h-.75a.75.75 0 0 1-.75-.75v-1.5a2.25 2.25 0 0 1 2.25-2.25h16.5a2.25 2.25 0 0 1 2.25 2.25v1.5a.75.75 0 0 1-.75.75H20a3 3 0 1 0-6 0H9.5a3 3 0 1 0-6 0Zm12.107.558a.752.752 0 0 0-.04-.115 1.5 1.5 0 1 1 .04.115Z"/>`,
  truck: `<path d="M1 3h15v13H1zM16 8h4l3 3v5h-7V8z"/><circle cx="5.5" cy="18.5" r="2.5"/><circle cx="18.5" cy="18.5" r="2.5"/>`,
  twitter: `<path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>`,
  userCheck: `<path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"/><circle cx="8.5" cy="7" r="4"/><path d="M17 11l2 2 4-4"/>`,
  users: `<path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"/><circle cx="9" cy="7" r="4"/><path d="M23 21v-2a4 4 0 00-3-3.87M16 3.13a4 4 0 010 7.75"/>`,
  video: `<path d="M23 7l-7 5 7 5V7z"/><rect x="1" y="5" width="15" height="14" rx="2" ry="2"/>`,
  wind: `<path d="M9.59 4.59A2 2 0 1111 8H2m10.59 11.41A2 2 0 1014 16H2m15.73-8.27A2.5 2.5 0 1119.5 12H2"/>`,
  x: `<path d="M18 6L6 18M6 6l12 12"/>`,
  xCircle: `<circle cx="12" cy="12" r="10"/><path d="M15 9l-6 6M9 9l6 6"/>`,
};

export default icons;
