import { isEqual, pick, sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory, useLocation } from 'react-router-dom';

import routes from 'config/routes';
import { MAX_PAGE1_TILES, MAX_TILES, Tile, TILES } from 'config/tiles';

import { rem, track } from 'utils';

import { useAlertSettings, useUpdateAlertSettings } from 'client/AlertSettings';
import { useUpdateUserMetadata } from 'client/User';

import { useLocalStorage, useQueryParams, useSettingsTabs } from 'hooks';

import { initialState, State, updateSettings, useSettings } from 'providers/SettingsProvider';
import { showError, showSuccess, useToastDispatch } from 'providers/ToastProvider';
import { useUserData } from 'providers/UserDataProvider';

import {
  Button,
  Checkbox,
  GridVisualizer,
  Icon,
  LinkButton,
  Modal,
  RequiredPermission,
  Select,
  Tab,
  Tabs,
} from 'components';

import { Box, TextInput, TextLabel } from 'styled';

const SettingsModal: React.FC = props => {
  const history = useHistory();
  const location = useLocation();
  const query = useQueryParams();
  const { setValue: setRedirectPath } = useLocalStorage('redirectPath');

  // Dashboard settings
  const [settings, dispatch] = useSettings();
  const initialDashboardPage = settings?.initialDashboardSettingsPage || 1;
  const initialPage = `page${initialDashboardPage}`;
  const initialSettingsTab = settings?.initialSettingsTab;
  const [selectedDashboardPage, setSelectedDashboardPage] = useState<string>(initialPage);
  const [state, setState] = useState<State>(settings);
  const { shouldShowAlertTab } = useSettingsTabs();

  // Alert settings
  const alertSettings = useAlertSettings();
  const [updateAlertSettings] = useUpdateAlertSettings();
  const [initializedAlertSettings, setInitializedAlertSettings] = useState(false);
  const [tileEditMode, setTileEditMode] = useState(true);
  const { permissions } = useUserData();

  const toastDispatch = useToastDispatch();
  const [updateMetadata, { loading }] = useUpdateUserMetadata({
    onCompleted: () => toastDispatch(showSuccess('Settings saved')),
    onError: () => toastDispatch(showError('There was an error saving your settings')),
  });

  // Handle dashboard setting
  useEffect(() => {
    const shouldRun = settings.dashboardMode && !query.get('dashboardMode');
    if (shouldRun) {
      setRedirectPath(`${location.pathname}${location.search}`);
      const pathname = routes.dashboard;
      history.push({
        pathname,
        search: '?dashboardMode=true',
      });
    } else {
      setRedirectPath(`${location.pathname}`);
    }
  }, [settings.dashboardMode, history, query, location, setRedirectPath]);

  // Load alert settings from API into state
  useEffect(() => {
    const settingsData = alertSettings?.data?.alertSettings;
    if (!settingsData || initializedAlertSettings) return;

    const newState = {
      ...state,
      alerts: {
        parking: {
          threshold: settingsData?.parking_threshold,
          frequency: settingsData?.parking_frequency,
        },
        security: {
          threshold: settingsData?.security_threshold,
          frequency: settingsData?.security_frequency,
        },
        bathrooms: {
          threshold: settingsData?.bathrooms_threshold,
          frequency: settingsData?.bathrooms_frequency,
        },
        feedback: {
          threshold: settingsData?.feedback_threshold,
          frequency: settingsData?.feedback_frequency,
        },
      },
    };
    setState(newState);
    setInitializedAlertSettings(true);
  }, [alertSettings, dispatch, initializedAlertSettings, state]);

  function handleSave(event: React.MouseEvent): void {
    event.preventDefault();
    const dashboardModeChanged = state.dashboardMode !== settings.dashboardMode;
    const needsAccountUpdate =
      state.emailAlerts !== settings.emailAlerts ||
      state.emailInsights !== settings.emailInsights ||
      !isEqual(state.dashboardLayout, settings.dashboardLayout);

    track('Update Settings');

    // Update Alerts settings and state
    dispatch(
      updateSettings({
        ...state,
        // We're doing this because we want to keep the `active` state of the alerts
        // in the state, but we don't want to save it to the account, which will
        // throw an error in the API because `active` is there.
        alerts: {
          parking: { ...state.alerts.parking, active: settings.alerts.parking.active },
          security: { ...state.alerts.security, active: settings.alerts.security.active },
          bathrooms: { ...state.alerts.bathrooms, active: settings.alerts.bathrooms.active },
          feedback: { ...state.alerts.feedback, active: settings.alerts.feedback.active },
        },
      })
    );
    updateAlertSettings({ variables: { settings: state.alerts } });

    // We only need to update the user's metadata when they made changes
    // to settings that are saved to the account.
    if (needsAccountUpdate) {
      updateMetadata({
        variables: {
          key: 'settings',
          value: JSON.stringify(
            pick(state, ['emailAlerts', 'emailInsights', 'dashboardLayout', 'hasSeenTour'])
          ),
        },
      });
    }

    if (dashboardModeChanged) {
      track('Set Dashboard Mode');
      history.push(routes.dashboard);
    } else {
      const pageIndex = selectedDashboardPage?.slice(-1);
      history.push({
        hash: `#${pageIndex}`,
        pathname: location.pathname,
      });
    }

    setTimeout(() => {
      toastDispatch(showSuccess('Settings saved'));
    }, 1000);
  }

  const hasInsightsConfig = process.env.REACT_APP_CONFIG_INSIGHTS === 'true';

  type Layout = {
    value: string;
    label: string;
  };

  const renderGeneralTab = () => (
    <Tab title="General" icon="gear">
      <Box mt="l">
        <Checkbox
          label="Dashboard Mode"
          name="dashboardMode"
          checked={state.dashboardMode}
          onChange={(): void => setState({ ...state, dashboardMode: !state.dashboardMode })}
          description="Dashboard Mode hides extraneous interface elements and cycles through data. It is best for running the app on a large screen while not interacting with it."
        />
      </Box>
    </Tab>
  );

  const renderAlertsTab = () => (
    <Tab title="Alerts" icon="bell">
      <Box as="h3" mt="l">
        Set Alert Triggers
      </Box>
      <Box as="p" mb="m" mt="xs" color="neutralsGradient.6" fontSize="s">
        You can set threshold values to check and the frequency to check them. You’ll be alerted
        when it passes the value.
      </Box>
      {/* Security Wait Times */}
      {settings?.alerts?.security?.active && (
        <Box borderTop="1px solid" borderColor="neutralsGradient.10" mt="m">
          <Box as="h5" mt="l" fontSize="s">
            Security Wait Times
          </Box>
          <Box display="flex">
            <Box display="flex" flexDirection="column">
              <Box as="h5" color="neutralsGradient.4">
                Threshold
              </Box>
              <TextInput
                title="Threshold"
                name="Threshold"
                id="security-threshold"
                type="number"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setState({
                    ...state,
                    alerts: {
                      ...state.alerts,
                      security: {
                        ...state?.alerts?.security,
                        threshold: Math.abs(parseInt(event?.target?.value)),
                      },
                    },
                  });
                }}
                value={state?.alerts?.security?.threshold || ''}
              />
              <Box as="h5" color="neutralsGradient.5" mt="xs">
                minutes
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" ml="l">
              <Box as="h5" color="neutralsGradient.4">
                Frequency
              </Box>
              <TextInput
                name="Frequency"
                type="number"
                id="security-frequency"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setState({
                    ...state,
                    alerts: {
                      ...state.alerts,
                      security: {
                        ...state?.alerts?.security,
                        frequency: Math.abs(parseInt(event?.target?.value)),
                      },
                    },
                  });
                }}
                value={state?.alerts?.security?.frequency || ''}
              />
              <Box as="h5" color="neutralsGradient.5" mt="xs">
                minutes
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/* Parking Capacity */}
      {settings?.alerts?.parking?.active && (
        <Box borderTop="1px solid" borderColor="neutralsGradient.10" mt="m">
          <Box as="h5" mt="l" fontSize="s">
            Parking Capacity
          </Box>
          <Box display="flex">
            <Box display="flex" flexDirection="column">
              <Box as="h5" color="neutralsGradient.4">
                Threshold
              </Box>
              <TextInput
                title="Threshold"
                name="Threshold"
                id="parking-threshold"
                type="number"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = parseInt(event?.target?.value);
                  const threshold = value ? Math.abs(value / 100) : 0;
                  setState({
                    ...state,
                    alerts: {
                      ...state.alerts,
                      parking: {
                        ...state?.alerts?.parking,
                        threshold,
                      },
                    },
                  });
                }}
                value={Math.round(state?.alerts?.parking?.threshold * 100) || ''}
              />
              <Box as="h5" color="neutralsGradient.5" mt="xs">
                % full
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" ml="l">
              <Box as="h5" color="neutralsGradient.4">
                Frequency
              </Box>
              <TextInput
                name="Frequency"
                type="number"
                id="parking-frequency"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setState({
                    ...state,
                    alerts: {
                      ...state.alerts,
                      parking: {
                        ...state?.alerts?.parking,
                        frequency: Math.abs(parseInt(event?.target?.value)),
                      },
                    },
                  });
                }}
                value={state?.alerts?.parking?.frequency || ''}
              />
              <Box as="h5" color="neutralsGradient.5" mt="xs">
                minutes
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/* Bathroom Traffic */}
      {settings?.alerts?.bathrooms?.active && (
        <Box borderTop="1px solid" borderColor="neutralsGradient.10" mt="m">
          <Box as="h5" mt="l" fontSize="s">
            Bathroom Traffic
          </Box>
          <Box display="flex">
            <Box display="flex" flexDirection="column">
              <Box as="h5" color="neutralsGradient.4">
                Threshold
              </Box>
              <TextInput
                title="Threshold"
                name="Threshold"
                id="bathrooms-threshold"
                type="number"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = parseInt(event?.target?.value);
                  const threshold = value ? Math.abs(value) : 0;
                  setState({
                    ...state,
                    alerts: {
                      ...state.alerts,
                      bathrooms: {
                        ...state?.alerts?.bathrooms,
                        threshold,
                      },
                    },
                  });
                }}
                value={Math.round(state?.alerts?.bathrooms?.threshold) || ''}
              />
              <Box as="h5" color="neutralsGradient.5" mt="xs">
                triggers
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" ml="l">
              <Box as="h5" color="neutralsGradient.4">
                Frequency
              </Box>
              <TextInput
                name="Frequency"
                type="number"
                id="bathrooms-frequency"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setState({
                    ...state,
                    alerts: {
                      ...state.alerts,
                      bathrooms: {
                        ...state?.alerts?.bathrooms,
                        frequency: Math.abs(parseInt(event?.target?.value)),
                      },
                    },
                  });
                }}
                value={state?.alerts?.bathrooms?.frequency || ''}
              />
              <Box as="h5" color="neutralsGradient.5" mt="xs">
                minutes
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/* Customer Feedback */}
      {settings?.alerts?.feedback?.active && (
        <Box
          borderBottom="1px solid"
          borderTop="1px solid"
          borderColor="neutralsGradient.10"
          mt="m"
          pb="m"
        >
          <Box as="h5" mt="l" fontSize="s">
            Customer Feedback
          </Box>
          <Box display="flex">
            <Box display="flex" flexDirection="column">
              <Box as="h5" color="neutralsGradient.4">
                Threshold
              </Box>
              <TextInput
                title="Threshold"
                name="Threshold"
                id="feedback-threshold"
                type="number"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = parseInt(event?.target?.value);
                  const threshold = value ? Math.abs(value) : 0;
                  setState({
                    ...state,
                    alerts: {
                      ...state.alerts,
                      feedback: {
                        ...state?.alerts?.feedback,
                        threshold,
                      },
                    },
                  });
                }}
                value={Math.round(state?.alerts?.feedback?.threshold) || ''}
              />
              <Box as="h5" color="neutralsGradient.5" mt="xs">
                # of feedback
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" ml="l">
              <Box as="h5" color="neutralsGradient.4">
                Frequency
              </Box>
              <TextInput
                name="Frequency"
                type="number"
                id="feedback-frequency"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setState({
                    ...state,
                    alerts: {
                      ...state.alerts,
                      feedback: {
                        ...state?.alerts?.feedback,
                        frequency: Math.abs(parseInt(event?.target?.value)),
                      },
                    },
                  });
                }}
                value={state?.alerts?.feedback?.frequency || ''}
              />
              <Box as="h5" color="neutralsGradient.5" mt="xs">
                minutes
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Tab>
  );

  const renderNotificationsTab = () => (
    <Tab title="Notifications" icon="messageSquare">
      <Box as="h3" mt="l">
        Emails
      </Box>
      <Box mt="xs">
        <Checkbox
          label="Receive Email Alerts"
          name="emailAlerts"
          checked={state.emailAlerts}
          onChange={(): void => setState({ ...state, emailAlerts: !state.emailAlerts })}
          description="As an alert is triggered, automatically receive an email sent to your inbox."
        />
      </Box>
      {hasInsightsConfig && (
        <RequiredPermission permission="feature:insights">
          <Box mt="base">
            <Checkbox
              label="Receive Weekly EASE Insights Email"
              name="emailInsights"
              checked={state.emailInsights}
              onChange={(): void => setState({ ...state, emailInsights: !state.emailInsights })}
              description="Receive an email every Monday with stats and trends from the past week."
            />
          </Box>
        </RequiredPermission>
      )}
    </Tab>
  );

  const renderTileOption = (option: Tile, stateKey: string) => {
    const maxTiles = selectedDashboardPage === 'page1' ? MAX_PAGE1_TILES : MAX_TILES;
    const pageTiles = state?.dashboardLayout?.[stateKey] || [];
    const updateTileOption = () => {
      const newState = pageTiles.includes(option?.title)
        ? pageTiles?.filter((tile: string) => tile !== option?.title)
        : [...state?.dashboardLayout?.[stateKey], option?.title];

      setState({
        ...state,
        dashboardLayout: {
          ...state?.dashboardLayout,
          [stateKey]: newState,
        },
      });
    };
    const isDisabled = pageTiles?.length >= maxTiles && !pageTiles?.includes(option?.title);

    return (
      <Box mt="base" pr="s">
        <Checkbox
          label={option?.title}
          labelSuffix={() =>
            option?.status === 'NEW' ? (
              <TextLabel
                border="1px solid"
                borderColor="primary"
                p="0 2px"
                borderRadius="base"
                ml="xs"
              >
                New
              </TextLabel>
            ) : null
          }
          name={option?.title}
          onChange={(): void => updateTileOption()}
          checked={pageTiles?.includes(option?.title)}
          disabled={isDisabled}
        />
        <Box fontSize="s" ml="base" color="neutralsGradient.5">
          {option?.description}
        </Box>
      </Box>
    );
  };

  const handleOnDragEnd = (result: any, stateKey: string) => {
    const pageTiles = state?.dashboardLayout?.[stateKey] || [];
    const newState = pageTiles?.filter((t: string) => t !== result.draggableId);
    newState?.splice(result?.destination?.index, 0, result.draggableId);
    setState({
      ...state,
      dashboardLayout: {
        ...state?.dashboardLayout,
        [stateKey]: newState,
      },
    });
  };

  const handleRemoveTile = (tile: string, stateKey: string) => {
    const pageTiles = state?.dashboardLayout?.[stateKey] || [];
    setState({
      ...state,
      dashboardLayout: {
        ...state?.dashboardLayout,
        [stateKey]: pageTiles?.filter((t: string) => t !== tile),
      },
    });
  };

  const getAvailableTileOptions = (stateKey: string) =>
    sortBy(TILES, 'title')
      .filter(tile => !tile?.requiredPermission || permissions.includes(tile.requiredPermission))
      .filter(tile => tile.configSetting)
      .map(tile => renderTileOption(tile, stateKey));

  const renderTileDragArea = (stateKey: string) => (
    <DragDropContext onDragEnd={result => handleOnDragEnd(result, stateKey)}>
      <Droppable droppableId="tiles">
        {provided => (
          <ul className="tiles" id="tiles" {...provided.droppableProps} ref={provided.innerRef}>
            {state?.dashboardLayout?.[stateKey]?.map((tile: string, index: number) => (
              <Draggable key={tile} draggableId={tile} index={index}>
                {(provided, snapshot) => {
                  if (snapshot.isDragging) {
                    try {
                      // https://github.com/atlassian/react-beautiful-dnd/issues/499#issuecomment-905783891
                      // Resetting the draggable's position fixes an offset issue on resized screens
                      // @ts-expect-error
                      provided.draggableProps.style.left = undefined;
                      // @ts-expect-error
                      provided.draggableProps.style.top = undefined;
                    } catch (e) {
                      // Sometimes the dnd object is frozen and we can't set values
                      // eslint-disable-next-line no-console
                      console.warn('There was an issue moving the tile');
                    }
                  }
                  return (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Box
                        ml="base"
                        mb="s"
                        display="flex"
                        backgroundColor="neutralsGradient.10"
                        p="base"
                        width="auto"
                        border="1px solid"
                        borderColor="neutralsGradient.8"
                        borderRadius="base"
                        fontWeight="bold"
                      >
                        <Icon name="alignJustify" size="20" mr="s" color="neutralsGradient.4" />
                        {tile}
                        <Icon
                          ml="auto"
                          name="xCircle"
                          size="20"
                          mr="s"
                          color="error"
                          onClick={() => handleRemoveTile(tile, stateKey)}
                        />
                      </Box>
                    </li>
                  );
                }}
              </Draggable>
            ))}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );

  const renderDashboardTab = () => {
    const tilesCount = state?.dashboardLayout?.[selectedDashboardPage]?.length + 1 || 1;
    const previewHeight = tilesCount <= 8 ? '340px' : tilesCount <= 12 ? '480px' : '625px';
    const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedDashboardPage(event.target.value);
    };
    return (
      <Tab title="Dashboard" icon="barChart" id="layout-settings-tab">
        <Box mt="l" id="layout-settings-content">
          <Box>
            <Box as="h3">Dashboard Tiles</Box>
            <Box mb="base" display="flex" justifyContent="space-between" alignItems="center">
              <Box width="200px">
                <Select
                  name="reverse"
                  options={[
                    { value: 'page1', label: 'Page 1' },
                    { value: 'page2', label: 'Page 2' },
                  ]}
                  onChange={handlePageChange}
                  value={selectedDashboardPage}
                />
              </Box>
              <Tabs>
                <Tab
                  title="Edit Tiles"
                  icon="edit"
                  selected={tileEditMode}
                  onClick={() => setTileEditMode(true)}
                />
                <Tab
                  title="Preview Tile Layout"
                  icon="eye"
                  selected={!tileEditMode}
                  onClick={() => setTileEditMode(false)}
                />
              </Tabs>
            </Box>
            {tileEditMode ? (
              <Box
                display="flex"
                borderBottom="1px solid"
                borderTop="1px solid"
                borderColor="neutralsGradient.8"
              >
                <Box
                  className="tile-settings"
                  width="50%"
                  height="700px"
                  display="flex"
                  flexDirection="column"
                  borderRight="1px solid"
                  borderColor="neutralsGradient.8"
                  overflowY="scroll"
                  pb="s"
                >
                  <Box fontWeight="bold" fontSize="base" pt="base">
                    Available Tiles
                  </Box>
                  <Box fontSize="s">
                    {`Select up to ${
                      selectedDashboardPage === 'page1' ? MAX_PAGE1_TILES : MAX_TILES
                    } tiles to show on the page.`}
                  </Box>
                  {getAvailableTileOptions(selectedDashboardPage)}
                </Box>
                <Box width="50%" height="700px" overflow="auto">
                  <Box display="flex" justifyContent="space-between" py="base" pl="base">
                    <Box fontSize="m" fontWeight="bold">
                      {state?.dashboardLayout?.[selectedDashboardPage]?.length} Tiles Selected
                    </Box>
                    <LinkButton
                      fontSize="s"
                      mt="-12px"
                      color="neutralsGradient.5"
                      onClick={() =>
                        setState({
                          ...state,
                          dashboardLayout: {
                            ...state?.dashboardLayout,
                            [selectedDashboardPage]:
                              initialState.dashboardLayout?.[selectedDashboardPage],
                          },
                        })
                      }
                    >
                      <Box
                        opacity={
                          isEqual(
                            initialState?.dashboardLayout?.[selectedDashboardPage],
                            state?.dashboardLayout?.[selectedDashboardPage]
                          )
                            ? 0.4
                            : 1
                        }
                      >
                        Reset To Default
                      </Box>
                    </LinkButton>
                  </Box>
                  {state.dashboardLayout?.[selectedDashboardPage]?.length ? (
                    renderTileDragArea(selectedDashboardPage)
                  ) : (
                    <Box color="neutralsGradient.4" textAlign="center" px="base">
                      Select at least 1 tile from the available tiles to save your changes.
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Box
                height={previewHeight}
                borderBottom="1px solid"
                borderTop="1px solid"
                borderColor="neutralsGradient.8"
              >
                <Box mt="m" fontWeight="bold">
                  Preview Tile Layout
                </Box>
                <GridVisualizer tiles={state?.dashboardLayout?.[selectedDashboardPage]} />
              </Box>
            )}
          </Box>
        </Box>
      </Tab>
    );
  };

  return (
    <Modal title="Settings" icon={{ name: 'gear' }} width={rem('650px')} {...props}>
      <Box mb="l">
        <Tabs initialTab={initialSettingsTab}>
          {renderGeneralTab()}
          {shouldShowAlertTab && renderAlertsTab()}
          {renderNotificationsTab()}
          {renderDashboardTab()}
        </Tabs>
      </Box>
      <Box mx="auto">
        <Button
          onClick={handleSave}
          loading={loading}
          disabled={
            !state?.dashboardLayout?.page1?.length || !state?.dashboardLayout?.page2?.length
          }
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default SettingsModal;
