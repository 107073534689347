import Highcharts from 'highcharts';
import merge from 'lodash/merge';

import { getDefaultYAxisOptions } from 'statistics/charts/utils';

const axis: Highcharts.YAxisOptions = merge(getDefaultYAxisOptions(), {
  id: 'dailyThroughputs',
  title: {
    text: 'Daily Throughput',
  },
  stackLabels: {
    enabled: false,
  },
} as Highcharts.YAxisOptions);

export default function getAxis(): Highcharts.YAxisOptions {
  return axis;
}
