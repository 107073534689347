import formatAirportTime from './formatAirportTime';

function getDelayTimeString(delayMinutes: number, scheduledTime: number): string {
  return delayMinutes
    ? `Delayed +${delayMinutes} minute${
        delayMinutes === 1 ? '' : 's'
      } (originally ${formatAirportTime(new Date(scheduledTime), 'h:mm a')})`
    : '';
}

export default getDelayTimeString;
