import React from 'react';

import { BlankSlate } from 'components';

const NotAvailableBlankSlate: React.FC = () => {
  const blankSlateMessage = "You don't have permissions to view this.";
  return (
    <BlankSlate
      title="Not available"
      icon={{ name: 'lock', color: 'warning' }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p="l"
    >
      {blankSlateMessage}
    </BlankSlate>
  );
};

export default NotAvailableBlankSlate;
