import { UAParser } from 'ua-parser-js';

function parseBrowserInfo(userAgent: string): string {
  const parser = new UAParser();
  parser.setUA(userAgent);
  const result = parser.getResult();

  const browser = `${result.browser.name} ${result.browser.version}`;
  const os = `${result.os.name} ${result.os.version}`;

  return `${browser} - ${os}`;
}

export default parseBrowserInfo;
