import React, { useEffect, useState } from 'react';

import { useCreateIssue } from 'client/Issue';
import { Issue, PreloadIssue } from 'client/Issue/types';
import { getOptimisticResponse } from 'client/Issue/useCreateIssue';

import { useDocumentTitle, useLocalStorage } from 'hooks';

import { showError, showSuccess, useToastDispatch } from 'providers/ToastProvider';

import { IssueForm, Modal, RequiredPermission } from 'components';

import { Box } from 'styled';

const NewIssueModal: React.FC = props => {
  const toastDispatch = useToastDispatch();
  const [preloadIssue, setPreloadIssue] = useState<PreloadIssue>({});
  const { storedValue: alertIssue, remove: removeAlertIssue } = useLocalStorage('alertIssue');
  const [createIssue] = useCreateIssue({
    onCompleted: () => toastDispatch(showSuccess('Issue created')),
    onError: () => toastDispatch(showError('There was an error creating the issue')),
  });
  useDocumentTitle('New Issue');

  // Set preloaded issue parameters if available
  useEffect(() => {
    if (!alertIssue || preloadIssue?.title) return;
    setPreloadIssue({
      title: alertIssue?.title,
      description: alertIssue?.description?.replace('<strong>', '')?.replace('</strong>', ''),
      severity: alertIssue?.status === 'WARNING' ? 'moderate' : 'severe',
    });

    // Clear the saved issue
    removeAlertIssue();
  }, [alertIssue, preloadIssue, removeAlertIssue]);

  return (
    <Modal title="New Issue" icon={{ name: 'book', color: 'neutralsGradient.1' }} {...props}>
      <RequiredPermission permission="feature:issues" redirect={true}>
        <RequiredPermission permission="create:issues" redirect={true} redirectTo="/issues">
          <Box mt="xl">
            <IssueForm
              data={preloadIssue}
              type="create"
              onSubmit={(values: Issue) =>
                createIssue({
                  variables: {
                    ...values,
                    status: 'open',
                  },
                  ...getOptimisticResponse(values),
                })
              }
            />
          </Box>
        </RequiredPermission>
      </RequiredPermission>
    </Modal>
  );
};

export default NewIssueModal;
