import { isEqual } from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { Tile, TILES } from 'config/tiles';

import { useMetadata } from 'hooks';

import { CenteredLoader } from 'components';

interface AlertSettings {
  threshold: number;
  frequency: number;
  active?: boolean;
}

interface Alerts {
  parking: AlertSettings;
  security: AlertSettings;
  bathrooms: AlertSettings;
  feedback: AlertSettings;
}

export interface DashboardPages {
  [key: string]: string[];
}

export interface State {
  [key: string]: any;
  dashboardMode?: boolean;
  dashboardModeDuration?: number;
  emailAlerts?: boolean;
  emailInsights?: boolean;
  hasSeenTour?: boolean;
  isUserMenuOpen?: boolean;
  status?: string;
  alerts: Alerts;
  dashboardLayout: DashboardPages;
  dashboardPage: string;
}

export interface ActionPayload {
  dashboardMode?: boolean;
  dashboardModeDuration?: number;
  emailAlerts?: boolean;
  emailInsights?: boolean;
  hasSeenTour?: boolean;
  status?: string;
  dashboardLayout?: DashboardPages;
}

interface Action {
  type: string;
  payload: ActionPayload;
}

// 1 minute
export const DEFAULT_DASHBOARD_MODE_DURATION = 60000;

const page1Defaults = (process.env.REACT_APP_PAGE1_TILES || '').split(',');
const page2Defaults = (process.env.REACT_APP_PAGE2_TILES || '').split(',');

const initialState: State = {
  dashboardMode: false,
  dashboardPage: 'page1',
  dashboardModeDuration: DEFAULT_DASHBOARD_MODE_DURATION,
  emailAlerts: false,
  emailInsights: true,
  hasSeenTour: false,
  initialDashboardSettingsPage: 1,
  isUserMenuOpen: false,
  initialSettingsTab: 0,
  status: 'initializing',
  alerts: {
    bathrooms: {
      threshold: 100,
      frequency: 30,
      active: process.env.REACT_APP_CONFIG_BATHROOMS === 'true' || false,
    },
    feedback: {
      threshold: 1,
      frequency: 30,
      active: process.env.REACT_APP_CONFIG_FEEDBACK === 'true' || false,
    },
    security: {
      threshold: 10,
      frequency: 30,
      active: process.env.REACT_APP_CONFIG_WAIT_TIMES === 'true' || false,
    },
    parking: {
      threshold: 0.75,
      frequency: 30,
      active: process.env.REACT_APP_CONFIG_PARKING === 'true' || false,
    },
  },
  dashboardLayout: {
    page1: page1Defaults.filter(t => TILES.find((tile: Tile) => tile.title === t)?.configSetting),
    page2: page2Defaults.filter(t => TILES.find((tile: Tile) => tile.title === t)?.configSetting),
  },
};

const SettingsProviderStateContext = createContext<State>(initialState);
const SettingsProviderDispatchContext = createContext<any>(null);

const actionTypes = {
  update: 'update',
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case actionTypes.update: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

interface Props {
  children?: React.ReactNode;
}

const SettingsProvider: React.FC<Props> = ({ children }: Props) => {
  const { metadata: settings, loading: loadingMetadata } = useMetadata('settings');
  const [state, dispatch] = useReducer(reducer, initialState);
  const loading = loadingMetadata || state.status === 'initializing';

  useEffect(() => {
    const shouldRun = !loadingMetadata && state.status !== 'complete';

    if (shouldRun) {
      const shouldUpdateSettings = !isEqual(settings, {
        emailAlerts: state.emailAlerts,
        emailInsights: state.emailInsights,
        hasSeenTour: state.hasSeenTour,
        dashboardLayout: state.dashboardLayout,
      });

      if (shouldUpdateSettings) {
        dispatch({
          type: actionTypes.update,
          payload: {
            emailAlerts: settings?.emailAlerts || false,
            emailInsights: settings?.emailInsights || false,
            hasSeenTour: settings?.hasSeenTour || false,
            dashboardLayout: settings?.dashboardLayout || initialState.dashboardLayout,
            status: 'complete',
          },
        });
      } else {
        dispatch({ type: actionTypes.update, payload: { status: 'complete' } });
      }
    }
  }, [
    loadingMetadata,
    settings,
    state.status,
    state.emailAlerts,
    state.emailInsights,
    state.hasSeenTour,
    state.dashboardLayout,
  ]);

  if (loading) return <CenteredLoader label="Customizing your settings..." />;

  return (
    <SettingsProviderStateContext.Provider value={state}>
      <SettingsProviderDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsProviderDispatchContext.Provider>
    </SettingsProviderStateContext.Provider>
  );
};

// const state = useSettingsState();
function useSettingsState() {
  const context = useContext(SettingsProviderStateContext);
  if (context === undefined) {
    throw new Error(`useSettingsState must be used within a SettingsProvider`);
  }
  return context;
}

// const dispatch = useSettingsDispatch();
function useSettingsDispatch() {
  const context = useContext(SettingsProviderDispatchContext);
  if (context === undefined) {
    throw new Error(`useSettingsDispatch must be used within a SettingsProvider`);
  }
  return context;
}

// const [state, dispatch] = useSettings();
function useSettings() {
  const context = [useSettingsState(), useSettingsDispatch()];
  if (context === undefined) {
    throw new Error(`useSettings must be used within a SettingsProvider`);
  }
  return context;
}

// SELECTORS
// const selectors = {};

// ACTION CREATORS
const updateSettings = (payload: any): Action => ({
  type: actionTypes.update,
  payload,
});

export {
  SettingsProvider as default,
  initialState,
  useSettings,
  useSettingsState,
  useSettingsDispatch,
  actionTypes,
  updateSettings,
};
