import { subHours } from 'date-fns';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import React from 'react';
import { getAirportTime } from 'utils';

import { TILES } from 'config/tiles';

import { getTrend } from 'utils';
import { Trend as ParkingTrend } from 'utils/getTrend';

import { useParking } from 'data/Parking';

import { useRefreshedAt } from 'hooks';

import { useTileOptionsState } from 'providers/TileOptionsProvider';

import { CapacityStatus, QueryCell, Table, Trend, UpdatedAt } from 'components';

import { Box, TextLabel } from 'styled';

import { Status } from './components';

const Parking: React.FC = () => {
  const {
    parking: { filters },
  } = useTileOptionsState();
  const isReverseSort = filters?.isReverseSort;
  const sortByColumn = filters?.sortByColumn || filters?.defaultSortByColumn;

  const tileConfig = TILES?.find(tile => tile.title === 'Parking');
  let headings = tileConfig?.headings || [];

  const timestamp = subHours(new Date(), 1);
  timestamp.setSeconds(0);
  timestamp.setMilliseconds(0);

  const { parking, loading, error } = useParking({});

  const {
    parking: parkingHistory,
    loading: loadingHistory,
    error: errorHistory,
  } = useParking({
    timestamp: getAirportTime(timestamp).getTime(),
  });

  const { refreshedAt } = useRefreshedAt(loading);
  if (process.env.REACT_APP_CONFIG_PARKING !== 'true') return null;

  const parkingTrends: ParkingTrend[] = [];
  if (parkingHistory.length && parking.length) {
    for (let i = 0; i < parking.length; i++) {
      const trend = getTrend(parkingHistory[i]?.spotsOccupied, parking[i]?.spotsOccupied);
      parkingTrends.push(trend);
    }
  }

  // Map and sort parking data
  const mappedParking = parking.map((p, i) => ({
    ...p,
    parkingTrends: parkingTrends[i],
    percentage: parkingTrends[i]?.percentage,
  }));
  let sortedParking = sortBy(mappedParking, sortByColumn || '');
  if (isReverseSort) sortedParking = sortedParking.reverse();

  let grid = '1.25fr repeat(2, 1fr) 0.75fr';

  if (sortedParking.map(p => p.lot).filter(Boolean).length > 0) {
    headings = [{ key: 'status', label: '' }, ...headings];
    grid = '0.5fr 1.5fr repeat(3, 1fr)';
  }

  const lotConverter = (lot: string): string => {
    const airportCode = process.env.REACT_APP_AIRPORT_CODE || '';
    if (airportCode === 'RNO') {
      switch (lot) {
        case 'BL':
          return 'Blue Lot';
        case 'GAR':
          return 'Long Term Garage';
        case 'GL':
          return 'Green Lot';
        case 'LT':
          return 'Long Term Surface';
        case 'YL':
          return 'Yellow Lot';
        case 'ST':
          return 'Short Term Garage';
        default:
          return lot;
      }
    } else if (airportCode === 'CVG') {
      switch (lot) {
        case 'VP':
          return 'ValuPark';
        case 'ST':
          return 'Garage';
        case 'EC':
          return 'Economy';
        default:
          return lot;
      }
    }
    return lot;
  };

  const renderData = (): { [key: string]: JSX.Element }[] => {
    return uniq(sortedParking).map((p, i) => ({
      status: p.status ? <Status status={p.status || 'open'} /> : <Box />,
      lot: (
        <Box as="span" fontSize="s">
          {lotConverter(p.lot)}
        </Box>
      ),
      trend: (
        <Trend
          direction={sortedParking[i]?.parkingTrends?.direction || 'positive'}
          value={sortedParking[i]?.parkingTrends?.percentage || 0}
          suffix="%"
          interval="hour"
        />
      ),
      spaces:
        p.spotsTotal > 0 ? (
          <CapacityStatus
            occupancy={p.spotsAvailable}
            capacity={p.spotsTotal}
            load={(p.spotsOccupied / p.spotsTotal) * 100}
            hideCapacityNumber={true}
          />
        ) : (
          <TextLabel color="subdued">N/A</TextLabel>
        ),
      load:
        p.spotsTotal > 0 ? (
          <Box fontSize="s">{`${Math.round((p.spotsOccupied / p.spotsTotal) * 100)}%`}</Box>
        ) : (
          <TextLabel color="subdued">N/A</TextLabel>
        ),
    }));
  };

  return (
    <QueryCell
      content="parking"
      data={sortedParking}
      error={error || errorHistory}
      loading={loading || loadingHistory}
    >
      <Table
        name="parking"
        headings={headings}
        data={renderData()}
        bordered={true}
        cellPaddingX="s"
        cellPaddingY="s"
        dataFontSize="s"
        grid={grid}
        gridGap="xs"
        striped={false}
      />
      <UpdatedAt date={refreshedAt} loading={loading || loadingHistory} />
    </QueryCell>
  );
};

export default Parking;
