import { addMinutes, format, isPast } from 'date-fns';

import { COMPLETED } from 'data/Flights/constants';

import CargoFlightData from './CargoFlightData';

import { ArrivalFlight } from 'data/Flights/types';

import { AircraftWeight } from 'config/weights';

type Cargo = ArrivalFlight & AircraftWeight & { cost?: number };

class CargoArrivalFlightData extends CargoFlightData {
  constructor({ flight }: { flight: Cargo }) {
    super({ flight });
  }

  get completed(): boolean {
    return this.flight.statusText === COMPLETED || isPast(this.flight.actualArrivalDate);
  }

  get delayed(): boolean {
    return Boolean(this.flight.arrivalDelay && !this.completed);
  }

  get arrival(): Date {
    return new Date(
      this.flight.actualArrivalDate ||
        this.flight.estimatedArrivalDate ||
        this.flight.scheduledArrivalDate
    );
  }

  get arrivalTime(): string {
    return format(this.arrival, 'h:mm a');
  }

  get newArrivalTime(): Date {
    return addMinutes(new Date(this.arrival), this.flight.arrivalDelay);
  }
}

export default CargoArrivalFlightData;
