// @ts-nocheck
import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Tab, Tabs } from 'styled';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Tile = styled.div<SystemProps>`
  background: ${themeGet('colors.neutralsGradient.11')};
  border-top: 4px solid ${themeGet('colors.primary')};
  box-shadow: ${themeGet('shadows.tile')};
  color: ${themeGet('colors.primary')};
  height: 100%;
  position: relative;
  text-align: center;

  ${system}
`;

const TileContentContainer = styled.div<SystemProps>`
  color: ${themeGet('colors.fg')};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 1;
`;

const TileFullscreen = styled.button<SystemProps>`
  background-color: transparent;
  border: 0;
  color: ${themeGet('colors.subdued')};
  cursor: pointer;
  position: absolute;
  right: ${themeGet('space.base')};
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease-in-out;

  &:active,
  &:focus,
  &:hover {
    color: currentColor;
    outline: none;
  }
`;

const TileOptions = styled.div<SystemProps>`
  font-size: ${themeGet('fontSizes.base')};
  font-weight: normal;
  margin-top: -2px;
  position: absolute;
  left: ${themeGet('space.base')};
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
`;

const TileHeader = styled.h2<{ floatingHeader?: boolean }>`
  font-size: ${themeGet('fontSizes.base')};
  margin-bottom: 0;
  padding-bottom: ${themeGet('space.base')};
  padding-top: ${themeGet('space.base')};
  position: relative;
  z-index: ${themeGet('zIndices.menu')};

  ${props =>
    props.floatingHeader &&
    css`
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 600;
    `};
`;

const TileContent = styled.div<{
  contentAlignment?: string;
  contentPadding?: string;
  overflowY?: string;
}>`
  align-items: ${props => props.contentAlignment || 'center'};
  display: grid;
  height: 100%;
  overflow-y: ${props => props.overflowY || 'auto'};
  padding: ${props => themeGet(`space.${props.contentPadding}`) || themeGet('space.l')};
`;

const TileContentInner = styled.div<{ contentAlignment?: string }>`
  align-items: ${props => props.contentAlignment || 'center'};
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const TileBackground = styled.div<SystemProps>`
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.25;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
`;

const TileTabs = styled(Tabs)<{ count: number; contentPadding?: string }>`
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 0;
  display: grid;
  grid-template-columns: repeat(${props => props.count}, 1fr);

  > *:not(:last-child) {
    border-right: 1px solid ${themeGet('colors.borderSolid')};
  }
`;

const TileTab = styled(Tab)<{ selected?: boolean; count?: number }>`
  border-radius: 0;
  color: ${themeGet('colors.neutralsGradient.4')};

  &:active,
  &:focus,
  &:hover {
    color: ${themeGet('colors.fg')};
    background-color: ${props =>
      props?.count > 1 ? themeGet('colors.neutralsGradient.10') : 'transparent'};
  }

  ${props =>
    props.selected &&
    css`
      background-color: ${props =>
        props?.count > 1 ? themeGet('colors.neutralsGradient.12') : 'transparent'};
      border-radius: 0;
      box-shadow: none;
      color: ${themeGet('colors.fg')};
      position: relative;

      &:active,
      &:focus,
      &:hover {
        color: ${themeGet('colors.fg')};
      }
    `}
`;

type TileTabContentProps = SystemProps & { hasTable?: boolean };

const TileTabContent = styled.div<TileTabContentProps>`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;

  ${props =>
    props.hasTable &&
    css`
      margin-top: -1px;
    `};

  ${system}
`;

export {
  Tile as default,
  TileContentContainer,
  TileHeader,
  TileContent,
  TileContentInner,
  TileBackground,
  TileFullscreen,
  TileOptions,
  TileTabs,
  TileTab,
  TileTabContent,
};
