import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const DividedList = styled.ul<SystemProps>`
  list-style-type: none;
  margin: 0;
  padding: 0;

  > *:first-child {
    border-top: 1px solid ${themeGet('colors.border')};
  }

  > * {
    border-bottom: 1px solid ${themeGet('colors.border')};
  }

  ${system}
`;

export default DividedList;
