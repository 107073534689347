import React, { useEffect } from 'react';

import { TILE_KEYS, TILES } from 'config/tiles';

import { showModal, updateModal, useModal } from 'providers/ModalProvider';
import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';
import Throughput from './Throughput';

export const ThroughputTile: React.FC = () => {
  const [optionsState, optionsDispatch] = useTileOptions();
  const [modalState, modalDispatch] = useModal();

  const {
    throughput: { filters },
  } = optionsState;

  const tileConfig = TILES?.find(tile => tile.title === 'Throughput');
  const headings = tileConfig?.headings || [];
  const additionalHeadings = tileConfig?.additionalHeadings || [];

  const modalProps = {
    title: 'Throughput',
    icon: { name: 'sliders' },
    large: true,
    children: (
      <RequiredPermission
        permission="read:throughput"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <Throughput
          grid="repeat(5, 1fr)"
          headings={[...headings, ...additionalHeadings]}
          isReverseSort={filters?.isReverseSort}
          sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
        />
      </RequiredPermission>
    ),
  };

  // Update modal data if needed
  useEffect(() => {
    const isActiveModal = modalState?.activeModal?.props?.title === 'Throughput';
    const needsUpdate = optionsState?.throughput?.filters?.needsUpdate;
    if (isActiveModal && needsUpdate) {
      modalDispatch(updateModal('Default', modalProps));
      optionsDispatch(
        updateOptions({
          throughput: {
            ...optionsState.throughput,
            filters: {
              ...optionsState.throughput.filters,
              needsUpdate: false,
            },
          },
        })
      );
    }
  }, [modalDispatch, modalState, modalProps, optionsDispatch, optionsState]);

  const isReverseSort =
    filters?.isReverseSort === undefined ? filters?.defaultIsReverseSort : filters?.isReverseSort;

  return (
    <Tile
      title="Throughput"
      icon="sliders"
      contentAlignment="top"
      contentPadding="0"
      modal="Default"
      modalProps={modalProps}
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Throughput Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.THROUGHPUT,
              })
            ),
        },
      ]}
    >
      <RequiredPermission
        permission="read:throughput"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <Throughput
          headings={headings}
          isReverseSort={isReverseSort}
          sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
        />
      </RequiredPermission>
    </Tile>
  );
};

export default ThroughputTile;
