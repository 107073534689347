import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
}

interface ManagerProps {
  renderComponent: Function;
}

export interface State {
  text?: string;
  type?: string;
}

interface Action {
  type: string;
  payload?: State;
}

// 3 seconds
const DURATION = 3000;

const initialState: State = {
  text: undefined,
  type: 'info',
};

const ToastStateContext = createContext<State>(initialState);
const ToastDispatchContext = createContext<any>(null);

const actionTypes = {
  show: 'show',
  hide: 'hide',
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case actionTypes.show: {
      return { ...action.payload };
    }
    case actionTypes.hide: {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const ToastProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>{children}</ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
};

function useToastState() {
  const context = useContext(ToastStateContext);
  if (context === undefined) {
    throw new Error(`useToastState must be used within a ToastProvider`);
  }
  return context;
}

function useToastDispatch() {
  const context = useContext(ToastDispatchContext);
  if (context === undefined) {
    throw new Error(`useToastDispatch must be used within a ToastProvider`);
  }
  return context;
}

function useToast() {
  const context = [useToastState(), useToastDispatch()];
  if (context === undefined) {
    throw new Error(`useToast must be used within a ToastProvider`);
  }
  return context;
}

const hideToast = (): Action => ({
  type: actionTypes.hide,
});

const showSuccess = (text: string): Action => ({
  type: actionTypes.show,
  payload: {
    type: 'success',
    text,
  },
});

const showError = (text: string): Action => ({
  type: actionTypes.show,
  payload: {
    type: 'error',
    text,
  },
});

const showWarning = (text: string): Action => ({
  type: actionTypes.show,
  payload: {
    type: 'warning',
    text,
  },
});

const ToastManager: React.FC<ManagerProps> = ({ renderComponent }) => {
  const [toast, dispatch] = useToast();
  const location = useLocation();
  const [active, setActive] = useState(toast.text !== undefined);

  // Whenever the location (page) changes, we want
  // to just hide the active toast (if present).
  useEffect(() => {
    if (active) dispatch(hideToast());
  }, [location, dispatch, active]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(hideToast());
      setActive(false);
    }, DURATION);
    return (): void => clearTimeout(timeout);
  }, [dispatch, toast]);

  if (!toast || !toast.text) return null;

  return renderComponent(toast);
};

export {
  ToastProvider as default,
  ToastManager,
  useToast,
  useToastState,
  useToastDispatch,
  actionTypes,
  hideToast,
  showSuccess,
  showError,
  showWarning,
};
