import Highcharts from 'highcharts';

import { TOOLTIP_HEADER_FORMAT } from 'statistics/charts/constants';

export default function getOptions(): Highcharts.Options {
  return {
    chart: {
      type: 'line',
    },
    plotOptions: {
      column: {
        stacking: undefined,
      },
    },
    tooltip: {
      headerFormat: TOOLTIP_HEADER_FORMAT,
    },
  };
}
