import { useSettingsState } from 'providers/SettingsProvider';
import { useUserData } from 'providers/UserDataProvider';

function useSettingsTabs() {
  const settingsState = useSettingsState();
  const { roles } = useUserData();
  const isAdmin = roles?.includes('Admin');

  const alertConfigOptions = Object.values(settingsState.alerts)
    .map((alert: any) => alert.active)
    .filter(Boolean);

  // We're only showing the `Alerts` tab in the Settings if the user
  // is an Admin and there are alerts to configure.
  const shouldShowAlertTab = isAdmin && alertConfigOptions.length > 0;

  return { shouldShowAlertTab };
}

export default useSettingsTabs;
