import React from 'react';

import { Icon } from 'components';
import { Props as IconProps } from 'components/Icon';

import { Box, Tag, TextLabel } from 'styled';
import { SystemProps } from 'styled/lib/types';

interface Props extends SystemProps {
  children?: React.ReactNode;
  contextLabel?: string;
  headingFontSize?: string;
  headingFontWeight?: string;
  label?: string;
  labelColor?: string;
  labelFontSize?: string;
  labelIcon?: IconProps;
  prefix?: string;
  prefixSpace?: string;
  suffix?: string;
  suffixSpace?: string;
}

interface SpanProps {
  children?: React.ReactNode;
  fontSize: string;
  ml?: string;
  mr?: string;
}

export const defaults = {
  headingFontSize: '4rem',
  headingFontWeight: 'bold',
  labelColor: 'subdued',
  labelFontSize: '2.75rem',
  prefixSpace: '0',
  suffixSpace: '0',
};

const Stat: React.FC<Props> = ({
  children,
  contextLabel,
  headingFontSize,
  headingFontWeight,
  label,
  labelColor,
  labelFontSize,
  labelIcon,
  prefix,
  prefixSpace,
  suffix,
  suffixSpace,
  ...rest
}: Props) => {
  return (
    <Box {...rest}>
      {label && (
        <TextLabel color={labelColor || defaults.labelColor}>
          {labelIcon && <Icon size="12" {...labelIcon} mr="xs" position="relative" top="-1px" />}
          {label}
        </TextLabel>
      )}
      <Box
        as="h3"
        fontSize={headingFontSize || defaults.headingFontSize}
        fontWeight={headingFontWeight || defaults.headingFontWeight}
        lineHeight="1"
      >
        {prefix && (
          <Span
            fontSize={labelFontSize || defaults.labelFontSize}
            mr={prefixSpace || defaults.prefixSpace}
          >
            {prefix}
          </Span>
        )}
        {children}
        {suffix && (
          <Span
            fontSize={labelFontSize || defaults.labelFontSize}
            ml={suffixSpace || defaults.suffixSpace}
          >
            {suffix}
          </Span>
        )}
      </Box>
      {contextLabel && <Tag display="inline-block">{contextLabel}</Tag>}
    </Box>
  );
};

const Span: React.FC<SpanProps> = ({ children, fontSize, ml, mr }: SpanProps) => (
  <Box as="span" color="subdued" fontSize={fontSize} ml={ml} mr={mr}>
    {children}
  </Box>
);

export default Stat;
