import React from 'react';

import { TILE_KEYS, TILES } from 'config/tiles';

import { showModal, useModalDispatch } from 'providers/ModalProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';
import Alerts from './Alerts';

export const AlertsTile: React.FC = () => {
  const modalDispatch = useModalDispatch();
  const tileConfig = TILES?.find(tile => tile.title === 'Alerts');
  const headings = tileConfig?.headings || [];

  return (
    <Tile
      title="Alerts"
      icon="bell"
      contentAlignment="top"
      contentPadding="0"
      modal="Alerts"
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Alerts Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.ALERTS,
              })
            ),
        },
      ]}
    >
      <RequiredPermission
        permission="read:alerts"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <Alerts headings={headings} />
      </RequiredPermission>
    </Tile>
  );
};

export default AlertsTile;
