import { StatItem, StatsArgs } from 'statistics/types';

import { STAT_TYPES } from './constants';

export default {
  [STAT_TYPES.BOOKINGS.AVERAGE]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Average Bookings',
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  }),
  [STAT_TYPES.BOOKINGS.HIGHEST]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Highest Bookings',
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  }),
  [STAT_TYPES.BOOKINGS.LOWEST]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Lowest Bookings',
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  }),
};
