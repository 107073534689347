import React, { useEffect, useRef, useState } from 'react';

import { parseBrowserInfo } from 'utils';

import { useSubmitUserFeedback } from 'client/UserFeedback';

import { useForm } from 'hooks';
import { track } from 'utils';

import { hideModal, useModalDispatch } from 'providers/ModalProvider';
import { showError, showSuccess, useToastDispatch } from 'providers/ToastProvider';
import { useUserData } from 'providers/UserDataProvider';

import { Button, Modal, TextInput } from 'components';

import { Box } from 'styled';

const FeedbackModal: React.FC = props => {
  const [status, setStatus] = useState('idle');
  const modalDispatch = useModalDispatch();
  const toastDispatch = useToastDispatch();
  const { name, email } = useUserData();
  const [submitUserFeedback] = useSubmitUserFeedback({
    onCompleted: () => {
      toastDispatch(showSuccess('Feedback submitted'));
      setStatus('success');
      modalDispatch(hideModal());
      track('Submit Feedback');
    },
    onError: () => {
      toastDispatch(showError('There was an error submitting the feedback'));
      setStatus('error');
    },
  });

  const { values, handleSubmit, handleChange } = useForm(async () => {
    setStatus('loading');

    const data = {
      ...values,
      name: name || 'No Name',
      email: email || 'No Email',
      browserInfo: parseBrowserInfo(window.navigator.userAgent),
      browserSize: `${window.innerWidth}x${window.innerHeight}`,
    };

    await submitUserFeedback({ variables: data });
  }, 'feedback');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (inputRef.current) {
      // Doing this because the `<Modal>` has an animation.
      timeout = setTimeout(() => inputRef?.current?.focus(), 500);
    }

    return (): void => clearTimeout(timeout);
  }, [inputRef]);

  const changeFn = (event: React.ChangeEvent<HTMLInputElement>): void => handleChange(event);
  const submitFn = (event: React.FormEvent): void => handleSubmit(event);

  return (
    <Modal title="Submit Feedback" icon={{ name: 'messageSquare' }} {...props}>
      <Box as="form" name="feedback" id="feedback" onSubmit={submitFn}>
        <Box as="p" fontSize="l" mb="base" pr="xl">
          <span role="img" aria-label="wave">
            👋
          </span>{' '}
          Thank you for taking the time to submit feedback!
        </Box>
        <Box as="p" fontSize="l" mb="l" pr="xl">
          This goes directly to the team working on the application. Please be{' '}
          <strong>specific</strong> and <strong>constructive</strong> and we will follow up via
          email if we have additional questions. Thank you!
        </Box>
        <Box mb="l">
          <TextInput
            as="textarea"
            name="feedback"
            value={values?.feedback || ''}
            onChange={changeFn}
            ref={inputRef}
            fontSize="base"
            rows="10"
            required
          />
        </Box>
        <Button loading={status === 'loading'} type="submit">
          Submit Feedback
        </Button>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
