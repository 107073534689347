import find from 'lodash/find';
import { useEffect } from 'react';

interface Key {
  key: number;
  action: Function;
}

function useKeys(keys: Key[]) {
  useEffect(() => {
    const handleKeyDown = (event: { keyCode: number }) => {
      const key = event.keyCode;
      const match = find(keys, { key });
      if (match) match.action();
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });
}

export default useKeys;
