import { gql, useQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { ArrivalsArgs, HistoricalArrivalFlightsQuery } from './types';

const HISTORICAL_ARRIVALS = gql`
  query HistoricalArrivals($between: [DateTime]!) {
    historicalArrivals(between: $between) {
      id
      actualArrivalDate
      arrivalDate
      arrivalDelay
      arrivalGate
      arrivalTime
      cancelled
      cargoCarrier
      diverted
      estimatedArrivalDate
      flightNumber
      isPassenger
      marketingCarrier
      netWeight
      origin
      scheduledArrivalDate
      statusText
    }
  }
`;

const now = new Date();
const start = startOfDay(now);
const end = endOfDay(now);

export default function useHistoricalArrivals({
  between = [start.getTime(), end.getTime()],
  refresh = true,
  skip = false,
}: ArrivalsArgs = {}): HistoricalArrivalFlightsQuery {
  const variables = pickBy(
    {
      between,
    },
    identity
  );

  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(HISTORICAL_ARRIVALS, options);
  const arrivals = query?.data?.historicalArrivals || [];

  return {
    arrivals,
    ...query,
  };
}
