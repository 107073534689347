// @ts-nocheck
import { SeriesOptionsType } from 'highcharts';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import merge from 'lodash/merge';

import { CHART_COLORS_ARRAY } from 'config/chartColors';

import { getDefaultSeriesOptions, getVisibility } from 'statistics/charts/utils';
import { CapacityChartAirline } from 'statistics/types';

const statuses: string[] = ['ON TIME', 'DELAYED', 'CANCELED', 'OTHER'];

export default function getSeries(
  data: CapacityChartAirline[],
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType[] {
  const cData: SeriesOptionsType[] = [];

  data.forEach(({ airline, data: chartData }) => {
    statuses.forEach((status, i) => {
      const name = airline === 'Total' ? '' : `${airline}`;

      const statusData = merge(getDefaultSeriesOptions(), {
        ...chartOptions,
        id: `${name}-${i}`,
        name: `${startCase(toLower(status))}`,
        data: chartData[status],
        yAxis: 'flights',
        stacking: 'normal',
        stack: name,
        borderWidth: 0,
        color: CHART_COLORS_ARRAY[i],
        opacity: 0.8,
        type: 'column',
        visible: getVisibility(name),
      } as SeriesOptionsType);

      // Add to front so "on time" can be on bottom
      cData.unshift(statusData);
    });
  });
  return cData;
}
