import { LatLng } from 'leaflet';
import React from 'react';
import { Polyline } from 'react-leaflet';

import { LINES } from 'config/airfield';
import theme from 'config/theme';

import { Line } from 'tiles/Airfield/utils';

import { usePopupWatcher } from 'providers/SelectedTableItemProvider';

import NotamPopup from './NotamPopup';

interface LineProps {
  line: Line;
  groupIdx: number;
  lineIdx: number;
}

const LineMarker: React.FC<LineProps> = ({ line, lineIdx, groupIdx }: LineProps) => {
  const airfield = LINES.find(L => L.code === line.code);
  const positions = line.positions.map(pos =>
    airfield?.direction === 'horizontal'
      ? new LatLng(pos.lat + 0.0001 * lineIdx, pos.lng)
      : new LatLng(pos.lat, pos.lng + 0.0001 * lineIdx)
  );
  const { ref } = usePopupWatcher(line?.notamId);

  return (
    <Polyline
      ref={ref}
      key={`${line.code}-${groupIdx}-${lineIdx}`}
      positions={positions}
      color={theme.colors[line.color] as string}
      weight={2}
      lineCap="butt"
    >
      <NotamPopup
        className={`leaflet-popup-${line.color}`}
        code={line.code}
        number={line.number}
        description={line.description}
        translation={line.translation}
      />
    </Polyline>
  );
};

export default LineMarker;
