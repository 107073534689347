import { SeriesOptionsType } from 'highcharts';

import { ChartDatum } from 'statistics/types';

import { getXAxis, getYAxis } from './axes';
import { default as getOptions } from './options';
import { default as getSeries } from './series';

export default {
  getXAxis,
  getYAxis,
  getOptions,
  getSeries: (
    data: ChartDatum[],
    chartOptions?: SeriesOptionsType,
    index?: number
  ): SeriesOptionsType => getSeries(data, chartOptions, index as number),
};
