import '@reach/tooltip/styles.css';

import ReachTooltip from '@reach/tooltip';
import React from 'react';

import { Box } from 'styled';

interface Props {
  label: string | JSX.Element;
  backgroundColor?: string;
}

const Tooltip: React.FC<Props> = ({ children, ...rest }) => {
  // If no tooltip label return just the children to prevent empty tooltips
  if (!rest?.label) return <Box as="span">{children}</Box>;

  return (
    <ReachTooltip {...rest}>
      <Box as="span">{children}</Box>
    </ReachTooltip>
  );
};

export default Tooltip;
