import { gql, useQuery } from '@apollo/client';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { TrafficFlowArgs, TrafficFlowQuery } from '../types';

const TRAFFIC_FLOW = gql`
  query TrafficFlow {
    trafficFlow {
      areaName
      timestamp
      routes {
        name
        toName
        fromName
        historicTime # normal travel time
        time # current travel time
        type
        line {
          x
          y
        }
      }
    }
  }
`;

export default function useTrafficFlow({ refresh = true }: TrafficFlowArgs = {}): TrafficFlowQuery {
  const options = {
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(TRAFFIC_FLOW, options);

  return {
    flow: query?.data?.trafficFlow || [],
    ...query,
  };
}
