import { gql, useQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { CargoRevenueArgs, CargoRevenueArrivalsQuery } from 'data/Cargo/types';

const CARGO_REVENUE_ARRIVALS = gql`
  query CargoRevenueArrivals($between: [DateTime]!) {
    cargoRevenueArrivals(between: $between) {
      airlines {
        airline
        operations
        weight
        cost
      }
      totalOperations
      totalWeight
      totalCost
    }
  }
`;

const now = new Date();
const start = startOfDay(now);
const end = endOfDay(now);

export default function useCargoRevenueArrivals({
  refresh = true,
  skip = false,
}: CargoRevenueArgs = {}): CargoRevenueArrivalsQuery {
  const variables = pickBy(
    {
      between: [start.getTime(), end.getTime()],
    },
    identity
  );

  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(CARGO_REVENUE_ARRIVALS, options);
  const data = query?.data?.cargoRevenueArrivals;
  return {
    airlines: data?.airlines,
    totalOperations: data?.totalOperations,
    totalWeight: data?.totalWeight,
    totalCost: data?.totalCost,
    ...query,
  };
}
