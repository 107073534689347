import {
  addDays,
  addMinutes,
  differenceInCalendarDays,
  endOfDay,
  format,
  startOfDay,
  startOfHour,
} from 'date-fns';
import range from 'lodash/range';
import upperCase from 'lodash/upperCase';

import { getAirline } from 'config/airlines';
import { DepartureFlightsData } from 'data/Flights/data';

import { CargoData, PassengerData } from 'utils/departures';
import { passengerFlights } from 'utils/departures/PassengerFlightData';

import { CANCELED } from 'data/Flights/constants';
import { DepartureFlight } from 'data/Flights/types';

import {
  filterByDayRange,
  filterByWeatherConditions,
  getFilteredChartData,
  getFilteredDataFromTime,
  isDaily,
} from 'statistics/charts/utils';
import { STAT_TYPES } from 'statistics/stats/constants';
import {
  DataObject,
  DeparturesChartAirline,
  DeparturesChartDatum,
  FilteredDepartureData,
  DepartureCounts,
  FlightTypeKey,
  StatsData,
} from 'statistics/types';
import { getQuarterHourMultiplier } from 'statistics/utils';

import { ProcessedDataObject } from './utils';

export function getFilteredData(
  startDate: Date,
  endDate: Date,
  departures: DepartureFlight[],
  destinationAirport = '',
  airline: string[] = [],
  flightType?: string
): FilteredDepartureData {
  let flightTypeData: PassengerData | CargoData | DepartureFlightsData | undefined;
  switch (flightType) {
    case 'passenger':
      flightTypeData = new PassengerData({
        flights: passengerFlights(departures, { type: 'departures' }),
      });
      break;
    case 'cargo':
      flightTypeData = new CargoData({ flights: departures });
      break;
    default:
      flightTypeData = new DepartureFlightsData({ flights: departures });
      break;
  }

  const flights = flightTypeData?.flights || [];
  const filteredData: FilteredDepartureData = { Total: [] };
  airline.forEach(a => {
    const _airline = getAirline(a);
    if (_airline) {
      filteredData[_airline.iata] = [];
    }
  });

  flights.forEach(flight => {
    if (flight.statusText === CANCELED) return false;
    const date = new Date(flight.scheduledDepartureDate);
    const withinTimeframe =
      date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime();
    const validAirport = destinationAirport ? flight.destination === destinationAirport : true;

    const flightAirlineCode = getAirline(flight.marketingCarrier)?.iata || 'N/A';

    // Flight has a valid airline if the airline code is in the filteredData object
    // Or there aren't any airlines specified in the filter
    const validAirline =
      (flightAirlineCode && flightAirlineCode in filteredData) || !airline.length;

    if (!validAirport || !validAirline || !withinTimeframe) return;

    if (airline.length > 1 && flightAirlineCode && flightAirlineCode in filteredData) {
      filteredData[flightAirlineCode].push(flight);
    }
    filteredData.Total.push(flight);
  });

  Object.entries(filteredData).forEach(([airlineCode, data]) => {
    if (!(airline.length > 1) && data.length === 0 && airlineCode !== 'Total')
      delete filteredData[airlineCode];
  });

  return filteredData;
}

export function getDepartureCounts(
  startDate: Date,
  endDate: Date,
  filteredData: DepartureFlight[]
): DepartureCounts {
  const timeCounts: DepartureCounts = {};

  const numDays = differenceInCalendarDays(endDate, startDate);

  let multiples = getQuarterHourMultiplier(startDate, endDate);
  let initializer = (multiple: number): void => {
    const time = new Date(startDate);
    time.setMinutes(time.getMinutes() + multiple * 15);
    timeCounts[time.getTime()] = {
      count: 0,
      flights: [],
    };
  };
  let aggregator = (flight: DepartureFlight): void => {
    const time = new Date(flight.scheduledDepartureDate);
    // If the flight departs within DEPARTURE_TIME_RANGE of a given time, add to that time's tally
    let timeSlot = startOfHour(time);
    if (time.getMinutes() >= 45) timeSlot = addMinutes(timeSlot, 45);
    if (time.getMinutes() >= 30) timeSlot = addMinutes(timeSlot, 30);
    if (time.getMinutes() >= 15) timeSlot = addMinutes(timeSlot, 15);
    const timeKey = timeSlot.getTime();
    if (timeCounts[timeKey] !== undefined) {
      timeCounts[timeKey].count += 1;
      timeCounts[timeKey].flights.push(flight);
    }
  };

  if (isDaily(startDate, endDate)) {
    multiples = range(numDays + 1);
    initializer = (multiple: number): void => {
      let time = startOfDay(startDate);
      time = addDays(time, multiple);
      timeCounts[time.getTime()] = {
        count: 0,
        flights: [],
      };
    };
    aggregator = (flight: DepartureFlight): void => {
      const time = startOfDay(new Date(flight.scheduledDepartureDate));
      const timeKey = time.getTime();

      if (timeCounts[timeKey] !== undefined) {
        timeCounts[timeKey].count += 1;
        timeCounts[timeKey].flights.push(flight);
      }
    };
  }

  multiples.forEach(initializer);

  filteredData.forEach(aggregator);

  return timeCounts;
}

type ProcessedDepartures = { [key: string]: number };

function getChartData(
  data: ProcessedDepartures,
  departureCounts: DepartureCounts
): DeparturesChartDatum[] {
  return Object.entries(data).map(([time, count]: [string, number]) => ({
    x: Number(time),
    y: count,
    flights: departureCounts[time]?.flights,
  }));
}

function getStatsData(data: ProcessedDepartures, flightType?: 'passenger' | 'cargo'): StatsData {
  const length = Object.keys(data).length;
  const { highest, sum } = Object.entries(data).reduce(
    (value, [key, count]: [string, number]) => {
      value.sum += count;
      if (count > value.highest.value) {
        value.highest.value = count;
        value.highest.context = format(new Date(Number(key)), 'LL/dd/yy');
      }
      return value;
    },
    { sum: 0, highest: { value: 0, context: '' } }
  );
  const average = Math.ceil(sum / length);

  const key: FlightTypeKey = flightType ? (upperCase(flightType) as FlightTypeKey) : 'PASSENGER';

  return {
    [STAT_TYPES.DEPARTURES[key].AVERAGE]: {
      value: isNaN(average) ? 0 : average,
      contextLabel: `All ${flightType} departures`,
    },
    [STAT_TYPES.DEPARTURES[key].HIGHEST]: { value: highest.value, contextLabel: highest.context },
  };
}

function getProcessedData(
  startDate: Date,
  endDate: Date,
  departuresData: DataObject,
  weatherData: DataObject,
  dateRangeDays: string[],
  destinationAirport?: string,
  airline?: string[],
  weather?: string[],
  flightType?: 'passenger' | 'cargo'
): ProcessedDataObject {
  const { loading, error, data: departures } = departuresData;
  const { loading: weatherLoading, error: weatherError, data: _weatherData } = weatherData;
  const hasWeatherDataAndLoading = weather && weather.length > 0 && weatherLoading;
  const hasWeatherDataAndError = weather && weather.length > 0 && weatherError;

  if (loading || error || hasWeatherDataAndLoading || hasWeatherDataAndError)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
      details: {
        loading,
        error,
        data: [],
      },
    };

  // TODO: This should be handled elsewhere!
  // We need to make sure the `endDate` is set to the end of the
  // day in terms of hours to avoid it being set to the same hour
  // as the `startDate`, which breaks when changing between days.
  const _endDate = endOfDay(endDate);

  const filteredData = getFilteredData(
    startDate,
    _endDate,
    departures as DepartureFlight[],
    destinationAirport,
    airline,
    flightType
  );

  const { byDayRange } = filterByDayRange(dateRangeDays);
  const { byWeatherConditions, filterChartDataByWeatherConditions } = filterByWeatherConditions(
    _weatherData,
    weather
  );
  const _filteredData: { [key: string]: DepartureFlight[] } = {};
  Object.keys(filteredData).forEach((key: string) => {
    _filteredData[key] = filteredData[key].filter((datum: DepartureFlight) =>
      byDayRange(datum.scheduledDepartureDate)
    );
  });

  let totalProcessedData: ProcessedDepartures = {};
  const chartData: DeparturesChartAirline[] = Object.entries(_filteredData).map(
    ([airlineCode, fd]) => {
      const departuresCounts = getDepartureCounts(startDate, _endDate, fd);
      const airlineData: ProcessedDepartures = {};
      const numDays = differenceInCalendarDays(_endDate, startDate);

      let multiples = getQuarterHourMultiplier(startDate, _endDate);
      let aggregator = (multiple: number): void => {
        const time = new Date(startDate);
        time.setMinutes(time.getMinutes() + multiple * 15);
        const timeKey = time.getTime();
        airlineData[time.getTime()] = departuresCounts[timeKey]?.count || 0;
      };

      if (isDaily(startDate, _endDate)) {
        multiples = range(numDays + 1);
        aggregator = (multiple: number): void => {
          let time = startOfDay(startDate);
          time = addDays(time, multiple);
          const timeKey = time.getTime();
          airlineData[timeKey] = departuresCounts[timeKey]?.count || 0;
        };
      }

      multiples.forEach(aggregator);

      const data = getChartData(airlineData, departuresCounts);

      if (airlineCode === 'Total') totalProcessedData = airlineData;

      return {
        airline: airlineCode,
        data: getFilteredChartData(data, dateRangeDays) as DeparturesChartDatum[],
      };
    }
  );

  const filteredChartData = chartData[0].data.filter((datum: DeparturesChartDatum) =>
    byWeatherConditions(datum.x)
  );
  const filteredStatsData = filterChartDataByWeatherConditions(
    getFilteredDataFromTime(totalProcessedData, dateRangeDays)
  );
  const filteredDetailsData = _filteredData.Total.filter(
    (datum: DepartureFlight) =>
      byDayRange(datum.scheduledDepartureDate) && byWeatherConditions(datum.scheduledDepartureDate)
  );

  // There's probably a better way to do this...
  chartData[0].data = filteredChartData;

  return {
    chart: {
      loading,
      error,
      data: chartData,
    },
    stats: {
      loading,
      error,
      data: getStatsData(filteredStatsData, flightType),
    },
    details: {
      loading,
      error,
      data: filteredDetailsData,
    },
  };
}

export default {
  passenger: {
    getProcessedData: (
      startDate: Date,
      endDate: Date,
      departures: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      destinationAirport?: string,
      airline?: string[],
      weather?: string[]
    ): ProcessedDataObject =>
      getProcessedData(
        startDate,
        endDate,
        departures,
        weatherData,
        dateRangeDays,
        destinationAirport,
        airline,
        weather,
        'passenger'
      ),
  },
  cargo: {
    getProcessedData: (
      startDate: Date,
      endDate: Date,
      departures: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      destinationAirport?: string,
      airline?: string[],
      weather?: string[]
    ): ProcessedDataObject =>
      getProcessedData(
        startDate,
        endDate,
        departures,
        weatherData,
        dateRangeDays,
        destinationAirport,
        airline,
        weather,
        'cargo'
      ),
  },
};
