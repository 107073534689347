import React, { PropsWithChildren, useEffect } from 'react';

import { setGlobalData } from 'utils/globalData';

import { useAirlines } from 'client/Airlines';
import { useAirports } from 'client/Airport';

import { CenteredLoader } from 'components/Loader';

const GlobalData: React.FC<PropsWithChildren<{}>> = (props: PropsWithChildren<{}>) => {
  const airportsQuery = useAirports({ filter: { noWeather: true } });
  const airports = airportsQuery?.data?.airports;

  const airlinesQuery = useAirlines();
  const airlines = airlinesQuery?.data?.airlines;

  useEffect(() => {
    setGlobalData('airports', airports || []);
  }, [airports]);

  useEffect(() => {
    setGlobalData('airlines', airlines || []);
  }, [airlines]);

  const loading = airportsQuery?.loading || airlinesQuery?.loading;

  return loading ? <CenteredLoader label="Getting global data..." /> : <>{props.children}</>;
};

export default GlobalData;
