import React, { useEffect } from 'react';

import { CapacityFlightsData } from 'data/Flights/data';
import { useDataLimiter } from 'hooks';

import { Tile } from 'components';
import { CapacityDetailsData, DetailsComponentProps } from 'components/ChartDetails';
import CapacityFlights from 'tiles/Capacity/CapacityFlights';

import DetailsNav from './DetailsNav';

const LIMIT = 10;

const CapacityDetails: React.FC<DetailsComponentProps> = ({ data }: DetailsComponentProps) => {
  const dataLimiter = useDataLimiter(LIMIT);
  const departures = (data as CapacityDetailsData)?.departures || [];

  useEffect(() => {
    const flightsData = new CapacityFlightsData({ flights: departures });
    dataLimiter.setData(flightsData?.flights);
  }, [departures, dataLimiter]);

  if (!data) return null;

  if (dataLimiter.status === 'loading') return null;

  return (
    <Tile
      title={`Capacity (${dataLimiter.resultsDescription})`}
      icon="userCheck"
      contentAlignment="top"
      contentPadding="0"
    >
      <CapacityFlights
        flights={dataLimiter.currentData}
        grid="1.5fr repeat(6, 1fr)"
        headings={[
          { key: 'city', label: 'City' },
          { key: 'flight', label: 'Flight' },
          { key: 'date', label: 'Date' },
          { key: 'time', label: 'Time' },
          { key: 'status', label: 'Status' },
          { key: 'capacity', label: 'Capacity' },
          { key: 'load', label: 'Load' },
        ]}
      />
      <DetailsNav dataLimiter={dataLimiter} />
    </Tile>
  );
};

export default CapacityDetails;
