import { format } from 'date-fns';

function getCargoDelayString(delayMinutes: number, newTime: number): string {
  return delayMinutes
    ? `Delayed +${delayMinutes} minute${delayMinutes === 1 ? '' : 's'} (${format(
        newTime,
        'h:mm a'
      )})`
    : '';
}

export default getCargoDelayString;
