// @ts-nocheck
import { addDays, differenceInCalendarDays, format, isSameDay, startOfDay } from 'date-fns';
import range from 'lodash/range';

import { CargoArrivalFlightData } from 'tiles/Cargo/details';
import { CargoData } from 'utils/arrivals';

import { CANCELED } from 'data/Flights/constants';
import { ArrivalFlight } from 'data/Flights/types';

import {
  filterByDayRange,
  filterByWeatherConditions,
  getFilteredDataFromTime,
} from 'statistics/charts/utils';
import { STAT_TYPES } from 'statistics/stats/constants';
import { ChartDatum, DataObject, StatsData } from 'statistics/types';

import { ProcessedDataObject } from './utils';

export function getFilteredData(
  startDate: Date,
  endDate: Date,
  arrivals: ArrivalFlight[],
  airline: string[] = [],
  departureAirport?: string
): ArrivalFlight[] {
  const filteredArrivals = arrivals.filter((flight): boolean => {
    if (flight.statusText === CANCELED) return false;
    const date = new Date(flight.actualArrivalDate || flight.scheduledDepartureDate);
    const withinTimeframe =
      date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime();
    const arrivalAirportCode = flight.destination;
    const validAirport = departureAirport ? arrivalAirportCode === departureAirport : true;
    const validAirline = airline.length > 0 ? airline.includes(flight.marketingCarrier) : true;

    return withinTimeframe && validAirport && validAirline;
  });

  return filteredArrivals;
}

export function getCargoData(
  startDate: Date,
  endDate: Date,
  arrivals: ArrivalFlight[]
): { [key: number]: CargoData } {
  const cargoFlightData = arrivals.map(d => new CargoArrivalFlightData({ flight: d }));
  const timeCounts: { [key: number]: CargoData } = {};

  const numDays = differenceInCalendarDays(endDate, startDate);
  const multiples = range(numDays + 1);

  multiples.forEach((multiple: number): void => {
    let time = startOfDay(startDate);
    time = addDays(time, multiple);
    const flights = cargoFlightData
      .filter(flight => isSameDay(flight.arrival, time))
      .map(flight => flight.flight);
    timeCounts[time.getTime()] = new CargoData({ flights });
  });

  return timeCounts;
}

function getChartData(data: { [key: number]: CargoData }): ChartDatum[] {
  return Object.entries(data).map(([time, cargoData]: [string, CargoData]) => ({
    x: Number(time),
    y: cargoData.totalCost,
  }));
}

function getStatsData(data: { [key: number]: CargoData }): StatsData {
  const length = Object.keys(data).length;
  const { lowest, highest, sum } = Object.entries(data).reduce(
    (value, [key, cargoData]: [string, CargoData]) => {
      const cost = cargoData.totalCost;
      value.sum += cost;
      if (cost > value.highest.value) {
        value.highest.value = cost;
        value.highest.context = format(new Date(Number(key)), 'LL/dd/yy');
      }
      if (cost < value.lowest.value) {
        value.lowest.value = cost;
        value.lowest.context = format(new Date(Number(key)), 'LL/dd/yy');
      }
      return value;
    },
    { sum: 0, highest: { value: -Infinity, context: '' }, lowest: { value: Infinity, context: '' } }
  );
  const average = Math.ceil(sum / length);

  return {
    [STAT_TYPES.CARGO_REVENUE.AVERAGE]: {
      value: isNaN(average) ? 0 : average,
      contextLabel: 'All cargo revenue',
    },
    [STAT_TYPES.CARGO_REVENUE.HIGHEST]: { value: highest.value, contextLabel: highest.context },
    [STAT_TYPES.CARGO_REVENUE.LOWEST]: { value: lowest.value, contextLabel: lowest.context },
  };
}

function getProcessedData(
  startDate: Date,
  endDate: Date,
  arrivalsData: DataObject,
  weatherData: DataObject,
  dateRangeDays: string[],
  departureAirport?: string,
  airline?: string[],
  weather?: string[]
): ProcessedDataObject {
  const { data: arrivals, error, loading } = arrivalsData;
  const { loading: weatherLoading, error: weatherError, data: _weatherData } = weatherData;
  const hasWeatherDataAndLoading = weather && weather.length > 0 && weatherLoading;
  const hasWeatherDataAndError = weather && weather.length > 0 && weatherError;

  if (loading || error || hasWeatherDataAndLoading || hasWeatherDataAndError)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
      details: {
        loading,
        error,
        data: [],
      },
    };
  const filteredData = getFilteredData(
    startDate,
    endDate,
    arrivals as ArrivalFlight[],
    airline,
    departureAirport
  );

  const { byDayRange } = filterByDayRange(dateRangeDays);
  const { byWeatherConditions, filterChartDataByWeatherConditions } = filterByWeatherConditions(
    _weatherData,
    weather
  );
  const cargoData = getCargoData(startDate, endDate, filteredData);
  const filteredCargoData = filterChartDataByWeatherConditions(
    getFilteredDataFromTime(cargoData, dateRangeDays)
  );
  const filteredDetailsData = filteredData.filter(
    (datum: ArrivalFlight) =>
      byDayRange(datum.scheduledArrivalDate) && byWeatherConditions(datum.scheduledArrivalDate)
  );

  return {
    chart: {
      loading,
      error,
      data: getChartData(filteredCargoData),
    },
    stats: {
      loading,
      error,
      data: getStatsData(filteredCargoData),
    },
    details: {
      loading,
      error,
      data: filteredDetailsData,
    },
  };
}

export default {
  getProcessedData,
};
