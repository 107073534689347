import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Card } from 'styled';

import system from './lib/system';
import { SystemProps } from './lib/types';

interface Props extends SystemProps {
  selected?: boolean;
}

const active =
  ({ selected }: Props) =>
  () => {
    if (selected) {
      return css`
        background-color: ${themeGet('colors.secondary')};
        border-color: transparent;
        box-shadow: ${themeGet('shadows.l')};
      `;
    }

    return css`
      background-color: transparent;
    `;
  };

const SelectionCard = styled(Card).attrs({
  as: 'button',
})<Props>`
  border: 1px solid ${themeGet('colors.border')};
  border-radius: ${themeGet('radii.l')};
  box-shadow: ${themeGet('shadows.base')};
  color: ${themeGet('colors.fg')};
  cursor: pointer;
  display: block;
  padding: ${themeGet('space.l')};
  position: relative;
  text-align: left;
  transition: 0.2s ease-in-out;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  ${active}
  ${system}
`;

export default SelectionCard;
