import Downshift from 'downshift';
import React, { useRef, useState } from 'react';

import { Icon, TextInput } from 'components';

import {
  SearchClearButton,
  SearchInput,
  SearchMenu,
  SearchMenuContainer,
  SearchMenuItem,
} from 'styled';
import { TextInputProps } from 'styled/TextInput';

type SearchItemType = { [key: string]: any } | string;

interface Props {
  data: SearchItemType[];
  inputValue: string;
  onClear?: Function;
  onChange?: Function;
  onSelect?: Function;
  matcherKeys: Array<string>;
  placeholder?: string;
  renderResult: Function;
  side?: string;
  resultsWidth?: string;
  disabled?: boolean;
  name?: string;
}

const Search: React.FC<Props> = ({
  data,
  renderResult,
  onSelect,
  onClear,
  onChange,
  inputValue,
  placeholder,
  matcherKeys,
  side,
  resultsWidth,
  disabled,
  name,
}: Props) => {
  const input = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  function matches(item: { [key: string]: string | string[] } | string, value: string): boolean {
    return matcherKeys.some(key => {
      const itemValue = typeof item === 'string' ? item : item[key];
      if (Array.isArray(itemValue)) {
        return itemValue.some(k => k.includes(value) || k.toLowerCase().includes(value));
      }
      return itemValue?.includes(value) || itemValue?.toLowerCase().includes(value);
    });
  }

  const handleClear = (event: React.MouseEvent): void => {
    if (event && event.preventDefault) event.preventDefault();
    setIsOpen(false);
    onClear && onClear();
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    onChange && onChange(event);
    value === '' ? setIsOpen(false) : setIsOpen(true);
  }

  function itemToString(item: { code: string }): string {
    if (!item) return '';
    if (Array.isArray(item.code)) return item.code.join(', ');
    return item.code;
  }

  return (
    <Downshift
      onSelect={selection => {
        setIsOpen(false);
        onSelect && onSelect(selection);
      }}
      itemToString={(item): string => itemToString(item)}
      defaultHighlightedIndex={0}
      inputValue={inputValue}
      isOpen={isOpen}
    >
      {downshift => (
        <SearchMenuContainer {...downshift.getRootProps()}>
          <SearchInput>
            <TextInput
              {...(downshift.getInputProps({
                value: inputValue,
                name,
                onChange: handleChange,
                placeholder: placeholder || 'Search...',
                disabled,
                icon: 'magnifyingGlass',
              }) as TextInputProps)}
              ref={input}
            />
            {downshift.inputValue !== '' && !disabled ? (
              <SearchClearButton onClick={handleClear}>
                <Icon name="x" size="16" />
              </SearchClearButton>
            ) : null}
          </SearchInput>
          {downshift.isOpen ? (
            <SearchMenu
              side={side}
              {...downshift.getMenuProps()}
              width={resultsWidth || '21.875rem'}
            >
              {data
                .filter(
                  (item: SearchItemType) =>
                    !downshift.inputValue || matches(item, downshift.inputValue)
                )
                .slice(0, 10)
                .map((item: SearchItemType, index: number) => (
                  <SearchMenuItem
                    key={index}
                    {...downshift.getItemProps({
                      key: `${typeof item === 'string' ? item : item.code}-${index}`,
                      index,
                      item,
                    })}
                    active={downshift.highlightedIndex === index}
                    as="li"
                    mb="base"
                  >
                    {renderResult && renderResult(item)}
                  </SearchMenuItem>
                ))}
            </SearchMenu>
          ) : null}
        </SearchMenuContainer>
      )}
    </Downshift>
  );
};

export default Search;
