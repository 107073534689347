import { SeriesOptionsType } from 'highcharts';
import { merge } from 'lodash';

import { PassengerChartDatum } from 'statistics/types';

import { getDefaultSeriesOptions, getVisibility } from '../utils';

export default function getSeries(
  name: string,
  data: PassengerChartDatum[],
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType {
  return merge(getDefaultSeriesOptions(), {
    ...chartOptions,
    data,
    name,
    colorByPoint: true,
    dataSorting: {
      enabled: true,
      matchByName: true,
    },
    showInLegend: false,
    borderWidth: 0,
    type: 'bar',
    visible: getVisibility(name),
  });
}
