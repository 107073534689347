import get from 'lodash/get';
import React from 'react';
import { CircleMarker } from 'react-leaflet';

import theme, { Colors } from 'config/theme';

interface DotProps {
  children: React.ReactNode;
  lat: number;
  lng: number;
  color: keyof Colors;
}

const Dot: React.FC<DotProps> = ({ children, lat, lng, color, ...rest }: DotProps) => {
  const _color = Array.isArray(color) ? color[0] : color;
  return (
    <CircleMarker
      center={[lat, lng]}
      stroke={false}
      color={get(theme.colors, _color)}
      fillOpacity={1}
      radius={4}
      {...rest}
    >
      {children}
    </CircleMarker>
  );
};

export default Dot;
