import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';
import TextLabel from './TextLabel';

type Props = { disabled?: boolean } & SystemProps;

const colors =
  ({ color }: Props) =>
  () => {
    if (color) {
      return css`
        border-color: ${themeGet(`colors.${String(color)}`)};
        color: ${themeGet(`colors.${String(color)}`)};
      `;
    }

    return css`
      border-color: ${themeGet('colors.subdued')};
      color: ${themeGet('colors.subdued')};
    `;
  };

const Tag = styled(TextLabel)<Props>`
  border: 1px solid transparent;
  border-radius: ${themeGet('radii.base')};
  display: inline;
  padding: ${themeGet('space.xs')} ${themeGet('space.s')};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  ${colors}
  ${system}
`;

export default Tag;
