const routes = {
  dashboard: '/',
  pulse: '/pulse',
  reports: '/reports',
  issues: {
    index: '/issues',
    new: '/issues/new',
    edit: '/issues/:id/edit',
  },
  callback: '/callback',
  login: '/login',
  logout: '/logout',
  error: '/error',
  unsubscribe: '/unsubscribe',
};

export default routes;
