import { ApolloError } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';

import { useSentry } from 'hooks';

import { BlankSlate, ErrorMessage, Loader } from 'components';

import { Box } from 'styled';

interface Props {
  content: string;
  data: object;
  error?: ApolloError;
  loading: boolean;
  renderError?: Function;
  renderLoading?: Function;
  renderEmpty?: Function;
}

const QueryCell: React.FC<Props> = ({
  content,
  data,
  error,
  loading,
  renderError,
  renderLoading,
  renderEmpty,
  children,
}) => {
  const { sendError } = useSentry();
  const noData = !data || isEmpty(data);
  if (loading && noData) return renderLoading ? renderLoading() : <DefaultLoader />;
  if (error) {
    sendError({ error, action: `Fetching ${content} data` });
    return renderError ? renderError() : <DefaultErrorMessage />;
  }
  if (noData) return renderEmpty ? renderEmpty() : <DefaultEmptyMessage content={content} />;
  return children;
};

const CenterContent: React.FC = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    height="100%"
    justifyContent="center"
    maxWidth="100%"
    p="l"
    width="100%"
  >
    {children}
  </Box>
);

const DefaultLoader: React.FC = () => <Loader />;

const DefaultErrorMessage: React.FC = () => (
  <CenterContent>
    <ErrorMessage maxWidth="100%" textAlign="center" title="Error" showActions={false}>
      <Box as="p">There was an error getting the data.</Box>
    </ErrorMessage>
  </CenterContent>
);

const DefaultErrorMessageWithError: React.FC<{ error: ApolloError; action: string }> = ({
  error,
  action,
}) => {
  const { sendError } = useSentry();

  useEffect(() => {
    if (error) sendError({ error, action });
  }, [error, action, sendError]);

  return <DefaultErrorMessage />;
};

const DefaultEmptyMessage: React.FC<{ content: string }> = ({ content }) => (
  <CenterContent>
    <BlankSlate
      maxWidth="100%"
      title={`No ${content}`}
      icon={{ name: 'magnifyingGlass', color: 'warning' }}
    >
      {`There is no ${content} data.`}
    </BlankSlate>
  </CenterContent>
);

export {
  QueryCell as default,
  DefaultLoader,
  DefaultErrorMessage,
  DefaultErrorMessageWithError,
  DefaultEmptyMessage,
};
