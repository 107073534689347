import Color from 'color';
import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';

import theme from 'config/theme';
import { getDefaultSeriesOptions, getVisibility } from 'statistics/charts/utils';

import { ChartDatum } from 'statistics/types';

export default function getSeries(
  data: ChartDatum[],
  setFrozenDate: (time: number) => void,
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType {
  return merge(getDefaultSeriesOptions(), {
    name: 'Passenger Projection',
    yAxis: 'passengers',
    data,
    borderWidth: 0,
    crisp: false,
    grouping: false,
    tooltip: {
      pointFormatter(): string {
        let { y } = this;
        const { series } = this;
        y = Math.ceil(y || 0);
        return `${series?.name}: <b>${y.toLocaleString()}</b><br/>`;
      },
    },
    point: {
      events: {
        click(): void {
          setFrozenDate(this.x);
        },
      },
    },
    dashStyle: 'Dot',
    fillColor: Color(theme?.colors?.success).fade(0.5).toString(),
    color: Color(theme?.colors?.success).fade(0.5).toString(),
    ...chartOptions,
    type: 'column',
    visible: getVisibility('Passenger Projection'),
  } as SeriesOptionsType);
}
