//@ts-nocheck
import sortBy from 'lodash/sortBy';
import React from 'react';

import { Table } from 'components';
import { Heading } from 'components/Table';

import { Box, TextLabel } from 'styled';

type CargoRevenueArrivalsResult = {
  airlines: CargoRevenue[];
  totalOperations: number;
  totalWeight: number;
  totalCost: number;
};

interface Props {
  data: CargoRevenueArrivalsResult;
  grid?: string;
  isReverseSort?: boolean;
  sortByColumn?: string;
  headings?: Heading[];
}

const CargoRevenueArrivalsTable: React.FC<Props> = ({
  data,
  grid,
  headings,
  isReverseSort,
  sortByColumn,
}: Props) => {
  function renderData() {
    const rowData = data.airlines.map((cargoRevenue: CargoRevenue) => {
      const weight = cargoRevenue.weight;
      const operations = cargoRevenue.operations;
      const cost = cargoRevenue.cost;
      return {
        airline: cargoRevenue.airline,
        operations,
        weight: weight.toLocaleString(),
        weightNum: weight,
        costNum: cost,
        cost: (
          <Box textAlign="left">
            $
            {cost.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),
      };
    });

    // Sort cargo revenue data
    let sortedData = sortBy(rowData, sortByColumn || 'airline');
    if (isReverseSort) sortedData = sortedData.reverse();

    const dataTotal = {
      airline: <TextLabel color="primary">Total</TextLabel>,
      operations: data.totalOperations,
      weight: data.totalWeight.toLocaleString(),
      cost: (
        <Box color="primary" fontWeight="bold" textAlign="left">
          $
          {data.totalCost.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </Box>
      ),
    };

    return [...sortedData, dataTotal];
  }

  const defaults = {
    grid: '1.25fr repeat(2, 1fr)',
    headings: [
      { key: 'airline', label: 'Airline' },
      { key: 'weight', label: 'Weight (lbs)', sortByKey: 'weightNum' },
      { key: 'cost', label: 'Revenue', sortByKey: 'costNum' },
    ],
  };

  return (
    <Table
      name="cargoRevenue"
      striped={false}
      bordered={true}
      cellPaddingX="s"
      grid={grid || defaults.grid}
      gridGap="0"
      headings={headings || defaults.headings}
      data={renderData()}
      dataFontSize="s"
    />
  );
};

export default CargoRevenueArrivalsTable;
