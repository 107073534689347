import omit from 'lodash/omit';
import React from 'react';

import { Box } from 'styled';
import { SystemProps } from 'styled/lib/types';

import Icon from './Icon';

export interface Props extends SystemProps {
  children: React.ReactNode;
  icon?: {
    name: string;
    color?: string;
    size?: string;
  };
  title?: string;
  maxWidth?: string;
}

const defaults = {
  maxWidth: '350px',
};

const BlankSlate: React.FC<Props> = (props: Props) => {
  return (
    <Box
      textAlign="center"
      maxWidth={props.maxWidth || defaults.maxWidth}
      mx="auto"
      {...omit(props, ['maxWidth'])}
    >
      {props.icon && props.icon.name && (
        <Icon
          name={props.icon.name || 'i'}
          size={props.icon.size || '40'}
          color={props.icon.color || 'primary'}
          mb="base"
        />
      )}
      {props.title && (
        <Box as="h1" fontWeight="bold">
          {props.title}
        </Box>
      )}
      <Box as="p" color="subdued" textAlign="center">
        {props.children}
      </Box>
    </Box>
  );
};

export default BlankSlate;
