import 'leaflet/dist/leaflet.css';

import React from 'react';
import { Polyline } from 'react-leaflet';

import theme from 'config/theme';

import { Route } from './types';
import { getDecoratedFlow } from './hooks/useTrafficDetails';

import { useTraffic } from 'providers/TrafficProvider';

import { UpdatedAt } from 'components';
import {
  DefaultEmptyMessage,
  DefaultErrorMessageWithError,
  DefaultLoader,
} from 'components/QueryCell';

import { Box } from 'styled';

import { TrafficMap } from './components';

function Traffic(): JSX.Element {
  const { loading, error, alerts, flow, refreshedAt } = useTraffic();

  const latitude = Number(process.env.REACT_APP_AIRPORT_LATITUDE) as number;
  const longitude = Number(process.env.REACT_APP_AIRPORT_LONGITUDE) as number;

  if (loading) return <DefaultLoader />;
  if (error) return <DefaultErrorMessageWithError error={error} action="useTraffic()" />;
  if (!alerts) return <DefaultEmptyMessage content="alerts" />;

  return (
    <Box position="relative">
      <TrafficMap center={[latitude, longitude]}>
        {getDecoratedFlow(flow)?.routes?.map((route: Route, i: number) => (
          <Polyline key={i} color={route.color} positions={route.latLngLine || []} />
        ))}
      </TrafficMap>
      <Box
        bottom="0"
        left="0"
        position="absolute"
        right="0"
        zIndex={theme.zIndices.modalOverlay + theme.zIndices.leaflet}
      >
        <UpdatedAt date={flow?.timestamp || refreshedAt} />
      </Box>
    </Box>
  );
}

export default Traffic;
