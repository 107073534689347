import { getXAxis, getYAxis } from './axes';
import { default as getOptions } from './options';
import { default as getSeries } from './series';

export default {
  getXAxis,
  getYAxis,
  getOptions,
  getSeries,
};
