import { format, parseISO } from 'date-fns';
import sortBy from 'lodash/sortBy';
import React from 'react';

import { useThroughputs } from 'data/Throughput';
import { Throughput as DailyThroughput } from 'data/Throughput/types';

import { useRefreshedAt } from 'hooks';

import { QueryCell, Table, UpdatedAt } from 'components';
import { Heading } from 'components/Table';

import { Box } from 'styled';

interface Props {
  grid?: string;
  headings?: Heading[];
  sortByColumn?: string;
  isReverseSort?: boolean;
}

interface Data {
  airport: string | JSX.Element;
  date: string;
  total: string;
  week: string;
  year: string;
}

const defaults = {
  grid: 'repeat(3, 1fr)',
  headings: [
    { key: 'airport', label: 'Airport' },
    { key: 'date', label: 'Date' },
    { key: 'total', label: 'Total' },
  ],
};

const Throughput: React.FC<Props> = ({ grid, headings, sortByColumn, isReverseSort }: Props) => {
  const { loading, error, throughputs } = useThroughputs({ filter: { latest: true } });
  const { refreshedAt } = useRefreshedAt(loading);

  function getData(): Data[] {
    const formatNumber = (n: number): string => new Intl.NumberFormat().format(n);

    // Sort throughput data
    let sortedData = sortBy(throughputs, sortByColumn || 'totalYesterday');
    if (isReverseSort) sortedData = sortedData.reverse();

    const airportCode = process.env.REACT_APP_AIRPORT_CODE || '';
    return sortedData.map((throughput: DailyThroughput) => ({
      airport:
        throughput.airport === airportCode ? (
          <Box as="span" color="primary" fontWeight="bold">
            {throughput.airport}
          </Box>
        ) : (
          throughput.airport
        ),
      date: format(parseISO(throughput.date), 'LL/dd/yy'),
      total: formatNumber(throughput.totalYesterday),
      week: formatNumber(throughput.totalOneWeekAgo),
      year: formatNumber(throughput.totalOneYearAgo),
    }));
  }

  return (
    <QueryCell content="daily throughput" loading={loading} error={error} data={throughputs}>
      <Table
        name="throughput"
        striped={false}
        bordered={true}
        dataFontSize="s"
        grid={grid || defaults.grid}
        headings={headings || defaults.headings}
        data={getData()}
      />
      <UpdatedAt
        date={refreshedAt}
        format={(date: Date, format: Function): string => format(new Date(date), 'LL/dd/y')}
      />
    </QueryCell>
  );
};

export default Throughput;
