import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { MAX_PAGE1_TILES } from 'config/tiles';

import { PAGINATION_BAR_HEIGHT } from '../components/PaginationBar';
import { HEADER_HEIGHT } from './Header';
import system from './lib/system';
import { SystemProps } from './lib/types';

type Props = SystemProps & {
  count: number;
  fullscreen: boolean;
  height?: number | string;
  dashboardMode?: boolean;
  pageIndex?: number;
};

const CONTENT_HEIGHT = '530px';
const MIN_TILE_HEIGHT = '275px';
const DASHBOARD_HEADER_HEIGHT = '16px';

const height =
  ({ height, fullscreen, dashboardMode, count, pageIndex = 0 }: Props) =>
  () => {
    if (height) {
      return css`
        height: ${height};
      `;
    }

    const contentHeight = pageIndex > 1 ? '0px' : CONTENT_HEIGHT;
    const _HEADER_HEIGHT = dashboardMode ? DASHBOARD_HEADER_HEIGHT : HEADER_HEIGHT;
    const _CONTENT_HEIGHT = contentHeight;

    function adjustItemHeights() {
      if (count === 6) {
        return css`
          > *:nth-child(1),
          > *:nth-child(2),
          > *:nth-child(3),
          > *:nth-child(4) {
            height: 100%;
          }
        `;
      }

      if (count === 7) {
        return css`
          > *:nth-child(1),
          > *:nth-child(2),
          > *:nth-child(3),
          > *:nth-child(4) {
            height: 100%;
          }
        `;
      }

      if (count === 8) {
        return css`
          > *:nth-child(1),
          > *:nth-child(2),
          > *:nth-child(3),
          > *:nth-child(4) {
            height: 100%;
          }
        `;
      }

      return ``;
    }

    if (!fullscreen) {
      // This is a little hacky, but it will allow the tiles to show full-height
      // when the app is loading, but will also not let them overlap the chart.

      if (pageIndex === 1) {
        return css`
          height: calc(100vh - ${_HEADER_HEIGHT} - ${_CONTENT_HEIGHT} - ${PAGINATION_BAR_HEIGHT});

          @media screen and (min-width: ${themeGet('breakpoints.lg')}) {
            margin: 0;
            ${adjustItemHeights()}
          }
        `;
      }

      // We need to double the `theme.m` value to get non-Page-1 pages
      // to be spaced correctly.
      return css`
        height: calc(
          100vh - ${_HEADER_HEIGHT} - ${_CONTENT_HEIGHT} - ${PAGINATION_BAR_HEIGHT} -
            ${themeGet('space.m')} - ${themeGet('space.m')}
        );

        @media screen and (min-width: ${themeGet('breakpoints.lg')}) {
          ${adjustItemHeights()}
        }
      `;
    }

    const heightMultiplier = count <= MAX_PAGE1_TILES ? 1 : count > 12 ? 2 : 1.5;
    return css`
      height: calc((100vh - ${_HEADER_HEIGHT} - ${themeGet('space.s')}) * ${heightMultiplier});
    `;
  };

const size =
  ({ height, count }: Props) =>
  () => {
    const adjustedHeight = height || MIN_TILE_HEIGHT;

    switch (count) {
      case 1:
        return css`
          grid-template-columns: 1fr;
          grid-template-rows: minmax(${adjustedHeight}, 100%);
        `;
      case 2:
        return css`
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: minmax(${adjustedHeight}, 100%);
        `;
      case 3:
        return css`
          grid-template-columns: repeat(2, 1fr) 2fr;
          grid-template-rows: minmax(${adjustedHeight}, 100%);
        `;
      case 4:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: minmax(${adjustedHeight}, 100%);
        `;
      case 5:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(2, minmax(${adjustedHeight}, 100%));
          > *:last-child {
            grid-column: span 4;
          }
        `;
      case 6:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(2, minmax(${adjustedHeight}, 100%));
          > *:nth-child(5),
          > *:nth-child(6) {
            grid-column: span 2;
          }
        `;
      case 7:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(2, minmax(${adjustedHeight}, 100%));
          > *:last-child {
            grid-column: span 2;
          }
        `;
      case 8:
        return css`
          grid-template-columns: repeat(4, minmax(0, 1fr));
          grid-template-rows: repeat(2, minmax(${adjustedHeight}, 100%));
        `;
      case 9:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(3, minmax(${adjustedHeight}, 100%));
          > *:last-child {
            grid-column: span 4;
          }
        `;
      case 10:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(3, minmax(${adjustedHeight}, 100%));
          > *:nth-child(9),
          > *:nth-child(10) {
            grid-column: span 2;
          }
        `;
      case 11:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(3, minmax(${adjustedHeight}, 100%));
          > *:last-child {
            grid-column: span 2;
          }
        `;
      case 12:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(3, minmax(${adjustedHeight}, 100%));
        `;
      case 13:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(4, minmax(${adjustedHeight}, 100%));
          > *:last-child {
            grid-column: span 4;
          }
        `;
      case 14:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(4, minmax(${adjustedHeight}, 100%));
          > *:nth-child(13),
          > *:nth-child(14) {
            grid-column: span 2;
          }
        `;
      case 15:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(4, minmax(${adjustedHeight}, 100%));
          > *:last-child {
            grid-column: span 2;
          }
        `;
      case 16:
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(4, minmax(${adjustedHeight}, 100%));
        `;
      default: {
        return css`
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(2, minmax(${adjustedHeight}, 100%));
        `;
      }
    }
  };

const TileGrid = styled.div<Props>`
  display: grid;
  grid-column-gap: ${themeGet('space.m')};
  grid-row-gap: ${themeGet('space.m')};
  margin-top: ${themeGet('space.m')};

  ${height}
  ${size}
  ${system}
`;

export default TileGrid;
