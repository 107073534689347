import { format, isValid } from 'date-fns';
import React, { useEffect, useState } from 'react';

import theme from 'config/theme';

import { getDelayTimeString, toLocaleCurrency } from 'utils';

import { useAirports } from 'client/Airport';
import { CANCELED, COMPLETED, DELAYED, DIVERTED, ON_TIME } from 'data/Flights/constants';
import { ArrivalFlight } from 'data/Flights/types';

import { BlankSlate, Table } from 'components';
import { Props as IconProps } from 'components/Icon';
import { Heading } from 'components/Table';

import { Box } from 'styled';

import { FlightCity, FlightStatusText } from 'data/Flights/components';

export interface Props {
  flights: ArrivalFlight[];
  configType?: string;
  filter?: Function;
  grid?: string;
  headings?: Heading[];
  renderData?: Function;
  limit?: number;
  filterEmptyMessage?: string;
  filterBlankSlateIcon?: IconProps;
  showEmptyMessage?: boolean;
}

const defaults = {
  headings: [
    { key: 'city', label: 'City' },
    { key: 'flightNumber', label: 'Flight' },
    { key: 'time', label: 'Time' },
    { key: 'status', label: 'Status' },
  ],
  grid: '2fr repeat(3, 1fr)',
  filterEmptyMessage: 'There is currently no data.',
  filterBlankSlateIcon: { name: 'magnifyingGlass', color: 'warning', size: '30' },
};

const Flights: React.FC<Props> = ({
  flights,
  configType,
  filter,
  grid,
  headings,
  renderData,
  limit,
  filterEmptyMessage,
  filterBlankSlateIcon,
  showEmptyMessage = true,
}: Props) => {
  const [filteredFlights, setFilteredFlights] = useState<ArrivalFlight[]>([]);
  const { data: airportsQueryData } = useAirports({ filter: { noWeather: true } });
  const airports = airportsQueryData?.airports || [];

  function getNumberToShow(flights: ArrivalFlight[]): number {
    return limit || flights.length;
  }

  // !NOTE: For paginated data, e.g. from Details components, this will make some pages shorter than others
  // TODO: Figure out how to retain page length for pagination while still using filter

  useEffect(() => {
    let stillMounted = true;

    (async () => {
      const _filteredFlights = filter ? flights.filter(flight => filter({ flight })) : flights;
      if (stillMounted) {
        setFilteredFlights(_filteredFlights);
      }
    })();

    return () => {
      stillMounted = false;
    };
  }, [flights, filter]);

  if (filteredFlights.length === 0 && showEmptyMessage)
    return (
      <BlankSlate
        display="flex"
        flexDirection="column"
        justifyContent="center"
        icon={filterBlankSlateIcon || defaults.filterBlankSlateIcon}
      >
        {filterEmptyMessage || defaults.filterEmptyMessage}
      </BlankSlate>
    );

  return (
    <Table
      name={configType || 'arrivals'}
      grid={grid || defaults.grid}
      striped={false}
      bordered={true}
      cellPaddingX="s"
      cellPaddingY="s"
      dataFontSize="s"
      gridGap="xs"
      headings={headings || defaults.headings}
      data={filteredFlights.slice(0, getNumberToShow(flights)).map(flight => {
        const delayMinutes = flight?.arrivalDelay;
        const scheduledTime = flight?.scheduledArrivalDate;
        const statusText = flight?.statusText;
        const delayTime = getDelayTimeString(delayMinutes, scheduledTime);
        const airportCode = flight?.origin;
        const delayed = statusText === DELAYED;
        const gate = flight?.arrivalGate;
        const time = flight?.arrivalTime;
        const date = flight?.arrivalDate;

        let statusColor = theme.colors.subdued;
        if (statusText === CANCELED || statusText === DIVERTED) {
          statusColor = theme.colors.error;
        }
        if (statusText === DELAYED) {
          statusColor = theme.colors.warning;
        }
        if (statusText === ON_TIME) {
          statusColor = theme.colors.success;
        }

        const isCompleted = statusText === COMPLETED;

        const returnData = {
          city: (
            <FlightCity
              flight={flight}
              airportCode={airportCode}
              airports={airports}
              statusColor={statusColor === theme.colors.subdued ? statusColor : null}
            />
          ),
          flight: (
            <Box as="span" color={isCompleted ? statusColor : theme.colors.fg}>
              {flight.flightNumber}
            </Box>
          ),
          date: isValid(date) ? format(date, 'LL/dd/yy') : 'N/A',
          time: (
            <Box as="span" color={statusColor}>
              {time}
            </Box>
          ),
          flightNumber: flight?.flightNumber || '',
          netWeight: flight?.netWeight ? parseInt(flight?.netWeight).toLocaleString() : '',
          cargoCarrier: flight?.cargoCarrier?.toUpperCase(),
          gate: (
            <Box as="span" color={isCompleted ? statusColor : theme.colors.fg}>
              {gate}
            </Box>
          ),
          status: (
            <FlightStatusText
              delayed={delayed}
              delayMinutes={delayMinutes}
              delayTime={delayTime}
              statusColor={statusColor}
              statusText={statusText}
            />
          ),
          weight: (
            <Box as="span" color={isCompleted ? statusColor : theme.colors.fg}>
              {flight?.weight?.toLocaleString()}
            </Box>
          ),
          cost: flight?.cost ? toLocaleCurrency(flight.cost) : 'N/A',
          aircraft: (
            <Box as="span" color={isCompleted ? statusColor : theme.colors.fg}>
              {flight?.manufacturer && flight?.makeModel
                ? `${flight?.manufacturer} ${flight?.makeModel}`
                : ''}
            </Box>
          ),
        };

        if (renderData) {
          return {
            ...returnData,
            ...renderData(flight),
          };
        }

        return returnData;
      })}
    />
  );
};

export default Flights;
