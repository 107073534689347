import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const UnstyledLink = styled.a<SystemProps>`
  text-decoration: none;

  ${system}
`;

export default UnstyledLink;
