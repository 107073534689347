import React from 'react';

import { TILE_KEYS } from 'config/tiles';

import { showModal, useModalDispatch } from 'providers/ModalProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';
import { Sales } from 'components/Tiles';

export const SalesTile: React.FC = () => {
  const modalDispatch = useModalDispatch();
  if (process.env.REACT_APP_CONFIG_SALES !== 'true') return null;
  return (
    <Tile
      title="Sales"
      icon="dollarSign"
      contentAlignment="top"
      contentPadding="0"
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Sales Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.SALES,
              })
            ),
        },
      ]}
    >
      <RequiredPermission permission="read:sales" renderFallback={() => <NotAvailableBlankSlate />}>
        <Sales />
      </RequiredPermission>
    </Tile>
  );
};

export default SalesTile;
