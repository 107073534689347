export function toLocaleCurrency(amount = 0): string {
  let _amount = amount;
  if (amount === null) _amount = 0;
  return _amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}

export function getCents(amount: number): string {
  return `.${toLocaleCurrency(amount).split('.')[1]}`;
}

export function getDollars(amount: number): string {
  return toLocaleCurrency(amount).split('.')[0];
}
