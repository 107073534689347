// @ts-nocheck
import { ArrivalFlight } from 'data/Flights/types';

interface ConstructorArgs {
  flights: ArrivalFlight[];
}

class ArrivalFlightsData {
  flights: ArrivalFlight[];

  constructor({ flights }: ConstructorArgs) {
    this.flights = flights;
  }

  get isArrivals(): boolean {
    return false;
  }

  get isDepartures(): boolean {
    return true;
  }
}

export default ArrivalFlightsData;
