import { Listbox } from '@headlessui/react';
import React, { useState } from 'react';

import { Box, ListboxButton, ListboxOption, ListboxOptions } from 'styled';

import Icon from './Icon';

interface Option {
  value: string;
  label: string;
}

interface Props {
  options: Option[];
  selectedOptions?: Option;
  disabled?: boolean;
  onChange?: Function;
  maxSelections?: number;
  multiple?: boolean;
}

const MAX_SELECTIONS = 4;

const ListBox: React.FC<Props> = ({
  options = [],
  onChange,
  disabled,
  maxSelections = MAX_SELECTIONS,
  multiple,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  return (
    <Listbox
      value={selectedOptions}
      onChange={value => {
        if (!Array.isArray(value)) {
          setSelectedOptions(value);
          onChange && onChange(value);
        } else {
          if (selectedOptions.length < maxSelections) {
            setSelectedOptions(value);
            onChange && onChange(value);
          } else {
            if (value.length < selectedOptions.length) {
              setSelectedOptions(value);
              onChange && onChange(value);
            }
          }
        }
      }}
      disabled={disabled}
      as={Box}
      multiple={multiple}
    >
      <Listbox.Button as={ListboxButton}>
        <span>
          {(Array.isArray(selectedOptions) &&
            selectedOptions.length > 0 &&
            selectedOptions.join(', ')) ||
            (selectedOptions.length > 0 &&
              options.find(option => option.value === selectedOptions.toString())?.label) ||
            'Select...'}
        </span>
      </Listbox.Button>
      <Listbox.Options as={ListboxOptions}>
        {options.map((option, index) => (
          <Listbox.Option as={ListboxOption} key={index} value={option?.value}>
            {({ selected }) => (
              <>
                {selected ? (
                  <>
                    <Box as="span">
                      <Icon name="check" size="12" mr="xs" color="fg" />
                    </Box>
                    <Box as="span" color="fg">
                      {option?.label}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box width="12px" height="12px" mr="xs"></Box>
                    <Box as="span">{option?.label}</Box>
                  </>
                )}
              </>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

export default ListBox;
