import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import { version } from '../../package.json';

function initializeSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: process.env.REACT_APP_ENV || 'development',
    release: version,
  });
  Sentry.setTag('airport', process.env.REACT_APP_AIRPORT_CODE);
}

export default initializeSentry;
