import React from 'react';

import { CANCELED, COMPLETED, DELAYED, ON_TIME } from 'data/Flights/constants';
import { DepartureFlight } from 'data/Flights/types';

import { showModal, useModalDispatch } from 'providers/ModalProvider';
import { useTileOptionsState } from 'providers/TileOptionsProvider';

import { Stat } from 'components';

import { Box, UnstyledLink } from 'styled';

import { getDepartureSummary } from '../utils';

interface Props {
  flights: DepartureFlight[];
  filter?: Function;
}

const Departures: React.FC<Props> = ({ flights, filter }: Props) => {
  const modalDispatch = useModalDispatch();
  const tileOptionsState = useTileOptionsState();
  const filteredFlights = filter ? flights?.filter(flight => filter(flight)) : flights;
  const { total, completed, delayed, canceled, remaining } = getDepartureSummary(filteredFlights);
  const filterTypes = tileOptionsState?.flightSummary?.filters.types;
  const initialType = filterTypes.length === 2 ? 'All' : filterTypes[0];

  const handleClick =
    (status: string) =>
    (event: React.MouseEvent<HTMLAnchorElement>): void => {
      event.preventDefault();
      modalDispatch(
        showModal('Departures', { stateKey: 'departures', initialType, initialStatus: status })
      );
    };

  return (
    <Box
      alignItems="center"
      display="grid"
      justifyContent="center"
      gridTemplateColumns={{ _: 'repeat(4, 1fr)', lg: 'repeat(2, 1fr)' }}
      m="base"
      // Setting a `maxHeight` for when the tile is stretched to the
      // full height of the screen and makes the content unreadable.
      maxHeight="400px"
    >
      <UnstyledLink
        as="a"
        href="#0"
        display="block"
        mb={{ lg: 'l' }}
        gridColumn={{ _: 'span 4', lg: 'span 2' }}
        onClick={handleClick('All')}
      >
        <Stat label="Total" labelColor="subdued" labelIcon={{ name: 'arrowUpAndRight' }}>
          {total}
        </Stat>
      </UnstyledLink>
      <UnstyledLink
        as="a"
        href="#0"
        display="block"
        mb={{ lg: 'l' }}
        onClick={handleClick(COMPLETED)}
      >
        <Stat label="Completed" labelColor="success" labelIcon={{ name: 'check' }}>
          {completed}
        </Stat>
      </UnstyledLink>
      <UnstyledLink
        as="a"
        href="#0"
        display="block"
        mb={{ lg: 'l' }}
        onClick={handleClick(DELAYED)}
      >
        <Stat label="Delayed" labelColor="warning" labelIcon={{ name: 'i' }}>
          {delayed}
        </Stat>
      </UnstyledLink>
      <UnstyledLink as="a" href="#0" display="block" onClick={handleClick(CANCELED)}>
        <Stat label="Canceled" labelColor="error" labelIcon={{ name: 'xCircle' }}>
          {canceled}
        </Stat>
      </UnstyledLink>
      <UnstyledLink as="a" href="#0" display="block" onClick={handleClick(ON_TIME)}>
        <Stat label="Remaining" labelColor="subdued" labelIcon={{ name: 'clock' }}>
          {remaining}
        </Stat>
      </UnstyledLink>
    </Box>
  );
};

export default Departures;
