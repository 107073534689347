import { divIcon, DivIcon, LatLngBoundsLiteral, MarkerCluster, point } from 'leaflet';
import flatten from 'lodash/flatten';
import React from 'react';
import { LayerGroup, Map as LeafletMap, Polyline, Rectangle, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import { LINES } from 'config/airfield';
import theme from 'config/theme';

import { Line, Notam, Point } from 'tiles/Airfield/utils';

import { PavementTemperatures } from 'components';

import { Box } from 'styled';

import Dot from './Dot';
import LineMarker from './LineMarker';

interface MapProps {
  notams: Notam[];
}

type LineGroup = {
  code: string;
  lines: Line[];
};

const NotamsMap: React.FC<MapProps> = ({ notams }: MapProps) => {
  if (process.env.REACT_APP_CONFIG_NOTAMS !== 'true') return null;
  const options = {
    lat: Number(process.env.REACT_APP_AIRPORT_LATITUDE) as number,
    lng: Number(process.env.REACT_APP_AIRPORT_LONGITUDE) as number,
  };

  const points = flatten(
    notams.map(notam => {
      const p = notam.points;
      p.forEach(pt => (pt.notamId = notam.id));
      return p;
    })
  );
  const lines = flatten(
    notams.map(notam => {
      const l = notam.lines;
      l.forEach(ln => (ln.notamId = notam.id));
      return l;
    })
  );
  const groupedLines: LineGroup[] = [];
  lines.forEach(line => {
    let group = groupedLines.find(gl => gl.code === line.code);
    if (!group) {
      group = { lines: [], code: line.code };
      groupedLines.push(group);
    }

    group.lines.push(line);
  });

  const rectangleBounds: LatLngBoundsLiteral = [
    [options.lat - 1000, options.lng - 1000],
    [options.lat + 1000, options.lng + 1000],
  ];

  return (
    <Box height="100%" position="relative" zIndex={0}>
      <LeafletMap
        center={[options.lat, options.lng]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
        minZoom={13}
      >
        <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png" />
        <Rectangle
          bounds={rectangleBounds}
          fillColor={theme.colors.notamsOverlay}
          fillOpacity={0.3}
          stroke={false}
        />
        {LINES.map(line => {
          const isRunway = line.code.startsWith('RWY');
          const width = isRunway ? 6 : 2;

          return (
            <Polyline
              key={line.code}
              positions={line.positions}
              color={theme?.colors?.bgDark}
              weight={width}
              lineCap="butt"
            />
          );
        })}
        {groupedLines.map((group: LineGroup, i: number) => (
          <LayerGroup key={`${group.code}-${i}`}>
            {group.lines.map((l: Line, j: number) => (
              <LineMarker key={j} groupIdx={i} line={l} lineIdx={j} />
            ))}
          </LayerGroup>
        ))}
        <MarkerClusterGroup
          maxClusterRadius={13}
          iconCreateFunction={(cluster: MarkerCluster): DivIcon => {
            const markers = cluster.getAllChildMarkers();
            //@ts-expect-error
            const color = markers.find(m => m.options.color === 'error') ? 'error' : 'warning';
            return divIcon({
              html: `${markers.length}`,
              className: `leaflet-notams-cluster leaflet-notams-cluster-${color}`,
              iconSize: point(20, 20),
            });
          }}
        >
          {points.map((p: Point, i: number) => {
            return <Dot key={i} p={p} i={i} />;
          })}
        </MarkerClusterGroup>
        <PavementTemperatures />
      </LeafletMap>
    </Box>
  );
};

export default NotamsMap;
