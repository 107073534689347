import { themeGet } from '@styled-system/theme-get';
import Color from 'color';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body {
    height: 100%;
  }

  body {
    background-color: ${themeGet(`colors.bg`)};
    color: ${themeGet('colors.fg')};
    font-family: ${themeGet('fontFamily')};
    font-size: ${themeGet('fontSizes.base')};
    line-height: ${themeGet('lineHeights.base')};
    overflow-x: hidden;
  }

  /* The emerging W3C standard
   that is currently Firefox-only */
  * {
    scrollbar-width: thin;
    scrollbar-color: ${themeGet('colors.neutralsGradient.8')} transparent;
  }

  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 3px solid ${themeGet('colors.neutralsGradient.8')};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${themeGet('fontFamily')};
    line-height: ${themeGet('lineHeights.heading')};
    margin: 0;
    margin-bottom: ${themeGet('space.s')};
  }
  h1 {
    font-size: ${themeGet('fontSizes.h1')};
    font-weight: ${themeGet('fontWeights.normal')};
  }
  h2 {
    font-size: ${themeGet('fontSizes.h2')};
    font-weight: ${themeGet('fontWeights.semibold')};
  }
  h3 {
    font-size: ${themeGet('fontSizes.h3')};
  }
  h4 {
    font-size: ${themeGet('fontSizes.h4')};
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  a {
    color: ${themeGet('colors.fg')};
  }
  a:focus,
  a:hover,
  a:active {
    text-decoration: none;
  }

  ::-moz-selection { background-color: ${props =>
    Color(themeGet('colors.primary')(props)).fade(0.75).toString()}; }
  ::selection { background: ${props =>
    Color(themeGet('colors.primary')(props)).fade(0.75).toString()}; }

  ::placeholder {
    color: ${themeGet('colors.subdued')};
  }

  /* NOTE: The <button> is not inheriting the <body> font-family for some reason. */
  button {
    font-family: ${themeGet('fontFamily')};
  }

  [data-reach-tooltip] {
    background-color: ${themeGet('colors.fg')};
    border-radius: ${themeGet('radii.base')};
    border: 0;
    z-index: ${themeGet('zIndices.tooltip')};
  }

  .leaflet-container {
    height: 100%;
    position: relative;
    width: 100%;
  }

  .leaflet-popup-content-wrapper {
    border-radius: ${themeGet('radii.base')};
  }

  .leaflet-popup-error {
    border: 2px solid ${themeGet('colors.error')};
    border-radius: ${themeGet('radii.base')};
  }

  .leaflet-popup-warning {
    border: 2px solid ${themeGet('colors.warning')};
    border-radius: ${themeGet('radii.base')};
  }

  .leaflet-popup-success {
    border: 2px solid ${themeGet('colors.success')};
    border-radius: ${themeGet('radii.base')};
  }

  .leaflet-notams-cluster {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    text-align: center;
  }

  .leaflet-notams-cluster-error {
    border: 2px solid ${themeGet('colors.error')};
  }

  .leaflet-notams-cluster-warning {
    border: 2px solid ${themeGet('colors.warning')};
  }

  .leaflet-tooltip-label-small {
    background-color: transparent;
    border: transparent;
    box-shadow: none;
    color: ${themeGet('colors.fg')};
    font-weight: ${themeGet('fontWeights.bold')};
    font-size: ${themeGet('fontSizes.xs')};
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  }

  .leaflet-tooltip-label {
    background-color: transparent;
    border: transparent;
    box-shadow: none;
    color: ${themeGet('colors.fg')};
    font-weight: ${themeGet('fontWeights.bold')};
    font-size: ${themeGet('fontSizes.s')};
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  }
  .leaflet-tooltip-label::before {
    display: none;
  }

  ul.tiles {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .media-grid-image {
    object-fit: cover !important;
    height: 100% !important;
    width: 100% !important;
  }
  
  .react-joyride__tooltip > div:nth-child(1) > h4 {
    text-align: start !important;
  }
  .react-joyride__spotlight {
    padding: 16px;
    border: 2px solid #74E567 !important;
    border-radius: 4px;
    transform: scale(1.05);
  }
  `;

export default GlobalStyles;
