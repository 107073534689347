import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { HEADER_HEIGHT } from './Header';
import system from './lib/system';
import { SystemProps } from './lib/types';
import { PAGINATION_BAR_HEIGHT } from './PaginationBar';
import { SIDEBAR_WIDTH } from './Sidebar';

const Layout = styled.div<SystemProps & { dashboardMode?: boolean }>`
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: ${props => (props.dashboardMode ? `1fr` : `${HEADER_HEIGHT} 1fr`)};

  ${system}
`;

const LayoutContainer = styled.div<SystemProps>`
  display: grid;
  grid-template-rows: auto 1fr;
  ${system}
`;

const LayoutContent = styled.div<{
  sidebar?: Function;
  collapsed?: boolean;
  dashboardMode?: boolean;
}>`
  display: grid;
  grid-template-rows: 1fr ${PAGINATION_BAR_HEIGHT};

  ${props =>
    props.sidebar &&
    css`
      grid-template-columns: ${props.collapsed ? '30px' : `${SIDEBAR_WIDTH}`} 1fr;
      grid-template-rows: calc(100vh - ${props.dashboardMode ? '0px' : HEADER_HEIGHT}) 1fr;
    `}

  ${system}
`;

const LayoutChart = styled.div<SystemProps & { dashboardMode?: boolean }>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 500px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: ${props =>
      props.dashboardMode ? themeGet('space.xl')(props) : themeGet('space.l')(props)}
    ${themeGet('space.xl')} ${themeGet('space.base')};
  width: 100%;

  ${system}
`;

export { Layout as default, LayoutContainer, LayoutContent, LayoutChart };
