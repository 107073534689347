import React from 'react';

import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { Checkbox, FormField, SortByOptions } from 'components';

const BathroomTrafficOptions: React.FC = () => {
  const [state, dispatch] = useTileOptions();
  const {
    bathroomTraffic: { filters },
  } = state;

  function onFilterChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const type = event.target.name;
    const checked = filters?.selectedTypes?.includes(type);
    let newTypes: string[] = [];

    if (checked) {
      newTypes = filters?.selectedTypes?.filter((t: string) => t !== type);
    } else {
      newTypes = [...filters.selectedTypes, type];
    }

    dispatch(
      updateOptions({
        bathroomTraffic: {
          filters: {
            ...filters,
            selectedTypes: newTypes,
          },
        },
      })
    );
  }

  return (
    <>
      <SortByOptions
        stateKey="bathroomTraffic"
        options={[
          { value: 'id', label: 'Bathroom' },
          { value: 'area', label: 'Area' },
          { value: 'count', label: 'Count' },
          { value: 'updatedAt', label: 'Updated' },
        ]}
      />
      <FormField label="Areas to show" type="stacked" mb="base">
        {filters?.types?.map((type: string, i: number) => (
          <Checkbox
            key={`${type}-${i}`}
            label={type}
            name={type}
            checked={!filters?.selectedTypes?.length || filters?.selectedTypes?.includes(type)}
            onChange={onFilterChange}
            mb="s"
          />
        ))}
      </FormField>
    </>
  );
};

export default BathroomTrafficOptions;
