import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { useIssuesFilterState } from 'providers/IssuesFilterProvider';

import { Issue } from './types';
import { GET_ISSUES } from './useIssues';

const DELETE_ISSUE = gql`
  mutation DeleteIssue($id: String!) {
    deleteIssue(id: $id) {
      issue {
        id
      }
    }
  }
`;

export function getOptimisticResponse() {
  return {
    optimisticResponse: {
      __typename: 'Mutation',
      deleteIssue: {
        issue: {
          __typename: 'Issue',
          id: Math.random() * 100,
        },
      },
    },
  };
}

export default function useDeleteIssue(options: MutationHookOptions = {}) {
  const { order, status } = useIssuesFilterState();

  return useMutation(DELETE_ISSUE, {
    update(cache, { data: { deleteIssue } }) {
      const queryResult = cache.readQuery<{ issues: Issue[] }>({
        query: GET_ISSUES,
        variables: { order, status },
      });
      const issues = queryResult?.issues || [];
      cache.writeQuery({
        query: GET_ISSUES,
        data: { issues: issues.filter(i => i.id !== deleteIssue.issue.id) },
        variables: { order, status },
      });
    },
    ...options,
  });
}
