import { Auth0ProviderOptions } from '@auth0/auth0-react';

const config: Auth0ProviderOptions = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  redirectUri: `${window.location.origin}/callback`,
  responseType: 'token id_token',
  scope: 'openid profile email',
  logoutRedirectUri: '/',
  audience: process.env.REACT_APP_AUTH0_IDENTIFIER,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
};

export default config;
