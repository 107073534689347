import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';

import theme from 'config/theme';

import { Notam as NotamProps } from 'data/Notam/types';

import { NOTAM_DATE_FORMAT } from './getData';
import getPositions from './getPositions';
import Line from './line';
import Point from './point';
import TRANSLATIONS from './translations';

export default class Notam {
  id: string;
  number: string;
  description: string;
  start: Date;
  end: Date;
  points: Point[];
  lines: Line[];
  codes: string[];

  constructor({ id, number, description, startTime, endTime }: NotamProps) {
    this.id = id;
    this.number = number;
    this.description = description;
    this.start = new Date(Number(startTime));
    this.end = new Date(Number(endTime));
    this.points = [];
    this.lines = [];
    this.codes = [];

    this.parseDescription();
  }

  get color(): keyof typeof theme.colors {
    if (this.description.match(/ficon/gi)) return 'secondary';
    if (this.description.match(/wip/gi)) return 'warning';
    if (this.description.match(/clsd/gi)) return 'error';
    return 'warning';
  }

  get translation(): string {
    return this.description
      .split(' ')
      .map(word => {
        const translation = TRANSLATIONS[word];
        if (translation) return translation;

        // E.g. "36C" or "18L"
        const hasEndingSingleLetter = word.match(/\d{1,2}[A-Za-z]{1}/g);
        if (hasEndingSingleLetter) return word;

        // If it is a date, let's convert it.
        const isDate = word.match(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/);
        if (isDate) {
          const dateString = `20${isDate[1]}-${isDate[2]}-${isDate[3]}T${isDate[4]}:${isDate[5]}:00Z`;
          const formattedDate = format(new Date(dateString), NOTAM_DATE_FORMAT);
          return formattedDate;
        }

        return capitalize(word);
      })
      .join(' ');
  }

  parseDescription(): void {
    const number = this.number;
    const description = this.description;

    const descriptionParts = description.split(',');
    let codeType: string;
    descriptionParts.forEach((_part: string): void => {
      const _codeType = ['RAMP', 'RWY', 'SPOT', 'TWY', 'TXL'].find(ct => _part.includes(ct));
      let part = _part.trim();
      if (_codeType) {
        codeType = _codeType;
      } else {
        part = [codeType, part].join(' ');
      }

      const { positions, code } = getPositions(part);
      const color = this.color;
      const translation = this.translation;

      if (code) {
        this.codes.push(code);
      }

      if (!positions || positions.length === 0 || !code) {
        return;
      } else if (positions.length > 1) {
        this.lines.push(new Line({ code, positions, number, description, color, translation }));
      } else {
        this.points.push(
          new Point({ code, position: positions[0], number, description, color, translation })
        );
      }
    });
  }
}
