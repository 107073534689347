import { CHART_TYPES } from 'statistics/charts/constants';
import { StatItem, StatsArgs } from 'statistics/types';

import statGetters from './';
import { STAT_TYPES } from './constants';

export const getStatTypes = (chartType: string): string[] => {
  switch (chartType) {
    case CHART_TYPES.WAIT_TIMES.WAIT_TIMES1:
      return [STAT_TYPES.WAIT_TIMES.WAIT_TIMES1.AVERAGE, STAT_TYPES.WAIT_TIMES.WAIT_TIMES1.HIGHEST];
    case CHART_TYPES.WAIT_TIMES.WAIT_TIMES2:
      return [STAT_TYPES.WAIT_TIMES.WAIT_TIMES2.AVERAGE, STAT_TYPES.WAIT_TIMES.WAIT_TIMES2.HIGHEST];
    case CHART_TYPES.WAIT_TIMES.WAIT_TIMES3:
      return [STAT_TYPES.WAIT_TIMES.WAIT_TIMES3.AVERAGE, STAT_TYPES.WAIT_TIMES.WAIT_TIMES3.HIGHEST];
    case CHART_TYPES.WAIT_TIMES.WAIT_TIMES4:
      return [STAT_TYPES.WAIT_TIMES.WAIT_TIMES4.AVERAGE, STAT_TYPES.WAIT_TIMES.WAIT_TIMES4.HIGHEST];
    case CHART_TYPES.WAIT_TIMES.WAIT_TIMES5:
      return [STAT_TYPES.WAIT_TIMES.WAIT_TIMES5.AVERAGE, STAT_TYPES.WAIT_TIMES.WAIT_TIMES5.HIGHEST];
    case CHART_TYPES.DEPARTURES.CARGO:
      return [STAT_TYPES.DEPARTURES.CARGO.AVERAGE, STAT_TYPES.DEPARTURES.CARGO.HIGHEST];
    case CHART_TYPES.DEPARTURES.PASSENGER:
      return [STAT_TYPES.DEPARTURES.PASSENGER.AVERAGE, STAT_TYPES.DEPARTURES.PASSENGER.HIGHEST];
    case CHART_TYPES.ARRIVALS.CARGO:
      return [STAT_TYPES.ARRIVALS.CARGO.AVERAGE, STAT_TYPES.ARRIVALS.CARGO.HIGHEST];
    case CHART_TYPES.ARRIVALS.PASSENGER:
      return [STAT_TYPES.ARRIVALS.PASSENGER.AVERAGE, STAT_TYPES.ARRIVALS.PASSENGER.HIGHEST];
    case CHART_TYPES.SALES.CONCOURSE_A:
      return [
        STAT_TYPES.SALES.CONCOURSE_A.AVERAGE,
        STAT_TYPES.SALES.CONCOURSE_A.HIGHEST,
        STAT_TYPES.SALES.CONCOURSE_A.LOWEST,
      ];
    case CHART_TYPES.SALES.CONCOURSE_B:
      return [
        STAT_TYPES.SALES.CONCOURSE_B.AVERAGE,
        STAT_TYPES.SALES.CONCOURSE_B.HIGHEST,
        STAT_TYPES.SALES.CONCOURSE_B.LOWEST,
      ];
    case CHART_TYPES.SALES.MAIN_TERMINAL:
      return [
        STAT_TYPES.SALES.MAIN_TERMINAL.AVERAGE,
        STAT_TYPES.SALES.MAIN_TERMINAL.HIGHEST,
        STAT_TYPES.SALES.MAIN_TERMINAL.LOWEST,
      ];
    case CHART_TYPES.SALES.SHORT_TERM:
      return [
        STAT_TYPES.SALES.SHORT_TERM.AVERAGE,
        STAT_TYPES.SALES.SHORT_TERM.HIGHEST,
        STAT_TYPES.SALES.SHORT_TERM.LOWEST,
      ];
    case CHART_TYPES.SALES.LONG_TERM:
      return [
        STAT_TYPES.SALES.LONG_TERM.AVERAGE,
        STAT_TYPES.SALES.LONG_TERM.HIGHEST,
        STAT_TYPES.SALES.LONG_TERM.LOWEST,
      ];
    case CHART_TYPES.SALES.ECONO_LOT:
      return [
        STAT_TYPES.SALES.ECONO_LOT.AVERAGE,
        STAT_TYPES.SALES.ECONO_LOT.HIGHEST,
        STAT_TYPES.SALES.ECONO_LOT.LOWEST,
      ];
    case CHART_TYPES.SALES.VALET:
      return [
        STAT_TYPES.SALES.VALET.AVERAGE,
        STAT_TYPES.SALES.VALET.HIGHEST,
        STAT_TYPES.SALES.VALET.LOWEST,
      ];
    case CHART_TYPES.SALES.TOTAL:
      return [
        STAT_TYPES.SALES.TOTAL.AVERAGE,
        STAT_TYPES.SALES.TOTAL.HIGHEST,
        STAT_TYPES.SALES.TOTAL.LOWEST,
      ];
    case CHART_TYPES.DAILY_THROUGHPUT.DAILY:
      return [
        STAT_TYPES.DAILY_THROUGHPUT.AVERAGE,
        STAT_TYPES.DAILY_THROUGHPUT.HIGHEST,
        STAT_TYPES.DAILY_THROUGHPUT.LOWEST,
      ];
    case CHART_TYPES.CARGO_REVENUE:
      return [
        STAT_TYPES.CARGO_REVENUE.AVERAGE,
        STAT_TYPES.CARGO_REVENUE.HIGHEST,
        STAT_TYPES.CARGO_REVENUE.LOWEST,
      ];
    case CHART_TYPES.BOOKINGS:
      return [STAT_TYPES.BOOKINGS.AVERAGE, STAT_TYPES.BOOKINGS.HIGHEST, STAT_TYPES.BOOKINGS.LOWEST];
    case CHART_TYPES.BAGGAGE.B_BAGS:
      return [
        STAT_TYPES.BAGGAGE.B_BAGS.AVERAGE,
        STAT_TYPES.BAGGAGE.B_BAGS.HIGHEST,
        STAT_TYPES.BAGGAGE.B_BAGS.LOWEST,
      ];
    case CHART_TYPES.BAGGAGE.T_DRIVE:
      return [
        STAT_TYPES.BAGGAGE.T_DRIVE.AVERAGE,
        STAT_TYPES.BAGGAGE.T_DRIVE.HIGHEST,
        STAT_TYPES.BAGGAGE.T_DRIVE.LOWEST,
      ];
    case CHART_TYPES.BAGGAGE.BAGS_RECEIVED:
      return [
        STAT_TYPES.BAGGAGE.BAGS_RECEIVED.AVERAGE,
        STAT_TYPES.BAGGAGE.BAGS_RECEIVED.HIGHEST,
        STAT_TYPES.BAGGAGE.BAGS_RECEIVED.LOWEST,
      ];
    case CHART_TYPES.BAGGAGE.BAGS_CLEARED:
      return [
        STAT_TYPES.BAGGAGE.BAGS_CLEARED.AVERAGE,
        STAT_TYPES.BAGGAGE.BAGS_CLEARED.HIGHEST,
        STAT_TYPES.BAGGAGE.BAGS_CLEARED.LOWEST,
      ];
    case CHART_TYPES.BAGGAGE.TOTAL:
      return [
        STAT_TYPES.BAGGAGE.TOTAL.AVERAGE,
        STAT_TYPES.BAGGAGE.TOTAL.HIGHEST,
        STAT_TYPES.BAGGAGE.TOTAL.LOWEST,
      ];
    case CHART_TYPES.PROJECTION.AM:
      return [
        STAT_TYPES.PROJECTION.AM.AVERAGE,
        STAT_TYPES.PROJECTION.AM.HIGHEST,
        STAT_TYPES.PROJECTION.AM.LOWEST,
      ];
    case CHART_TYPES.PROJECTION.PM:
      return [
        STAT_TYPES.PROJECTION.PM.AVERAGE,
        STAT_TYPES.PROJECTION.PM.HIGHEST,
        STAT_TYPES.PROJECTION.PM.LOWEST,
      ];
    case CHART_TYPES.COMMON_USE.BAG_TAGS:
      return [
        STAT_TYPES.COMMON_USE.BAG_TAGS.AVERAGE,
        STAT_TYPES.COMMON_USE.BAG_TAGS.HIGHEST,
        STAT_TYPES.COMMON_USE.BAG_TAGS.LOWEST,
        STAT_TYPES.COMMON_USE.BAG_TAGS.TOTAL,
      ];
    case CHART_TYPES.COMMON_USE.WORKSTATIONS:
      return [
        STAT_TYPES.COMMON_USE.WORKSTATIONS.AVERAGE,
        STAT_TYPES.COMMON_USE.WORKSTATIONS.HIGHEST,
        STAT_TYPES.COMMON_USE.WORKSTATIONS.LOWEST,
      ];
    default:
      return [];
  }
};

export function getStatItem(statType: string, datum: StatsArgs): StatItem | undefined {
  const type = statType.split('.')[0];
  switch (type) {
    case 'ARRIVALS':
      return statGetters.arrivals[statType](datum);
    case 'DEPARTURES':
      return statGetters.departures[statType](datum);
    case 'SALES':
      return statGetters.sales[statType](datum);
    case 'WAIT_TIMES':
      return statGetters.security[statType](datum);
    case 'DAILY_THROUGHPUT':
      return statGetters.dailyThroughputs[statType](datum);
    case 'CARGO_REVENUE':
      return statGetters.cargoRevenue[statType](datum);
    case 'BOOKINGS':
      return statGetters.bookings[statType](datum);
    case 'BAGGAGE':
      return statGetters.baggage[statType](datum);
    case 'PROJECTION':
      return statGetters.projection[statType](datum);
    case 'COMMON_USE':
      return statGetters.commonUse[statType](datum);
    default:
      return;
  }
}
