import { gql, QueryResult, useQuery } from '@apollo/client';
import { add, getMonth, getYear, sub } from 'date-fns';
import pickBy from 'lodash/pickBy';

import { FeedStatusHistory } from './types';

const FEED_STATUS_HISTORY = gql`
  query FeedStatusHistory($between: [DateTime]) {
    feedStatusHistory(between: $between) {
      feed
      description
      status
      updatedAt
    }
  }
`;

export default function useFeedStatusHistory(
  selectedDate: Date
): QueryResult<{ feedStatusHistory: FeedStatusHistory[] }> {
  const startDate = new Date(`${getMonth(selectedDate) + 1}/01/${getYear(selectedDate)}`);
  const endDate = sub(add(startDate, { months: 1 }), { days: 1 });
  const variables = pickBy({ between: [startDate, endDate] });
  const options = { variables };
  return useQuery(FEED_STATUS_HISTORY, options);
}
