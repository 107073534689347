import React from 'react';
import { useLocation } from 'react-router-dom';

import routes from 'config/routes';

import { useQueryParams } from 'hooks';

import { AppLayout, AuthErrorMessage, ErrorMessage } from 'components';
import { Props as ErrorMessageProps } from 'components/ErrorMessage';

import { VerticalContent } from 'styled';

interface Type {
  [key: string]: ErrorMessageProps;
}

interface Props {
  type?: string;
}

const errorTypes: Type = {
  auth: {
    title: 'There was an error signing you in',
    icon: 'lock',
    showActions: false,
    // eslint-disable-next-line react/display-name
    children: (): JSX.Element => <AuthErrorMessage />,
  },
  unauthorized: {
    title: 'Not authorized',
    icon: 'lock',
    showActions: false,
    // eslint-disable-next-line react/display-name
    children: (): JSX.Element => (
      <AuthErrorMessage
        message="You are not authorized to access this application."
        showActions={false}
      />
    ),
  },
  unverified: {
    title: 'Verify your email',
    icon: 'checkCircle',
    showActions: false,
    // eslint-disable-next-line react/display-name
    children: (): JSX.Element => (
      <AuthErrorMessage
        message="Please check your email to verify your email address and then come back and sign in again."
        buttonText="Sign in with verified email"
      />
    ),
  },
};

const AppError: React.FC<Props> = ({ type }: Props) => {
  const location = useLocation();
  const isErrorScreen = location.pathname === routes.error;
  const params = useQueryParams();
  const _type = type || params.get('type') || undefined;

  function renderErrorMessage(): JSX.Element {
    if (isErrorScreen && _type) {
      const error = errorTypes[_type];

      if (typeof error.children === 'function') {
        return <ErrorMessage {...error}>{error.children()}</ErrorMessage>;
      }

      return <ErrorMessage {...error}>{error?.children}</ErrorMessage>;
    }

    return <ErrorMessage />;
  }

  return (
    <AppLayout>
      <VerticalContent>{renderErrorMessage()}</VerticalContent>
    </AppLayout>
  );
};

export default AppError;
