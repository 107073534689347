import React from 'react';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';

import Weather from './Weather';
import WeatherDetails from './WeatherDetails';

export const WeatherTile: React.FC = () => {
  return (
    <Tile
      title="Weather"
      icon="sun"
      floatingHeader={true}
      contentAlignment="top"
      contentPadding="0"
      modal="Default"
      modalProps={{
        title: 'Weather',
        icon: { name: 'sun' },
        children: <WeatherDetails />,
        large: true,
      }}
    >
      <RequiredPermission
        permission="read:weather"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <Weather />
      </RequiredPermission>
    </Tile>
  );
};

export default WeatherTile;
