import { gql, useQuery } from '@apollo/client';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { DelayArgs, DelaysQuery } from './types';

const GET_DELAYS = gql`
  query Delays {
    delays {
      id
      airportCode
      type
      description
      expiresAt
      createdAt
      updatedAt
    }
  }
`;

export default function useDelays({ refresh = true }: DelayArgs = {}): DelaysQuery {
  const options = {
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_DELAYS, options);

  return {
    delays: query.data?.delays || [],
    ...query,
  };
}
