import React from 'react';

import { SortByOptions } from 'components';

const ParkingOptions: React.FC = () => {
  return (
    <>
      <SortByOptions
        stateKey="parking"
        options={[
          { value: 'spotsOccupied', label: '% Full' },
          { value: 'spotsAvailable', label: 'Available' },
          { value: 'percentage', label: 'Change' },
          { value: 'lot', label: 'Lot' },
        ]}
      />
    </>
  );
};

export default ParkingOptions;
