import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Title = styled.div<SystemProps>`
  text-transform: capitalize;
  ${system}
`;

export default Title;
