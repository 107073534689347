import React from 'react';

import { SortByOptions } from 'components';

const CargoRevenueOptions: React.FC = () => {
  return (
    <>
      <SortByOptions
        stateKey="cargoRevenue"
        options={[
          { value: 'airline', label: 'Airline' },
          { value: 'operations', label: 'Operations' },
          { value: 'costNum', label: 'Revenue' },
          { value: 'weightNum', label: 'Weight (lbs)' },
        ]}
      />
    </>
  );
};

export default CargoRevenueOptions;
