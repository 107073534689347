import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const SearchMenu = styled.ul<{ side?: string }>`
  background-color: ${themeGet('colors.fg')};
  border-radius: ${themeGet('radii.base')};
  box-shadow: ${themeGet('shadows.base')};
  color: ${themeGet('colors.bg')};
  margin-top: ${themeGet('space.xs')};
  overflow: hidden;
  position: absolute;
  ${props => props.side || 'right'}: 0;
  top: 100%;
  z-index: ${themeGet('zIndices.menu')};

  > li {
    margin: 0;
  }

  ${system}
`;

const SearchMenuItem = styled.div<{ active: boolean }>`
  display: block;
  list-style-type: none;
  margin: 0;
  padding: ${themeGet('space.s')};

  ${props =>
    props.active &&
    css`
      background-color: ${themeGet('colors.secondary')};
      color: ${themeGet('colors.fg')};
    `};

  ${system}
`;

const SearchMenuContainer = styled.div<SystemProps>`
  position: relative;

  ${system}
`;

export { SearchMenu as default, SearchMenuContainer, SearchMenuItem };
