import { format } from 'date-fns';

import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';

import { filterByDayRange, getFilteredChartData } from 'statistics/charts/utils';
import { STAT_TYPES } from 'statistics/stats/constants';
import { ChartDatum, DataObject, StatsData } from 'statistics/types';

import { ProcessedDataObject } from './utils';
import { TSACount } from 'data/TSACounts/types';

type TSACountYear = 'current' | 'year2019';

function getChartData(tsaCounts: TSACount[], countYear: TSACountYear): ChartDatum[] {
  const termAData = tsaCounts.map((d: TSACount) => ({
    x: new Date(d.date).getTime(),
    y: d[countYear === 'current' ? 'termA' : 'termA2019'],
    name: `Terminal A${countYear === 'current' ? '' : ' (2019)'}`,
  }));
  const termBData = tsaCounts.map((d: TSACount) => ({
    x: new Date(d.date).getTime(),
    y: d[countYear === 'current' ? 'termB' : 'termB2019'],
    name: `Terminal B${countYear === 'current' ? '' : ' (2019)'}`,
  }));
  const data: ChartDatum[] = [...termAData, ...termBData];

  return sortBy(data, 'y');
}

function getStatsData(data: TSACount[]): StatsData {
  const statsData = data
    .map((d: TSACount) => ({ total: d.termA + d.termB, date: format(d.date, 'MM/dd/yyyy') }))
    .sort((a, b) => a.total - b.total);

  const lowest = statsData[0];
  const highest = statsData[statsData.length - 1];
  const average = sum(statsData.map(d => d.total)) / statsData.length;

  return {
    [STAT_TYPES.TSA_COUNTS.AVERAGE]: { value: average, contextLabel: 'Average' },
    [STAT_TYPES.TSA_COUNTS.HIGHEST]: { value: highest.total, contextLabel: highest.date },
    [STAT_TYPES.TSA_COUNTS.LOWEST]: { value: lowest.total, contextLabel: lowest.date },
  };
}

function getProcessedData(
  tsaCountsData: DataObject,
  dateDayRanges: string[],
  countYear: TSACountYear
): ProcessedDataObject {
  const { data: tsaCounts, loading, error } = tsaCountsData;
  if (loading || error)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
    };

  const _data = (tsaCounts as TSACount[]) || [];

  const { byDayRange } = filterByDayRange(dateDayRanges);
  _data.filter((datum: TSACount) => byDayRange(datum.date));

  return {
    chart: {
      loading,
      error,
      data: getFilteredChartData(getChartData(_data, countYear), dateDayRanges),
    },
    stats: {
      loading,
      error,
      data: getStatsData(_data),
    },
  };
}

export default {
  current: {
    getProcessedData: (tsaCountsData: DataObject, dateDayRanges: string[]): ProcessedDataObject =>
      getProcessedData(tsaCountsData, dateDayRanges, 'current'),
  },
  year2019: {
    getProcessedData: (
      dailyThroughputsData: DataObject,
      dateDayRanges: string[]
    ): ProcessedDataObject => getProcessedData(dailyThroughputsData, dateDayRanges, 'year2019'),
  },
};
