import { gql, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { BathroomArgs, BathroomQuery } from './types';

const GET_BATHROOM = gql`
  query Bathroom($code: String) {
    bathroom(code: $code) {
      id
      count
      area
      threshold
      totalCount
      updatedAt
      inspections {
        id
        score
        inspector
        durationMinutes
        updatedAt
      }
    }
  }
`;

export default function useBathroom({ code }: BathroomArgs = {}): BathroomQuery {
  const variables = pickBy(
    {
      code,
    },
    identity
  );

  const options = {
    variables,
  };

  const query = useQuery(GET_BATHROOM, options);

  return {
    bathroom: query.data?.bathroom || {},
    ...query,
  };
}
