import merge from 'lodash/merge';

import { formatTime } from 'utils';

import { getDefaultYAxisOptions } from 'statistics/charts/utils';

export default function getAxis(options: Highcharts.YAxisOptions = {}): Highcharts.YAxisOptions {
  return merge(getDefaultYAxisOptions(), {
    title: {
      text: 'Wait Times',
    },
    labels: {
      formatter(): string {
        return formatTime(this.value);
      },
    },
    ...options,
    id: 'wait-times',
  } as Highcharts.YAxisOptions);
}
