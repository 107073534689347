import React, { useEffect, useRef, useState } from 'react';

import { TILE_KEYS, TILES } from 'config/tiles';

import { CANCELED } from 'data/Flights/constants';
import { CapacityDepartureFlight } from 'data/Flights/types';

import { useDashboardMode } from 'hooks';

import { showModal, updateModal, useModal } from 'providers/ModalProvider';
import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile, TileTab, TileTabs } from 'components';
import { Option } from 'components/Menu';
import { Capacity } from 'components/Tiles';

import { TileTabContent } from 'styled';
import { getAirportTime } from 'utils';

export const CapacityTile: React.FC = () => {
  const [optionsState, optionsDispatch] = useTileOptions();
  const [modalState, modalDispatch] = useModal();
  const [capacityMenuSelection, setCapacityMenuSelection] = useState(0);
  const now = getAirportTime(new Date());

  const {
    capacity: { filters },
  } = optionsState;

  const tileConfig = TILES?.find(tile => tile.title === 'Capacity');

  const departureOptionsData = [
    {
      label: 'View Current',
      icon: { name: 'clock' },
      filter: ({ flight }: { flight: CapacityDepartureFlight }): boolean => {
        const notCancelled = flight.statusText !== CANCELED;
        const date = now;
        const flightDate = new Date(flight.estimatedDepartureDate || flight.scheduledDepartureDate);
        return notCancelled && flightDate >= date;
      },
    },
    {
      label: 'View Previous',
      icon: { name: 'corneredArrowUpLeft' },
      filter: ({ flight }: { flight: CapacityDepartureFlight }): boolean => {
        const notCancelled = flight.statusText !== CANCELED;
        const date = now;
        const flightDate = new Date(flight.estimatedDepartureDate || flight.scheduledDepartureDate);
        return notCancelled && flightDate < date;
      },
    },
    {
      label: 'View All',
      icon: { name: 'list' },
      filter: ({ flight }: { flight: CapacityDepartureFlight }): boolean => {
        const notCancelled = flight.statusText !== CANCELED;
        return notCancelled;
      },
    },
    {
      label: 'Settings',
      icon: { name: 'gear' },
      action: (): void =>
        modalDispatch(
          showModal('TileOptions', {
            title: 'Capacity Settings',
            icon: { name: 'gear' },
            optionKey: TILE_KEYS.CAPACITY,
          })
        ),
    },
  ];

  const capacityOptions: Option[] = departureOptionsData.map((optionData, i) => ({
    label: optionData.label,
    icon: optionData.icon,
    action: (): void => {
      if (optionData?.action) optionData?.action();
      else setCapacityMenuSelection(i);
    },
    showActive: true,
  }));

  const capacityFilter = departureOptionsData[capacityMenuSelection].filter;

  const count = useRef<number>(0);
  useDashboardMode(() => {
    count.current++;
    if (count.current === capacityOptions.length) count.current = 0;
    setCapacityMenuSelection(count.current);
  });

  const headings = tileConfig?.headings || [];
  const additionalHeadings = tileConfig?.additionalHeadings || [];

  const modalProps = {
    title: 'Capacity',
    icon: { name: 'list' },
    large: true,
    children: (
      <TileTabs>
        <TileTab count={1} title="Departures" icon="userCheck">
          <TileTabContent hasTable={true}>
            <RequiredPermission
              permission="read:flight"
              renderFallback={() => <NotAvailableBlankSlate />}
            >
              <Capacity
                grid="1.75fr repeat(6, 1fr)"
                headings={[...headings, ...additionalHeadings]}
                filterBlankSlateIcon={{
                  name: 'magnifyingGlass',
                  color: 'warning',
                  size: '30',
                }}
                filterEmptyMessage="No capacity data matching parameters."
                isReverseSort={filters?.isReverseSort}
                sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
              />
            </RequiredPermission>
          </TileTabContent>
        </TileTab>
      </TileTabs>
    ),
  };

  // Update modal data if needed
  useEffect(() => {
    const isActiveModal = modalState?.activeModal?.props?.title === 'Capacity';
    const needsUpdate = optionsState?.capacity?.filters?.needsUpdate;
    if (isActiveModal && needsUpdate) {
      modalDispatch(updateModal('Default', modalProps));
      optionsDispatch(
        updateOptions({
          capacity: {
            ...optionsState.capacity,
            filters: {
              ...optionsState.capacity.filters,
              needsUpdate: false,
            },
          },
        })
      );
    }
  }, [modalDispatch, modalState, modalProps, optionsDispatch, optionsState]);

  return (
    <Tile
      title="Capacity"
      icon="userCheck"
      contentAlignment="top"
      contentPadding="0"
      options={capacityOptions}
      modal="Default"
      modalProps={modalProps}
    >
      <TileTabs>
        <TileTab count={1} title="Departures" icon="arrowUpAndRight">
          <TileTabContent hasTable={true}>
            <RequiredPermission
              permission="read:flight"
              renderFallback={() => <NotAvailableBlankSlate />}
            >
              <Capacity
                grid="1.75fr repeat(3, 1fr)"
                filter={capacityFilter}
                headings={headings}
                isReverseSort={filters?.isReverseSort}
                sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
              />
            </RequiredPermission>
          </TileTabContent>
        </TileTab>
      </TileTabs>
    </Tile>
  );
};

export default CapacityTile;
