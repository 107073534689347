import React from 'react';

import { Box, FormLabel } from 'styled';
import { SystemProps } from 'styled/lib/types';

interface Props extends SystemProps {
  label: string;
  name?: string;
  alignItems?: string;
  mb?: string;
  type?: 'stacked';
  labelHorizontalAlign?: 'left';
  labelVerticalAlign?: 'top';
  labelVerticalAlignSpace?: string;
  labelMarginRight?: string;
  labelWidth?: string;
  children?: React.ReactNode;
}

const FormField: React.FC<Props> = ({
  children,
  label,
  name,
  labelHorizontalAlign,
  labelVerticalAlign,
  labelVerticalAlignSpace,
  labelMarginRight,
  labelWidth,
  type,
  ...rest
}: Props) => {
  if (type === 'stacked') {
    return (
      <Box {...rest}>
        <FormLabel htmlFor={name} display="block" mb="s">
          {label}
        </FormLabel>
        <Box>{children}</Box>
      </Box>
    );
  }

  return (
    <Box
      alignItems={labelVerticalAlign && labelVerticalAlign === 'top' ? 'flex-start' : 'center'}
      display="grid"
      gridTemplateColumns={`${labelWidth || '100px'} 1fr`}
      {...rest}
    >
      <FormLabel
        htmlFor={name}
        mr={labelMarginRight || 'l'}
        mt={
          labelVerticalAlign && labelVerticalAlign === 'top'
            ? labelVerticalAlignSpace || 's'
            : undefined
        }
        textAlign={labelHorizontalAlign || 'right'}
      >
        {label}:
      </FormLabel>
      <Box>{children}</Box>
    </Box>
  );
};

export default FormField;
