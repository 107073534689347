import { gql, useQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { DeparturesArgs, HistoricalDepartureFlightsQuery } from './types';

const HISTORICAL_DEPARTURES = gql`
  query HistoricalDepartures($between: [DateTime]!) {
    historicalDepartures(between: $between) {
      id
      actualDepartureDate
      aircraftType
      bookings
      cancelled
      cargoCarrier
      departureDelay
      departureGate
      destination
      diverted
      estimatedDepartureDate
      flightNumber
      isPassenger
      marketingCarrier
      netWeight
      origin
      scheduledDepartureDate
      statusText
      departureTime
      departureDate
    }
  }
`;

const now = new Date();
const start = startOfDay(now);
const end = endOfDay(now);

export default function useHistoricalDepartures({
  between = [start.getTime(), end.getTime()],
  refresh = true,
  skip = false,
}: DeparturesArgs = {}): HistoricalDepartureFlightsQuery {
  const variables = pickBy(
    {
      between,
    },
    identity
  );

  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(HISTORICAL_DEPARTURES, options);
  const departures = query?.data?.historicalDepartures || [];

  return {
    departures,
    ...query,
  };
}
