import React from 'react';
import groupBy from 'lodash/groupBy';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';

import { useChartOptions } from 'providers/ChartOptionsProvider';
import { ExportableTile, TileTab, TileTabs } from 'components';
import { BagTagData, BagTagSummaryData } from 'data/CommonUse/types';
import { COMMON_USE_AIRLINES } from 'data/CommonUse/airlines';

import { CommonUseBagTagSummary, CommonUseBagTagTable } from './components';

import { Box, TileTabContent } from 'styled';
import { ChartDetailsData } from 'components/ChartDetails';

type Props = { data: ChartDetailsData };

const CommonUseBagTagDetails: React.FC<Props> = ({ data }: Props) => {
  const [state] = useChartOptions();
  const { chartStartDate, chartEndDate } = state;
  const formattedStart = format(chartStartDate, 'LL/dd/yyyy');
  const formattedEnd = format(chartEndDate, 'LL/dd/yyyy');
  const date = `${formattedStart} - ${formattedEnd}`;

  if (!data) return null;

  const _data = data as BagTagData[];
  const groupedData = groupBy(_data, 'airline');
  const mappedData = Object.keys(groupedData).map(key => {
    const airline = COMMON_USE_AIRLINES[key];
    const count = groupedData[key].reduce((acc, curr) => acc + curr.count, 0);
    return { date, airline, count };
  }) as BagTagSummaryData[];

  const options = [
    {
      label: 'Summary Data (CSV)',
      render: ({ label }: { label: string }) => (
        <CSVLink data={mappedData} filename={`Bag Tag Summary - ${date}`}>
          <Box as="a" color="bg">
            {label}
          </Box>
        </CSVLink>
      ),
    },
    {
      label: 'All Data (CSV)',
      render: ({ label }: { label: string }) => (
        <CSVLink data={_data} filename={`Bag Tag Data - ${date}`}>
          <Box as="a" color="bg">
            {label}
          </Box>
        </CSVLink>
      ),
    },
  ];

  return (
    <ExportableTile
      title={`Common Use Bag Tags`}
      icon="briefcase"
      contentAlignment="top"
      contentPadding="0"
      options={options}
      menuWidth="11rem"
    >
      <TileTabs>
        <TileTab count={2} title="Summary">
          <TileTabContent hasTable={true}>
            <CommonUseBagTagSummary data={mappedData} />
          </TileTabContent>
        </TileTab>
        <TileTab count={2} title="All Data">
          <TileTabContent hasTable={true}>
            <CommonUseBagTagTable data={data} />
          </TileTabContent>
        </TileTab>
      </TileTabs>
    </ExportableTile>
  );
};

export default CommonUseBagTagDetails;
