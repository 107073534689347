import { useCallback } from 'react';

import { State } from 'providers/ChartOptionsProvider';

import {
  getContextsData,
  getDataTypes,
  getProcessedData,
  getProcessorTypes,
  ProcessedDataObject,
} from 'statistics/processors/utils';
import { ProvidersContexts } from 'statistics/types';

import useWorker from './useWorker';

export default function useProcessedData(
  charts: string[],
  contexts: ProvidersContexts,
  state?: State,
  noPermission?: boolean
): { [key: string]: ProcessedDataObject } | undefined {
  const callback = useCallback(async (): Promise<
    { [key: string]: ProcessedDataObject } | undefined
  > => {
    if (noPermission || Object.values(contexts).some(ctx => ctx.loading)) {
      return;
    }
    const dataTypes = getDataTypes(charts);
    const processorTypes = getProcessorTypes(charts);
    const contextsData = getContextsData(dataTypes, contexts);
    return getProcessedData(processorTypes, contextsData, state);
  }, [charts, contexts, state, noPermission]);

  return useWorker(callback);
}
