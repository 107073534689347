import { gql, useQuery } from '@apollo/client';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { PavementTempsArgs, PavementTempsQuery } from './types';

const GET_PAVEMENT_TEMPS = gql`
  query {
    currentPavementTemps {
      latitude
      longitude
      roadTemp
      sensorId
      sensorName
      surfaceCondition
      stationId
      utcTime
    }
  }
`;

export default function usePavementTemps({
  refresh = true,
  skip = false,
}: PavementTempsArgs): PavementTempsQuery {
  const options = {
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_PAVEMENT_TEMPS, options);

  return {
    temps: query.data?.currentPavementTemps || [],
    ...query,
  };
}
