export interface Camera {
  id: string;
  road: string;
  coordinates: [number, number];
  image: {
    url: string;
    height: number;
    width: number;
  };
}

const BASE_URL = 'http://www.trimarc.org/images/milestone';

const getImage = (filename: string): string => `${BASE_URL}/${filename}.jpg`;

const CAMERAS: Camera[] = [
  // I-275
  {
    id: 'CCTV_06_275_0070',
    road: 'I-275 at KY 237',
    coordinates: [39.081277, -84.708122],
    image: {
      url: getImage('CCTV_06_275_0070'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_275_0060',
    road: 'I-275 East of KY 237',
    coordinates: [39.081175, -84.700222],
    image: {
      url: getImage('CCTV_06_275_0060'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_275_0048',
    road: 'I-275 at Petersburg Rd Overpass',
    coordinates: [39.073726, -84.670841],
    image: {
      url: getImage('CCTV_06_275_0048'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_275_0037',
    road: 'I-275 East of KY 212/Airport',
    coordinates: [39.069568, -84.649833],
    image: {
      url: getImage('CCTV_06_275_0037'),
      width: 720,
      height: 528,
    },
  },
  // I-71/I-75
  {
    id: 'CCTV_06_75_1847',
    road: 'I-71/I-75 at I-275',
    coordinates: [39.034865, -84.603687],
    image: {
      url: getImage('CCTV_06_75_1847'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_75_1838',
    road: 'I-71/I-75 at Donaldson Hwy',
    coordinates: [39.026038, -84.613119],
    image: {
      url: getImage('CCTV_06_75_1838'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_75_1886',
    road: 'I-71/I-75 at Kyles Ln',
    coordinates: [39.05903, -84.538342],
    image: {
      url: getImage('CCTV_06_75_1886'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_75_1758',
    road: 'I-71/I-75 north of Richwood Rd',
    coordinates: [38.924515, -84.630122],
    image: {
      url: getImage('CCTV_06_75_1758'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_75_1912',
    road: 'I-71/I-75 N on Brent Spence Bridge',
    coordinates: [39.086853, -84.522807],
    image: {
      url: getImage('CCTV_06_75_1912'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_75_1908',
    road: 'I-71/I-75 at 12th St',
    coordinates: [39.08081, -84.522593],
    image: {
      url: getImage('CCTV_06_75_1908'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_75_1896',
    road: 'I-71/I-75 at Cut in the Hill',
    coordinates: [39.064365, -84.521673],
    image: {
      url: getImage('CCTV_06_75_1896'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_75_1868',
    road: 'I-71/I-75 at N of Buttermilk Pike',
    coordinates: [39.051071, -84.567256],
    image: {
      url: getImage('CCTV_06_75_1868'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_75_1863',
    road: 'I-71/I-75 at Buttermilk Pike',
    coordinates: [39.04646, -84.574987],
    image: {
      url: getImage('CCTV_06_75_1863'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_75_1823',
    road: 'I-71/I-75 at Turfway Rd',
    coordinates: [39.011684, -84.633235],
    image: {
      url: getImage('CCTV_06_75_1823'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_75_1812',
    road: 'I-71/I-75 at Burlington Pike',
    coordinates: [39.001601, -84.648367],
    image: {
      url: getImage('CCTV_06_75_1812'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_75_1801',
    road: 'I-71/I-75 at US-42',
    coordinates: [38.984589, -84.642244],
    image: {
      url: getImage('CCTV_06_75_1801'),
      width: 720,
      height: 528,
    },
  },
  // I-275
  {
    id: 'CCTV_06_275_0823',
    road: 'I-275 at Dixie Hwy',
    coordinates: [39.026347, -84.572702],
    image: {
      url: getImage('CCTV_06_275_0823'),
      width: 720,
      height: 528,
    },
  },
  {
    id: 'CCTV_06_275_0812',
    road: 'I-275 East of Turkeyfoot Road Mile 81.2',
    coordinates: [39.026783, -84.552853],
    image: {
      url: getImage('CCTV_06_275_0812'),
      width: 720,
      height: 528,
    },
  },
];

export default CAMERAS;
