import React from 'react';

import { NotAvailableBlankSlate, TileTab, TileTabs } from 'components';
import Notams from './Notams';

import { TileTabContent } from 'styled';

interface NotamsDetailsProps {
  tile?: boolean;
}
export function NotamsDetails({ tile = false }: NotamsDetailsProps): JSX.Element {
  if (process.env.REACT_APP_CONFIG_NOTAMS !== 'true') return <NotAvailableBlankSlate />;
  const showMap = process.env.REACT_APP_INTEGRATION_AIRFIELD_TEMPS === 'true';

  return (
    <TileTabs>
      <TileTab count={2} title="Active" icon="check">
        <TileTabContent hasTable={true}>
          <Notams
            showTable={true}
            showMap={showMap}
            filter={{ active: true, current: false }}
            mapHeight="400px"
            tile={tile}
          />
        </TileTabContent>
      </TileTab>
      <TileTab count={2} title="Submitted" icon="calendar">
        <TileTabContent hasTable={true}>
          <Notams
            showTable={true}
            showMap={showMap}
            filter={{ active: false, current: true }}
            mapHeight="400px"
            tile={tile}
          />
        </TileTabContent>
      </TileTab>
    </TileTabs>
  );
}

export default NotamsDetails;
