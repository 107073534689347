import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import authConfig from 'config/auth';
import theme from 'config/theme';

import { AuthorizedApolloProvider, ProviderComposer, ToastProvider } from 'providers';

interface Props {
  children?: React.ReactNode;
}

const AppProvider: React.FC<Props> = ({ children }: Props) => {
  const contexts: React.ReactElement[] = [
    <ThemeProvider key={1} theme={theme} />,
    <ToastProvider key={3} />,
  ];

  return (
    <Auth0Provider {...authConfig}>
      <AuthorizedApolloProvider>
        <ProviderComposer contexts={contexts}>{children}</ProviderComposer>
      </AuthorizedApolloProvider>
    </Auth0Provider>
  );
};

export default AppProvider;
