import 'leaflet/dist/leaflet.css';

import { divIcon, DivIcon, LatLngBoundsLiteral, MarkerCluster, point } from 'leaflet';
import React from 'react';
import { CircleMarker, Map as LeafletMap, Rectangle, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import theme from 'config/theme';
import CAMERAS, { Camera } from 'config/trimarc';

interface TrafficMapProps {
  center?: [number, number];
  children?: React.ReactNode;
  zoom?: number;
}

export const TrafficMap: React.FC<TrafficMapProps> = ({
  center,
  children,
  zoom,
}: TrafficMapProps) => {
  const options = {
    lat: 39.037788,
    lng: -84.642955,
    zoom: 10.4,
  };

  const camerasEnabled = process.env.REACT_APP_AIRPORT_CODE === 'CVG';

  const rectangleBounds: LatLngBoundsLiteral = [
    [options.lat - 1000, options.lng - 1000],
    [options.lat + 1000, options.lng + 1000],
  ];

  function renderCameras() {
    return CAMERAS.map(
      (camera: Camera, idx: number): JSX.Element => (
        <CircleMarker
          key={idx}
          center={[...camera.coordinates]}
          stroke={false}
          color={theme.colors.secondary}
          fillOpacity={1}
          radius={6}
          onClick={(): void => {
            window.open(
              camera.image.url,
              camera.road,
              `width=${camera.image.width}, height=${camera.image.height}`
            );
          }}
        />
      )
    );
  }

  return (
    <LeafletMap
      center={center || [options.lat, options.lng]}
      zoom={zoom || options.zoom}
      attributionControl={false}
      zoomControl={false}
    >
      <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png" />
      <Rectangle
        bounds={rectangleBounds}
        fillColor={theme.colors.trafficOverlay}
        fillOpacity={0.15}
        stroke={false}
      />
      {children}
      <MarkerClusterGroup
        maxClusterRadius={13}
        iconCreateFunction={(cluster: MarkerCluster): DivIcon => {
          const markers = cluster.getAllChildMarkers();
          return divIcon({
            html: `${markers.length}`,
            className: 'leaflet-notams-cluster',
            iconSize: point(20, 20),
          });
        }}
      >
        {camerasEnabled && renderCameras()}
      </MarkerClusterGroup>
    </LeafletMap>
  );
};

export default TrafficMap;
