import { track as amplitudeTrack } from '@amplitude/analytics-browser';
// Tried to import from `@amplitude/types`, but it doesn't work for some reason.

function track(
  eventInput: string,
  eventProperties?: any, // Need the type from @amplitude/types
  eventOptions?: any // Need the type from @amplitude/types
) {
  return amplitudeTrack(
    eventInput,
    { airport: process.env.REACT_APP_AIRPORT_CODE, ...eventProperties },
    eventOptions
  );
}

export default track;
