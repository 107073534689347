import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';

import { getDefaultSeriesOptions, getVisibility } from 'statistics/charts/utils';
import { ChartDatum } from 'statistics/types';

export default function getSeries(
  data: ChartDatum[],
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType {
  return merge(getDefaultSeriesOptions(), {
    yAxis: 'bookings',
    name: 'Bookings',
    pointPadding: 0.2,
    data,
    tooltip: {
      pointFormatter(): string {
        let { y } = this;
        const { series } = this;
        y = Math.ceil(y || 0);
        return `${series?.name}: <b>${y.toLocaleString()}</b><br/>`;
      },
    },
    ...chartOptions,
    type: 'line',
    visible: getVisibility('Bookings'),
  } as SeriesOptionsType);
}
