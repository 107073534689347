import { subDays } from 'date-fns';
import React, { createContext, useContext, useReducer } from 'react';

type Created = Date[] | string;

interface State {
  assignee: string;
  created: Created;
  createdValue: string;
  limit: number | null;
  offset: number | null;
  order: string[][];
  search: string;
  severity: string;
  status: string;
}

interface Action {
  type: 'update';
  payload: State;
}

const initialState: State = {
  assignee: '',
  created: '',
  createdValue: '',
  limit: null,
  offset: null,
  order: [['createdAt', 'ASC']],
  search: '',
  severity: '',
  status: 'open',
};

const IssuesFilterStateContext = createContext<State>(initialState);
const IssuesFilterDispatchContext = createContext<any>(null);

const actionTypes = {
  update: 'update',
};

function getBetweenRange(payload: State) {
  let created: Created = '';

  if (payload.created) {
    switch (payload.created) {
      case 'anytime': {
        created = '';
        break;
      }
      case 'past-week': {
        created = [subDays(new Date(), 7), new Date()];
        break;
      }
      case 'past-month': {
        created = [subDays(new Date(), 30), new Date()];
        break;
      }
      case 'past-year': {
        created = [subDays(new Date(), 365), new Date()];
        break;
      }
      default: {
        created = '';
        break;
      }
    }
  }

  return created;
}

function reducer(state: State, action: Action) {
  const { type, payload } = action;

  switch (type) {
    case 'update': {
      const created = getBetweenRange(payload);

      return {
        ...state,
        ...payload,
        created,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

const IssuesFilterProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <IssuesFilterStateContext.Provider value={state}>
      <IssuesFilterDispatchContext.Provider value={dispatch}>
        {children}
      </IssuesFilterDispatchContext.Provider>
    </IssuesFilterStateContext.Provider>
  );
};

function useIssuesFilterState() {
  const context = useContext(IssuesFilterStateContext);
  if (context === undefined) {
    throw new Error(`useIssuesFilterState must be used within a IssuesFilterProvider`);
  }
  return context;
}

function useIssuesFilterDispatch() {
  const context = useContext(IssuesFilterDispatchContext);
  if (context === undefined) {
    throw new Error(`useIssuesFilterDispatch must be used within a IssuesFilterProvider`);
  }
  return context;
}

function useIssuesFilter() {
  const context = [useIssuesFilterState(), useIssuesFilterDispatch()];
  if (context === undefined) {
    throw new Error(`useIssuesFiltermust be used within a IssuesFilterProvider`);
  }
  return context;
}

const updateFilter = (payload: object) => ({
  type: 'update',
  payload,
});

export {
  IssuesFilterProvider as default,
  useIssuesFilter,
  useIssuesFilterState,
  useIssuesFilterDispatch,
  actionTypes,
  updateFilter,
};
