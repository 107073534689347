import { themeGet } from '@styled-system/theme-get';
import styled, { keyframes } from 'styled-components';

import { Props as ToastProps } from 'components/Toast';

import system from './lib/system';
import { SystemProps } from './lib/types';
import { PAGINATION_BAR_HEIGHT } from './PaginationBar';

type Props = ToastProps & SystemProps;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Toast = styled.div<Props>`
  animation: ${fadeIn} 0.5s 1;
  animation-fill-mode: forwards;
  background-color: ${themeGet('colors.fg')};
  border-radius: 40px;
  bottom: calc(${PAGINATION_BAR_HEIGHT} + ${themeGet('space.base')});
  box-shadow: ${themeGet('shadows.l')};
  left: 50%;
  color: ${themeGet('colors.bg')};
  padding: ${themeGet('space.s')} ${themeGet('space.base')};
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
  max-width: 37.5rem;
  z-index: ${themeGet('zIndices.toast')};

  ${system}
`;

export default Toast;
