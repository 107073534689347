import React from 'react';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';
import WaitTime from './WaitTime';

import securityImg from 'images/security.jpg';

export const SecurityTile: React.FC = () => {
  if (process.env.REACT_APP_CONFIG_WAIT_TIMES !== 'true') return null;
  return (
    <Tile title="Security" icon="clock" backgroundImage={securityImg}>
      <RequiredPermission
        permission="read:security"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <WaitTime />
      </RequiredPermission>
    </Tile>
  );
};

export default SecurityTile;
