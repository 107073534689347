import { LatLng } from 'leaflet';
import React from 'react';
import { CircleMarker, Tooltip } from 'react-leaflet';

import { usePavementTemps } from 'tiles/Weather/hooks';

const PavementTemperatures: React.FC = () => {
  const pavementTemps = usePavementTemps({});

  if (!pavementTemps?.temps?.length) return null;

  return (
    <>
      {pavementTemps.temps.map(pt => {
        const position = new LatLng(pt.latitude, pt.longitude);
        if (!pt.roadTemp) return null;
        return (
          <CircleMarker
            radius={0}
            opacity={0}
            fillOpacity={0}
            center={position}
            key={pt.sensorName}
          >
            <Tooltip
              className="leaflet-tooltip-label-small"
              direction="center"
              opacity={1.0}
              permanent={true}
            >
              {`${pt.roadTemp}º`}
            </Tooltip>
          </CircleMarker>
        );
      })}
    </>
  );
};

export default PavementTemperatures;
