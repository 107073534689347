import { gql, QueryResult, useQuery } from '@apollo/client';

import { User } from 'client/User/types';

const USER_METADATA = gql`
  query UsersData($email: String!) {
    user(email: $email) {
      user_id
      user_metadata {
        alerts {
          lastSeenTimestamp
        }
        settings {
          emailAlerts
          emailInsights
          hasSeenTour
          dashboardLayout {
            page1
            page2
          }
        }
        tileOptions {
          airfield {
            views {
              available
              default
            }
            filters {
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          alerts {
            filters {
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          arrivals {
            filters {
              status
              limit
              types
              carrierType
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          bathroomTraffic {
            filters {
              selectedTypes
              types
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          capacity {
            filters {
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          capacitySummary {
            filters {
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          cargoRevenue {
            filters {
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          customerFeedback {
            filters {
              selectedSentiment
              isAttachmentShown
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          departures {
            filters {
              status
              limit
              types
              carrierType
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          flightSummary {
            filters {
              types
            }
          }
          facilityConditions {
            filters {
              areas
              severities
              status
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          sales {
            filters {
              areas
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          throughput {
            filters {
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
          parking {
            filters {
              defaultSortByColumn
              defaultIsReverseSort
            }
          }
        }
      }
    }
  }
`;

export default function useUserMetadata(email: string): QueryResult<{ user: User }> {
  return useQuery(USER_METADATA, {
    variables: { email },
  });
}
