import merge from 'lodash/merge';

import { getDefaultYAxisOptions } from 'statistics/charts/utils';

const axis: Highcharts.YAxisOptions = merge(getDefaultYAxisOptions(), {
  id: 'readRate',
  title: {
    text: 'Read Rate Percentage',
  },
} as Highcharts.YAxisOptions);

export default function getAxis(): Highcharts.YAxisOptions {
  return axis;
}
