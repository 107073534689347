// @ts-nocheck
import Color from 'color';
import { differenceInCalendarDays, getDay, isDate, isFuture, isSameDay, subDays } from 'date-fns';
import Highcharts, { Series, SeriesOptionsType } from 'highcharts';
import capitalize from 'lodash/capitalize';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import orderBy from 'lodash/orderBy';
import pull from 'lodash/pull';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';

import { PARKING } from 'config/parking';
import { ChartOptions } from 'config/tabs';
import theme from 'config/theme';
import { getNormalizedWeatherCondition } from 'config/weather';

import { getDollars } from 'utils';

import { LS_PREFIX } from 'hooks/useLocalStorage';

import { Action, State, updateOptions } from 'providers/ChartOptionsProvider';

import { getProcessorType, ProcessedDataObject } from 'statistics/processors/utils';
import {
  ArrivalsChartAirline,
  CapacityChartAirline,
  ChartDatum,
  DataType,
  DeparturesChartAirline,
  PassengerChartDatum,
} from 'statistics/types';

import * as charts from './';
import { CHART_TYPES, NUM_DAYS_FOR_DAILY_INTERVAL } from './constants';

const CVG_CHECKPOINTS = ['General', 'TSA Pre✓'];

const PHL_CHECKPOINTS = [
  'Terminal A-West General',
  'Terminal A-East General',
  'Terminal A-East TSA Pre✓',
  'Terminal D/E General',
  'Terminal D/E TSA Pre✓',
];

let hiddenSeries: string[] = [];

export function amountPointFormatter(this: Highcharts.Point): string {
  const { color, y = 0, series } = this;
  return `<span style="color:${color}">●</span> ${series?.name}: <b>${getDollars(y)}</b><br/>`;
}

export function isDaily(startDate: Date, endDate: Date): boolean {
  const numDays = differenceInCalendarDays(endDate, startDate) + 1;
  return numDays >= NUM_DAYS_FOR_DAILY_INTERVAL;
}

export function getChartTypeLabel(chartType: string): string {
  const sections: string[] = chartType.split('.');
  if (sections[0] === 'WAIT_TIMES') {
    const indexNumber = parseInt(sections[1]?.substring(sections[1].length - 1));
    if (parseInt(process.env.REACT_APP_CHECKPOINT_TOTAL || '5') === 5) {
      return PHL_CHECKPOINTS[indexNumber - 1];
    } else {
      return CVG_CHECKPOINTS[indexNumber - 1];
    }
  }
  if (sections[0] === 'DAILY_THROUGHPUT') {
    switch (sections[1]) {
      case 'DAILY':
        return 'Daily Throughput';
      case 'ROLLING_AVG':
        return 'Show 7-day rolling avg.';
      case 'ONE_YEAR_AGO':
        return 'Show one year ago';
      case 'YEAR_2019':
        return 'Show total from 2019';
    }
  }
  if (sections[0] === 'TSA_COUNTS') {
    switch (sections[1]) {
      case 'CURRENT':
        return 'Current year';
      case 'YEAR_2019':
        return 'Show data from 2019';
    }
  }
  const formattedSections: string[] = sections.map(section => {
    return section
      .split('_')
      .map(title => {
        // NOTE: LOT below since we have machine names that are 3 characters and need to be capitalized.
        const formatted = title.length > 3 || title === 'LOT' ? capitalize(title) : title;
        // We're passing the formatted section title to the PARKING
        // map that will translate the garage name, if present.
        return PARKING[formatted] || formatted;
      })
      .join(' ');
  });
  return formattedSections.join(' - ');
}

export function getChartDetailsComponent(chartType: string): string {
  switch (chartType) {
    case CHART_TYPES.ARRIVALS.PASSENGER:
    case CHART_TYPES.ARRIVALS.CARGO:
      return 'ArrivalDetails';
    case CHART_TYPES.DEPARTURES.PASSENGER:
    case CHART_TYPES.DEPARTURES.CARGO:
      return 'DepartureDetails';
    case CHART_TYPES.CARGO_REVENUE:
      return 'CargoRevenueDetails';
    case CHART_TYPES.BOOKINGS:
      return 'BookingDetails';
    case CHART_TYPES.BAGGAGE.B_BAGS:
    case CHART_TYPES.BAGGAGE.T_DRIVE:
    case CHART_TYPES.BAGGAGE.TOTAL:
    case CHART_TYPES.BAGGAGE.BAGS_RECEIVED:
    case CHART_TYPES.BAGGAGE.BAGS_CLEARED:
    case CHART_TYPES.BAGGAGE.ML1_READ_RATE:
    case CHART_TYPES.BAGGAGE.ML2_READ_RATE:
    case CHART_TYPES.BAGGAGE.CB1_READ_RATE:
      return 'BaggageDetails';
    case CHART_TYPES.COMMON_USE_KIOSKS.BAG_TAGS:
    case CHART_TYPES.COMMON_USE_KIOSKS.BOARDING_PASSES:
      return 'CommonUseKioskDetails';
    case CHART_TYPES.COMMON_USE.WORKSTATIONS:
      return 'CommonUseWorkstationDetails';
    case CHART_TYPES.COMMON_USE.BAG_TAGS:
      return 'CommonUseBagTagDetails';
    default:
      return '';
  }
}

export function getDefaultYAxisOptions(): Highcharts.YAxisOptions {
  return {
    gridLineColor: theme?.colors?.border,
    min: 0,
    stackLabels: {
      enabled: false,
    },
    visible: true,
    allowDecimals: false,
    title: {
      style: {
        color: theme?.colors?.subdued,
      },
    },
    labels: {
      style: {
        color: theme?.colors?.subdued,
      },
      formatter(): string {
        return `${this.value}`;
      },
    },
  };
}

export function setVisibility(key: string, value: boolean): void {
  const keyPath = `${LS_PREFIX}:${key}:visibility`;
  localStorage.setItem(keyPath, JSON.stringify(value));
}

export function getDefaultSeriesOptions(): Highcharts.SeriesOptionsType {
  return {
    visible: true,
    stacking: undefined,
    showInLegend: true,
    type: 'area',
    color: theme?.colors?.chart.gray,
    fillColor: undefined,
    dashStyle: undefined,
    colorByPoint: false,
    opacity: 1,
    lineWidth: 4,
    marker: {
      enabled: false,
    },
  };
}

export function getDefaultOptions(): Highcharts.Options {
  return {
    credits: {
      enabled: false,
    },
    time: {
      useUTC: false,
    },
    title: {
      text: '',
    },
    chart: {
      backgroundColor: 'transparent',
      events: {
        redraw: function (): void {
          this.series.forEach((s: Highcharts.Series) => {
            s.legendItem?.on('mouseover', () => {
              s.legendItem.element.childNodes.forEach(
                (n: ChildNode) => ((n as SVGElement).style.fill = s.options.color as string)
              );
            });
            s.legendItem?.on('mouseout', () => {
              s.legendItem.element.childNodes.forEach(
                (n: ChildNode) =>
                  ((n as SVGElement).style.fill =
                    this.legend.options.itemStyle?.color || theme.colors.fg)
              );
            });
          });
        },
      },
    },
    legend: {
      itemStyle: {
        color: theme?.colors?.fg,
      },
      itemHoverStyle: {
        color: theme?.colors?.subdued,
      },
      itemHiddenStyle: {
        color: theme?.colors?.border,
      },
      navigation: {
        style: {
          color: theme?.colors?.fg,
        },
        activeColor: theme?.colors?.fg,
        inactiveColor: theme?.colors?.subdued,
      },
    },
    navigation: {
      buttonOptions: {
        symbolStroke: theme?.colors?.fg,
        theme: {
          fill: 'transparent',
          states: {
            hover: {
              fill: 'transparent',
              stroke: theme?.colors?.fg,
            },
            select: {
              stroke: theme?.colors?.fg,
              fill: Color(theme?.colors?.fg).fade(0.7).toString(),
            },
          },
        },
        y: -10,
        x: 8,
      },
    },
    tooltip: {
      shared: true,
      formatter: undefined,
    },
    exporting: {
      enabled: true,
      csv: {
        dateFormat: '%Y-%m-%d %H:%M:%S',
        lineDelimiter: ' ',
      },
    },
    plotOptions: {
      series: {
        events: {
          hide: function (this: Series): void {
            hiddenSeries = union(hiddenSeries, [this.name]);
            setVisibility(this.name, false);
          },
          show: function (this: Series): void {
            pull(hiddenSeries, this.name);
            setVisibility(this.name, true);
          },
        },
      },
    },
  };
}

export function getSeriesOptions(
  options: Highcharts.SeriesOptionsType
): Highcharts.SeriesOptionsType {
  if (options.name) {
    options.visible = !hiddenSeries.includes(options.name);
  }

  return options;
}

export function getXAxes(
  chartTypes: string[],
  startDate: Date,
  endDate: Date
): Highcharts.YAxisOptions[] {
  const axes = chartTypes.map((type: string): Highcharts.YAxisOptions | undefined => {
    const intraday = !isDaily(startDate, endDate);
    switch (type) {
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES1:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES2:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES3:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES4:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES5:
        return charts.waitTimes.getXAxis(startDate, endDate, { intraday });
      case CHART_TYPES.ARRIVALS.PASSENGER:
        return charts.arrivals.passenger.getXAxis(startDate, endDate, { intraday });
      case CHART_TYPES.ARRIVALS.CARGO:
        return charts.arrivals.cargo.getXAxis(startDate, endDate, { intraday });
      case CHART_TYPES.DEPARTURES.PASSENGER:
        return charts.departures.passenger.getXAxis(startDate, endDate, { intraday });
      case CHART_TYPES.DEPARTURES.CARGO:
        return charts.departures.cargo.getXAxis(startDate, endDate, { intraday });
      case CHART_TYPES.FLIGHT_PERFORMANCE:
        return charts.flightPerformance.getXAxis(startDate, endDate);
      case CHART_TYPES.SALES.CONCOURSE_A:
        return charts.sales.concourseA.getXAxis(startDate, endDate);
      case CHART_TYPES.SALES.CONCOURSE_B:
        return charts.sales.concourseB.getXAxis(startDate, endDate);
      case CHART_TYPES.SALES.MAIN_TERMINAL:
        return charts.sales.mainTerminal.getXAxis(startDate, endDate);
      case CHART_TYPES.SALES.VALET:
        return charts.sales.valet.getXAxis(startDate, endDate);
      case CHART_TYPES.SALES.ECONO_LOT:
        return charts.sales.econoLot.getXAxis(startDate, endDate);
      case CHART_TYPES.SALES.SHORT_TERM:
        return charts.sales.shortTerm.getXAxis(startDate, endDate);
      case CHART_TYPES.SALES.LONG_TERM:
        return charts.sales.longTerm.getXAxis(startDate, endDate);
      case CHART_TYPES.SALES.TOTAL:
        return charts.sales.total.getXAxis(startDate, endDate);
      case CHART_TYPES.PASSENGERS.AGE:
        return charts.passengers.age.getXAxis();
      case CHART_TYPES.PASSENGERS.GENDER:
        return charts.passengers.gender.getXAxis();
      case CHART_TYPES.PASSENGERS.STATUS:
        return charts.passengers.status.getXAxis();
      case CHART_TYPES.DAILY_THROUGHPUT.DAILY:
      case CHART_TYPES.DAILY_THROUGHPUT.ROLLING_AVG:
      case CHART_TYPES.DAILY_THROUGHPUT.ONE_YEAR_AGO:
      case CHART_TYPES.DAILY_THROUGHPUT.YEAR_2019:
        return charts.dailyThroughputs.getXAxis(startDate, endDate);
      case CHART_TYPES.TSA_COUNTS.CURRENT:
      case CHART_TYPES.TSA_COUNTS.YEAR_2019:
        return charts.tsaCounts.getXAxis(startDate, endDate);
      case CHART_TYPES.BOOKINGS:
        return charts.bookings.getXAxis(startDate, endDate, { intraday });
      case CHART_TYPES.CARGO_REVENUE:
        return charts.cargoRevenue.getXAxis(startDate, endDate);
      case CHART_TYPES.BAGGAGE.B_BAGS:
        return charts.baggage.bBags.getXAxis(startDate, endDate);
      case CHART_TYPES.BAGGAGE.T_DRIVE:
        return charts.baggage.tDrive.getXAxis(startDate, endDate);
      case CHART_TYPES.BAGGAGE.BAGS_RECEIVED:
        return charts.baggage.bagsReceived.getXAxis(startDate, endDate);
      case CHART_TYPES.BAGGAGE.BAGS_CLEARED:
        return charts.baggage.bagsCleared.getXAxis(startDate, endDate);
      case CHART_TYPES.BAGGAGE.ML1_READ_RATE:
        return charts.baggage.readRateML1.getXAxis(startDate, endDate);
      case CHART_TYPES.BAGGAGE.ML2_READ_RATE:
        return charts.baggage.readRateML2.getXAxis(startDate, endDate);
      case CHART_TYPES.BAGGAGE.CB1_READ_RATE:
        return charts.baggage.readRateCB1.getXAxis(startDate, endDate);
      case CHART_TYPES.BAGGAGE.TOTAL:
        return charts.baggage.total.getXAxis(startDate, endDate);
      case CHART_TYPES.GATES.GATES1:
      case CHART_TYPES.GATES.GATES2:
      case CHART_TYPES.GATES.GATES3:
      case CHART_TYPES.GATES.GATES4:
        return charts.gates.getXAxis(startDate, endDate, { intraday });
      case CHART_TYPES.PROJECTION.AM:
        return charts.projection.am.getXAxis(startDate, endDate);
      case CHART_TYPES.PROJECTION.PM:
        return charts.projection.pm.getXAxis(startDate, endDate);
      case CHART_TYPES.DAILY_PROJECTION:
        return charts.dailyProjection.getXAxis(startDate, endDate, { intraday });
      case CHART_TYPES.COMMON_USE.WORKSTATIONS:
        return charts.commonUse.getXAxis(startDate, endDate);
      case CHART_TYPES.COMMON_USE.BAG_TAGS:
        return charts.bagTags.getXAxis(startDate, endDate);
      case CHART_TYPES.COMMON_USE_KIOSKS.BAG_TAGS:
        return charts.commonUseKiosks.bagTags.getXAxis(startDate, endDate);
      case CHART_TYPES.COMMON_USE_KIOSKS.BOARDING_PASSES:
        return charts.commonUseKiosks.boardingPasses.getXAxis(startDate, endDate);
      default:
        return undefined;
    }
  });

  return uniqBy(orderBy(axes, 'max', 'desc'), 'id').filter(axis =>
    Boolean(axis)
  ) as Highcharts.XAxisOptions[];
}

export function getYAxes(chartsOptions: ChartOptions[]): Highcharts.YAxisOptions[] {
  const axes = chartsOptions.map((chart: ChartOptions): Highcharts.YAxisOptions | undefined => {
    switch (chart.type) {
      case CHART_TYPES.DAILY_PROJECTION:
        return charts.dailyProjection.getYAxis();
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES1:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES2:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES3:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES4:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES5:
        return charts.waitTimes.getYAxis(chart.yAxisOptions);
      case CHART_TYPES.ARRIVALS.PASSENGER:
        return charts.arrivals.passenger.getYAxis();
      case CHART_TYPES.ARRIVALS.CARGO:
        return charts.arrivals.cargo.getYAxis();
      case CHART_TYPES.DEPARTURES.PASSENGER:
        return charts.departures.passenger.getYAxis();
      case CHART_TYPES.DEPARTURES.CARGO:
        return charts.departures.cargo.getYAxis();
      case CHART_TYPES.FLIGHT_PERFORMANCE:
        return charts.flightPerformance.getYAxis();
      case CHART_TYPES.SALES.CONCOURSE_A:
        return charts.sales.concourseA.getYAxis();
      case CHART_TYPES.SALES.CONCOURSE_B:
        return charts.sales.concourseB.getYAxis();
      case CHART_TYPES.SALES.MAIN_TERMINAL:
        return charts.sales.mainTerminal.getYAxis();
      case CHART_TYPES.SALES.VALET:
        return charts.sales.valet.getYAxis();
      case CHART_TYPES.SALES.SHORT_TERM:
        return charts.sales.shortTerm.getYAxis();
      case CHART_TYPES.SALES.LONG_TERM:
        return charts.sales.longTerm.getYAxis();
      case CHART_TYPES.SALES.ECONO_LOT:
        return charts.sales.econoLot.getYAxis();
      case CHART_TYPES.SALES.TOTAL:
        return charts.sales.total.getYAxis();
      case CHART_TYPES.PASSENGERS.AGE:
        return charts.passengers.age.getYAxis();
      case CHART_TYPES.PASSENGERS.GENDER:
        return charts.passengers.gender.getYAxis();
      case CHART_TYPES.PASSENGERS.STATUS:
        return charts.passengers.status.getYAxis();
      case CHART_TYPES.DAILY_THROUGHPUT.DAILY:
      case CHART_TYPES.DAILY_THROUGHPUT.ROLLING_AVG:
      case CHART_TYPES.DAILY_THROUGHPUT.ONE_YEAR_AGO:
      case CHART_TYPES.DAILY_THROUGHPUT.YEAR_2019:
        return charts.dailyThroughputs.getYAxis();
      case CHART_TYPES.TSA_COUNTS.CURRENT:
      case CHART_TYPES.TSA_COUNTS.YEAR_2019:
        return charts.tsaCounts.getYAxis();
      case CHART_TYPES.BOOKINGS:
        return charts.bookings.getYAxis();
      case CHART_TYPES.CARGO_REVENUE:
        return charts.cargoRevenue.getYAxis();
      case CHART_TYPES.BAGGAGE.B_BAGS:
        return charts.baggage.bBags.getYAxis();
      case CHART_TYPES.BAGGAGE.T_DRIVE:
        return charts.baggage.tDrive.getYAxis();
      case CHART_TYPES.BAGGAGE.BAGS_RECEIVED:
        return charts.baggage.bagsReceived.getYAxis();
      case CHART_TYPES.BAGGAGE.BAGS_CLEARED:
        return charts.baggage.bagsCleared.getYAxis();
      case CHART_TYPES.BAGGAGE.ML1_READ_RATE:
        return charts.baggage.readRateML1.getYAxis();
      case CHART_TYPES.BAGGAGE.ML2_READ_RATE:
        return charts.baggage.readRateML2.getYAxis();
      case CHART_TYPES.BAGGAGE.CB1_READ_RATE:
        return charts.baggage.readRateCB1.getYAxis();
      case CHART_TYPES.BAGGAGE.TOTAL:
        return charts.baggage.total.getYAxis();
      case CHART_TYPES.GATES.GATES1:
      case CHART_TYPES.GATES.GATES2:
      case CHART_TYPES.GATES.GATES3:
      case CHART_TYPES.GATES.GATES4:
        return charts.gates.getYAxis();
      case CHART_TYPES.PROJECTION.AM:
        return charts.projection.am.getYAxis();
      case CHART_TYPES.PROJECTION.PM:
        return charts.projection.pm.getYAxis();
      case CHART_TYPES.COMMON_USE.WORKSTATIONS:
        return charts.commonUse.getYAxis();
      case CHART_TYPES.COMMON_USE.BAG_TAGS:
        return charts.bagTags.getYAxis();
      case CHART_TYPES.COMMON_USE_KIOSKS.BAG_TAGS:
        return charts.commonUseKiosks.bagTags.getYAxis();
      case CHART_TYPES.COMMON_USE_KIOSKS.BOARDING_PASSES:
        return charts.commonUseKiosks.boardingPasses.getYAxis();
      default:
        return undefined;
    }
  });

  return uniqBy(axes, 'id').filter(axis => Boolean(axis)) as Highcharts.YAxisOptions[];
}

export function setFrozenDate(
  time: number,
  frozen: boolean,
  dispatch: React.Dispatch<Action>
): void {
  if (isFuture(time)) {
    if (frozen) {
      dispatch(updateOptions({ currentDate: new Date(), frozen: false }));
    }
  } else {
    dispatch(updateOptions({ currentDate: new Date(time), frozen: true }));
  }
}

export function getSeries(
  chartsOptions: ChartOptions[],
  data: { [key: string]: ProcessedDataObject } | undefined,
  chartState: State,
  dispatch: React.Dispatch<Action>
): SeriesOptionsType[] {
  const { dataStartDate, dataEndDate, frozen } = chartState;
  const series: SeriesOptionsType[] = [];
  chartsOptions.forEach((chart: ChartOptions, index: number) => {
    const processorType = getProcessorType(chart.type);
    const chartData = (processorType && data?.[processorType]?.chart?.data) || [];
    switch (chart.type) {
      case CHART_TYPES.DAILY_PROJECTION:
        return series.push(
          charts.dailyProjection.getSeries(chartData as ChartDatum[], (time: number) =>
            setFrozenDate(time, frozen, dispatch)
          )
        );
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES1:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES2:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES3:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES4:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES5:
        return series.push(
          charts.waitTimes.getSeries(chartData as ChartDatum[], chart?.seriesOptions, index)
        );
      case CHART_TYPES.CARGO_REVENUE:
        return series.push(charts.cargoRevenue.getSeries(chartData as ChartDatum[]));
      case CHART_TYPES.ARRIVALS.PASSENGER:
        return series.push(
          ...charts.arrivals.passenger.getSeries(
            dataStartDate,
            dataEndDate,
            chartData as ArrivalsChartAirline[],
            chart.seriesOptions
          )
        );
      case CHART_TYPES.ARRIVALS.CARGO:
        return series.push(
          ...charts.arrivals.cargo.getSeries(
            dataStartDate,
            dataEndDate,
            chartData as ArrivalsChartAirline[],
            chart.seriesOptions
          )
        );
      case CHART_TYPES.DEPARTURES.PASSENGER:
        return series.push(
          ...charts.departures.passenger.getSeries(
            dataStartDate,
            dataEndDate,
            chartData as DeparturesChartAirline[],
            chart.seriesOptions
          )
        );
      case CHART_TYPES.DEPARTURES.CARGO:
        return series.push(
          ...charts.departures.cargo.getSeries(
            dataStartDate,
            dataEndDate,
            chartData as DeparturesChartAirline[],
            chart.seriesOptions
          )
        );
      case CHART_TYPES.FLIGHT_PERFORMANCE:
        return series.push(
          ...charts.flightPerformance.getSeries(
            chartData as CapacityChartAirline[],
            chart.seriesOptions
          )
        );
      case CHART_TYPES.SALES.CONCOURSE_A:
        return series.push(
          charts.sales.concourseA.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.SALES.CONCOURSE_B:
        return series.push(
          charts.sales.concourseB.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.SALES.MAIN_TERMINAL:
        return series.push(
          charts.sales.mainTerminal.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.SALES.VALET:
        return series.push(
          charts.sales.valet.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.SALES.SHORT_TERM:
        return series.push(
          charts.sales.shortTerm.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.SALES.LONG_TERM:
        return series.push(
          charts.sales.longTerm.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.SALES.ECONO_LOT:
        return series.push(
          charts.sales.econoLot.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.SALES.TOTAL:
        return series.push(
          charts.sales.total.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.PASSENGERS.AGE:
        return series.push(
          charts.passengers.age.getSeries(chartData as PassengerChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.PASSENGERS.GENDER:
        return series.push(
          charts.passengers.gender.getSeries(
            chartData as PassengerChartDatum[],
            chart.seriesOptions
          )
        );
      case CHART_TYPES.PASSENGERS.STATUS:
        return series.push(
          charts.passengers.status.getSeries(
            chartData as PassengerChartDatum[],
            chart.seriesOptions
          )
        );
      case CHART_TYPES.DAILY_THROUGHPUT.DAILY:
      case CHART_TYPES.DAILY_THROUGHPUT.ROLLING_AVG:
      case CHART_TYPES.DAILY_THROUGHPUT.ONE_YEAR_AGO:
      case CHART_TYPES.DAILY_THROUGHPUT.YEAR_2019:
        return series.push(
          ...charts.dailyThroughputs.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.TSA_COUNTS.CURRENT:
      case CHART_TYPES.TSA_COUNTS.YEAR_2019:
        return series.push(
          ...charts.tsaCounts.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.BOOKINGS:
        return series.push(
          charts.bookings.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.BAGGAGE.B_BAGS:
        return series.push(
          charts.baggage.bBags.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.BAGGAGE.T_DRIVE:
        return series.push(
          charts.baggage.tDrive.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.BAGGAGE.BAGS_RECEIVED:
        return series.push(
          charts.baggage.bagsReceived.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.BAGGAGE.BAGS_CLEARED:
        return series.push(
          charts.baggage.bagsCleared.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.BAGGAGE.ML1_READ_RATE:
        return series.push(
          charts.baggage.readRateML1.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.BAGGAGE.ML2_READ_RATE:
        return series.push(
          charts.baggage.readRateML2.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.BAGGAGE.CB1_READ_RATE:
        return series.push(
          charts.baggage.readRateCB1.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.BAGGAGE.TOTAL:
        return series.push(
          charts.baggage.total.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.GATES.GATES1:
      case CHART_TYPES.GATES.GATES2:
      case CHART_TYPES.GATES.GATES3:
      case CHART_TYPES.GATES.GATES4:
        return series.push(
          charts?.gates?.getSeries(
            chartData as ChartDatum[],
            chart?.seriesOptions,
            chart?.key || '',
            index
          )
        );
      case CHART_TYPES.PROJECTION.AM:
        return series.push(
          charts.projection.am.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.PROJECTION.PM:
        return series.push(
          charts.projection.pm.getSeries(chartData as ChartDatum[], chart.seriesOptions)
        );
      case CHART_TYPES.COMMON_USE.WORKSTATIONS:
        return series.push(
          ...charts.commonUse.getSeries(chartData as CapacityChartAirline[], chart.seriesOptions)
        );
      case CHART_TYPES.COMMON_USE.BAG_TAGS:
        return series.push(
          ...charts.bagTags.getSeries(chartData as CapacityChartAirline[], chart.seriesOptions)
        );
      case CHART_TYPES.COMMON_USE_KIOSKS.BAG_TAGS:
        return series.push(
          charts.commonUseKiosks.bagTags.getSeries(chartData as ChartDatum[], chart?.seriesOptions)
        );
      case CHART_TYPES.COMMON_USE_KIOSKS.BOARDING_PASSES:
        return series.push(
          charts.commonUseKiosks.boardingPasses.getSeries(
            chartData as ChartDatum[],
            chart?.seriesOptions
          )
        );
      default:
        return undefined;
    }
  });
  return series.filter(s => Boolean(s)) as SeriesOptionsType[];
}

export function getOptions(chartTypes: string[]): Highcharts.Options {
  const options: Highcharts.Options = {};
  chartTypes.forEach((type: string): Highcharts.Options => {
    switch (type) {
      case CHART_TYPES.DAILY_PROJECTION:
        return merge(options, charts.dailyProjection.getOptions());
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES1:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES2:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES3:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES4:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES5:
        return merge(options, charts.waitTimes.getOptions());
      case CHART_TYPES.ARRIVALS.PASSENGER:
        return merge(options, charts.arrivals.passenger.getOptions());
      case CHART_TYPES.ARRIVALS.CARGO:
        return merge(options, charts.arrivals.cargo.getOptions());
      case CHART_TYPES.DEPARTURES.PASSENGER:
        return merge(options, charts.departures.passenger.getOptions());
      case CHART_TYPES.DEPARTURES.CARGO:
        return merge(options, charts.departures.cargo.getOptions());
      case CHART_TYPES.FLIGHT_PERFORMANCE:
        return merge(options, charts.flightPerformance.getOptions());
      case CHART_TYPES.SALES.CONCOURSE_A:
        return merge(options, charts.sales.concourseA.getOptions());
      case CHART_TYPES.SALES.CONCOURSE_B:
        return merge(options, charts.sales.concourseB.getOptions());
      case CHART_TYPES.SALES.MAIN_TERMINAL:
        return merge(options, charts.sales.mainTerminal.getOptions());
      case CHART_TYPES.SALES.VALET:
        return merge(options, charts.sales.valet.getOptions());
      case CHART_TYPES.SALES.SHORT_TERM:
        return merge(options, charts.sales.shortTerm.getOptions());
      case CHART_TYPES.SALES.LONG_TERM:
        return merge(options, charts.sales.longTerm.getOptions());
      case CHART_TYPES.SALES.ECONO_LOT:
        return merge(options, charts.sales.econoLot.getOptions());
      case CHART_TYPES.SALES.TOTAL:
        return merge(options, charts.sales.total.getOptions());
      case CHART_TYPES.PASSENGERS.AGE:
        return merge(options, charts.passengers.age.getOptions());
      case CHART_TYPES.PASSENGERS.GENDER:
        return merge(options, charts.passengers.gender.getOptions());
      case CHART_TYPES.PASSENGERS.STATUS:
        return merge(options, charts.passengers.status.getOptions());
      case CHART_TYPES.DAILY_THROUGHPUT.DAILY:
      case CHART_TYPES.DAILY_THROUGHPUT.ROLLING_AVG:
      case CHART_TYPES.DAILY_THROUGHPUT.ONE_YEAR_AGO:
      case CHART_TYPES.DAILY_THROUGHPUT.YEAR_2019:
        return merge(options, charts.dailyThroughputs.getOptions());
      case CHART_TYPES.TSA_COUNTS.CURRENT:
      case CHART_TYPES.TSA_COUNTS.YEAR_2019:
        return merge(options, charts.tsaCounts.getOptions());
      case CHART_TYPES.BOOKINGS:
        return merge(options, charts.bookings.getOptions());
      case CHART_TYPES.CARGO_REVENUE:
        return merge(options, charts.cargoRevenue.getOptions());
      case CHART_TYPES.BAGGAGE.B_BAGS:
        return merge(options, charts.baggage.bBags.getOptions());
      case CHART_TYPES.BAGGAGE.T_DRIVE:
        return merge(options, charts.baggage.bBags.getOptions());
      case CHART_TYPES.BAGGAGE.TOTAL:
        return merge(options, charts.baggage.bBags.getOptions());
      case CHART_TYPES.GATES.GATES1:
      case CHART_TYPES.GATES.GATES2:
      case CHART_TYPES.GATES.GATES3:
      case CHART_TYPES.GATES.GATES4:
        return merge(options, charts.gates.getOptions());
      case CHART_TYPES.PROJECTION.AM:
        return merge(options, charts.projection.am.getOptions());
      case CHART_TYPES.PROJECTION.PM:
        return merge(options, charts.projection.pm.getOptions());
      case CHART_TYPES.COMMON_USE.WORKSTATIONS:
        return merge(options, charts.commonUse.getOptions());
      case CHART_TYPES.COMMON_USE.BAG_TAGS:
        return merge(options, charts.bagTags.getOptions());
      case CHART_TYPES.COMMON_USE_KIOSKS.BAG_TAGS:
        return merge(options, charts.commonUseKiosks.bagTags.getOptions());
      case CHART_TYPES.COMMON_USE_KIOSKS.BOARDING_PASSES:
        return merge(options, charts.commonUseKiosks.boardingPasses.getOptions());
      default:
        return merge(options, undefined);
    }
  });

  return options;
}

export function getChartOptions(
  chartsOptions: ChartOptions[],
  data: { [key: string]: ProcessedDataObject } | undefined,
  state: State = { chartStartDate: new Date(), chartEndDate: new Date() } as State,
  dispatch: React.Dispatch<Action> = noop
): Highcharts.Options {
  const { chartStartDate, chartEndDate } = state;
  const chartTheme = getDefaultOptions();
  const chartTypes = chartsOptions.map(chart => chart.type);

  const series = getSeries(chartsOptions, data, state, dispatch);
  const xAxis = getXAxes(chartTypes, chartStartDate, chartEndDate);
  const yAxis = getYAxes(chartsOptions);
  const chartOptions = getOptions(chartTypes);

  let options = merge({} as Highcharts.Options, chartTheme);

  options = merge(options, chartOptions);
  options = merge(options, {
    series,
    xAxis,
    yAxis,
  });
  return options;
}

export function getVisibility(key: string): boolean {
  const keyPath = `${LS_PREFIX}:${key}:visibility`;
  const visible = localStorage.getItem(keyPath);
  return visible && JSON.parse(visible);
}

export function filterByDayRange(dateRangeDays: string[]): { byDayRange: Function } {
  const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const _getDay = (date: Date): string => {
    const i = getDay(date);
    return DAYS[i];
  };

  const byDayRange = (date: Date | number): boolean => {
    const _date = isDate(date) ? new Date(date) : new Date(Number(date));
    const day = _getDay(_date);
    return dateRangeDays.includes(day);
  };

  return { byDayRange };
}

export function getFilteredChartData(
  chartData: ChartDatum[],
  dateRangeDays: string[],
  axis?: 'x' | 'y'
): ChartDatum[] {
  const { byDayRange } = filterByDayRange(dateRangeDays);
  const _axis = axis ? axis : 'x';
  return chartData.filter((data: ChartDatum): boolean => byDayRange(data[_axis]));
}

export function getFilteredDataFromTime(
  chartData: { [key: string]: any },
  dateRangeDays: string[]
): { [key: string]: any } {
  const { byDayRange } = filterByDayRange(dateRangeDays);
  const _data: { [key: string]: any } = {};

  Object.keys(chartData).forEach((time: string) => {
    if (byDayRange(Number(time))) _data[time] = chartData[time];
  });

  return _data;
}

export function filterByWeatherConditions(
  weatherData: DataType[] | undefined,
  weatherConditions: string[] | undefined
): { byWeatherConditions: Function; filterChartDataByWeatherConditions: Function } {
  const timestampToDate = (t: number): Date => new Date(t);

  const datesToFilterBy = weatherData
    ?.filter((datum: any): boolean => {
      if (!weatherConditions) return true;
      const normalizedCondition = getNormalizedWeatherCondition(datum.summary);
      return weatherConditions.includes(normalizedCondition);
    })
    .map((datum: any) => timestampToDate(datum.date));

  const noWeatherFilter = !weatherConditions || weatherConditions.length === 0;

  const byWeatherConditions = (timestamp: number): boolean => {
    if (!datesToFilterBy || noWeatherFilter) return true;

    for (const filterDate of datesToFilterBy) {
      const chartDate = timestampToDate(timestamp);
      // We subtract a day from `chartDate` because we're adding a day
      // when fetching the range of days for the weather.
      if (isSameDay(filterDate, subDays(chartDate, 1))) return true;
    }

    return false;
  };

  const filterChartDataByWeatherConditions = (data: {
    [key: string]: any;
  }): { [key: string]: any } => {
    if (!datesToFilterBy || noWeatherFilter) return data;

    const _data: { [key: string]: any } = {};

    Object.keys(data).forEach((time: string) => {
      if (byWeatherConditions(Number(time))) _data[time] = data[time];
    });

    return _data;
  };

  return { byWeatherConditions, filterChartDataByWeatherConditions };
}
