import isEqual from 'lodash/isEqual';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { areas, severities } from 'config/facilityConditions';

import { useMetadata } from 'hooks';

import { CenteredLoader } from 'components';

interface Props {
  children?: any;
}

export interface State {
  airfield: {
    filters: {
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  alerts: {
    filters: {
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  arrivals: {
    filters: {
      status: string[];
      limit: number;
      types: string[];
      carrierType: string[];
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  bathroomTraffic: {
    filters: {
      types: string[];
      selectedTypes: string[];
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  capacity: {
    filters: {
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  capacitySummary: {
    filters: {
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  cargoRevenue: {
    filters: {
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  customerFeedback: {
    filters: {
      sentiment: string[];
      selectedSentiment: string[];
      isAttachmentShown: boolean;
    };
  };
  departures: {
    filters: {
      status: string[];
      limit: number;
      types: string[];
      carrierType: string[];
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  facilityConditions: {
    filters: {
      status: string[];
      severities: string[];
      areas: string[];
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  flightSummary: {
    filters: {
      types: string[];
    };
  };
  parking: {
    filters: {
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  projections: {
    filters: {
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  remoteParking: {
    filters: {
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  sales: {
    filters: {
      areas: string[];
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  status: 'initializing' | 'complete';
  throughput: {
    filters: {
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
  tsaCounts: {
    filters: {
      defaultSortByColumn: string;
      defaultIsReverseSort: boolean;
      sortByColumn: string;
      isReverseSort: boolean;
      needsUpdate: boolean;
    };
  };
}

interface Action {
  type: string;
  payload: any;
}

const initialState: State = {
  airfield: {
    filters: {
      defaultSortByColumn: 'statusName',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  alerts: {
    filters: {
      defaultSortByColumn: 'time',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  arrivals: {
    filters: {
      status: ['DELAYED', 'CANCELED'],
      limit: 10,
      types: ['passenger', 'cargo'],
      carrierType: ['nondhl', 'dhl', 'amazon'],
      defaultSortByColumn: 'time',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  bathroomTraffic: {
    filters: {
      types: ['Concourse A', 'Concourse B', 'Ticketing', 'Baggage Claim', 'Customs'],
      selectedTypes: ['Concourse A', 'Concourse B', 'Ticketing', 'Baggage Claim', 'Customs'],
      defaultSortByColumn: 'id',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  customerFeedback: {
    filters: {
      sentiment: ['Positive', 'Negative', 'Neutral'],
      selectedSentiment: ['Positive', 'Negative', 'Neutral'],
      isAttachmentShown: true,
    },
  },
  capacity: {
    filters: {
      defaultSortByColumn: 'time',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  capacitySummary: {
    filters: {
      defaultSortByColumn: 'name',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  cargoRevenue: {
    filters: {
      defaultSortByColumn: 'airline',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  departures: {
    filters: {
      status: ['DELAYED', 'CANCELED'],
      limit: 10,
      types: ['passenger', 'cargo'],
      carrierType: ['nondhl', 'dhl', 'amazon'],
      defaultSortByColumn: 'time',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  flightSummary: {
    filters: {
      types: ['passenger', 'cargo'],
    },
  },
  facilityConditions: {
    filters: {
      status: ['Issue'],
      severities,
      areas,
      defaultSortByColumn: 'feed',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  parking: {
    filters: {
      defaultSortByColumn: '',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  projections: {
    filters: {
      defaultSortByColumn: 'hourNum',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  remoteParking: {
    filters: {
      defaultSortByColumn: 'spotName',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  sales: {
    filters: {
      areas: ['CONCESSION', 'PARKING'],
      defaultSortByColumn: 'title',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  status: 'initializing',
  throughput: {
    filters: {
      defaultSortByColumn: 'airport',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
  tsaCounts: {
    filters: {
      defaultSortByColumn: 'hourNum',
      defaultIsReverseSort: false,
      sortByColumn: '',
      isReverseSort: false,
      needsUpdate: false,
    },
  },
};

const actionTypes = {
  update: 'update',
};

const TileOptionsProviderStateContext = createContext<State>(initialState);
const TileOptionsProviderDispatchContext = createContext<any>(null);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case actionTypes.update: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const TileOptionsProvider: React.FC<Props> = ({ children }: Props) => {
  const { metadata: tileOptions, loading: loadingMetadata } = useMetadata('tileOptions');
  const [state, dispatch] = useReducer(reducer, initialState);
  const loading = loadingMetadata || state.status === 'initializing';

  // Load user meta data into state
  useEffect(() => {
    const shouldRun = !loadingMetadata && state.status !== 'complete';
    const getFlightFilters = (type: 'arrivals' | 'departures') => ({
      filters: {
        status: tileOptions?.[type]?.filters?.status || state[type].filters.status,
        limit: tileOptions?.[type]?.filters?.limit || state[type].filters.limit,
        types: tileOptions?.[type]?.filters?.types || state[type].filters.types,
        carrierType: tileOptions?.[type]?.filters?.carrierType || state[type].filters.carrierType,
        defaultSortByColumn:
          tileOptions?.[type]?.filters?.defaultSortByColumn ||
          state[type].filters.defaultSortByColumn ||
          initialState[type].filters.defaultSortByColumn,
        defaultIsReverseSort:
          tileOptions?.[type]?.filters?.defaultIsReverseSort ||
          state[type].filters.defaultIsReverseSort ||
          initialState[type].filters.defaultIsReverseSort,
        isReverseSort:
          state[type].filters.isReverseSort ||
          tileOptions?.[type]?.filters?.defaultIsReverseSort ||
          state[type].filters.defaultIsReverseSort,
        sortByColumn:
          state?.[type]?.filters?.sortByColumn ||
          tileOptions?.[type]?.filters?.defaultSortByColumn ||
          state?.[type]?.filters?.defaultSortByColumn,
      },
    });

    const getFilters = (key: string) => {
      return {
        ...tileOptions?.[key],
        //@ts-expect-error
        ...state?.[key],
        filters: {
          ...tileOptions?.[key]?.filters,
          //@ts-expect-error
          ...state?.[key]?.filters,
          sortByColumn: tileOptions?.[key]?.filters?.defaultSortByColumn,
          isReverseSort: tileOptions?.[key]?.filters?.defaultIsReverseSort,
        },
      };
    };

    const getParkingFilters = (airportCode: string) => {
      return {
        ...tileOptions?.parking,
        ...state?.parking,
        filters: {
          ...tileOptions?.parking?.filters,
          ...state?.parking?.filters,
          sortByColumn:
            airportCode === 'RNO'
              ? 'sortOrder'
              : tileOptions?.parking?.filters?.defaultSortByColumn,
          isReverseSort: tileOptions?.parking?.filters?.defaultIsReverseSort,
        },
      };
    };

    if (shouldRun) {
      const shouldUpdateSettings = !isEqual(tileOptions, {
        airfield: state.airfield,
        alerts: state.alerts,
        arrivals: state.arrivals,
        bathroomTraffic: state.bathroomTraffic,
        capacity: state.capacity,
        capacitySummary: state.capacitySummary,
        cargoRevenue: state.cargoRevenue,
        customerFeedback: state.customerFeedback,
        departures: state.departures,
        facilityConditions: state.facilityConditions,
        flightSummary: state.flightSummary,
        remoteParking: state.remoteParking,
        projections: state.projections,
        sales: state.sales,
        throughput: state.throughput,
        tsaCounts: state.tsaCounts,
      });

      if (shouldUpdateSettings) {
        dispatch({
          type: actionTypes.update,
          payload: {
            airfield: getFilters('airfield'),
            alerts: getFilters('alerts'),
            arrivals: getFlightFilters('arrivals'),
            bathroomTraffic: getFilters('bathroomTraffic'),
            capacity: getFilters('capacity'),
            capacitySummary: getFilters('capacitySummary'),
            cargoRevenue: getFilters('cargoRevenue'),
            customerFeedback: tileOptions?.customerFeedback || state?.customerFeedback,
            departures: getFlightFilters('departures'),
            facilityConditions: getFilters('facilityConditions'),
            flightSummary: tileOptions?.flightSummary || state?.flightSummary,
            parking: getParkingFilters(process.env.REACT_APP_AIRPORT_CODE || ''),
            projections: getFilters('projections'),
            remoteParking: getFilters('remoteParking'),
            sales: getFilters('sales'),
            throughput: getFilters('throughput'),
            tsaCounts: getFilters('tsaCounts'),
            status: 'complete',
          },
        });
      } else {
        dispatch({ type: actionTypes.update, payload: { status: 'complete' } });
      }
    }
  }, [
    loadingMetadata,
    tileOptions,
    state.airfield,
    state.alerts,
    state.arrivals,
    state.bathroomTraffic,
    state.capacity,
    state.capacitySummary,
    state.cargoRevenue,
    state.customerFeedback,
    state.departures,
    state.facilityConditions,
    state.flightSummary,
    state.parking,
    state.projections,
    state.remoteParking,
    state.sales,
    state.status,
    state.throughput,
    state.tsaCounts,
    state,
  ]);

  if (loading) return <CenteredLoader label="Creating your layout..." />;

  return (
    <TileOptionsProviderStateContext.Provider value={state}>
      <TileOptionsProviderDispatchContext.Provider value={dispatch}>
        {children}
      </TileOptionsProviderDispatchContext.Provider>
    </TileOptionsProviderStateContext.Provider>
  );
};

// const state = useTileOptionsState();
function useTileOptionsState() {
  const context = useContext(TileOptionsProviderStateContext);
  if (context === undefined) {
    throw new Error(`useTileOptionsProviderState must be used within a TileOptionsProvider`);
  }
  return context;
}

// const dispatch = useTileOptionsDispatch();
function useTileOptionsDispatch() {
  const context = useContext(TileOptionsProviderDispatchContext);
  if (context === undefined) {
    throw new Error(`useTileOptionsProviderDispatch must be used within a TileOptionsProvider`);
  }
  return context;
}

// const [state, dispatch] = useTileOptions();
function useTileOptions() {
  const context = [useTileOptionsState(), useTileOptionsDispatch()];
  if (context === undefined) {
    throw new Error(`useTileOptions must be used within a TileOptionsProvider`);
  }
  return context;
}

// SELECTORS
const selectors = {};

// ACTION CREATORS
const updateOptions = (payload: any): Action => ({
  type: actionTypes.update,
  payload,
});

export {
  TileOptionsProvider as default,
  initialState,
  useTileOptions,
  useTileOptionsState,
  useTileOptionsDispatch,
  actionTypes,
  selectors,
  updateOptions,
};
