import React, { useEffect } from 'react';

import { TILE_KEYS } from 'config/tiles';

import { showModal, updateModal, useModal } from 'providers/ModalProvider';
import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';
import Notams from './Notams';

import NotamsDetails from './NotamsDetails';

export function AirfieldTile(): JSX.Element {
  const [optionsState, optionsDispatch] = useTileOptions();
  const [modalState, modalDispatch] = useModal();

  const modalProps = {
    title: 'Airfield',
    icon: { name: 'minimize' },
    large: true,
    children: <NotamsDetails />,
    width: 'auto',
  };

  // Update modal data if needed
  useEffect(() => {
    const isActiveModal = modalState?.activeModal?.props?.title === 'Airfield';
    const needsUpdate = optionsState?.airfield?.filters?.needsUpdate;
    if (isActiveModal && needsUpdate) {
      modalDispatch(updateModal('Default', modalProps));
      optionsDispatch(
        updateOptions({
          airfield: {
            ...optionsState?.airfield,
            filters: {
              ...optionsState?.airfield?.filters,
              needsUpdate: false,
            },
          },
        })
      );
    }
  }, [modalDispatch, modalState, modalProps, optionsDispatch, optionsState]);

  if (process.env.REACT_APP_CONFIG_NOTAMS !== 'true') return <NotAvailableBlankSlate />;
  const showMap = process.env.REACT_APP_INTEGRATION_AIRFIELD_TEMPS === 'true';

  function renderContent(): JSX.Element | null {
    return showMap ? (
      <RequiredPermission
        permission="read:notams"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <Notams showMap={true} filter={{ active: true, current: false }} />
      </RequiredPermission>
    ) : (
      <RequiredPermission
        permission="read:notams"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <NotamsDetails tile={true} />
      </RequiredPermission>
    );
  }

  return (
    <Tile
      title="Airfield"
      icon="minimize"
      floatingHeader={showMap}
      contentAlignment="top"
      contentPadding="0"
      modal="Default"
      modalProps={modalProps}
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Airfield Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.AIRFIELD,
              })
            ),
        },
      ]}
    >
      {renderContent()}
    </Tile>
  );
}

export default AirfieldTile;
