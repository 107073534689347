import React from 'react';

import { TILE_KEYS, TILES } from 'config/tiles';

import { ArrivalFlight } from 'data/Flights/types';

import { useTileArrivalFilter } from 'hooks';

import { showModal, useModalDispatch } from 'providers/ModalProvider';
import { useTileOptionsState } from 'providers/TileOptionsProvider';

import { Tile } from 'components';
import Arrivals from './Arrivals';

export const ArrivalsTile: React.FC = () => {
  const optionsState = useTileOptionsState();
  const modalDispatch = useModalDispatch();
  const { tileFilter } = useTileArrivalFilter({
    key: 'arrivals',
    setFlightDate: (flight: ArrivalFlight) =>
      new Date(
        flight.actualArrivalDate || flight.estimatedArrivalDate || flight.scheduledArrivalDate
      ),
  });
  const tileConfig = TILES?.find(tile => tile.title === 'Arrivals');
  const headings = tileConfig?.headings || [];
  const {
    arrivals: { filters },
  } = optionsState;

  return (
    <Tile
      title="Arrivals"
      icon="arrowDownAndLeft"
      contentAlignment="top"
      contentPadding="0"
      modal="Arrivals"
      modalProps={{
        stateKey: 'arrivals',
      }}
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Arrivals Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.ARRIVALS,
              })
            ),
        },
      ]}
    >
      <Arrivals
        limit={filters.limit}
        headings={headings}
        isReverseSort={filters?.isReverseSort}
        filter={tileFilter}
        filterBlankSlateIcon={{ name: 'check', color: 'primary', size: '30' }}
        filterEmptyMessage="No arrivals matching parameters."
        sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
      />
    </Tile>
  );
};

export default ArrivalsTile;
