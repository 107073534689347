import Highcharts from 'highcharts';

export default function getOptions(): Highcharts.Options {
  return {
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
  };
}
