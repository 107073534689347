import React from 'react';
import { useLocation } from 'react-router-dom';

import { Icon, RequiredPermission } from 'components';

import { Box as TourWrapper, HeaderNav as StyledHeaderNav, HeaderNavLink } from 'styled';

const HeaderNav: React.FC = () => {
  const location = useLocation();
  const isPageActive = (url: string): string => `${location?.pathname === url}`;

  return (
    <StyledHeaderNav>
      <RequiredPermission permission="feature:dashboard">
        <HeaderNavLink to="/" active={isPageActive('/')}>
          <TourWrapper id="dashboard-tab">
            <Icon name="barChart" size="20" mr="s" />
            Dashboard
          </TourWrapper>
        </HeaderNavLink>
      </RequiredPermission>
      <RequiredPermission permission="feature:reports">
        <HeaderNavLink to="/reports" active={isPageActive('/reports')}>
          <TourWrapper id="reports-tab">
            <Icon name="pieChart" size="20" mr="s" />
            Reports
          </TourWrapper>
        </HeaderNavLink>
      </RequiredPermission>
      <RequiredPermission permission="feature:issues">
        <HeaderNavLink to="/issues" active={isPageActive('/issues')}>
          <TourWrapper id="issues-tab">
            <Icon name="list" size="20" mr="s" />
            Issues
          </TourWrapper>
        </HeaderNavLink>
      </RequiredPermission>
    </StyledHeaderNav>
  );
};

export default HeaderNav;
