// @ts-nocheck
import groupBy from 'lodash/groupBy';

import { getCostMultiplier } from 'config/airlines';
import { AircraftWeight, findWeight } from 'config/weights';

import { ArrivalFlight } from 'data/Flights/types';

interface CargoAirline {
  [key: string]: {
    weight: number;
    cost: number;
    operations: number;
  };
}

export type Cargo = ArrivalFlight & AircraftWeight & { cost?: number };

class CargoData {
  flights: Cargo[];
  airlines: CargoAirline;
  totalWeight: number;
  totalCost: number;
  totalOperations: number;

  constructor({ flights }: { flights: ArrivalFlight[] }) {
    this.flights = this.normalizeFlights(flights);
    this.airlines = {};
    this.totalWeight = 0;
    this.totalCost = 0;
    this.totalOperations = 0;

    this.normalizeAirlines();
  }

  normalizeFlights(flights: ArrivalFlight[]): Cargo[] {
    const filteredFlights = flights.filter((flight: Flight) => {
      const notPassenger = !flight?.isPassenger;
      const hasCargoCarrier = flight?.cargoCarrier;
      return notPassenger && hasCargoCarrier;
    });

    return filteredFlights.map((flight: ArrivalFlight) => {
      const weight = findWeight(flight);
      const airline = flight.marketingCarrier;
      const costMultiplier = getCostMultiplier(airline);
      const cost = weight.weight * costMultiplier;
      return {
        ...flight,
        ...weight,
        cost,
      };
    });
  }

  normalizeAirlines(): void {
    const filteredAirlines: CargoAirline = {};

    this.completedFlights.forEach((cargo: Cargo) => {
      const airline = cargo.marketingCarrier;
      if (!(airline in filteredAirlines)) {
        filteredAirlines[airline] = {
          weight: 0,
          cost: 0,
          operations: 0,
        };
      }

      const weight = cargo.weight || 0;
      filteredAirlines[airline].weight += weight;
      this.totalWeight += weight;

      const costMultiplier = getCostMultiplier(airline);
      // Truncate to 2 decimals
      const cost = weight * costMultiplier;
      filteredAirlines[airline].cost += cost;
      this.totalCost += cost;
    });

    const cargoesByAirline = groupBy(this.completedFlights, flight => flight.marketingCarrier);
    Object.keys(cargoesByAirline).forEach((airline: string) => {
      const count = cargoesByAirline[airline].length;
      filteredAirlines[airline].operations = count;
      this.totalOperations += count;
    });

    this.airlines = filteredAirlines;
  }

  get completedFlights(): Cargo[] {
    return this.flights;
  }
}

export default CargoData;
