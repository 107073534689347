import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { HEADER_HEIGHT } from './Header';
import system from './lib/system';
import { SystemProps } from './lib/types';

const TRANSITION_TIMING = '0.3s';

const Modal = styled.div<{
  loaded: boolean;
  width?: string;
  large?: boolean;
  fullscreen?: boolean;
}>`
  background-color: ${themeGet('colors.neutralsGradient.11')};
  border-radius: ${themeGet('radii.base')};
  box-shadow: ${themeGet('shadows.l')};
  left: 50%;
  opacity: 0;
  max-height: calc(100vh - 200px);
  overflow: auto;
  padding: ${themeGet('space.l')};
  position: fixed;
  top: calc(${HEADER_HEIGHT} + ${themeGet('space.l')});
  transform: translate(-50%, 10%) scale(0.8);
  transition: ${TRANSITION_TIMING} ease-in-out;
  z-index: ${themeGet('zIndices.modal')};

  ${props =>
    props.loaded &&
    css`
      opacity: 1;
      transform: translate(-50%, 0) scale(1);
      transition: ${TRANSITION_TIMING} ${TRANSITION_TIMING} ease-in-out;
    `};

  ${props =>
    props.large &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      bottom: 0;
      left: ${themeGet('space.l')};
      max-height: 100%;
      right: ${themeGet('space.l')};
      top: ${themeGet('space.l')};
      transform: none;
    `};

  ${props =>
    props.fullscreen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      bottom: 0;
      left: 0;
      max-height: 100%;
      padding: 0;
      right: 0;
      top: 0;
      transform: none;
    `};

  ${system}
`;

const ModalClose = styled.button<SystemProps>`
  display: block;
  font-size: ${themeGet('fontSizes.base')};
  line-height: ${themeGet('lineHeights.base')};
  padding: 0;

  ${system}
`;

const ModalContent = styled.div<SystemProps>`
  ${system}
`;

const ModalHeader = styled.header<SystemProps>`
  align-items: center;
  display: flex;
  margin-bottom: ${themeGet('space.l')};

  ${system}
`;

const ModalOverlay = styled.div<{ loaded: boolean }>`
  background-color: transparent;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: ${TRANSITION_TIMING} ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: ${themeGet('zIndices.modalOverlay')};

  ${props =>
    props.loaded &&
    css`
      visibility: visible;
      background-color: ${themeGet('colors.neutralsGradient.17')};
    `};

  ${system}
`;

export { Modal as default, ModalClose, ModalContent, ModalHeader, ModalOverlay };
