import React from 'react';

import { TILE_KEYS, TILES } from 'config/tiles';

import { DepartureFlight } from 'data/Flights/types';

import { useTileDepartureFilter } from 'hooks';

import { showModal, useModalDispatch } from 'providers/ModalProvider';
import { useTileOptionsState } from 'providers/TileOptionsProvider';

import { Tile } from 'components';
import { Departures } from 'components/Tiles';

export const DeparturesTile: React.FC = () => {
  const optionsState = useTileOptionsState();
  const modalDispatch = useModalDispatch();
  const { tileFilter } = useTileDepartureFilter({
    key: 'departures',
    setFlightDate: (flight: DepartureFlight) =>
      new Date(
        flight.actualDepartureDate || flight.estimatedDepartureDate || flight.scheduledDepartureDate
      ),
  });
  const {
    departures: { filters },
  } = optionsState;

  const tileConfig = TILES?.find(tile => tile.title === 'Departures');
  const headings = tileConfig?.headings || [];

  return (
    <Tile
      title="Departures"
      icon="arrowUpAndRight"
      contentAlignment="top"
      contentPadding="0"
      modal="Departures"
      modalProps={{
        stateKey: 'departures',
      }}
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Departures Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.DEPARTURES,
              })
            ),
        },
      ]}
    >
      <Departures
        limit={filters?.limit}
        headings={headings}
        isReverseSort={filters?.isReverseSort}
        filter={tileFilter}
        filterBlankSlateIcon={{ name: 'check', color: 'primary', size: '30' }}
        filterEmptyMessage="No departures matching parameters."
        sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
      />
    </Tile>
  );
};

export default DeparturesTile;
