import React, { useEffect } from 'react';

import { useDataLimiter } from 'hooks';

import { Table, Tile } from 'components';
import { DetailsComponentProps } from 'components/ChartDetails';

import DetailsNav from './DetailsNav';
import { CommonUseWorkstation } from 'data/CommonUse/types';

const LIMIT = 20;

const CommonUseWorkstationDetails: React.FC<DetailsComponentProps> = ({
  data,
}: DetailsComponentProps) => {
  const dataLimiter = useDataLimiter(LIMIT);

  useEffect(() => {
    dataLimiter.setData(data as CommonUseWorkstation[]);
  }, [data, dataLimiter]);

  if (!data) return null;

  if (dataLimiter.status === 'loading') return null;

  const _data = data as CommonUseWorkstation[];

  return (
    <Tile
      title={`Common Use Workstations`}
      icon="briefcase"
      contentAlignment="top"
      contentPadding="0"
    >
      <Table
        grid="repeat(6, 1fr)"
        striped={false}
        bordered={true}
        cellPaddingX="s"
        cellPaddingY="s"
        dataFontSize="s"
        gridGap="xs"
        headings={[
          { key: 'date', label: 'Date' },
          { key: 'workstation', label: 'Workstation' },
          { key: 'position', label: 'Position' },
          { key: 'description', label: 'Description' },
          { key: 'airline', label: 'Airline' },
          { key: 'duration', label: 'Duration' },
        ]}
        data={_data}
      />
      <DetailsNav dataLimiter={dataLimiter} />
    </Tile>
  );
};

export default CommonUseWorkstationDetails;
