import React from 'react';

import { SortByOptions } from 'components';

const CapacityOptions: React.FC = () => {
  return (
    <>
      <SortByOptions
        stateKey="capacity"
        options={[
          { value: 'city', label: 'City' },
          { value: 'flightNum', label: 'Flight' },
          { value: 'time', label: 'Time' },
          { value: 'capacity', label: 'Capacity' },
        ]}
      />
    </>
  );
};

export default CapacityOptions;
