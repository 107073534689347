import Color from 'color';
import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';

import { CHART_COLORS_ARRAY } from 'config/chartColors';

import { getDefaultSeriesOptions, getVisibility, isDaily } from 'statistics/charts/utils';
import { ArrivalsChartAirline, FlightType } from 'statistics/types';

const ARRIVAL_CHART_NAMES: { [key: string]: 'Passenger Arrivals' | 'Cargo Arrivals' } = {
  passenger: 'Passenger Arrivals',
  cargo: 'Cargo Arrivals',
};

export default function getSeries(
  startDate: Date,
  endDate: Date,
  data: ArrivalsChartAirline[],
  area: FlightType,
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType[] {
  const type = isDaily(startDate, endDate) ? 'line' : 'column';

  return data.map(({ airline, data: chartData }, i) => {
    const name =
      airline === 'Total' ? ARRIVAL_CHART_NAMES[area] : `${airline} ${ARRIVAL_CHART_NAMES[area]}`;
    const color = CHART_COLORS_ARRAY[i % CHART_COLORS_ARRAY.length];
    return merge(getDefaultSeriesOptions(), {
      name,
      yAxis: 'flights',
      stack: 'flights',
      stacking: type === 'column' ? 'normal' : null,
      data: chartData,
      borderWidth: 0,
      crisp: false,
      grouping: false,
      color: area === 'passenger' ? color : Color(color).negate().toString(),
      visible: getVisibility(name),
      tooltip: {
        pointFormatter(): string {
          let { y } = this;
          const { color, series, index } = this;
          y = Math.ceil(y || 0);
          let total = 0;
          series?.chart?.series?.forEach(s => {
            if ((Object.values(ARRIVAL_CHART_NAMES) as string[]).includes(s.name)) {
              total += s.yData[index];
            }
          });
          return `<span style="color:${color}">●</span> ${
            series?.name
          }: <b>${y.toLocaleString()} / ${total.toLocaleString()}</b><br/>`;
        },
      },
      ...chartOptions,
      type, // chartOptions has a default type on it from the type definition, so it would overwrite the type provided here
    } as SeriesOptionsType);
  });
}
