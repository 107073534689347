export type CommonUseAirlineCode =
  | '7H'
  | 'AA'
  | 'AS'
  | 'B6'
  | 'DL'
  | 'F9'
  | 'G4'
  | 'NK'
  | 'SY'
  | 'UA'
  | 'WN'
  | 'Y4';

export type CommonUseAirlineName =
  | 'American'
  | 'Alaska'
  | 'JetBlue'
  | 'Delta'
  | 'Frontier'
  | 'Allegiant'
  | 'New Pacific'
  | 'Spirit'
  | 'Sun Country'
  | 'United'
  | 'Southwest'
  | 'Volaris';

export const COMMON_USE_AIRLINES: { [key: string]: CommonUseAirlineName } = {
  '7H': 'New Pacific',
  AA: 'American',
  AS: 'Alaska',
  B6: 'JetBlue',
  DL: 'Delta',
  F9: 'Frontier',
  G4: 'Allegiant',
  NK: 'Spirit',
  SY: 'Sun Country',
  UA: 'United',
  WN: 'Southwest',
  Y4: 'Volaris',
};

export const COMMON_USE_AIRLINE_CODES = Object.keys(COMMON_USE_AIRLINES);
export const COMMON_USE_AIRLINE_NAMES = Object.values(COMMON_USE_AIRLINES);
