import theme from 'config/theme';

export default function getAxis(): Highcharts.XAxisOptions {
  return {
    id: 'category',
    type: 'category',
    lineColor: theme?.colors?.border,
    tickColor: theme?.colors?.fg,
    labels: {
      style: {
        color: theme?.colors?.subdued,
      },
    },
    crosshair: true,
  };
}
