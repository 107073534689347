import { parseISO } from 'date-fns';

import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import sum from 'lodash/sum';

import { filterByDayRange } from 'statistics/charts/utils';
import { ChartDatum, DataObject } from 'statistics/types';

import { ProcessedDataObject } from './utils';
import { CommonUseKiosk } from 'data/CommonUse/types';

type KioskDataType = 'bagTags' | 'boardingPasses';

function getKiosksChartData(kiosks: CommonUseKiosk[], kioskDataType: KioskDataType): ChartDatum[] {
  const data: ChartDatum[] = [];
  const groupedKiosks = groupBy(kiosks, 'date');
  Object.keys(groupedKiosks).forEach(key => {
    const kiosks = groupedKiosks[key];
    data.push({
      x: parseISO(key).getTime(),
      y: sum(kiosks.map(kiosk => (kioskDataType === 'bagTags' ? kiosk.btp : kiosk.atb))),
    });
  });
  return sortBy(data, 'x');
}

function getFilteredKiosks(data: CommonUseKiosk[], airline: string[]): CommonUseKiosk[] {
  const filtered = data.filter((kiosk: CommonUseKiosk) => airline.includes(kiosk.airline));
  return filtered;
}

function getProcessedKiosks(
  kioskData: DataObject,
  dateRangeDays: string[],
  airline: string[],
  kioskDataType: KioskDataType
): ProcessedDataObject {
  const { data: kData, loading, error } = kioskData;

  if (loading || error)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      details: {
        loading,
        error,
        data: [],
      },
    };

  const kiosks = (kData as CommonUseKiosk[]) || [];

  const _data: CommonUseKiosk[] =
    airline.length > 0
      ? getFilteredKiosks(kiosks as CommonUseKiosk[], airline)
      : (kiosks as CommonUseKiosk[]);

  const { byDayRange } = filterByDayRange(dateRangeDays);
  const filteredKiosksByDayRange = _data.filter((datum: CommonUseKiosk) =>
    byDayRange(parseISO(datum.date))
  );

  const chartData = getKiosksChartData(kiosks as CommonUseKiosk[], kioskDataType);

  return {
    chart: {
      loading,
      error,
      data: chartData,
    },
    details: {
      loading,
      error,
      data: filteredKiosksByDayRange,
    },
  };
}

export default {
  bagTags: {
    getProcessedData: (
      kioskData: DataObject,
      dateRangeDays: string[],
      airline: string[]
    ): ProcessedDataObject => getProcessedKiosks(kioskData, dateRangeDays, airline, 'bagTags'),
  },
  boardingPasses: {
    getProcessedData: (
      kioskData: DataObject,
      dateRangeDays: string[],
      airline: string[]
    ): ProcessedDataObject =>
      getProcessedKiosks(kioskData, dateRangeDays, airline, 'boardingPasses'),
  },
};
