import { init, identify, Identify } from '@amplitude/analytics-browser';

function initializeAmplitude() {
  if (process.env.REACT_APP_ENV === 'production') {
    init(process.env.REACT_APP_AMPLITUDE_API_KEY as string);
    const identifyEvent = new Identify();
    identifyEvent.set('airport', process.env.REACT_APP_AIRPORT_CODE || '');
    identify(identifyEvent);
  } else {
    init('');
  }
}

export default initializeAmplitude;
