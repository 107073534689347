import { default as arrivals } from './arrivals';
import { default as baggage } from './baggage';
import { default as bookings } from './bookings';
import { default as cargoRevenue } from './cargoRevenue';
import { default as commonUse } from './commonUse';
import { default as commonUseKiosks } from './commonUseKiosks';
import { default as dailyThroughputs } from './dailyThroughputs';
import { default as departures } from './departures';
import { default as flightPerformance } from './flightPerformance';
import { default as gates } from './gates';
import { default as passengers } from './passengers';
import { default as sales } from './sales';
import { default as waitTimes } from './waitTimes';
import { default as projection } from './projection';
import { default as dailyProjection } from './dailyProjection';
import { default as tsaCounts } from './tsaCounts';

export default {
  arrivals,
  baggage,
  bookings,
  cargoRevenue,
  commonUse,
  commonUseKiosks,
  dailyThroughputs,
  departures,
  flightPerformance,
  gates,
  passengers,
  sales,
  waitTimes,
  projection,
  dailyProjection,
  tsaCounts,
};
