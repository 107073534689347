import Highcharts from 'highcharts';

import { TOOLTIP_HEADER_FORMAT } from 'statistics/charts/constants';

export default function getOptions(): Highcharts.Options {
  return {
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    tooltip: {
      headerFormat: TOOLTIP_HEADER_FORMAT,
    },
  };
}
