import { eachDayOfInterval, endOfDay, startOfDay } from 'date-fns';
import countBy from 'lodash/countBy';
import uniqBy from 'lodash/uniqBy';

import { getAirline } from 'config/airlines';

import { FlightData } from 'utils/departures';
import { passengerFlights } from 'utils/departures/PassengerFlightData';

import { DepartureFlight } from 'data/Flights/types';

import { filterByDayRange, filterByWeatherConditions } from 'statistics/charts/utils';
import {
  DataObject,
  FilteredPerformanceData,
  PerformanceChartDatum,
  StatsData,
} from 'statistics/types';

import { ProcessedDataObject } from './utils';

type Performances = { [key: number]: { [key: string]: number } };

function getFilteredData(
  startDate: Date,
  endDate: Date,
  departures: DepartureFlight[],
  airline: string[] = [],
  destinationAirport?: string
): FilteredPerformanceData {
  const filteredData: FilteredPerformanceData = { Total: { departures: [] } };
  airline.forEach(a => {
    const _airline = getAirline(a);
    if (_airline) {
      filteredData[_airline.iata] = { departures: [] };
    }
  });

  const start = startOfDay(startDate).getTime();
  const end = endOfDay(endDate).getTime();

  uniqBy(departures, 'id').forEach(departure => {
    const isPassenger = departure.isPassenger;
    const withinTimeframe =
      departure.scheduledDepartureDate >= start && departure.scheduledDepartureDate <= end;
    const validAirport = !destinationAirport || departure.destination === destinationAirport;
    const flightAirlineCode = getAirline(departure.marketingCarrier)?.iata || 'N/A';
    const validAirline =
      (flightAirlineCode && flightAirlineCode in filteredData) || !airline.length;

    if (!validAirline || !validAirport || !isPassenger || !withinTimeframe) return;

    if (airline.length > 1 && flightAirlineCode && flightAirlineCode in filteredData) {
      filteredData[flightAirlineCode].departures.push(departure);
    }

    filteredData.Total.departures.push(departure);
  });

  Object.entries(filteredData).forEach(([airlineCode, data]) => {
    if (!(airline.length > 1) && data.departures.length === 0 && airlineCode !== 'Total')
      delete filteredData[airlineCode];
  });

  return filteredData;
}

async function getPerformances(departures: DepartureFlight[]): Promise<Performances> {
  const performances: Performances = {};

  const days: number[] = [];
  const dayPerformance: { [key: number]: string[] } = {};

  departures.forEach((d: DepartureFlight) => {
    const day = startOfDay(d.scheduledDepartureDate);
    const time = day.getTime();

    if (!days.includes(time)) days.push(time);
    if (!(time in dayPerformance)) dayPerformance[time] = [];

    const flightData = new FlightData({ flight: d });
    dayPerformance[time].push(flightData.flightStatus.statusText);
  });

  await Promise.all(
    days.map(async (day: number) => {
      const count = countBy(dayPerformance[day], p => {
        if (['COMPLETED', 'ON_TIME', 'ON TIME'].includes(p)) return 'ON TIME';
        if (['DELAYED'].includes(p)) return 'DELAYED';
        if (['CANCELED'].includes(p)) return 'CANCELED';
        return 'OTHER';
      });

      performances[day] = count;
    })
  );

  return performances;
}

function getChartData(range: Date[], performances: Performances): PerformanceChartDatum {
  const statuses = ['ON TIME', 'DELAYED', 'CANCELED', 'OTHER'];
  const data: PerformanceChartDatum = { 'ON TIME': [], DELAYED: [], CANCELED: [], OTHER: [] };
  range.forEach((day: Date) => {
    statuses.forEach(status => {
      data[status].push({
        x: new Date(day).getTime(),
        y: performances[day.getTime()]?.[status] || 0,
      });
    });
  });

  return data;
}

function getStatsData(): StatsData {
  return {};
}

async function getProcessedData(
  startDate: Date,
  endDate: Date,
  departuresData: DataObject,
  weatherData: DataObject,
  dateRangeDays: string[],
  destinationAirport?: string,
  airline?: string[],
  weather?: string[]
): Promise<ProcessedDataObject> {
  const { data: departures, loading, error } = departuresData;
  const passengerDepartures = passengerFlights(departures as DepartureFlight[], {
    type: 'departures',
  });
  const { loading: weatherLoading, error: weatherError, data: _weatherData } = weatherData;
  const hasWeatherDataAndLoading = weather && weather.length > 0 && weatherLoading;
  const hasWeatherDataAndError = weather && weather.length > 0 && weatherError;

  if (loading || error || hasWeatherDataAndLoading || hasWeatherDataAndError)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
      details: {
        loading,
        error,
        data: [],
      },
    };

  const filteredData = getFilteredData(
    startDate,
    endDate,
    passengerDepartures,
    airline,
    destinationAirport
  );

  const range = eachDayOfInterval({ start: startDate, end: endDate });
  const { byDayRange } = filterByDayRange(dateRangeDays);
  const { byWeatherConditions } = filterByWeatherConditions(_weatherData, weather);
  const filteredChartData: FilteredPerformanceData = { Total: { departures: [] } };

  Object.entries(filteredData).forEach(([key, datum]) => {
    const filteredDepartures: DepartureFlight[] = datum.departures.filter(
      (datum: DepartureFlight) =>
        byDayRange(datum.scheduledDepartureDate) &&
        byWeatherConditions(datum.scheduledDepartureDate)
    );
    filteredChartData[key] = {
      departures: filteredDepartures,
    };
  });

  const airlinePerformances = await Promise.all(
    Object.entries(filteredChartData).map(async ([airlineCode, fd]) => {
      const performances = await getPerformances(fd.departures);
      const airlineChartData = getChartData(range, performances);
      return { airline: airlineCode, data: airlineChartData };
    })
  );

  return {
    chart: {
      loading,
      error,
      data: airlinePerformances,
    },
    stats: {
      loading,
      error,
      data: getStatsData(),
    },
    details: {
      loading,
      error,
      data: filteredChartData.Total,
    },
  };
}

export default {
  getProcessedData,
};
