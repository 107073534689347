import { gql, useQuery } from '@apollo/client';

import { CustomerFeedbackQuery } from './types';

const CUSTOMER_FEEDBACK = gql`
  query CustomerFeedback {
    feedback {
      id
      body
      date
      platform
      link
      sentiment
      attachments {
        id
        previewUrl
        type
      }
    }
  }
`;

export default function useCustomerFeedback(): CustomerFeedbackQuery {
  const query = useQuery(CUSTOMER_FEEDBACK);
  const feedbackData = query?.data?.feedback || [];

  return {
    customerFeedback: feedbackData,
    ...query,
  };
}
