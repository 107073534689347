import { formatRelative } from 'date-fns';
import capitalize from 'lodash/capitalize';
import React from 'react';

import { Delay } from 'client/Delay/types';

import { Stat } from 'components';
import { WeatherConditionIcon } from '.';

import { getDelayStatus, getWeatherDetails } from '../utils';
import { Weather } from 'data/Weather/types';

import { Box } from 'styled';

interface ItemProps {
  weatherData: Weather;
  delaysData: Delay[];
}

const Item: React.FC<ItemProps> = ({ weatherData, delaysData }: ItemProps): JSX.Element | null => {
  const airportData = weatherData.airport;
  const weatherDetails = getWeatherDetails(weatherData);
  const hasDelays = delaysData.length > 0;

  return (
    <Box textAlign="center">
      <Box mb="base">
        <WeatherConditionIcon
          conditionCode={weatherDetails.conditionCode}
          icon={{ name: '', color: getDelayStatus(delaysData, weatherDetails), size: '40' }}
        />
      </Box>
      <Stat label={weatherDetails.conditions}>
        {isNaN(weatherDetails.temperature) ? 'N/A' : `${weatherDetails.temperature}°`}
      </Stat>
      <Box as="h2" color="subdued" fontWeight="normal">
        {airportData?.city} ({airportData.code})
      </Box>
      {hasDelays ? (
        delaysData.map((delay: Delay) => (
          <Box key={delay.id} as="p" color="subdued" fontSize="s">
            <Box as="b" color="fg" display="block">
              {capitalize(formatRelative(delay.createdAt, new Date()))}
            </Box>
            <Box as="span">
              {delay.type}: {delay.description}
            </Box>
          </Box>
        ))
      ) : (
        <Box as="p" color="subdued" fontSize="s">
          No delay programs
        </Box>
      )}
    </Box>
  );
};

export default Item;
