import { gql, useQuery } from '@apollo/client';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { WaitTimeArgs, WaitTimesQuery } from './types';

const GET_WAIT_TIMES = gql`
  query GetWaitTimes($startTime: String, $endTime: String) {
    waitTimes(startTime: $startTime, endTime: $endTime) {
      name
      waitTimes {
        timestamp
        value
      }
    }
  }
`;

export default function useWaitTimes({
  startDate,
  endDate,
  refresh = true,
  skip = false,
}: WaitTimeArgs): WaitTimesQuery {
  const options = {
    variables: {
      startTime: startDate,
      endTime: endDate,
    },
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_WAIT_TIMES, options);

  return {
    waitTimes: query?.data?.waitTimes || [],
    ...query,
  };
}
