import React from 'react';

import { Icon } from 'components';

import { Box, Button as StyledButton, ButtonLoader } from 'styled';
import { SystemProps } from 'styled/lib/types';

const DEFAULT_ICON_SIZE = '18';
const SMALL_ICON_SIZE = '14';

interface Props extends SystemProps {
  disabled?: boolean;
  loading?: boolean;
  onClick?: Function;
  size?: string;
  to?: string;
  type?: string;
  variant?: string;
  arrow?: string;
}

const Button: React.FC<Props> = ({ disabled, loading, size, variant, arrow, ...rest }) => {
  const iconSize = size === 's' ? SMALL_ICON_SIZE : DEFAULT_ICON_SIZE;
  if (loading) {
    return (
      <StyledButton size={size} variant={variant} {...rest}>
        <ButtonLoader as={Icon} name="loader" size={iconSize} />
      </StyledButton>
    );
  }

  switch (arrow) {
    case 'left':
      return (
        <StyledButton size={size} variant={variant} {...rest}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box as={Icon} name="chevronLeft" marginRight="xs" size={iconSize} />
            {rest.children}
          </Box>
        </StyledButton>
      );
    case 'right':
      return (
        <StyledButton size={size} variant={variant} {...rest}>
          <Box display="flex" alignItems="center" justifyContent="center">
            {rest.children}
            <Box as={Icon} name="chevronRight" marginLeft="xs" size={iconSize} />
          </Box>
        </StyledButton>
      );
    default:
      return <StyledButton disabled={disabled} size={size} variant={variant} {...rest} />;
  }
};

export default Button;
