import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

type Props = {
  direction: 'x' | 'y';
  xspace?: string;
  yspace?: string;
} & SystemProps;

const direction =
  ({ direction, xspace, yspace }: Props) =>
  () => {
    if (direction === 'y') {
      return css`
        flex-direction: column;

        > *:not(:last-child) {
          margin-bottom: ${yspace ? themeGet(`space.${yspace}`) : themeGet('space.base')};
        }
      `;
    }

    return css`
      flex-direction: row;
      flex-wrap: wrap;

      > * {
        margin-bottom: ${xspace ? themeGet(`space.${xspace}`) : themeGet('space.base')};
      }

      > *:not(:last-child) {
        margin-right: ${xspace ? themeGet(`space.${xspace}`) : themeGet('space.base')};
      }
    `;
  };

const Stack = styled.div<Props>`
  display: flex;

  ${direction}
  ${system}
`;

export default Stack;
