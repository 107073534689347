// @ts-nocheck
import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';
import sample from 'lodash/sample';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import CHART_COLORS from 'config/chartColors';
import theme from 'config/theme';
import { AIRPORT_CODE, REGIONAL_AIRPORTS } from 'config/constants';

import { getDefaultSeriesOptions, getVisibility } from 'statistics/charts/utils';
import { ChartDatum } from 'statistics/types';

function getColor(airport?: string): string {
  const randomColor = sample(Object.values(theme.colors.chart)) as string;

  if (!airport) return randomColor;

  const colors: { [key: string]: string } = {
    [AIRPORT_CODE]: CHART_COLORS[`Daily Throughput - ${AIRPORT_CODE}`],
    '7 Day Rolling Average': CHART_COLORS['Daily Throughput - 7 Day Rolling Average'],
    '1 year ago': CHART_COLORS['Daily Throughput - 1 year ago'],
    '2019': CHART_COLORS['Daily Throughput - 2019'],
  };
  REGIONAL_AIRPORTS.map(
    airport => (colors[airport] = CHART_COLORS[`Daily Throughput - ${airport}`])
  );

  const airportCode = process.env.REACT_APP_AIRPORT_CODE || '';
  if (airport === airportCode) return CHART_COLORS[`Daily Throughput - ${airportCode}`];
  return colors[airport] || randomColor;
}

export default function getSeries(
  data: ChartDatum[],
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType[] {
  const airports = uniq(data.map(d => d.name));

  return airports.map(airport => {
    const _data: ChartDatum[] = [];
    data.forEach(d => d.name === airport && _data.push(d));
    return merge(getDefaultSeriesOptions(), {
      yAxis: 'dailyThroughputs',
      name: airport,
      color: getColor(airport),
      data: sortBy(_data, 'x'),
      tooltip: {
        pointFormatter(): string {
          let { y } = this;
          const { color, series } = this;
          y = Math.ceil(y || 0);
          return `<span style="color:${color}">●</span> ${
            series?.name
          }: <b>${y.toLocaleString()}</b><br/>`;
        },
      },
      ...chartOptions,
      type: 'line',
      visible: getVisibility(airport),
    } as SeriesOptionsType);
  });
}
