import React from 'react';

import { Tile, TILES } from 'config/tiles';

import { useSettingsState } from 'providers/SettingsProvider';

import TileManager from 'components/TileManager/TileManager';

import { TileGrid } from 'styled';

interface Props {
  pageIndex: number;
  fullscreen?: boolean;
}

const Tetris: React.FC<Props> = ({ pageIndex, fullscreen = false }: Props) => {
  const settings = useSettingsState();
  const tileSettings = settings?.dashboardLayout?.[`page${pageIndex}`];

  const tiles: Tile[] = [];

  tileSettings?.forEach((tile: string) => {
    const foundTile = TILES?.find((t: Tile) => t.title === tile && t.configSetting);
    if (foundTile) {
      tiles.push(foundTile);
    }
  });

  return (
    <TileGrid
      count={tiles.length}
      dashboardMode={settings.dashboardMode}
      fullscreen={fullscreen}
      pageIndex={pageIndex}
      id="tile-grid"
    >
      <TileManager tiles={tiles} />
    </TileGrid>
  );
};

export default Tetris;
