import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const UPDATE_USER_METADATA = gql`
  mutation UpdateMetadata($key: String!, $value: String!) {
    updateMetadata(key: $key, value: $value) {
      user_metadata {
        alerts {
          lastSeenTimestamp
        }
        settings {
          emailAlerts
          emailInsights
          hasSeenTour
          dashboardLayout {
            page1
            page2
          }
        }
      }
    }
  }
`;

export default function useUpdateUserMetadata(options: MutationHookOptions = {}) {
  return useMutation(UPDATE_USER_METADATA, options);
}
