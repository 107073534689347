import React from 'react';

import { Tile as TetrisTile, TILE_KEYS } from 'config/tiles';

import { ProviderComposer, TrafficProvider } from 'providers';

interface Props {
  tiles: TetrisTile[];
}

interface Context {
  [key: string]: React.ReactElement;
}

const TileManager: React.FC<Props> = ({ tiles }: Props) => {
  const _tileTitles = tiles.map((tile: TetrisTile) => tile.title);

  const contexts: Context = {
    traffic: <TrafficProvider />,
  };

  const activeContexts: React.ReactElement[] = [];

  if (_tileTitles.includes(TILE_KEYS.TRAFFIC)) {
    activeContexts.push(contexts.traffic);
  }

  return (
    <ProviderComposer contexts={activeContexts}>
      {tiles.map((tile: TetrisTile, idx: number) => {
        const TileComponent = tile?.component || null;
        const props = tile?.props || {};

        if (!TileComponent) return null;

        return <TileComponent key={idx} {...props} />;
      })}
    </ProviderComposer>
  );
};

export default TileManager;
