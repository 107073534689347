import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useDeleteIssue } from 'client/Issue';
import { Issue } from 'client/Issue/types';
import { getOptimisticResponse } from 'client/Issue/useDeleteIssue';
import { useUser } from 'client/User';
import { track } from 'utils';

import { showError, showSuccess, useToastDispatch } from 'providers/ToastProvider';

import {
  Button,
  ConfirmationLink,
  CreatedAt,
  Icon,
  IssueSeverity,
  Modal,
  RequiredPermission,
} from 'components';
import IssueStatusToggle from 'components/IssueStatusToggle';

import { Avatar, Box, Card, Circle, TextLabel } from 'styled';

interface Props {
  title: string;
  issue: Issue;
}

interface StatusButtonProps {
  handleClick: Function;
  loading: boolean;
  status: 'open' | 'closed';
}

const IssueModal: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const toastDispatch = useToastDispatch();
  const [deleteIssue, { loading: deleteIssueLoading }] = useDeleteIssue({
    onCompleted: () => {
      toastDispatch(showSuccess('Issue deleted'));
      track('Delete Issue');
    },
    onError: () => toastDispatch(showError('There was an error deleting the issue')),
  });
  const { id, assignee, createdAt, severity, status: _status, description } = props.issue;
  const [status, setStatus] = useState(_status);
  const { data: userData, loading: userLoading } = useUser(assignee);
  const statusColor = status === 'closed' ? 'error' : 'success';
  const avatar = userData?.user?.picture;
  const username = userData?.user?.name;
  const paragraphs = description?.split('\n\n');

  function getDescriptionParagraphMargin(idx: number): string {
    if (!paragraphs) return '0';
    const numParagraphs = paragraphs.length;
    const isLastParagraph = idx === numParagraphs - 1;
    if (isLastParagraph) return '0';
    return 'base';
  }

  return (
    <Modal {...props}>
      <Box display="grid" gridTemplateColumns="1.5fr repeat(3, 22%)" gridColumnGap="s" mb="l">
        <Box display="grid">
          <Label>Assignee</Label>
          <Box alignItems="center" display="flex">
            <Box mr="s">
              {userLoading ? (
                <Circle bg="bgDark" size="20px" />
              ) : avatar ? (
                <Avatar src={avatar} alt="" height="20" width="20" />
              ) : (
                <Circle bg="subdued" size="20px" />
              )}
            </Box>
            <Box as="span" flex="1">
              {userLoading ? 'Loading...' : username || 'No assignee'}
            </Box>
          </Box>
        </Box>
        <Box display="grid">
          <Label>Severity</Label>
          <IssueSeverity level={severity} />
        </Box>
        <Box display="grid">
          <Label>Status</Label>
          <TextLabel color={statusColor}>{status || 'open'}</TextLabel>
        </Box>
        <Box display="grid">
          <Label>Created</Label>
          <CreatedAt time={createdAt} />
        </Box>
      </Box>
      {description ? (
        <Card fontSize="l" mb="l" p="l">
          {paragraphs.map((item: string, idx: number) => (
            <Box key={idx} as="p" mb={getDescriptionParagraphMargin(idx)}>
              {item}
            </Box>
          ))}
        </Card>
      ) : null}
      <Box alignItems="center" display="flex">
        <RequiredPermission permission="update:issues">
          <Button variant="secondary" as={Link} to={`/issues/${id}/edit`} mr="base">
            <Icon name="pencil" size="16" mr="s" />
            Edit issue
          </Button>
          <IssueStatusToggle
            id={id}
            onComplete={(): void => setStatus(status === 'open' ? 'closed' : 'open')}
            status={status}
            renderExternal={(props: StatusButtonProps) => <StatusButton {...props} />}
          />
        </RequiredPermission>
        <RequiredPermission permission="delete:issues">
          <ConfirmationLink
            text={`Delet${deleteIssueLoading ? 'ing' : 'e'} this issue`}
            onConfirm={() => {
              deleteIssue({ variables: { id }, ...getOptimisticResponse() });
              history.push('/issues');
            }}
          />
        </RequiredPermission>
      </Box>
    </Modal>
  );
};

const StatusButton: React.FC<StatusButtonProps> = ({ status, loading, handleClick }) => {
  if (status === 'open') {
    return (
      <Button onClick={handleClick} variant="secondary" loading={loading}>
        <Icon name="x" size="16" mr="s" />
        Close issue
      </Button>
    );
  }

  return (
    <Button onClick={handleClick} variant="secondary" loading={loading}>
      <Icon name="check" size="16" mr="s" />
      Reopen issue
    </Button>
  );
};

const Label: React.FC = ({ children }) => (
  <TextLabel color="subdued" mb="xs">
    {children}
  </TextLabel>
);

export default IssueModal;
