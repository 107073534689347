import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { PrivateAppProvider } from 'providers';

import GlobalData from 'components/GlobalData';

interface PrivateRoute {
  path: string;
  component: React.FC;
  exact: boolean;
  children?: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRoute> = ({
  path,
  children,
  component: Component,
  exact,
  ...props
}: PrivateRoute) => {
  const { isAuthenticated } = useAuth0();

  return (
    <Route
      {...props}
      exact={exact}
      path={path}
      render={(): JSX.Element =>
        isAuthenticated ? (
          <GlobalData>
            <PrivateAppProvider>{<Component /> || children}</PrivateAppProvider>
          </GlobalData>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
