import { gql, useQuery } from '@apollo/client';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { TrafficAlertsArgs, TrafficAlertsQuery } from '../types';

const TRAFFIC_ALERTS = gql`
  query TrafficAlerts {
    trafficAlerts {
      id
      type
      subtype
      street
      roadType
      reportDescription
      location {
        x
        y
      }
      timestamp
    }
  }
`;

export default function useTrafficAlerts({
  refresh = true,
}: TrafficAlertsArgs = {}): TrafficAlertsQuery {
  const options = {
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(TRAFFIC_ALERTS, options);

  return {
    alerts: query?.data?.trafficAlerts || [],
    ...query,
  };
}
