import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const UPDATE_ISSUE = gql`
  mutation UpdateIssue(
    $id: String!
    $assignee: String
    $status: String
    $severity: String
    $title: String
    $description: String
  ) {
    updateIssue(
      id: $id
      assignee: $assignee
      status: $status
      severity: $severity
      title: $title
      description: $description
    ) {
      id
      createdAt
      updatedAt
      status
      severity
      title
      description
      assignee
    }
  }
`;

export default function useUpdateIssue(options: MutationHookOptions = {}) {
  return useMutation(UPDATE_ISSUE, options);
}
