import capitalize from 'lodash/capitalize';
import React, { useEffect, useState } from 'react';

import { TILES } from 'config/tiles';
import { track } from 'utils';

import { CANCELED, COMPLETED, DELAYED, ON_TIME } from 'data/Flights/constants';
import { ArrivalFlight } from 'data/Flights/types';

import { updateModal, useModal } from 'providers/ModalProvider';
import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { FormField, Modal, NotAvailableBlankSlate, RequiredPermission, Select } from 'components';
import { Arrivals, Departures } from 'components/Tiles';

import { Box } from 'styled';

type Type = 'All' | 'cargo' | 'passenger';
type Status = 'All' | 'CANCELED' | 'COMPLETED' | 'DELAYED' | 'ON_TIME';

interface Props {
  stateKey: string;
  title: string;
  iconName: string;
  initialType: Type;
  initialStatus: Status;
}

const ArrivalsModal: React.FC<Props> = (props: Props) => {
  const [optionsState, optionsDispatch] = useTileOptions();
  const [modalState, modalDispatch] = useModal();
  const [type, setType] = useState<Type | string>(props.initialType || 'All');
  const [status, setStatus] = useState<Status | string>(props.initialStatus || 'All');

  const title = capitalize(props.stateKey);
  const icon =
    props.stateKey === 'arrivals' ? { name: 'arrowDownAndLeft' } : { name: 'arrowUpAndRight' };

  const filters = optionsState?.[props.stateKey]?.filters;
  const tileConfig = TILES?.find(tile => tile.title === title);
  const headings = tileConfig?.headings || [];
  const additionalHeadings = tileConfig?.additionalHeadings || [];

  const modalFilter = ({ flight }: { flight: ArrivalFlight }): boolean => {
    let statusFilter = true;
    if (status !== 'All') statusFilter = flight.statusText === status;
    let typeFilter = true;
    if (type === 'cargo') typeFilter = !!flight?.isPassenger === false;
    if (type === 'passenger') typeFilter = !!flight?.isPassenger === true;

    return typeFilter && statusFilter;
  };

  function updateModalData() {
    optionsDispatch(
      updateOptions({
        [props.stateKey]: {
          ...optionsState[props.stateKey],
          filters: {
            ...optionsState?.[props.stateKey]?.filters,
            needsUpdate: true,
          },
        },
      })
    );
  }

  function handleTypeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setType(value);
    updateModalData();
    track('Tile Filter', {
      tile: title,
      key: 'type',
      value,
    });
  }

  function handleStatusChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setStatus(value);
    updateModalData();
    track('Tile Filter', {
      tile: title,
      key: 'status',
      value,
    });
  }

  function renderComponent() {
    const _props = {
      grid: '2fr repeat(6, 1fr)',
      headings: [...headings, ...additionalHeadings],
      filter: modalFilter,
      filterBlankSlateIcon: { name: 'check', color: 'primary', size: '30' },
      filterEmptyMessage: `No ${props.stateKey} matching parameters.`,
      isReverseSort: filters.isReverseSort,
      sortByColumn: filters?.sortByColumn || filters?.defaultSortByColumn,
    };

    if (title === 'Arrivals') return <Arrivals {..._props} />;
    if (title === 'Departures') return <Departures {..._props} />;
    return null;
  }

  const modalProps = {
    title,
    icon,
    children: (
      <RequiredPermission
        permission="read:flight"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <Box display="flex" mb="20px" mt="-4px">
          <FormField
            label="Type"
            type="stacked"
            labelHorizontalAlign="left"
            labelWidth="60px"
            width="200px"
            mr="base"
          >
            <Select
              name="type"
              options={[
                { value: 'All', label: 'All' },
                { value: 'cargo', label: 'Cargo' },
                { value: 'passenger', label: 'Passenger' },
              ]}
              onChange={handleTypeChange}
              value={type}
            />
          </FormField>
          <FormField
            label="Status"
            labelHorizontalAlign="left"
            labelWidth="60px"
            width="200px"
            type="stacked"
          >
            <Select
              name="status"
              options={[
                { value: 'All', label: 'All' },
                { value: CANCELED, label: 'Cancelled' },
                { value: COMPLETED, label: 'Completed' },
                { value: DELAYED, label: 'Delayed' },
                { value: ON_TIME, label: 'On Time' },
              ]}
              onChange={handleStatusChange}
              value={status}
            />
          </FormField>
        </Box>
        {renderComponent()}
      </RequiredPermission>
    ),
    large: true,
  };

  // Update modal data if needed
  useEffect(() => {
    const isActiveModal = modalState?.activeModal?.props?.title === 'Flights';
    const needsUpdate = optionsState?.[props.stateKey]?.filters?.needsUpdate;
    if (isActiveModal && needsUpdate) {
      modalDispatch(updateModal('Flights', modalProps));
      optionsDispatch(
        updateOptions({
          [props.stateKey]: {
            ...optionsState[props.stateKey],
            filters: {
              ...optionsState?.[props.stateKey]?.filters,
              needsUpdate: false,
            },
          },
        })
      );
    }
  }, [modalDispatch, modalState, modalProps, optionsDispatch, optionsState, props.stateKey]);

  return <Modal {...modalProps} />;
};

export default ArrivalsModal;
