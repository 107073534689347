import { format, isValid } from 'date-fns';
import React, { useEffect, useState } from 'react';

import theme from 'config/theme';

import { getDelayTimeString } from 'utils';

import { CANCELED, COMPLETED, DELAYED, DIVERTED, ON_TIME } from 'data/Flights/constants';
import { CapacityDepartureFlight } from 'data/Flights/types';

import { BlankSlate, Table } from 'components';
import { Props as IconProps } from 'components/Icon';
import { Heading } from 'components/Table';

import { Box } from 'styled';

import { FlightStatusText } from 'data/Flights/components';

import { CapacityStatus, City } from './components';

export interface Props {
  flights: CapacityDepartureFlight[];
  filter?: Function;
  grid?: string;
  headings?: Heading[];
  limit?: number;
  filterEmptyMessage?: string;
  filterBlankSlateIcon?: IconProps;
}

const defaults = {
  headings: [
    { key: 'city', label: 'City' },
    { key: 'flightNumber', label: 'Flight' },
    { key: 'time', label: 'Time' },
    { key: 'status', label: 'Status' },
  ],
  grid: '2fr repeat(3, 1fr)',
  filterEmptyMessage: 'There is currently no data.',
  filterBlankSlateIcon: { name: 'magnifyingGlass', color: 'warning', size: '30' },
};

const CapacityFlights: React.FC<Props> = ({
  flights,
  filter,
  grid,
  headings,
  limit,
  filterEmptyMessage,
  filterBlankSlateIcon,
}: Props) => {
  const [filteredFlights, setFilteredFlights] = useState<CapacityDepartureFlight[]>([]);

  function getNumberToShow(flights: CapacityDepartureFlight[]): number {
    return limit || flights.length;
  }

  // !NOTE: For paginated data, e.g. from Details components, this will make some pages shorter than others
  // TODO: Figure out how to retain page length for pagination while still using filter

  useEffect(() => {
    let stillMounted = true;

    (async () => {
      const _filteredFlights = filter ? flights.filter(flight => filter({ flight })) : flights;
      if (stillMounted) {
        setFilteredFlights(_filteredFlights);
      }
    })();

    return () => {
      stillMounted = false;
    };
  }, [flights, filter]);

  if (filteredFlights.length === 0)
    return (
      <BlankSlate
        display="flex"
        flexDirection="column"
        justifyContent="center"
        icon={filterBlankSlateIcon || defaults.filterBlankSlateIcon}
      >
        {filterEmptyMessage || defaults.filterEmptyMessage}
      </BlankSlate>
    );

  return (
    <Table
      name="capacity"
      grid={grid || defaults.grid}
      striped={false}
      bordered={true}
      cellPaddingX="s"
      cellPaddingY="s"
      dataFontSize="s"
      gridGap="xs"
      headings={headings || defaults.headings}
      data={filteredFlights.slice(0, getNumberToShow(flights)).map(flight => {
        const delayMinutes = flight?.departureDelay;
        const scheduledTime = flight?.scheduledDepartureDate;
        const statusText = flight?.statusText;
        const delayTime = getDelayTimeString(delayMinutes, scheduledTime);
        const delayed = statusText === DELAYED;
        const gate = flight?.departureGate;
        const time = flight?.departureTime;
        const date = flight?.departureDate;

        let statusColor = theme.colors.subdued;
        if (statusText === CANCELED || statusText === DIVERTED) {
          statusColor = theme.colors.error;
        }
        if (statusText === DELAYED) {
          statusColor = theme.colors.warning;
        }
        if (statusText === ON_TIME) {
          statusColor = theme.colors.success;
        }

        const isCompleted = statusText === COMPLETED;

        const returnData = {
          city: (
            <City
              airline={flight.airline}
              airport={flight.airport}
              statusColor={statusColor === theme.colors.subdued ? statusColor : null}
            />
          ),
          flight: (
            <Box as="span" color={isCompleted ? statusColor : theme.colors.fg}>
              {flight.flightNumber}
            </Box>
          ),
          date: isValid(date) ? format(date, 'LL/dd/yy') : 'N/A',
          time: (
            <Box as="span" color={statusColor}>
              {time}
            </Box>
          ),
          flightNumber: flight?.flightNumber || '',
          gate: (
            <Box as="span" color={isCompleted ? statusColor : theme.colors.fg}>
              {gate}
            </Box>
          ),
          status: (
            <FlightStatusText
              delayed={delayed}
              delayMinutes={delayMinutes}
              delayTime={delayTime}
              statusColor={statusColor}
              statusText={statusText}
            />
          ),
          bookings: flight.bookings,
          capacity: (
            <CapacityStatus
              occupancy={flight?.capacityData?.occupancy || 0}
              capacity={flight?.capacityData?.capacity || 0}
              load={flight?.capacityData?.load || 0}
              booking={flight.bookings}
            />
          ),
          load: <Box fontSize="s">{flight?.capacityData?.load}%</Box>,
        };

        return {
          ...returnData,
        };
      })}
    />
  );
};

export default CapacityFlights;
