import { useEffect } from 'react';

const AIRPORT_CODE = `${process.env.REACT_APP_AIRPORT_CODE} ` || '';
const DEFAULT_TITLE = `${AIRPORT_CODE}EASE`;

function useDocumentTitle(title?: string) {
  useEffect(() => {
    if (title === DEFAULT_TITLE) document.title = DEFAULT_TITLE;
    else document.title = `${title} - ${DEFAULT_TITLE}`;
  }, [title]);
}

export default useDocumentTitle;
