import React from 'react';
import { Link } from 'react-router-dom';

import { FacilityCondition } from 'client/FacilityConditions/types';
import { Issue } from 'client/Issue/types';

import { useSelectedTableItemDispatch } from 'providers/SelectedTableItemProvider';

import { Icon } from 'components';
import Table, { Heading } from 'components/Table';

import { Box } from 'styled';

interface FacilityConditionsTableProps {
  grid: string;
  headings: Heading[];
  facilityConditions: FacilityCondition[];
  issues: Issue[];
  clickable?: boolean;
}

interface FacilityConditionsData {
  data: { id: string };
}

const FacilityConditionsTable: React.FC<FacilityConditionsTableProps> = ({
  facilityConditions,
  issues,
  clickable,
  headings,
  grid,
}: FacilityConditionsTableProps) => {
  const setSelectedFacilityCondition = useSelectedTableItemDispatch(!clickable);

  const data = facilityConditions.map(facilityCondition => {
    const { feed, assets, importance, issueId, generator } = facilityCondition;
    const issue = issues.find(i => i.id === issueId);
    const color = issue ? 'error' : 'success';

    return {
      feed: (
        <Box alignItems="center" display="flex">
          <Icon name="circle" size="16" color={color} fill={color} mr="s" />
          {feed}
        </Box>
      ),
      assets,
      importance,
      generator,
      issue: issue ? (
        <Box alignItems="center" display="flex">
          <Link to={`/issues?id=${issue.id}`}>Issue</Link>
        </Box>
      ) : null,
      data: { id: JSON.stringify(facilityCondition.coordinates) },
    };
  });

  return (
    <Table
      name="facilityConditions"
      striped={false}
      bordered={true}
      dataFontSize="s"
      grid={grid}
      headings={headings}
      data={data}
      onRowClick={
        clickable
          ? (row: FacilityConditionsData): void => {
              setSelectedFacilityCondition(row.data);
            }
          : undefined
      }
    />
  );
};

export default FacilityConditionsTable;
