import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const LinkBox = styled.a<SystemProps>`
  text-decoration: none;
  transition-duration: 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: ${themeGet('colors.neutralsGradient.4')};
  }
  ${system}
`;

export default LinkBox;
