import { themeGet } from '@styled-system/theme-get';
import styled, { css, keyframes } from 'styled-components';

import { Props } from 'components/CircleIcon';

import system from './lib/system';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const CircleIcon = styled.div<Props>`
  background-color: ${themeGet('colors.bg')};
  border-radius: 50%;
  height: ${props => props.size}px;
  line-height: ${props => props.size}px;
  text-align: center;
  transform-origin: center;
  transition: 0.3s ease-in-out;
  width: ${props => props.size}px;

  > svg {
    transform-origin: center;
  }

  ${props =>
    props.spinner &&
    css`
      animation: ${spin} 1s infinite;
    `};

  ${system}
`;

export default CircleIcon;
