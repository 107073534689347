import { gql, useQuery } from '@apollo/client';

export const GET_ISSUE = gql`
  query Issue($id: ID!) {
    issue(id: $id) {
      id
      createdAt
      updatedAt
      status
      severity
      title
      description
      assignee
    }
  }
`;

export default function useIssue(id: string | undefined) {
  return useQuery(GET_ISSUE, { variables: { id } });
}
