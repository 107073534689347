import React from 'react';

import { Table } from 'components';

import { BagTagSummaryData } from 'data/CommonUse/types';

const CommonUseBagTagSummary: React.FC<{ data: BagTagSummaryData[] }> = ({
  data,
}: {
  data: BagTagSummaryData[];
}) => {
  if (!data) return null;

  const _data = data as BagTagSummaryData[];

  return (
    <>
      <Table
        grid="repeat(3, 1fr)"
        striped={false}
        bordered={true}
        cellPaddingX="s"
        cellPaddingY="s"
        dataFontSize="s"
        gridGap="xs"
        headings={[
          { key: 'date', label: 'Date' },
          { key: 'airline', label: 'Airline' },
          { key: 'count', label: 'Bag Tags' },
        ]}
        data={_data}
      />
    </>
  );
};

export default CommonUseBagTagSummary;
