import React from 'react';

import { TILE_KEYS } from 'config/tiles';

import { showModal, useModalDispatch } from 'providers/ModalProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';
import { Parking } from 'components/Tiles';

export const ParkingTile: React.FC = () => {
  const modalDispatch = useModalDispatch();
  if (process.env.REACT_APP_CONFIG_PARKING !== 'true') return null;
  return (
    <Tile
      title="Parking"
      icon="truck"
      contentAlignment="top"
      contentPadding="0"
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Parking Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.PARKING,
              })
            ),
        },
      ]}
    >
      <RequiredPermission
        permission="read:parking"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <Parking />
      </RequiredPermission>
    </Tile>
  );
};

export default ParkingTile;
