import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Radio = styled.input<SystemProps>`
  accent-color: ${themeGet('colors.inputs')};
  ${system}
`;

export { Radio as default };
