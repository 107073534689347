import { parseISO } from 'date-fns';

import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import max from 'lodash/max';
import min from 'lodash/min';
import sum from 'lodash/sum';
import find from 'lodash/find';

import { filterByDayRange } from 'statistics/charts/utils';
import { ChartDatum, DataObject, StatsData } from 'statistics/types';
import { STAT_TYPES } from 'statistics/stats/constants';
import { FILTER_TYPES } from 'statistics/charts/constants';
import { ChartOptionsFilter } from 'config/tabs';

import { ProcessedDataObject } from './utils';
import { CommonUseKiosk, CommonUseWorkstation, BagTagData } from 'data/CommonUse/types';

type AirlineCommonUseData = { [key: string]: ChartDatum[] };

function getWorkstationsChartData(workstations: CommonUseWorkstation[]): ChartDatum[] {
  const data: ChartDatum[] = [];
  const groupedWorkstations = groupBy(workstations, 'date');
  Object.keys(groupedWorkstations).forEach(key => {
    const workstations = groupedWorkstations[key];
    data.push({
      x: parseISO(key).getTime(),
      y: sum(workstations.map(workstation => workstation.duration)) / workstations.length,
    });
  });
  return sortBy(data, 'x');
}

function getBagTagsChartData(bagTagData: BagTagData[]): ChartDatum[] {
  const data: ChartDatum[] = [];
  const groupedBagTagData = groupBy(bagTagData, 'date');
  Object.keys(groupedBagTagData).forEach(key => {
    const bagTagData = groupedBagTagData[key];
    data.push({
      x: parseISO(key).getTime(),
      y: sum(bagTagData.map(bagTagDatum => bagTagDatum.count)),
    });
  });
  return sortBy(data, 'x');
}

function getWorkstationStats(workstations: CommonUseWorkstation[]): StatsData {
  const lowest = min(workstations.map(w => w.duration)) || 0;
  const highest = max(workstations.map(w => w.duration)) || 0;
  const average = workstations.length
    ? sum(workstations.map(workstation => workstation.duration)) / workstations.length
    : 0;

  const highestWorkstation = workstations.find(w => w.duration === highest);
  const highestLabel =
    highestWorkstation !== undefined
      ? `${highestWorkstation.airline} ${highestWorkstation.date}`
      : undefined;
  const lowestWorkstation = workstations.find(w => w.duration === lowest);
  const lowestLabel =
    lowestWorkstation !== undefined
      ? `${lowestWorkstation.airline} ${lowestWorkstation.date}`
      : undefined;

  return {
    [STAT_TYPES.COMMON_USE.WORKSTATIONS.AVERAGE]: {
      value: average,
      contextLabel: `Average workstation duration`,
    },
    [STAT_TYPES.COMMON_USE.WORKSTATIONS.HIGHEST]: {
      value: highest,
      contextLabel: highestLabel !== undefined ? highestLabel : undefined,
    },
    [STAT_TYPES.COMMON_USE.WORKSTATIONS.LOWEST]: {
      value: lowest,
      contextLabel: lowestLabel !== undefined ? lowestLabel : undefined,
    },
  };
}

function getBagTagStats(bagTagData: BagTagData[]): StatsData {
  const aggregatedData: { airline: string; date: string; count: number }[] = [];
  const groupedBagTagData = groupBy(bagTagData, 'date');
  Object.keys(groupedBagTagData).forEach(key => {
    const bagTagsForDate = groupedBagTagData[key];
    const groupedByAirline = groupBy(bagTagsForDate, 'airline');
    Object.keys(groupedByAirline).forEach(airline => {
      const bagTagsForAirline = groupedByAirline[airline];
      const count = sum(bagTagsForAirline.map(b => b.count));
      aggregatedData.push({ airline, date: key, count });
    });
  });

  const lowest = min(aggregatedData.map(b => b.count)) || 0;
  const highest = max(aggregatedData.map(b => b.count)) || 0;

  const sums = Object.keys(groupedBagTagData).map(key => {
    const count = sum(groupedBagTagData[key].map(b => b.count));
    return count;
  });
  const average = sums.length ? sum(sums) / sums.length : 0;
  const total = sum(sums);

  const highestBagTagData = aggregatedData.find(b => b.count === highest);
  const highestLabel =
    highestBagTagData !== undefined
      ? `${highestBagTagData.airline} ${highestBagTagData.date}`
      : undefined;
  const lowestBagTagData = aggregatedData.find(b => b.count === lowest);
  const lowestLabel =
    lowestBagTagData !== undefined
      ? `${lowestBagTagData.airline} ${lowestBagTagData.date}`
      : undefined;

  return {
    [STAT_TYPES.COMMON_USE.BAG_TAGS.AVERAGE]: {
      value: average,
      contextLabel: `Average number per day`,
    },
    [STAT_TYPES.COMMON_USE.BAG_TAGS.HIGHEST]: {
      value: highest,
      contextLabel: highestLabel !== undefined ? highestLabel : undefined,
    },
    [STAT_TYPES.COMMON_USE.BAG_TAGS.LOWEST]: {
      value: lowest,
      contextLabel: lowestLabel !== undefined ? lowestLabel : undefined,
    },
    [STAT_TYPES.COMMON_USE.BAG_TAGS.TOTAL]: {
      value: total,
      contextLabel: 'Total bag tags',
    },
  };
}

function getMappedWorkstations(workstations: CommonUseWorkstation[]): BagTagData[] {
  return workstations.map((workstation: CommonUseWorkstation) => {
    const bagTagData: BagTagData = {
      date: workstation.date,
      airline: workstation.airline,
      count: workstation.btp,
      workstation: workstation.workstation,
    };
    return bagTagData;
  });
}

function getMappedKiosks(kiosks: CommonUseKiosk[]): BagTagData[] {
  return kiosks.map((kiosk: CommonUseKiosk) => {
    const bagTagData: BagTagData = {
      date: kiosk.date,
      airline: kiosk.airline,
      count: kiosk.btp,
      workstation: kiosk.name,
    };
    return bagTagData;
  });
}

function getFilteredWorkstations(
  data: CommonUseWorkstation[],
  airline: string[]
): CommonUseWorkstation[] {
  const filtered = data.filter((workstation: CommonUseWorkstation) =>
    airline.includes(workstation.airline)
  );
  return filtered;
}

function getFilteredKiosks(data: CommonUseKiosk[], airline: string[]): CommonUseKiosk[] {
  const filtered = data.filter((kiosk: CommonUseKiosk) => airline.includes(kiosk.airline));
  return filtered;
}

function getProcessedWorkstations(
  workstationData: DataObject,
  dateRangeDays: string[],
  activeFilters: ChartOptionsFilter[],
  airline: string[]
): ProcessedDataObject {
  const { data: wData, loading, error } = workstationData;

  if (loading || error)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
      details: {
        loading,
        error,
        data: [],
      },
    };

  const workstations = (wData as CommonUseWorkstation[]) || [];

  const _data: CommonUseWorkstation[] =
    airline.length > 0
      ? getFilteredWorkstations(workstations as CommonUseWorkstation[], airline)
      : (workstations as CommonUseWorkstation[]);

  let _filteredData = _data;

  if (find(activeFilters, { type: FILTER_TYPES.COMMON_USE.GATE_COUNTER })) {
    _filteredData = _filteredData?.filter(
      (w: CommonUseWorkstation) => w.description === 'Gate Counter'
    );
  }

  if (find(activeFilters, { type: FILTER_TYPES.COMMON_USE.TICKET_COUNTER })) {
    _filteredData = _filteredData?.filter(
      (w: CommonUseWorkstation) => w.description === 'Ticket Counter'
    );
  }

  const { byDayRange } = filterByDayRange(dateRangeDays);
  const filteredWorkstationsByDayRange = _filteredData.filter((datum: CommonUseWorkstation) =>
    byDayRange(parseISO(datum.date))
  );

  const chartData: AirlineCommonUseData = {};
  const groupedByAirlines = groupBy(filteredWorkstationsByDayRange, 'airline');
  Object.keys(groupedByAirlines).forEach(key => {
    const workstations = groupedByAirlines[key];
    const data = getWorkstationsChartData(workstations as CommonUseWorkstation[]);
    chartData[key] = data;
  });

  return {
    chart: {
      loading,
      error,
      data: [{ data: chartData, airline: 'Total' }],
    },
    stats: {
      loading,
      error,
      data: getWorkstationStats(filteredWorkstationsByDayRange as CommonUseWorkstation[]),
    },
    details: {
      loading,
      error,
      data: filteredWorkstationsByDayRange,
    },
  };
}

function getProcessedBagTags(
  workstationData: DataObject,
  kioskData: DataObject,
  dateRangeDays: string[],
  activeFilters: ChartOptionsFilter[],
  airline: string[]
): ProcessedDataObject {
  const { data: wData, loading: loadingWorkstations, error: errorWorkstations } = workstationData;
  const { data: kData, loading: loadingKiosks, error: errorKiosks } = kioskData;
  const loading = loadingWorkstations || loadingKiosks;
  const error = errorWorkstations || errorKiosks;

  if (loading || error)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
      details: {
        loading,
        error,
        data: [],
      },
    };

  const workstations = (wData as CommonUseWorkstation[]) || [];
  const kiosks = (kData as CommonUseKiosk[]) || [];

  const filteredWorkstations: CommonUseWorkstation[] =
    airline.length > 0
      ? getFilteredWorkstations(workstations as CommonUseWorkstation[], airline)
      : (workstations as CommonUseWorkstation[]);

  const filteredKiosks: CommonUseKiosk[] =
    airline.length > 0
      ? getFilteredKiosks(kiosks as CommonUseKiosk[], airline)
      : (kiosks as CommonUseKiosk[]);

  const mappedWorkstations = getMappedWorkstations(filteredWorkstations);
  const mappedKiosks = getMappedKiosks(filteredKiosks);
  const bagTagData = [...mappedWorkstations, ...mappedKiosks];
  let _filteredData = [];

  // If we want all of them, don't filter
  if (find(activeFilters, { type: FILTER_TYPES.COMMON_USE.SHOW_ALL })) {
    _filteredData = bagTagData;
  } else {
    // Gate counters start with RNOB or RNOC
    if (find(activeFilters, { type: FILTER_TYPES.COMMON_USE.GATE_COUNTER })) {
      _filteredData = bagTagData?.filter(
        (b: BagTagData) => b.workstation.match(/^RNOC/) || b.workstation.match(/^RNOB/)
      );
    } else {
      _filteredData = bagTagData?.filter((b: BagTagData) => b.workstation.match(/^RNO1/));
    }
  }

  const { byDayRange } = filterByDayRange(dateRangeDays);
  const filteredBagTagDataByDayRange = _filteredData.filter((datum: BagTagData) =>
    byDayRange(parseISO(datum.date))
  );

  const chartData: AirlineCommonUseData = {};
  const groupedByAirlines = groupBy(filteredBagTagDataByDayRange, 'airline');
  Object.keys(groupedByAirlines).forEach(key => {
    const bagTags = groupedByAirlines[key];
    const data = getBagTagsChartData(bagTags as BagTagData[]);
    chartData[key] = data;
  });

  return {
    chart: {
      loading,
      error,
      data: [{ data: chartData, airline: 'Total' }],
    },
    stats: {
      loading,
      error,
      data: getBagTagStats(filteredBagTagDataByDayRange as BagTagData[]),
    },
    details: {
      loading,
      error,
      data: filteredBagTagDataByDayRange,
    },
  };
}

export default {
  workstations: {
    getProcessedData: (
      workstationData: DataObject,
      dateRangeDays: string[],
      activeFilters: ChartOptionsFilter[],
      airline: string[]
    ): ProcessedDataObject =>
      getProcessedWorkstations(workstationData, dateRangeDays, activeFilters, airline),
  },
  bagTags: {
    getProcessedData: (
      workstationData: DataObject,
      kioskData: DataObject,
      dateRangeDays: string[],
      activeFilters: ChartOptionsFilter[],
      airline: string[]
    ): ProcessedDataObject =>
      getProcessedBagTags(workstationData, kioskData, dateRangeDays, activeFilters, airline),
  },
};
