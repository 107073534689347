const TOUR_CONTENT = {
  dashboard: {
    title: 'Dashboard',
    description:
      'Here you’ll find real-time and predictive data charts for boarding-pass scans, flights, and passenger flow. It’s a multi-page customizable view of all of your data, including charts and tiles.',
  },
  reports: {
    title: 'Reports',
    description:
      'Run reports by selecting a time range and what data you’d like to see. The data is displayed in charts and graphs alongside relevant stats and the raw data feeding the report.',
  },
  issues: {
    title: 'Issues',
    description:
      'Manage existing issues, or create new issues with an assignee and a severity level. Take your insights and turn them to actions.',
  },
  tiles: {
    title: 'Tiles',
    description:
      'With 16 different data sets available, display the data that’s relevant to you on your Dashboard. The size of the tiles automatically grows bigger or smaller depending on how many tiles you add to your page layout.',
  },
  tileSettings: {
    title: 'Tile Settings',
    description:
      'If you see this gear icon, you can select parameters to filter the kind of data displayed in that tile.',
  },
  expandTile: {
    title: 'Expand Tile',
    description: 'Expand the tile to view at a larger scale.',
  },
  feedback: {
    title: 'Submit Feedback',
    description:
      'If you run into a technical issue or you have an idea of how we can make EASE better, submit feedback in this form. This goes directly to the team working on the application.',
  },
  settings: {
    title: 'Settings',
    description:
      'Edit and view your EASE application settings. Turn on Dashboard Mode, set Alert triggers, edit your email notifications, and customize your Dashboard layout.',
  },
  layout: {
    title: 'Dashboard Settings',
    description: 'Customize what you see on your Dashboard here.',
  },
  customizeDashboard: {
    title: 'Customize Dashboard',
    description:
      'Select the page you want to customize, then select the tiles you want displayed.  Drag and drop the tiles and then “preview tile layout” to see what your layout will look like. You can also reset to a default layout. Don’t forget to save your changes at the bottom of the modal!',
  },
};

export default TOUR_CONTENT;
