import {
  AlertsModal,
  ArrivalsModal,
  DefaultModal,
  DeparturesModal,
  FeedbackModal,
  FeedStatusModal,
  IssueModal,
  NewIssueModal,
  OnboardingModal,
  SettingsModal,
  TileOptionsModal,
} from 'components/Modals';

interface Modal {
  title: string;
  component: React.FC<any>;
}

const modals: Modal[] = [
  {
    title: 'Alerts',
    component: AlertsModal,
  },
  {
    title: 'Arrivals',
    component: ArrivalsModal,
  },
  {
    title: 'Default',
    component: DefaultModal,
  },
  {
    title: 'Departures',
    component: DeparturesModal,
  },
  {
    title: 'Feedback',
    component: FeedbackModal,
  },
  {
    title: 'FeedStatus',
    component: FeedStatusModal,
  },
  {
    title: 'Issue',
    component: IssueModal,
  },
  {
    title: 'NewIssue',
    component: NewIssueModal,
  },
  {
    title: 'Onboarding',
    component: OnboardingModal,
  },
  {
    title: 'Settings',
    component: SettingsModal,
  },
  {
    title: 'TileOptions',
    component: TileOptionsModal,
  },
];

export default modals;
