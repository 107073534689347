import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import { SystemProps } from './lib/types';

type Props = { disabled?: boolean } & SystemProps;

const Listbox = styled.div``;

const ListboxButton = styled.button`
  appearance: none;
  background-color: ${themeGet('colors.neutralsGradient.10')};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'%3E%3C/path%3E%3C/svg%3E");
  background-position: right ${themeGet('space.s')} top 50%;
  background-repeat: no-repeat;
  border-radius: ${themeGet('radii.base')};
  border: 1px solid ${themeGet('colors.border')};
  box-sizing: border-box;
  color: ${themeGet('colors.fg')};
  display: block;
  font-family: ${themeGet('fontFamily')};
  font-size: ${themeGet('fontSizes.s')};
  max-width: 100%;
  padding: ${themeGet('space.s')};
  text-align: initial;
  width: 100%;

  &:focus {
    background-color: ${themeGet('colors.neutralsGradient.10')};
    border-color: ${themeGet('colors.primary')};
    outline: none;
  }

  &[disabled] {
    color: ${themeGet('colors.subdued')};
  }
`;

const ListboxOptions = styled.ul`
  background-color: ${themeGet('colors.neutralsGradient.10')};
  border-radius: ${themeGet('radii.base')};
  border: 1px solid ${themeGet('colors.neutralsGradient.8')};
  max-height: 200px;
  margin-top: ${themeGet('space.xs')};
  overflow-y: scroll;
  padding: ${themeGet('space.xs')};
  position: absolute;
  width: 200px;
`;

const ListboxOption = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: ${themeGet('fontSizes.s')};
  padding: ${themeGet('space.xs')};

  &:active,
  &:focus,
  &:hover {
    color: ${themeGet('colors.fg')};
  }
`;

export { Listbox as default, ListboxButton, ListboxOption, ListboxOptions };
