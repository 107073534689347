import { format, isEqual, isToday, isYesterday, max, parseISO } from 'date-fns';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import React from 'react';

import { PARKING } from 'config/parking';
import { TILES } from 'config/tiles';

import { useSales } from 'client/Sale';
import { Sale } from 'client/Sale/types';

import { useRefreshedAt } from 'hooks';

import { useTileOptionsState } from 'providers/TileOptionsProvider';

import { Table, UpdatedAt } from 'components';
import {
  DefaultEmptyMessage,
  DefaultErrorMessageWithError,
  DefaultLoader,
} from 'components/QueryCell';

import { Box, TextLabel } from 'styled';

const Sales: React.FC = () => {
  const {
    sales: { filters },
  } = useTileOptionsState();
  const isReverseSort = filters?.isReverseSort;
  const sortByColumn = filters?.sortByColumn || filters?.defaultSortByColumn;

  const tileConfig = TILES?.find(tile => tile.title === 'Sales');
  const headings = tileConfig?.headings || [];

  const { loading, sales, error } = useSales({ order: [['title', 'ASC']] });
  const { refreshedAt } = useRefreshedAt(loading);
  if (process.env.REACT_APP_CONFIG_SALES !== 'true') return null;

  // Sort sales data
  let sortedSales = sortBy(sales, sortByColumn || '');
  if (isReverseSort) sortedSales = sortedSales.reverse();

  if (loading) return <DefaultLoader />;
  if (error) return <DefaultErrorMessageWithError error={error} action="useSales()" />;

  const noSales = !sales?.length;
  if (noSales) return <DefaultEmptyMessage content="sales" />;

  let latestData = sortedSales.filter((sale: Sale) => isToday(parseISO(sale.date)));

  // If no data for today, get data for previous day.
  if (!latestData?.length) {
    latestData = sortedSales.filter((sale: Sale) => isYesterday(parseISO(sale.date)));
  }

  // If no data for the previous day, get the latest day.
  if (!latestData?.length) {
    const allDates = sortedSales.map((sale: Sale) => new Date(sale.date));
    const latestDate = max(allDates);
    latestData = sortedSales.filter((sale: Sale) => isEqual(new Date(sale.date), latestDate));
  }

  const noLatestData = !latestData?.length;
  if (noLatestData) return <DefaultEmptyMessage content="sales" />;

  const formatter: { format: Function } = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const filterLatestData = (data: Sale[]): Sale[] =>
    data.filter(
      (sale: Sale) => sale.amount !== 0 && filters?.areas?.includes(sale?.title.toUpperCase())
    );

  const filteredLatestData = filterLatestData(latestData);
  const total = sumBy(filteredLatestData, 'amount');

  if (total === 0) return <DefaultEmptyMessage content="sales" />;

  const getData = () =>
    filteredLatestData.map((sale: Sale) => ({
      title: <Box fontSize="s">{sale.title}</Box>,
      description: <Box fontSize="s">{PARKING[sale.description] || sale.description}</Box>,
      date: <Box fontSize="s">{format(parseISO(sale.date), 'LL/dd/yy')}</Box>,
      amount: (
        <Box fontSize="s" textAlign="right">
          {formatter.format(sale.amount)}
        </Box>
      ),
    }));

  return (
    <>
      <Table
        name="sales"
        striped={false}
        bordered={true}
        grid="1.25fr 1.5fr repeat(2, 1fr)"
        gridGap="0"
        cellPaddingX="s"
        headings={headings}
        data={[
          ...getData(),
          {
            title: <TextLabel color="primary">Total</TextLabel>,
            description: '',
            date: '',
            amount: (
              <Box color="primary" fontSize="s" fontWeight="bold" textAlign="right">
                {formatter.format(total)}
              </Box>
            ),
          },
        ]}
      />
      <UpdatedAt
        date={refreshedAt}
        format={(date: Date, format: Function): string => format(new Date(date), 'LL/dd/y')}
      />
    </>
  );
};

export default Sales;
