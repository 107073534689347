import pick from 'lodash/pick';
import { useEffect, useState } from 'react';
import { CallBackProps } from 'react-joyride';
import { useHistory } from 'react-router-dom';

import { useUpdateUserMetadata } from 'client/User';
import { track } from 'utils';

import { hideModal, showModal, useModalDispatch } from 'providers/ModalProvider';
import { updateOnboarding, useOnboarding } from 'providers/OnboardingProvider';
import { updateSettings, useSettings } from 'providers/SettingsProvider';
import { useUserData } from 'providers/UserDataProvider';

function useOnboardingCallback() {
  const history = useHistory();
  const [settings, settingsDispatch] = useSettings();
  const modalDispatch = useModalDispatch();
  const { roles, permissions } = useUserData();
  const hasIssuesPermission = permissions?.includes('read:issues');

  // Joyride state helpers
  const [state, dispatch] = useOnboarding();
  const { stepIndex } = state;

  const [updateStep, setUpdateStep] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [updateMetadata] = useUpdateUserMetadata();

  const isAdmin = roles?.includes('Admin');

  // Handle modal loading for joyride tour
  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdate(false);
      modalDispatch(showModal('Settings'));
      dispatch(updateOnboarding({ run: true, stepIndex: updateStep }));
    }
  }, [shouldUpdate, dispatch, modalDispatch, stepIndex, updateStep]);

  const onboardingCallback = (data: CallBackProps) => {
    const { action, index, type } = data;
    const { stepIndex } = state;
    const isAfter = type === 'step:after';
    const isNext = action === 'next';
    const isPrev = action === 'prev';
    let skip = false;

    // Cancel tour if user clicks outside tooltip
    if (action === 'close') {
      dispatch(updateOnboarding({ run: false }));
      track('Cancel Tour');
    }

    // Mark tour skipped if user clicks skip button
    if (action === 'skip') {
      // Stop the joyride tour
      dispatch(updateOnboarding({ run: false }));

      // Set joyride tour as skipped
      settingsDispatch(updateSettings({ hasSeenTour: true }));

      // Update meta data that tour was skipped
      updateMetadata({
        variables: {
          key: 'settings',
          value: JSON.stringify({
            hasSeenTour: true,
            ...pick(settings, ['emailAlerts', 'emailInsights', 'dashboardLayout']),
          }),
        },
      });
      track('Skip Tour');
      return;
    }

    // Handle route/modal/menu interaction based on tour step
    switch (stepIndex) {
      case 0: {
        if (isNext && isAfter) history.push('/reports');
        break;
      }
      case 1: {
        if (isNext && isAfter) {
          if (hasIssuesPermission) {
            history.push('/issues');
          } else {
            skip = true;
            history.push('/');
            dispatch(updateOnboarding({ stepIndex: 3 }));
          }
        } else if (isPrev && isAfter) {
          history.push('/');
        }
        break;
      }
      case 2: {
        if (isNext && isAfter) {
          history.push('/');
        } else if (isPrev && isAfter) {
          history.push('/reports');
        }
        break;
      }
      case 3: {
        if (isPrev && isAfter) {
          if (hasIssuesPermission) {
            history.push('/issues');
          } else {
            skip = true;
            history.push('/reports');
            dispatch(updateOnboarding({ stepIndex: 1 }));
          }
        }
        break;
      }
      case 5: {
        if (isNext && isAfter) {
          settingsDispatch(
            updateSettings({
              isUserMenuOpen: true,
            })
          );
        }
        break;
      }
      case 7: {
        if (isNext && isAfter) {
          skip = true;
          modalDispatch(hideModal());
          settingsDispatch(
            updateSettings({
              isUserMenuOpen: false,
              initialDashboardSettingsPage: 1,
              initialSettingsTab: isAdmin ? 3 : 2,
            })
          );
          dispatch(updateOnboarding({ run: false }));
          setUpdateStep(stepIndex + 1);
          setShouldUpdate(true);
        }
        break;
      }
      case 8: {
        if (isNext && isAfter) {
          modalDispatch(hideModal());
          skip = true;
          settingsDispatch(
            updateSettings({
              initialDashboardSettingsPage: 1,
              initialSettingsTab: isAdmin ? 3 : 2,
            })
          );
          dispatch(updateOnboarding({ run: false }));
          setUpdateStep(stepIndex + 1);
          setShouldUpdate(true);
        } else if (isPrev && isAfter) {
          settingsDispatch(
            updateSettings({
              isUserMenuOpen: true,
            })
          );
        }
        break;
      }
      case 9: {
        if (isNext && isAfter) {
          // Last step - Mark joyride tour as completed
          track('Finish Tour');
          settingsDispatch(updateSettings({ hasSeenTour: true }));
          updateMetadata({
            variables: {
              key: 'settings',
              value: JSON.stringify({
                hasSeenTour: true,
                ...pick(settings, ['emailAlerts', 'emailInsights', 'dashboardLayout']),
              }),
            },
          });
        } else if (isPrev && isAfter) {
          modalDispatch(hideModal());
          skip = true;
          settingsDispatch(
            updateSettings({
              initialDashboardSettingsPage: 1,
              initialSettingsTab: isAdmin ? 3 : 2,
            })
          );
          dispatch(updateOnboarding({ run: false }));
          setUpdateStep(stepIndex - 1);
          setShouldUpdate(true);
        }
        break;
      }
    }

    // Update step index
    if (isNext && isAfter) {
      if (!skip) dispatch(updateOnboarding({ run: true, stepIndex: index + 1 }));
    } else if (isPrev && isAfter && index > 0) {
      if (!skip) dispatch(updateOnboarding({ stepIndex: index - 1 }));
    }
  };

  return { onboardingCallback };
}

export default useOnboardingCallback;
