import { parseISO, format } from 'date-fns';
import max from 'lodash/max';
import min from 'lodash/min';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import groupBy from 'lodash/groupBy';

import { filterByDayRange } from 'statistics/charts/utils';
import { STAT_TYPES } from 'statistics/stats/constants';
import { ChartDatum, DataObject, StatsData } from 'statistics/types';

import { ProcessedDataObject } from './utils';
import { Projection } from 'data/Projections/types';

type Timeframe = 'AM' | 'PM';

function getFilteredData(projections: Projection[], timeframe: Timeframe): Projection[] {
  switch (timeframe) {
    case 'AM':
      return projections.filter((projection: Projection) => projection.hour <= 12);
    case 'PM':
      return projections.filter((projection: Projection) => projection.hour > 12);
    default:
      return [];
  }
}

function getChartData(projections: Projection[]): ChartDatum[] {
  const data: ChartDatum[] = [];
  const groupedProjections = groupBy(projections, 'date');
  Object.keys(groupedProjections).forEach(key => {
    const projections = groupedProjections[key];
    data.push({
      x: parseISO(key).getTime(),
      y: sum(projections.map(projection => projection.count)),
    });
  });
  return sortBy(data, 'x');
}

function getStatsData(projections: Projection[], timeframe: Timeframe): StatsData {
  const totals: { [key: string]: number } = {};
  projections.forEach(projection => {
    if (!(projection.date in totals)) {
      totals[projection.date] = 0;
    }

    totals[projection.date] += projection.count;
  });

  const lowest = min(projections.map(p => p.count)) || 0;
  const highest = max(projections.map(p => p.count)) || 0;
  const average = projections.length ? sum(projections.map(p => p.count)) / projections.length : 0;

  const highestProjection = projections.find(p => p.count === highest);
  const highestLabel =
    highestProjection !== undefined
      ? `${highestProjection.date} ${format(new Date().setHours(highestProjection.hour), 'h a')}`
      : undefined;
  const lowestProjection = projections.find(p => p.count === lowest);
  const lowestLabel =
    lowestProjection !== undefined
      ? `${lowestProjection.date} ${format(new Date().setHours(lowestProjection.hour), 'h a')}`
      : undefined;

  return {
    [STAT_TYPES.PROJECTION[timeframe].AVERAGE]: {
      value: average,
      contextLabel: `Average hourly projection`,
    },
    [STAT_TYPES.PROJECTION[timeframe].HIGHEST]: {
      value: highest,
      contextLabel: highestLabel !== undefined ? highestLabel : undefined,
    },
    [STAT_TYPES.PROJECTION[timeframe].LOWEST]: {
      value: lowest,
      contextLabel: lowestLabel !== undefined ? lowestLabel : undefined,
    },
  };
}

function getProcessedData(
  projectionData: DataObject,
  dateRangeDays: string[],
  timeframe: Timeframe
): ProcessedDataObject {
  const { data: projections, loading, error } = projectionData;

  if (loading || error)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
    };

  const { byDayRange } = filterByDayRange(dateRangeDays);
  const filteredData = getFilteredData(projections as Projection[], timeframe);
  const filteredDataByDayRange = filteredData.filter((datum: Projection) =>
    byDayRange(parseISO(datum.date))
  );

  return {
    chart: {
      loading,
      error,
      data: getChartData(filteredDataByDayRange),
    },
    stats: {
      loading,
      error,
      data: getStatsData(filteredDataByDayRange, timeframe),
    },
  };
}

export default {
  am: {
    getProcessedData: (projectionData: DataObject, dateRangeDays: string[]): ProcessedDataObject =>
      getProcessedData(projectionData, dateRangeDays, 'AM'),
  },
  pm: {
    getProcessedData: (projectionData: DataObject, dateRangeDays: string[]): ProcessedDataObject =>
      getProcessedData(projectionData, dateRangeDays, 'PM'),
  },
};
