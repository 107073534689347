// @ts-nocheck
import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';
import { AIRLINES_COLORS } from 'config/chartColors';

import { getDefaultSeriesOptions, getVisibility } from 'statistics/charts/utils';
import { CapacityChartAirline } from 'statistics/types';

import { COMMON_USE_AIRLINES } from 'data/CommonUse/airlines';

export default function getSeries(
  data: CapacityChartAirline[],
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType[] {
  const cData: SeriesOptionsType[] = [];

  data.forEach(({ data: chartData }) => {
    const airlines = Object.keys(chartData);
    airlines.forEach((a, i) => {
      const name = COMMON_USE_AIRLINES[a];
      const statusData = merge(getDefaultSeriesOptions(), {
        ...chartOptions,
        id: `${name}-${i}`,
        name,
        data: chartData[a].map((d: ChartDatum) => ({ ...d, label: name })),
        yAxis: 'bag-tags',
        linkedTo: i > 0 ? `${name}-0` : undefined,
        showInLegend: true,
        tooltip: {
          pointFormatter(): string {
            let { y } = this;
            const { series } = this;
            y = Math.ceil(y || 0);
            return `${series?.name}: <b>${y.toLocaleString()}</b><br/>`;
          },
        },
        color: AIRLINES_COLORS[name],
        fillColor: AIRLINES_COLORS[name],
        ...chartOptions,
        stacking: 'normal',
        borderWidth: 0,
        type: 'column',
        visible: getVisibility(name),
      } as SeriesOptionsType);

      cData.unshift(statusData);
    });
  });
  return cData;
}
