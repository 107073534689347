import React, { useEffect, useState } from 'react';

import { TILE_KEYS, TILES } from 'config/tiles';

import { useDashboardMode } from 'hooks';

import { showModal, updateModal, useModal } from 'providers/ModalProvider';
import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile, TileTab, TileTabs } from 'components';
import CargoArrivalRevenue, { Data as CargoData } from './CargoArrivalRevenue';
import CargoDepartureRevenue from './CargoDepartureRevenue';
import { TileTabContent } from 'styled';

export const CargoRevenueTile: React.FC = () => {
  const [optionsState, optionsDispatch] = useTileOptions();
  const [modalState, modalDispatch] = useModal();
  const [currentTab, setCurrentTab] = useState(1);
  useDashboardMode(() => {
    currentTab === 0 ? setCurrentTab(1) : setCurrentTab(0);
  });

  const {
    cargoRevenue: { filters },
  } = optionsState;

  const tileConfig = TILES?.find(tile => tile.title === 'Cargo Revenue');
  const headings = tileConfig?.headings || [];

  const modalProps = {
    title: 'Cargo Revenue',
    icon: { name: 'list' },
    large: true,
    children: (
      <TileTabs initialTab={1}>
        <TileTab count={2} title="Departures" icon="arrowUpAndRight">
          <TileTabContent hasTable={true} alignItems="center">
            <RequiredPermission
              permission="read:flight"
              renderFallback={() => <NotAvailableBlankSlate />}
            >
              <CargoDepartureRevenue
                grid="repeat(4, 1fr)"
                headings={headings?.filter(heading => heading.key !== 'cost')}
                isReverseSort={filters?.isReverseSort}
                sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
              />
            </RequiredPermission>
          </TileTabContent>
        </TileTab>
        <TileTab count={2} title="Arrivals" icon="arrowDownAndLeft">
          <TileTabContent hasTable={true}>
            <RequiredPermission
              permission="read:flight"
              renderFallback={() => <NotAvailableBlankSlate />}
            >
              <CargoArrivalRevenue
                grid="repeat(4, 1fr)"
                headings={headings}
                isReverseSort={filters?.isReverseSort}
                sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
                renderExternalData={({ data }: { data: CargoData }) => ({
                  airline: data.airline,
                  operations: data.operations,
                  weight: data.weight,
                  weightNum: data.weightNum,
                  cost: data.cost,
                  costNum: data.costNum,
                })}
                renderExternalDataTotal={({ data }: { data: CargoData }) => ({
                  airline: data.airline,
                  operations: data.operations,
                  weight: data.weight,
                  cost: data.cost,
                })}
              />
            </RequiredPermission>
          </TileTabContent>
        </TileTab>
      </TileTabs>
    ),
  };

  // Update modal data if needed
  useEffect(() => {
    const isActiveModal = modalState?.activeModal?.props?.title === 'Cargo Revenue';
    const needsUpdate = optionsState?.cargoRevenue?.filters?.needsUpdate;
    if (isActiveModal && needsUpdate) {
      modalDispatch(updateModal('Default', modalProps));
      optionsDispatch(
        updateOptions({
          cargoRevenue: {
            ...optionsState.cargoRevenue,
            filters: {
              ...optionsState.cargoRevenue.filters,
              needsUpdate: false,
            },
          },
        })
      );
    }
  }, [modalDispatch, modalState, modalProps, optionsDispatch, optionsState]);

  return (
    <Tile
      title="Cargo Revenue"
      icon="list"
      contentAlignment="top"
      contentPadding="0"
      tooltip="Weights and revenue are approximate"
      modal="Default"
      modalProps={modalProps}
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Cargo Revenue Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.CARGO_REVENUE,
              })
            ),
        },
      ]}
    >
      <TileTabs initialTab={currentTab}>
        <TileTab count={2} title="Departures" icon="arrowUpAndRight">
          <TileTabContent hasTable={true}>
            <RequiredPermission
              permission="read:flight"
              renderFallback={() => <NotAvailableBlankSlate />}
            >
              <CargoDepartureRevenue
                headings={headings?.filter(
                  heading => heading.key !== 'cost' && heading.key !== 'weight'
                )}
                isReverseSort={filters?.isReverseSort}
                sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
              />
            </RequiredPermission>
          </TileTabContent>
        </TileTab>
        <TileTab count={2} title="Arrivals" icon="arrowDownAndLeft">
          <TileTabContent hasTable={true}>
            <RequiredPermission
              permission="read:flight"
              renderFallback={() => <NotAvailableBlankSlate />}
            >
              <CargoArrivalRevenue
                headings={headings?.filter(heading => heading.key !== 'operations')}
                isReverseSort={filters?.isReverseSort}
                sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
              />
            </RequiredPermission>
          </TileTabContent>
        </TileTab>
      </TileTabs>
    </Tile>
  );
};

export default CargoRevenueTile;
