import { LatLng } from 'leaflet';

export type AirfieldLine = {
  code: string;
  direction: 'vertical' | 'horizontal';
  positions: LatLng[];
  heading?: number;
};

export const LINES: AirfieldLine[] = [
  {
    code: 'RWY 18R',
    direction: 'vertical',
    heading: 180,
    positions: [new LatLng(39.07138, -84.683778), new LatLng(39.04852, -84.683778)],
  },
  {
    code: 'RWY 36L',
    direction: 'vertical',
    heading: 0,
    positions: [new LatLng(39.04852, -84.683778), new LatLng(39.07138, -84.683778)],
  },
  {
    code: 'RWY 18R/36L',
    direction: 'vertical',
    heading: 180,
    positions: [new LatLng(39.07138, -84.683778), new LatLng(39.04852, -84.683778)],
  },
  {
    code: 'RWY 18C',
    direction: 'vertical',
    heading: 180,
    positions: [new LatLng(39.065094, -84.66861), new LatLng(39.034077, -84.66861)],
  },
  {
    code: 'RWY 36C',
    direction: 'vertical',
    heading: 0,
    positions: [new LatLng(39.034077, -84.66861), new LatLng(39.065094, -84.66861)],
  },
  {
    code: 'RWY 18C/36C',
    direction: 'vertical',
    heading: 180,
    positions: [new LatLng(39.065094, -84.66861), new LatLng(39.034077, -84.66861)],
  },
  {
    code: 'RWY 18L',
    direction: 'vertical',
    heading: 180,
    positions: [new LatLng(39.056175, -84.6468), new LatLng(39.028046, -84.6468)],
  },
  {
    code: 'RWY 36R',
    direction: 'vertical',
    heading: 0,
    positions: [new LatLng(39.028046, -84.6468), new LatLng(39.056175, -84.6468)],
  },
  {
    code: 'RWY 18L/36R',
    direction: 'vertical',
    heading: 180,
    positions: [new LatLng(39.056175, -84.6468), new LatLng(39.028046, -84.6468)],
  },
  {
    code: 'RWY 09',
    direction: 'horizontal',
    heading: 90,
    positions: [new LatLng(39.0463, -84.69556), new LatLng(39.0463, -84.65248)],
  },
  {
    code: 'RWY 27',
    heading: 270,
    direction: 'horizontal',
    positions: [new LatLng(39.0463, -84.65248), new LatLng(39.0463, -84.69556)],
  },
  {
    code: 'RWY 09/27',
    direction: 'horizontal',
    heading: 90,
    positions: [new LatLng(39.0463, -84.69556), new LatLng(39.0463, -84.65248)],
  },
  {
    code: 'TWY A',
    direction: 'horizontal',
    positions: [new LatLng(39.06455, -84.6818), new LatLng(39.06455, -84.6662)],
  },
  {
    code: 'TWY B',
    direction: 'vertical',
    positions: [new LatLng(39.0508, -84.68189), new LatLng(39.0705, -84.68189)],
  },
  {
    code: 'TWY C',
    direction: 'vertical',
    positions: [new LatLng(39.04502, -84.6707), new LatLng(39.06458, -84.6707)],
  },
  {
    code: 'TWY D',
    direction: 'vertical',
    positions: [new LatLng(39.03459, -84.6673), new LatLng(39.06458, -84.6673)],
  },
  {
    code: 'TWY E',
    direction: 'vertical',
    positions: [new LatLng(39.0453, -84.6663), new LatLng(39.06458, -84.6663)],
  },
  {
    code: 'TWY J',
    direction: 'horizontal',
    positions: [new LatLng(39.0481, -84.68356), new LatLng(39.0481, -84.64863)],
  },
  {
    code: 'TWY K',
    direction: 'horizontal',
    positions: [new LatLng(39.0473, -84.69473), new LatLng(39.0473, -84.65284)],
  },
  {
    code: 'TWY M',
    direction: 'horizontal',
    positions: [new LatLng(39.045, -84.68), new LatLng(39.045, -84.648)],
  },
  {
    code: 'TWY N',
    direction: 'horizontal',
    positions: [new LatLng(39.0358, -84.648908), new LatLng(39.0358, -84.66874)],
  },
  {
    code: 'TWY R',
    direction: 'vertical',
    positions: [new LatLng(39.054365, -84.65061), new LatLng(39.048109, -84.65059)],
  },
  {
    code: 'TWY S',
    direction: 'vertical',
    positions: [new LatLng(39.05467, -84.64991), new LatLng(39.02599, -84.64991)],
  },
  {
    code: 'TWY T',
    direction: 'vertical',
    positions: [new LatLng(39.055765, -84.64876), new LatLng(39.02857, -84.64889)],
  },
  {
    code: 'TWY U',
    direction: 'vertical',
    positions: [new LatLng(39.0557215, -84.647968), new LatLng(39.054354, -84.647968)],
  },
  {
    code: 'TWY V',
    direction: 'vertical',
    positions: [new LatLng(39.0298876, -84.64811), new LatLng(39.028519, -84.64811)],
  },
  {
    code: 'TXL F',
    direction: 'vertical',
    positions: [new LatLng(39.05209, -84.66083), new LatLng(39.04732, -84.66083)],
  },
  {
    code: 'TXL G',
    direction: 'vertical',
    positions: [new LatLng(39.05209, -84.66007), new LatLng(39.04732, -84.66007)],
  },
  {
    code: 'TXL H',
    direction: 'vertical',
    positions: [new LatLng(39.0521, -84.65811), new LatLng(39.05436, -84.65811)],
  },
  {
    code: 'TXL N',
    direction: 'horizontal',
    positions: [new LatLng(39.05492, -84.65), new LatLng(39.05492, -84.6525)],
  },
  {
    code: 'TXL S',
    direction: 'horizontal',
    positions: [new LatLng(39.05435, -84.65), new LatLng(39.05435, -84.6663)],
  },
  {
    code: 'RAMP 2 NORTH TXL',
    direction: 'horizontal',
    positions: [new LatLng(39.05212, -84.65), new LatLng(39.05212, -84.6663)],
  },
  {
    code: 'RAMP 2 SOUTH TXL',
    direction: 'horizontal',
    positions: [new LatLng(39.05155, -84.65), new LatLng(39.05155, -84.6663)],
  },
  {
    code: 'RAMP 3 TXL',
    direction: 'horizontal',
    positions: [new LatLng(39.04872, -84.65), new LatLng(39.04872, -84.6663)],
  },
];

export const POINTS = [
  {
    code: 'TWY B3',
    position: new LatLng(39.0515, -84.6829),
  },
  {
    code: 'TWY B4',
    position: new LatLng(39.0543, -84.6829),
  },
  {
    code: 'TWY B5',
    position: new LatLng(39.0643, -84.6829),
  },
  {
    code: 'TWY B6',
    position: new LatLng(39.0694, -84.6829),
  },
  {
    code: 'TWY C6',
    position: new LatLng(39.049952, -84.6697),
  },
  {
    code: 'TWY C7',
    position: new LatLng(39.051452, -84.6697),
  },
  {
    code: 'TWY C8',
    position: new LatLng(39.05505, -84.6697),
  },
  {
    code: 'TWY C9',
    position: new LatLng(39.05782, -84.6697),
  },
  {
    code: 'TWY C11',
    position: new LatLng(39.063165, -84.6697),
  },
  {
    code: 'TWY D2',
    position: new LatLng(39.035886, -84.66783),
  },
  {
    code: 'TWY D3',
    position: new LatLng(39.041136, -84.66783),
  },
  {
    code: 'TWY D4',
    position: new LatLng(39.043486, -84.66783),
  },
  {
    code: 'TWY D6',
    position: new LatLng(39.050102, -84.66783),
  },
  {
    code: 'TWY D7',
    position: new LatLng(39.052168, -84.66783),
  },
  {
    code: 'TWY D8',
    position: new LatLng(39.05522, -84.66783),
  },
  {
    code: 'TWY D9',
    position: new LatLng(39.05797, -84.66783),
  },
  {
    code: 'TWY D11',
    position: new LatLng(39.063165, -84.66783),
  },
  {
    code: 'TWY E6',
    position: new LatLng(39.050102, -84.6668),
  },
  {
    code: 'TWY E7',
    position: new LatLng(39.052168, -84.6668),
  },
  {
    code: 'TWY E8',
    position: new LatLng(39.05522, -84.6668),
  },
  {
    code: 'TWY E9',
    position: new LatLng(39.05797, -84.6668),
  },
  {
    code: 'TWY E10',
    position: new LatLng(39.0605, -84.6668),
  },
  {
    code: 'TWY K2',
    position: new LatLng(39.0468, -84.65296),
  },
  {
    code: 'TWY K3',
    position: new LatLng(39.0468, -84.6545),
  },
  {
    code: 'TWY K4',
    position: new LatLng(39.0468, -84.65824),
  },
  {
    code: 'TWY K6',
    position: new LatLng(39.0468, -84.67395),
  },
  {
    code: 'TWY K7',
    position: new LatLng(39.0468, -84.6799),
  },
  {
    code: 'TWY K8',
    position: new LatLng(39.0468, -84.686),
  },
  {
    code: 'TWY K9',
    position: new LatLng(39.0468, -84.68766),
  },
  {
    code: 'TWY K10',
    position: new LatLng(39.0468, -84.6931),
  },
  {
    code: 'TWY M2',
    position: new LatLng(39.0456, -84.6529),
  },
  {
    code: 'TWY M4',
    position: new LatLng(39.0456, -84.6592),
  },
  {
    code: 'TWY M6',
    position: new LatLng(39.0456, -84.6735),
  },
  {
    code: 'TWY S3',
    position: new LatLng(39.0299, -84.6494),
  },
  {
    code: 'TWY S4',
    position: new LatLng(39.03435, -84.6494),
  },
  {
    code: 'TWY S5',
    position: new LatLng(39.03952, -84.6494),
  },
  {
    code: 'TWY S8',
    position: new LatLng(39.0543, -84.64923),
  },
  {
    code: 'TWY T3',
    position: new LatLng(39.029916, -84.6476),
  },
  {
    code: 'TWY T4',
    position: new LatLng(39.03452, -84.6477),
  },
  {
    code: 'TWY T5',
    position: new LatLng(39.03973, -84.6478),
  },
  {
    code: 'TWY T6',
    position: new LatLng(39.04457, -84.64786),
  },
  {
    code: 'TWY T7',
    position: new LatLng(39.0507, -84.6478),
  },
  {
    code: 'TWY T8',
    position: new LatLng(39.05434, -84.6478),
  },
  {
    code: 'SPOT 1',
    position: new LatLng(39.05491, -84.65062),
  },
  {
    code: 'SPOT 2',
    position: new LatLng(39.05434, -84.65062),
  },
  {
    code: 'SPOT 3',
    position: new LatLng(39.0521225, -84.65062),
  },
  {
    code: 'SPOT 4',
    position: new LatLng(39.0515553, -84.65062),
  },
  {
    code: 'SPOT 5',
    position: new LatLng(39.048718, -84.65062),
  },
  {
    code: 'SPOT 6',
    position: new LatLng(39.048718, -84.652955),
  },
  {
    code: 'SPOT 7',
    position: new LatLng(39.048718, -84.65538),
  },
  {
    code: 'SPOT 8',
    position: new LatLng(39.048718, -84.65774),
  },
  {
    code: 'SPOT 9',
    position: new LatLng(39.048718, -84.660053),
  },
  {
    code: 'SPOT 10',
    position: new LatLng(39.048718, -84.660804),
  },
  {
    code: 'SPOT 11',
    position: new LatLng(39.048718, -84.6637),
  },
  {
    code: 'SPOT 12',
    position: new LatLng(39.048718, -84.666127),
  },
  {
    code: 'SPOT 13',
    position: new LatLng(39.05007, -84.666127),
  },
  {
    code: 'SPOT 14',
    position: new LatLng(39.051565, -84.666127),
  },
  {
    code: 'SPOT 15',
    position: new LatLng(39.052115, -84.666127),
  },
  {
    code: 'SPOT 16',
    position: new LatLng(39.055202, -84.666127),
  },
  {
    code: 'SPOT 17',
    position: new LatLng(39.057904, -84.666127),
  },
  {
    code: 'SPOT 18',
    position: new LatLng(39.060506, -84.666127),
  },
  {
    code: 'SPOT 19',
    position: new LatLng(39.062974, -84.666127),
  },
  {
    code: 'SPOT 20',
    position: new LatLng(39.064265, -84.666127),
  },
  {
    code: 'SPOT 40',
    position: new LatLng(39.04855, -84.67972),
  },
  {
    code: 'SPOT 41',
    position: new LatLng(39.047736, -84.67972),
  },
  {
    code: 'SPOT 51',
    position: new LatLng(39.0281336, -84.649898),
  },
  {
    code: 'SPOT 52',
    position: new LatLng(39.0295368, -84.6505466),
  },
  {
    code: 'SPOT 54',
    position: new LatLng(39.035787, -84.65032),
  },
  {
    code: 'SPOT 55',
    position: new LatLng(39.03744, -84.65032),
  },
  {
    code: 'SPOT 56',
    position: new LatLng(39.035222, -84.65117),
  },
  {
    code: 'SPOT 57',
    position: new LatLng(39.035222, -84.653374),
  },
  {
    code: 'SPOT 58',
    position: new LatLng(39.035222, -84.65595),
  },
  {
    code: 'SPOT 59',
    position: new LatLng(39.036111, -84.656487),
  },
  {
    code: 'SPOT 65',
    position: new LatLng(39.035807, -84.656747),
  },
];
