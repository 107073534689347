import React from 'react';

import { Box } from 'styled';

import { getRVRColor } from '../utils';

import { RVR as RVRType } from 'data/RVR/types';

interface RVRProps {
  rvrs: RVRType[];
}

const RVR: React.FC<RVRProps> = ({ rvrs }: RVRProps) => {
  if (process.env.REACT_APP_CONFIG_RVR !== 'true') return null;

  const rvr = rvrs[0];
  const { value } = rvr;

  const color = getRVRColor(value);
  let valueText = `${value} ft`;
  if (value === null) valueText = 'No data';
  if (value === 6001) valueText = '>6000 ft';

  return (
    <Box alignItems="center" display="flex" justifyContent="center">
      <Box as="h1" color={color}>
        {valueText}
      </Box>
    </Box>
  );
};

export default RVR;
