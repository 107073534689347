import merge from 'lodash/merge';

import { getDefaultYAxisOptions } from 'statistics/charts/utils';

export default function getAxis(options: Highcharts.YAxisOptions = {}): Highcharts.YAxisOptions {
  return merge(getDefaultYAxisOptions(), {
    title: {
      text: 'Duration',
    },
    ...options,
    id: 'duration',
  } as Highcharts.YAxisOptions);
}
