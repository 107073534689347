import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Circle = styled.div<SystemProps>`
  background-color: ${themeGet('colors.subdued')};
  border-radius: 50%;

  ${system}
`;

export default Circle;
