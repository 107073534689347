import React from 'react';

import {
  Box,
  Checkbox as StyledCheckbox,
  CheckboxContainer,
  CheckboxDescription,
  CheckboxLabel,
} from 'styled';
import { SystemProps } from 'styled/lib/types';

interface Props extends SystemProps {
  label: string;
  labelSuffix?: Function;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: Function;
  description?: string;
}

const Checkbox: React.FC<Props> = ({
  label,
  labelSuffix,
  name,
  checked,
  disabled,
  onChange,
  description,
  ...rest
}: Props): JSX.Element => {
  function renderLabel(): JSX.Element {
    if (labelSuffix) {
      return (
        <Box display="flex" alignItems="center">
          <CheckboxLabel htmlFor={name}>{label}</CheckboxLabel>
          {labelSuffix()}
        </Box>
      );
    }

    return <CheckboxLabel htmlFor={name}>{label}</CheckboxLabel>;
  }

  return (
    <CheckboxContainer disabled={disabled}>
      <StyledCheckbox
        as="input"
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        mr="xs"
        {...rest}
      />
      <Box>
        {renderLabel()}
        {description && <CheckboxDescription>{description}</CheckboxDescription>}
      </Box>
    </CheckboxContainer>
  );
};

export default Checkbox;
