import React, { createContext, useContext, useReducer } from 'react';
import { Step } from 'react-joyride';

import tourContentCopy from 'config/tourContentCopy';

import TourContent from 'components/TourContent';

export interface State {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
  tourInitialized: boolean;
}

export interface ActionPayload {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
}

interface Action {
  type: string;
  payload: ActionPayload;
}

const initialState: State = {
  run: false,
  stepIndex: 0,
  tourInitialized: false,
  steps: [
    {
      target: '#dashboard-tab',
      title: tourContentCopy.dashboard.title,
      content: <TourContent content={tourContentCopy.dashboard} />,
      disableBeacon: true,
    },
    {
      target: '#reports-tab',
      title: tourContentCopy.reports.title,
      content: <TourContent content={tourContentCopy.reports} />,
      disableBeacon: true,
    },
    {
      target: '#issues-tab',
      title: tourContentCopy.issues.title,
      content: <TourContent content={tourContentCopy.issues} />,
      disableBeacon: false,
    },
    {
      target: '#tile-grid > div:first-child',
      title: tourContentCopy.tiles.title,
      content: <TourContent content={tourContentCopy.tiles} />,
      disableBeacon: true,
    },
    {
      target: '#tile-grid > div:first-child div h2 div svg',
      title: tourContentCopy.tileSettings.title,
      content: <TourContent content={tourContentCopy.tileSettings} />,
      disableBeacon: true,
      placement: 'right',
    },
    {
      target: '#tile-grid > div:first-child div h2 > span svg',
      title: tourContentCopy.expandTile.title,
      content: <TourContent content={tourContentCopy.expandTile} />,
      disableBeacon: true,
    },
    {
      target: '#user-menu-container > div > ol > li#feedback > a',
      title: tourContentCopy.feedback.title,
      content: <TourContent content={tourContentCopy.feedback} />,
      disableBeacon: true,
      placement: 'left',
    },
    {
      target: '#user-menu-container > div > ol > li:nth-child(1) > a',
      title: tourContentCopy.settings.title,
      content: <TourContent content={tourContentCopy.settings} />,
      disableBeacon: true,
      placement: 'left',
    },
    {
      target: '#header-logo',
      title: tourContentCopy.layout.title,
      content: <TourContent content={tourContentCopy.layout} />,
      disableBeacon: true,
      disableOverlay: true,
      placement: 'bottom-end',
      offset: 200,
    },
    {
      target: '#layout-settings-content',
      title: tourContentCopy.customizeDashboard.title,
      content: <TourContent content={tourContentCopy.customizeDashboard} />,
      disableBeacon: true,
      disableOverlay: true,
      placement: 'left',
      offset: 50,
    },
  ],
  tourActive: true,
};

const OnboardingProviderStateContext = createContext<State>(initialState);
const OnboardingProviderDispatchContext = createContext<any>(null);

const actionTypes = {
  update: 'update',
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case actionTypes.update: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

interface Props {
  children?: React.ReactNode;
}

const OnboardingProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <OnboardingProviderStateContext.Provider value={state}>
      <OnboardingProviderDispatchContext.Provider value={dispatch}>
        {children}
      </OnboardingProviderDispatchContext.Provider>
    </OnboardingProviderStateContext.Provider>
  );
};

function useOnboardingState() {
  const context = useContext(OnboardingProviderStateContext);
  if (context === undefined) {
    throw new Error(`useOnboardingState must be used within a OnboardingProvider`);
  }
  return context;
}

function useOnboardingDispatch() {
  const context = useContext(OnboardingProviderDispatchContext);
  if (context === undefined) {
    throw new Error(`useOnboardingDispatch must be used within a OnboardingProvider`);
  }
  return context;
}

function useOnboarding() {
  const context = [useOnboardingState(), useOnboardingDispatch()];
  if (context === undefined) {
    throw new Error(`useOnboarding must be used within a OnboardingProvider`);
  }
  return context;
}
const updateOnboarding = (payload: any): Action => ({
  type: actionTypes.update,
  payload,
});

export {
  OnboardingProvider as default,
  initialState,
  useOnboarding,
  useOnboardingState,
  useOnboardingDispatch,
  updateOnboarding,
  actionTypes,
};
