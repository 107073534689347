import { format, isAfter, isSameMonth, sub } from 'date-fns';
import uniqBy from 'lodash/uniqBy';

import { DateOption, FeedStatus, FeedStatusHistory, TypeOption } from 'client/FeedStatus/types';

const INCIDENTS_PER_PAGE = 10;
const EARLIEST_OPTION_DATE = new Date('1 Jan 2021');

function useFeedStatus(historyData: FeedStatusHistory[]) {
  const getFeedStatusTitle = (feed: string): string => {
    const capitalizeFirstLetter = (string: string) =>
      string.charAt(0).toUpperCase() + string.slice(1);
    if (feed === 'facilityConditions') return 'Facility Conditions';
    if (feed === 'remoteParking') return 'Remote Parking';
    if (feed === 'customerFeedback') return 'Customer Feedback';
    return capitalizeFirstLetter(feed);
  };

  // History date filter options
  const getHistoryDateOptions = () => {
    let dateOption = new Date();
    const historyOptions = [];
    while (isAfter(dateOption, EARLIEST_OPTION_DATE)) {
      historyOptions.push({
        label: format(dateOption, 'LLLL yyyy'),
        value: new Date(dateOption).toString(),
      });
      dateOption = sub(dateOption, { months: 1 });
    }
    return historyOptions;
  };
  const historyDateOptions = getHistoryDateOptions();

  // History type filter options
  const historyTypeOptions = historyData?.length
    ? uniqBy(
        historyData?.map((feed: FeedStatusHistory) => ({
          label: getFeedStatusTitle(feed.feed),
          value: feed.feed,
        })),
        'label'
      )
    : [];
  historyTypeOptions.push({ label: 'All', value: 'All' });

  // History results pagination
  const getHistoryResults = (history: FeedStatusHistory[], pageIndex: number) => {
    if (!history?.length) return [];
    if (history.length < INCIDENTS_PER_PAGE) return history;
    const currentIndex = pageIndex * INCIDENTS_PER_PAGE;
    const results = history.slice(currentIndex, currentIndex + INCIDENTS_PER_PAGE);
    return results;
  };

  const getStatusIcon = (status: string): string => {
    if (status === 'DANGER') return 'xCircle';
    if (status === 'WARNING') return 'alertTriangle';
    return 'checkCircle';
  };

  const getStatusColor = (status: string): string => {
    if (status === 'DANGER') return 'error';
    if (status === 'WARNING') return 'warning';
    return 'success';
  };

  const getStatusBorderColor = (status: string): string => {
    if (status === 'DANGER') return 'error';
    if (status === 'WARNING') return 'warning';
    return 'neutralsGradient.7';
  };

  const getFeedIssuesTitle = (feeds: FeedStatus[]): string => {
    if (feeds?.length === 1) {
      return `Data Issues with ${getFeedStatusTitle(feeds[0].feed)}`;
    }
    if (feeds?.length === 2) {
      return `Data Issues with ${getFeedStatusTitle(feeds?.[0]?.feed)} and ${getFeedStatusTitle(
        feeds?.[1]?.feed
      )}`;
    }
    return `Data Issues with ${feeds.map((feed, i) =>
      i + 1 === feeds.length
        ? ` and ${getFeedStatusTitle(feed.feed)}`
        : ` ${getFeedStatusTitle(feed.feed)}`
    )}.`;
  };

  // Filter feeds by selected date range and feed type
  const filterHistoryData = (
    historyData: FeedStatusHistory[],
    dateOption: DateOption,
    typeOption: TypeOption
  ) =>
    historyData?.length && dateOption && typeOption
      ? historyData
          ?.filter(o => isSameMonth(new Date(o?.updatedAt), new Date(dateOption?.value)))
          ?.filter(o => typeOption?.value === 'All' || o?.feed === typeOption?.value)
          .reverse()
      : [];

  return {
    filterHistoryData,
    getFeedIssuesTitle,
    getFeedStatusTitle,
    getHistoryResults,
    getStatusBorderColor,
    getStatusColor,
    getStatusIcon,
    historyDateOptions,
    historyTypeOptions,
    INCIDENTS_PER_PAGE,
  };
}

export default useFeedStatus;
