import { CANCELED, COMPLETED, DELAYED, ON_TIME } from 'data/Flights/constants';
import { DepartureFlight } from 'data/Flights/types';

import { FlightSummary } from '../types';

function getDepartureSummary(flights: DepartureFlight[]): FlightSummary {
  const total = flights.length;
  let completed = 0;
  let onTime = 0;
  let delayed = 0;
  let canceled = 0;

  flights.forEach((flight: DepartureFlight) => {
    const isCanceled = flight.statusText === CANCELED;
    const isDelayed = flight.statusText === DELAYED;
    const isScheduled = flight.statusText === ON_TIME;
    const isCompleted = flight.statusText === COMPLETED;

    if (isCanceled) return canceled++;
    if (isDelayed) return delayed++;
    if (isScheduled) return onTime++;
    if (isCompleted) return completed++;
    return completed++;
  });

  const remaining = total - (completed + delayed + canceled);

  return { total, completed, onTime, delayed, canceled, remaining };
}

export default getDepartureSummary;
