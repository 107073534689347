/* eslint-disable @typescript-eslint/camelcase */
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';

import { useUser } from 'client/User';

import { CenteredLoader } from 'components';

interface State {
  created_at: Date | null;
  email: string;
  last_login: Date | null;
  name: string;
  permissions: string[];
  roles: string[];
  picture: string;
  updatedAt: Date | null;
  user_id: string;
  user_metadata: object;
}

const UserDataContext = createContext<State>({
  created_at: null,
  email: '',
  last_login: null,
  name: '',
  permissions: [],
  roles: [],
  picture: '',
  updatedAt: null,
  user_id: '',
  user_metadata: {},
});

interface Props {
  children?: React.ReactNode;
}

const UserDataProvider: React.FC = ({ children }: Props) => {
  const { user } = useAuth0();
  const { data, loading } = useUser(user?.email || '');
  const state: State = useMemo(() => ({ ...data?.user }), [data]);

  if (loading) return <CenteredLoader label="Verifying permissions..." />;

  return <UserDataContext.Provider value={state}>{children}</UserDataContext.Provider>;
};

// const state = useUserData();
function useUserData() {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error(`useUserData must be used within a UserDataProvider`);
  }
  return context;
}

UserDataProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

export { UserDataProvider as default, useUserData };
