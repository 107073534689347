import { endOfDay, isToday, startOfDay, subDays } from 'date-fns';
import omit from 'lodash/omit';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { ChartOptionsFilter } from 'config/tabs';
import { getAirportTime } from 'utils';

export interface Action {
  type: string;
  payload?: object;
}

export interface State {
  activeFilters: ChartOptionsFilter[];
  activeReport: boolean;
  airline: string[];
  airlineInputValue: string;
  chartEndDate: Date;
  chartStartDate: Date;
  currentDate: Date;
  dataEndDate: Date;
  dataStartDate: Date;
  dateRange: number;
  dateRangeDays: string[];
  destinationAirport: string;
  destinationAirportInputValue: string;
  frozen: boolean;
  height: number;
  selectedGates: string[];
  tabs: string[];
  weather: string[];
  weatherInputValue: string;
  width: number;
}

export const DEFAULT_DATE_RANGE = 7; // Days
export const DATE_RANGE_DAYS: string[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const initDate = getAirportTime(new Date());
export const initialState: State = {
  activeFilters: [],
  activeReport: false,
  airline: [],
  airlineInputValue: '',
  chartEndDate: startOfDay(initDate),
  chartStartDate: startOfDay(subDays(initDate, DEFAULT_DATE_RANGE)),
  currentDate: initDate,
  dataEndDate: endOfDay(initDate),
  dataStartDate: startOfDay(subDays(initDate, DEFAULT_DATE_RANGE)),
  dateRange: DEFAULT_DATE_RANGE,
  dateRangeDays: DATE_RANGE_DAYS,
  destinationAirport: '',
  destinationAirportInputValue: '',
  frozen: false,
  height: 400,
  selectedGates: [],
  tabs: [],
  weather: [],
  weatherInputValue: '',
  width: 1200,
};

const ChartOptionsProviderStateContext = createContext<State>(initialState);
const ChartOptionsProviderDispatchContext = createContext<React.Dispatch<Action> | null>(null);

const actionTypes = {
  update: 'update',
  unlock: 'unlock',
  reset: 'reset',
};

// ACTION CREATORS
// dispatch(updateOptions({ key: 'value' }));
const updateOptions = (payload: object) => ({
  type: actionTypes.update,
  payload,
});

// dispatch(resetOptions());
const resetOptions = () => ({ type: actionTypes.reset });

// dispatch(unlockOptions());
const unlockOptions = () => ({ type: actionTypes.unlock });

function reducer(state: State, action: Action) {
  switch (action.type) {
    case actionTypes.update: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.unlock: {
      return {
        ...state,
        activeReport: false,
        dateRange: null,
      };
    }
    case actionTypes.reset: {
      return {
        ...state,
        ...omit(initialState, ['height', 'width']),
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

interface Props {
  height?: number;
  width?: number;
  dataStartDate?: Date;
  dataEndDate?: Date;
  chartStartDate?: Date;
  chartEndDate?: Date;
  currentDate?: Date;
  getCurrentDate?: Function;
  tabs?: string[];
  children?: React.ReactNode;
}

const ChartOptionsProvider: React.FC<Props> = ({
  children,
  height,
  width,
  dataStartDate,
  dataEndDate,
  chartStartDate,
  chartEndDate,
  currentDate,
  tabs,
  getCurrentDate,
}: Props) => {
  //@ts-expect-error
  const [state, dispatch] = useReducer(reducer, initialState, state => ({
    ...state,
    tabs: tabs || state.tabs,
    height: height || state.height,
    width: width || state.width,
    dataStartDate: dataStartDate || state.dataStartDate,
    dataEndDate: dataEndDate || state.dataEndDate,
    chartStartDate: chartStartDate || state.chartStartDate,
    chartEndDate: chartEndDate || state.chartEndDate,
    currentDate: currentDate || state.currentDate,
  }));

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!state.frozen && isToday(state.currentDate) && getCurrentDate) {
      const { timeoutInMs, date } = getCurrentDate(state.currentDate);
      timeout = setTimeout(() => {
        //@ts-expect-error
        dispatch(updateOptions({ currentDate: date }));
      }, timeoutInMs);
    }

    return (): void => clearTimeout(timeout);
  }, [getCurrentDate, state.frozen, state.currentDate]);

  return (
    <ChartOptionsProviderStateContext.Provider value={state}>
      <ChartOptionsProviderDispatchContext.Provider value={dispatch}>
        {children}
      </ChartOptionsProviderDispatchContext.Provider>
    </ChartOptionsProviderStateContext.Provider>
  );
};

// const state = useChartOptionsState();
function useChartOptionsState() {
  const context = useContext(ChartOptionsProviderStateContext);
  if (context === undefined) {
    throw new Error(`useChartOptionsState must be used within a ChartOptionsProvider`);
  }
  return context;
}

// const dispatch = useChartOptionsDispatch();
function useChartOptionsDispatch() {
  const context = useContext(ChartOptionsProviderDispatchContext);
  if (context === undefined) {
    throw new Error(`useChartOptionsDispatch must be used within a ChartOptionsProvider`);
  }
  return context;
}

// const [state, dispatch] = useChartOptions();
function useChartOptions() {
  const context: [State, React.Dispatch<Action>] = [
    useChartOptionsState(),
    useChartOptionsDispatch() as React.Dispatch<Action>,
  ];
  if (context === undefined) {
    throw new Error(`useChartOptions must be used within a ChartOptionsProvider`);
  }
  return context;
}

// SELECTORS
const selectors = {};

export {
  ChartOptionsProvider as default,
  useChartOptions,
  useChartOptionsState,
  useChartOptionsDispatch,
  actionTypes,
  selectors,
  updateOptions,
  resetOptions,
  unlockOptions,
};
