import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useLocalStorage, useQueryParams } from 'hooks';
import { TrafficProvider } from 'providers';
import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';

import Traffic from './Traffic';
import TrafficDetails from './TrafficDetails';
import { useTrafficDetails } from './hooks';

export const TrafficTile: React.FC = () => {
  const { loading, accidents, getAccidentInformation } = useTrafficDetails();

  const query = useQueryParams();
  const history = useHistory();
  const location = useLocation();
  const { setValue: setRedirectPath } = useLocalStorage('redirectPath');

  function getTooltip(): string {
    if (loading) return '';
    if (accidents.length === 0) return '';
    return getAccidentInformation();
  }

  return (
    <Tile
      title="Traffic"
      icon="pin"
      floatingHeader={true}
      contentAlignment="top"
      contentPadding="0"
      modal="Default"
      modalProps={{
        title: 'Traffic',
        icon: { name: 'pin' },
        fullscreen: true,
        onClose: () => {
          // Update redirect path without fullscreen tile param
          setRedirectPath(location.pathname);

          // Remove tile query param on modal close
          query?.delete('tile');
          history.replace({ search: query.toString() });
        },
        children: (
          <TrafficProvider>
            <TrafficDetails />
          </TrafficProvider>
        ),
      }}
      tooltip={getTooltip()}
      tooltipIcon={{ name: 'bell', color: 'error' }}
      tooltipClickTriggerModal={true}
    >
      <RequiredPermission
        permission="read:traffic"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <Traffic />
      </RequiredPermission>
    </Tile>
  );
};

export default TrafficTile;
