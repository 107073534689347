import React from 'react';

import airlineLogos from 'config/airlineLogos';

import { Icon } from 'components';

import { Icon as StyledIcon } from 'styled';
import { SystemProps } from 'styled/lib/types';

export interface Props extends SystemProps {
  name: string;
  color?: string;
  description?: string;
  size?: string;
}

export const AlertIcon: React.FC<Props> = ({ name, color, description, size }) => {
  let icon;
  const _size = size || '32';

  switch (name) {
    case 'parking_st':
    case 'parking_vp':
    case 'parking_ec':
    case 'parking_gl':
    case 'parking_ga':
    case 'parking_gal2':
    case 'parking_gal3':
    case 'parking_gar':
    case 'parking_lt':
    case 'parking_yl':
      icon = <Icon name="parking" fill={color} size={_size} stroke="1px" />;
      break;
    case 'security':
    case 'security_tsa':
      icon = <Icon name="security" fill={color} size={_size} stroke="1px" />;
      break;
    case 'scans':
    case 'scans_selectee':
      icon = <Icon name="scans" fill={color} size={_size} stroke="1px" />;
      break;
    case 'feedback':
      icon = <Icon name="messageSquare" size={_size} color={color} />;
      break;
    case 'bathroomTraffic':
      icon = <Icon name="toiletPaper" size={_size} color={color} />;
      break;
    case 'rvr':
      icon = <Icon name="cloud" size={_size} color={color} />;
      break;
    case 'cancellations':
      if (description) {
        const iataCode = description?.substring(15, 17);
        const iconSvg = airlineLogos[iataCode];
        icon = iconSvg ? (
          <StyledIcon
            as="svg"
            width={_size}
            height={_size}
            viewBox={`0 0 16 16`}
            dangerouslySetInnerHTML={{ __html: iconSvg }}
          />
        ) : (
          <Icon name="x" color={color} size={_size} />
        );
      } else {
        icon = <Icon name="x" color={color} size={_size} />;
      }
      break;
    case 'delays':
      icon = <Icon name="clock" color={color} size={_size} />;
      break;
    default:
      icon = <Icon name={name} fill={color} size={_size} stroke="1px" />;
      break;
  }

  return icon;
};

export default AlertIcon;
