import React, { useEffect, useState } from 'react';

import { Box, FormLabel, Select as StyledSelect } from 'styled';

interface Option {
  value: string;
  label: string;
}

interface Props {
  name: string;
  onChange?: Function;
  options: Option[];
  type?: 'dropdown' | 'radio';
  value?: string;
  required?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

const Select: React.FC<Props> = ({
  name,
  onChange,
  options,
  type,
  required,
  value,
  disabled,
}: Props) => {
  const [selected, setSelected] = useState<string>(value || '');

  useEffect(() => {
    setSelected(value || '');
  }, [setSelected, value]);

  function handleChange(event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void {
    const { value } = event.target;
    setSelected(value);
    onChange && onChange(event);
  }

  if (type === 'radio') {
    return (
      <>
        {options.map((option: Option, idx: number) => {
          const isFirstItem = idx === 0;
          const isLastItem = options.length === idx + 1;
          const labelize = (string: string): string => string.replace(' ', '-').toLowerCase();
          const value = option.value === '' ? labelize(option.label) : option.value;

          return (
            <Box key={idx} alignItems="center" display="flex" mb={isLastItem ? '0' : 's'}>
              <Box
                as="input"
                type="radio"
                id={value}
                name={name}
                value={option.value}
                onChange={handleChange}
                checked={option.value === selected}
                mr="xs"
                position="relative"
                top="0"
                required={isFirstItem ? true : false}
              />
              <FormLabel htmlFor={value}>{option.label}</FormLabel>
            </Box>
          );
        })}
      </>
    );
  }

  return (
    <StyledSelect
      name={name}
      id={name}
      value={selected}
      onChange={handleChange}
      required={required}
      disabled={disabled}
    >
      {!value && <option value="">Select&hellip;</option>}
      {options.map((option: Option, idx: number) => (
        <option key={idx} value={option.value}>
          {option.label}
        </option>
      ))}
    </StyledSelect>
  );
};

export default Select;
