export const CHART_TYPES = {
  ARRIVALS: {
    PASSENGER: 'ARRIVALS.PASSENGER',
    CARGO: 'ARRIVALS.CARGO',
  },
  BAGGAGE: {
    TOTAL: 'BAGGAGE.TOTAL',
    B_BAGS: 'BAGGAGE.B_BAGS',
    T_DRIVE: 'BAGGAGE.T_DRIVE',
    BAGS_RECEIVED: 'BAGGAGE.BAGS_RECEIVED',
    BAGS_CLEARED: 'BAGGAGE.BAGS_CLEARED',
    ML1_READ_RATE: 'BAGGAGE.ML1_READ_RATE',
    ML2_READ_RATE: 'BAGGAGE.ML2_READ_RATE',
    CB1_READ_RATE: 'BAGGAGE.CB1_READ_RATE',
  },
  BOOKINGS: 'BOOKINGS',
  COMMON_USE: {
    WORKSTATIONS: 'COMMON_USE.WORKSTATIONS',
    BAG_TAGS: 'COMMON_USE.BAG_TAGS',
  },
  COMMON_USE_KIOSKS: {
    BAG_TAGS: 'COMMON_USE_KIOSKS.BAG_TAGS',
    BOARDING_PASSES: 'COMMON_USE_KIOSKS.BOARDING_PASSES',
  },
  FLIGHT_PERFORMANCE: 'FLIGHT_PERFORMANCE',
  CARGO_REVENUE: 'CARGO_REVENUE',
  DAILY_THROUGHPUT: {
    DAILY: 'DAILY_THROUGHPUT.DAILY',
    ROLLING_AVG: 'DAILY_THROUGHPUT.ROLLING_AVG',
    ONE_YEAR_AGO: 'DAILY_THROUGHPUT.ONE_YEAR_AGO',
    YEAR_2019: 'DAILY_THROUGHPUT.YEAR_2019',
  },
  DAILY_PROJECTION: 'DAILY_PROJECTION',
  DEPARTURES: {
    PASSENGER: 'DEPARTURES.PASSENGER',
    CARGO: 'DEPARTURES.CARGO',
  },
  GATES: {
    GATES1: 'GATES1',
    GATES2: 'GATES2',
    GATES3: 'GATES3',
    GATES4: 'GATES4',
  },
  PASSENGERS: {
    STATUS: 'PASSENGERS.STATUS',
    AGE: 'PASSENGERS.AGE',
    GENDER: 'PASSENGERS.GENDER',
  },
  PROJECTION: {
    AM: 'PROJECTION.AM',
    PM: 'PROJECTION.PM',
  },
  SALES: {
    TOTAL: 'SALES.TOTAL',
    CONCOURSE_A: 'SALES.CONCOURSE_A',
    CONCOURSE_B: 'SALES.CONCOURSE_B',
    MAIN_TERMINAL: 'SALES.MAIN_TERMINAL',
    SHORT_TERM: 'SALES.SHORT_TERM',
    LONG_TERM: 'SALES.LONG_TERM',
    ECONO_LOT: 'SALES.ECONO_LOT',
    VALET: 'SALES.VALET',
  },
  TSA_COUNTS: {
    CURRENT: 'TSA_COUNTS.CURRENT',
    YEAR_2019: 'TSA_COUNTS.YEAR_2019',
  },
  WAIT_TIMES: {
    WAIT_TIMES1: 'WAIT_TIMES.1',
    WAIT_TIMES2: 'WAIT_TIMES.2',
    WAIT_TIMES3: 'WAIT_TIMES.3',
    WAIT_TIMES4: 'WAIT_TIMES.4',
    WAIT_TIMES5: 'WAIT_TIMES.5',
  },
  WEATHER: 'WEATHER',
};

export const FILTER_TYPES = {
  COMMON_USE: {
    GATE_COUNTER: 'COMMON_USE.GATE_COUNTER',
    SHOW_ALL: 'SHOW_ALL',
    TICKET_COUNTER: 'COMMON_USE.TICKET_COUNTER',
  },
  DAILY_THROUGHPUT: {
    LOCAL: 'DAILY_THROUGHPUT.LOCAL',
    ALL_AIRPORTS: 'DAILY_THROUGHPUT.ALL_AIRPORTS',
  },
};

export const NUM_DAYS_FOR_DAILY_INTERVAL = 4;

export const TOOLTIP_HEADER_FORMAT =
  '<span style="font-size: 10px">{point.x:%A, %b %e, %Y}</span><br/>';
