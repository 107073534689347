import { ApolloError } from '@apollo/client';
import { format } from 'date-fns';

import { initialState, State } from 'providers/ChartOptionsProvider';

import { CHART_TYPES } from 'statistics/charts/constants';
import { STAT_TYPES } from 'statistics/stats/constants';
import { ChartDatumType, DataObject, ProvidersContexts, StatsDataObject } from 'statistics/types';

import processors from './';
import { DATA_TYPES, DETAILS_TYPES, PROCESSOR_TYPES } from './constants';

export type ChartData = ChartDatumType[];
export type DetailsData = any;

export type ChartDataObject = {
  loading?: boolean;
  error?: ApolloError;
  data: ChartData;
};

export type DetailsDataObject = {
  loading?: boolean;
  error?: ApolloError;
  data: DetailsData;
};

export type ProcessedDataObject = {
  chart?: ChartDataObject;
  stats?: StatsDataObject;
  details?: DetailsDataObject;
};

export function getDataTypes(types: string[]): Set<string> {
  const dataTypes: Set<string> = new Set();
  types.forEach(type => {
    switch (type) {
      case CHART_TYPES.PASSENGERS.AGE:
      case CHART_TYPES.PASSENGERS.GENDER:
      case CHART_TYPES.PASSENGERS.STATUS:
        dataTypes.add(DATA_TYPES.PASSENGERS);
        break;

      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES1:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES2:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES3:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES4:
      case CHART_TYPES.WAIT_TIMES.WAIT_TIMES5:
      case STAT_TYPES.WAIT_TIMES.WAIT_TIMES1.AVERAGE:
      case STAT_TYPES.WAIT_TIMES.WAIT_TIMES1.HIGHEST:
      case STAT_TYPES.WAIT_TIMES.WAIT_TIMES2.AVERAGE:
      case STAT_TYPES.WAIT_TIMES.WAIT_TIMES2.HIGHEST:
      case STAT_TYPES.WAIT_TIMES.WAIT_TIMES3.AVERAGE:
      case STAT_TYPES.WAIT_TIMES.WAIT_TIMES3.HIGHEST:
      case STAT_TYPES.WAIT_TIMES.WAIT_TIMES4.AVERAGE:
      case STAT_TYPES.WAIT_TIMES.WAIT_TIMES4.HIGHEST:
      case STAT_TYPES.WAIT_TIMES.WAIT_TIMES5.AVERAGE:
      case STAT_TYPES.WAIT_TIMES.WAIT_TIMES5.HIGHEST:
        dataTypes.add(DATA_TYPES.WAIT_TIMES);
        break;

      case CHART_TYPES.CARGO_REVENUE:
      case CHART_TYPES.ARRIVALS.PASSENGER:
      case CHART_TYPES.ARRIVALS.CARGO:
      case STAT_TYPES.CARGO_REVENUE.AVERAGE:
      case STAT_TYPES.CARGO_REVENUE.HIGHEST:
      case STAT_TYPES.CARGO_REVENUE.LOWEST:
      case STAT_TYPES.ARRIVALS.PASSENGER.AVERAGE:
      case STAT_TYPES.ARRIVALS.PASSENGER.HIGHEST:
      case STAT_TYPES.ARRIVALS.CARGO.AVERAGE:
      case STAT_TYPES.ARRIVALS.CARGO.HIGHEST:
        dataTypes.add(DATA_TYPES.ARRIVALS);
        dataTypes.add(DATA_TYPES.WEATHER);
        break;

      case CHART_TYPES.DEPARTURES.PASSENGER:
      case CHART_TYPES.DEPARTURES.CARGO:
      case STAT_TYPES.DEPARTURES.PASSENGER.AVERAGE:
      case STAT_TYPES.DEPARTURES.PASSENGER.HIGHEST:
      case STAT_TYPES.DEPARTURES.CARGO.AVERAGE:
      case STAT_TYPES.DEPARTURES.CARGO.HIGHEST:
        dataTypes.add(DATA_TYPES.DEPARTURES);
        dataTypes.add(DATA_TYPES.WEATHER);
        break;

      case CHART_TYPES.FLIGHT_PERFORMANCE:
        dataTypes.add(DATA_TYPES.DEPARTURES);
        dataTypes.add(DATA_TYPES.CAPACITY_DEPARTURES);
        dataTypes.add(DATA_TYPES.WEATHER);
        break;

      case CHART_TYPES.SALES.CONCOURSE_A:
      case CHART_TYPES.SALES.CONCOURSE_B:
      case CHART_TYPES.SALES.MAIN_TERMINAL:
      case CHART_TYPES.SALES.SHORT_TERM:
      case CHART_TYPES.SALES.LONG_TERM:
      case CHART_TYPES.SALES.ECONO_LOT:
      case CHART_TYPES.SALES.VALET:
      case CHART_TYPES.SALES.TOTAL:
      case STAT_TYPES.SALES.CONCOURSE_A.AVERAGE:
      case STAT_TYPES.SALES.CONCOURSE_A.HIGHEST:
      case STAT_TYPES.SALES.CONCOURSE_A.LOWEST:
      case STAT_TYPES.SALES.CONCOURSE_B.AVERAGE:
      case STAT_TYPES.SALES.CONCOURSE_B.HIGHEST:
      case STAT_TYPES.SALES.CONCOURSE_B.LOWEST:
      case STAT_TYPES.SALES.MAIN_TERMINAL.AVERAGE:
      case STAT_TYPES.SALES.MAIN_TERMINAL.HIGHEST:
      case STAT_TYPES.SALES.MAIN_TERMINAL.LOWEST:
      case STAT_TYPES.SALES.ECONO_LOT.AVERAGE:
      case STAT_TYPES.SALES.ECONO_LOT.HIGHEST:
      case STAT_TYPES.SALES.ECONO_LOT.LOWEST:
      case STAT_TYPES.SALES.SHORT_TERM.AVERAGE:
      case STAT_TYPES.SALES.SHORT_TERM.HIGHEST:
      case STAT_TYPES.SALES.SHORT_TERM.LOWEST:
      case STAT_TYPES.SALES.LONG_TERM.AVERAGE:
      case STAT_TYPES.SALES.LONG_TERM.HIGHEST:
      case STAT_TYPES.SALES.LONG_TERM.LOWEST:
      case STAT_TYPES.SALES.VALET.AVERAGE:
      case STAT_TYPES.SALES.VALET.HIGHEST:
      case STAT_TYPES.SALES.VALET.LOWEST:
      case STAT_TYPES.SALES.TOTAL.AVERAGE:
      case STAT_TYPES.SALES.TOTAL.HIGHEST:
      case STAT_TYPES.SALES.TOTAL.LOWEST:
        dataTypes.add(DATA_TYPES.SALES);
        dataTypes.add(DATA_TYPES.WEATHER);
        break;

      case CHART_TYPES.DAILY_THROUGHPUT.DAILY:
      case CHART_TYPES.DAILY_THROUGHPUT.ROLLING_AVG:
      case CHART_TYPES.DAILY_THROUGHPUT.ONE_YEAR_AGO:
      case CHART_TYPES.DAILY_THROUGHPUT.YEAR_2019:
      case STAT_TYPES.DAILY_THROUGHPUT.AVERAGE:
      case STAT_TYPES.DAILY_THROUGHPUT.HIGHEST:
      case STAT_TYPES.DAILY_THROUGHPUT.LOWEST:
        dataTypes.add(DATA_TYPES.DAILY_THROUGHPUTS);
        break;

      case CHART_TYPES.TSA_COUNTS.CURRENT:
      case CHART_TYPES.TSA_COUNTS.YEAR_2019:
      case STAT_TYPES.TSA_COUNTS.AVERAGE:
      case STAT_TYPES.TSA_COUNTS.HIGHEST:
      case STAT_TYPES.TSA_COUNTS.LOWEST:
        dataTypes.add(DATA_TYPES.TSA_COUNTS);
        break;

      case CHART_TYPES.BOOKINGS:
      case STAT_TYPES.BOOKINGS.AVERAGE:
      case STAT_TYPES.BOOKINGS.HIGHEST:
      case STAT_TYPES.BOOKINGS.LOWEST:
        dataTypes.add(DATA_TYPES.BOOKINGS);
        break;

      case CHART_TYPES.BAGGAGE.B_BAGS:
      case CHART_TYPES.BAGGAGE.T_DRIVE:
      case CHART_TYPES.BAGGAGE.BAGS_RECEIVED:
      case CHART_TYPES.BAGGAGE.BAGS_CLEARED:
      case CHART_TYPES.BAGGAGE.ML1_READ_RATE:
      case CHART_TYPES.BAGGAGE.ML2_READ_RATE:
      case CHART_TYPES.BAGGAGE.CB1_READ_RATE:
      case CHART_TYPES.BAGGAGE.TOTAL:
      case STAT_TYPES.BAGGAGE.TOTAL.AVERAGE:
      case STAT_TYPES.BAGGAGE.TOTAL.HIGHEST:
      case STAT_TYPES.BAGGAGE.TOTAL.LOWEST:
        dataTypes.add(DATA_TYPES.BAGGAGE);
        break;

      case CHART_TYPES.GATES.GATES1:
      case CHART_TYPES.GATES.GATES2:
      case CHART_TYPES.GATES.GATES3:
      case CHART_TYPES.GATES.GATES4:
        dataTypes.add(DATA_TYPES.FLOW);
        break;

      case CHART_TYPES.DAILY_PROJECTION:
      case CHART_TYPES.PROJECTION.AM:
      case STAT_TYPES.PROJECTION.AM.AVERAGE:
      case STAT_TYPES.PROJECTION.AM.HIGHEST:
      case STAT_TYPES.PROJECTION.AM.LOWEST:
      case CHART_TYPES.PROJECTION.PM:
      case STAT_TYPES.PROJECTION.PM.AVERAGE:
      case STAT_TYPES.PROJECTION.PM.HIGHEST:
      case STAT_TYPES.PROJECTION.PM.LOWEST:
        dataTypes.add(DATA_TYPES.PROJECTION);
        break;

      case CHART_TYPES.COMMON_USE.WORKSTATIONS:
        dataTypes.add(DATA_TYPES.COMMON_USE_WORKSTATIONS);
        break;

      case CHART_TYPES.COMMON_USE_KIOSKS.BAG_TAGS:
      case CHART_TYPES.COMMON_USE_KIOSKS.BOARDING_PASSES:
        dataTypes.add(DATA_TYPES.COMMON_USE_KIOSKS);
        break;

      case CHART_TYPES.COMMON_USE.BAG_TAGS:
        dataTypes.add(DATA_TYPES.COMMON_USE_WORKSTATIONS);
        dataTypes.add(DATA_TYPES.COMMON_USE_KIOSKS);
        break;
      default:
        break;
    }
  });

  return dataTypes;
}

export function getContextsData(
  dataTypes: Set<string>,
  contexts: ProvidersContexts
): { [key: string]: DataObject } {
  const data: { [key: string]: DataObject } = {};
  dataTypes.forEach((type: string) => {
    switch (type) {
      case DATA_TYPES.ARRIVALS:
        data[type] = {
          loading: contexts.arrivalsContext?.loading,
          error: contexts.arrivalsContext?.error,
          data: contexts.arrivalsContext?.arrivals,
        };
        break;
      case DATA_TYPES.BAGGAGE:
        data[type] = {
          loading: contexts.baggageContext?.loading,
          error: contexts.baggageContext?.error,
          data: contexts.baggageContext?.baggage,
        };
        break;
      case DATA_TYPES.BOOKINGS:
        data[type] = {
          loading: contexts.bookingsContext?.loading,
          error: contexts.bookingsContext?.error,
          data: contexts.bookingsContext?.bookings,
        };
        break;
      case DATA_TYPES.DAILY_THROUGHPUTS:
        data[type] = {
          loading: contexts.dailyThroughputsContext?.loading,
          error: contexts.dailyThroughputsContext?.error,
          data: contexts.dailyThroughputsContext?.throughputs,
        };
        break;
      case DATA_TYPES.TSA_COUNTS:
        data[type] = {
          loading: contexts.tsaCountsContext?.loading,
          error: contexts.tsaCountsContext?.error,
          data: contexts.tsaCountsContext?.tsaCounts,
        };
        break;
      case DATA_TYPES.DEPARTURES:
        data[type] = {
          loading: contexts.departuresContext?.loading,
          error: contexts.departuresContext?.error,
          data: contexts.departuresContext?.departures,
        };
        break;
      case DATA_TYPES.CAPACITY_DEPARTURES:
        data[type] = {
          loading: contexts.capacityDeparturesContext?.loading,
          error: contexts.capacityDeparturesContext?.error,
          data: contexts.capacityDeparturesContext?.departures,
        };
        break;
      case DATA_TYPES.FLOW:
        data[type] = {
          loading: contexts.flowContext?.loading,
          error: contexts.flowContext?.error,
          data: contexts.flowContext?.flow,
        };
        break;
      case DATA_TYPES.HISTORICAL_DEPARTURES:
        data[type] = {
          loading: contexts.historicalDeparturesContext?.loading,
          error: contexts.historicalDeparturesContext?.error,
          data: contexts.historicalDeparturesContext?.historicalDepartures,
        };
        break;
      case DATA_TYPES.PASSENGERS:
        data[type] = {
          loading: contexts.passengersContext?.loading,
          error: contexts.passengersContext?.error,
          data: contexts.passengersContext?.passengers,
        };
        break;
      case DATA_TYPES.PROJECTION:
        data[type] = {
          loading: contexts.projectionContext?.loading,
          error: contexts.projectionContext?.error,
          data: contexts.projectionContext?.projections,
        };
        break;
      case DATA_TYPES.SALES:
        data[type] = {
          loading: contexts.salesContext?.loading,
          error: contexts.salesContext?.error,
          data: contexts.salesContext?.sales,
        };
        break;
      case DATA_TYPES.WAIT_TIMES:
        data[type] = {
          loading: contexts.waitTimesContext?.loading,
          error: contexts.waitTimesContext?.error,
          data: contexts.waitTimesContext?.waitTimes,
        };
        break;
      case DATA_TYPES.WEATHER:
        data[type] = {
          loading: contexts.weatherContext?.loading,
          error: contexts.weatherContext?.error,
          data: contexts.weatherContext?.weather,
        };
        break;
      case DATA_TYPES.COMMON_USE_WORKSTATIONS:
        data[type] = {
          loading: contexts.commonUseWorkstationsContext?.loading,
          error: contexts.commonUseWorkstationsContext?.error,
          data: contexts.commonUseWorkstationsContext?.workstations,
        };
        break;
      case DATA_TYPES.COMMON_USE_KIOSKS:
        data[type] = {
          loading: contexts.commonUseKiosksContext?.loading,
          error: contexts.commonUseKiosksContext?.error,
          data: contexts.commonUseKiosksContext?.kiosks,
        };
        break;
      default:
        break;
    }
  });

  return data;
}

export function getProcessorType(type: string): string | undefined {
  switch (type) {
    case CHART_TYPES.ARRIVALS.PASSENGER:
    case STAT_TYPES.ARRIVALS.PASSENGER.AVERAGE:
    case STAT_TYPES.ARRIVALS.PASSENGER.HIGHEST:
    case DETAILS_TYPES.ARRIVALS.PASSENGER:
      return PROCESSOR_TYPES.ARRIVALS.PASSENGER;
    case CHART_TYPES.ARRIVALS.CARGO:
    case STAT_TYPES.ARRIVALS.CARGO.AVERAGE:
    case STAT_TYPES.ARRIVALS.CARGO.HIGHEST:
    case DETAILS_TYPES.ARRIVALS.CARGO:
      return PROCESSOR_TYPES.ARRIVALS.CARGO;
    case CHART_TYPES.FLIGHT_PERFORMANCE:
      return PROCESSOR_TYPES.FLIGHT_PERFORMANCE;
    case CHART_TYPES.CARGO_REVENUE:
    case STAT_TYPES.CARGO_REVENUE.AVERAGE:
    case STAT_TYPES.CARGO_REVENUE.HIGHEST:
    case STAT_TYPES.CARGO_REVENUE.LOWEST:
    case DETAILS_TYPES.CARGO_REVENUE:
      return PROCESSOR_TYPES.CARGO_REVENUE;
    case CHART_TYPES.DAILY_THROUGHPUT.DAILY:
    case STAT_TYPES.DAILY_THROUGHPUT.AVERAGE:
    case STAT_TYPES.DAILY_THROUGHPUT.HIGHEST:
    case STAT_TYPES.DAILY_THROUGHPUT.LOWEST:
      return PROCESSOR_TYPES.DAILY_THROUGHPUT.DAILY;
    case CHART_TYPES.DAILY_THROUGHPUT.ROLLING_AVG:
      return PROCESSOR_TYPES.DAILY_THROUGHPUT.ROLLING_AVG;
    case CHART_TYPES.DAILY_THROUGHPUT.ONE_YEAR_AGO:
      return PROCESSOR_TYPES.DAILY_THROUGHPUT.ONE_YEAR_AGO;
    case CHART_TYPES.DAILY_THROUGHPUT.YEAR_2019:
      return PROCESSOR_TYPES.DAILY_THROUGHPUT.YEAR_2019;
    case CHART_TYPES.TSA_COUNTS.CURRENT:
    case STAT_TYPES.TSA_COUNTS.AVERAGE:
    case STAT_TYPES.TSA_COUNTS.HIGHEST:
    case STAT_TYPES.TSA_COUNTS.LOWEST:
      return PROCESSOR_TYPES.TSA_COUNTS.CURRENT;
    case CHART_TYPES.TSA_COUNTS.YEAR_2019:
      return PROCESSOR_TYPES.TSA_COUNTS.YEAR_2019;
    case CHART_TYPES.DEPARTURES.CARGO:
    case STAT_TYPES.DEPARTURES.CARGO.AVERAGE:
    case STAT_TYPES.DEPARTURES.CARGO.HIGHEST:
    case DETAILS_TYPES.DEPARTURES.CARGO:
      return PROCESSOR_TYPES.DEPARTURES.CARGO;
    case CHART_TYPES.DEPARTURES.PASSENGER:
    case STAT_TYPES.DEPARTURES.PASSENGER.AVERAGE:
    case STAT_TYPES.DEPARTURES.PASSENGER.HIGHEST:
    case DETAILS_TYPES.DEPARTURES.PASSENGER:
      return PROCESSOR_TYPES.DEPARTURES.PASSENGER;
    case CHART_TYPES.PASSENGERS.AGE:
      return PROCESSOR_TYPES.PASSENGERS.AGE;
    case CHART_TYPES.PASSENGERS.GENDER:
      return PROCESSOR_TYPES.PASSENGERS.GENDER;
    case CHART_TYPES.PASSENGERS.STATUS:
      return PROCESSOR_TYPES.PASSENGERS.STATUS;
    case CHART_TYPES.DAILY_PROJECTION:
      return PROCESSOR_TYPES.DAILY_PROJECTION;
    case CHART_TYPES.PROJECTION.AM:
    case STAT_TYPES.PROJECTION.AM.AVERAGE:
    case STAT_TYPES.PROJECTION.AM.HIGHEST:
    case STAT_TYPES.PROJECTION.AM.LOWEST:
      return PROCESSOR_TYPES.PROJECTION.AM;
    case CHART_TYPES.PROJECTION.PM:
    case STAT_TYPES.PROJECTION.PM.AVERAGE:
    case STAT_TYPES.PROJECTION.PM.HIGHEST:
    case STAT_TYPES.PROJECTION.PM.LOWEST:
      return PROCESSOR_TYPES.PROJECTION.PM;
    case CHART_TYPES.SALES.TOTAL:
    case STAT_TYPES.SALES.TOTAL.AVERAGE:
    case STAT_TYPES.SALES.TOTAL.HIGHEST:
    case STAT_TYPES.SALES.TOTAL.LOWEST:
      return PROCESSOR_TYPES.SALES.TOTAL;
    case CHART_TYPES.SALES.CONCOURSE_A:
    case STAT_TYPES.SALES.CONCOURSE_A.AVERAGE:
    case STAT_TYPES.SALES.CONCOURSE_A.HIGHEST:
    case STAT_TYPES.SALES.CONCOURSE_A.LOWEST:
      return PROCESSOR_TYPES.SALES.CONCOURSE_A;
    case CHART_TYPES.SALES.CONCOURSE_B:
    case STAT_TYPES.SALES.CONCOURSE_B.AVERAGE:
    case STAT_TYPES.SALES.CONCOURSE_B.HIGHEST:
    case STAT_TYPES.SALES.CONCOURSE_B.LOWEST:
      return PROCESSOR_TYPES.SALES.CONCOURSE_B;
    case CHART_TYPES.SALES.MAIN_TERMINAL:
    case STAT_TYPES.SALES.MAIN_TERMINAL.AVERAGE:
    case STAT_TYPES.SALES.MAIN_TERMINAL.HIGHEST:
    case STAT_TYPES.SALES.MAIN_TERMINAL.LOWEST:
      return PROCESSOR_TYPES.SALES.MAIN_TERMINAL;
    case CHART_TYPES.SALES.SHORT_TERM:
    case STAT_TYPES.SALES.SHORT_TERM.AVERAGE:
    case STAT_TYPES.SALES.SHORT_TERM.HIGHEST:
    case STAT_TYPES.SALES.SHORT_TERM.LOWEST:
      return PROCESSOR_TYPES.SALES.SHORT_TERM;
    case CHART_TYPES.SALES.LONG_TERM:
    case STAT_TYPES.SALES.LONG_TERM.AVERAGE:
    case STAT_TYPES.SALES.LONG_TERM.HIGHEST:
    case STAT_TYPES.SALES.LONG_TERM.LOWEST:
      return PROCESSOR_TYPES.SALES.LONG_TERM;
    case CHART_TYPES.SALES.ECONO_LOT:
    case STAT_TYPES.SALES.ECONO_LOT.AVERAGE:
    case STAT_TYPES.SALES.ECONO_LOT.HIGHEST:
    case STAT_TYPES.SALES.ECONO_LOT.LOWEST:
      return PROCESSOR_TYPES.SALES.ECONO_LOT;
    case CHART_TYPES.SALES.VALET:
    case STAT_TYPES.SALES.VALET.AVERAGE:
    case STAT_TYPES.SALES.VALET.HIGHEST:
    case STAT_TYPES.SALES.VALET.LOWEST:
      return PROCESSOR_TYPES.SALES.VALET;
    case CHART_TYPES.WAIT_TIMES.WAIT_TIMES1:
    case STAT_TYPES.WAIT_TIMES.WAIT_TIMES1.AVERAGE:
    case STAT_TYPES.WAIT_TIMES.WAIT_TIMES1.HIGHEST:
      return PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES1;
    case CHART_TYPES.WAIT_TIMES.WAIT_TIMES2:
    case STAT_TYPES.WAIT_TIMES.WAIT_TIMES2.AVERAGE:
    case STAT_TYPES.WAIT_TIMES.WAIT_TIMES2.HIGHEST:
      return PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES2;
    case CHART_TYPES.WAIT_TIMES.WAIT_TIMES3:
    case STAT_TYPES.WAIT_TIMES.WAIT_TIMES3.AVERAGE:
    case STAT_TYPES.WAIT_TIMES.WAIT_TIMES3.HIGHEST:
      return PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES3;
    case CHART_TYPES.WAIT_TIMES.WAIT_TIMES4:
    case STAT_TYPES.WAIT_TIMES.WAIT_TIMES4.AVERAGE:
    case STAT_TYPES.WAIT_TIMES.WAIT_TIMES4.HIGHEST:
      return PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES4;
    case CHART_TYPES.WAIT_TIMES.WAIT_TIMES5:
    case STAT_TYPES.WAIT_TIMES.WAIT_TIMES5.AVERAGE:
    case STAT_TYPES.WAIT_TIMES.WAIT_TIMES5.HIGHEST:
      return PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES5;
    case CHART_TYPES.BOOKINGS:
    case STAT_TYPES.BOOKINGS.AVERAGE:
    case STAT_TYPES.BOOKINGS.HIGHEST:
    case STAT_TYPES.BOOKINGS.LOWEST:
      return PROCESSOR_TYPES.BOOKINGS;
    case CHART_TYPES.BAGGAGE.B_BAGS:
    case STAT_TYPES.BAGGAGE.B_BAGS.AVERAGE:
    case STAT_TYPES.BAGGAGE.B_BAGS.HIGHEST:
    case STAT_TYPES.BAGGAGE.B_BAGS.LOWEST:
      return PROCESSOR_TYPES.BAGGAGE.B_BAGS;
    case CHART_TYPES.BAGGAGE.T_DRIVE:
    case STAT_TYPES.BAGGAGE.T_DRIVE.AVERAGE:
    case STAT_TYPES.BAGGAGE.T_DRIVE.HIGHEST:
    case STAT_TYPES.BAGGAGE.T_DRIVE.LOWEST:
      return PROCESSOR_TYPES.BAGGAGE.T_DRIVE;
    case CHART_TYPES.BAGGAGE.BAGS_RECEIVED:
    case STAT_TYPES.BAGGAGE.BAGS_RECEIVED.HIGHEST:
    case STAT_TYPES.BAGGAGE.BAGS_RECEIVED.LOWEST:
    case STAT_TYPES.BAGGAGE.BAGS_RECEIVED.AVERAGE:
      return PROCESSOR_TYPES.BAGGAGE.BAGS_RECEIVED;
    case CHART_TYPES.BAGGAGE.BAGS_CLEARED:
    case STAT_TYPES.BAGGAGE.BAGS_CLEARED.HIGHEST:
    case STAT_TYPES.BAGGAGE.BAGS_CLEARED.LOWEST:
    case STAT_TYPES.BAGGAGE.BAGS_CLEARED.AVERAGE:
      return PROCESSOR_TYPES.BAGGAGE.BAGS_CLEARED;
    case CHART_TYPES.BAGGAGE.ML1_READ_RATE:
    case STAT_TYPES.BAGGAGE.ML1_READ_RATE.HIGHEST:
    case STAT_TYPES.BAGGAGE.ML1_READ_RATE.LOWEST:
    case STAT_TYPES.BAGGAGE.ML1_READ_RATE.AVERAGE:
      return PROCESSOR_TYPES.BAGGAGE.ML1_READ_RATE;
    case CHART_TYPES.BAGGAGE.ML2_READ_RATE:
    case STAT_TYPES.BAGGAGE.ML2_READ_RATE.HIGHEST:
    case STAT_TYPES.BAGGAGE.ML2_READ_RATE.LOWEST:
    case STAT_TYPES.BAGGAGE.ML2_READ_RATE.AVERAGE:
      return PROCESSOR_TYPES.BAGGAGE.ML2_READ_RATE;
    case CHART_TYPES.BAGGAGE.CB1_READ_RATE:
    case STAT_TYPES.BAGGAGE.CB1_READ_RATE.HIGHEST:
    case STAT_TYPES.BAGGAGE.CB1_READ_RATE.LOWEST:
    case STAT_TYPES.BAGGAGE.CB1_READ_RATE.AVERAGE:
      return PROCESSOR_TYPES.BAGGAGE.CB1_READ_RATE;
    case CHART_TYPES.BAGGAGE.TOTAL:
    case STAT_TYPES.BAGGAGE.TOTAL.AVERAGE:
    case STAT_TYPES.BAGGAGE.TOTAL.HIGHEST:
    case STAT_TYPES.BAGGAGE.TOTAL.LOWEST:
      return PROCESSOR_TYPES.BAGGAGE.TOTAL;
    case CHART_TYPES.GATES.GATES1:
      return PROCESSOR_TYPES.GATES.GATES1;
    case CHART_TYPES.GATES.GATES2:
      return PROCESSOR_TYPES.GATES.GATES2;
    case CHART_TYPES.GATES.GATES3:
      return PROCESSOR_TYPES.GATES.GATES3;
    case CHART_TYPES.GATES.GATES4:
      return PROCESSOR_TYPES.GATES.GATES4;
    case CHART_TYPES.COMMON_USE.WORKSTATIONS:
    case STAT_TYPES.COMMON_USE.WORKSTATIONS.AVERAGE:
    case STAT_TYPES.COMMON_USE.WORKSTATIONS.HIGHEST:
    case STAT_TYPES.COMMON_USE.WORKSTATIONS.LOWEST:
    case DETAILS_TYPES.COMMON_USE.WORKSTATIONS:
      return PROCESSOR_TYPES.COMMON_USE.WORKSTATIONS;
    case CHART_TYPES.COMMON_USE_KIOSKS.BAG_TAGS:
      return PROCESSOR_TYPES.COMMON_USE_KIOSKS.BAG_TAGS;
    case CHART_TYPES.COMMON_USE_KIOSKS.BOARDING_PASSES:
      return PROCESSOR_TYPES.COMMON_USE_KIOSKS.BOARDING_PASSES;
    case CHART_TYPES.COMMON_USE.BAG_TAGS:
    case STAT_TYPES.COMMON_USE.BAG_TAGS.AVERAGE:
    case STAT_TYPES.COMMON_USE.BAG_TAGS.HIGHEST:
    case STAT_TYPES.COMMON_USE.BAG_TAGS.LOWEST:
    case STAT_TYPES.COMMON_USE.BAG_TAGS.TOTAL:
    case DETAILS_TYPES.COMMON_USE.BAG_TAGS:
      return PROCESSOR_TYPES.COMMON_USE.BAG_TAGS;
    default:
      return;
  }
}

export function getProcessorTypes(types: string[]): string[] {
  return types.map(type => getProcessorType(type)).filter(type => Boolean(type)) as string[];
}

export async function getProcessedData(
  types: string[],
  data: { [key: string]: DataObject },
  state?: State
): Promise<{ [key: string]: ProcessedDataObject }> {
  const {
    dataStartDate,
    dataEndDate,
    destinationAirport,
    airline,
    activeFilters,
    dateRangeDays,
    weather,
    selectedGates,
  } = state || initialState;
  const processedData: { [key: string]: ProcessedDataObject } = {};

  for (const type of types) {
    switch (type) {
      case PROCESSOR_TYPES.ARRIVALS.CARGO:
        processedData[type] = processors.arrivals.cargo.getProcessedData(
          dataStartDate,
          dataEndDate,
          data[DATA_TYPES.ARRIVALS],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          destinationAirport,
          airline,
          weather
        );
        break;
      case PROCESSOR_TYPES.ARRIVALS.PASSENGER:
        processedData[type] = processors.arrivals.passenger.getProcessedData(
          dataStartDate,
          dataEndDate,
          data[DATA_TYPES.ARRIVALS],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          destinationAirport,
          airline,
          weather
        );
        break;
      case PROCESSOR_TYPES.BAGGAGE.B_BAGS:
        processedData[type] = processors.baggage.bBags.getProcessedData(
          data[DATA_TYPES.BAGGAGE],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.BAGGAGE.T_DRIVE:
        processedData[type] = processors.baggage.tDrive.getProcessedData(
          data[DATA_TYPES.BAGGAGE],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.BAGGAGE.BAGS_RECEIVED:
        processedData[type] = processors.baggage.bagsReceived.getProcessedData(
          data[DATA_TYPES.BAGGAGE],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.BAGGAGE.BAGS_CLEARED:
        processedData[type] = processors.baggage.bagsCleared.getProcessedData(
          data[DATA_TYPES.BAGGAGE],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.BAGGAGE.ML1_READ_RATE:
        processedData[type] = processors.baggage.readRateML1.getProcessedData(
          data[DATA_TYPES.BAGGAGE],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.BAGGAGE.ML2_READ_RATE:
        processedData[type] = processors.baggage.readRateML2.getProcessedData(
          data[DATA_TYPES.BAGGAGE],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.BAGGAGE.CB1_READ_RATE:
        processedData[type] = processors.baggage.readRateCB1.getProcessedData(
          data[DATA_TYPES.BAGGAGE],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.BAGGAGE.TOTAL:
        processedData[type] = processors.baggage.total.getProcessedData(
          data[DATA_TYPES.BAGGAGE],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.BOOKINGS:
        processedData[type] = processors.bookings.getProcessedData(
          dataStartDate,
          dataEndDate,
          data[DATA_TYPES.BOOKINGS],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.FLIGHT_PERFORMANCE:
        processedData[type] = await processors.flightPerformance.getProcessedData(
          dataStartDate,
          dataEndDate,
          data[DATA_TYPES.DEPARTURES],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          destinationAirport,
          airline,
          weather
        );
        break;
      case PROCESSOR_TYPES.CARGO_REVENUE:
        processedData[type] = processors.cargoRevenue.getProcessedData(
          dataStartDate,
          dataEndDate,
          data[DATA_TYPES.ARRIVALS],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          destinationAirport,
          airline,
          weather
        );
        break;
      case PROCESSOR_TYPES.DAILY_THROUGHPUT.DAILY:
        processedData[type] = processors.dailyThroughputs.daily.getProcessedData(
          data[DATA_TYPES.DAILY_THROUGHPUTS],
          dateRangeDays,
          activeFilters
        );
        break;
      case PROCESSOR_TYPES.DAILY_THROUGHPUT.ROLLING_AVG:
        processedData[type] = processors.dailyThroughputs.rollingAvg.getProcessedData(
          data[DATA_TYPES.DAILY_THROUGHPUTS],
          dateRangeDays,
          activeFilters
        );
        break;
      case PROCESSOR_TYPES.DAILY_THROUGHPUT.ONE_YEAR_AGO:
        processedData[type] = processors.dailyThroughputs.oneYearAgo.getProcessedData(
          data[DATA_TYPES.DAILY_THROUGHPUTS],
          dateRangeDays,
          activeFilters
        );
        break;
      case PROCESSOR_TYPES.DAILY_THROUGHPUT.YEAR_2019:
        processedData[type] = processors.dailyThroughputs.year2019.getProcessedData(
          data[DATA_TYPES.DAILY_THROUGHPUTS],
          dateRangeDays,
          activeFilters
        );
        break;
      case PROCESSOR_TYPES.TSA_COUNTS.CURRENT:
        processedData[type] = processors.tsaCounts.current.getProcessedData(
          data[DATA_TYPES.TSA_COUNTS],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.TSA_COUNTS.YEAR_2019:
        processedData[type] = processors.tsaCounts.year2019.getProcessedData(
          data[DATA_TYPES.TSA_COUNTS],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.DEPARTURES.CARGO:
        processedData[type] = processors.departures.cargo.getProcessedData(
          dataStartDate,
          dataEndDate,
          data[DATA_TYPES.DEPARTURES],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          destinationAirport,
          airline,
          weather
        );
        break;
      case PROCESSOR_TYPES.DEPARTURES.PASSENGER:
        processedData[type] = processors.departures.passenger.getProcessedData(
          dataStartDate,
          dataEndDate,
          data[DATA_TYPES.DEPARTURES],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          destinationAirport,
          airline,
          weather
        );
        break;
      case PROCESSOR_TYPES.PASSENGERS.AGE:
        processedData[type] = processors.passengers.age.getProcessedData(
          data[DATA_TYPES.PASSENGERS],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.PASSENGERS.GENDER:
        processedData[type] = processors.passengers.gender.getProcessedData(
          data[DATA_TYPES.PASSENGERS],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.PASSENGERS.STATUS:
        processedData[type] = processors.passengers.status.getProcessedData(
          data[DATA_TYPES.PASSENGERS],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.DAILY_PROJECTION:
        processedData[type] = await processors.dailyProjection.getProcessedData(
          data[DATA_TYPES.PROJECTION]
        );
        break;
      case PROCESSOR_TYPES.PROJECTION.AM:
        processedData[type] = await processors.projection.am.getProcessedData(
          data[DATA_TYPES.PROJECTION],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.PROJECTION.PM:
        processedData[type] = await processors.projection.pm.getProcessedData(
          data[DATA_TYPES.PROJECTION],
          dateRangeDays
        );
        break;
      case PROCESSOR_TYPES.SALES.CONCOURSE_A:
        processedData[type] = processors.sales.concourseA.getProcessedData(
          data[DATA_TYPES.SALES],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          weather
        );
        break;
      case PROCESSOR_TYPES.SALES.CONCOURSE_B:
        processedData[type] = processors.sales.concourseB.getProcessedData(
          data[DATA_TYPES.SALES],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          weather
        );
        break;
      case PROCESSOR_TYPES.SALES.MAIN_TERMINAL:
        processedData[type] = processors.sales.mainTerminal.getProcessedData(
          data[DATA_TYPES.SALES],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          weather
        );
        break;
      case PROCESSOR_TYPES.SALES.SHORT_TERM:
        processedData[type] = processors.sales.shortTerm.getProcessedData(
          data[DATA_TYPES.SALES],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          weather
        );
        break;
      case PROCESSOR_TYPES.SALES.LONG_TERM:
        processedData[type] = processors.sales.longTerm.getProcessedData(
          data[DATA_TYPES.SALES],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          weather
        );
        break;
      case PROCESSOR_TYPES.SALES.ECONO_LOT:
        processedData[type] = processors.sales.econoLot.getProcessedData(
          data[DATA_TYPES.SALES],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          weather
        );
        break;
      case PROCESSOR_TYPES.SALES.VALET:
        processedData[type] = processors.sales.valet.getProcessedData(
          data[DATA_TYPES.SALES],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          weather
        );
        break;
      case PROCESSOR_TYPES.SALES.TOTAL:
        processedData[type] = processors.sales.total.getProcessedData(
          data[DATA_TYPES.SALES],
          data[DATA_TYPES.WEATHER],
          dateRangeDays,
          weather
        );
        break;
      case PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES1:
      case PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES2:
      case PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES3:
      case PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES4:
      case PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES5:
        processedData[type] = processors.waitTimes.getProcessedData(
          data[DATA_TYPES.WAIT_TIMES],
          dateRangeDays,
          type
        );
        break;
      case PROCESSOR_TYPES.GATES.GATES1:
      case PROCESSOR_TYPES.GATES.GATES2:
      case PROCESSOR_TYPES.GATES.GATES3:
      case PROCESSOR_TYPES.GATES.GATES4:
        processedData[type] = processors.gates.getProcessedData(
          data[DATA_TYPES.FLOW],
          selectedGates,
          type
        );
        break;
      case PROCESSOR_TYPES.COMMON_USE.WORKSTATIONS:
        processedData[type] = processors.commonUse.workstations.getProcessedData(
          data[DATA_TYPES.COMMON_USE_WORKSTATIONS],
          dateRangeDays,
          activeFilters,
          airline
        );
        break;
      case PROCESSOR_TYPES.COMMON_USE_KIOSKS.BAG_TAGS:
        processedData[type] = processors.commonUseKiosks.bagTags.getProcessedData(
          data[DATA_TYPES.COMMON_USE_KIOSKS],
          dateRangeDays,
          airline
        );
        break;
      case PROCESSOR_TYPES.COMMON_USE_KIOSKS.BOARDING_PASSES:
        processedData[type] = processors.commonUseKiosks.boardingPasses.getProcessedData(
          data[DATA_TYPES.COMMON_USE_KIOSKS],
          dateRangeDays,
          airline
        );
        break;
      case PROCESSOR_TYPES.COMMON_USE.BAG_TAGS:
        processedData[type] = processors.commonUse.bagTags.getProcessedData(
          data[DATA_TYPES.COMMON_USE_WORKSTATIONS],
          data[DATA_TYPES.COMMON_USE_KIOSKS],
          dateRangeDays,
          activeFilters,
          airline
        );
        break;
      default:
        break;
    }
  }

  return processedData;
}

export function getProcessedDataForType(
  type: string,
  processedData: { [key: string]: ProcessedDataObject }
): ProcessedDataObject | undefined {
  const processorType = getProcessorType(type);
  if (processorType) return processedData[processorType];
}

export function getBusiestHourForScans(counts: number[], data: { [key: number]: any }): string {
  const highestCount = Math.max(...counts);
  const index = counts.indexOf(highestCount);
  const time = Number(Object.keys(data)[index]);
  if (!time) return 'N/A';
  const hour = format(new Date(time), 'h:mm a');

  return hour;
}
