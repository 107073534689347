import { default as amount } from './amount';
import { default as baggage } from './baggage';
import { default as bagTags } from './bagTags';
import { default as bookings } from './bookings';
import { default as capacity } from './capacity';
import { default as currentScans } from './currentScans';
import { default as dailyThroughputs } from './dailyThroughputs';
import { default as duration } from './duration';
import { default as flights } from './flights';
import { default as passengers } from './passengers';
import { default as readRate } from './readRate';
import { default as waitTimes } from './waitTimes';

export default {
  amount,
  baggage,
  bagTags,
  bookings,
  capacity,
  currentScans,
  dailyThroughputs,
  duration,
  flights,
  passengers,
  readRate,
  waitTimes,
};
