import React from 'react';

import { Airline } from 'client/Airlines/types';
import { Airport } from 'client/Airport/types';

import { AirlineIcon, Tooltip } from 'components';

import { Box } from 'styled';

interface CityProps {
  statusColor?: string | null;
  airport: Airport;
  airline: Airline;
}

const City: React.FC<CityProps> = ({ statusColor, airport, airline }: CityProps) => {
  if (!airport.city) {
    return (
      <Box alignItems="center" display="flex" color={`${statusColor}`}>
        <AirlineIcon airline={airline} />
        {airport.code}
      </Box>
    );
  }

  return (
    <Box alignItems="center" display="flex" color={`${statusColor}`}>
      <AirlineIcon airline={airline} />
      <Tooltip label={`${airport.code} (${airport.city}, ${airport.state})`}>
        {airport.city}
      </Tooltip>
    </Box>
  );
};

export default City;
