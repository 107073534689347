import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';

import CHART_COLORS, { ChartColorsKey } from 'config/chartColors';
import { PARKING } from 'config/parking';

import { getDefaultSeriesOptions, getVisibility } from 'statistics/charts/utils';
import { ChartDatum, SalesArea } from 'statistics/types';

export default function getSeries(
  data: ChartDatum[],
  area: SalesArea,
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType {
  switch (area) {
    case 'Concourse A':
    case 'Concourse B':
    case 'Main Terminal':
    case 'Short Term':
    case 'Long Term':
    case 'Econo Lot':
    case 'Valet':
      return merge(getDefaultSeriesOptions(), {
        name: PARKING[area] || area,
        yAxis: 'amount',
        borderWidth: 0,
        data,
        color: CHART_COLORS[`Sales - ${area}` as ChartColorsKey],
        stacking: 'normal',
        stack: 'sales',
        ...chartOptions,
        type: 'column',
        visible: getVisibility(PARKING[area] || area),
      });
    case 'Total':
    default:
      return merge(getDefaultSeriesOptions(), {
        visible: false,
        yAxis: 'amount',
        showInLegend: false,
        stacking: 'normal',
        stack: 'sales',
        ...chartOptions,
        type: 'column',
      } as SeriesOptionsType);
  }
}
