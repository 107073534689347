import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const SelectedTableItemProviderStateContext = createContext<any>(null);
const SelectedTableItemProviderDispatchContext = createContext<any>(null);

interface Props {
  children: JSX.Element;
}

function SelectedTableItemProvider({ children }: Props): JSX.Element {
  const [state, setState] = useState({});
  return (
    <SelectedTableItemProviderStateContext.Provider value={state}>
      <SelectedTableItemProviderDispatchContext.Provider value={setState}>
        {children}
      </SelectedTableItemProviderDispatchContext.Provider>
    </SelectedTableItemProviderStateContext.Provider>
  );
}

// const state = useProviderState();
function useSelectedTableItemState() {
  const context = useContext(SelectedTableItemProviderStateContext);
  if (context === undefined) {
    throw new Error(`useSelectedTableItemState must be used within a SelectedTableItemProvider`);
  }
  return context;
}

// const setState = useSelectedTableItemDispatch();
function useSelectedTableItemDispatch(skip?: boolean) {
  const context = useContext(SelectedTableItemProviderDispatchContext);
  if (skip) return;
  if (context === undefined) {
    throw new Error(`useSelectedTableItemDispatch must be used within a SelectedTableItemProvider`);
  }
  return context;
}

// const [state, setState] = useSelectedTableItem();
function useSelectedTableItem() {
  const context = [useSelectedTableItemState(), useSelectedTableItemDispatch()];
  if (context === undefined) {
    throw new Error(`useSelectedTableItem must be used within a SelectedTableItemProvider`);
  }
  return context;
}

function usePopupWatcher(itemId?: string): {
  ref: any;
} {
  const selectedItem = useSelectedTableItemState();
  const setSelectedItem = useSelectedTableItemDispatch();
  const [selected, setSelected] = useState(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    if (!selectedItem?.id || !itemId) return;

    if (!selectedItem) {
      setSelected(false);
      setSelectedItem(null);
      ref?.current?.leafletElement?.closePopup();
      return;
    }

    if (selectedItem?.id === itemId) {
      setSelected(true);
    }

    return (): void => {
      setSelected(false);
      setSelectedItem(null);
    };
  }, [selectedItem, setSelectedItem, itemId]);

  useEffect(() => {
    if (selected) ref?.current?.leafletElement?.openPopup();
  }, [selected]);

  return { ref };
}

export {
  SelectedTableItemProvider as default,
  useSelectedTableItem,
  useSelectedTableItemState,
  useSelectedTableItemDispatch,
  usePopupWatcher,
};
