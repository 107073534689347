import React from 'react';

import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { Checkbox, FormField, Select } from 'components';

const CustomerFeedbackOptions: React.FC = () => {
  const [state, dispatch] = useTileOptions();
  const {
    customerFeedback: { filters },
  } = state;

  function onFilterChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const type = event.target.name;
    const checked = filters?.selectedSentiment?.includes(type);
    let newTypes: string[] = [];

    if (checked) {
      newTypes = filters?.selectedSentiment?.filter((t: string) => t !== type);
    } else {
      newTypes = [...filters.selectedSentiment, type];
    }

    dispatch(
      updateOptions({
        customerFeedback: {
          filters: {
            ...filters,
            selectedSentiment: newTypes,
          },
        },
      })
    );
  }

  function handleAttachmentChange(): void {
    dispatch(
      updateOptions({
        customerFeedback: {
          filters: {
            ...filters,
            isAttachmentShown: !filters.isAttachmentShown,
          },
        },
      })
    );
  }

  return (
    <>
      <FormField label="Tweets to show" type="stacked" mb="base">
        {['Positive', 'Negative', 'Neutral'].map((type: string, i: number) => (
          <Checkbox
            key={`${type}-${i}`}
            label={type}
            name={type}
            checked={filters?.selectedSentiment?.includes(type)}
            onChange={onFilterChange}
            mb="s"
          />
        ))}
      </FormField>
      <FormField label="Attachments in tile" type="stacked" mb="base">
        <Select
          type="radio"
          name="limit"
          value={filters.isAttachmentShown.toString()}
          options={[
            { value: 'true', label: `Show attachments` },
            { value: 'false', label: `Hide attachments` },
          ]}
          onChange={handleAttachmentChange}
        />
      </FormField>
    </>
  );
};

export default CustomerFeedbackOptions;
