import { parseISO } from 'date-fns';
import max from 'lodash/max';
import min from 'lodash/min';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import upperCase from 'lodash/upperCase';

import { PARKING } from 'config/parking';

import { Sale } from 'client/Sale/types';

import { filterByDayRange, filterByWeatherConditions } from 'statistics/charts/utils';
import { STAT_TYPES } from 'statistics/stats/constants';
import { DataObject, SalesArea, SalesAreaKey, SalesChartDatum, StatsData } from 'statistics/types';

import { ProcessedDataObject } from './utils';

function getFilteredData(sales: Sale[], area: SalesArea): Sale[] {
  switch (area) {
    case 'Concourse A':
    case 'Concourse B':
    case 'Main Terminal':
    case 'Short Term':
    case 'Long Term':
    case 'Econo Lot':
    case 'Valet':
      return sales.filter((sale: Sale) => sale.description === area);
    case 'Total':
      return sales;
    default:
      return [];
  }
}

function getChartData(sales: Sale[]): SalesChartDatum[] {
  const salesData = sales.map(
    (sale: Sale): SalesChartDatum => ({
      x: parseISO(sale.date).getTime(),
      y: sale.amount,
      name: sale.description as SalesArea,
    })
  );
  return sortBy(salesData, 'x');
}

function getStatsData(sales: Sale[], area: SalesArea): StatsData {
  const totals: { [key: string]: number } = {};
  sales.forEach(sale => {
    if (!(sale.date in totals)) {
      totals[sale.date] = 0;
    }

    totals[sale.date] += sale.amount;
  });

  const values = Object.values(totals);

  const lowest = min(values) || 0;
  const highest = max(values) || 0;
  const average = values.length ? sum(values) / values.length : 0;

  const highestContext = Object.keys(totals).find(key => totals[key] === highest);
  const lowestContext = Object.keys(totals).find(key => totals[key] === lowest);

  const key: SalesAreaKey = upperCase(area).split(' ').join('_') as SalesAreaKey;

  return {
    [STAT_TYPES.SALES[key].AVERAGE]: {
      value: average,
      contextLabel: `All ${PARKING[area] || area} sales`,
    },
    [STAT_TYPES.SALES[key].HIGHEST]: {
      value: highest,
      contextLabel:
        highestContext !== undefined ? PARKING[highestContext] || highestContext : undefined,
    },
    [STAT_TYPES.SALES[key].LOWEST]: {
      value: lowest,
      contextLabel:
        lowestContext !== undefined ? PARKING[lowestContext] || lowestContext : undefined,
    },
  };
}

function getProcessedData(
  salesData: DataObject,
  weatherData: DataObject,
  dateRangeDays: string[],
  weather: string[],
  area: SalesArea
): ProcessedDataObject {
  const { data: sales, loading, error } = salesData;
  const { loading: weatherLoading, error: weatherError, data: _weatherData } = weatherData;
  const hasWeatherDataAndLoading = weather && weather.length > 0 && weatherLoading;
  const hasWeatherDataAndError = weather && weather.length > 0 && weatherError;

  if (loading || error || hasWeatherDataAndLoading || hasWeatherDataAndError)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
    };

  const { byDayRange } = filterByDayRange(dateRangeDays);
  const { byWeatherConditions } = filterByWeatherConditions(_weatherData, weather);
  const filteredData = getFilteredData(sales as Sale[], area);
  const filteredDataByDayRange = filteredData.filter(
    (datum: Sale) => byDayRange(parseISO(datum.date)) && byWeatherConditions(parseISO(datum.date))
  );

  return {
    chart: {
      loading,
      error,
      data: getChartData(filteredDataByDayRange),
    },
    stats: {
      loading,
      error,
      data: getStatsData(filteredDataByDayRange, area),
    },
  };
}

export default {
  concourseA: {
    getProcessedData: (
      salesData: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      weather: string[]
    ): ProcessedDataObject =>
      getProcessedData(salesData, weatherData, dateRangeDays, weather, 'Concourse A'),
  },
  concourseB: {
    getProcessedData: (
      salesData: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      weather: string[]
    ): ProcessedDataObject =>
      getProcessedData(salesData, weatherData, dateRangeDays, weather, 'Concourse B'),
  },
  mainTerminal: {
    getProcessedData: (
      salesData: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      weather: string[]
    ): ProcessedDataObject =>
      getProcessedData(salesData, weatherData, dateRangeDays, weather, 'Main Terminal'),
  },
  shortTerm: {
    getProcessedData: (
      salesData: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      weather: string[]
    ): ProcessedDataObject =>
      getProcessedData(salesData, weatherData, dateRangeDays, weather, 'Short Term'),
  },
  longTerm: {
    getProcessedData: (
      salesData: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      weather: string[]
    ): ProcessedDataObject =>
      getProcessedData(salesData, weatherData, dateRangeDays, weather, 'Long Term'),
  },
  econoLot: {
    getProcessedData: (
      salesData: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      weather: string[]
    ): ProcessedDataObject =>
      getProcessedData(salesData, weatherData, dateRangeDays, weather, 'Econo Lot'),
  },
  valet: {
    getProcessedData: (
      salesData: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      weather: string[]
    ): ProcessedDataObject =>
      getProcessedData(salesData, weatherData, dateRangeDays, weather, 'Valet'),
  },
  total: {
    getProcessedData: (
      salesData: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      weather: string[]
    ): ProcessedDataObject =>
      getProcessedData(salesData, weatherData, dateRangeDays, weather, 'Total'),
  },
};
