import { StatItem, StatsArgs } from 'statistics/types';

import { STAT_TYPES } from './constants';

function getAverageStatItem() {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Average Wait Time',
    suffix: ' min',
    value: value as number,
    contextLabel,
  });
}

function getHighestStatItem() {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Highest Wait Time',
    suffix: ' min',
    value: value as number,
    contextLabel,
  });
}

export default {
  [STAT_TYPES.WAIT_TIMES.WAIT_TIMES1.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.WAIT_TIMES.WAIT_TIMES1.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.WAIT_TIMES.WAIT_TIMES2.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.WAIT_TIMES.WAIT_TIMES2.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.WAIT_TIMES.WAIT_TIMES3.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.WAIT_TIMES.WAIT_TIMES3.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.WAIT_TIMES.WAIT_TIMES4.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.WAIT_TIMES.WAIT_TIMES4.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.WAIT_TIMES.WAIT_TIMES5.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.WAIT_TIMES.WAIT_TIMES5.HIGHEST]: getHighestStatItem(),
};
