import { CANCELED, DELAYED, ON_TIME } from 'data/Flights/constants';
import { DepartureFlight } from 'data/Flights/types';

import { useTileOptionsState } from 'providers/TileOptionsProvider';

interface Args {
  key: 'arrivals' | 'departures';
  setFlightDate: (flight: DepartureFlight) => Date;
}

function useTileDepartureFilter(args: Args) {
  const optionsState = useTileOptionsState();
  const filters = optionsState?.[args.key]?.filters;

  const tileFilter = ({ flight }: { flight: DepartureFlight }): boolean => {
    // Filter out invalid flight status
    const onTime = flight.statusText === ON_TIME && filters.status.includes('ONTIME');
    const canceled = flight.statusText === CANCELED && filters.status.includes('CANCELED');
    const delayed = flight.statusText === DELAYED && filters.status.includes('DELAYED');
    const isValidStatus = onTime || canceled || delayed;

    // Filter out invalid flight dates
    const date = new Date();
    const flightDate = args.setFlightDate(flight);
    const isValidFlightDate = flightDate >= date;

    // Filter out invalid flight types (cargo, passenger, etc)
    const isValidCargoType = filters?.types?.includes('cargo') && flight?.isPassenger === false;
    const isValidPassengerType = filters?.types?.includes('passenger') && flight?.departureGate;
    const isValidFlightType = isValidCargoType || isValidPassengerType;

    // Filter out Carrier types
    const isValidDhl =
      filters?.carrierType?.includes('dhl') && flight?.cargoCarrier?.toLowerCase() === 'dhl';
    const isValidAmazon =
      filters?.carrierType?.includes('amazon') && flight?.cargoCarrier?.toLowerCase() === 'amazon';
    const isValidNonDhl =
      filters?.carrierType?.includes('nondhl') &&
      flight?.cargoCarrier?.toLowerCase() !== 'dhl' &&
      flight?.cargoCarrier?.toLowerCase() !== 'amazon';

    const isValidCarrierType = isValidAmazon || isValidDhl || isValidNonDhl;

    if (isValidCargoType) {
      return !!(isValidStatus && isValidFlightDate && isValidFlightType && isValidCarrierType);
    } else {
      return !!(isValidStatus && isValidFlightDate && isValidFlightType);
    }
  };

  return { tileFilter };
}

export default useTileDepartureFilter;
