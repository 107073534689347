import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import React from 'react';

import { useAirlineCapacities } from 'client/Airlines';
import { AirlineCapacity } from 'client/Airlines/types';

import { useRefreshedAt } from 'hooks';

import { AirlineIcon, BlankSlate, CapacityStatus, Table, UpdatedAt } from 'components';
import { Props as IconProps } from 'components/Icon';
import { DefaultErrorMessageWithError, DefaultLoader } from 'components/QueryCell';
import { Heading } from 'components/Table';

import { Box, TextLabel } from 'styled';

export interface TableData {
  airline: React.ReactNode;
  load: React.ReactNode;
  status: React.ReactNode;
  bookings: React.ReactNode;
  onTime?: React.ReactNode;
  delayed?: React.ReactNode;
  canceled?: React.ReactNode;
  total?: React.ReactNode;
}

export interface Data {
  airline: React.ReactNode;
  load: React.ReactNode;
  status: React.ReactNode;
  bookings: React.ReactNode;
  onTime: React.ReactNode;
  delayed: React.ReactNode;
  canceled: React.ReactNode;
  total: React.ReactNode;
}

interface Props {
  filterBlankSlateIcon?: IconProps;
  filterEmptyMessage?: string;
  grid?: string;
  headings?: Heading[];
  sortByColumn?: string;
  isReverseSort?: boolean;
  renderExternalData?: Function;
  renderExternalDataTotal?: Function;
}

const CapacitySummary: React.FC<Props> = ({
  filterBlankSlateIcon,
  filterEmptyMessage,
  grid,
  headings,
  isReverseSort,
  sortByColumn,
  renderExternalData,
  renderExternalDataTotal,
}: Props) => {
  const airlineCapacitiesData = useAirlineCapacities();
  const airlinesCapacities = airlineCapacitiesData?.data?.airlineCapacities;
  const filteredAirlinesCapacities = airlinesCapacities?.filter(
    (airline: AirlineCapacity) => airline?.isMarketing
  );
  const { refreshedAt } = useRefreshedAt(airlineCapacitiesData?.loading);
  if (process.env.REACT_APP_CONFIG_BOOKINGS !== 'true') return null;

  function renderData(): TableData[] {
    if (!airlinesCapacities) return [];
    let loadTotal = 0;

    // Map and sort capacity summary data
    const mappedAirlineCapacities = filteredAirlinesCapacities?.map(
      (capacity: AirlineCapacity) => ({
        ...capacity,
        load: capacity?.capacity
          ? Math.ceil((capacity?.bookings / capacity?.capacity) * 10000) / 100
          : 0,
      })
    );
    let sortedAirlinesCapacities = sortBy(mappedAirlineCapacities, sortByColumn || 'name');
    if (isReverseSort) sortedAirlinesCapacities = sortedAirlinesCapacities.reverse();

    const data = sortedAirlinesCapacities?.map((airline: AirlineCapacity): TableData => {
      loadTotal += airline?.load || 0;
      const d: Data = {
        airline: (
          <Box lineHeight="1">
            <AirlineIcon airline={airline} />
            <Box as="span" fontSize="s">
              {airline.name}
            </Box>
          </Box>
        ),
        status:
          airline?.capacity > 0 ? (
            <CapacityStatus
              occupancy={airline?.bookings || 0}
              scans={airline?.scans || 0}
              capacity={airline?.capacity || 0}
              load={airline?.load || 0}
              booking={airline?.bookings || 0}
            />
          ) : (
            <TextLabel color="subdued">N/A</TextLabel>
          ),
        load:
          airline?.load && airline?.load > 0 ? (
            <Box fontSize="s">{`${airline?.load}%`}</Box>
          ) : (
            <TextLabel color="subdued">N/A</TextLabel>
          ),
        canceled: <Box fontSize="s">{`${airline?.canceled}`}</Box>,
        delayed: <Box fontSize="s">{`${airline?.delayed}`}</Box>,
        total: <Box fontSize="s">{`${airline?.total}`}</Box>,
        onTime: <Box fontSize="s">{`${airline?.onTime}`}</Box>,
        bookings: airline?.bookings || 0,
      };

      if (renderExternalData)
        return renderExternalData({
          data: d,
        });
      return d;
    });

    const getTotal = (key: string): number => {
      if (!sortedAirlinesCapacities?.length) return 0;
      const capacities = sortedAirlinesCapacities?.map(
        (ac: AirlineCapacity) => ac?.[key as keyof AirlineCapacity]
      );
      return sum(capacities);
    };

    const airlinesCount = sortedAirlinesCapacities?.filter(
      (ac: AirlineCapacity) => !!ac?.capacity
    )?.length;

    const dataTotal: Data = {
      airline: <TextLabel color="primary">Total</TextLabel>,
      status: (
        <CapacityStatus
          occupancy={getTotal('bookings')}
          scans={getTotal('scans')}
          capacity={getTotal('capacity')}
          load={0}
          booking={getTotal('bookings')}
        />
      ),
      load: airlinesCount ? (
        <Box color="primary" fontWeight="bold">
          {`${Math.round(((loadTotal / airlinesCount) * 100) / 100)}%`}
        </Box>
      ) : (
        <TextLabel color="subdued">N/A</TextLabel>
      ),
      onTime: <TextLabel>{getTotal('onTime')}</TextLabel>,
      delayed: <TextLabel>{getTotal('delayed')}</TextLabel>,
      canceled: <TextLabel>{getTotal('canceled')}</TextLabel>,
      bookings: <TextLabel>{getTotal('bookings')}</TextLabel>,
      total: <TextLabel>{getTotal('total')}</TextLabel>,
    };

    if (renderExternalDataTotal) {
      return [...data, renderExternalDataTotal({ data: dataTotal })];
    }

    return [...data, dataTotal];
  }

  const defaults = {
    grid: '3fr 1.5fr 1fr',
    headings: [
      { key: 'airline', label: 'Airline' },
      { key: 'status', label: 'Status' },
      { key: 'load', label: 'Load' },
    ],
    filterBlankSlateIcon: { name: 'magnifyingGlass', color: 'warning', size: '30' },
    filterEmptyMessage: 'No capacity summary data matching parameters.',
  };

  if (airlineCapacitiesData?.loading) return <DefaultLoader />;

  if (airlineCapacitiesData?.error)
    return (
      <DefaultErrorMessageWithError
        error={airlineCapacitiesData?.error}
        action="useAirlineCapacities()"
      />
    );

  if (!airlinesCapacities?.length) {
    return (
      <Box display="grid" height="100%" gridTemplateRows="1fr auto">
        <BlankSlate
          display="flex"
          flexDirection="column"
          title="No capacity summary"
          justifyContent="center"
          icon={filterBlankSlateIcon || defaults.filterBlankSlateIcon}
        >
          {filterEmptyMessage || defaults.filterEmptyMessage}
        </BlankSlate>
        <UpdatedAt date={refreshedAt} />
      </Box>
    );
  }

  return (
    <>
      <Table
        name="capacitySummary"
        headings={headings || defaults.headings}
        data={renderData()}
        bordered={true}
        cellPaddingX="s"
        cellPaddingY="s"
        dataFontSize="s"
        grid={grid || defaults.grid}
        gridGap="xs"
        striped={false}
      />
      <UpdatedAt date={refreshedAt} loading={airlineCapacitiesData?.loading} />
    </>
  );
};

export default CapacitySummary;
