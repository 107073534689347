import React from 'react';

import { Circle } from 'styled';

interface Props {
  status: 'open' | 'closed';
}

const Status: React.FC<Props> = ({ status }: Props) => {
  if (status === 'closed') {
    return <Circle bg="error" size="14px" />;
  }

  return <Circle bg="primary" size="14px" />;
};

export default Status;
