export const STAT_TYPES = {
  ARRIVALS: {
    PASSENGER: {
      AVERAGE: 'ARRIVALS.PASSENGER.AVERAGE',
      HIGHEST: 'ARRIVALS.PASSENGER.HIGHEST',
    },
    CARGO: {
      AVERAGE: 'ARRIVALS.CARGO.AVERAGE',
      HIGHEST: 'ARRIVALS.CARGO.HIGHEST',
    },
  },
  BAGGAGE: {
    B_BAGS: {
      AVERAGE: 'BAGGAGE.B_BAGS.AVERAGE',
      HIGHEST: 'BAGGAGE.B_BAGS.HIGHEST',
      LOWEST: 'BAGGAGE.B_BAGS.LOWEST',
    },
    T_DRIVE: {
      AVERAGE: 'BAGGAGE.T_DRIVE.AVERAGE',
      HIGHEST: 'BAGGAGE.T_DRIVE.HIGHEST',
      LOWEST: 'BAGGAGE.T_DRIVE.LOWEST',
    },
    TOTAL: {
      AVERAGE: 'BAGGAGE.TOTAL.AVERAGE',
      HIGHEST: 'BAGGAGE.TOTAL.HIGHEST',
      LOWEST: 'BAGGAGE.TOTAL.LOWEST',
    },
    BAGS_RECEIVED: {
      AVERAGE: 'BAGGAGE.BAGS_RECEIVED.AVERAGE',
      HIGHEST: 'BAGGAGE.BAGS_RECEIVED.HIGHEST',
      LOWEST: 'BAGGAGE.BAGS_RECEIVED.LOWEST',
    },
    BAGS_CLEARED: {
      AVERAGE: 'BAGGAGE.BAGS_CLEARED.AVERAGE',
      HIGHEST: 'BAGGAGE.BAGS_CLEARED.HIGHEST',
      LOWEST: 'BAGGAGE.BAGS_CLEARED.LOWEST',
    },
    ML1_READ_RATE: {
      AVERAGE: 'BAGGAGE.ML1_READ_RATE.AVERAGE',
      HIGHEST: 'BAGGAGE.ML1_READ_RATE.HIGHEST',
      LOWEST: 'BAGGAGE.ML1_READ_RATE.LOWEST',
    },
    ML2_READ_RATE: {
      AVERAGE: 'BAGGAGE.ML2_READ_RATE.AVERAGE',
      HIGHEST: 'BAGGAGE.ML2_READ_RATE.HIGHEST',
      LOWEST: 'BAGGAGE.ML2_READ_RATE.LOWEST',
    },
    CB1_READ_RATE: {
      AVERAGE: 'BAGGAGE.CB1_READ_RATE.AVERAGE',
      HIGHEST: 'BAGGAGE.CB1_READ_RATE.HIGHEST',
      LOWEST: 'BAGGAGE.CB1_READ_RATE.LOWEST',
    },
  },
  BOOKINGS: {
    AVERAGE: 'BOOKINGS.AVERAGE',
    HIGHEST: 'BOOKINGS.HIGHEST',
    LOWEST: 'BOOKINGS.LOWEST',
  },
  CARGO_REVENUE: {
    AVERAGE: 'CARGO_REVENUE.AVERAGE',
    HIGHEST: 'CARGO_REVENUE.HIGHEST',
    LOWEST: 'CARGO_REVENUE.LOWEST',
  },
  COMMON_USE: {
    WORKSTATIONS: {
      AVERAGE: 'COMMON_USE.WORKSTATIONS.AVERAGE',
      HIGHEST: 'COMMON_USE.WORKSTATIONS.HIGHEST',
      LOWEST: 'COMMON_USE.WORKSTATIONS.LOWEST',
    },
    BAG_TAGS: {
      AVERAGE: 'COMMON_USE.BAG_TAGS.AVERAGE',
      HIGHEST: 'COMMON_USE.BAG_TAGS.HIGHEST',
      LOWEST: 'COMMON_USE.BAG_TAGS.LOWEST',
      TOTAL: 'COMMON_USE.BAG_TAGS.TOTAL',
    },
  },
  DAILY_THROUGHPUT: {
    AVERAGE: 'DAILY_THROUGHPUT.AVERAGE',
    HIGHEST: 'DAILY_THROUGHPUT.HIGHEST',
    LOWEST: 'DAILY_THROUGHPUT.LOWEST',
  },
  DEPARTURES: {
    PASSENGER: {
      AVERAGE: 'DEPARTURES.PASSENGER.AVERAGE',
      HIGHEST: 'DEPARTURES.PASSENGER.HIGHEST',
    },
    CARGO: {
      AVERAGE: 'DEPARTURES.CARGO.AVERAGE',
      HIGHEST: 'DEPARTURES.CARGO.HIGHEST',
    },
  },
  LOAD: {
    AVERAGE: 'LOAD.AVERAGE',
  },
  PROJECTION: {
    AM: {
      AVERAGE: 'PROJECTION.AM.AVERAGE',
      HIGHEST: 'PROJECTION.AM.HIGHEST',
      LOWEST: 'PROJECTION.AM.LOWEST',
    },
    PM: {
      AVERAGE: 'PROJECTION.PM.AVERAGE',
      HIGHEST: 'PROJECTION.PM.HIGHEST',
      LOWEST: 'PROJECTION.PM.LOWEST',
    },
  },
  SALES: {
    TOTAL: {
      AVERAGE: 'SALES.TOTAL.AVERAGE',
      HIGHEST: 'SALES.TOTAL.HIGHEST',
      LOWEST: 'SALES.TOTAL.LOWEST',
    },
    CONCOURSE_A: {
      AVERAGE: 'SALES.CONCOURSE_A.AVERAGE',
      HIGHEST: 'SALES.CONCOURSE_A.HIGHEST',
      LOWEST: 'SALES.CONCOURSE_A.LOWEST',
    },
    CONCOURSE_B: {
      AVERAGE: 'SALES.CONCOURSE_B.AVERAGE',
      HIGHEST: 'SALES.CONCOURSE_B.HIGHEST',
      LOWEST: 'SALES.CONCOURSE_B.LOWEST',
    },
    MAIN_TERMINAL: {
      AVERAGE: 'SALES.MAIN_TERMINAL.AVERAGE',
      HIGHEST: 'SALES.MAIN_TERMINAL.HIGHEST',
      LOWEST: 'SALES.MAIN_TERMINAL.LOWEST',
    },
    SHORT_TERM: {
      AVERAGE: 'SALES.SHORT_TERM.AVERAGE',
      HIGHEST: 'SALES.SHORT_TERM.HIGHEST',
      LOWEST: 'SALES.SHORT_TERM.LOWEST',
    },
    LONG_TERM: {
      AVERAGE: 'SALES.LONG_TERM.AVERAGE',
      HIGHEST: 'SALES.LONG_TERM.HIGHEST',
      LOWEST: 'SALES.LONG_TERM.LOWEST',
    },
    ECONO_LOT: {
      AVERAGE: 'SALES.ECONO_LOT.AVERAGE',
      HIGHEST: 'SALES.ECONO_LOT.HIGHEST',
      LOWEST: 'SALES.ECONO_LOT.LOWEST',
    },
    VALET: {
      AVERAGE: 'SALES.VALET.AVERAGE',
      HIGHEST: 'SALES.VALET.HIGHEST',
      LOWEST: 'SALES.VALET.LOWEST',
    },
  },
  TSA_COUNTS: {
    AVERAGE: 'TSA_COUNTS.AVERAGE',
    HIGHEST: 'TSA_COUNTS.HIGHEST',
    LOWEST: 'TSA_COUNTS.LOWEST',
  },
  WAIT_TIMES: {
    WAIT_TIMES1: {
      AVERAGE: 'WAIT_TIMES.WAIT_TIMES1.AVERAGE',
      HIGHEST: 'WAIT_TIMES.WAIT_TIMES1.HIGHEST',
    },
    WAIT_TIMES2: {
      AVERAGE: 'WAIT_TIMES.WAIT_TIMES2.AVERAGE',
      HIGHEST: 'WAIT_TIMES.WAIT_TIMES2.HIGHEST',
    },
    WAIT_TIMES3: {
      AVERAGE: 'WAIT_TIMES.WAIT_TIMES3.AVERAGE',
      HIGHEST: 'WAIT_TIMES.WAIT_TIMES3.HIGHEST',
    },
    WAIT_TIMES4: {
      AVERAGE: 'WAIT_TIMES.WAIT_TIMES4.AVERAGE',
      HIGHEST: 'WAIT_TIMES.WAIT_TIMES4.HIGHEST',
    },
    WAIT_TIMES5: {
      AVERAGE: 'WAIT_TIMES.WAIT_TIMES5.AVERAGE',
      HIGHEST: 'WAIT_TIMES.WAIT_TIMES5.HIGHEST',
    },
  },
};
