import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const TextLabel = styled.b<SystemProps>`
  display: block;
  font-size: ${themeGet('fontSizes.xs')};
  font-weight: ${themeGet('fontWeights.bold')};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;

  ${system}
`;

export default TextLabel;
