import { SeriesOptionsType } from 'highcharts';

import { DeparturesChartAirline } from 'statistics/types';

import { getXAxis, getYAxis } from './axes';
import { default as getOptions } from './options';
import { default as getSeries } from './series';

export default {
  getXAxis,
  getYAxis,
  getOptions,
  getSeries: (
    data: DeparturesChartAirline[],
    chartOptions?: SeriesOptionsType,
    key?: string | undefined,
    index?: number
  ): SeriesOptionsType => getSeries(data, chartOptions, key as string, index as number),
};
