import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { User } from 'client/User/types';

import { useForm } from 'hooks';
import { track } from 'utils';
import { ReturnArgs as FormReturnArgs } from 'hooks/useForm';

import { showSuccess, useToastDispatch } from 'providers/ToastProvider';

import { Button, FormField, Select, TextInput, UserSearch } from 'components';

import { Box } from 'styled';

export interface Data {
  title?: string;
  description?: string;
  severity?: string;
  assignee?: string;
}

interface Props {
  data?: Data;
  onSubmit: Function;
  type: 'create' | 'edit';
}

const IssueForm: React.FC<Props> = ({ data, onSubmit, type }: Props) => {
  const toastDispatch = useToastDispatch();
  const history = useHistory();
  const { values, setValues, handleChange, handleSubmit }: FormReturnArgs = useForm(async () => {
    const action = type === 'create' ? 'created' : 'updated';
    onSubmit?.(values);
    history.push('/issues');
    toastDispatch(showSuccess(`Issue ${action}`));
    track('Create Issue');
  });

  useEffect(() => {
    if (data) setValues((values: object) => ({ ...values, ...data }));
  }, [data, setValues]);

  const submitFn = (event: React.FormEvent): Function => handleSubmit(event);
  const changeFn = (event: React.ChangeEvent<HTMLInputElement>): Function => handleChange(event);

  function getButtonText(): string {
    if (type === 'create') return 'Create Issue';
    if (type === 'edit') return 'Update Issue';
    return 'Submit';
  }

  return (
    <Box as="form" id={`${type}-issue`} onSubmit={submitFn}>
      <FormField label="Title" mb="l">
        <TextInput
          name="title"
          id="title"
          onChange={changeFn}
          value={values.title || ''}
          required
          autoFocus
        />
      </FormField>
      <FormField label="Description" labelVerticalAlign="top" mb="l">
        <TextInput
          as="textarea"
          name="description"
          id="description"
          onChange={changeFn}
          value={values.description || ''}
          rows="10"
        />
      </FormField>
      <FormField label="Severity" alignItems="flex-start" mb="l">
        <Select
          type="radio"
          name="severity"
          options={[
            { value: 'severe', label: 'Severe' },
            { value: 'moderate', label: 'Moderate' },
            { value: 'light', label: 'Light' },
          ]}
          onChange={changeFn}
          value={values.severity || ''}
          required
        />
      </FormField>
      <FormField label="Assignee" mb="l">
        <UserSearch
          name="assigneeName"
          id="assignee"
          value={values.assigneeName || ''}
          onChange={changeFn}
          onSelect={(selection: User): void =>
            setValues((_values: object) => ({
              ..._values,
              assignee: selection.email,
              assigneeName: selection.name,
            }))
          }
          onClear={(): void =>
            setValues((_values: object) => ({ ..._values, assignee: '', assigneeName: '' }))
          }
        />
      </FormField>
      <Button type="submit">{getButtonText()}</Button>
    </Box>
  );
};

export default IssueForm;
