import { Airline } from 'client/Airlines/types';
import { Airport } from 'client/Airport/types';

export type GlobalDataType = Airline[] | Airport[];

const data: { [key: string]: GlobalDataType } = {
  airports: [] as Airport[],
  airlines: [] as Airline[],
};

export function setGlobalData(key: string, obj: GlobalDataType) {
  data[key] = obj;
}

export function getGlobalData(key: string) {
  return data[key];
}
