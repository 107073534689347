export const DATA_TYPES = {
  ARRIVALS: 'ARRIVALS',
  BAGGAGE: 'BAGGAGE',
  BOOKINGS: 'BOOKINGS',
  CAPACITY_DEPARTURES: 'CAPACITY_DEPARTURES',
  DAILY_THROUGHPUTS: 'DAILY_THROUGHPUTS',
  DEPARTURES: 'DEPARTURES',
  FLOW: 'FLOW',
  HISTORICAL_DEPARTURES: 'HISTORICAL_DEPARTURES',
  PASSENGERS: 'PASSENGERS',
  PROJECTION: 'PROJECTION',
  SALES: 'SALES',
  WAIT_TIMES: 'WAIT_TIMES',
  WEATHER: 'WEATHER',
  COMMON_USE_KIOSKS: 'COMMON_USE_KIOSKS',
  COMMON_USE_WORKSTATIONS: 'COMMON_USE_WORKSTATIONS',
  TSA_COUNTS: 'TSA_COUNTS',
};

export const PROCESSOR_TYPES = {
  ARRIVALS: {
    PASSENGER: 'ARRIVALS.PASSENGER',
    CARGO: 'ARRIVALS.CARGO',
  },
  BAGGAGE: {
    TOTAL: 'BAGGAGE.TOTAL',
    B_BAGS: 'BAGGAGE.B_BAGS',
    T_DRIVE: 'BAGGAGE.T_DRIVE',
    BAGS_RECEIVED: 'BAGGAGE.BAGS_RECEIVED',
    BAGS_CLEARED: 'BAGGAGE.BAGS_CLEARED',
    ML1_READ_RATE: 'BAGGAGE.ML1_READ_RATE',
    ML2_READ_RATE: 'BAGGAGE.ML2_READ_RATE',
    CB1_READ_RATE: 'BAGGAGE.CB1_READ_RATE',
  },
  BOOKINGS: 'BOOKINGS',
  CARGO_REVENUE: 'CARGO_REVENUE',
  COMMON_USE: {
    WORKSTATIONS: 'COMMON_USE.WORKSTATIONS',
    BAG_TAGS: 'COMMON_USE.BAG_TAGS',
  },
  COMMON_USE_KIOSKS: {
    BAG_TAGS: 'COMMON_USE_KIOSKS.BAG_TAGS',
    BOARDING_PASSES: 'COMMON_USE_KIOSKS.BOARDING_PASSES',
  },
  DAILY_THROUGHPUT: {
    DAILY: 'DAILY_THROUGHPUT.DAILY',
    ROLLING_AVG: 'DAILY_THROUGHPUT.ROLLING_AVG',
    ONE_YEAR_AGO: 'DAILY_THROUGHPUT.ONE_YEAR_AGO',
    YEAR_2019: 'DAILY_THROUGHPUT.YEAR_2019',
  },
  DAILY_PROJECTION: 'DAILY_PROJECTION',
  DEPARTURES: {
    PASSENGER: 'DEPARTURES.PASSENGER',
    CARGO: 'DEPARTURES.CARGO',
  },
  FLIGHT_PERFORMANCE: 'FLIGHT_PERFORMANCE',
  GATES: {
    GATES1: 'GATES1',
    GATES2: 'GATES2',
    GATES3: 'GATES3',
    GATES4: 'GATES4',
  },
  PASSENGERS: {
    AGE: 'PASSENGERS.AGE',
    GENDER: 'PASSENGERS.GENDER',
    STATUS: 'PASSENGERS.STATUS',
  },
  PROJECTION: {
    AM: 'PROJECTION.AM',
    PM: 'PROJECTION.PM',
  },
  SALES: {
    TOTAL: 'SALES.TOTAL',
    CONCOURSE_A: 'SALES.CONCOURSE_A',
    CONCOURSE_B: 'SALES.CONCOURSE_B',
    MAIN_TERMINAL: 'SALES.MAIN_TERMINAL',
    VALET: 'SALES.VALET',
    SHORT_TERM: 'SALES.SHORT_TERM',
    LONG_TERM: 'SALES.LONG_TERM',
    ECONO_LOT: 'SALES.ECONO_LOT',
  },
  TSA_COUNTS: {
    CURRENT: 'TSA_COUNTS.CURRENT',
    YEAR_2019: 'TSA_COUNTS.YEAR_2019',
  },
  WAIT_TIMES: {
    WAIT_TIMES1: 'WAIT_TIMES.1',
    WAIT_TIMES2: 'WAIT_TIMES.2',
    WAIT_TIMES3: 'WAIT_TIMES.3',
    WAIT_TIMES4: 'WAIT_TIMES.4',
    WAIT_TIMES5: 'WAIT_TIMES.5',
  },
  WEATHER: 'WEATHER',
};

export const DETAILS_TYPES = {
  ARRIVALS: {
    PASSENGER: 'ARRIVALS.PASSENGER',
    CARGO: 'ARRIVALS.CARGO',
  },
  BOOKINGS: 'BOOKINGS',
  CARGO_REVENUE: 'CARGO_REVENUE',
  COMMON_USE: {
    WORKSTATIONS: 'COMMON_USE.WORKSTATIONS',
    KIOSKS: 'COMMON_USE.KIOSKS',
    BAG_TAGS: 'COMMON_USE.BAG_TAGS',
  },
  DEPARTURES: {
    PASSENGER: 'DEPARTURES.PASSENGER',
    CARGO: 'DEPARTURES.CARGO',
  },
};
