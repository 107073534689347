import sortBy from 'lodash/sortBy';
import React from 'react';

import { getAirportCity } from 'config/airports';

import { CargoArrivalsData } from 'utils/arrivals';

import { useArrivalFlights } from 'data/Flights';

import { useRefreshedAt } from 'hooks';

import { QueryCell, UpdatedAt } from 'components';
import { Props as IconProps } from 'components/Icon';
import { Heading } from 'components/Table';

import { Box } from 'styled';

import ArrivalFlights from './ArrivalFlights';

export interface Props {
  filter?: Function;
  filterEmptyMessage?: string;
  filterBlankSlateIcon?: IconProps;
  sortByColumn?: string;
  isReverseSort?: boolean;
  grid?: string;
  headings?: Heading[];
  renderData?: Function;
  limit?: number;
}

const Arrivals: React.FC<Props> = (props: Props) => {
  const { arrivals, loading, error } = useArrivalFlights();
  const cargoData = new CargoArrivalsData({ flights: arrivals });
  const { refreshedAt } = useRefreshedAt(loading);
  const passengerArrivals = arrivals.filter(
    arr => cargoData?.flights.findIndex(cf => cf.id === arr.id) === -1
  );

  const allFlights = [...passengerArrivals, ...cargoData?.flights].map(flight => {
    const airportCode = flight?.origin;
    const airportCity = getAirportCity(airportCode);

    return {
      ...flight,
      city: airportCity,
      flightNum: parseInt(flight?.flightNumber),
      time: flight?.estimatedArrivalDate || flight?.scheduledArrivalDate,
    };
  });

  const sortedFlights = sortBy(allFlights, props.sortByColumn || '');

  let allSortedFlights;
  if (props?.isReverseSort) {
    allSortedFlights = sortedFlights.reverse();
  } else {
    allSortedFlights = sortedFlights;
  }

  return (
    <QueryCell content="arrivals" data={passengerArrivals} error={error} loading={loading}>
      <ArrivalFlights flights={allSortedFlights} headings={props?.headings} {...props} />
      <Box mt="base">
        <UpdatedAt date={refreshedAt} loading={loading} />
      </Box>
    </QueryCell>
  );
};

export default Arrivals;
