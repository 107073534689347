import pick from 'lodash/pick';
import React from 'react';

import { useUpdateUserMetadata } from 'client/User';
import { track } from 'utils';

import { hideModal, useModalDispatch } from 'providers/ModalProvider';
import { updateOnboarding, useOnboardingDispatch } from 'providers/OnboardingProvider';
import { updateSettings, useSettings } from 'providers/SettingsProvider';

import { Button, LinkButton, Modal } from 'components';

import { Box } from 'styled';

const OnboardingModal: React.FC = props => {
  const modalDispatch = useModalDispatch();
  const onboardingDispatch = useOnboardingDispatch();
  const [settingsState, settingsDispatch] = useSettings();
  const [updateMetadata] = useUpdateUserMetadata();
  const video = process.env.REACT_APP_ONBOARDING_LOOM;

  const handleSkip = (event: React.MouseEvent) => {
    event.preventDefault();

    // Stop the joyride tour
    onboardingDispatch(updateOnboarding({ run: false }));

    // Set joyride tour as completed/skipped
    settingsDispatch(updateSettings({ hasSeenTour: true }));
    updateMetadata({
      variables: {
        key: 'settings',
        value: JSON.stringify({
          hasSeenTour: true,
          ...pick(settingsState, ['emailAlerts', 'emailInsights', 'dashboardLayout']),
        }),
      },
    });
    track('Skip Tour');
    modalDispatch(hideModal());
  };

  const handleStartTour = (event: React.MouseEvent) => {
    event.preventDefault();
    onboardingDispatch(updateOnboarding({ run: true }));
    track('Start Tour');
    modalDispatch(hideModal());
  };

  return (
    <Modal title="Welcome to EASE!" {...props}>
      <Box display="flex" flexDirection="column">
        <Box>
          <Box as="span">
            EASE is a data-integration platform for real-time operational responses. Collect key
            data insights, predict customer behavior, and direct confidently. Watch this quick video
            for an overview of the EASE platform from our Product Director, Drew.
          </Box>
        </Box>
        <Box backgroundColor="#2A2B38" my="l" height="330px">
          <iframe
            src={video}
            frameBorder="0"
            width="100%"
            height="100%"
            title="onboarding"
            allowFullScreen={true}
          ></iframe>
        </Box>
        <Box display="flex" flexDirection="column" mb="l">
          <Box as="span" fontSize="l" fontWeight="bold" mb="s">
            Let’s Take a Tour of EASE
          </Box>
          <Box as="span">
            We’ll show you around and help you view the data that’s relevant to you. If right now
            isn’t a good time, you can revisit this tour in your profile menu (in the top right of
            your screen).
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <LinkButton padding="0" onClick={handleSkip}>
            Skip Tour
          </LinkButton>
          <Button onClick={handleStartTour}>Start Tour</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OnboardingModal;
