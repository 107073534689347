import React from 'react';

import airlineLogos from 'config/airlineLogos';

import { Airline, AirlineCapacity } from 'client/Airlines/types';

import { Icon, Tooltip } from 'components';

import { Box, TextLabel } from 'styled';

interface Props {
  airline: Airline | AirlineCapacity | null;
  size?: string;
}

interface DefaultIconProps {
  size?: string;
}

const defaults = {
  size: '16px',
};

const AirlineIcon: React.FC<Props> = ({ airline, size }: Props) => {
  if (!airline) return <DefaultIcon size={size} />;

  const code = airline?.icao || airline?.iata;
  const icon = airlineLogos[code];

  if (!icon) {
    // We want the table sizing to be consistent, so let's just trim
    // the codes with more than 2 digits down to 2 digits max.
    const trimmedCode = code?.substring(0, 2);
    return (
      <Tooltip label={airline.name}>
        <Box display="inline-block" size={size || defaults.size} mr="xs">
          <TextLabel as="span" color="subdued" fontSize="xxs" lineHeight="16px" textAlign="center">
            {trimmedCode}
          </TextLabel>
        </Box>
      </Tooltip>
    );
  }

  return (
    <Tooltip label={airline.name}>
      <Box
        dangerouslySetInnerHTML={{
          __html: icon || '',
        }}
        display="inline-block"
        verticalAlign="middle"
        mr="xs"
        size={size || defaults.size}
      />
    </Tooltip>
  );
};

const DefaultIcon: React.FC<DefaultIconProps> = ({ size }: DefaultIconProps) => (
  <Icon name="arrowUpAndRight" mr="xs" size={size || defaults.size} color="subdued" />
);

export default AirlineIcon;
