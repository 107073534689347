import { formatRelative } from 'date-fns';
import React from 'react';

import { Box } from 'styled';
import { getAirportTime } from 'utils';

interface Props {
  time: Date;
}

const CreatedAt: React.FC<Props> = ({ time }) => {
  const now = getAirportTime(new Date());
  return (
    <Box color="subdued" fontSize="s" fontStyle="italic">
      {formatRelative(getAirportTime(new Date(time)), now)}
    </Box>
  );
};

export default CreatedAt;
