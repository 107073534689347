import styled from 'styled-components';

interface Props {
  height: string;
}

const HideUntilHeight = styled.div<Props>`
  display: block;

  @media screen and (max-height: ${(props: Props): string => props.height}) {
    display: none;
  }
`;

export default HideUntilHeight;
