import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const UNSUBSCRIBE_INSIGHTS = gql`
  mutation UnsubscribeInsights($email: String!) {
    unsubscribeInsights(email: $email)
  }
`;

export default function useUnsubscribeInsights(options: MutationHookOptions = {}) {
  return useMutation(UNSUBSCRIBE_INSIGHTS, options);
}
