import { gql, useQuery } from '@apollo/client';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { WeatherQuery } from './types';

const GET_WEATHER = gql`
  query {
    weather {
      airport {
        city
        name
        id
        latitude
        longitude
        code
      }
      temperature
      weatherSummary
      conditionCode
    }
  }
`;

export default function useWeather({ refresh = true, skip = false }): WeatherQuery {
  const options = {
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_WEATHER, options);

  return {
    weather: query.data?.weather || [],
    ...query,
  };
}
