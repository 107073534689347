import { useState } from 'react';

import useLocalStorage from './useLocalStorage';

export interface ReturnArgs {
  handleChange: Function;
  handleSubmit: Function;
  reset: Function;
  setValues: Function;
  values: any;
}

const useForm = (callback?: Function, lsKey?: string): ReturnArgs => {
  const {
    storedValue: localValues,
    setValue: setLocalValues,
    remove: removeLocalValue,
  } = useLocalStorage(lsKey || 'unknownForm');
  const [values, setValues] = useState(localValues ?? {});

  function handleSubmit(event: React.FormEvent): void {
    event?.preventDefault();
    removeLocalValue();
    callback?.();
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    event?.persist();
    const { name, value } = event.target;
    setValues((values: any) => ({ ...values, [name]: value }));
    setLocalValues({ ...values, [name]: value });
  }

  function reset(): void {
    setValues({});
    setLocalValues({});
  }

  return {
    handleChange,
    handleSubmit,
    values,
    reset,
    setValues,
  };
};

export default useForm;
