import React from 'react';
import { CircleMarker, Polyline } from 'react-leaflet';

import theme from 'config/theme';

import { useTrafficDetails } from 'tiles/Traffic/hooks';
import { Route, TrafficAlert } from 'tiles/Traffic/types';

import { Icon, QueryCell, UpdatedAt } from 'components';

import { Box, Card, TextLabel } from 'styled';

import Table from '../../components/Table';
import { TrafficMap, Incidents } from './components';

const TrafficDetails: React.FC = () => {
  const {
    loading,
    error,
    alerts,
    refreshedAt,
    flow,
    ROADS,
    accidents,
    roadClosures,
    jams,
    badWeather,
    roadWorks,
    unknown,
    getRoadAlertStatusColor,
    getAccidentInformation,
  } = useTrafficDetails();

  const latitude = Number(process.env.REACT_APP_AIRPORT_LATITUDE) as number;
  const longitude = Number(process.env.REACT_APP_AIRPORT_LONGITUDE) as number;

  function renderAccidents(): JSX.Element | null {
    if (accidents.length === 0) return null;

    return (
      <Card
        borderColor="error"
        borderRadius="2px"
        overflow="hidden"
        height="222px"
        width="362px"
        p="0"
        position="absolute"
        zIndex={theme.zIndices.modal + theme.zIndices.leaflet}
        mt="24px"
        ml="36px"
      >
        <Box height="185px" width="362px">
          <TrafficMap center={[accidents[0].location.y, accidents[0].location.x]} zoom={14}>
            {accidents.map((accident: TrafficAlert) => (
              <CircleMarker
                key={accident.id}
                center={[accident.location.y, accident.location.x]}
                stroke={false}
                color={theme.colors.error}
                fillOpacity={1}
                radius={4}
              />
            ))}
          </TrafficMap>
        </Box>
        <Box alignItems="center" py="6px" display="flex" backgroundColor="neutralsGradient.11">
          <Icon name="xCircle" color="error" size="20" mx="s" />
          <Box as="p" flex="1" fontSize="l">
            {getAccidentInformation()}
          </Box>
        </Box>
      </Card>
    );
  }

  function renderTableData(): {
    road: JSX.Element;
    accidents: number;
    roadClosures: number;
    jams: number;
    badWeather: number;
    roadWorks: number;
    unknown: number;
  }[] {
    const getCountOf = (data: TrafficAlert[], road: string): number =>
      data.filter((d: TrafficAlert) => d.street.startsWith(road)).length;

    return ROADS.map((road: string) => ({
      road: (
        <Box alignItems="center" display="flex">
          <Icon
            name="circle"
            size="16"
            color={getRoadAlertStatusColor(road)}
            fill={getRoadAlertStatusColor(road)}
            mr="s"
          />
          {road}
        </Box>
      ),
      accidents: getCountOf(accidents, road),
      roadClosures: getCountOf(roadClosures, road),
      jams: getCountOf(jams, road),
      badWeather: getCountOf(badWeather, road),
      roadWorks: getCountOf(roadWorks, road),
      unknown: getCountOf(unknown, road),
    }));
  }

  return (
    <>
      <Box>
        <QueryCell content="traffic" data={alerts} error={error} loading={loading}>
          <>
            <Box mb="l">{renderAccidents()}</Box>
            <Box height="80vh" position="relative" mt="-24px">
              <TrafficMap center={[latitude, longitude]} zoom={12}>
                {flow?.routes?.map((route: Route, i: number) => (
                  <Polyline
                    key={i}
                    color={route.color}
                    positions={route.latLngLine || []}
                    opacity={0.25}
                  />
                ))}
                <Incidents data={accidents} type="Accident" color="error" />
                <Incidents data={roadClosures} type="Road Closure" color="warning" />
                <Incidents data={jams} type="Traffic Jam" color="warning" />
                <Incidents data={badWeather} type="Bad Weather" color="warning" />
                <Incidents data={roadWorks} type="Road Work" color="warning" />
                <Incidents data={unknown} type="Unknown" color="subdued" />
              </TrafficMap>
            </Box>
            <Box
              height="auto"
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              zIndex={theme.zIndices.menu + theme.zIndices.leaflet}
              backgroundColor="neutralsGradient.11"
              borderTop={`4px solid ${theme.colors.neutralsGradient[9]}`}
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                height="48px"
                zIndex={theme.zIndices.modalOverlay + theme.zIndices.leaflet}
                mt="-40px"
              >
                <UpdatedAt date={refreshedAt} loading={loading} />
              </Box>
              <Table
                bordered={true}
                dataFontSize="s"
                headings={[
                  { key: 'road', label: 'Road' },
                  { key: 'accidents', label: 'Accidents' },
                  { key: 'roadClosures', label: 'Road Closures' },
                  { key: 'jams', label: 'Jams' },
                  { key: 'badWeather', label: 'Bad Weather' },
                  { key: 'roadWorks', label: 'Road Works' },
                  { key: 'unknown', label: 'Unknown' },
                ]}
                data={[
                  ...renderTableData(),
                  {
                    road: <TextLabel color="success">Total</TextLabel>,
                    accidents: accidents.length,
                    roadClosures: roadClosures.length,
                    jams: jams.length,
                    badWeather: badWeather.length,
                    roadWorks: roadWorks.length,
                    unknown: unknown.length,
                  },
                ]}
              />
            </Box>
          </>
        </QueryCell>
      </Box>
    </>
  );
};

export default TrafficDetails;
