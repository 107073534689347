import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import { getGlobalData } from 'utils/globalData';

import { Airline } from 'client/Airlines/types';

export const getAirline = (code: string): Airline | null => {
  const airlines = getGlobalData('airlines');
  const match = find(
    airlines,
    (airline: Airline) =>
      airline?.name?.toLowerCase() === code?.toLowerCase() ||
      airline?.iata === code ||
      airline?.icao === code
  ) as Airline;
  if (!match) return null;
  return match;
};

const SIGNATORY_COST = 2.18;
const NONSIGNATORY_COST = 2.5;

export const getCostMultiplier = (iata: string): number => {
  const airline = getAirline(iata);
  const perTonCost = airline && airline.signatory ? SIGNATORY_COST : NONSIGNATORY_COST;
  return perTonCost / 1000; // per pound cost
};

export const isAirlineMatch = (bpCode: string, flightCode: string): boolean => {
  const bpAirline = getAirline(bpCode);
  const flightAirline = getAirline(flightCode);

  return isEqual(bpAirline, flightAirline);
};
