import { WeatherDetails } from './getWeatherDetails';

import { Delay } from 'client/Delay/types';

export function getDelayStatus(
  delays: Delay[],
  weatherDetails: WeatherDetails
): 'success' | 'warning' | 'error' {
  const hasDelays = delays.length > 0;
  if (hasDelays) return 'error';
  return weatherDetails?.statusColor || 'success';
}

export default getDelayStatus;
