import { gql, useQuery } from '@apollo/client';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { CurrentWaitTimeArgs, CurrentWaitTimesQuery } from './types';

const GET_CURRENT_WAIT_TIMES = gql`
  query GetCurrentWaitTimes {
    currentWaitTimes {
      name
      waitTimes {
        timestamp
        value
      }
    }
  }
`;

export default function useCurrentWaitTimes({
  refresh = true,
  skip = false,
}: CurrentWaitTimeArgs): CurrentWaitTimesQuery {
  const options = {
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_CURRENT_WAIT_TIMES, options);

  return {
    waitTimes: query?.data?.waitTimes || [],
    ...query,
  };
}
