import { gql, MutationHookOptions, useMutation } from '@apollo/client';

interface Options extends MutationHookOptions {
  id?: string;
  sentiment?: string;
}

const UPDATE_FEEDBACK = gql`
  mutation UpdateFeedback($id: ID!, $sentiment: SentimentType!) {
    updateFeedback(id: $id, sentiment: $sentiment) {
      id
      sentiment
    }
  }
`;

export default function useUpdateFeedback(options: Options) {
  return useMutation(UPDATE_FEEDBACK, options);
}
