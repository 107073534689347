// @ts-nocheck
import { DepartureFlight } from 'data/Flights/types';

interface ConstructorArgs {
  flights: DepartureFlight[];
}

class DepartureFlightsData {
  flights: DepartureFlight[];

  constructor({ flights }: ConstructorArgs) {
    this.flights = flights;
  }

  get isArrivals(): boolean {
    return true;
  }

  get isDepartures(): boolean {
    return false;
  }
}

export default DepartureFlightsData;
