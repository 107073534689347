import { format } from 'date-fns';
import uniq from 'lodash/uniq';
import React from 'react';

import { Tooltip } from 'components';
import NotamStatus from 'tiles/Airfield/components/NotamStatus';

import getStatusText from './getStatusText';
import Notam from './notam';

export interface NotamData {
  area: JSX.Element;
  description: string;
  start: string;
  end: string;
  notam: Notam;
}

export const NOTAM_DATE_FORMAT = 'L/dd h:mm a';

export function getData(notams: Notam[]): NotamData[] {
  const getDescription = (notam: Notam): string => {
    if (!notam.number) return notam.translation;
    return `${notam.number} - ${notam.translation}`;
  };

  return notams.map(notam => ({
    area: <Tooltip label={notam.description}>{uniq(notam.codes).join(', ')}</Tooltip>,
    areaName: uniq(notam.codes).join(', '),
    description: getDescription(notam),
    start: format(new Date(notam.start), NOTAM_DATE_FORMAT),
    end: format(new Date(notam.end), NOTAM_DATE_FORMAT),
    status: <NotamStatus notam={notam} />,
    statusName: getStatusText(notam.description),
    // This is so the individual NOTAM can be selected, but this
    // will not show up in the table.
    notam,
  }));
}
