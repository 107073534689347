import React from 'react';

import { getGlobalData } from 'utils/globalData';

import { Airline } from 'client/Airlines/types';

import { updateOptions, useChartOptions } from 'providers/ChartOptionsProvider';

import { AirlineIcon, Icon, Search } from 'components';

import { Box, ResetButton, Stack, Tag } from 'styled';

interface Props {
  placeholder?: string;
  resultsWidth?: string;
  side?: string;
}

interface Event {
  target: {
    value: string;
  };
}

const AirlineSearch: React.FC<Props> = props => {
  const [state, dispatch] = useChartOptions();
  const { activeReport, airlineInputValue } = state;
  const airlines = getGlobalData('airlines') as Airline[];

  function renderSelection(): JSX.Element | null {
    if (state.airline.length === 0) return null;

    return (
      <Stack direction="x" xspace="xs" mt="s">
        {state.airline.map(
          (code: string, idx: number): JSX.Element => (
            <Tag key={idx} display="block" disabled={activeReport}>
              {code}
              <ResetButton
                disabled={activeReport}
                onClick={(event: React.MouseEvent): void => {
                  event.preventDefault();
                  dispatch(
                    updateOptions({ airline: state.airline.filter((c: string) => c !== code) })
                  );
                }}
              >
                <Icon name="x" size="10" color="subdued" ml="xs" />
              </ResetButton>
            </Tag>
          )
        )}
      </Stack>
    );
  }

  return (
    <>
      <Search
        data={airlines}
        inputValue={airlineInputValue}
        matcherKeys={['iata', 'icao', 'name']}
        renderResult={(item: Airline): JSX.Element => (
          <>
            {item.icon && item?.icon !== '' && <AirlineIcon airline={item} />}
            {item.iata || item.icao}
            <Box as="b" display="block" fontSize="s">
              {item.name}
            </Box>
          </>
        )}
        onClear={(): void => {
          dispatch(updateOptions({ airlineInputValue: '', airline: [] }));
        }}
        onChange={(event: Event): void => {
          dispatch(updateOptions({ airlineInputValue: event.target.value }));
        }}
        onSelect={(selection: Airline): void => {
          const code = selection?.iata || selection?.icao;

          //@ts-expect-error
          if (state?.airline?.includes(code)) {
            dispatch(
              updateOptions({
                airlineInputValue: '',
                airline: state.airline.filter((c: string) => c !== code),
              })
            );
          } else {
            dispatch(
              updateOptions({
                airlineInputValue: '',
                airline: [...state.airline, code],
              })
            );
          }
        }}
        disabled={activeReport}
        {...props}
      />
      {renderSelection()}
    </>
  );
};

export default AirlineSearch;
