import sortBy from 'lodash/sortBy';
import React from 'react';

import { CargoDeparturesData } from 'utils/departures';

import { useDepartureFlights } from 'data/Flights';
import { DepartureFlight } from 'data/Flights/types';

import { useRefreshedAt } from 'hooks';

import { QueryCell, UpdatedAt } from 'components';

import Departures from './Departures';

interface Props {
  filters?: { types: string[] };
}

const DepartureSummary: React.FC<Props> = ({ filters }: Props) => {
  const { departures, loading, error } = useDepartureFlights();
  const { refreshedAt } = useRefreshedAt(loading);
  const cargoData = new CargoDeparturesData({ flights: departures });

  const passengerDepartures = departures.filter(
    dep => cargoData?.flights.findIndex(cf => cf.id === dep.id) === -1
  );

  const filter = (flight: DepartureFlight): boolean => {
    const isValidCargoType = filters?.types?.includes('cargo') && flight?.weight;
    const isValidPassengerType =
      filters?.types?.includes('passenger') && (flight?.departureGate || flight?.isPassenger);
    const isValidFlightType = !!(isValidCargoType || isValidPassengerType);
    return isValidFlightType;
  };

  const sortedDepartures = sortBy(
    [...passengerDepartures, ...cargoData?.flights],
    [
      (departure: DepartureFlight): Date =>
        new Date(departure.estimatedDepartureDate || departure.scheduledDepartureDate),
    ]
  );

  return (
    <QueryCell content="departures" data={passengerDepartures} error={error} loading={loading}>
      <Departures flights={sortedDepartures} filter={filter} />
      <UpdatedAt date={refreshedAt} loading={loading} />
    </QueryCell>
  );
};

export default DepartureSummary;
