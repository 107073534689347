import 'leaflet/dist/leaflet.css';

import { formatRelative } from 'date-fns';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { CircleMarker, Popup } from 'react-leaflet';

import theme from 'config/theme';

import { Delay } from 'client/Delay/types';

import { Box } from 'styled';

import { WeatherConditionIcon } from '.';
import { getDelayStatus, getWeatherDetails } from '../utils';
import { Weather } from 'data/Weather/types';

function Dot({
  weatherData,
  delaysData,
}: {
  weatherData: Weather;
  delaysData: Delay[];
}): JSX.Element | null {
  const airportData = weatherData.airport;
  const hasDelays = delaysData?.length > 0;
  const weatherDetails = getWeatherDetails(weatherData);
  const statusColor = getDelayStatus(delaysData, weatherDetails);

  return (
    <CircleMarker
      center={[airportData?.latitude, airportData?.longitude]}
      stroke={false}
      color={theme?.colors[statusColor]}
      fillOpacity={1}
      radius={4}
    >
      <Popup>
        <Box textAlign="center">
          <Box mb="xs">
            <WeatherConditionIcon conditionCode={weatherDetails?.conditionCode} />
          </Box>
          <Box>
            {airportData?.city} ({airportData?.code})
          </Box>
          <Box as="span" color="subduedLight" display="block">
            {isNaN(weatherDetails?.temperature) ? 'N/A' : `${weatherDetails?.temperature}°`}{' '}
            {weatherDetails?.conditions}
          </Box>
          <Box as="span" color="subduedLight" display="block" fontSize="xs">
            {hasDelays ? (
              delaysData.map((delay: Delay) => (
                <Box key={delay?.id} mt="xs">
                  <Box as="span" color="bg" display="block">
                    {capitalize(formatRelative(delay?.createdAt, new Date()))}
                  </Box>
                  <Box as="span">{delay?.type}</Box>
                </Box>
              ))
            ) : (
              <Box mt="xs">No delays</Box>
            )}
          </Box>
        </Box>
      </Popup>
    </CircleMarker>
  );
}

export default Dot;
