import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import styled from 'styled-components';

import { BrowserValidator as BV } from 'utils';

import { Box } from 'styled';

import BlankSlate from './BlankSlate';
import { LogoSVG } from './Logo';

interface Props {
  children: JSX.Element;
}

const BrowserValidator: React.FC<Props> = ({ children }: Props) => {
  const bv = new BV(window.navigator.userAgent);
  const { supported, reason } = bv.validate();

  // If their browser is supported, continue with rendering
  // the rest of the app.
  if (supported) return children;

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      height="100vh"
      maxWidth="550px"
      mx="auto"
    >
      <Box width="100%">
        <Box mb="l" mx="auto" textAlign="center">
          <LogoSVG />
        </Box>
        <BlankSlate title={reason} maxWidth="100%" mb="l">
          We are using the latest in browser technologies for EASE. In order to use the application,
          you need to use the latest version of one of the following browsers:
        </BlankSlate>
        <BrowserLinks>
          {Object.keys(BV.SUPPORTED_BROWSERS).map((browser: string, idx: number) => (
            <Box key={idx} as="li">
              <BrowserLink
                href={BV.BROWSER_LINKS[browser]}
              >{`${browser} (${BV.SUPPORTED_BROWSERS[browser]}+)`}</BrowserLink>
            </Box>
          ))}
        </BrowserLinks>
      </Box>
    </Box>
  );
};

// These are being colocated here with the component because they don't
// make sense abstracted into `src/styled` because they will only be
// used in one place. HERE.
//
// If they later need to be reused, we'll move them out...

const BrowserLinks = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;

  > *:not(:last-child) {
    margin-right: ${themeGet('space.l')};
  }
`;

const BrowserLink = styled.a`
  border-bottom: 1px solid ${themeGet('colors.border')};
  display: block;
  font-weight: ${themeGet('fontWeights.bold')};
  padding-bottom: ${themeGet('space.xs')};
  text-decoration: none;
  transition: 0.3s ease-in-out;

  &:active,
  &:focus,
  &:hover {
    border-color: ${themeGet('colors.primary')};
  }
`;

export default BrowserValidator;
