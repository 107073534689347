import React from 'react';
import { Link } from 'react-router-dom';

import { useAlertsState } from 'providers/AlertsProvider';
import { useSettingsState } from 'providers/SettingsProvider';

import { RequiredPermission } from 'components';

import { Box } from 'styled';

import AlertBadge from './AlertBadge';

type LogoProps = {
  hideAlerts?: boolean;
};

const Logo: React.FC<LogoProps> = ({ hideAlerts = false }: LogoProps) => {
  const { alerts } = useAlertsState();
  const settings = useSettingsState();

  return (
    <Box position="relative">
      <Link to="/">
        <LogoSVG />
      </Link>
      {!settings.dashboardMode && !hideAlerts ? (
        <RequiredPermission permission="read:alerts">
          <AlertBadge count={alerts?.length || 0} />
        </RequiredPermission>
      ) : null}
    </Box>
  );
};

export const LogoSVG: React.FC = () => (
  <svg width="131" height="20" viewBox="0 0 131 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.1878 4.06308L41.8192 0.105135H22.1047L17.6838 4.06308H40.1878Z" fill="white" />
    <path d="M38.5564 8.02103H13.2628L8.8419 11.979H36.925L38.5564 8.02103Z" fill="white" />
    <path d="M35.2936 15.9369H4.42095L0 19.8949H33.6622L35.2936 15.9369Z" fill="white" />
    <path
      d="M128.833 7.94561H117.867V3.64629H129.431V0.380982H113.839V19.5919H128.833L130.086 16.4643L117.867 16.3266V11.2109H128.833V7.94561Z"
      fill="white"
    />
    <path
      d="M91.9004 13.9048C92.499 17.3878 95.1929 20 101.016 20C107.247 20 109.805 17.3333 109.805 14.0952C109.805 11.3469 108.662 9.17007 101.479 7.89116C97.6146 7.21088 96.8255 6.53061 96.8255 5.2517C96.8255 3.78231 98.1861 3.02041 100.581 3.02041C104.036 3.02041 104.717 4.43537 105.043 5.4966H109.07C108.853 3.37415 107.166 0 100.689 0C96.2541 0 92.7167 1.95918 92.7167 5.65986C92.7167 8.2449 93.9956 10.2857 100.39 11.3741C104.717 12.1088 105.669 13.0068 105.669 14.5034C105.669 15.9184 104.608 17.0068 101.37 17.0068C97.9956 17.0068 96.5534 15.7551 95.9548 13.9048H91.9004Z"
      fill="white"
    />
    <path
      d="M83.769 14.7619L85.8098 19.6055H90.1908L81.8914 0.39457H76.803L68.8846 19.6055H73.0479L74.9799 14.7619H83.769ZM76.0683 11.4966C77.6738 7.4694 78.7894 4.42178 79.1976 3.06124H79.2248C79.6874 4.4762 80.6397 6.78913 82.5989 11.4966H76.0683Z"
      fill="white"
    />
    <path
      d="M65.058 7.9592H54.092V3.65988H65.6566V0.394574H50.0648V19.6055H65.058L66.3112 16.4779L54.092 16.3402V11.2245H65.058V7.9592Z"
      fill="white"
    />
  </svg>
);

export default Logo;
