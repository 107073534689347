import { ApolloError } from '@apollo/client';
import { subWeeks } from 'date-fns';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { NUM_WEEKS_HISTORICAL } from 'config/constants';

import { useHistoricalDepartures as useHistoricalDeparturesFlights } from 'data/Flights';
import { HistoricalDeparture } from 'data/Flights/types';

import { useSentry } from 'hooks';

export interface HistoricalDeparturesContext {
  loading: boolean;
  error?: ApolloError;
  historicalDepartures: HistoricalDeparture[];
}

const HistoricalDeparturesProviderContext = createContext<HistoricalDeparturesContext>({
  loading: false,
  historicalDepartures: [],
});

interface Props {
  startDate: Date;
  endDate: Date;
  skip?: boolean;
}

function getBetween(data: HistoricalDeparture): [number, number] {
  const start = data.start;
  const end = data.end;
  return [start.getTime(), end.getTime()];
}

const HistoricalDeparturesProvider: React.FC<Props> = ({ startDate, endDate, children, skip }) => {
  const [historicalDepartures, setHistoricalDepartures] = useState<HistoricalDeparture[]>([]);
  const [historicalDeparturesError, setHistoricalDeparturesError] = useState<ApolloError>();
  const [initHistoricalDepartures, setInitHistoricalDepartures] = useState<boolean>(false);
  const { sendError } = useSentry();

  useEffect(() => {
    setHistoricalDeparturesError(undefined);
    setInitHistoricalDepartures(false);
    setHistoricalDepartures([]);
  }, [startDate, endDate]);

  const _historicalDepartures: HistoricalDeparture[] = Array(NUM_WEEKS_HISTORICAL)
    .fill(null)
    .map((_: null, i) => ({
      weekNo: i + 1,
      start: subWeeks(startDate, i + 1),
      end: subWeeks(endDate, i + 1),
    }));

  // TODO: Is there a better way to do this?
  _historicalDepartures[0].query = useHistoricalDeparturesFlights({
    between: getBetween(_historicalDepartures[0]),
    skip,
  });
  _historicalDepartures[0].data = _historicalDepartures[0].query?.data?.departures;
  _historicalDepartures[1].query = useHistoricalDeparturesFlights({
    between: getBetween(_historicalDepartures[1]),
    skip,
  });
  _historicalDepartures[1].data = _historicalDepartures[1].query?.data?.departures;
  _historicalDepartures[2].query = useHistoricalDeparturesFlights({
    between: getBetween(_historicalDepartures[2]),
    skip,
  });
  _historicalDepartures[2].data = _historicalDepartures[2].query?.data?.departures;
  _historicalDepartures[3].query = useHistoricalDeparturesFlights({
    between: getBetween(_historicalDepartures[3]),
    skip,
  });
  _historicalDepartures[3].data = _historicalDepartures[3].query?.data?.departures;

  useEffect(() => {
    const error = _historicalDepartures.find(q => q?.query?.error)?.query?.error;
    if (error) {
      sendError({ error, action: 'useHistoricalDeparturesFlights()' });
      setHistoricalDeparturesError(error);
    } else {
      const queriesComplete = _historicalDepartures.every(
        q => !q?.query?.loading && q?.query?.data
      );
      if (queriesComplete && !initHistoricalDepartures) {
        setHistoricalDepartures(_historicalDepartures);
        setInitHistoricalDepartures(true);
      }
    }
  }, [historicalDepartures, initHistoricalDepartures, _historicalDepartures, sendError]);

  const loading = !initHistoricalDepartures && !historicalDeparturesError && !skip;

  const state = useMemo(
    () => ({
      loading,
      error: historicalDeparturesError,
      historicalDepartures,
    }),
    [loading, historicalDeparturesError, historicalDepartures]
  );

  return (
    <HistoricalDeparturesProviderContext.Provider value={state}>
      {children}
    </HistoricalDeparturesProviderContext.Provider>
  );
};

function useHistoricalDepartures() {
  const context = useContext(HistoricalDeparturesProviderContext);
  return context;
}

export { HistoricalDeparturesProvider as default, useHistoricalDepartures };
