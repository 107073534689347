interface WeatherData {
  day: string;
  night: string;
  icon: string;
  status: 'error' | 'warning' | 'success';
}
interface WeatherCodeMap {
  [key: number]: WeatherData;
}

export const weatherCodeMap: WeatherCodeMap = {
  // Clear
  1000: { day: 'Sunny', night: 'Clear', icon: 'sun', status: 'success' },

  // Cloudy
  1003: { day: 'Partly cloudy', night: 'Partly cloudy', icon: 'cloud', status: 'success' },
  1006: { day: 'Cloudy', night: 'Cloudy', icon: 'cloud', status: 'success' },
  1009: { day: 'Overcast', night: 'Overcast', icon: 'cloud', status: 'success' },

  // Rain
  1030: { day: 'Mist', night: 'Mist', icon: 'fog', status: 'warning' },
  1063: {
    day: 'Patchy rain possible',
    night: 'Patchy rain possible',
    icon: 'rain',
    status: 'warning',
  },
  1150: {
    day: 'Patchy light drizzle',
    night: 'Patchy light drizzle',
    icon: 'rain',
    status: 'warning',
  },
  1153: { day: 'Light drizzle', night: 'Light drizzle', icon: 'rain', status: 'warning' },
  1180: { day: 'Patchy light rain', night: 'Patchy light rain', icon: 'rain', status: 'warning' },
  1183: { day: 'Light rain', night: 'Light rain', icon: 'rain', status: 'warning' },
  1186: {
    day: 'Moderate rain at times',
    night: 'Moderate rain at times',
    icon: 'rain',
    status: 'warning',
  },
  1189: { day: 'Moderate rain', night: 'Moderate rain', icon: 'rain', status: 'warning' },
  1192: {
    day: 'Heavy rain at times',
    night: 'Heavy rain at times',
    icon: 'rain',
    status: 'warning',
  },
  1195: { day: 'Heavy rain', night: 'Heavy rain', icon: 'rain', status: 'warning' },
  1240: { day: 'Light rain shower', night: 'Light rain shower', icon: 'rain', status: 'warning' },
  1243: {
    day: 'Moderate or heavy rain shower',
    night: 'Moderate or heavy rain shower',
    icon: 'rain',
    status: 'warning',
  },
  1246: {
    day: 'Torrential rain shower',
    night: 'Torrential rain shower',
    icon: 'rain',
    status: 'warning',
  },

  // Thunderstorms
  1087: {
    day: 'Thundery outbreaks possible',
    night: 'Thundery outbreaks possible',
    icon: 'lightning',
    status: 'warning',
  },
  1273: {
    day: 'Patchy light rain with thunder',
    night: 'Patchy light rain with thunder',
    icon: 'lightning',
    status: 'warning',
  },
  1276: {
    day: 'Moderate or heavy rain with thunder',
    night: 'Moderate or heavy rain with thunder',
    icon: 'lightning',
    status: 'warning',
  },
  1279: {
    day: 'Patchy light snow with thunder',
    night: 'Patchy light snow with thunder',
    icon: 'lightning',
    status: 'warning',
  },
  1282: {
    day: 'Moderate or heavy snow with thunder',
    night: 'Moderate or heavy snow with thunder',
    icon: 'lightning',
    status: 'warning',
  },

  // Sleet
  1069: {
    day: 'Patchy sleet possible',
    night: 'Patchy sleet possible',
    icon: 'sleet',
    status: 'warning',
  },
  1072: {
    day: 'Patchy freezing drizzle possible',
    night: 'Patchy freezing drizzle possible',
    icon: 'sleet',
    status: 'warning',
  },
  1168: { day: 'Freezing drizzle', night: 'Freezing drizzle', icon: 'sleet', status: 'warning' },
  1171: {
    day: 'Heavy freezing drizzle',
    night: 'Heavy freezing drizzle',
    icon: 'sleet',
    status: 'warning',
  },
  1198: {
    day: 'Light freezing rain',
    night: 'Light freezing rain',
    icon: 'sleet',
    status: 'warning',
  },
  1201: {
    day: 'Moderate or heavy freezing rain',
    night: 'Moderate or heavy freezing rain',
    icon: 'sleet',
    status: 'warning',
  },
  1204: { day: 'Light sleet', night: 'Light sleet', icon: 'sleet', status: 'warning' },
  1207: {
    day: 'Moderate or heavy sleet',
    night: 'Moderate or heavy sleet',
    icon: 'sleet',
    status: 'warning',
  },
  1249: {
    day: 'Light sleet showers',
    night: 'Light sleet showers',
    icon: 'sleet',
    status: 'warning',
  },
  1252: {
    day: 'Moderate or heavy sleet showers',
    night: 'Moderate or heavy sleet showers',
    icon: 'sleet',
    status: 'warning',
  },

  // Snow
  1066: {
    day: 'Patchy snow possible',
    night: 'Patchy snow possible',
    icon: 'snow',
    status: 'warning',
  },
  1114: { day: 'Blowing snow', night: 'Blowing snow', icon: 'snow', status: 'warning' },
  1210: { day: 'Patchy light snow', night: 'Patchy light snow', icon: 'snow', status: 'warning' },
  1213: { day: 'Light snow', night: 'Light snow', icon: 'snow', status: 'warning' },
  1216: {
    day: 'Patchy moderate snow',
    night: 'Patchy moderate snow',
    icon: 'snow',
    status: 'warning',
  },
  1219: { day: 'Moderate snow', night: 'Moderate snow', icon: 'snow', status: 'warning' },
  1222: { day: 'Patchy heavy snow', night: 'Patchy heavy snow', icon: 'snow', status: 'warning' },
  1225: { day: 'Heavy snow', night: 'Heavy snow', icon: 'snow', status: 'warning' },
  1237: { day: 'Ice pellets', night: 'Ice pellets', icon: 'snow', status: 'warning' },
  1255: { day: 'Light snow showers', night: 'Light snow showers', icon: 'snow', status: 'warning' },
  1258: {
    day: 'Moderate or heavy snow showers',
    night: 'Moderate or heavy snow showers',
    icon: 'snow',
    status: 'warning',
  },
  1264: {
    day: 'Moderate or heavy showers of ice pellets',
    night: 'Moderate or heavy showers of ice pellets',
    icon: 'snow',
    status: 'warning',
  },

  // Fog
  1135: { day: 'Fog', night: 'Fog', icon: 'fog', status: 'success' },
  1147: { day: 'Freezing fog', night: 'Freezing fog', icon: 'fog', status: 'success' },

  // Blizzard
  1117: { day: 'Blizzard', night: 'Blizzard', icon: 'snow', status: 'warning' },
};

const NORMALIZED_WEATHER_CONDITIONS: { [key: string]: string[] } = {
  Clear: ['Sunny', 'Clear'],
  Cloudy: ['Partly cloudy', 'Cloudy', 'Overcast'],
  Rain: [
    'Mist',
    'Patchy rain possible',
    'Patchy light drizzle',
    'Light drizzle',
    'Patchy light rain',
    'Light rain',
    'Moderate rain at times',
    'Moderate rain',
    'Heavy rain at times',
    'Heavy rain',
    'Light rain shower',
    'Moderate or heavy rain shower',
    'Torrential rain shower',
  ],
  Thunderstorms: [
    'Thundery outbreaks possible',
    'Patchy light rain with thunder',
    'Moderate or heavy rain with thunder',
    'Patchy light snow with thunder',
    'Moderate or heavy snow with thunder',
  ],
  Sleet: [
    'Patchy sleet possible',
    'Patchy freezing drizzle possible',
    'Freezing drizzle',
    'Heavy freezing drizzle',
    'Light freezing rain',
    'Moderate or heavy freezing rain',
    'Light sleet',
    'Moderate or heavy sleet',
    'Light sleet showers',
    'Moderate or heavy sleet showers',
  ],
  Snow: [
    'Patchy snow possible',
    'Blowing snow',
    'Patchy light snow',
    'Light snow',
    'Patchy moderate snow',
    'Moderate snow',
    'Patchy heavy snow',
    'Heavy snow',
    'Ice pellets',
    'Light snow showers',
    'Moderate or heavy snow showers',
    'Moderate or heavy showers of ice pellets',
  ],
  Fog: ['Fog', 'Freezing fog'],
  Blizzard: ['Blizzard'],
};

export const normalizedWeatherConditions = Object.keys(NORMALIZED_WEATHER_CONDITIONS);
export const weatherConditions = Object.values(NORMALIZED_WEATHER_CONDITIONS);

export const getNormalizedWeatherCondition = (condition: string): string => {
  for (const [normalizedValue, potentialValues] of Object.entries(NORMALIZED_WEATHER_CONDITIONS)) {
    if (potentialValues.includes(condition)) return normalizedValue;
  }
  return condition;
};
