import { parseISO } from 'date-fns';
import { find } from 'lodash';
import max from 'lodash/max';
import min from 'lodash/min';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';

import { ChartOptionsFilter } from 'config/tabs';
import { AIRPORT_CODE } from 'config/constants';

import { Throughput } from 'data/Throughput/types';

import { FILTER_TYPES } from 'statistics/charts/constants';
import { filterByDayRange, getFilteredChartData } from 'statistics/charts/utils';
import { STAT_TYPES } from 'statistics/stats/constants';
import { ChartDatum, DataObject, StatsData } from 'statistics/types';

import { ProcessedDataObject } from './utils';

type DailyThroughputField =
  | 'totalYesterday'
  | 'weeklyRollingAverage'
  | 'totalOneYearAgo'
  | 'total2019';

function getFilteredData(data: Throughput[]): Throughput[] {
  return data.filter((d: Throughput) => d.airport !== 'USA');
}

function getLabel(airport: string, fieldName: DailyThroughputField) {
  switch (fieldName) {
    case 'totalYesterday':
      return airport;
    case 'weeklyRollingAverage':
      return `7 Day Rolling Average`;
    case 'totalOneYearAgo':
      return '1 year ago';
    case 'total2019':
      return '2019';
    default:
      return airport;
  }
}
function getChartData(data: Throughput[], fieldName: DailyThroughputField): ChartDatum[] {
  const dailyThroughputData = data.map((d: Throughput) => ({
    x: parseISO(d.date).getTime(),
    y: d[fieldName],
    name: getLabel(d.airport, fieldName),
  }));
  return sortBy(dailyThroughputData, 'y');
}

function getActiveFilterLabel(activeFilters: ChartOptionsFilter[]) {
  if (activeFilters.length === 0) {
    return 'Regional Airports';
  }

  const label = activeFilters[activeFilters.length - 1]?.label;
  if (activeFilters.length > 0) {
    if (label === `${AIRPORT_CODE} Only`) {
      return AIRPORT_CODE;
    }
    return label;
  }
}

function getStatsData(data: Throughput[], activeFilters: ChartOptionsFilter[]): StatsData {
  const totals: { [key: string]: number } = {};

  data.forEach((d: Throughput) => {
    const key = `${d.airport}-${d.date}`;
    if (!(key in totals)) {
      totals[key] = 0;
    }
    totals[key] += d.totalYesterday;
  });

  const values = Object.values(totals);

  const lowest = min(values) || 0;
  const highest = max(values) || 0;
  const average = values.length ? sum(values) / values.length : 0;

  const highestLabel = Object.keys(totals).find(key => totals[key] === highest);
  const lowestLabel = Object.keys(totals).find(key => totals[key] === lowest);
  const label = getActiveFilterLabel(activeFilters);

  return {
    [STAT_TYPES.DAILY_THROUGHPUT.AVERAGE]: { value: average, contextLabel: label },
    [STAT_TYPES.DAILY_THROUGHPUT.HIGHEST]: { value: highest, contextLabel: highestLabel },
    [STAT_TYPES.DAILY_THROUGHPUT.LOWEST]: { value: lowest, contextLabel: lowestLabel },
  };
}

function getProcessedData(
  dailyThroughputsData: DataObject,
  dateDayRanges: string[],
  activeFilters: ChartOptionsFilter[],
  fieldName: DailyThroughputField
): ProcessedDataObject {
  const { data: dailyThroughputs, loading, error } = dailyThroughputsData;
  if (loading || error)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
    };
  const filteredData = getFilteredData(dailyThroughputs as Throughput[]);
  let _filteredData = filteredData;

  if (find(activeFilters, { type: FILTER_TYPES.DAILY_THROUGHPUT.LOCAL })) {
    const codeRegex = new RegExp(AIRPORT_CODE, 'i');
    _filteredData = (dailyThroughputs as Throughput[])?.filter((t: Throughput) =>
      t.airport.match(codeRegex)
    );
  }

  if (find(activeFilters, { type: FILTER_TYPES.DAILY_THROUGHPUT.ALL_AIRPORTS })) {
    _filteredData = (dailyThroughputs as Throughput[])?.filter((t: Throughput) =>
      t.airport.match(/usa/i)
    );
  }

  const hasLocalFilter = find(activeFilters, { type: FILTER_TYPES.DAILY_THROUGHPUT.LOCAL });

  if (fieldName !== 'totalYesterday' && !hasLocalFilter) {
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
    };
  }

  const { byDayRange } = filterByDayRange(dateDayRanges);
  _filteredData.filter((datum: Throughput) => byDayRange(parseISO(datum.date)));

  return {
    chart: {
      loading,
      error,
      data: getFilteredChartData(getChartData(_filteredData, fieldName), dateDayRanges),
    },
    stats: {
      loading,
      error,
      data: getStatsData(_filteredData, activeFilters),
    },
  };
}

export default {
  daily: {
    getProcessedData: (
      dailyThroughputsData: DataObject,
      dateDayRanges: string[],
      activeFilters: ChartOptionsFilter[]
    ): ProcessedDataObject =>
      getProcessedData(dailyThroughputsData, dateDayRanges, activeFilters, 'totalYesterday'),
  },
  rollingAvg: {
    getProcessedData: (
      dailyThroughputsData: DataObject,
      dateDayRanges: string[],
      activeFilters: ChartOptionsFilter[]
    ): ProcessedDataObject =>
      getProcessedData(dailyThroughputsData, dateDayRanges, activeFilters, 'weeklyRollingAverage'),
  },
  oneYearAgo: {
    getProcessedData: (
      dailyThroughputsData: DataObject,
      dateDayRanges: string[],
      activeFilters: ChartOptionsFilter[]
    ): ProcessedDataObject =>
      getProcessedData(dailyThroughputsData, dateDayRanges, activeFilters, 'totalOneYearAgo'),
  },
  year2019: {
    getProcessedData: (
      dailyThroughputsData: DataObject,
      dateDayRanges: string[],
      activeFilters: ChartOptionsFilter[]
    ): ProcessedDataObject =>
      getProcessedData(dailyThroughputsData, dateDayRanges, activeFilters, 'total2019'),
  },
};
