import React from 'react';

import { getBusiestHour, getSlowestHour } from 'utils/passengerFlowStats';

import { PROCESSOR_TYPES } from 'statistics/processors/constants';
import { ProcessedDataObject } from 'statistics/processors/utils';

import { Icon, Tooltip } from 'components';

import { Box, TextLabel } from 'styled';
import { STAT_TYPES } from 'statistics/stats/constants';

interface Props {
  currentTab: string;
  data: { [key: string]: ProcessedDataObject } | undefined;
}

type Stat = {
  chart: string;
  label: string;
  value?: React.ReactNode;
  suffix?: string;
  icon?: string;
  tooltip?: string;
};

const ChartControlStats: React.FC<Props> = ({ data, currentTab }: Props) => {
  const totalProjection =
    data?.[PROCESSOR_TYPES.DAILY_PROJECTION]?.stats?.data?.totalProjection?.value || undefined;
  const projectedBusiestHourTime =
    data?.[PROCESSOR_TYPES.DAILY_PROJECTION]?.stats?.data?.busiestPredictedHourTime?.value;
  const projectedBusiestHourCount =
    data?.[PROCESSOR_TYPES.DAILY_PROJECTION]?.stats?.data?.busiestPredictedHourCount?.value || 0;

  const totalFlights = (): number | undefined => {
    if (
      !data?.[PROCESSOR_TYPES.DEPARTURES.PASSENGER]?.details?.data ||
      !data?.[PROCESSOR_TYPES.ARRIVALS.PASSENGER]?.details?.data
    )
      return undefined;
    const passengerDepartures = data?.[PROCESSOR_TYPES.DEPARTURES.PASSENGER].details?.data;
    const passengerArrivals = data?.[PROCESSOR_TYPES.ARRIVALS.PASSENGER].details?.data;
    const cargoDepartures = data?.[PROCESSOR_TYPES.DEPARTURES.CARGO].details?.data;
    const cargoArrivals = data?.[PROCESSOR_TYPES.ARRIVALS.CARGO].details?.data;
    return (
      passengerDepartures.length +
      passengerArrivals.length +
      cargoDepartures.length +
      cargoArrivals.length
    ).toLocaleString();
  };

  const moreCheckpoints = process.env.REACT_APP_CHECKPOINT_TOTAL === '5';
  let generalWaitTime;
  let generalHighest = 'N/A';
  let tsaWaitTime;
  let tsaHighest = 'N/A';
  if (moreCheckpoints) {
    generalWaitTime =
      (data?.[PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES1]?.stats?.data?.[
        STAT_TYPES.WAIT_TIMES.WAIT_TIMES1.HIGHEST
      ]?.value as number) - 1 || 0;
    generalHighest = 'Terminal A-West';
    const generalWaitTime2 =
      (data?.[PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES2]?.stats?.data?.[
        STAT_TYPES.WAIT_TIMES.WAIT_TIMES2.HIGHEST
      ]?.value as number) - 1 || 0;
    if (generalWaitTime2 > generalWaitTime) {
      generalWaitTime = generalWaitTime2;
      generalHighest = 'Terminal A-East';
    }
    const generalWaitTime4 =
      (data?.[PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES4]?.stats?.data?.[
        STAT_TYPES.WAIT_TIMES.WAIT_TIMES4.HIGHEST
      ]?.value as number) - 1 || 0;
    if (generalWaitTime4 > generalWaitTime) {
      generalWaitTime = generalWaitTime4;
      generalHighest = 'Terminal D/E';
    }

    tsaWaitTime =
      (data?.[PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES3]?.stats?.data?.[
        STAT_TYPES.WAIT_TIMES.WAIT_TIMES3.HIGHEST
      ]?.value as number) - 1 || 0;
    tsaHighest = 'Terminal A-East';
    const tsaWaitTime5 =
      (data?.[PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES5]?.stats?.data?.[
        STAT_TYPES.WAIT_TIMES.WAIT_TIMES5.HIGHEST
      ]?.value as number) - 1 || 0;
    if (tsaWaitTime5 > tsaWaitTime) {
      tsaWaitTime = tsaWaitTime5;
      tsaHighest = 'Terminal D/E';
    }
  } else {
    generalWaitTime =
      (data?.[PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES1]?.stats?.data?.[
        STAT_TYPES.WAIT_TIMES.WAIT_TIMES1.HIGHEST
      ]?.value as number) - 1 || 0;

    tsaWaitTime =
      (data?.[PROCESSOR_TYPES.WAIT_TIMES.WAIT_TIMES2]?.stats?.data?.[
        STAT_TYPES.WAIT_TIMES.WAIT_TIMES2.HIGHEST
      ]?.value as number) - 1 || 0;
  }

  const securityStats = [];

  securityStats.push({
    chart: 'Security',
    label: 'Highest General Wait Time',
    icon: 'clock',
    value: `${generalWaitTime} minutes${moreCheckpoints ? ` (${generalHighest})` : ''}`,
  });
  securityStats.push({
    chart: 'Security',
    label: 'Highest TSA PreCheck Wait Time',
    icon: 'clock',
    value: `${tsaWaitTime} minutes${moreCheckpoints ? ` (${tsaHighest})` : ''}`,
  });
  // }

  const projectionStats = [];
  if (process.env.REACT_APP_CONFIG_PROJECTIONS === 'true') {
    projectionStats.push({
      chart: 'Projection',
      label: 'Daily Total Projection',
      value: totalProjection?.toLocaleString(),
      tooltip: ` ${totalProjection?.toLocaleString()} projected`,
    });
    projectionStats.push({
      chart: 'Projection',
      label: 'Projected Busiest Hour',
      value: projectedBusiestHourTime,
      icon: 'clock',
      tooltip: `Projected number of passengers at ${projectedBusiestHourTime}: ${projectedBusiestHourCount.toLocaleString()}`,
    });
  }

  const STATS: Stat[] = [
    ...securityStats,
    ...projectionStats,
    {
      chart: 'Flights',
      label: 'Total Flights',
      value: totalFlights(),
    },
    {
      chart: 'Flow',
      icon: 'clock',
      label: 'Slowest Time',
      value: getSlowestHour(data),
    },
    {
      chart: 'Flow',
      icon: 'clock',
      label: 'Busiest Time',
      value: getBusiestHour(data),
    },
  ];

  function renderStat(chart: string): JSX.Element | null {
    const items = STATS.filter(s => s.chart === chart);

    if (!items || items.length === 0) return null;

    const isLast = (idx: number): boolean => idx === items.length - 1;

    return (
      <Box alignItems="center" display="flex">
        {items.map((item, idx) => {
          const stat = (
            <StatComponent
              key={idx}
              label={item.label}
              value={item.value}
              icon={item?.icon}
              suffix={item?.suffix}
              mr={isLast(idx) ? '0' : 'base'}
            />
          );

          return item.tooltip ? (
            <Tooltip key={idx} label={item.tooltip}>
              <Box as="span" display="flex">
                {stat}
              </Box>
            </Tooltip>
          ) : (
            stat
          );
        })}
      </Box>
    );
  }

  return renderStat(currentTab);
};

interface StatProps {
  label: string;
  value?: React.ReactNode;
  icon?: string;
  suffix?: string;
  mr?: string;
}

const StatComponent: React.FC<StatProps> = ({ label, value, icon, suffix, mr }: StatProps) => {
  function renderValue(): React.ReactNode {
    if (suffix) {
      if (value) return `${value} ${suffix}`;
      return 'N/A';
    }
    if (value) return value;
    return 'N/A';
  }

  return (
    <Box alignItems="center" display="flex" justifyContent="flex-end" lineHeight="1.3" mr={mr}>
      <Box>
        <Icon name={icon || 'barChart'} color="subdued" size="28" mr="s" />
      </Box>
      <Box>
        <TextLabel color="subdued" mb="0">
          {label}
        </TextLabel>
        <Box as="b" fontSize="l" fontWeight="bold">
          {renderValue()}
        </Box>
      </Box>
    </Box>
  );
};

export default ChartControlStats;
