import { StatItem, StatsArgs } from 'statistics/types';

import { STAT_TYPES } from './constants';

function getAverageStatItem() {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Average Sales',
    value: `$${Math.floor(value as number).toLocaleString()}`,
    contextLabel,
  });
}

function getHighestStatItem() {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Highest Sales',
    value: `$${Math.floor(value as number).toLocaleString()}`,
    contextLabel,
  });
}

function getLowestStatItem() {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Lowest Sales',
    value: `$${Math.floor(value as number).toLocaleString()}`,
    contextLabel,
  });
}

export default {
  [STAT_TYPES.SALES.CONCOURSE_A.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.SALES.CONCOURSE_A.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.SALES.CONCOURSE_A.LOWEST]: getLowestStatItem(),
  [STAT_TYPES.SALES.CONCOURSE_B.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.SALES.CONCOURSE_B.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.SALES.CONCOURSE_B.LOWEST]: getLowestStatItem(),
  [STAT_TYPES.SALES.MAIN_TERMINAL.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.SALES.MAIN_TERMINAL.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.SALES.MAIN_TERMINAL.LOWEST]: getLowestStatItem(),
  [STAT_TYPES.SALES.SHORT_TERM.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.SALES.SHORT_TERM.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.SALES.SHORT_TERM.LOWEST]: getLowestStatItem(),
  [STAT_TYPES.SALES.LONG_TERM.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.SALES.LONG_TERM.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.SALES.LONG_TERM.LOWEST]: getLowestStatItem(),
  [STAT_TYPES.SALES.ECONO_LOT.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.SALES.ECONO_LOT.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.SALES.ECONO_LOT.LOWEST]: getLowestStatItem(),
  [STAT_TYPES.SALES.VALET.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.SALES.VALET.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.SALES.VALET.LOWEST]: getLowestStatItem(),
  [STAT_TYPES.SALES.TOTAL.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.SALES.TOTAL.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.SALES.TOTAL.LOWEST]: getLowestStatItem(),
};
