import { addMinutes, differenceInMinutes, startOfHour } from 'date-fns';
import range from 'lodash/range';

import { PredictionPerFlightData } from './types';

export function getHalfHourMultiplier(startDate: Date, endDate: Date): number[] {
  return range(Math.floor(differenceInMinutes(endDate, startDate) / 30) + 1);
}

export function getQuarterHourMultiplier(startDate: Date, endDate: Date): number[] {
  return range(Math.floor(differenceInMinutes(endDate, startDate) / 15) + 1);
}

export function getPredictionDataInitializer(
  startDate: Date,
  endDate: Date,
  initialObject: PredictionPerFlightData
): { [key: string]: PredictionPerFlightData } {
  const data: { [key: string]: PredictionPerFlightData } = {};
  let startTime = startOfHour(startDate);
  if (startDate.getMinutes() >= 30) {
    startTime = addMinutes(startTime, 30);
  }
  const halfHourMultiplier = getHalfHourMultiplier(startDate, endDate);
  halfHourMultiplier.forEach((halfHour: number) => {
    const time = new Date(startTime);
    time.setMinutes(time.getMinutes() + halfHour * 30);
    data[time.getTime()] = {
      ...initialObject,
    };
  });
  return data;
}
