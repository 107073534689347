import omit from 'lodash/omit';
import React, { createContext, useContext, useReducer } from 'react';

import { ChartOptions } from 'config/tabs';

export interface Action {
  type: string;
  payload?: object;
}

export interface State {
  activeCharts: ChartOptions[];
  activeTab: string;
  activeChartDetails: string;
}

export const initialState: State = {
  activeCharts: [],
  activeTab: '',
  activeChartDetails: '',
};

const ActiveChartsProviderStateContext = createContext<State>(initialState);
const ActiveChartsProviderDispatchContext = createContext<React.Dispatch<Action> | null>(null);

const actionTypes = {
  update: 'update',
  reset: 'reset',
};

// ACTION CREATORS
// dispatch(updateActiveChartsState({ key: 'value' }));
const updateActiveChartsState = (payload: object) => ({
  type: actionTypes.update,
  payload,
});

// dispatch(resetActiveChartsState());
const resetActiveChartsState = () => ({ type: actionTypes.reset });

function reducer(state: State, action: Action) {
  switch (action.type) {
    case actionTypes.update: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.reset: {
      return {
        ...state,
        ...omit(initialState, ['height', 'width']),
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

interface Props {
  children?: React.ReactNode;
}

const ActiveChartsProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ActiveChartsProviderStateContext.Provider value={state}>
      <ActiveChartsProviderDispatchContext.Provider value={dispatch}>
        {children}
      </ActiveChartsProviderDispatchContext.Provider>
    </ActiveChartsProviderStateContext.Provider>
  );
};

// const state = useActiveChartsState();
function useActiveChartsState() {
  const context = useContext(ActiveChartsProviderStateContext);
  if (context === undefined) {
    throw new Error(`useActiveChartsState must be used within a ActiveChartsProvider`);
  }
  return context;
}

// const dispatch = useActiveChartsDispatch();
function useActiveChartsDispatch() {
  const context = useContext(ActiveChartsProviderDispatchContext);
  if (context === undefined) {
    throw new Error(`useActiveChartsDispatch must be used within a ActiveChartsProvider`);
  }
  return context;
}

// const [state, dispatch] = useActiveCharts();
function useActiveCharts() {
  const context: [State, React.Dispatch<Action>] = [
    useActiveChartsState(),
    useActiveChartsDispatch() as React.Dispatch<Action>,
  ];
  if (context === undefined) {
    throw new Error(`useActiveCharts must be used within a ActiveChartsProvider`);
  }
  return context;
}

// SELECTORS
const selectors = {};

export {
  ActiveChartsProvider as default,
  useActiveCharts,
  useActiveChartsState,
  useActiveChartsDispatch,
  actionTypes,
  selectors,
  updateActiveChartsState,
  resetActiveChartsState,
};
