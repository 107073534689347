import React from 'react';

import { useRVRs } from 'data/RVR';

import { useRefreshedAt } from 'hooks';
import { QueryCell, UpdatedAt } from 'components';

import { RVR as RVRComponent } from './components';

const RVR: React.FC = () => {
  const { loading, error, rvrs } = useRVRs();

  const { refreshedAt } = useRefreshedAt(loading);
  if (process.env.REACT_APP_CONFIG_RVR !== 'true') return null;

  return (
    <QueryCell content="RVR" loading={loading} error={error} data={rvrs}>
      <RVRComponent rvrs={rvrs} />
      <UpdatedAt date={refreshedAt} />
    </QueryCell>
  );
};

export default RVR;
