import React from 'react';

import modals from 'config/modals';

import {
  AlertsProvider,
  ModalProvider,
  OnboardingProvider,
  ProviderComposer,
  SettingsProvider,
  TileOptionsProvider,
  UserDataProvider,
} from 'providers';

interface Props {
  children?: React.ReactNode;
}

// --
// This provider is structured just like `AppProvider`, but is only
// used within the `PrivateRoute` component when a user is authenticated
// and their data is available.
// --
// This is needed for the `SettingsProvider` to capture the user's
// metadata in Auth0, which is also used by `TileOptionsProvider`
// and `AlertsProvider`.
// --
// The `ModalProvider` and `ToastProvider` are included here, too,
// since they may need to access this data. For example, the
// `TileOptionsProvider` is used within various modals.
// --
const PrivateAppProvider: React.FC<Props> = ({ children }: Props) => {
  const contexts: React.ReactElement[] = [
    <UserDataProvider key={1} />,
    <SettingsProvider key={2} />,
    <TileOptionsProvider key={3} />,
    <AlertsProvider key={4} />,
    <ModalProvider key={5} modals={modals} />,
    <OnboardingProvider key={6} />,
  ];

  return <ProviderComposer contexts={contexts}>{children}</ProviderComposer>;
};

export default PrivateAppProvider;
