import merge from 'lodash/merge';

import theme from 'config/theme';

import { getDefaultYAxisOptions } from 'statistics/charts/utils';

const axis: Highcharts.YAxisOptions = merge(getDefaultYAxisOptions(), {
  title: {
    text: 'Baggage',
  },
  stackLabels: {
    enabled: true,
    style: {
      color: theme?.colors?.fg,
      textOutline: 'none',
    },
    formatter: function (): string {
      return this.total.toLocaleString();
    },
  },
  id: 'baggage',
} as Highcharts.YAxisOptions);

export default function getAxis(): Highcharts.YAxisOptions {
  return axis;
}
