import theme from 'config/theme';

function getRVRColor(value: number | null): string {
  if (value === null) return theme.colors.fg;
  if (value > 6000) return theme.colors.primary;
  if (value >= 1300) return theme.colors.warning;
  return theme.colors.error;
}

export default getRVRColor;
