import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import Button from './Button';
import system from './lib/system';

const LinkButton = styled(Button)`
  background: transparent;
  box-shadow: none;
  color: ${themeGet('colors.fg')};
  font-size: ${themeGet('fontSizes.s')};
  padding-left: ${themeGet('space.base')};
  padding-right: ${themeGet('space.base')};
  text-decoration: underline;

  &:active,
  &:focus,
  &:hover {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: ${themeGet('colors.fg')};
    text-decoration: none;
  }

  &:active {
    border-color: transparent;
    box-shadow: none;
    position: static;
  }

  &:disabled {
    opacity: 0.4;
    text-decoration: none;
  }

  ${system}
`;

export default LinkButton;
