import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';

import CHART_COLORS, { ChartColorsKey } from 'config/chartColors';

import { getDefaultSeriesOptions, getVisibility } from 'statistics/charts/utils';
import { ChartDatum } from 'statistics/types';

type TimeFrame = 'AM' | 'PM' | 'Total';

export default function getSeries(
  data: ChartDatum[],
  timeframe: TimeFrame,
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType {
  switch (timeframe) {
    case 'AM':
    case 'PM':
      return merge(getDefaultSeriesOptions(), {
        name: timeframe,
        yAxis: 'passengers',
        borderWidth: 0,
        data,
        color: CHART_COLORS[`Passenger Projection - ${timeframe}` as ChartColorsKey],
        stacking: 'normal',
        crisp: false,
        grouping: false,
        tooltip: {
          pointFormatter(): string {
            let { y } = this;
            const { series } = this;
            y = Math.ceil(y || 0);
            return `${series?.name}: <b>${y.toLocaleString()}</b><br/>`;
          },
        },
        ...chartOptions,
        type: 'column',
        visible: getVisibility(timeframe),
      });
    case 'Total':
    default:
      return merge(getDefaultSeriesOptions(), {
        visible: false,
        yAxis: 'passengers',
        showInLegend: false,
        stacking: 'normal',
        crisp: false,
        grouping: false,
        tooltip: {
          pointFormatter(): string {
            let { y } = this;
            const { series } = this;
            y = Math.ceil(y || 0);
            return `${series?.name}: <b>${y.toLocaleString()}</b><br/>`;
          },
        },
        ...chartOptions,
        type: 'column',
      } as SeriesOptionsType);
  }
}
