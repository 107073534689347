import React from 'react';

import { getGlobalData } from 'utils/globalData';

import { Airport } from 'client/Airport/types';

import { updateOptions, useChartOptions } from 'providers/ChartOptionsProvider';

import { Search } from 'components';
import { AIRPORT_CODE } from 'config/constants';

import { Box } from 'styled';

interface Props {
  placeholder?: string;
  resultsWidth?: string;
  side?: string;
}

interface Item {
  code: string;
  name: string;
  state: string;
}

interface Event {
  target: {
    value: string;
  };
}

const AirportSearch: React.FC<Props> = props => {
  const [state, dispatch] = useChartOptions();
  const { activeReport, destinationAirportInputValue } = state;
  const airports = getGlobalData('airports') as Airport[];

  return (
    <Search
      data={airports.filter(airport => airport.code !== AIRPORT_CODE)}
      inputValue={destinationAirportInputValue}
      matcherKeys={['code', 'name', 'state']}
      renderResult={(item: Item) => (
        <>
          {Array.isArray(item.code) ? item.code.join(', ') : item.code} - {item.state}
          <Box as="b" display="block" fontSize="s">
            {item.name}
          </Box>
        </>
      )}
      onClear={() => {
        dispatch(updateOptions({ destinationAirportInputValue: '', destinationAirport: '' }));
      }}
      onChange={(event: Event) => {
        dispatch(updateOptions({ destinationAirportInputValue: event.target.value }));
      }}
      onSelect={(selection: Item) => {
        const code = Array.isArray(selection?.code) ? selection?.code[0] : selection?.code;
        dispatch(
          updateOptions({
            destinationAirportInputValue: code,
            destinationAirport: code || undefined,
          })
        );
      }}
      disabled={activeReport}
      {...props}
    />
  );
};

export default AirportSearch;
