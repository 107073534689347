import { default as arrivals } from './arrivals';
import { default as baggage } from './baggage';
import { default as bookings } from './bookings';
import { default as cargoRevenue } from './cargoRevenue';
import { default as commonUse } from './commonUse';
import { default as dailyThroughputs } from './dailyThroughputs';
import { default as departures } from './departures';
import { default as sales } from './sales';
import { default as security } from './security';
import { default as projection } from './projection';

export default {
  arrivals,
  baggage,
  bookings,
  cargoRevenue,
  commonUse,
  dailyThroughputs,
  departures,
  sales,
  security,
  projection,
};
