import { gql, QueryResult, useQuery } from '@apollo/client';

import { FeedStatus } from './types';

const FEED_STATUS = gql`
  query FeedStatus {
    feedStatus {
      feed
      description
      status
      tooltip
      lastChecked
    }
  }
`;

export default function useFeedStatus(): QueryResult<{ feedStatus: FeedStatus[] }> {
  return useQuery(FEED_STATUS);
}
