import { StatItem, StatsArgs } from 'statistics/types';

import { STAT_TYPES } from './constants';

function getAverageStatItem() {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Average Hourly Projection',
    value: Math.round(value as number).toLocaleString(),
    contextLabel,
  });
}

function getHighestStatItem() {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Highest Hourly Projection',
    value: value.toLocaleString(),
    contextLabel,
  });
}

function getLowestStatItem() {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Lowest Hourly Projection',
    value: value.toLocaleString(),
    contextLabel,
  });
}

export default {
  [STAT_TYPES.PROJECTION.AM.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.PROJECTION.AM.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.PROJECTION.AM.LOWEST]: getLowestStatItem(),
  [STAT_TYPES.PROJECTION.PM.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.PROJECTION.PM.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.PROJECTION.PM.LOWEST]: getLowestStatItem(),
};
