import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Uppercase = styled.span<SystemProps>`
  text-transform: uppercase;

  ${system}
`;

export default Uppercase;
