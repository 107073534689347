import React from 'react';

import { Box } from 'styled';

interface ItemProps {
  children: React.ReactNode;
  name: string;
  mb?: string;
}

const Item: React.FC<ItemProps> = ({ children, name, mb }: ItemProps): JSX.Element => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="column"
    textAlign="left"
    width="100%"
    mb={mb}
    p="s"
  >
    <Box alignSelf="left" width="100%" mb="s">
      {name}
    </Box>
    <Box
      display="flex"
      width="100%"
      flexDirection="row"
      alignItems="center"
      textAlign="center"
      justifyContent="space-between"
    >
      {children}
    </Box>
  </Box>
);

export default Item;
