import { gql, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { BathroomsArgs, BathroomsQuery } from './types';

const GET_BATHROOMS = gql`
  query Bathrooms($between: [DateTime], $offset: Int, $limit: Int, $order: [[String]]) {
    bathrooms(between: $between, offset: $offset, limit: $limit, order: $order) {
      id
      count
      area
      threshold
      totalCount
      updatedAt
    }
  }
`;

export default function useBathrooms({
  between,
  offset,
  limit,
  order,
  refresh = true,
  skip = false,
}: BathroomsArgs = {}): BathroomsQuery {
  const variables = pickBy(
    {
      between,
      offset,
      limit,
      order,
    },
    identity
  );

  const options = {
    variables,
    pollInterval: 1000 * 60, // 1 minute
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;
  const query = useQuery(GET_BATHROOMS, options);

  return {
    bathrooms: query.data?.bathrooms || [],
    ...query,
  };
}
