import React from 'react';

import { Notam } from 'tiles/Airfield/utils';

import { NotamFilter } from 'data/Notam/types';

import { Box, TileContent, TileContentInner } from 'styled';

import NotamsMap from './NotamsMap';

interface Props {
  showTable?: boolean;
  showMap?: boolean;
  mapHeight?: string;
  filter: NotamFilter;
}

interface MapProps {
  mapHeight?: string | object;
  mapMinHeight?: string;
  parsedNotams: Notam[];
}

const Map: React.FC<MapProps> = ({ mapHeight, mapMinHeight, parsedNotams }: MapProps) => (
  <TileContent overflowY="hidden">
    <TileContentInner>
      <Box height={mapHeight} minHeight={mapMinHeight} width="100%">
        <NotamsMap notams={parsedNotams} />
      </Box>
    </TileContentInner>
  </TileContent>
);

export default Map;
