import sortBy from 'lodash/sortBy';
import React from 'react';

import { getAirportCity } from 'config/airports';

import { CargoDeparturesData } from 'utils/departures';

import { useDepartureFlights } from 'data/Flights';

import { useRefreshedAt } from 'hooks';

import { QueryCell, UpdatedAt } from 'components';
import { Props as IconProps } from 'components/Icon';
import { Heading } from 'components/Table';

import { Box } from 'styled';

import DepartureFlights from './DepartureFlights';

export interface Props {
  filter?: Function;
  filterEmptyMessage?: string;
  filterBlankSlateIcon?: IconProps;
  sortByColumn?: string;
  isReverseSort?: boolean;
  grid?: string;
  headings?: Heading[];
  renderData?: Function;
  limit?: number;
}

const Departures: React.FC<Props> = (props: Props) => {
  const { departures, loading, error } = useDepartureFlights();
  const cargoData = new CargoDeparturesData({ flights: departures });
  const { refreshedAt } = useRefreshedAt(loading);
  const passengerDepartures = departures.filter(
    dep => cargoData?.flights.findIndex(cf => cf.id === dep.id) === -1
  );

  const allFlights = [...passengerDepartures, ...cargoData?.flights].map(flight => {
    const airportCode = flight?.destination;
    const airportCity = getAirportCity(airportCode);

    return {
      ...flight,
      city: airportCity,
      flightNum: parseInt(flight?.flightNumber),
      time: flight?.estimatedDepartureDate || flight?.scheduledDepartureDate,
    };
  });

  const sortedFlights = sortBy(allFlights, props.sortByColumn || '');

  let allSortedFlights;
  if (props?.isReverseSort) {
    allSortedFlights = sortedFlights.reverse();
  } else {
    allSortedFlights = sortedFlights;
  }
  return (
    <QueryCell content="departures" data={passengerDepartures} error={error} loading={loading}>
      <DepartureFlights flights={allSortedFlights} headings={props?.headings} {...props} />
      <Box mt="base">
        <UpdatedAt date={refreshedAt} loading={loading} />
      </Box>
    </QueryCell>
  );
};

export default Departures;
