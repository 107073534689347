const HOUR = 60 * 60 * 1000;
const MINUTE = 60 * 1000;
const SECOND = 1000;

function timeObject(time: number): {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
} {
  const hours = Math.floor(time / HOUR);
  const minutes = Math.floor((time % HOUR) / MINUTE);
  const seconds = Math.ceil((time % MINUTE) / SECOND);
  const milliseconds = time % SECOND;
  return { hours, minutes, seconds, milliseconds };
}

export default function formatTime(time: number): string {
  const { minutes } = timeObject(time);
  return `${minutes} min`;
}
