import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import { withTheme } from 'styled-components';

import icons from 'config/icons';

import { Icon as StyledIcon } from 'styled';
import { SystemProps } from 'styled/lib/types';

const DEFAULT_ICON_SIZE = 20;
const DEFAULT_VIEWBOX_SIZE = 24;

export interface Props extends SystemProps {
  name: string;
  color?: string;
  fill?: string;
  height?: string;
  size?: string;
  theme?: object;
  width?: string;
  flexShrink?: number;
  onClick?: Function;
  stroke?: string;
  cursor?: string;
}

export const Icon: React.FC<Props> = ({ color, fill, height, name, size, width, ...rest }) => {
  const newWidth = size || width || DEFAULT_ICON_SIZE;
  const newHeight = size || height || DEFAULT_ICON_SIZE;
  const iconName = name || icons[0];
  const icon = icons[iconName];

  return (
    <StyledIcon
      as="svg"
      width={newWidth}
      height={newHeight}
      viewBox={`0 0 ${DEFAULT_VIEWBOX_SIZE} ${DEFAULT_VIEWBOX_SIZE}`}
      fill={themeGet(`colors.${fill}`)(rest) || 'none'}
      stroke={themeGet(`colors.${color}`)(rest) || 'currentColor'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      dangerouslySetInnerHTML={{ __html: icon }}
      {...rest}
    />
  );
};

export default withTheme(Icon);
