// @ts-nocheck
import { isSameDay, parseISO } from 'date-fns';
import max from 'lodash/max';
import min from 'lodash/min';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import upperCase from 'lodash/upperCase';

import { Baggage, BaggageData } from 'client/Baggage/types';

import { filterByDayRange } from 'statistics/charts/utils';
import { STAT_TYPES } from 'statistics/stats/constants';
import { BaggageArea, BaggageAreaKey, ChartDatum, DataObject, StatsData } from 'statistics/types';

import { DetailsData, ProcessedDataObject } from './utils';

interface BaggageDatum {
  date: string;
  total: number;
  description: string;
}

function getChartData(data: BaggageDatum[]): ChartDatum[] {
  const baggageData = data.map(
    (baggage: BaggageDatum): ChartDatum => ({
      x: parseISO(baggage.date).getTime(),
      y: baggage.total,
      name: 'baggage',
    })
  );
  return sortBy(baggageData, 'x');
}

function getStatsData(data: BaggageDatum[], area: BaggageArea): StatsData {
  const totals: { [key: string]: number } = {};
  data.forEach((baggage: BaggageDatum) => {
    if (!(baggage.date in totals)) {
      totals[baggage.date] = 0;
    }

    totals[baggage.date] += baggage.total;
  });

  const values = Object.values(totals);

  const lowest = min(values) || 0;
  const highest = max(values) || 0;
  const average = values.length
    ? sum(
        values.map(v => {
          if (isNaN(v)) return 0;
          return v;
        })
      ) / values.length
    : 0;

  const highestContext = Object.keys(totals).find(key => totals[key] === highest);
  const lowestContext = Object.keys(totals).find(key => totals[key] === lowest);

  let key: BaggageAreaKey = upperCase(area).split(' ').join('_') as BaggageAreaKey;

  if (key === 'ML_1_READ_RATE') key = 'ML1_READ_RATE';
  else if (key === 'ML_2_READ_RATE') key = 'ML2_READ_RATE';
  else if (key === 'CB_1_READ_RATE') key = 'CB1_READ_RATE';

  return {
    [STAT_TYPES.BAGGAGE[key].AVERAGE]: { value: average, contextLabel: `All ${area}` },
    [STAT_TYPES.BAGGAGE[key].HIGHEST]: { value: highest, contextLabel: highestContext },
    [STAT_TYPES.BAGGAGE[key].LOWEST]: { value: lowest, contextLabel: lowestContext },
  };
}

function getDetailsData(data: Baggage[]): DetailsData {
  return data;
}

function getFilteredData(data: Baggage[], area: BaggageArea): BaggageDatum[] {
  const cbra = data
    .map((baggage: Baggage) =>
      baggage.data.filter(
        (datum: BaggageData) => datum.area.split('.').shift() === 'CBRA_Area_Statistics'
      )
    )
    // Removing the empty arrays and grabbing the nested array.
    .filter(array => array.length)
    .flat();

  const der: { [key: string]: BaggageData[] } = {};
  ['ML1', 'ML2', 'CB1'].forEach(array => {
    der[array] = data
      .map((baggage: Baggage) =>
        baggage.data.filter((datum: BaggageData) => datum.area === `Day_End_Report.ATR_${array}`)
      )
      // Removing the empty arrays and grabbing the nested array.
      .filter(array => array.length)
      .flat();
  });

  const getBagsData = (
    date: string
  ): {
    bagsCleared: number;
    bagsReceived: number;
    readRateML1: number;
    readRateML2: number;
    readRateCB1: number;
  } => {
    const bagsReceived = cbra?.find(
      (datum: BaggageData) =>
        datum.key === 'Bags_Received' && isSameDay(new Date(datum.date), new Date(date))
    );
    const bagsCleared = cbra?.find(
      (datum: BaggageData) =>
        datum.key === 'Bags_Cleared' && isSameDay(new Date(datum.date), new Date(date))
    );
    const readRateML1 = der['ML1']?.find(
      (datum: BaggageData) =>
        datum.key === 'Read_Rate_%' && isSameDay(new Date(datum.date), new Date(date))
    );
    const readRateML2 = der['ML2']?.find(
      (datum: BaggageData) =>
        datum.key === 'Read_Rate_%' && isSameDay(new Date(datum.date), new Date(date))
    );
    const readRateCB1 = der['CB1']?.find(
      (datum: BaggageData) =>
        datum.key === 'Read_Rate_%' && isSameDay(new Date(datum.date), new Date(date))
    );

    return {
      bagsCleared: Number(bagsCleared?.value),
      bagsReceived: Number(bagsReceived?.value),
      readRateML1: Number(readRateML1?.value.replace('%', '') || 0),
      readRateML2: Number(readRateML2?.value.replace('%', '') || 0),
      readRateCB1: Number(readRateCB1?.value.replace('%', '') || 0),
    };
  };

  switch (area) {
    case 'B-Bags':
      return data.map(
        (baggage: Baggage): BaggageDatum => ({
          date: baggage.date,
          description: 'B-Bags',
          total: baggage.bBags,
        })
      );
    case 'T-Drive':
      return data.map(
        (baggage: Baggage): BaggageDatum => ({
          date: baggage.date,
          description: 'T-Drive',
          total: baggage.tDrive,
        })
      );
    case 'Total':
      return data.map(
        (baggage: Baggage): BaggageDatum => ({
          date: baggage.date,
          description: 'Total',
          total: baggage.totalScreened,
        })
      );
    case 'Bags Received':
      return data.map(
        (baggage: Baggage): BaggageDatum => ({
          date: baggage.date,
          description: 'Bags Received',
          total: getBagsData(baggage.date).bagsReceived,
        })
      );
    case 'Bags Cleared':
      return data.map(
        (baggage: Baggage): BaggageDatum => ({
          date: baggage.date,
          description: 'Bags Cleared',
          total: getBagsData(baggage.date).bagsCleared,
        })
      );
    case 'ML1 Read Rate %':
      return data.map(
        (baggage: Baggage): BaggageDatum => ({
          date: baggage.date,
          description: 'Read Rate %',
          total: getBagsData(baggage.date).readRateML1,
        })
      );
    case 'ML2 Read Rate %':
      return data.map(
        (baggage: Baggage): BaggageDatum => ({
          date: baggage.date,
          description: 'Read Rate %',
          total: getBagsData(baggage.date).readRateML2,
        })
      );
    case 'CB1 Read Rate %':
      return data.map(
        (baggage: Baggage): BaggageDatum => ({
          date: baggage.date,
          description: 'Read Rate %',
          total: getBagsData(baggage.date).readRateCB1,
        })
      );
    default:
      return [];
  }
}

function getProcessedData(
  baggageData: DataObject,
  dateRangeDays: string[],
  area: BaggageArea
): ProcessedDataObject {
  const { data: baggage, loading, error } = baggageData;
  if (loading || error)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
      details: {
        loading,
        error,
        data: [],
      },
    };

  const _data: Baggage[] = getFilteredData(baggage as Baggage[], area);
  const { byDayRange } = filterByDayRange(dateRangeDays);
  const filteredDataByDayRange = _data.filter((datum: Baggage) => byDayRange(parseISO(datum.date)));
  const filteredDetailsData = (baggage as Baggage[])?.filter((datum: Baggage) =>
    byDayRange(parseISO(datum.date))
  );

  return {
    chart: {
      loading,
      error,
      data: getChartData(filteredDataByDayRange as BaggageDatum[]),
    },
    stats: {
      loading,
      error,
      data: getStatsData(filteredDataByDayRange as BaggageDatum[], area),
    },
    details: {
      loading,
      error,
      data: getDetailsData(filteredDetailsData),
    },
  };
}

export default {
  bBags: {
    getProcessedData: (baggageData: DataObject, dateRangeDays: string[]): ProcessedDataObject =>
      getProcessedData(baggageData, dateRangeDays, 'B-Bags'),
  },
  tDrive: {
    getProcessedData: (baggageData: DataObject, dateRangeDays: string[]): ProcessedDataObject =>
      getProcessedData(baggageData, dateRangeDays, 'T-Drive'),
  },
  total: {
    getProcessedData: (baggageData: DataObject, dateRangeDays: string[]): ProcessedDataObject =>
      getProcessedData(baggageData, dateRangeDays, 'Total'),
  },
  bagsReceived: {
    getProcessedData: (baggageData: DataObject, dateRangeDays: string[]): ProcessedDataObject =>
      getProcessedData(baggageData, dateRangeDays, 'Bags Received'),
  },
  bagsCleared: {
    getProcessedData: (baggageData: DataObject, dateRangeDays: string[]): ProcessedDataObject =>
      getProcessedData(baggageData, dateRangeDays, 'Bags Cleared'),
  },
  readRateML1: {
    getProcessedData: (baggageData: DataObject, dateRangeDays: string[]): ProcessedDataObject =>
      getProcessedData(baggageData, dateRangeDays, 'ML1 Read Rate %'),
  },
  readRateML2: {
    getProcessedData: (baggageData: DataObject, dateRangeDays: string[]): ProcessedDataObject =>
      getProcessedData(baggageData, dateRangeDays, 'ML2 Read Rate %'),
  },
  readRateCB1: {
    getProcessedData: (baggageData: DataObject, dateRangeDays: string[]): ProcessedDataObject =>
      getProcessedData(baggageData, dateRangeDays, 'CB1 Read Rate %'),
  },
};
