import { useEffect, useState } from 'react';

function useSidebar() {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [collapsed]);

  function handleToggle(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    setCollapsed(!collapsed);
  }

  return { collapsed, handleToggle };
}

export default useSidebar;
