import React from 'react';
import { useHistory } from 'react-router-dom';

import routes from 'config/routes';

import { Button } from 'components';

import { Box } from 'styled';

interface Props {
  message?: string;
  showActions?: boolean;
  buttonText?: string;
}

const defaults = {
  message: `
    We cannot sign you in due to an error that ocurred. This has been reported to our
    error-monitoring service and we are looking into it.
  `,
  buttonText: 'Try Again',
};

const AuthErrorMessage: React.FC<Props> = ({ message, showActions = true, buttonText }: Props) => (
  <>
    <Box as="p" color="subdued" fontSize="l" mb="l">
      {message || defaults.message}
    </Box>
    {showActions ? <TryAgainButton buttonText={buttonText} /> : null}
  </>
);

interface TryAgainButtonProps {
  buttonText?: string;
}

const TryAgainButton: React.FC<TryAgainButtonProps> = ({ buttonText }: TryAgainButtonProps) => {
  const history = useHistory();

  function handleClick(event: React.MouseEvent): void {
    event.preventDefault();
    history.push(routes.login);
  }

  return (
    <Button onClick={handleClick} variant="danger">
      {buttonText || defaults.buttonText}
    </Button>
  );
};

AuthErrorMessage.displayName = 'AuthErrorMessage';

export default AuthErrorMessage;
