import React from 'react';

import { useDelays } from 'client/Delay';

import { DefaultEmptyMessage, DefaultErrorMessage, DefaultLoader } from 'components/QueryCell';
import { Box, TileContent, TileContentInner } from 'styled';

import Weather from './Weather';
import { Item } from './components';
import { getAirportDelays } from './utils';
import { useWeather } from 'data/Weather';
import { Weather as WeatherType } from 'data/Weather/types';

const WeatherDetails: React.FC = () => {
  const weather = useWeather({});
  const delays = useDelays({});

  const loading = weather.loading || delays.loading;
  const error = weather.error || delays.error;

  if (loading) return <DefaultLoader />;
  if (error) return <DefaultErrorMessage />;
  if (!weather?.weather) return <DefaultEmptyMessage content="weather" />;

  const filteredWeatherData = weather?.weather;
  const delaysData = delays.delays;

  return (
    <>
      <TileContent>
        <TileContentInner>
          <Box height="400px" width="100%">
            <Weather zoom={4} />
          </Box>
        </TileContentInner>
      </TileContent>
      <Box
        display="grid"
        justifyContent="center"
        gridColumnGap="l"
        gridRowGap="l"
        gridTemplateColumns={{ _: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(5, 1fr)' }}
        py="xl"
      >
        {filteredWeatherData.map((w: WeatherType) => (
          <Item
            key={w.airport.id}
            weatherData={w}
            delaysData={getAirportDelays(w.airport, delaysData)}
          />
        ))}
      </Box>
    </>
  );
};

export default WeatherDetails;
