import { gql, useMutation } from '@apollo/client';

interface Settings {
  threshold: number;
  frequency: number;
}

interface AlertSettings {
  bathrooms: Settings;
  feedback: Settings;
  parking: Settings;
  scans: Settings;
  security: Settings;
}

const UPDATE_ALERT_SETTINGS = gql`
  mutation UpdateAlertSettings($settings: AlertSettingPayload) {
    updateAlertSettings(settings: $settings) {
      scans_threshold
      scans_frequency
      parking_threshold
      parking_frequency
      security_threshold
      security_frequency
      bathrooms_threshold
      bathrooms_frequency
      feedback_threshold
      feedback_frequency
      updatedAt
    }
  }
`;

export default function useUpdateAlertSettings(settings = {}) {
  return useMutation(UPDATE_ALERT_SETTINGS, settings);
}
