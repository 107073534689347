import React from 'react';

import { Icon } from 'components';

import { Box, LinkButton as StyledLinkButton } from 'styled';
import { SystemProps } from 'styled/lib/types';

interface Props extends SystemProps {
  disabled?: boolean;
  onClick?: Function;
  size?: string;
  variant?: string;
  arrow?: string;
}

const LinkButton: React.FC<Props> = ({ disabled, size, variant, arrow, ...rest }) => {
  switch (arrow) {
    case 'left':
      return (
        <StyledLinkButton disabled={disabled} size={size} variant={variant} {...rest}>
          <Box as={Icon} name="chevronLeft" marginRight="xs" />
          {rest.children}
        </StyledLinkButton>
      );
    case 'right':
      return (
        <StyledLinkButton disabled={disabled} size={size} variant={variant} {...rest}>
          {rest.children}
          <Box as={Icon} name="chevronRight" marginLeft="xs" />
        </StyledLinkButton>
      );
    default:
      return <StyledLinkButton disabled={disabled} size={size} variant={variant} {...rest} />;
  }
};

export default LinkButton;
