import sortBy from 'lodash/sortBy';
import React from 'react';

import { CargoArrivalsData } from 'utils/arrivals';

import { useArrivalFlights } from 'data/Flights';
import { ArrivalFlight } from 'data/Flights/types';

import { useRefreshedAt } from 'hooks';

import { QueryCell, UpdatedAt } from 'components';

import FlightSummary from './Arrivals';

interface Props {
  filters?: { types: string[] };
}

const ArrivalSummary: React.FC<Props> = ({ filters }: Props) => {
  const { arrivals, loading, error } = useArrivalFlights();

  const filter = (flight: ArrivalFlight): boolean => {
    const isValidCargoType = filters?.types?.includes('cargo') && flight?.weight;
    const isValidPassengerType =
      filters?.types?.includes('passenger') && (flight?.arrivalGate || flight?.isPassenger);
    const isValidFlightType = !!(isValidCargoType || isValidPassengerType);
    return isValidFlightType;
  };

  const { refreshedAt } = useRefreshedAt(loading);
  const cargoData = new CargoArrivalsData({ flights: arrivals });

  const passengerArrivals = arrivals.filter(
    arr => cargoData?.flights.findIndex(cf => cf.id === arr.id) === -1
  );

  const sortedArrivals = sortBy(
    [...passengerArrivals, ...cargoData?.flights],
    [
      (arrival: ArrivalFlight): Date =>
        new Date(
          arrival.actualArrivalDate || arrival.estimatedArrivalDate || arrival.scheduledArrivalDate
        ),
    ]
  );

  return (
    <>
      <QueryCell content="arrivals" data={passengerArrivals} error={error} loading={loading}>
        <FlightSummary flights={sortedArrivals} filter={filter} />
        <UpdatedAt date={refreshedAt} loading={loading} />
      </QueryCell>
    </>
  );
};

export default ArrivalSummary;
