import { gql, useQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { DeparturesArgs, CapacityDeparturesQuery } from '../../data/Flights/types';

const CAPACITY_DEPARTURES = gql`
  query CapacityDepartures($between: [DateTime]!) {
    capacityDepartures(between: $between) {
      id
      airline {
        name
        icao
        iata
      }
      airport {
        city
        state
        code
      }
      bookings
      capacityData {
        capacity
        occupancy
        load
      }
      departureDate
      departureDelay
      departureGate
      departureTime
      estimatedDepartureDate
      flightNumber
      isPassenger
      scheduledDepartureDate
      statusText
    }
  }
`;

const now = new Date();
const start = startOfDay(now);
const end = endOfDay(now);

export default function useCapacityDepartures({
  between = [start.getTime(), end.getTime()],
  refresh = true,
  skip = false,
}: DeparturesArgs = {}): CapacityDeparturesQuery {
  const variables = pickBy(
    {
      between,
    },
    identity
  );

  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(CAPACITY_DEPARTURES, options);
  const departures = query?.data?.capacityDepartures || [];

  return {
    departures,
    ...query,
  };
}
