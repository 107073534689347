import { endOfDay, getTime, startOfDay } from 'date-fns';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { ALERT_AUTO_CLOSE_DURATION } from 'config/alerts';

import { useAlerts as useAlertsData } from 'data/Alert';
import { Alert } from 'data/Alert/types';
import { useUpdateUserMetadata } from 'client/User';

import { useMetadata } from 'hooks';
import { getAirportTime } from 'utils';

interface Action {
  type?: string;
  payload?: State;
}
interface ManagerProps {
  renderComponent: Function;
}

export interface State {
  activeAlert?: Alert;
  alerts?: Alert[];
  error?: object;
  lastSeenTimestamp?: number | undefined;
  loading?: boolean;
  pastAlerts?: Alert[];
}

const initialState: State = {
  activeAlert: undefined,
  alerts: [],
  error: undefined,
  lastSeenTimestamp: undefined,
  loading: false,
  pastAlerts: [],
};

const AlertsStateContext = createContext<State>(initialState);
const AlertsDispatchContext = createContext<React.Dispatch<Action> | null>(null);

const today = getAirportTime(new Date());
const todayStart = getTime(startOfDay(today));
const todayEnd = getTime(endOfDay(today));

const actionTypes = {
  update: 'update',
  updateTimestamp: 'updateTimestamp',
  showActiveAlert: 'showActiveAlert',
  hideActiveAlert: 'hideActiveAlert',
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case actionTypes.update: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.updateTimestamp: {
      return {
        ...state,
        lastSeenTimestamp: Date.now(),
      };
    }
    case actionTypes.showActiveAlert: {
      return {
        ...state,
        activeAlert: {
          ...action.payload?.alerts?.[0],
        },
      };
    }
    case actionTypes.hideActiveAlert: {
      return {
        ...state,
        activeAlert: {
          ...initialState.activeAlert,
        },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const updateTimestamp = () => ({ type: actionTypes.updateTimestamp });

const AlertsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { metadata } = useMetadata('alerts');
  const [updateMetadata] = useUpdateUserMetadata();

  // Get current day's alerts (full day for history)
  // TODO On date change, we will 'lose' history. What to do there?
  const alertsQuery = useAlertsData({
    between: [todayStart, todayEnd],
    order: [['createdAt', 'DESC']],
  });
  const alertsQueryData: Alert[] = alertsQuery.alerts || [];

  // Initial update to lastSeenTimestamp
  useEffect(() => {
    if (metadata?.lastSeenTimestamp) {
      dispatch({
        type: actionTypes.update,
        payload: { lastSeenTimestamp: Number(metadata.lastSeenTimestamp) },
      });
    }
  }, [metadata]);

  // Add alerts to state
  useEffect(() => {
    if (state.lastSeenTimestamp) {
      const alerts = alertsQueryData?.filter(alert => alert?.isActive);
      const pastAlerts = alertsQueryData?.filter(alert => !alert.isActive)?.slice(0, 10);

      const payload = {
        alerts,
        pastAlerts,
      };

      // Only update if alerts or pastAlerts has changed compared to what we have in state
      const hasAlertsChanged =
        JSON.stringify(state?.alerts?.[0]?.description) !==
        JSON.stringify(alerts?.[0]?.description);
      const hasAlerts = alerts?.length;
      const hasPastAlertsChanged = JSON.stringify(state.pastAlerts) !== JSON.stringify(pastAlerts);

      // Show active alert popup for new alert
      if (hasAlertsChanged && hasAlerts) dispatch({ type: actionTypes.showActiveAlert, payload });

      // Update state with new alert changes
      if (hasAlertsChanged || hasPastAlertsChanged) dispatch({ type: actionTypes.update, payload });
    }
  }, [alertsQueryData, state]);

  // Update user's metadata timestamp
  useEffect(() => {
    if (state.lastSeenTimestamp) {
      updateMetadata({
        variables: {
          key: 'alerts',
          value: JSON.stringify({ lastSeenTimestamp: state.lastSeenTimestamp }),
        },
      });
    }
  }, [state.lastSeenTimestamp, updateMetadata]);

  // Update loading / error statuses
  useEffect(() => {
    const payload = {
      loading: alertsQuery.loading,
      error: alertsQuery.error,
    };
    if (state.loading !== payload.loading || state.error !== payload.error)
      dispatch({ type: actionTypes.update, payload });
  }, [alertsQuery.loading, alertsQuery.error, state.error, state.loading]);

  return (
    <AlertsStateContext.Provider value={state}>
      <AlertsDispatchContext.Provider value={dispatch}>{children}</AlertsDispatchContext.Provider>
    </AlertsStateContext.Provider>
  );
};

function useAlertsState() {
  const context = useContext(AlertsStateContext);
  if (context === undefined) {
    throw new Error(`useAlertsState must be used within a AlertsProvider`);
  }
  return context;
}

function useAlertsDispatch() {
  const context = useContext(AlertsDispatchContext);
  if (context === undefined) {
    throw new Error(`useAlertsDispatch must be used within a AlertsProvider`);
  }
  return context;
}

function useAlerts() {
  const context: [State, React.Dispatch<Action>] = [
    useAlertsState(),
    useAlertsDispatch() as React.Dispatch<Action>,
  ];
  if (context === undefined) {
    throw new Error(`useAlerts must be used within a AlertsProvider`);
  }
  return context;
}

const hideActiveAlert = (): Action => ({
  type: actionTypes.hideActiveAlert,
});

const showActiveAlert = (): Action => ({
  type: actionTypes.showActiveAlert,
});

const ActiveAlertsManager: React.FC<ManagerProps> = ({ renderComponent }) => {
  const [state, dispatch] = useAlerts();

  useEffect(() => {
    if (!state?.activeAlert?.description) return;
    const timeout = setTimeout(() => {
      dispatch(hideActiveAlert());
    }, ALERT_AUTO_CLOSE_DURATION);
    return (): void => clearTimeout(timeout);
  }, [state, dispatch]);

  if (!state?.activeAlert || !state?.activeAlert?.description) return null;

  return renderComponent(state);
};

export {
  AlertsProvider as default,
  ActiveAlertsManager,
  hideActiveAlert,
  showActiveAlert,
  useAlerts,
  useAlertsDispatch,
  useAlertsState,
  updateTimestamp,
};
