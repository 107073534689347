import React from 'react';

import { useCargoRevenueArrivals } from 'data/Cargo';
import { useRefreshedAt } from 'hooks';

import { QueryCell, UpdatedAt } from 'components';
import { Heading } from 'components/Table';

import CargoRevenueArrivalsTable from './components/CargoRevenueArrivalsTable';
export interface Data {
  airline: React.ReactNode;
  operations: React.ReactNode;
  weight: React.ReactNode;
  cost: React.ReactNode;
  weightNum: number;
  costNum: number;
}

interface Props {
  grid?: string;
  headings?: Heading[];
  sortByColumn?: string;
  isReverseSort?: boolean;
  renderExternalData?: Function;
  renderExternalDataTotal?: Function;
}

const CargoArrivalRevenue: React.FC<Props> = (props: Props) => {
  const { airlines, totalOperations, totalWeight, totalCost, loading, error } =
    useCargoRevenueArrivals();
  const { refreshedAt } = useRefreshedAt(loading);

  return (
    <QueryCell content="arrivals" data={airlines} error={error} loading={loading}>
      <CargoRevenueArrivalsTable
        data={{ airlines, totalOperations, totalWeight, totalCost }}
        {...props}
      />
      <UpdatedAt date={refreshedAt} loading={loading} />
    </QueryCell>
  );
};

export default CargoArrivalRevenue;
