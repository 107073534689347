import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import styled from 'styled-components';

import { Tooltip } from 'components';

import { Box, TextLabel } from 'styled';
import { SystemProps } from 'styled/lib/types';

interface Props {
  occupancy: number;
  capacity: number;
  load: number;
  booking?: number;
  scans?: number;
}

const MINIMUM_LOAD_PERCENTAGE = 5;

const CapacityStatus: React.FC<Props> = ({ occupancy, capacity, load, scans }: Props) => {
  function getStatus(): string {
    if (occupancy === capacity) return 'full';
    if (occupancy === 0) return 'empty';
    return 'under';
  }

  function getColor(): string {
    const status = getStatus();

    switch (status) {
      case 'full': {
        return 'success';
      }
      case 'empty': {
        return 'error';
      }
      default: {
        return 'warning';
      }
    }
  }

  const color = getColor();
  const showProgressBar = load >= MINIMUM_LOAD_PERCENTAGE;
  const progressLoad = load > 100 ? 100 : load;

  return (
    <>
      <Tooltip label={scans ? `Scans: ${scans.toLocaleString()}` : ''}>
        <Box alignItems="center" display="flex">
          <TextLabel color={color} display="inline">
            {occupancy}
          </TextLabel>
          <Box as="span" color="subdued" fontSize="s" mx="2px">
            /
          </Box>
          <TextLabel color={color} display="inline">
            {capacity}
          </TextLabel>
        </Box>
      </Tooltip>
      {showProgressBar ? <ProgressBar width={`${progressLoad}%`} color={color} /> : null}
    </>
  );
};

const ProgressBar = styled.div.attrs((props: SystemProps) => ({
  style: {
    width: props.width,
  },
}))<SystemProps>`
  background-color: rgba(42, 43, 56, 0.5);
  border-bottom: 1px solid ${props => themeGet(`colors.${props.color}`)(props)};
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
`;

export default CapacityStatus;
