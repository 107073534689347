import React from 'react';

import { Icon, Tooltip } from 'components';

import { Box } from 'styled';

interface Props {
  direction: string;
  value: number;
  suffix: string;
  interval: string;
}

const Trend: React.FC<Props> = ({ direction, value, suffix, interval }: Props) => {
  const positive = direction === 'positive';
  const iconName = positive ? 'arrowUpNoCircle' : 'arrowDownNoCircle';
  const color = positive ? 'error' : 'success';

  return (
    <Box>
      <Tooltip label={`Over past ${interval}`}>
        <Icon name={iconName} color={color} />{' '}
        <Box as="span" color={color} fontWeight="bold">{`${value}${suffix}`}</Box>
      </Tooltip>
    </Box>
  );
};

export default Trend;
