import React from 'react';

import { Icon } from 'components';

import {
  Sidebar as StyledSidebar,
  SidebarContent,
  SidebarContentInner,
  SidebarToggle,
} from 'styled';

interface Props {
  bg: string;
  collapsed: boolean;
  onToggle: Function;
}

const Sidebar: React.FC<Props> = ({ children, collapsed, onToggle, bg }) => {
  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onToggle && onToggle(event);
  }

  return (
    <StyledSidebar>
      <SidebarToggle onClick={handleClick}>
        {collapsed ? <Icon name="chevronRight" size="30" /> : <Icon name="chevronLeft" size="30" />}
      </SidebarToggle>
      <SidebarContent collapsed={collapsed} bg={bg}>
        <SidebarContentInner collapsed={collapsed}>{children}</SidebarContentInner>
      </SidebarContent>
    </StyledSidebar>
  );
};

export default Sidebar;
