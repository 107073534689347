import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { SystemProps } from './lib/types';

const primary = css`
  background: linear-gradient(
    to bottom,
    ${themeGet('colors.secondary')} -22.11%,
    rgba(18, 71, 123, 0.626694) 38.17%,
    rgba(54, 54, 54, 0) 100%
  );
`;

const dark = css`
  background: linear-gradient(
    180deg,
    ${themeGet('colors.neutralsGradient.10')} 0%,
    ${themeGet('colors.neutralsGradient.9')} 58%,
    ${themeGet('colors.neutralsGradient.11')} 100%
  );
`;

type GradientProps = {
  type: string;
};
const BackgroundGradient = styled.div<SystemProps & GradientProps>`
  ${props => (props.type === 'primary' ? primary : dark)}
`;

export default BackgroundGradient;
