import { differenceInHours, format as dateFormat, isToday } from 'date-fns';
import React from 'react';

import theme from 'config/theme';
import { formatAirportTime, removeHtmlEntities } from 'utils';
import { useCustomerFeedback, useUpdateFeedback } from 'client/CustomerFeedback';
import { CustomerFeedback as CustomerFeedbackData } from 'client/CustomerFeedback/types';

import { useRefreshedAt } from 'hooks';

import { showSuccess, useToastDispatch } from 'providers/ToastProvider';

import {
  Icon,
  MediaGrid,
  Menu,
  MultipleCircularLoading,
  QueryCell,
  RequiredPermission,
  UpdatedAt,
} from 'components';
import { TriggerArgs } from 'components/Menu';

import { Box, LinkBox, Title, Uppercase } from 'styled';

interface Props {
  filter?: Function;
  fullscreen?: boolean;
  isAttachmentShown?: boolean;
  label?: string;
}

const SENTIMENT_COLORS: { [key: string]: string } = {
  positive: theme.colors.success,
  negative: theme.colors.error,
  neutral: theme.colors.neutralsGradient[6],
};

const CustomerFeedback: React.FC<Props> = ({
  fullscreen = false,
  filter,
  isAttachmentShown = true,
}) => {
  const { refreshedAt } = useRefreshedAt(false);
  const toastDispatch = useToastDispatch();
  const [updateFeedback] = useUpdateFeedback({
    onCompleted: () => toastDispatch(showSuccess('Feedback updated!')),
  });
  const lastChecked = formatAirportTime(refreshedAt, 'hh:mm a');
  const { customerFeedback: customerFeedbackData, loading, error } = useCustomerFeedback();
  if (process.env.REACT_APP_CONFIG_SALES !== 'true') return null;
  const filteredCustomerFeedbackData = filter?.(customerFeedbackData) || customerFeedbackData;

  const formatTime = (time: Date) => {
    const formattedTime = dateFormat(time, 'p');
    const formattedDate = dateFormat(time, 'PP');
    return `${formattedTime} ・ ${formattedDate.toUpperCase()}`;
  };

  const calculatePercentage = () => {
    const sentiments: { [key: string]: number } = {
      positiveTotal: 0,
      positivePercentage: 0,
      positiveRoundedPercentage: 0,
      negativeTotal: 0,
      negativePercentage: 0,
      negativeRoundedPercentage: 0,
      neutralTotal: 0,
      neutralPercentage: 0,
      neutralRoundedPercentage: 0,
    };

    // Get count of occurance for each sentiment (if sentiment is null, assume neutral)
    customerFeedbackData.forEach(feedback => {
      switch (feedback.sentiment) {
        case 'positive':
          sentiments.positiveTotal++;
          break;
        case 'negative':
          sentiments.negativeTotal++;
          break;
        case 'neutral':
          sentiments.neutralTotal++;
          break;
        case null:
          sentiments.neutralTotal++;
          break;
        default:
          break;
      }
    });

    //Calculate percentage with decimals for each sentiment
    const totalSentiments =
      sentiments.positiveTotal + sentiments.negativeTotal + sentiments.neutralTotal;
    sentiments.positivePercentage = (sentiments.positiveTotal / totalSentiments) * 100;
    sentiments.negativePercentage = (sentiments.negativeTotal / totalSentiments) * 100;
    sentiments.neutralPercentage = (sentiments.neutralTotal / totalSentiments) * 100;

    //Round down percentages
    sentiments.positiveRoundedPercentage = Math.floor(sentiments.positivePercentage);
    sentiments.negativeRoundedPercentage = Math.floor(sentiments.negativePercentage);
    sentiments.neutralRoundedPercentage = Math.floor(sentiments.neutralPercentage);

    //Make rounded percentages add up to 100% using Largest Remainder method
    //Get difference of 100% and sum of rounded percentages
    const roundedPercentageRemainder =
      100 -
      sentiments.positiveRoundedPercentage -
      sentiments.negativeRoundedPercentage -
      sentiments.neutralRoundedPercentage;

    //Sort percentages by largest decimal remainder
    const remainders = [
      sentiments.positivePercentage,
      sentiments.negativePercentage,
      sentiments.neutralPercentage,
    ].sort((a, b) => {
      return b - Math.floor(b) - (a - Math.floor(a));
    });

    //Add 1 to percentage with the largest decimal remainder until percentages adds up to 100
    for (let i = 0; i < roundedPercentageRemainder; i++) {
      const percentWithLargestRemainder = remainders.shift();
      if (percentWithLargestRemainder === sentiments.positivePercentage) {
        sentiments.positiveRoundedPercentage++;
      } else if (percentWithLargestRemainder === sentiments.negativePercentage) {
        sentiments.negativeRoundedPercentage++;
      } else if (percentWithLargestRemainder === sentiments.neutralPercentage) {
        sentiments.neutralRoundedPercentage++;
      }
    }

    return sentiments;
  };

  const feedbackCard = (feed: CustomerFeedbackData, i: number) => {
    const time = new Date(feed.date);
    const color = SENTIMENT_COLORS[feed.sentiment] || SENTIMENT_COLORS['neutral']; //if sentiment is null, assume neutral
    const filteredMedia = feed?.attachments?.filter(attachment => attachment?.previewUrl);
    const attachmentsCount = filteredMedia.length;
    const body = removeHtmlEntities(feed.body);

    const renderAttachmentCount = () => {
      if (!attachmentsCount) return null;
      return (
        <Box display="flex" alignItems="center" ml="s">
          <Icon name="paperClip" mr="xs" />
          {attachmentsCount}
        </Box>
      );
    };

    const menu = (
      <Menu
        side="right"
        width="10rem"
        renderTrigger={({ getTriggerProps }: TriggerArgs) => {
          return (
            <Box as="a" href="#0" {...getTriggerProps()}>
              <Icon name="ellipsis" />
            </Box>
          );
        }}
        options={[
          {
            label: 'Positive',
            icon: { name: 'smile' },
            action: (event: React.MouseEvent) => {
              event.preventDefault();
              updateFeedback({ variables: { id: feed?.id, sentiment: 'positive' } });
            },
          },
          {
            label: 'Neutral',
            icon: { name: 'meh' },
            action: (event: React.MouseEvent) => {
              event.preventDefault();
              updateFeedback({ variables: { id: feed?.id, sentiment: 'neutral' } });
            },
          },
          {
            label: 'Negative',
            icon: { name: 'frown' },
            action: (event: React.MouseEvent) => {
              event.preventDefault();
              updateFeedback({ variables: { id: feed?.id, sentiment: 'negative' } });
            },
          },
        ]}
      />
    );

    return (
      <LinkBox href={feed?.link} target="_blank" key={`${feed.id}-${i}`}>
        <Box borderBottom="1px solid" borderColor="neutralsGradient.10" paddingY="m">
          <Box
            display="flex"
            flexDirection="column"
            borderLeft="2px solid"
            borderColor={color}
            pl="s"
          >
            <Box textAlign="left" marginBottom="s">
              {body}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb="s"
              color="neutralsGradient.4"
            >
              <Box display="flex" justifyContent="space-between" width="100%" textAlign="start">
                <Box display="flex" alignItems="center">
                  <Icon name="twitter" mr="xs" />
                  {formatTime(time)}
                  {attachmentsCount && renderAttachmentCount()}
                </Box>
                <RequiredPermission permission="update:customer-feedback">
                  {menu}
                </RequiredPermission>
              </Box>
            </Box>

            {isAttachmentShown && (
              <Box display="flex" alignItems="center" pr="xs">
                <MediaGrid media={filteredMedia || []} height="290px" />
              </Box>
            )}
          </Box>
        </Box>
      </LinkBox>
    );
  };

  const renderFeedTraffic = () => {
    let pastHour = 0;
    let pastDay = 0;
    customerFeedbackData?.forEach(data => {
      if (isToday(new Date(data?.date))) pastDay++;
      if (differenceInHours(new Date(), new Date(data?.date)) < 1) pastHour++;
    });

    return (
      <Box
        display="flex"
        flexDirection="column"
        border="1px solid"
        borderColor="neutralsGradient.7"
        borderRadius="base"
        py="base"
        px="m"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Title fontSize="xl" fontWeight="normal">
            Tweets
          </Title>
          <Box as="p" fontSize="s" fontWeight="light" color="neutralsGradient.3">
            <Uppercase>Last Checked</Uppercase> {lastChecked}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-around">
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box as="p" fontSize="66px" fontWeight="bold" textAlign="center" lineHeight="base">
              {pastHour}
            </Box>
            <Box as="p" fontSize="l" fontWeight="light" color="neutralsGradient.3">
              <Uppercase>Past Hour</Uppercase>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box as="p" fontSize="66px" fontWeight="bold" textAlign="center" lineHeight="base">
              {pastDay}
            </Box>
            <Box as="p" fontSize="l" fontWeight="light" color="neutralsGradient.3">
              <Uppercase>Past Day</Uppercase>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderSentimentCard = () => {
    const { positiveRoundedPercentage, negativeRoundedPercentage, neutralRoundedPercentage } =
      calculatePercentage();
    return (
      <Box
        display="flex"
        flexDirection="column"
        border="1px solid"
        borderColor="neutralsGradient.7"
        borderRadius="base"
        py="base"
        px="m"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="base">
          <Title fontSize="xl" fontWeight="normal">
            Sentiment
          </Title>
          <Box as="p" fontSize="s" fontWeight="light" color="neutralsGradient.3">
            <Uppercase>Last Checked</Uppercase> {lastChecked}
          </Box>
        </Box>
        <Box display="flex" paddingX="64px" justifyContent="center">
          <Box width="40%" minWidth="180px" marginRight="50px">
            <MultipleCircularLoading
              percentages={[
                negativeRoundedPercentage,
                neutralRoundedPercentage,
                positiveRoundedPercentage,
              ]}
              colors={[
                SENTIMENT_COLORS.negative,
                SENTIMENT_COLORS.neutral,
                SENTIMENT_COLORS.positive,
              ]}
              size="185px"
              text="@CVGairport"
            />
          </Box>
          <Box>
            <Box mb="base">
              <Box display="flex" alignItems="center" fontSize="s" fontWeight="light">
                <Icon name="circle" size="8px" color="error" fill="error" mr="xs" />
                <Uppercase>Negative</Uppercase>
              </Box>
              <Box as="p" fontSize="xl" fontWeight="bold" lineHeight="base">
                {negativeRoundedPercentage}%
              </Box>
            </Box>
            <Box marginBottom="base">
              <Box display="flex" alignItems="center" fontSize="s" fontWeight="light">
                <Icon name="circle" size="8px" color="success" fill="success" mr="xs" />
                <Uppercase>Positive</Uppercase>
              </Box>
              <Box as="p" fontSize="xl" fontWeight="bold" lineHeight="base">
                {positiveRoundedPercentage}%
              </Box>
            </Box>
            <Box>
              <Box display="flex" alignItems="center" fontSize="s" fontWeight="light">
                <Icon
                  name="circle"
                  size="8px"
                  color="neutralsGradient.6"
                  fill="neutralsGradient.6"
                  mr="xs"
                />
                <Uppercase>Neutral</Uppercase>
              </Box>
              <Box as="p" fontSize="xl" fontWeight="bold" lineHeight="base">
                {neutralRoundedPercentage}%
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderFeed = () =>
    filteredCustomerFeedbackData.map((data: CustomerFeedbackData, i: number) =>
      feedbackCard(data, i)
    );

  const renderFullScreen = () => {
    return (
      <Box
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        borderTop="1px solid"
        borderColor="neutralsGradient.10"
      >
        <Box pr="l" pt="l">
          {renderFeedTraffic()}
        </Box>
        <Box
          borderX="1px solid"
          borderColor="neutralsGradient.10"
          paddingX="l"
          pt="m"
          overflowY="scroll"
          height="80vh"
        >
          {renderFeed()}
        </Box>
        <Box pl="l" pt="l">
          {renderSentimentCard()}
        </Box>
      </Box>
    );
  };

  const renderTile = () => (
    <Box display="grid" height="100%" gridTemplateRows="1fr auto">
      <Box paddingX="m" overflowY="auto">
        {renderFeed()}
      </Box>
      <UpdatedAt date={refreshedAt} loading={false} />
    </Box>
  );

  return (
    <QueryCell
      content="customer feedback"
      data={customerFeedbackData}
      error={error}
      loading={loading}
    >
      {fullscreen ? renderFullScreen() : renderTile()}
    </QueryCell>
  );
};

export default CustomerFeedback;
