import React, { Suspense, useEffect, useRef } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import routes from 'config/routes';
import { track } from 'utils';

import { useLocalStorage } from 'hooks';

import {
  AppError,
  Callback,
  Dashboard,
  EditIssue,
  Issues,
  Login,
  Logout,
  NotFound,
  Reports,
  Unsubscribe,
} from 'screens';

import { CenteredLoader } from 'components';

import PrivateRoute from './PrivateRoute';

const EXCLUDED_ROUTES = [routes.callback, routes.login, routes.logout, routes.error];

const Router: React.FC = () => {
  const { setValue: setRedirectPath } = useLocalStorage('redirectPath');
  const location = useLocation();
  const prevPath = useRef(location.pathname);

  useEffect(() => {
    track('Page View', {
      page: window.location.pathname,
    });
  }, [location]);

  useEffect(() => {
    const skip = !location.search && !location.hash && prevPath.current === location.pathname;

    if (!skip && !EXCLUDED_ROUTES.includes(location.pathname)) {
      const url = `${location.pathname}${location.search}${location.hash ? location.hash : ''}`;
      setRedirectPath(url);
    }

    prevPath.current = location.pathname;
  }, [location, setRedirectPath]);

  return (
    <Suspense fallback={<CenteredLoader label="Loading..." />}>
      <Switch>
        <PrivateRoute exact path={routes.dashboard} component={Dashboard} />
        <PrivateRoute exact path={routes.pulse} component={Dashboard} />
        <PrivateRoute exact path={routes.reports} component={Reports} />
        <PrivateRoute exact path={routes.issues.index} component={Issues} />
        <PrivateRoute exact path={routes.issues.edit} component={EditIssue} />
        <Route exact path={routes.callback} component={Callback} />
        <Route exact path={routes.login} component={Login} />
        <Route exact path={routes.logout} component={Logout} />
        <Route exact path={routes.error} component={AppError} />
        <Route exact path={routes.unsubscribe} component={Unsubscribe} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default Router;
