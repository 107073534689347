import React from 'react';
import { CircleMarker } from 'react-leaflet';

import theme from 'config/theme';

import { Point } from 'tiles/Airfield/utils';

import { usePopupWatcher } from 'providers/SelectedTableItemProvider';

import NotamPopup from './NotamPopup';

interface DotProps {
  p: Point;
  i: number;
}

const Dot: React.FC<DotProps> = ({ p, i }: DotProps) => {
  const { ref } = usePopupWatcher(p?.notamId);

  return (
    <CircleMarker
      ref={ref}
      key={`${p.code}-${i}`}
      radius={3}
      weight={6}
      center={p.position}
      color={theme.colors[p.color] as string}
    >
      <NotamPopup
        className={`leaflet-popup-${p.color}`}
        code={p.code}
        number={p.number}
        description={p.description}
        translation={p.translation}
      />
    </CircleMarker>
  );
};

export default Dot;
