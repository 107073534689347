import { gql, useQuery } from '@apollo/client';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { ThroughputArgs, ThroughputsQuery } from './types';

const GET_THROUGHPUTS = gql`
  query Throughputs {
    throughputs {
      id
      airport
      totalYesterday
      totalOneWeekAgo
      totalOneYearAgo
      date
      createdAt
      updatedAt
    }
  }
`;

export default function useThroughputs({
  refresh = true,
  skip = false,
}: ThroughputArgs = {}): ThroughputsQuery {
  const options = {
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_THROUGHPUTS, options);

  return {
    throughputs: query.data?.throughputs || [],
    ...query,
  };
}
