import find from 'lodash/find';
import React, { createContext, useContext, useReducer } from 'react';

interface Props {
  modals: {
    title: string;
    component: React.FC | null;
  }[];
}

interface Modal {
  title: string;
  component: React.FC | null;
  props: Record<string, any>;
}

interface State {
  modals: [Modal] | any[];
  activeModal: {
    component: string | null;
    props: Record<string, any>;
  };
}

interface Action {
  type: string;
  payload: {
    component: string;
    props: Record<string, any>;
  };
}

const initialState: State = {
  modals: [],
  activeModal: {
    component: null,
    props: {},
  },
};

const ModalStateContext = createContext<State>(initialState);
const ModalDispatchContext = createContext<any>(null);

const actionTypes = {
  show: 'show',
  hide: 'hide',
  update: 'update',
};

function findModal(state: State, action: Action) {
  const title = action.payload.component;
  const activeModal = find(state.modals, m => m.title === title);
  return activeModal;
}

function modalReducer(state: State, action: Action) {
  switch (action.type) {
    case actionTypes.show: {
      const activeModal = findModal(state, action);
      if (activeModal) {
        return {
          ...state,
          activeModal: {
            component: activeModal.component,
            props: {
              ...action.payload.props,
            },
          },
        };
      }
      return state;
    }
    case actionTypes.hide: {
      return {
        ...state,
        activeModal: initialState.activeModal,
      };
    }
    case actionTypes.update: {
      const activeModal = findModal(state, action);
      if (activeModal) {
        return {
          ...state,
          activeModal: {
            component: activeModal.component,
            props: {
              ...action.payload.props,
            },
          },
        };
      }
      return state;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const ModalProvider: React.FC<Props> = ({ children, modals }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState, state => ({
    ...state,
    modals: modals || [],
  }));
  return (
    <ModalStateContext.Provider value={state}>
      <ModalDispatchContext.Provider value={dispatch}>{children}</ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

const showModal = (modal: string, modalProps = {}) => ({
  type: actionTypes.show,
  payload: {
    component: modal,
    props: modalProps,
  },
});

const updateModal = (modal: string, modalProps = {}) => ({
  type: actionTypes.update,
  payload: {
    component: modal,
    props: modalProps,
  },
});

const hideModal = () => ({
  type: actionTypes.hide,
});

function useModalState() {
  const context = useContext(ModalStateContext);
  if (context === undefined) {
    throw new Error(`useModalState must be used within a ModalProvider`);
  }
  return context;
}

function useModalDispatch() {
  const context = useContext(ModalDispatchContext);
  if (context === undefined) {
    throw new Error(`useModalDispatch must be used within a ModalProvider`);
  }
  return context;
}

function useModal() {
  const context = [useModalState(), useModalDispatch()];
  if (context === undefined) {
    throw new Error(`useModal must be used within a ModalProvider`);
  }
  return context;
}

const ModalManager: React.FC = () => {
  const { activeModal: modal } = useModalState();
  const Modal: any | null = modal.component;

  if (!modal || !modal.component) return null;

  return <Modal {...modal.props} />;
};

export {
  ModalProvider as default,
  ModalManager,
  useModal,
  useModalState,
  useModalDispatch,
  showModal,
  hideModal,
  updateModal,
};
