import { gql, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { TSACountsArgs, TSACountsQuery } from './types';

const GET_TSA_COUNTS = gql`
  query TSACounts($between: [DateTime]) {
    tsaCounts(between: $between) {
      id
      date
      dow
      termA
      termB
      dow2019
      termA2019
      termB2019
      totalSeats
    }
  }
`;

export default function useTSACounts({
  between,
  refresh = true,
  skip = false,
}: TSACountsArgs): TSACountsQuery {
  const variables = pickBy({ between }, identity);
  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_TSA_COUNTS, options);

  return {
    tsaCounts: query?.data?.tsaCounts || [],
    ...query,
  };
}
