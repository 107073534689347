import React from 'react';
import { Popup } from 'react-leaflet';

import { Colors } from 'config/theme';

import { Box } from 'styled';

import { Dot } from '.';

import { TrafficAlert } from '../types';

interface IncidentsProps {
  data: TrafficAlert[];
  type: string;
  color: keyof Colors;
}

const Incidents: React.FC<IncidentsProps> = ({ data, type, color }: IncidentsProps) => {
  return (
    <>
      {data.map((datum: TrafficAlert) => (
        <Dot key={datum.id} lat={datum.location.y} lng={datum.location.x} color={color}>
          <Popup>
            <Box textAlign="center">{type}</Box>
          </Popup>
        </Dot>
      ))}
    </>
  );
};

export default Incidents;
