import React, { useState, useEffect } from 'react';

import { HeaderNav, Logo, UserMenu } from 'components';

import { Box, Header as StyledHeader } from 'styled';
import { formatAirportTime } from 'utils';

export const Header: React.FC = () => {
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    setInterval(() => setTime(new Date()), 30000);
  }, []);

  return (
    <StyledHeader>
      <HeaderNav />
      <Box mx="auto" id="header-logo">
        <Logo />
      </Box>
      <Box alignItems="center" display="flex" justifySelf="flex-end">
        <Box as="p" fontWeight="bold" mr="base">
          {formatAirportTime(time, 'EEEE, MMMM do h:mm a zzz')}
        </Box>
        <Box alignItems="center" display="flex" id="user-menu-container">
          <UserMenu />
        </Box>
      </Box>
    </StyledHeader>
  );
};

export default Header;
