import React from 'react';

import { Table } from 'components';
import { DetailsComponentProps } from 'components/ChartDetails';

import { BagTagData } from 'data/CommonUse/types';

const CommonUseBagTagTable: React.FC<DetailsComponentProps> = ({ data }: DetailsComponentProps) => {
  const _data = data as BagTagData[];

  return (
    <Table
      grid="repeat(4, 1fr)"
      striped={false}
      bordered={true}
      cellPaddingX="s"
      cellPaddingY="s"
      dataFontSize="s"
      gridGap="xs"
      headings={[
        { key: 'date', label: 'Date' },
        { key: 'airline', label: 'Airline' },
        { key: 'count', label: 'Bag Tags' },
        { key: 'workstation', label: 'Workstations' },
      ]}
      data={_data}
    />
  );
};

export default CommonUseBagTagTable;
