import React, { useEffect } from 'react';

import { useDataLimiter } from 'hooks';

import { Table, Tile } from 'components';
import { DetailsComponentProps } from 'components/ChartDetails';

import DetailsNav from './DetailsNav';
import { CommonUseKiosk } from 'data/CommonUse/types';

const LIMIT = 20;

const CommonUseKioskDetails: React.FC<DetailsComponentProps> = ({
  data,
}: DetailsComponentProps) => {
  const dataLimiter = useDataLimiter(LIMIT);

  useEffect(() => {
    dataLimiter.setData(data as CommonUseKiosk[]);
  }, [data, dataLimiter]);

  if (!data) return null;

  if (dataLimiter.status === 'loading') return null;

  const _data = data as CommonUseKiosk[];

  return (
    <Tile title={`Common Use Kiosks`} icon="briefcase" contentAlignment="top" contentPadding="0">
      <Table
        grid="repeat(5, 1fr)"
        striped={false}
        bordered={true}
        cellPaddingX="s"
        cellPaddingY="s"
        dataFontSize="s"
        gridGap="xs"
        headings={[
          { key: 'date', label: 'Date' },
          { key: 'name', label: 'Name' },
          { key: 'airline', label: 'Airline' },
          { key: 'btp', label: 'Bag Tags Printed' },
          { key: 'atb', label: 'Boarding Passes Printed' },
        ]}
        data={_data}
      />
      <DetailsNav dataLimiter={dataLimiter} />
    </Tile>
  );
};

export default CommonUseKioskDetails;
