import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import Joyride, { Step } from 'react-joyride';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import routes from 'config/routes';
import theme from 'config/theme';

import { useOnboardingCallback, useSidebar } from 'hooks';

import { ActiveAlertsManager, State as AlertsState } from 'providers/AlertsProvider';
import { ModalManager } from 'providers/ModalProvider';
import { useOnboardingState } from 'providers/OnboardingProvider';
import { useSettingsState } from 'providers/SettingsProvider';
import { State as ToastState, ToastManager } from 'providers/ToastProvider';

import { ActiveAlertPopup, Header, Logo, Sidebar, Toast, UserMenu } from 'components';

import { BackgroundGradient, Box, Layout, LayoutContent } from 'styled';

export interface Props {
  backgroundGradient?: string;
  children?: JSX.Element;
  sidebar?: Function;
  sidebarBackground?: string;
  pageIndex?: number;
}

interface State {
  steps: Step[];
}

export const AppLayout: React.FC<Props> = ({
  backgroundGradient,
  children,
  sidebar,
  sidebarBackground,
  pageIndex,
}: Props) => {
  const location = useLocation();
  const { collapsed, handleToggle } = useSidebar();
  const settings = useSettingsState();

  // Joyride state
  const onboardingState = useOnboardingState();
  const { run, stepIndex, steps } = onboardingState;
  const { onboardingCallback } = useOnboardingCallback();

  const JoyRide = (
    <Joyride
      debug={false}
      callback={onboardingCallback}
      continuous
      hideCloseButton
      run={run}
      showProgress
      showSkipButton
      stepIndex={stepIndex}
      steps={steps}
      styles={{
        options: {
          primaryColor: '#08080A',
          width: 288,
          zIndex: 9999,
        },
      }}
    />
  );

  const Default = (): JSX.Element => (
    <>
      <Layout dashboardMode={settings.dashboardMode}>
        {settings.dashboardMode ? (
          pageIndex === 1 && (
            <DashboardModeHeader screen={location.pathname}>
              <Box mr="s" zIndex={theme.zIndices.menu}>
                <UserMenu hideAvatar={true} side="center" />
              </Box>
              <Logo />
            </DashboardModeHeader>
          )
        ) : (
          <>
            <Header />
          </>
        )}
        <LayoutContent
          sidebar={sidebar}
          collapsed={collapsed}
          dashboardMode={settings.dashboardMode}
        >
          {sidebar ? (
            <Sidebar
              onToggle={handleToggle}
              collapsed={collapsed}
              bg={sidebarBackground || 'border'}
            >
              {sidebar()}
            </Sidebar>
          ) : null}
          <Box as="main" bg={backgroundGradient ? 'inherit' : 'neutralsGradient.9'} overflow="auto">
            {children}
          </Box>
        </LayoutContent>
      </Layout>
      <ModalManager />
      {JoyRide}
      <ToastManager renderComponent={(toast: ToastState): JSX.Element => <Toast {...toast} />} />
      <ActiveAlertsManager
        renderComponent={(alert: AlertsState): JSX.Element => <ActiveAlertPopup {...alert} />}
      />
    </>
  );

  if (backgroundGradient) {
    return (
      <BackgroundGradient type={backgroundGradient}>
        <Default />
      </BackgroundGradient>
    );
  }

  return (
    <>
      <Default />
    </>
  );
};

interface DashboardModeHeaderProps {
  screen: string;
}

const DashboardModeHeader = styled.div<DashboardModeHeaderProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${themeGet('space.base')};
  padding-bottom: calc(${themeGet('space.base')} - ${themeGet('space.xs')});
  ${props =>
    props.screen === routes.dashboard &&
    css`
      left: 50%;
      top: ${themeGet('space.base')};
      transform: translateX(-50%);
      padding: 0;
      position: absolute;
      z-index: ${themeGet('zIndices.modalOverlay')};
    `};
`;

export default AppLayout;
