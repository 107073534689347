import React, { useEffect } from 'react';

import { TILE_KEYS, TILES } from 'config/tiles';

import { FacilityCondition } from 'client/FacilityConditions/types';
import { Issue } from 'client/Issue/types';

import { showModal, updateModal, useModal } from 'providers/ModalProvider';
import { updateOptions, useTileOptions, useTileOptionsState } from 'providers/TileOptionsProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';
import { FacilityConditions } from 'components/Tiles';

export const FacilityConditionsTile: React.FC = () => {
  const [optionsState, optionsDispatch] = useTileOptions();
  const [modalState, modalDispatch] = useModal();
  const {
    facilityConditions: { filters },
  } = useTileOptionsState();

  const tileConfig = TILES?.find(tile => tile.title === 'Facility');
  const headings = tileConfig?.headings || [];
  const additionalHeadings = tileConfig?.additionalHeadings || [];

  const modalProps = {
    title: 'Facility',
    icon: { name: 'power' },
    large: true,
    children: (
      <FacilityConditions
        grid="1fr 2fr repeat(3, 1fr)"
        headings={[...headings, ...additionalHeadings]}
        showTable={true}
        showMap={true}
        mapHeight="400px"
        isReverseSort={filters?.isReverseSort}
        sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
      />
    ),
  };

  // Update modal data if needed
  useEffect(() => {
    const isActiveModal = modalState?.activeModal?.props?.title === 'Facility';
    const needsUpdate = optionsState?.facilityConditions?.filters?.needsUpdate;
    if (isActiveModal && needsUpdate) {
      modalDispatch(updateModal('Default', modalProps));
      optionsDispatch(
        updateOptions({
          facilityConditions: {
            ...optionsState.facilityConditions,
            filters: {
              ...optionsState.facilityConditions.filters,
              needsUpdate: false,
            },
          },
        })
      );
    }
  }, [modalDispatch, modalState, modalProps, optionsDispatch, optionsState]);
  if (process.env.REACT_APP_CONFIG_FACILITY !== 'true') return null;
  return (
    <RequiredPermission
      permission="read:facility-conditions"
      renderFallback={() => <NotAvailableBlankSlate />}
    >
      <Tile
        title="Facility"
        icon="power"
        contentAlignment="top"
        contentPadding="0"
        modal="Default"
        modalProps={modalProps}
        options={[
          {
            label: 'Settings',
            icon: {
              name: 'gear',
            },
            action: (): void =>
              modalDispatch(
                showModal('TileOptions', {
                  title: 'Facility Conditions Settings',
                  icon: { name: 'gear' },
                  optionKey: TILE_KEYS.FACILITY_CONDITIONS,
                })
              ),
          },
        ]}
      >
        <FacilityConditions
          showTable={true}
          filter={({
            facilityCondition,
            issues,
          }: {
            facilityCondition: FacilityCondition;
            issues: Issue[];
          }): boolean => {
            const { areas, status, severities } = filters;

            let validStatus = false;
            if (status?.includes('OK')) {
              validStatus =
                !facilityCondition.issueId ||
                issues.every(issue => issue.id !== facilityCondition.issueId);
            }
            if (!validStatus && status?.includes('Issue')) {
              validStatus =
                Boolean(facilityCondition.issueId) &&
                issues.some(issue => issue.id === facilityCondition.issueId);
            }
            const validSeverity = severities?.includes(facilityCondition.importance);
            const validArea = areas?.includes(facilityCondition.feed);

            return validStatus && validSeverity && validArea;
          }}
          filterBlankSlateIcon={{ name: 'check', color: 'primary', size: '30' }}
          filterEmptyMessage="No facility conditions matching parameters."
          isReverseSort={filters?.isReverseSort}
          sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
        />
      </Tile>
    </RequiredPermission>
  );
};

export default FacilityConditionsTile;
