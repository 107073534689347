import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

const AuthorizedApolloProvider: React.FC = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const isHttps = window.location.protocol.includes('s');
  const wsProtocol = isHttps ? 'wss' : 'ws';

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  });

  const wsLink = new WebSocketLink({
    uri: `${wsProtocol}:${process.env.REACT_APP_API_URL}/graphql`,
    options: {
      reconnect: true,
    },
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  const cache = new InMemoryCache({
    addTypename: true,
    dataIdFromObject: o => {
      if (o.__typename && o.id) return `${o.__typename}:${o.id}`;
      return defaultDataIdFromObject(o);
    },
    typePolicies: {
      Query: {
        fields: {
          issues: {
            merge(_ignored, incoming) {
              return incoming;
            },
          },
          user: {
            merge(_ignored, incoming) {
              return incoming;
            },
          },
        },
      },
      UsersData: {
        keyFields: ['user_id'],
      },
    },
  });

  const client = new ApolloClient({ link: authLink.concat(link), cache });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
