import React from 'react';

import { useCurrentWaitTimes } from 'data/Security';

import { Stat, Trend, UpdatedAt } from 'components';
import { DefaultErrorMessageWithError, DefaultLoader } from 'components/QueryCell';

import { Box } from 'styled';

import { Item } from './components';
import { getTrend } from './utils';
import { Trend as TrendType } from './utils/getTrend';
import { Checkpoint } from 'data/Security/types';

type WaitTime = {
  name: string;
  current: number;
  trend: TrendType;
};

export default function WaitTime() {
  // Wait Times data
  const endDate = new Date();
  const waitTimes = useCurrentWaitTimes({});
  const loading = waitTimes.loading;
  const error = waitTimes.error;
  const currentWaitTimes = waitTimes?.data?.currentWaitTimes || [];
  const checkpoints: WaitTime[] = currentWaitTimes.map((checkpoint: Checkpoint) => {
    const { name, waitTimes } = checkpoint;
    const current = Math.ceil(waitTimes[waitTimes.length - 1].value);
    const previous = Math.ceil(waitTimes[0].value);
    const trend = getTrend(previous, current);
    return { name, current, trend };
  });

  if (loading) return <DefaultLoader />;
  if (error) return <DefaultErrorMessageWithError error={error} action="useWaitTimes()" />;
  const displayGrid = process.env.REACT_APP_CHECKPOINT_TOTAL === '5';
  return (
    <>
      {displayGrid ? (
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" width="100%" px="base">
          {checkpoints?.map((checkpoint, index) => {
            const { name, current, trend } = checkpoint;
            return (
              <Item mb={index === checkpoints.length - 1 ? '' : 'base'} key={name} name={name}>
                <Box>
                  <Stat
                    suffix="min"
                    suffixSpace="xs"
                    labelFontSize="1.5rem"
                    headingFontSize="2.2rem"
                    headingFontWeight="normal"
                  >
                    {current}
                  </Stat>
                </Box>
                <Box>
                  <Trend
                    direction={trend.direction}
                    value={trend.percentage}
                    suffix="%"
                    interval="30 minutes"
                  />
                </Box>
              </Item>
            );
          })}
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" width="100%" px="base">
          {checkpoints?.map((checkpoint, index) => {
            const { name, current, trend } = checkpoint;
            return (
              <Item mb={index === checkpoints.length - 1 ? '' : 'base'} key={name} name={name}>
                <Box>
                  <Stat
                    suffix="min"
                    suffixSpace="xs"
                    labelFontSize="1.5rem"
                    headingFontSize="2.2rem"
                    headingFontWeight="normal"
                  >
                    {current}
                  </Stat>
                </Box>
                <Box>
                  <Trend
                    direction={trend.direction}
                    value={trend.percentage}
                    suffix="%"
                    interval="30 minutes"
                  />
                </Box>
              </Item>
            );
          })}
        </Box>
      )}
      <UpdatedAt date={endDate} loading={loading} />
    </>
  );
}
