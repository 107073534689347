import { SeriesOptionsType } from 'highcharts';

import { PassengerChartDatum } from 'statistics/types';

import { getXAxis, getYAxis } from './axes';
import { default as getOptions } from './options';
import { default as getSeries } from './series';

export default {
  age: {
    getXAxis,
    getYAxis,
    getOptions: (): Highcharts.Options => getOptions('Age'),
    getSeries: (data: PassengerChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries('Age', data, chartOptions),
  },
  gender: {
    getXAxis,
    getYAxis,
    getOptions: (): Highcharts.Options => getOptions('Gender'),
    getSeries: (data: PassengerChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries('Gender', data, chartOptions),
  },
  status: {
    getXAxis,
    getYAxis,
    getOptions: (): Highcharts.Options => getOptions('Passenger Status'),
    getSeries: (data: PassengerChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries('Passenger Status', data, chartOptions),
  },
};
