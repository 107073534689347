function rem(value: string, delimeter = 'px'): string {
  const ctx = 16;
  const [number] = value.split(delimeter);
  const int = parseInt(number, 10);
  const rem = int / ctx;

  return `${rem}rem`;
}

export default rem;
