import { endOfDay, format, parseISO, startOfDay, subDays } from 'date-fns';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { useEffect } from 'react';

import { useQueryParams } from 'hooks';

import { updateOptions, useChartOptions } from 'providers/ChartOptionsProvider';

interface GetReportQueryParams {
  data: {
    endDate: Date;
    startDate: Date;
    days: string[];
    tabs: string[];
    airport?: string;
    airline?: string;
    weather?: string;
  };
  options?: {
    range?: '7 days';
  };
}

function useReportQueryParams(): {
  getQueryParams: (opts: GetReportQueryParams) => string;
} {
  const [state, dispatch] = useChartOptions();
  const query = useQueryParams();
  const {
    dataStartDate,
    dataEndDate,
    chartStartDate,
    chartEndDate,
    destinationAirportInputValue: airport,
    airlineInputValue: airline,
    activeReport,
    tabs,
    dateRangeDays,
    weatherInputValue: weather,
  } = state;

  function getQueryParams({ data, options }: GetReportQueryParams): string {
    const { startDate, endDate, tabs, airport, airline, days, weather } = data;
    const formatString = 'yyyy-MM-dd';
    let _startDate = format(startDate, formatString);
    let _endDate = format(endDate, formatString);
    const views = tabs?.join(',');
    const _days = days.join(',');

    if (options?.range) {
      switch (options?.range) {
        case '7 days': {
          _startDate = format(subDays(new Date(), 7), formatString);
          _endDate = format(new Date(), formatString);
          break;
        }
        default: {
          return '';
        }
      }
    }

    return `?startDate=${_startDate}&endDate=${_endDate}&days=${_days}&views=${views}&airport=${airport}&airline=${airline}&weather=${weather}`;
  }

  useEffect(() => {
    const _startDate = query.get('startDate');
    const _endDate = query.get('endDate');

    if (!_startDate || !_endDate) return;

    const shouldRunReport = !activeReport && _startDate && _endDate;
    if (shouldRunReport) {
      const parsedStartDate = new Date(parseISO(_startDate || ''));
      const parsedEndDate = new Date(parseISO(_endDate || ''));
      const _airport = query.get('airport');
      const _airline = query.get('airline');
      const views = query.get('views')?.split(',');
      const days = query.get('days')?.split(',');
      const _weather = query.get('weather');

      const options = {
        dataStartDate: startOfDay(parsedStartDate),
        dataEndDate: endOfDay(parsedEndDate),
        chartStartDate: startOfDay(parsedStartDate),
        chartEndDate: endOfDay(parsedEndDate),
        destinationAirport: _airport,
        destinationAirportInputValue: _airport,
        airline: isEmpty(_airline) ? [] : _airline?.split(','),
        airlineInputValue: '',
        activeReport: true,
        tabs: views,
        dateRangeDays: days,
        weather: isEmpty(_weather) ? [] : _weather?.split(','),
        weatherInputValue: '',
      };
      const curOptions = {
        dataStartDate,
        dataEndDate,
        chartStartDate,
        chartEndDate,
        destinationAirport: airport,
        destinationAirportInputValue: airport,
        airline: airline,
        airlineInputValue: airline,
        activeReport,
        tabs,
        dateRangeDays: days,
        weather,
        weatherInputValue: weather,
      };

      if (!isEqual(options, curOptions)) {
        dispatch(updateOptions(pickBy({ ...options }, identity)));
      }
    }
  }, [
    query,
    activeReport,
    dataStartDate,
    dataEndDate,
    chartStartDate,
    chartEndDate,
    airport,
    airline,
    dispatch,
    tabs,
    dateRangeDays,
    weather,
  ]);

  return { getQueryParams };
}

export default useReportQueryParams;
