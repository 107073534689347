import { lazy } from 'react';

import { default as AppError } from './AppError';

const Callback = lazy(() => import('./Callback'));
const Dashboard = lazy(() => import('./Dashboard'));
const EditIssue = lazy(() => import('./EditIssue'));
const Issues = lazy(() => import('./Issues'));
const Login = lazy(() => import('./Login'));
const Logout = lazy(() => import('./Logout'));
const NotFound = lazy(() => import('./NotFound'));
const Reports = lazy(() => import('./Reports'));
const Unsubscribe = lazy(() => import('./Unsubscribe'));

export {
  AppError,
  Callback,
  Dashboard,
  EditIssue,
  Issues,
  Login,
  Logout,
  NotFound,
  Reports,
  Unsubscribe,
};
