import { gql, useQuery } from '@apollo/client';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { RemoteParkingSpot } from './types';

const REMOTE_PARKING = gql`
  query RemoteParking {
    remoteParking {
      reservation {
        airline
        airlineCode
        rentalStatus
        estimatedIssueDate
        estimatedReturnDate
        issueDate
        tailNumber
        aircraftType
        udfs
        phoneNumber
        purpose
        customerRentalCode
      }
      asset {
        availabilityStatus
        equipmentNumber
        latitude
        longitude
      }
    }
  }
`;

export default function useRemoteParking({ refresh = true }: { refresh?: boolean } = {}) {
  const options = {
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery<{ remoteParking: RemoteParkingSpot[] }>(REMOTE_PARKING, options);
  const spots: RemoteParkingSpot[] = (query?.data?.remoteParking || []).map(spot => ({
    ...spot,
    id: spot?.asset?.equipmentNumber,
  }));

  return {
    spots,
    ...query,
  };
}
