import { UAParser } from 'ua-parser-js';

import {
  BROWSER_LINKS,
  BrowserLinks,
  SUPPORTED_BROWSERS,
  SupportedBrowsers,
} from 'config/browsers';

interface Reason {
  supported: boolean;
  reason: string;
}

class BrowserValidator {
  static SUPPORTED_BROWSERS: SupportedBrowsers = SUPPORTED_BROWSERS;
  static BROWSER_LINKS: BrowserLinks = BROWSER_LINKS;

  UAParser: UAParser;
  userAgent: string;

  constructor(userAgent: string) {
    this.userAgent = userAgent;
    this.UAParser = new UAParser();
    this.UAParser.setUA(userAgent);
  }

  validate(): Reason {
    const { name, major } = this.UAParser.getBrowser();
    const browserNames = Object.keys(BrowserValidator.SUPPORTED_BROWSERS);

    if (!name || !major) {
      return this.withReason(false, 'Unknown browser/version');
    }

    if (!browserNames.includes(name)) {
      return this.withReason(false, `We do not support ${name}`);
    }

    const version = parseInt(major);
    const supportedVersion = BrowserValidator.SUPPORTED_BROWSERS[name];

    if (version < supportedVersion) {
      return this.withReason(false, `We do not support ${name} ${major}`);
    }

    return this.withReason(true, `We support ${name} ${major}`);
  }

  withReason(supported: boolean, reason: string): Reason {
    return {
      supported,
      reason,
    };
  }
}

export default BrowserValidator;
