import React from 'react';

import { useRefreshedAt } from 'hooks';
import { useCargoRevenueDepartures } from 'data/Cargo';
import { QueryCell, UpdatedAt } from 'components';
import { Heading } from 'components/Table';

import CargoRevenueDeparturesTable from './components/CargoRevenueDeparturesTable';

export interface Data {
  airline: React.ReactNode;
  operations: React.ReactNode;
  weight: React.ReactNode;
  cost: React.ReactNode;
}

interface Props {
  grid?: string;
  headings?: Heading[];
  sortByColumn?: string;
  isReverseSort?: boolean;
}

const CargoDepartureRevenue: React.FC<Props> = (props: Props) => {
  const { airlines, totalOperations, totalWeight, loading, error } = useCargoRevenueDepartures();
  const { refreshedAt } = useRefreshedAt(loading);

  return (
    <QueryCell content="departures" data={airlines} error={error} loading={loading}>
      <CargoRevenueDeparturesTable
        data={{ airlines, totalOperations, totalWeight }}
        grid="repeat(2, 1fr)"
        {...props}
      />
      <UpdatedAt date={refreshedAt} loading={loading} />
    </QueryCell>
  );
};

export default CargoDepartureRevenue;
