import React from 'react';

import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { Checkbox, FormField } from 'components';

interface Props {
  type: 'arrivals' | 'departures';
}

const FlightSummaryOptions: React.FC<Props> = () => {
  const [state, dispatch] = useTileOptions();
  const filters = state?.flightSummary?.filters;

  function handleTypeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const flightType = event.target.name.toLowerCase();
    const checked = filters?.types?.includes(flightType);
    let newTypes: string[] = [];

    if (checked) {
      newTypes = filters?.types?.filter((t: string) => t !== flightType);
    } else {
      newTypes = [...filters?.types, flightType];
    }

    dispatch(
      updateOptions({
        flightSummary: {
          filters: {
            ...filters,
            types: newTypes,
          },
        },
      })
    );
  }

  return (
    <>
      <FormField label={`Types of flights to show`} type="stacked" mb="base">
        <Checkbox
          label="Passenger"
          name="passenger"
          checked={filters?.types?.includes('passenger')}
          onChange={handleTypeChange}
          mb="s"
        />
        <Checkbox
          label="Cargo"
          name="cargo"
          checked={filters?.types?.includes('cargo')}
          onChange={handleTypeChange}
          mb="s"
        />
      </FormField>
    </>
  );
};

export default FlightSummaryOptions;
