import React from 'react';

import { Icon } from 'components';

import { Box } from 'styled';

interface MediaData {
  type: string;
  previewUrl: string;
}

interface Props {
  media: MediaData[];
  height?: string;
}

export const MediaGrid: React.FC<Props> = ({ media = [], height = '300px' }: Props) => {
  const getMediaElement = ({ previewUrl }: MediaData) => {
    if (!previewUrl) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          backgroundColor="neutralsGradient.7"
        >
          <Icon name="alertTriangle" />
          Unable to load
        </Box>
      );
    } else {
      return <img className="media-grid-image" alt="media grid" src={previewUrl} />;
    }
  };

  const renderMediaGrid = () => {
    switch (media?.length) {
      case 0:
        return <></>;
      case 2:
        return (
          <Box display="flex" flexWrap="wrap" height="100%" width="100%">
            <Box width="50%" height="100%">
              {getMediaElement(media?.[0])}
            </Box>
            <Box width="50%" height="100%">
              {getMediaElement(media?.[1])}
            </Box>
          </Box>
        );
      case 3:
        return (
          <Box display="flex" flexWrap="wrap" height="100%" width="100%">
            <Box width="50%" height="100%">
              {getMediaElement(media?.[0])}
            </Box>
            <Box display="flex" flexDirection="column" width="50%" height="100%">
              <Box width="100%" height="50%">
                {getMediaElement(media?.[1])}
              </Box>
              <Box width="100%" height="50%">
                {getMediaElement(media?.[2])}
              </Box>
            </Box>
          </Box>
        );
      case 4:
        return (
          <Box display="flex" flexWrap="wrap" height="100%" width="100%">
            {media?.map((m, i) => (
              <Box width="50%" height="50%" key={`media-cell-${i}`}>
                {getMediaElement(m)}
              </Box>
            ))}
          </Box>
        );
      default:
        return (
          <Box display="flex" width="100%" height="100%">
            {media?.map((m, i) => (
              <Box width="100%" height="100%" key={`media-cell-${i}`}>
                {getMediaElement(m)}
              </Box>
            ))}
          </Box>
        );
    }
  };

  return media?.length > 0 ? (
    <Box height={height} width="100%" overflow="hidden" borderRadius="l">
      {renderMediaGrid()}
    </Box>
  ) : (
    <></>
  );
};

export default MediaGrid;
