import merge from 'lodash/merge';

import { getDefaultYAxisOptions } from 'statistics/charts/utils';

const axis: Highcharts.YAxisOptions = merge(getDefaultYAxisOptions(), {
  title: {
    text: 'Flights',
  },
  id: 'flights',
} as Highcharts.YAxisOptions);

export default function getAxis(): Highcharts.YAxisOptions {
  return axis;
}
