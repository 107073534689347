import React from 'react';

import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { FormField, Select } from 'components';

import { Box } from 'styled';

type Option = {
  value: string;
  label: string;
};

interface Props {
  stateKey: string;
  options: Option[];
}

const SortByOptions: React.FC<Props> = ({ stateKey, options }: Props) => {
  const [state, dispatch] = useTileOptions();
  const filters = state?.[stateKey]?.filters;

  function handleSortByChange(event: React.ChangeEvent<HTMLInputElement>): void {
    dispatch(
      updateOptions({
        [stateKey]: {
          ...state?.[stateKey],
          filters: {
            ...filters,
            defaultSortByColumn: event.target.value,
            sortByColumn: event.target.value,
          },
        },
      })
    );
  }

  function handleReverseSortChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const isReverseSort = event.target.value === 'descending';
    dispatch(
      updateOptions({
        [stateKey]: {
          ...state?.[stateKey],
          filters: {
            ...filters,
            defaultIsReverseSort: isReverseSort,
            isReverseSort,
          },
        },
      })
    );
  }

  return (
    <>
      <FormField label="Sort by" type="stacked" mb="base">
        <Box display="flex">
          <Box width="150px" mr="base">
            <Select
              name="sortBy"
              options={options}
              onChange={handleSortByChange}
              value={filters?.defaultSortByColumn}
            />
          </Box>
          <Box width="150px">
            <Select
              name="reverse"
              options={[
                { value: 'ascending', label: 'Ascending' },
                { value: 'descending', label: 'Descending' },
              ]}
              onChange={handleReverseSortChange}
              value={filters?.defaultIsReverseSort ? 'descending' : 'ascending'}
            />
          </Box>
        </Box>
      </FormField>
    </>
  );
};

export default SortByOptions;
