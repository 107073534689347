import 'leaflet/dist/leaflet.css';

import { LatLngBoundsLiteral } from 'leaflet';
import React from 'react';
import { Map as LeafletMap, Rectangle, TileLayer } from 'react-leaflet';

import theme from 'config/theme';

import { useDelays } from 'client/Delay';

import { useRefreshedAt } from 'hooks';

import { UpdatedAt } from 'components';
import {
  DefaultEmptyMessage,
  DefaultErrorMessageWithError,
  DefaultLoader,
} from 'components/QueryCell';

import { Box } from 'styled';

import { Dot } from './components';
import { getAirportDelays } from './utils';
import { useWeather } from 'data/Weather';
import { Weather as WeatherType } from 'data/Weather/types';

interface Props {
  zoom?: number;
}

const Weather: React.FC<Props> = ({ zoom }: Props) => {
  const weather = useWeather({});

  const delays = useDelays({});
  const loading = weather.loading || delays.loading;
  const error = weather.error || delays.error;
  const noData = !weather.weather;
  const { refreshedAt } = useRefreshedAt(loading);

  if (loading && noData) return <DefaultLoader />;
  if (error) return <DefaultErrorMessageWithError error={error} action="useWeather()" />;
  if (noData) return <DefaultEmptyMessage content="weather" />;

  const filteredWeatherData = weather?.weather;
  const delaysData = delays?.delays;

  const options = {
    lat: 39.5,
    lng: -98.35,
    zoom: zoom || 3,
  };

  const rectangleBounds: LatLngBoundsLiteral = [
    [options.lat - 1000, options.lng - 1000],
    [options.lat + 1000, options.lng + 1000],
  ];

  return (
    <Box height="100%" position="relative">
      <LeafletMap
        center={[options.lat, options.lng]}
        zoom={options.zoom}
        attributionControl={false}
        zoomControl={false}
      >
        <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/dark_nolabels/{z}/{x}/{y}.png" />
        {/* From https://www.mesonet.agron.iastate.edu/ogc/ */}
        <TileLayer
          url="https://mesonet.agron.iastate.edu/cache/tile.py/1.0.0/nexrad-n0q-900913/{z}/{x}/{y}.png"
          opacity={0.5}
        />
        <Rectangle
          bounds={rectangleBounds}
          fillColor={theme.colors.weatherOverlay}
          fillOpacity={0.15}
          stroke={false}
        />
        {filteredWeatherData?.map((weather: WeatherType) => (
          <Dot
            key={weather.airport?.id}
            weatherData={weather}
            delaysData={getAirportDelays(weather.airport, delaysData)}
          />
        ))}
        <Box bottom="0" left="0" position="absolute" right="0" zIndex={800}>
          <UpdatedAt date={refreshedAt} loading={loading} />
        </Box>
      </LeafletMap>
    </Box>
  );
};

export default Weather;
