import { format } from 'date-fns';
import sortBy from 'lodash/sortBy';
import React from 'react';

import { getAirline } from 'config/airlines';

import { RemoteParkingSpot } from 'client/RemoteParking/types';

import { useSelectedTableItemDispatch } from 'providers/SelectedTableItemProvider';
import { useTileOptionsState } from 'providers/TileOptionsProvider';

import { AirlineIcon, Icon } from 'components';
import Table, { Heading } from 'components/Table';

import { Box, TextLabel } from 'styled';

const defaults = {
  grid: 'repeat(4, 1fr)',
  headings: [
    { key: 'spotName', label: 'Spot' },
    { key: 'name', label: 'Name' },
    { key: 'start', label: 'Start' },
    { key: 'end', label: 'End' },
  ],
};

// Reservation ID - 11294
// Start Time - 8/24/2020 10:47:00 PM
// Estimated vacancy - 8/24/2020 10:50:00 PM
// Tail # -
// Type - 3.A320
// Airline - Allegiant Airlines
// Name - Trego Dugan Ops
// Phone - 859-757-2376
// Purpose - Ron

interface RemoteParkingTableProps {
  grid?: string;
  headings?: Heading[];
  spots: RemoteParkingSpot[];
}

interface RemoteParkingData {
  spot: RemoteParkingSpot;
}

const RemoteParkingTable: React.FC<RemoteParkingTableProps> = ({
  spots,
}: RemoteParkingTableProps) => {
  const state = useTileOptionsState();
  const {
    remoteParking: { filters },
  } = state;
  const sortByColumn = filters?.sortByColumn || filters?.defaultSortByColumn;
  const setSelectedSpot = useSelectedTableItemDispatch();
  if (process.env.REACT_APP_CONFIG_REMOTE_PARKING !== 'true') return null;

  const data = spots.map(spot => {
    const { reservation, asset } = spot;
    const { equipmentNumber } = asset;
    const { rentalStatus, issueDate, estimatedIssueDate, estimatedReturnDate, udfs, airlineCode } =
      reservation;
    const isOccupied = rentalStatus === 'O';
    const isReserved = rentalStatus === 'U';
    const color = isOccupied ? 'error' : isReserved ? 'warning' : 'success';
    const airline = getAirline(airlineCode);

    return {
      spotName: (
        <Box alignItems="center" display="flex">
          <Box mr="xs">
            {airline ? (
              <AirlineIcon airline={airline} />
            ) : (
              <Icon name="circle" size="16" color={color} fill={color} mr="xs" />
            )}
          </Box>
          <TextLabel color={color} display="inline">
            {equipmentNumber}
          </TextLabel>
        </Box>
      ),
      name: udfs.find(udf => Boolean(udf)),
      start: isOccupied
        ? format(issueDate, 'LL/dd/yyyy KK:mm aa')
        : isReserved
        ? format(estimatedIssueDate, 'LL/dd/yyyy KK:mm aa')
        : '',
      end: estimatedReturnDate ? format(estimatedReturnDate, 'LL/dd/yyyy KK:mm aa') : '',
      spot,
    };
  });

  let sortedData = sortBy(data, sortByColumn || 'spotName');
  if (filters?.isReverseSort) sortedData = sortedData.reverse();

  return (
    <Table
      name="remoteParking"
      striped={false}
      bordered={true}
      dataFontSize="s"
      grid={defaults.grid}
      headings={defaults.headings}
      data={sortedData}
      onRowClick={(spot: RemoteParkingData): void => {
        setSelectedSpot(spot.spot);
      }}
    />
  );
};

export default RemoteParkingTable;
