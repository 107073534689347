import React from 'react';

import { TILE_KEYS } from 'config/tiles';

import { showModal, useModalDispatch } from 'providers/ModalProvider';
import { useTileOptionsState } from 'providers/TileOptionsProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';
import { CustomerFeedback } from 'components/Tiles';

export const CustomerFeedbackTile: React.FC = () => {
  const modalDispatch = useModalDispatch();
  const {
    customerFeedback: { filters },
  } = useTileOptionsState();
  if (process.env.REACT_APP_CONFIG_FEEDBACK !== 'true') return null;
  return (
    <Tile
      title="Customer Feedback"
      icon="messageSquare"
      contentAlignment="top"
      contentPadding="0"
      modal="Default"
      modalProps={{
        title: 'Customer Feedback',
        icon: { name: 'messageSquare' },
        large: true,
        children: (
          <RequiredPermission
            permission="read:customer-feedback"
            renderFallback={() => <NotAvailableBlankSlate />}
          >
            <CustomerFeedback fullscreen />
          </RequiredPermission>
        ),
      }}
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Customer Feedback Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.CUSTOMER_FEEDBACK,
              })
            ),
        },
      ]}
    >
      {/* TODO: Update permissions */}
      <RequiredPermission
        permission="read:customer-feedback"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <CustomerFeedback
          isAttachmentShown={filters?.isAttachmentShown}
          filter={(data: Array<Record<string, any>>) => {
            // TODO: TS input will need to be changed when data comes in to better reflect function
            const filteredFeed = data?.filter(feed =>
              filters?.selectedSentiment?.some(sentiment =>
                feed.sentiment ? sentiment.toLowerCase() === feed.sentiment.toLowerCase() : true
              )
            );
            return filteredFeed;
          }}
        />
      </RequiredPermission>
    </Tile>
  );
};

export default CustomerFeedbackTile;
