import React from 'react';

import { useUpdateIssue } from 'client/Issue';

import { showSuccess, useToastDispatch } from 'providers/ToastProvider';
import { track } from 'utils';

import { CircleIcon } from 'components';

import { ResetButton } from 'styled';

interface Props {
  id: string;
  onComplete?: Function;
  renderExternal?: Function;
  status: 'open' | 'closed' | 'closing' | 'opening';
}

const IssueStatusToggle: React.FC<Props> = ({ id, onComplete, renderExternal, status }: Props) => {
  const toastDispatch = useToastDispatch();
  const [updateIssue, { loading, data: _data }] = useUpdateIssue({
    onCompleted: data => {
      const _status = data.updateIssue.status;
      const action = _status === 'closed' ? 'closed' : 'opened';
      toastDispatch(showSuccess(`Issue ${action}`));
      track('Update Issue', {
        status: action,
      });
    },
  });

  async function handleClick(event: React.MouseEvent): Promise<any> {
    event.preventDefault();
    const newStatus = status === 'open' ? 'closed' : 'open';
    await updateIssue({ variables: { id, status: newStatus } });
    onComplete?.();
    track('Update Issue', {
      status: newStatus,
    });
  }

  if (renderExternal) {
    return renderExternal({ status: _data?.updateIssue?.status || status, loading, handleClick });
  }

  function render() {
    if (loading) {
      return (
        <CircleIcon
          bg="primary"
          color="fg"
          icon="loader"
          iconProps={{ position: 'relative', top: '-1px' }}
          spinner={true}
        />
      );
    }

    if (status === 'closed') {
      return <CircleIcon bg="secondary" color="fg" icon="check" />;
    }

    return <CircleIcon bg="neutralsGradient.15" color="transparent" icon="check" />;
  }

  return <ResetButton onClick={handleClick}>{render()}</ResetButton>;
};

export default IssueStatusToggle;
