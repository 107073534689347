import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';
import ResetButton from './ResetButton';

type SidebarContentProps = SystemProps & { collapsed: boolean };

export const SIDEBAR_WIDTH = '350px';

const Sidebar = styled.aside`
  display: flex;
  height: 100%;
  overflow: auto;
  position: relative;

  .nice-dates-popover {
    position: fixed !important;
    width: 370px !important;
  }
`;

const SidebarContent = styled.div<SidebarContentProps>`
  border-top: 1px solid transparent;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease-in-out;
  width: 100%;

  ${props =>
    props.bg === 'bg' &&
    css`
      border-color: ${themeGet('colors.bgDark')};
    `};

  ${props =>
    props.collapsed &&
    css`
      transform: translateX(-100%);
    `};

  ${system}
`;

const SidebarContentInner = styled.div<{ collapsed: boolean }>`
  height: 100%;
  overflow: auto;
  padding: ${themeGet('space.l')};

  ${props =>
    props.collapsed &&
    css`
      display: none;
    `};
`;

const SidebarToggle = styled(ResetButton)`
  color: ${themeGet('colors.subdued')};
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease-in-out;

  &:active,
  &:focus,
  &:hover {
    color: ${themeGet('colors.fg')};
  }
`;

export { Sidebar as default, SidebarContent, SidebarContentInner, SidebarToggle };
