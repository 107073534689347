import { themeGet } from '@styled-system/theme-get';
import styled, { keyframes } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const HEIGHT = '10px';
const WIDTH = '60px';

const bounce = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

const LoaderContainer = styled.div<SystemProps>`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  height: 100%;

  ${system}
`;

const Loader = styled.div<SystemProps>`
  background-color: ${themeGet('colors.bg')};
  border-radius: 20px;
  height: ${HEIGHT};
  overflow: hidden;
  position: relative;
  width: ${WIDTH};

  &::before {
    animation: ${bounce} 0.35s linear alternate infinite;
    background-color: ${props => themeGet(`colors.${props.color}`)(props)};
    border-radius: ${themeGet('radii.base')};
    content: '';
    display: block;
    height: ${HEIGHT};
    left: 0;
    position: absolute;
    top: 0;
    width: calc(${WIDTH} / 2);
  }

  > b {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    -wrap: normal !important;
  }

  ${system}
`;

const LoaderLabel = styled.b<SystemProps>`
  color: ${themeGet('colors.subdued')};
  font-size: ${themeGet('fontSizes.s')};
  font-weight: ${themeGet('fontWeights.normal')};
  margin-top: ${themeGet('space.s')};

  ${system}
`;

export { Loader as default, LoaderContainer, LoaderLabel };
