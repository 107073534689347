import React from 'react';

import { UpdatedAt } from 'components';

import { Box } from 'styled';

interface UpdatedAtProps {
  refreshedAt: Date;
}

const RefreshedAt: React.FC<UpdatedAtProps> = ({ refreshedAt }: UpdatedAtProps) => (
  <Box bottom="0" left="0" position="absolute" right="0" zIndex={800}>
    <UpdatedAt date={refreshedAt} />
  </Box>
);

export default RefreshedAt;
