import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Icon = styled.div<SystemProps>`
  align-self: center;
  display: inline-flex;
  vertical-align: middle;

  ${system}
`;

export default Icon;
