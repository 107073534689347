import omit from 'lodash/omit';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useIssues } from 'client/Issue';
import { Issue } from 'client/Issue/types';
import { useUsers } from 'client/User';
import { User } from 'client/User/types';

import { useQueryParams } from 'hooks';
import { track } from 'utils';

import { useIssuesFilterState } from 'providers/IssuesFilterProvider';
import { showModal, useModalDispatch } from 'providers/ModalProvider';

import {
  CreatedAt,
  IssueSeverity,
  IssueStatusToggle,
  Loader,
  RequiredPermission,
  Table,
} from 'components';
import { DefaultEmptyMessage, DefaultErrorMessageWithError } from 'components/QueryCell';

import { Avatar, Box, Circle, ResetButton, VerticalContent } from 'styled';

interface Event {
  preventDefault: Function;
}

const IssuesTable: React.FC = () => {
  const { data: usersData, loading: usersLoading } = useUsers();
  const issuesFilterState = useIssuesFilterState();
  const variables = omit(issuesFilterState, 'createdValue');
  const { loading, data, error } = useIssues(variables);
  const issues = data?.issues || [];

  const modalDispatch = useModalDispatch();
  const query = useQueryParams();
  const history = useHistory();
  const issueId = query.get('id');

  useEffect(() => {
    const issue = issues.find(i => i.id === issueId);
    if (issue) {
      modalDispatch(
        showModal('Issue', {
          title: issue.title,
          issue: issue,
          onClose: () => history.push('/issues'),
        })
      );
      track('View Issue');
    }
  }, [issueId, issues, modalDispatch, history]);

  const handleIssueClick =
    (issue: Issue) =>
    (event: Event): void => {
      event.preventDefault();
      history.push({
        pathname: '/issues',
        search: `?id=${issue.id}`,
      });
    };

  if (loading && !issues.length) {
    return (
      <Box mt="xl">
        <Loader />
      </Box>
    );
  }

  if (error) {
    return (
      <VerticalContent>
        <DefaultErrorMessageWithError error={error} action="useIssues()" />
      </VerticalContent>
    );
  }

  if (issues.length === 0) {
    return (
      <VerticalContent>
        <DefaultEmptyMessage content="issues" />
      </VerticalContent>
    );
  }

  function filterData(_issues: Issue[]): Issue[] {
    const query = issuesFilterState.search;
    const isSearching = query !== '';

    if (isSearching) {
      return _issues.filter((issue: Issue) => {
        const regex = new RegExp(query, 'gi');
        const match = issue.title.match(regex);
        if (match) return true;
        return false;
      });
    }

    return _issues;
  }

  function getData() {
    return filterData(issues).map((issue: Issue) => {
      const user = usersData?.users?.find(
        (u: User) => u.name === issue.assignee || u.email === issue.assignee
      );
      const avatar = user?.picture;
      return {
        status: (
          <RequiredPermission permission="update:issues">
            <IssueStatusToggle status={issue.status || 'open'} id={issue.id} />
          </RequiredPermission>
        ),
        title: (
          <ResetButton onClick={handleIssueClick(issue)} lineHeight="base" textAlign="left">
            <Box color="fg" fontSize="base">
              {issue.title}
            </Box>
          </ResetButton>
        ),
        created: <CreatedAt time={issue.createdAt} />,
        severity: <IssueSeverity level={issue.severity} />,
        assignee: usersLoading ? (
          <Circle bg="bg" size="30px" />
        ) : avatar ? (
          <Avatar src={avatar} alt="" height="30" width="30" />
        ) : (
          <Circle bg="subdued" size="30px" />
        ),
      };
    });
  }

  return (
    <Table
      grid="5% 1fr 20% 12% 12%"
      headings={[
        { key: 'status', label: '' },
        { key: 'title', label: 'Title' },
        { key: 'created', label: 'Created' },
        { key: 'severity', label: 'Severity' },
        { key: 'assignee', label: 'Assignee' },
      ]}
      data={getData()}
    />
  );
};

export default IssuesTable;
