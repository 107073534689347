import { startOfDay } from 'date-fns';
import groupBy from 'lodash/groupBy';
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect } from 'react';

import { Baggage, BaggageData, MassagedBaggageData } from 'client/Baggage/types';

import { useDataLimiter } from 'hooks';

import { Table, Tile } from 'components';
import { DetailsComponentProps } from 'components/ChartDetails';

import DetailsNav from './DetailsNav';

const LIMIT = 20;

const BaggageDetails: React.FC<DetailsComponentProps> = ({ data }: DetailsComponentProps) => {
  const dataLimiter = useDataLimiter(LIMIT);

  useEffect(() => {
    dataLimiter.setData(data);
  }, [data, dataLimiter]);

  if (!data) return null;

  if (dataLimiter.status === 'loading') return null;

  function getData() {
    const filteredData = (data as Baggage[])
      .map((baggage: Baggage) =>
        baggage.data.filter(
          (datum: BaggageData) => datum.area.split('.').shift() === 'EDS_Statistics'
        )
      )
      // Removing the empty arrays and grabbing the nested array.
      .filter((array: BaggageData[]) => array.length)
      .flat();

    const keyMap: { [key: string]: keyof MassagedBaggageData } = {
      Alarmed_Bags: 'alarmed',
      Cleared_Bags: 'cleared',
      Faults: 'faults',
      Runtime: 'runtime',
      Start_Operation: 'start',
      End_Operation: 'end',
    };

    const dates = groupBy(filteredData, fd => fd.date);
    const _data = Object.values(dates).map(date => {
      return date?.reduce(
        (
          acc,
          data
        ): {
          [key: string]: MassagedBaggageData;
        } => {
          const parts = data.area.split('.');
          const machine = parts.pop();
          if (!machine) return acc;
          if (!(machine in acc)) acc[machine] = {};
          acc[machine][keyMap[data.key]] = data.value;
          return acc;
        },
        {} as { [key: string]: MassagedBaggageData }
      );
    });

    if (!_data) return [];

    const finalData = _data
      .map(d => {
        return Object.entries(d).map(([key, value]) => ({
          machine: key,
          ...value,
        }));
      })
      .flat();

    const sortedData = finalData.sort((a, b) => {
      const date1 = new Date(a?.start || '');
      const date2 = new Date(b?.start || '');
      return startOfDay(date1).getTime() - startOfDay(date2).getTime();
    });
    return sortedData;
  }

  const _data = getData();

  return (
    <Tile
      title={`Baggage (${_data.length} result${_data.length === 1 ? '' : 's'})`}
      icon="briefcase"
      contentAlignment="top"
      contentPadding="0"
    >
      <Table
        grid="repeat(5, 0.75fr) repeat(2, 1.25fr)"
        striped={false}
        bordered={true}
        cellPaddingX="s"
        cellPaddingY="s"
        dataFontSize="s"
        gridGap="xs"
        headings={[
          { key: 'machine', label: 'EDS Machine' },
          { key: 'alarmed', label: 'Alarmed Bags' },
          { key: 'cleared', label: 'Cleared Bags' },
          { key: 'faults', label: 'Faults' },
          { key: 'runtime', label: 'Runtime' },
          { key: 'start', label: 'Start Operation' },
          { key: 'end', label: 'End Operation' },
        ]}
        data={_data}
      />
      <DetailsNav dataLimiter={dataLimiter} />
    </Tile>
  );
};

export default BaggageDetails;
