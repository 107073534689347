import React from 'react';

import { TILE_KEYS } from 'config/tiles';

import { showModal, useModalDispatch } from 'providers/ModalProvider';

import { Tile } from 'components';
import { RemoteParking } from 'components/Tiles';

export const RemoteParkingTile: React.FC = () => {
  const modalDispatch = useModalDispatch();
  if (process.env.REACT_APP_CONFIG_REMOTE_PARKING !== 'true') return null;
  return (
    <Tile
      title="Remote Parking"
      icon="clipboard"
      floatingHeader={true}
      contentAlignment="top"
      contentPadding="0"
      modal="Default"
      modalProps={{
        title: 'Remote Parking',
        icon: { name: 'clipboard' },
        large: true,
        children: <RemoteParking showTable={true} showMap={true} mapHeight="400px" />,
      }}
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Remote Parking Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.REMOTE_PARKING,
              })
            ),
        },
      ]}
    >
      <RemoteParking showMap={true} />
    </Tile>
  );
};

export default RemoteParkingTile;
