import React from 'react';

import theme from 'config/theme';

import { Box } from 'styled';

interface ChartData {
  color: string;
  total: number;
  value: number;
}

interface Props {
  backgroundColor?: string;
  colors?: string[];
  percentages: number[];
  text?: string;
  size?: string;
}

const DEFAULT_BAR_COLOR = theme?.colors?.primary;
const DEFAULT_BACKGROUND_COLOR = theme?.colors?.neutralsGradient?.[10];

const MultipleCircularLoading: React.FC<Props> = ({
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  colors = [DEFAULT_BAR_COLOR],
  percentages = [0],
  text = '',
  size = '170px',
}: Props) => {
  const buildChartData = () => {
    // Group percentages with colors
    const chartObjects = percentages?.map((percent, index) => ({
      value: percent,
      color: colors?.[index] || DEFAULT_BAR_COLOR,
    }));

    // Sort percentages low to high
    const sortedChartObjects = chartObjects.sort((a, b) => a?.value - b?.value);

    // Get chart visual percent for overlays
    let total = 100;
    const chartData = sortedChartObjects?.map((percent, index) => {
      if (index) total = total - sortedChartObjects?.[index - 1]?.value;
      return {
        ...percent,
        total: !percent?.value ? 0 : total,
      };
    });
    return chartData;
  };

  const renderBar = (chartData: ChartData, index: number) => (
    <Box position="absolute" width={size} height={size} key={`${chartData?.value}-${index}`}>
      <svg viewBox="0 0 36 36">
        <path
          className="circle"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke={`${chartData?.color || DEFAULT_BAR_COLOR}`}
          strokeWidth="2.5px"
          strokeDasharray={`${chartData?.total}, 100`}
          fill={index === 0 ? backgroundColor : 'transparent'}
        />
      </svg>
      {index === 0 && (
        <Box display="flex" width="100%" position="absolute" justifyContent="center" top="43%">
          <Box fontSize="l" fontWeight="bold" key={`${chartData?.value}-${index}-text`}>
            {text}
          </Box>
        </Box>
      )}
    </Box>
  );

  return <>{buildChartData()?.map((percent, index) => renderBar(percent, index))}</>;
};

export default MultipleCircularLoading;
