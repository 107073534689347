import React from 'react';
import theme from 'config/theme';

interface Props {
  backgroundColor?: string;
  color?: string;
  percent: number | string;
}

const DEFAULT_BAR_COLOR = theme?.colors?.primary;
const DEFAULT_BACKGROUND_COLOR = theme?.colors?.neutralsGradient?.[10];

const CircularLoading: React.FC<Props> = ({
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  color = DEFAULT_BAR_COLOR,
  percent,
}: Props) => (
  <svg viewBox="0 0 36 36">
    <path
      className="circle"
      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      stroke={color}
      strokeDasharray={`${percent}, 100`}
      fill={backgroundColor}
    />
  </svg>
);

export default CircularLoading;
