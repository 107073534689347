import { gql, useQuery } from '@apollo/client';

const ALERT_SETTINGS = gql`
  query AlertSettings {
    alertSettings {
      scans_threshold
      scans_frequency
      parking_threshold
      parking_frequency
      security_threshold
      security_frequency
      bathrooms_threshold
      bathrooms_frequency
      feedback_threshold
      feedback_frequency
    }
  }
`;

export default function useAlertSettings() {
  return useQuery(ALERT_SETTINGS, {
    fetchPolicy: 'network-only',
  });
}
