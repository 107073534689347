import { Heading } from 'components/Table';
import {
  BathroomTrafficTile,
  CustomerFeedbackTile,
  FacilityConditionsTile,
  RemoteParkingTile,
  SalesTile,
} from 'components/TileManager/';

import {
  AirfieldTile,
  AlertsTile,
  ArrivalsTile,
  CapacitySummaryTile,
  CapacityTile,
  CargoRevenueTile,
  DeparturesTile,
  FlightSummaryTile,
  ParkingTile,
  ProjectionsTile,
  RVRTile,
  SecurityTile,
  ThroughputTile,
  TrafficTile,
  TSACountsTile,
  WeatherTile,
} from 'tiles';

import {
  AirfieldOptions,
  AlertsOptions,
  ArrivalsOptions,
  BathroomTrafficOptions,
  CapacityOptions,
  CapacitySummaryOptions,
  CargoRevenueOptions,
  CustomerFeedbackOptions,
  DeparturesOptions,
  FacilityConditionsOptions,
  FlightSummaryOptions,
  ParkingOptions,
  RemoteParkingOptions,
  SalesOptions,
  ThroughputOptions,
} from 'components/TileOptions';

import { AIRPORT_CODE } from './constants';

export type Size = {
  minCol: number;
  maxCol: number;
  minRow: number;
  maxRow: number;
};

export interface Tile {
  title: string;
  size?: Size;
  component: Function;
  description: string;
  requiredPermission?: string;
  props?: object;
  headings?: Heading[];
  additionalHeadings?: Heading[];
  configSetting?: boolean;
  status?: 'NEW';
}

export interface Grid {
  [key: string]: {
    [key: number]: string[];
  };
}

export type TileKeys =
  | 'Alerts'
  | 'Airfield'
  | 'Arrivals'
  | 'Bathroom Traffic'
  | 'Capacity Summary'
  | 'Capacity'
  | 'Cargo Revenue'
  | 'Customer Feedback'
  | 'Departures'
  | 'Facility'
  | 'Flight Summary'
  | 'Flights'
  | 'Parking'
  | 'Passenger Projection'
  | 'RVR'
  | 'Sales'
  | 'Security'
  | 'Throughput'
  | 'Traffic'
  | 'TSA Counts'
  | 'Remote Parking'
  | 'Weather';

export const MAX_PAGE1_TILES = 8;
export const MAX_TILES = 16;

export const TILE_KEYS: { [key: string]: TileKeys } = {
  AIRFIELD: 'Airfield',
  ALERTS: 'Alerts',
  ARRIVALS: 'Arrivals',
  BATHROOM_TRAFFIC: 'Bathroom Traffic',
  CAPACITY_SUMMARY: 'Capacity Summary',
  CAPACITY: 'Capacity',
  CARGO_REVENUE: 'Cargo Revenue',
  CUSTOMER_FEEDBACK: 'Customer Feedback',
  DEPARTURES: 'Departures',
  FACILITY_CONDITIONS: 'Facility',
  FLIGHT_SUMMARY: 'Flight Summary',
  PARKING: 'Parking',
  PROJECTIONS: 'Passenger Projection',
  REMOTE_PARKING: 'Remote Parking',
  RVR: 'RVR',
  SALES: 'Sales',
  SECURITY: 'Security',
  THROUGHPUT: 'Throughput',
  TSA_COUNTS: 'TSA Counts',
  TRAFFIC: 'Traffic',
  WEATHER: 'Weather',
};

export interface TileView {
  label: string;
  value: string;
}

export interface TileViews {
  [key: string]: TileView[];
}

export interface TileOption {
  [key: string]: Function;
}

export const TILE_OPTIONS: TileOption = {
  [TILE_KEYS.AIRFIELD]: AirfieldOptions,
  [TILE_KEYS.ALERTS]: AlertsOptions,
  [TILE_KEYS.ARRIVALS]: ArrivalsOptions,
  [TILE_KEYS.BATHROOM_TRAFFIC]: BathroomTrafficOptions,
  [TILE_KEYS.CAPACITY]: CapacityOptions,
  [TILE_KEYS.CAPACITY_SUMMARY]: CapacitySummaryOptions,
  [TILE_KEYS.CARGO_REVENUE]: CargoRevenueOptions,
  [TILE_KEYS.CUSTOMER_FEEDBACK]: CustomerFeedbackOptions,
  [TILE_KEYS.DEPARTURES]: DeparturesOptions,
  [TILE_KEYS.FACILITY_CONDITIONS]: FacilityConditionsOptions,
  [TILE_KEYS.FLIGHT_SUMMARY]: FlightSummaryOptions,
  [TILE_KEYS.PARKING]: ParkingOptions,
  [TILE_KEYS.REMOTE_PARKING]: RemoteParkingOptions,
  [TILE_KEYS.SALES]: SalesOptions,
  [TILE_KEYS.THROUGHPUT]: ThroughputOptions,
};

export const TILES: Tile[] = [
  // ===========================================================================
  // AIRFIELD TILE
  // ===========================================================================
  {
    title: TILE_KEYS.AIRFIELD,
    component: AirfieldTile,
    description: `Airfield NOTAMs${
      process.env.REACT_APP_INTEGRATION_AIRFIELD_TEMPS === 'true' ? ' and temperatures' : ''
    }`,
    configSetting: process.env.REACT_APP_CONFIG_NOTAMS === 'true',
  },
  // ===========================================================================
  // ALERTS TILE
  // ===========================================================================
  {
    title: TILE_KEYS.ALERTS,
    component: AlertsTile,
    description: 'Active alerts',
    configSetting: true,
    headings: [
      { key: 'alert', label: 'Alert', sortByKey: 'description' },
      { key: 'time', label: 'Time', sortByKey: 'updatedAt' },
    ],
  },
  // ===========================================================================
  // ARRIVALS TILE
  // ===========================================================================
  {
    title: TILE_KEYS.ARRIVALS,
    component: ArrivalsTile,
    description: 'Cargo and passenger arrival flights',
    configSetting: true,
    headings: [
      { key: 'city', label: 'City' },
      { key: 'flightNumber', label: 'Flight', sortByKey: 'flightNum' },
      { key: 'time', label: 'Time' },
      { key: 'status', label: 'Status', sortByKey: 'statusText' },
    ],
    additionalHeadings: [
      { key: 'gate', label: 'Gate', sortByKey: 'arrivalGate' },
      { key: 'weight', label: 'Weight (lbs)' },
      { key: 'aircraft', label: 'Aircraft', sortByKey: ['manufacturer', 'makeModel'] },
    ],
  },
  // ===========================================================================
  // BATHROOM TRAFFIC TILE
  // ===========================================================================
  {
    title: TILE_KEYS.BATHROOM_TRAFFIC,
    component: BathroomTrafficTile,
    description: 'Bathroom traffic counts and inspection data',
    configSetting: process.env.REACT_APP_CONFIG_BATHROOMS === 'true',
    headings: [
      { key: 'bathroom', label: 'Bathroom', sortByKey: 'id' },
      { key: 'area', label: 'Area' },
      { key: 'count', label: 'Count' },
      { key: 'updated', label: 'Updated', sortByKey: 'updatedAt' },
    ],
  },
  // ===========================================================================
  // CAPACITY SUMMARY TILE
  // ===========================================================================
  {
    title: TILE_KEYS.CAPACITY_SUMMARY,
    component: CapacitySummaryTile,
    description: 'Load factor across major passenger airlines',
    configSetting: process.env.REACT_APP_CONFIG_BOOKINGS === 'true',
    headings: [
      { key: 'airline', label: 'Airline', sortByKey: 'name' },
      { key: 'status', label: 'Status', sortByKey: 'bookings' },
      { key: 'load', label: 'Load' },
    ],
    additionalHeadings: [
      { key: 'onTime', label: 'On Time' },
      { key: 'delayed', label: 'Delayed' },
      { key: 'canceled', label: 'Canceled' },
      { key: 'total', label: 'All' },
    ],
  },
  // ===========================================================================
  // CAPACITY TILE
  // ===========================================================================
  {
    title: TILE_KEYS.CAPACITY,
    component: CapacityTile,
    description: 'Per-flight breakdown of flight capacity based on scans + bookings',
    configSetting: process.env.REACT_APP_CONFIG_BOOKINGS === 'true',
    headings: [
      { key: 'city', label: 'City' },
      { key: 'flightNumber', label: 'Flight', sortByKey: 'flightNum' },
      { key: 'time', label: 'Time' },
      { key: 'capacity', label: 'Capacity' },
    ],
    additionalHeadings: [
      { key: 'gate', label: 'Gate', sortByKey: 'departureGate' },
      { key: 'bookings', label: 'Bookings' },
      { key: 'load', label: 'Load' },
    ],
  },
  // ===========================================================================
  // CARGO REVENUE TILE
  // ===========================================================================
  {
    title: TILE_KEYS.CARGO_REVENUE,
    component: CargoRevenueTile,
    description: 'Cargo landed-weight revenue',
    configSetting: process.env.REACT_APP_CONFIG_CARGO === 'true',
    requiredPermission: 'tile:cargo',
    headings: [
      { key: 'airline', label: 'Airline' },
      { key: 'operations', label: 'Operations' },
      { key: 'weight', label: 'Weight (lbs)', sortByKey: 'weightNum' },
      { key: 'cost', label: 'Revenue', sortByKey: 'costNum' },
    ],
  },

  // ===========================================================================
  // CUSTOMER FEEDBACK TILE
  // ===========================================================================
  {
    title: TILE_KEYS.CUSTOMER_FEEDBACK,
    component: CustomerFeedbackTile,
    description: 'Customer Feedback from social channels',
    configSetting: process.env.REACT_APP_CONFIG_FEEDBACK === 'true',
  },
  // ===========================================================================
  // DEPARTURES TILE
  // ===========================================================================
  {
    title: TILE_KEYS.DEPARTURES,
    component: DeparturesTile,
    description: 'Cargo and passenger departure flights',
    configSetting: true,
    headings: [
      { key: 'city', label: 'City' },
      { key: 'flightNumber', label: 'Flight', sortByKey: 'flightNum' },
      { key: 'time', label: 'Time' },
      { key: 'status', label: 'Status', sortByKey: 'statusText' },
    ],
    additionalHeadings: [
      { key: 'gate', label: 'Gate', sortByKey: 'departureGate' },
      { key: 'weight', label: 'Weight (lbs)' },
      { key: 'aircraft', label: 'Aircraft', sortByKey: ['manufacturer', 'makeModel'] },
    ],
  },
  // ===========================================================================
  // FLIGHT SUMMARY TILE
  // ===========================================================================
  {
    title: TILE_KEYS.FLIGHT_SUMMARY,
    component: FlightSummaryTile,
    description: 'Flight breakdown across cargo and passenger flights',
    configSetting: true,
  },
  // ===========================================================================
  // PARKING TILE
  // ===========================================================================
  {
    title: TILE_KEYS.PARKING,
    component: ParkingTile,
    description: 'Garage capacity and trends',
    configSetting: process.env.REACT_APP_CONFIG_PARKING === 'true',
    headings: [
      { key: 'lot', label: 'Lot' },
      { key: 'trend', label: 'Change', sortByKey: 'percentage' },
      { key: 'spaces', label: 'Available', sortByKey: 'spotsAvailable' },
      { key: 'load', label: '% Full', sortByKey: 'spotsOccupied' },
    ],
    status: process.env.REACT_APP_AIRPORT_CODE === 'RNO' ? 'NEW' : undefined,
  },
  // ===========================================================================
  // PROJECTIONS TILE
  // ===========================================================================
  {
    title: TILE_KEYS.PROJECTIONS,
    component: ProjectionsTile,
    description: 'Passenger projection from the TSA',
    configSetting: process.env.REACT_APP_CONFIG_PROJECTIONS === 'true',
  },
  // ===========================================================================
  // TSA COUNTS TILE
  // ===========================================================================
  {
    title: TILE_KEYS.TSA_COUNTS,
    component: TSACountsTile,
    description: 'Daily passenger counts from the TSA',
    configSetting: process.env.REACT_APP_CONFIG_TSA_COUNTS === 'true',
    status: 'NEW',
  },
  // ===========================================================================
  // REMOTE PARKING TILE
  // ===========================================================================
  {
    title: TILE_KEYS.REMOTE_PARKING,
    component: RemoteParkingTile,
    description: 'Remote parking information',
    configSetting: process.env.REACT_APP_CONFIG_REMOTE_PARKING === 'true',
  },
  // ===========================================================================
  // RVR TILE
  // ===========================================================================
  {
    title: TILE_KEYS.RVR,
    component: RVRTile,
    description: 'View the current Runway Visual Range (RVR)',
    configSetting: process.env.REACT_APP_CONFIG_RVR === 'true',
    status: 'NEW',
  },
  // ===========================================================================
  // SALES TILE
  // ===========================================================================
  {
    title: TILE_KEYS.SALES,
    component: SalesTile,
    description: 'Parking and concession sales data',
    configSetting: process.env.REACT_APP_CONFIG_SALES === 'true',
    requiredPermission: 'tile:sales',
    headings: [
      { key: 'title', label: 'Title' },
      { key: 'description', label: 'Description' },
      { key: 'date', label: 'Date' },
      { key: 'amount', label: 'Amount' },
    ],
  },
  // ===========================================================================
  // SECURITY TILE
  // ===========================================================================
  {
    title: TILE_KEYS.SECURITY,
    component: SecurityTile,
    description: 'Security wait times and trends for TSA Pre-Check and General',
    configSetting: process.env.REACT_APP_CONFIG_WAIT_TIMES === 'true',
  },
  // ===========================================================================
  // THROUGHPUT TILE
  // ===========================================================================
  {
    title: TILE_KEYS.THROUGHPUT,
    component: ThroughputTile,
    description: `Daily throughput for ${AIRPORT_CODE} and regional airports`,
    configSetting: true,
    headings: [
      { key: 'airport', label: 'Airport' },
      { key: 'date', label: 'Date' },
      { key: 'total', label: 'Total', sortByKey: 'totalYesterday' },
    ],
    additionalHeadings: [
      { key: 'week', label: 'One Week Ago', sortByKey: 'totalOneWeekAgo' },
      { key: 'year', label: 'One Year Ago', sortByKey: 'totalOneYearAgo' },
    ],
  },
  // ===========================================================================
  // TRAFFIC TILE
  // ===========================================================================
  {
    title: TILE_KEYS.TRAFFIC,
    component: TrafficTile,
    description: 'Traffic flow and alerts',
    configSetting: true,
  },
  // ===========================================================================
  // WEATHER TILE
  // ===========================================================================
  {
    title: TILE_KEYS.WEATHER,
    component: WeatherTile,
    description: 'Revelant airport delays and weather information',
    configSetting: true,
  },
  // ===========================================================================
  // FACILITY CONDITIONS TILE
  // ===========================================================================
  {
    title: TILE_KEYS.FACILITY_CONDITIONS,
    component: FacilityConditionsTile,
    description: 'Facility conditions information',
    configSetting: process.env.REACT_APP_CONFIG_FACILITY === 'true',
    headings: [
      { key: 'feed', label: 'Feed' },
      { key: 'assets', label: 'Assets' },
    ],
    additionalHeadings: [
      { key: 'importance', label: 'Importance' },
      { key: 'generator', label: 'Generator' },
      { key: 'issue', label: 'Issue' },
    ],
  },
];
