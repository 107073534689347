import { gql, useQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { CargoRevenueArgs, CargoRevenueDeparturesQuery } from 'data/Cargo/types';

const CARGO_REVENUE_DEPARTURES = gql`
  query CargoRevenueDepartures($between: [DateTime]!) {
    cargoRevenueDepartures(between: $between) {
      airlines {
        airline
        operations
        weight
      }
      totalOperations
      totalWeight
    }
  }
`;

const now = new Date();
const start = startOfDay(now);
const end = endOfDay(now);

export default function useCargoRevenueDepartures({
  refresh = true,
  skip = false,
}: CargoRevenueArgs = {}): CargoRevenueDeparturesQuery {
  const variables = pickBy(
    {
      between: [start.getTime(), end.getTime()],
    },
    identity
  );

  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(CARGO_REVENUE_DEPARTURES, options);
  const data = query?.data?.cargoRevenueDepartures;
  return {
    airlines: data?.airlines,
    totalOperations: data?.totalOperations,
    totalWeight: data?.totalWeight,
    ...query,
  };
}
