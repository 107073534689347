import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import system from './lib/system';
import { DownshiftInputProps, SystemProps } from './lib/types';

export interface TextInputProps extends DownshiftInputProps {
  icon?: string;
  type?: string;
  inputTitle?: string;
  helperText?: string;
}

const TextInput = styled.input<TextInputProps>`
  background-color: transparent;
  border: 1px solid ${themeGet('colors.border')};
  border-radius: ${themeGet('radii.base')};
  color: ${themeGet('colors.fg')};
  font-family: ${themeGet('fontFamily')};
  font-size: ${themeGet('fontSizes.s')};
  padding: ${themeGet('space.s')};
  width: 100%;

  &:focus {
    border-color: ${themeGet('colors.primary')};
    outline: none;
  }

  &[disabled] {
    opacity: 0.6;
  }

  ${props =>
    props.icon &&
    css`
      padding-left: calc(${themeGet('space.l')} + ${themeGet('space.xs')});
    `};

  ${system}
`;

const TextInputIcon = styled.div<SystemProps>`
  left: ${themeGet('space.s')};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${system}
`;

export { TextInput as default, TextInputIcon };
