import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Divider = styled.div<SystemProps>`
  background-color: ${props =>
    themeGet(`colors.${props.color}`)(props) || themeGet('colors.border')(props)};
  height: ${props => props.height || '1px'};
  width: ${props => props.width || '100%'};

  ${system}
`;

export default Divider;
