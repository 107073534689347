import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const SearchInput = styled.div<SystemProps>`
  position: relative;

  > input {
    padding-right: ${themeGet('space.l')};
  }

  ${system}
`;

const SearchClearButton = styled.button<SystemProps>`
  background-color: transparent;
  border: 0;
  color: ${themeGet('colors.fg')};
  position: absolute;
  right: ${themeGet('space.s')};
  top: 50%;
  transform: translateY(-50%);

  ${system}
`;

export { SearchInput as default, SearchClearButton };
