import { gql, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { HistoricalWeatherArgs, HistoricalWeatherQuery } from '../types';

const GET_HISTORICAL_WEATHER_DATA = gql`
  query HistoricalWeatherData($latitude: Float!, $longitude: Float!, $days: Int) {
    historicalWeatherData(latitude: $latitude, longitude: $longitude, days: $days) {
      date
      conditionCode
      summary
      temperature
    }
  }
`;

export default function useHistoricalWeather({
  latitude = Number(process.env.REACT_APP_AIRPORT_LATITUDE),
  longitude = Number(process.env.REACT_APP_AIRPORT_LONGITUDE),
  refresh = true,
  skip = false,
  days,
}: HistoricalWeatherArgs): HistoricalWeatherQuery {
  const variables = pickBy({ latitude, longitude, days }, identity);
  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_HISTORICAL_WEATHER_DATA, options);

  return {
    weather: query.data?.historicalWeatherData || [],
    ...query,
  };
}
