import React from 'react';

import { SortByOptions } from 'components';

const AlertsOptions: React.FC = () => {
  return (
    <SortByOptions
      stateKey="alerts"
      options={[
        { value: 'alert', label: 'Alert' },
        { value: 'time', label: 'Time' },
      ]}
    />
  );
};

export default AlertsOptions;
