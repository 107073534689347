import React from 'react';

import { NotAvailableBlankSlate, Tile } from 'components';

import RVR from './RVR';

export function RVRTile(): JSX.Element {
  if (process.env.REACT_APP_CONFIG_RVR !== 'true') return <NotAvailableBlankSlate />;

  return (
    <Tile title="RVR" icon="cloud" contentAlignment="top" contentPadding="0">
      <RVR />
    </Tile>
  );
}

export default RVRTile;
