import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

interface MenuListProps {
  side: string;
  status: string;
  width?: string;
}

const Menu = styled.div<SystemProps>`
  position: relative;

  ${system}
`;

const side =
  ({ side }: MenuListProps) =>
  (props: MenuListProps) => {
    if (side === 'left' || side === 'right') {
      return `${side}: -${themeGet('space.s')(props)};`;
    }

    if (side === 'center') {
      return css`
        left: 50%;
        transform: translateX(-50%);
      `;
    }
  };

const MenuList = styled.ol<MenuListProps>`
  background-color: ${themeGet('colors.fg')};
  border-radius: ${themeGet('radii.base')};
  box-shadow: ${themeGet('shadows.l')};
  color: ${themeGet('colors.bg')};
  list-style-type: none;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: calc(100% + ${themeGet('space.s')});
  transform: translateY(-${themeGet('space.xs')});
  transition: 0.3s ease-in-out;
  visibility: hidden;
  width: ${props => props.width};
  z-index: ${themeGet('zIndices.menu')};

  ${props =>
    props.status === 'open' &&
    css`
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    `};

  > *:not(:last-child) {
    border-bottom: 1px solid ${themeGet('colors.borderLight')};
  }

  ${side}
`;

const MenuListItem = styled.li<{ active: boolean }>`
  > * {
    color: ${themeGet('colors.bg')};
    display: block;
    padding: ${themeGet('space.s')} ${themeGet('space.base')};
    text-decoration: none;
    transition: 0.3s ease-in-out;

    &:active,
    &:focus,
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      color: ${themeGet('colors.bg')};
    }

    ${props =>
      props.active &&
      css`
        background-color: ${themeGet('colors.secondary')};
        color: ${themeGet('colors.fg')};

        &:active,
        &:focus,
        &:hover {
          background-color: ${themeGet('colors.secondary')};
          color: ${themeGet('colors.fg')};
        }
      `};
  }
`;

export { Menu as default, MenuList, MenuListItem };
