import { isSameDay, startOfDay } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import theme from 'config/theme';

type XAxisCustomOptions = {
  showProjectionBand?: boolean;
  intraday?: boolean;
};

export default function getAxis(
  startDate: Date,
  endDate: Date,
  options?: XAxisCustomOptions
): Highcharts.XAxisOptions {
  const airportTz =
    process.env.REACT_APP_AIRPORT_TIMEZONE || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const airportOffset = getTimezoneOffset(airportTz);
  const localOffset = getTimezoneOffset(localTz);
  const offsetDiff = airportOffset - localOffset;
  const plotLines: Highcharts.XAxisPlotLinesOptions[] | undefined = isSameDay(startDate, endDate)
    ? [
        {
          value: Date.now() + offsetDiff,
          color: theme?.colors?.neutralsGradient[3],
          width: 1,
        },
      ]
    : undefined;
  const plotBands = [];
  if (options?.showProjectionBand && Date.now() < endDate.getTime()) {
    plotBands.push({
      from: Date.now(),
      to: endDate.getTime(),
      color: theme?.colors?.neutralsGradient[8],
      label: {
        text: 'Projected data',
        style: {
          color: theme?.colors?.neutralsGradient[1],
        },
      },
    });
  }
  return {
    id: 'date',
    type: 'datetime',
    dateTimeLabelFormats: {
      hour: '%H:%M',
    },
    min: startDate.getTime(),
    // If we are only showing daily data, we should end the chart at the start of the last day
    max: (options?.intraday ? endDate : startOfDay(endDate)).getTime(),
    lineColor: theme?.colors?.border,
    tickColor: theme?.colors?.fg,
    labels: {
      style: {
        color: theme?.colors?.subdued,
      },
    },
    plotBands,
    crosshair: true,
    plotLines,
  };
}
