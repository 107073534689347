import {
  addDays,
  addMinutes,
  differenceInCalendarDays,
  format,
  isSameDay,
  parseISO,
  startOfDay,
  startOfHour,
} from 'date-fns';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';

import { Booking } from 'client/Bookings/types';

import { filterByDayRange, getFilteredDataFromTime, isDaily } from 'statistics/charts/utils';
import { STAT_TYPES } from 'statistics/stats/constants';
import { ChartDatum, DataObject, StatsData } from 'statistics/types';
import { getHalfHourMultiplier } from 'statistics/utils';

import { DetailsData, ProcessedDataObject } from './utils';

type Bookings = { [key: number]: Booking[] };

function getChartData(data: Bookings): ChartDatum[] {
  const bookingsData = Object.entries(data).map(([time, bookings]: [string, Booking[]]) => ({
    x: Number(time),
    y: sumBy(bookings, 'count'),
  }));
  return sortBy(bookingsData, 'x');
}

function getStatsData(startDate: Date, endDate: Date, data: { [key: string]: number }): StatsData {
  const numDays = differenceInCalendarDays(addDays(endDate, 1), startDate);

  const times = sortBy(Object.keys(data).map(Number));
  const lastTime = times[times.length - 1];
  let prevTime: number;
  let newDay: boolean;
  const highest = { value: -Infinity, context: '' };
  const lowest = { value: Infinity, context: '' };
  let sum = 0;
  let curDaySum = 0;
  sortBy(Object.keys(data), Number).forEach(time => {
    const timeAsNumber = Number(time);
    const value = data[timeAsNumber];
    sum += value;
    curDaySum += value;
    if (curDaySum > highest.value) {
      highest.value = curDaySum;
      highest.context = format(new Date(timeAsNumber), 'LL/dd/yy');
    }
    newDay = isDaily(startDate, endDate) || Boolean(prevTime && !isSameDay(prevTime, timeAsNumber));
    prevTime = timeAsNumber;
    if (newDay || lastTime === timeAsNumber) {
      if (curDaySum < lowest.value) {
        lowest.value = curDaySum;
        lowest.context = format(new Date(timeAsNumber), 'LL/dd/yy');
      }
      curDaySum = 0;
    }
  });

  return {
    [STAT_TYPES.BOOKINGS.AVERAGE]: {
      value: Math.ceil(sum / numDays),
      contextLabel: 'All bookings',
    },
    [STAT_TYPES.BOOKINGS.HIGHEST]: { value: highest.value, contextLabel: highest.context },
    [STAT_TYPES.BOOKINGS.LOWEST]: { value: lowest.value, contextLabel: lowest.context },
  };
}

function getDetailsData(data: Booking[]): DetailsData {
  return data;
}

function getFilteredData(data: Booking[]): Booking[] {
  return data.filter(
    booking => differenceInCalendarDays(new Date(booking.date), new Date(booking.reportDate)) === 1
  );
}

function getProcessedData(
  startDate: Date,
  endDate: Date,
  bookingsData: DataObject,
  dateRangeDays: string[]
): ProcessedDataObject {
  const { data: bookings, loading, error } = bookingsData;
  if (loading || error)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
      details: {
        loading,
        error,
        data: [],
      },
    };

  const filteredBookings = getFilteredData(bookings as Booking[]);

  const numDays = differenceInCalendarDays(endDate, startDate);
  const intervalData: { [key: string]: Booking[] } = {};
  const statsData: { [key: string]: number } = {};
  const detailsData: Booking[] = [];

  let multiples = getHalfHourMultiplier(startDate, endDate);
  let initializer = (multiple: number): void => {
    let time = new Date(startDate);
    time = startOfHour(time);
    time.setMinutes(time.getMinutes() + multiple * 30);
    intervalData[time.getTime()] = [];
    statsData[time.getTime()] = 0;
  };
  let aggregator = (booking: Booking): void => {
    // TODO: time zone. Date and time are in EST/EDT
    const time = parseISO(`${booking.date}T${booking.time}`);
    let halfHour = startOfHour(time);
    if (time.getMinutes() >= 30) {
      halfHour = addMinutes(halfHour, 30);
    }
    const timeKey = halfHour.getTime();
    if (intervalData[timeKey]) {
      intervalData[timeKey].push(booking);
      statsData[timeKey] += booking.count;
      detailsData.push(booking);
    }
  };

  if (isDaily(startDate, endDate)) {
    multiples = range(numDays + 1);
    initializer = (multiple: number): void => {
      let time = startOfDay(startDate);
      time = addDays(time, multiple);
      intervalData[time.getTime()] = [];
      statsData[time.getTime()] = 0;
    };
    aggregator = (booking: Booking): void => {
      // TODO: time zone. Date and time are in EST/EDT
      const time = parseISO(`${booking.date}T${booking.time}`);
      const timeKey = startOfDay(time).getTime();
      if (intervalData[timeKey]) {
        intervalData[timeKey].push(booking);
        statsData[timeKey] += booking.count;
        detailsData.push(booking);
      }
    };
  }

  multiples.forEach(initializer);
  filteredBookings.forEach(aggregator);

  const { byDayRange } = filterByDayRange(dateRangeDays);
  const filteredIntervalData = getFilteredDataFromTime(intervalData, dateRangeDays) as {
    [key: string]: Booking[];
  };
  const filteredDetailsData = detailsData.filter((datum: Booking) =>
    byDayRange(parseISO(datum.date))
  );
  const filteredStatsData = getFilteredDataFromTime(statsData, dateRangeDays);

  return {
    chart: {
      loading,
      error,
      data: getChartData(filteredIntervalData),
    },
    stats: {
      loading,
      error,
      data: getStatsData(startDate, endDate, filteredStatsData),
    },
    details: {
      loading,
      error,
      data: getDetailsData(filteredDetailsData),
    },
  };
}

export default { getProcessedData };
