import { useState } from 'react';

function useConfirmation(callback: Function) {
  const [status, setStatus] = useState('idle');

  function handleDestructiveClick(event: { preventDefault: Function }) {
    event.preventDefault();
    setStatus('waiting');
  }

  function handleConfirmClick(event: { preventDefault: Function }) {
    event.preventDefault();
    setStatus('confirmed');
    callback();
  }

  function handleCancelClick(event: { preventDefault: Function }) {
    event.preventDefault();
    setStatus('idle');
  }

  return { handleDestructiveClick, handleConfirmClick, handleCancelClick, status };
}

export default useConfirmation;
