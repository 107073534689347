import React from 'react';

import { useConfirmation } from 'hooks';

import { LinkButton } from 'components';

import { Box } from 'styled';

interface Props {
  onConfirm: Function;
  text: string;
}

const ConfirmationLink: React.FC<Props> = ({ onConfirm, text }) => {
  const {
    handleDestructiveClick,
    handleConfirmClick,
    handleCancelClick,
    status: confirmationStatus,
  } = useConfirmation(onConfirm);

  function getDeleteText() {
    switch (confirmationStatus) {
      case 'waiting': {
        return (
          <Box fontSize="s" fontWeight="bold" px="base">
            Are you sure?
          </Box>
        );
      }
      case 'confirmed': {
        return (
          <Box color="error" fontSize="s" fontWeight="bold" px="base">
            Deleting...
          </Box>
        );
      }
      default: {
        return (
          <LinkButton onClick={handleDestructiveClick} color="error">
            {text}
          </LinkButton>
        );
      }
    }
  }

  return (
    <Box alignItems="center" display="flex">
      {getDeleteText()}
      {confirmationStatus === 'waiting' && (
        <Box alignItems="center" display="flex">
          <LinkButton onClick={handleConfirmClick} color="error" mr="s" px="0">
            Yes
          </LinkButton>
          <LinkButton onClick={handleCancelClick} px="0">
            Cancel
          </LinkButton>
        </Box>
      )}
    </Box>
  );
};

export default ConfirmationLink;
