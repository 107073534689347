import { gql, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { SalesArgs, SalesQuery } from './types';

const GET_SALES = gql`
  query Sales($between: [DateTime], $offset: Int, $limit: Int, $order: [[String]], $amount: Float) {
    sales(between: $between, offset: $offset, limit: $limit, order: $order, amount: $amount) {
      id
      createdAt
      updatedAt
      amount
      description
      title
      date
    }
  }
`;

export default function useSales({
  between,
  offset,
  limit,
  order,
  amount,
  refresh = true,
  skip = false,
}: SalesArgs = {}): SalesQuery {
  const variables = pickBy(
    {
      between,
      offset,
      limit,
      order,
      amount,
    },
    identity
  );

  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_SALES, options);

  return {
    sales: query.data?.sales || [],
    ...query,
  };
}
