import React from 'react';
import { Redirect } from 'react-router-dom';

import { useUserData } from 'providers/UserDataProvider';

interface Props {
  permission: string;
  redirect?: boolean;
  redirectTo?: string;
  renderFallback?: Function;
}

const RequiredPermission: React.FC<Props> = ({
  children,
  permission,
  redirect,
  redirectTo,
  renderFallback,
}) => {
  const { permissions } = useUserData();
  const defaults = {
    redirectTo: '/',
  };

  if (!permissions?.includes(permission)) {
    if (redirect) return <Redirect to={redirectTo || defaults.redirectTo} />;
    if (renderFallback) return renderFallback();
    return null;
  }

  return children;
};

export default RequiredPermission;
