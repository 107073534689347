import React from 'react';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';

import Projections from './Projections';

export function ProjectionsTile(): JSX.Element {
  if (process.env.REACT_APP_CONFIG_PROJECTIONS !== 'true') return <NotAvailableBlankSlate />;

  return (
    <Tile title="Passenger Projection" icon="users" contentAlignment="top" contentPadding="0">
      <RequiredPermission
        permission="read:projections"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <Projections />
      </RequiredPermission>
    </Tile>
  );
}

export default ProjectionsTile;
