import uniq from 'lodash/uniq';

import theme from 'config/theme';

import { Route, TrafficAlert, TrafficFlow, TrafficPosition } from 'tiles/Traffic/types';

import { useTraffic } from 'providers/TrafficProvider';

type Breakdown = { [key: string]: TrafficAlert[] };

export const getRouteColor = (route: Route): string => {
  const timeRatio = route.time / route.historicTime;
  if (timeRatio >= 1.5) {
    return theme.colors.error;
  } else if (timeRatio >= 1.1) {
    return theme.colors.warning;
  } else {
    return theme.colors.success;
  }
};

export const getDecoratedFlow = (flow?: TrafficFlow): TrafficFlow | undefined => {
  if (!flow) return undefined;

  const decoratedFlowRoutes = flow?.routes?.map((route: Route) => {
    const latLngLine = route?.line?.map((line: TrafficPosition) => ({
      lat: line.y,
      lng: line.x,
    }));
    return {
      ...route,
      latLngLine,
      color: getRouteColor(route),
    };
  });

  return {
    ...flow,
    routes: decoratedFlowRoutes,
  };
};

function useTrafficDetails() {
  const traffic = useTraffic();
  const { alerts, flow } = traffic;
  const ROADS = (process?.env?.REACT_APP_TRAFFIC_ROADS || '').split(',');

  const filterAlerts = (alerts: TrafficAlert[]): TrafficAlert[] =>
    alerts.filter((a: TrafficAlert) => ROADS.find(r => a.street?.startsWith(r)));

  const filteredAlerts = filterAlerts(alerts);

  function getAlertsBreakdown(alerts: TrafficAlert[]) {
    const _alerts: Breakdown = {
      accidents: [],
      jams: [],
      badWeather: [],
      hazards: [],
      roadWorks: [],
      roadClosures: [],
      unknown: [],
    };

    alerts.forEach((alert: TrafficAlert) => {
      if (alert.type.match(/accident/i)) _alerts.accidents.push(alert);
      else if (alert.type.match(/jam/i)) _alerts.jams.push(alert);
      else if (alert.subtype.match(/weather/i)) _alerts.badWeather.push(alert);
      else if (alert.subtype.match(/^((?!(weather)).)*$/i)) _alerts.hazards.push(alert);
      else if (alert.type.match(/construction/i)) _alerts.roadWorks.push(alert);
      else if (alert.type.match(/road_closed/i)) _alerts.roadClosures.push(alert);
      else if (alert.type.match(/misc/i)) _alerts.unknown.push(alert);
      else _alerts.unknown.push(alert);
    });

    return _alerts;
  }

  const alertsBreakdown = getAlertsBreakdown(filteredAlerts);
  const { accidents, jams, badWeather, hazards, roadWorks, roadClosures, unknown } =
    alertsBreakdown;

  const getRoadAlerts = (road: string): TrafficAlert[] =>
    filteredAlerts.filter((a: TrafficAlert) => a.street?.startsWith(road));

  const getAccidentInformation = (): string => {
    const count = accidents.length;
    const _accidents = uniq(accidents.map((a: TrafficAlert) => a.street));
    return `Accident${count > 1 ? 's' : ''} on ${_accidents.join(', ')}`;
  };

  const getRoadAlertStatusColor = (road: string) => {
    const roadAlerts = getRoadAlerts(road);
    const roadAlertsBreakdown = getAlertsBreakdown(roadAlerts);
    const noAlerts = roadAlerts.length === 0;

    if (noAlerts) return 'success';
    if (roadAlertsBreakdown.accidents.length > 0) return 'error';

    const jamsBreakdown: Breakdown = {
      extreme: [],
      heavy: [],
      moderate: [],
      other: [],
    };
    roadAlertsBreakdown.jams.forEach((a: TrafficAlert) => {
      if (a.subtype.match(/stand_still/i)) jamsBreakdown.extreme.push(a);
      else if (a.subtype.match(/heavy/i)) jamsBreakdown.heavy.push(a);
      else if (a.subtype.match(/moderate/i)) jamsBreakdown.moderate.push(a);
      else jamsBreakdown.other.push(a);
    });

    if (jamsBreakdown.extreme.length > 1) return 'error';
    if (jamsBreakdown.heavy.length > 1 || jamsBreakdown.moderate.length) return 'warning';

    return 'success';
  };

  return {
    ...traffic,
    flow: getDecoratedFlow(flow),
    ROADS,
    accidents,
    roadClosures,
    hazards,
    jams,
    badWeather,
    roadWorks,
    unknown,
    getRoadAlertStatusColor,
    getAccidentInformation,
  };
}

export default useTrafficDetails;
