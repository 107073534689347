import { StatItem, StatsArgs } from 'statistics/types';

import { STAT_TYPES } from './constants';

export default {
  [STAT_TYPES.DAILY_THROUGHPUT.AVERAGE]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Average Throughput',
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  }),
  [STAT_TYPES.DAILY_THROUGHPUT.HIGHEST]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Highest Throughput',
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  }),
  [STAT_TYPES.DAILY_THROUGHPUT.LOWEST]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Lowest Throughput',
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  }),
};
