import { LatLng, LatLngBoundsLiteral } from 'leaflet';
import React from 'react';
import { CircleMarker, Map as LeafletMap, Rectangle, TileLayer } from 'react-leaflet';

import theme from 'config/theme';

import { RemoteParkingSpot } from 'client/RemoteParking/types';

import { usePopupWatcher } from 'providers/SelectedTableItemProvider';

import { Box } from 'styled';

import RemoteParkingPopup from './RemoteParkingPopup';

type RemoteParkingMapProps = {
  spots: RemoteParkingSpot[];
};

const RemoteParkingMap: React.FC<RemoteParkingMapProps> = ({ spots }: RemoteParkingMapProps) => {
  if (process.env.REACT_APP_CONFIG_REMOTE_PARKING !== 'true') return null;
  const options = {
    lat: (Number(process.env.REACT_APP_AIRPORT_LATITUDE) as number) + 0.003,
    lng: (Number(process.env.REACT_APP_AIRPORT_LONGITUDE) as number) + 0.006,
  };

  const rectangleBounds: LatLngBoundsLiteral = [
    [options.lat - 1000, options.lng - 1000],
    [options.lat + 1000, options.lng + 1000],
  ];

  return (
    <Box height="100%" position="relative" zIndex={0}>
      <LeafletMap
        center={[options.lat, options.lng]}
        zoom={15}
        attributionControl={false}
        zoomControl={false}
        minZoom={15}
      >
        <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png" />
        <Rectangle
          bounds={rectangleBounds}
          fillColor={theme.colors.notamsOverlay}
          fillOpacity={0.3}
          stroke={false}
        />
        {spots.map((spot, i) => (
          <Dot key={i} i={i} spot={spot} />
        ))}
      </LeafletMap>
    </Box>
  );
};

interface DotProps {
  i: number;
  spot: RemoteParkingSpot;
}

const Dot: React.FC<DotProps> = ({ spot }: DotProps) => {
  const { ref } = usePopupWatcher(spot.id);
  const isOccupied = spot.reservation.rentalStatus === 'O';
  const isReserved = spot.reservation.rentalStatus === 'U';
  const colorKey = isOccupied ? 'error' : isReserved ? 'warning' : 'success';
  const color = theme.colors[colorKey];

  return (
    <CircleMarker
      ref={ref}
      key={spot.asset.equipmentNumber}
      radius={3}
      weight={6}
      center={new LatLng(spot.asset.latitude, spot.asset.longitude)}
      color={color}
    >
      <RemoteParkingPopup className={`leaflet-popup-${colorKey}`} spot={spot} />
    </CircleMarker>
  );
};

export default RemoteParkingMap;
