import { format, isPast, isValid } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { CANCELED, COMPLETED, DELAYED, DIVERTED, ON_TIME } from 'data/Flights/constants';
import { ArrivalFlight } from 'data/Flights/types';

import { AircraftWeight } from 'config/weights';

type Cargo = ArrivalFlight & AircraftWeight & { cost?: number };
interface ConstructorArgs {
  flight: ArrivalFlight;
}

export function actualArrivalDate(flight: ArrivalFlight): Date | undefined {
  return flight.actualArrivalDate ? new Date(flight.actualArrivalDate) : undefined;
}

export function estimatedArrivalDate(flight: ArrivalFlight): Date {
  return new Date(flight.estimatedArrivalDate || flight.scheduledArrivalDate);
}

export function arrivalDate(flight: ArrivalFlight): Date {
  return actualArrivalDate(flight) || estimatedArrivalDate(flight);
}

export function estimatedArrivalTime(flight: ArrivalFlight): string {
  if (!isValid(estimatedArrivalDate(flight))) return 'N/A';
  return format(estimatedArrivalDate(flight), 'h:mm a');
}

export function actualArrivalTime(flight: ArrivalFlight): string | undefined {
  if (!isValid(actualArrivalDate(flight))) return;
  return format(actualArrivalDate(flight) as Date, 'h:mm a');
}

export function scheduledArrivalDate(flight: ArrivalFlight): Date {
  return new Date(flight.scheduledArrivalDate);
}

export function scheduledArrivalTime(flight: ArrivalFlight): string {
  return format(scheduledArrivalDate(flight), 'h:mm a');
}

function active(flight: ArrivalFlight): boolean {
  return flight.statusText === ON_TIME || flight.statusText === DELAYED;
}

function canceled(flight: ArrivalFlight): boolean {
  return flight.statusText === CANCELED;
}

function diverted(flight: ArrivalFlight): boolean {
  return flight.statusText === DIVERTED;
}

function delayed(flight: ArrivalFlight): boolean {
  return flight.statusText === DELAYED;
}

function arrivalTimeElapsed(flight: ArrivalFlight): boolean {
  return isPast(
    new Date(flight.actualArrivalDate || flight.estimatedArrivalDate || flight.scheduledArrivalDate)
  );
}

function mostLikelyCompleted(flight: ArrivalFlight): boolean {
  // Sometimes a flight actually landed, but FlightStats hasn't
  // marked it as completed and it's still marked "active". Instead
  // of marking this as cancelled in `mostLikelyCancelled`, we want
  // to mark this as complete *IF* it's active *AND* past its
  // departures and arrival times.
  return (isEmpty(flight.statusText) || active(flight)) && arrivalTimeElapsed(flight);
}

function mostLikelyCanceled(flight: ArrivalFlight): boolean {
  // We need to account for flights that were canceled, but not
  // actually updated properly in the FlightStats API.
  // --
  // 1. The flight has a status. Harris flights have no status,
  //    so many Cargo flights are being marked Cancelled
  // 2. The flight is not active.
  // 3. The estimated departure (and arrival) time has elapsed.
  // 4. There were no scans for the flight.
  // 5. There is no gate assigned.
  // --
  const hasStatus = !isEmpty(flight.statusText);
  const notActive = !active(flight);
  // FIXME: [Performance] This is slow!
  const noGate = !!flight.arrivalGate;
  const _mostLikelyCanceled = hasStatus && notActive && arrivalTimeElapsed(flight) && noGate;

  return _mostLikelyCanceled;
}

class CargoFlightData {
  flight: ArrivalFlight | Cargo;

  constructor({ flight }: ConstructorArgs) {
    this.flight = flight;
  }

  get mostLikelyCompleted(): boolean {
    return mostLikelyCompleted(this.flight);
  }

  get mostLikelyCanceled(): boolean {
    return mostLikelyCanceled(this.flight);
  }

  get active(): boolean {
    return active(this.flight);
  }

  get canceled(): boolean {
    return canceled(this.flight);
  }

  get completed(): boolean {
    return this.flight.statusText === COMPLETED;
  }

  get diverted(): boolean {
    return diverted(this.flight);
  }

  get delayed(): boolean {
    return delayed(this.flight);
  }

  get arrivalTimeElapsed(): boolean {
    return arrivalTimeElapsed(this.flight);
  }

  get estimatedArrivalDate(): Date {
    return estimatedArrivalDate(this.flight);
  }

  get estimatedArrivalTime(): string {
    return estimatedArrivalTime(this.flight);
  }

  get scheduledArrivalDate(): Date {
    return scheduledArrivalDate(this.flight);
  }

  get scheduledArrivalTime(): string {
    return scheduledArrivalTime(this.flight);
  }

  get flightStatus(): { statusText: string } {
    let statusText = ON_TIME;

    if (this.diverted) {
      statusText = DIVERTED;
    } else if (this.canceled) {
      statusText = CANCELED;
    } else if (this.completed) {
      statusText = COMPLETED;
    } else if (this.delayed) {
      statusText = DELAYED;
    } else if (this.mostLikelyCompleted) {
      statusText = COMPLETED;
    } else if (this.mostLikelyCanceled) {
      statusText = CANCELED;
    }

    return { statusText };
  }
}

export default CargoFlightData;
