import { getDollars } from 'utils';

import { StatItem, StatsArgs } from 'statistics/types';

import { STAT_TYPES } from './constants';

export default {
  [STAT_TYPES.CARGO_REVENUE.AVERAGE]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Average Cargo Revenue',
    value: getDollars(value as number),
    contextLabel,
  }),
  [STAT_TYPES.CARGO_REVENUE.HIGHEST]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Highest Cargo Revenue',
    value: getDollars(value as number),
    contextLabel,
  }),
  [STAT_TYPES.CARGO_REVENUE.LOWEST]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Lowest Cargo Revenue',
    value: getDollars(value as number),
    contextLabel,
  }),
};
