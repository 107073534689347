import { useEffect, useState } from 'react';

export default function useWorker(callback: Function) {
  const [workerData, setWorkerData] = useState();

  useEffect(() => {
    let stillMounted = true;

    (async () => {
      const _workerData = await callback();
      if (stillMounted) {
        setWorkerData(_workerData);
      }
    })();

    return () => {
      stillMounted = false;
    };
  }, [callback]);

  return workerData;
}
