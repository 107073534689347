import { gql, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { ParkingArgs, ParkingQuery } from './types';

const GET_PARKING = gql`
  query Parking($timestamp: DateTime) {
    parking(timestamp: $timestamp) {
      lot
      timestamp
      spotsTotal
      spotsAvailable
      spotsOccupied
      status
      sortOrder
    }
  }
`;

export default function useParking({ timestamp, refresh = true }: ParkingArgs = {}): ParkingQuery {
  const variables = pickBy({ timestamp }, identity);

  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_PARKING, options);

  return {
    parking: query?.data?.parking || [],
    ...query,
  };
}
