import { useUserMetadata } from 'client/User';

import { useUserData } from 'providers/UserDataProvider';

function useMetadata(requestedKey?: string) {
  const userData = useUserData();
  const { data, loading } = useUserMetadata(userData?.email || '');
  let metadata = undefined;

  if (userData && !loading && requestedKey && data && data.user) {
    const userMetadata = data.user.user_metadata || {};
    for (const [key, _value] of Object.entries(userMetadata)) {
      if (key === requestedKey) metadata = _value;
    }
  } else if (userData && !loading) metadata = userData?.user_metadata || {};

  return { loading, metadata };
}

export default useMetadata;
