import sortBy from 'lodash/sortBy';
import React from 'react';

import { Notam } from 'tiles/Airfield/utils';
import { getData, NotamData } from 'tiles/Airfield/utils/getData';

import { useSelectedTableItemDispatch } from 'providers/SelectedTableItemProvider';
import { useTileOptionsState } from 'providers/TileOptionsProvider';

import Table, { Heading } from 'components/Table';

const defaults = {
  grid: '1fr 3fr repeat(3, 1fr)',
  headings: [
    { key: 'area', label: 'Area', sortByKey: 'areaName' },
    { key: 'description', label: 'Description', sortByKey: 'description' },
    { key: 'start', label: 'Start', sortByKey: 'start' },
    { key: 'end', label: 'End', sortByKey: 'end' },
    { key: 'status', label: 'Status', sortByKey: 'statusName' },
  ],
};

interface NotamsTableProps {
  grid?: string;
  headings?: Heading[];
  notams: Notam[];
  tile?: boolean;
  mapEnabled?: boolean;
}

const NotamsTable: React.FC<NotamsTableProps> = ({
  notams,
  tile = false,
  mapEnabled = true,
}: NotamsTableProps) => {
  const state = useTileOptionsState();
  const {
    airfield: { filters },
  } = state;
  const sortByColumn = filters?.sortByColumn || filters?.defaultSortByColumn;
  const setSelectedNotam = useSelectedTableItemDispatch();

  if (process.env.REACT_APP_CONFIG_NOTAMS !== 'true') return null;
  const data = getData(notams);

  // Sort NOTAMS data
  let sortedData = sortBy(data, sortByColumn || 'end');
  if (filters?.isReverseSort) sortedData = sortedData.reverse();
  const grid = tile ? '1fr 2.5fr 1fr' : defaults.grid;
  const headings = tile
    ? [
        { key: 'area', label: 'Area', sortByKey: 'areaName' },
        { key: 'description', label: 'Description', sortByKey: 'description' },
        { key: 'status', label: 'Status', sortByKey: 'statusName' },
      ]
    : defaults.headings;

  return (
    <Table
      name="airfield"
      striped={false}
      bordered={true}
      dataFontSize="s"
      grid={grid}
      headings={headings}
      data={sortedData}
      onRowClick={
        mapEnabled
          ? (notam: NotamData): void => {
              setSelectedNotam(notam.notam);
            }
          : undefined
      }
    />
  );
};

export default NotamsTable;
