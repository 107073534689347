import React from 'react';

import { DataLimiter } from 'hooks/useDataLimiter';

import { LinkButton } from 'components';

import { Box } from 'styled';

interface Props {
  dataLimiter: DataLimiter;
}

const DetailsNav: React.FC<Props> = ({ dataLimiter }: Props) => {
  return (
    <Box alignItems="center" display="flex" justifyContent="center">
      <LinkButton
        disabled={dataLimiter.firstPage}
        onClick={(event: React.MouseEvent) => dataLimiter.handlePrev(event)}
      >
        Previous
      </LinkButton>
      <Box as="span" display="block" fontSize="s" mx="s">
        {dataLimiter.pageDescription}
      </Box>
      <LinkButton
        disabled={dataLimiter.lastPage}
        onClick={(event: React.MouseEvent) => dataLimiter.handleNext(event)}
      >
        Next
      </LinkButton>
    </Box>
  );
};

export default DetailsNav;
