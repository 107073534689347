import { gql, useQuery } from '@apollo/client';

const USERS_DATA = gql`
  query UsersData($email: String!) {
    user(email: $email) {
      user_id
      name
      email
      picture
      created_at
      last_login
      permissions
      roles
    }
  }
`;

export default function useUser(email: string) {
  return useQuery(USERS_DATA, {
    variables: { email },
  });
}
