import { format, isPast, isValid } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { CANCELED, COMPLETED, DELAYED, DIVERTED, ON_TIME } from 'data/Flights/constants';
import { DepartureFlight } from 'data/Flights/types';

import { Cargo } from './CargoData';

interface ConstructorArgs {
  flight: DepartureFlight;
}

export function actualDepartureDate(flight: DepartureFlight): Date | undefined {
  return flight.actualDepartureDate ? new Date(flight.actualDepartureDate) : undefined;
}

export function estimatedDepartureDate(flight: DepartureFlight): Date {
  return new Date(flight.estimatedDepartureDate || flight.scheduledDepartureDate);
}

export function departureDate(flight: DepartureFlight): Date {
  return actualDepartureDate(flight) || estimatedDepartureDate(flight);
}

export function estimatedDepartureTime(flight: DepartureFlight): string {
  if (!isValid(estimatedDepartureDate(flight))) return 'N/A';
  return format(estimatedDepartureDate(flight), 'h:mm a');
}

export function actualDepartureTime(flight: DepartureFlight): string | undefined {
  if (!isValid(actualDepartureDate(flight))) return;
  return format(actualDepartureDate(flight) as Date, 'h:mm a');
}

export function scheduledDepartureDate(flight: DepartureFlight): Date {
  return new Date(flight.scheduledDepartureDate);
}

export function scheduledDepartureTime(flight: DepartureFlight): string {
  return format(scheduledDepartureDate(flight), 'h:mm a');
}

function active(flight: DepartureFlight): boolean {
  return flight.statusText === ON_TIME || flight.statusText === DELAYED;
}

function canceled(flight: DepartureFlight): boolean {
  return flight.statusText === CANCELED;
}

function completed(flight: DepartureFlight): boolean {
  return flight.statusText === COMPLETED;
}

function diverted(flight: DepartureFlight): boolean {
  return flight.statusText === DIVERTED;
}

function delayed(flight: DepartureFlight): boolean {
  return flight.statusText === DELAYED;
}

function departureTimeElapsed(flight: DepartureFlight): boolean {
  return isPast(
    new Date(
      flight.actualDepartureDate || flight.estimatedDepartureDate || flight.scheduledDepartureDate
    )
  );
}

function mostLikelyCompleted(flight: DepartureFlight): boolean {
  // Sometimes a flight actually landed, but FlightStats hasn't
  // marked it as completed and it's still marked "active". Instead
  // of marking this as cancelled in `mostLikelyCancelled`, we want
  // to mark this as complete *IF* it's active *AND* past its
  // departures and arrival times.
  return (isEmpty(flight.statusText) || active(flight)) && departureTimeElapsed(flight);
}

function mostLikelyCanceled(flight: DepartureFlight): boolean {
  // We need to account for flights that were canceled, but not
  // actually updated properly in the FlightStats API.
  // --
  // 1. The flight has a status. Harris flights have no status,
  //    so many Cargo flights are being marked Cancelled
  // 2. The flight is not active.
  // 3. The estimated departure (and arrival) time has elapsed.
  // 5. There is no gate assigned.
  // --
  const hasStatus = !isEmpty(flight.statusText);
  const notActive = !active(flight);
  // FIXME: [Performance] This is slow!
  const noGate = !!flight.departureGate;
  const _mostLikelyCanceled = hasStatus && notActive && departureTimeElapsed(flight) && noGate;

  return _mostLikelyCanceled;
}

class FlightData {
  flight: DepartureFlight | Cargo;

  constructor({ flight }: ConstructorArgs) {
    this.flight = flight;
  }

  get mostLikelyCompleted(): boolean {
    return mostLikelyCompleted(this.flight);
  }

  get mostLikelyCanceled(): boolean {
    return mostLikelyCanceled(this.flight);
  }

  get active(): boolean {
    return active(this.flight);
  }

  get canceled(): boolean {
    return canceled(this.flight);
  }

  get completed(): boolean {
    return completed(this.flight);
  }

  get diverted(): boolean {
    return diverted(this.flight);
  }

  get delayed(): boolean {
    return delayed(this.flight);
  }

  get departureTimeElapsed(): boolean {
    return departureTimeElapsed(this.flight);
  }

  get estimatedDepartureDate(): Date {
    return estimatedDepartureDate(this.flight);
  }

  get estimatedDepartureTime(): string {
    return estimatedDepartureTime(this.flight);
  }

  get scheduledDepartureDate(): Date {
    return scheduledDepartureDate(this.flight);
  }

  get scheduledDepartureTime(): string {
    return scheduledDepartureTime(this.flight);
  }

  get flightStatus(): { statusText: string } {
    let statusText = ON_TIME;

    if (this.diverted) {
      statusText = DIVERTED;
    } else if (this.canceled) {
      statusText = CANCELED;
    } else if (this.completed) {
      statusText = COMPLETED;
    } else if (this.delayed) {
      statusText = DELAYED;
    } else if (this.mostLikelyCompleted) {
      statusText = COMPLETED;
    } else if (this.mostLikelyCanceled) {
      statusText = CANCELED;
    }

    return { statusText };
  }
}

export default FlightData;
