import sortBy from 'lodash/sortBy';
import React from 'react';

import { useFacilityConditions } from 'client/FacilityConditions';
import { FacilityCondition } from 'client/FacilityConditions/types';
import { Issue } from 'client/Issue/types';
import useIssues from 'client/Issue/useIssues';

import { useRefreshedAt } from 'hooks';

import { SelectedTableItemProvider } from 'providers';

import { BlankSlate, QueryCell, UpdatedAt } from 'components';
import { Props as IconProps } from 'components/Icon';
import { Heading } from 'components/Table';

import { Box, TileContent, TileContentInner } from 'styled';

import FacilityConditionsMap from './FacilityConditionsMap';
import FacilityConditionsTable from './FacilityConditionsTable';

interface Props {
  showTable?: boolean;
  showMap?: boolean;
  mapHeight?: string;
  grid?: string;
  sortByColumn?: string;
  isReverseSort?: boolean;
  headings?: Heading[];
  filter?: Function;
  filterEmptyMessage?: string;
  filterBlankSlateIcon?: IconProps;
}

const defaults = {
  grid: '0.75fr 1fr',
  headings: [
    { key: 'feed', label: 'Feed' },
    { key: 'assets', label: 'Assets' },
  ],
  filterEmptyMessage: 'There is currently no data.',
  filterBlankSlateIcon: { name: 'magnifyingGlass', color: 'warning', size: '30' },
};

const FacilityConditions: React.FC<Props> = ({
  grid,
  headings,
  showTable,
  showMap,
  isReverseSort,
  sortByColumn,
  mapHeight = '100%',
  filter,
  filterEmptyMessage,
  filterBlankSlateIcon,
}: Props) => {
  const {
    facilityConditions,
    error: facilityConditionsError,
    loading: facilityConditionsLoading,
  } = useFacilityConditions();
  const {
    data: issuesData,
    error: issuesError,
    loading: issuesLoading,
  } = useIssues({
    ids: facilityConditions.map(fc => fc.issueId),
    status: 'open',
    skip: facilityConditionsLoading || Boolean(facilityConditionsError),
  });

  const loading = facilityConditionsLoading || issuesLoading;
  const error = facilityConditionsError || issuesError;
  const { refreshedAt } = useRefreshedAt(loading);

  const issues = issuesData?.issues || [];
  const filteredFacilityConditions = filter
    ? facilityConditions.filter(facilityCondition => filter({ facilityCondition, issues }))
    : facilityConditions;

  // Sort capacity data
  let sortedFacilityConditions = sortBy(filteredFacilityConditions, sortByColumn || 'feed');
  if (isReverseSort) sortedFacilityConditions = sortedFacilityConditions.reverse();

  const _grid = grid || defaults.grid;
  const _headings = headings || defaults.headings;

  function render(): JSX.Element {
    if (filteredFacilityConditions.length === 0) {
      return (
        <Box display="grid" height="100%" gridTemplateRows="1fr auto">
          <BlankSlate
            display="flex"
            flexDirection="column"
            justifyContent="center"
            icon={filterBlankSlateIcon || defaults.filterBlankSlateIcon}
          >
            {filterEmptyMessage || defaults.filterEmptyMessage}
          </BlankSlate>
          <UpdatedAt date={refreshedAt} />
        </Box>
      );
    }

    if (showMap && !showTable) {
      return (
        <>
          <Map
            mapHeight={mapHeight}
            facilityConditions={sortedFacilityConditions}
            issues={issues}
          />
          <RefreshedAt refreshedAt={refreshedAt} />
        </>
      );
    }

    if (!showMap && showTable) {
      return (
        <>
          <FacilityConditionsTable
            facilityConditions={sortedFacilityConditions}
            issues={issues}
            grid={_grid}
            headings={_headings}
          />
          <UpdatedAt date={refreshedAt} />
        </>
      );
    }

    return (
      <SelectedTableItemProvider>
        <Box height="150px" position="relative">
          <Box position="relative">
            <Map
              mapHeight={mapHeight}
              facilityConditions={sortedFacilityConditions}
              issues={issues}
            />
            <RefreshedAt refreshedAt={refreshedAt} />
          </Box>
          <FacilityConditionsTable
            facilityConditions={sortedFacilityConditions}
            issues={issues}
            clickable={true}
            grid={_grid}
            headings={_headings}
          />
        </Box>
      </SelectedTableItemProvider>
    );
  }

  return (
    <QueryCell
      content="Facility Conditions"
      loading={loading}
      error={error}
      data={facilityConditions}
    >
      {render()}
    </QueryCell>
  );
};

interface MapProps {
  mapHeight?: string | object;
  mapMinHeight?: string;
  facilityConditions: FacilityCondition[];
  issues: Issue[];
}

const Map: React.FC<MapProps> = ({
  mapHeight,
  mapMinHeight,
  facilityConditions,
  issues,
}: MapProps) => (
  <TileContent overflowY="hidden">
    <TileContentInner>
      <Box height={mapHeight} minHeight={mapMinHeight} width="100%">
        <FacilityConditionsMap facilityConditions={facilityConditions} issues={issues} />
      </Box>
    </TileContentInner>
  </TileContent>
);

interface UpdatedAtProps {
  refreshedAt: Date;
}

const RefreshedAt: React.FC<UpdatedAtProps> = ({ refreshedAt }: UpdatedAtProps) => (
  <Box bottom="0" left="0" position="absolute" right="0" zIndex={800}>
    <UpdatedAt date={refreshedAt} />
  </Box>
);

export default FacilityConditions;
