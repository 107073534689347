import { gql, useQuery } from '@apollo/client';
import { DEFAULT_POLL_INTERVAL } from 'config/constants';
import { endOfToday, startOfToday } from 'date-fns';
import { AirlineCapacitiesQuery, AirlineCapacityArgs } from './types';

const GET_AIRLINE_CAPACITIES = gql`
  query AirlineCapacities($between: [DateTime]!) {
    airlineCapacities(between: $between) {
      bookings
      canceled
      capacity
      delayed
      iata
      isMarketing
      name
      onTime
      scans
      total
    }
  }
`;

export default function useAirlineCapacities({
  between = [startOfToday().getTime(), endOfToday().getTime()],
  refresh = true,
  skip = false,
}: AirlineCapacityArgs = {}): AirlineCapacitiesQuery {
  const options = {
    variables: { between },
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  return useQuery(GET_AIRLINE_CAPACITIES, options);
}
