import React from 'react';

import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';
import { track } from 'utils';

import { Icon } from 'components';

import {
  Table as StyledTable,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow,
} from 'styled';

export interface Heading {
  key: string;
  label: string;
  sortByKey?: string | string[];
  onHeadingClick?: Function;
}

interface Props {
  data: Array<any>;
  headings: Array<Heading>;
  bordered?: boolean;
  cellPaddingX?: string;
  cellPaddingY?: string;
  dataFontSize?: string;
  grid?: string;
  gridGap?: string;
  name?: string;
  striped?: boolean;
  onRowClick?: Function;
}

const Table: React.FC<Props> = ({
  name,
  data,
  headings,
  grid,
  bordered,
  cellPaddingX,
  cellPaddingY,
  dataFontSize,
  gridGap,
  striped,
  onRowClick,
  ...rest
}: Props) => {
  const [state, dispatch] = useTileOptions();
  const numberOfColumns = headings.length;
  const defaults = {
    cellPaddingX: 'base',
    cellPaddingY: 's',
    gridGap: 'base',
    bordered: false,
    striped: true,
  };

  const onHeadingClick = (headingKey: string | string[]) => {
    if (!name || !headingKey) return;

    // Reverse the sort order if your first click is the defaultSortByColumn
    // or you click a column that is the current sortByColumn
    const sortByColumn = state?.[name]?.filters?.sortByColumn;
    const defaultSortByColumn = state?.[name]?.filters?.defaultSortByColumn;
    const isSameHeading = sortByColumn && sortByColumn === headingKey;
    const isDefaultReverse = !sortByColumn && defaultSortByColumn === headingKey;
    const isReverse = !isSameHeading ? false : !state[name].filters?.isReverseSort;
    const isReverseSort = isDefaultReverse || isReverse;

    dispatch(
      updateOptions({
        [name]: {
          ...state[name],
          filters: {
            ...state[name].filters,
            isReverseSort,
            sortByColumn: headingKey,
            needsUpdate: true,
          },
        },
      })
    );
    track('Tile Sort', {
      tile: name || '',
      column: headingKey || '',
    });
  };

  const renderHeading = (heading: Heading, index: number) => {
    const { key, sortByKey } = heading;
    const filters = state?.[name || '']?.filters;
    const sortByColumn = filters?.sortByColumn || filters?.defaultSortByColumn;
    const isReverse = filters?.isReverseSort;
    const isSortByColumn = sortByColumn && (sortByColumn === sortByKey || sortByColumn === key);
    return (
      <TableHeading
        key={`${index}-${heading.key}`}
        px={cellPaddingX || defaults.cellPaddingX}
        py={cellPaddingY || defaults.cellPaddingY}
        onClick={() => onHeadingClick(heading.sortByKey || heading.key)}
      >
        {heading.label}
        {isSortByColumn && isReverse && (
          <Icon name="chevronUp" mt="-3px" size="16" color="subdued" />
        )}
        {isSortByColumn && !isReverse && (
          <Icon name="chevronDown" mt="-3px" size="16" color="subdued" />
        )}
      </TableHeading>
    );
  };

  return (
    <StyledTable {...rest}>
      <TableHead
        bordered={bordered === null || bordered === undefined ? defaults.bordered : bordered}
      >
        <TableRow
          grid={grid}
          numberOfColumns={numberOfColumns}
          gridColumnGap={gridGap || defaults.gridGap}
        >
          {headings.map((heading, index) => renderHeading(heading, index))}
        </TableRow>
      </TableHead>
      <TableBody
        striped={striped === null || striped === undefined ? defaults.striped : striped}
        bordered={bordered === null || bordered === undefined ? defaults.bordered : bordered}
      >
        {data.map((datum, idx) => (
          <TableRow
            key={idx}
            grid={grid}
            numberOfColumns={numberOfColumns}
            gridColumnGap={gridGap || defaults.gridGap}
            onClick={(): void => onRowClick?.(datum)}
            clickable={onRowClick !== undefined}
          >
            {headings.map((heading: Heading, _idx) => (
              <TableData
                key={_idx}
                px={cellPaddingX || defaults.cellPaddingX}
                py={cellPaddingY || defaults.cellPaddingY}
                fontSize={dataFontSize || 'base'}
              >
                {datum[heading.key]}
              </TableData>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default Table;
