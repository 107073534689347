const concourseOptions = {
  concourseA: [
    'A1',
    'A2',
    'A3',
    'A4',
    'A5',
    'A6',
    'A7',
    'A8',
    'A9',
    'A10',
    'A11',
    'A12',
    'A13',
    'A14',
    'A15',
    'A16',
    'A17',
    'A18',
    'A19',
    'A21',
    'A22',
  ],
  concourseB: [
    'B1',
    'B2',
    'B3',
    'B4',
    'B5',
    'B6',
    'B7',
    'B8',
    'B9',
    'B10',
    'B11',
    'B12',
    'B13',
    'B14',
    'B15',
    'B16',
    'B17',
    'B18',
    'B19',
    'B20',
    'B21',
    'B22',
    'B23',
    'B24',
    'B25',
    'B26',
    'B27',
    'B28',
  ],
};

export default concourseOptions;
