import { SeriesOptionsType } from 'highcharts';

import { ChartDatum } from 'statistics/types';

import { getXAxis, getYAxis } from './axes';
import { default as getOptions } from './options';
import { default as getSeries } from './series';

export default {
  concourseA: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Concourse A', chartOptions),
  },
  concourseB: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Concourse B', chartOptions),
  },
  mainTerminal: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Main Terminal', chartOptions),
  },
  shortTerm: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Short Term', chartOptions),
  },
  longTerm: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Long Term', chartOptions),
  },
  econoLot: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Econo Lot', chartOptions),
  },
  valet: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Valet', chartOptions),
  },
  total: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Total', chartOptions),
  },
};
