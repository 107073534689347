import maxBy from 'lodash/maxBy';
import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';
import { addHours, format, startOfToday, startOfDay, addDays } from 'date-fns';

import { ChartDatum, DataObject, StatsData } from 'statistics/types';

import { ProcessedDataObject } from './utils';
import { Projection } from 'data/Projections/types';

function getChartData(projections: Projection[]): ChartDatum[] {
  if (!projections.length) return [];
  const chartDate = projections[0].date;
  let hour = 0;
  const data: ChartDatum[] = [];
  let date = addDays(startOfDay(new Date(chartDate)), 1);
  while (hour < 24) {
    const x = date.getTime();
    // eslint-disable-next-line
    const projection = projections.find(p => p.hour === hour);
    data.push({ x, y: projection?.count || 0 });
    date = addHours(date, 1);
    hour++;
  }
  return sortBy(data, 'x');
}

function getStatsData(projections: Projection[]): StatsData {
  // Get highest hour and format the time
  const highestByTotal = maxBy(projections, 'count');
  const hour = highestByTotal ? highestByTotal?.hour : 0;
  const formattedHour = format(addHours(startOfToday(), hour), 'h:mm a');
  const count = highestByTotal?.count || 0;

  return {
    busiestPredictedHourTime: { value: formattedHour },
    busiestPredictedHourCount: { value: count },
    totalProjection: { value: sumBy(projections, 'count') || 0 },
  };
}

async function getProcessedData(projectionData: DataObject): Promise<ProcessedDataObject> {
  const { data: projections, loading, error } = projectionData;

  if (loading || error || !projections) return { chart: { loading, error, data: [] } };
  const statData = getStatsData(projections as Projection[]);
  const chartData = getChartData(projections as Projection[]);

  return {
    chart: {
      error,
      loading,
      data: chartData,
    },
    stats: {
      error,
      loading,
      data: statData,
    },
  };
}

export default {
  getProcessedData,
};
