import { RadioGroup } from '@headlessui/react';
import React from 'react';

import { Icon } from 'components';

import {
  Box,
  Pagination,
  PaginationBar as StyledPaginationBar,
  PaginationGroup,
  PaginationIcon,
  PaginationOption,
} from 'styled';

interface Props {
  count: number;
  onClick: Function;
  onNext: Function;
  onPrev: Function;
  onSettingsClick: Function;
  pageIndex: number;
}

export const PAGINATION_BAR_HEIGHT = '70px';

export const PaginationBar: React.FC<Props> = ({
  count = 0,
  onClick,
  onNext,
  onPrev,
  onSettingsClick,
  pageIndex,
}) => {
  const pageOptions = [...Array(count)]?.map((item, i) => ({ value: i }));

  const handleLeftPageChange = () => onPrev && onPrev();
  const handleRightPageChange = () => onNext && onNext();
  const handleSettings = () => onSettingsClick && onSettingsClick();

  return (
    <StyledPaginationBar>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box as="span" color="neutralsGradient.6" fontWeight="bold" fontSize="base" minWidth="50px">
          Page {pageIndex + 1}
        </Box>
      </Box>
      <Pagination>
        {pageIndex > 0 ? (
          <PaginationIcon>
            <Icon
              name="chevronLeft"
              size="28"
              cursor="pointer"
              color="neutralsGradient.6"
              onClick={handleLeftPageChange}
            />
          </PaginationIcon>
        ) : (
          <Icon
            name="chevronLeft"
            size="28"
            cursor="not-allowed"
            color="neutralsGradient.8"
            onClick={handleLeftPageChange}
          />
        )}
        <RadioGroup
          value={pageIndex}
          onChange={(index: number) => {
            onClick && onClick(index + 1);
          }}
          as={PaginationGroup}
        >
          {pageOptions.map((page, index) => (
            <RadioGroup.Option as={PaginationOption} key={index} value={page.value}>
              {({ checked }) =>
                checked ? (
                  <Box width="10px" height="10px" borderRadius="50%" backgroundColor="fg" />
                ) : (
                  <PaginationIcon>
                    <Box
                      width="10px"
                      height="10px"
                      borderRadius="50%"
                      backgroundColor="neutralsGradient.9"
                    />
                  </PaginationIcon>
                )
              }
            </RadioGroup.Option>
          ))}
        </RadioGroup>
        {pageIndex < pageOptions.length - 1 ? (
          <PaginationIcon>
            <Icon
              name="chevronRight"
              size="28"
              cursor="pointer"
              color="neutralsGradient.6"
              onClick={handleRightPageChange}
            />
          </PaginationIcon>
        ) : (
          <Icon
            name="chevronRight"
            size="28"
            cursor="not-allowed"
            color="neutralsGradient.8"
            onClick={handleRightPageChange}
          />
        )}
      </Pagination>

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <PaginationIcon>
          <Icon name="gear" size="20" cursor="pointer" onClick={handleSettings} />
        </PaginationIcon>
      </Box>
    </StyledPaginationBar>
  );
};

export default PaginationBar;
