import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { Box, TextInput as StyledTextInput, TextInputIcon } from 'styled';
import { TextInputProps } from 'styled/TextInput';

import Icon from './Icon';

// eslint-disable-next-line react/display-name
const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const defaults = {
    type: 'text',
  };

  const title = props.inputTitle ? (
    <Box as="h5" color="neutralsGradient.4">
      {props.inputTitle}
    </Box>
  ) : (
    <></>
  );

  const helperText = props.helperText ? (
    <Box as="h5" color="neutralsGradient.5" mt="xs">
      {props.helperText}
    </Box>
  ) : (
    <></>
  );

  const input = props.icon ? (
    <Box position="relative">
      <TextInputIcon as={Icon} name={props.icon} size="18" />
      <StyledTextInput type={props.type || defaults.type} {...props} ref={ref} />
    </Box>
  ) : (
    <StyledTextInput type={props.type || defaults.type} {...props} ref={ref} />
  );

  return (
    <Box display="flex" flexDirection="column">
      {title}
      {input}
      {helperText}
    </Box>
  );
});

TextInput.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.string,
  inputTitle: PropTypes.string,
  helperText: PropTypes.string,
};

export default TextInput;
