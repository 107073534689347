import React, { useState } from 'react';

import concourseOptions from 'config/concourseOptions';
import theme from 'config/theme';

import { useActiveChartsState } from 'providers/ActiveChartsProvider';
import { updateOptions, useChartOptions } from 'providers/ChartOptionsProvider';

import { Button, FormField, Listbox } from 'components';

import { Box, ChartOverlay as StyledChartOverlay, ChartOverlayBackground } from 'styled';

import flowImg from 'images/flow-chart.svg';

const ChartOverlay: React.FC = () => {
  const [state, dispatch] = useChartOptions();
  const activeChartsState = useActiveChartsState();
  const [concourseType, setConcourseType] = useState('');
  const [selectedGates, setSelectedGates] = useState<string[]>([]);
  const activeTab = activeChartsState?.activeTab;
  const { concourseA, concourseB } = concourseOptions;

  const handleConcourseTypeChange = (value: string) => {
    setConcourseType(value);
  };

  const handleConcourseChange = (value: string[]) => {
    setSelectedGates(value);
  };

  const handleSubmit = () => {
    dispatch(
      updateOptions({
        selectedGates,
      })
    );
  };

  if (activeTab === 'Flow') {
    // Don't show overlay if we have gate data
    if (state?.selectedGates?.length) return null;

    const concourseAreas = [
      { value: 'concourseA', label: 'Concourse A' },
      { value: 'concourseB', label: 'Concourse B' },
    ];
    const availableGates = concourseType === 'concourseA' ? concourseA : concourseB;
    const gateOptions = availableGates?.map((gate: string) => ({ value: gate, label: gate }));

    return (
      <StyledChartOverlay>
        <Box width="100%" height="100%" position="relative">
          <ChartOverlayBackground backgroundImage={flowImg} />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            position="relative"
            zIndex={theme.zIndices.modal}
          >
            <FormField
              label="Concourse"
              type="stacked"
              mr="base"
              width="200px"
              color="neutralsGradient.4"
            >
              <Listbox options={concourseAreas} onChange={handleConcourseTypeChange} />
            </FormField>
            <FormField label="Gate(s)" type="stacked" width="200px" color="neutralsGradient.4">
              <Listbox
                options={gateOptions}
                disabled={!concourseType}
                onChange={handleConcourseChange}
                multiple={true}
              />
            </FormField>
            <Button
              onClick={handleSubmit}
              size="base"
              mt="28px"
              ml="base"
              disabled={!selectedGates?.length}
            >
              Visualize
            </Button>
          </Box>
        </Box>
      </StyledChartOverlay>
    );
  }

  return null;
};

export default ChartOverlay;
