import { format, isFuture } from 'date-fns';
import { maxBy, minBy, sortBy } from 'lodash';

import { CHART_TYPES } from 'statistics/charts/constants';
import { ProcessedDataObject } from 'statistics/processors/utils';
import { ChartDatum } from 'statistics/types';

export const getFlowGraphData = (data: { [key: string]: ProcessedDataObject } | undefined) => {
  const hasGateData = data?.GATES1?.chart?.data?.length;
  if (!hasGateData) return undefined;

  // Combine all gates data
  let gateData = [] as ChartDatum[];
  Object.keys(CHART_TYPES.GATES).forEach(gate => {
    //@ts-expect-error
    const newGateData = data?.[gate]?.chart?.data?.[0]?.data || [];
    gateData = [...gateData, ...newGateData];
  });

  // Sum totals with all gates passengers in each time window
  const summedGatesByTime = {} as any;
  gateData?.forEach(gate => {
    if (summedGatesByTime[gate.x] === undefined) {
      summedGatesByTime[gate.x] = gate.y;
    } else {
      summedGatesByTime[gate.x] += gate.y;
    }
  });

  // Map gate time data to graph format
  const mappedGatesByTime = Object.keys(summedGatesByTime).map(key => ({
    x: parseInt(key),
    y: summedGatesByTime[key],
  }));

  // Sort by time
  const sortedGateData = sortBy(mappedGatesByTime, 'x');

  return sortedGateData;
};

export const getSlowestHour = (
  data: { [key: string]: ProcessedDataObject } | undefined
): string | undefined => {
  const chartData = getFlowGraphData(data);
  const filteredChartData = chartData?.filter(datum => isFuture(datum?.x));
  const slowestTime = minBy(filteredChartData, 'y');
  if (slowestTime?.y === undefined) return undefined;
  const formatedTime = format(slowestTime?.x, 'p');
  return formatedTime;
};

export const getBusiestHour = (
  data: { [key: string]: ProcessedDataObject } | undefined
): string | undefined => {
  const chartData = getFlowGraphData(data);
  const filteredChartData = chartData?.filter(datum => isFuture(datum?.x));
  const busiestTime = maxBy(filteredChartData, 'y');
  if (!busiestTime?.y) return undefined;
  const formatedTime = format(busiestTime.x, 'p');
  return formatedTime;
};
