import { gql, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { AlertsArgs, AlertsQuery } from './types';

const GET_ALERTS = gql`
  query Alerts($between: [DateTime], $offset: Int, $limit: Int, $order: [[String]]) {
    alerts(between: $between, offset: $offset, limit: $limit, order: $order) {
      id
      createdAt
      updatedAt
      title
      description
      category
      status
      isActive
    }
  }
`;

export default function useAlerts({
  between,
  offset,
  limit,
  order,
  refresh = true,
  skip = false,
}: AlertsArgs = {}): AlertsQuery {
  const variables = pickBy(
    {
      between,
      offset,
      limit,
      order,
    },
    identity
  );

  const options = {
    variables,
    pollInterval: 1000 * 60, // 1 minute
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;
  const query = useQuery(GET_ALERTS, options);

  return {
    alerts: query.data?.alerts || [],
    ...query,
  };
}
