import React from 'react';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';

import TSACounts from './TSACounts';

export function TSACountsTile(): JSX.Element {
  if (process.env.REACT_APP_CONFIG_TSA_COUNTS !== 'true') return <NotAvailableBlankSlate />;

  return (
    <Tile title="TSA Counts" icon="users" contentAlignment="top" contentPadding="0">
      <RequiredPermission
        permission="read:flight"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <TSACounts />
      </RequiredPermission>
    </Tile>
  );
}

export default TSACountsTile;
