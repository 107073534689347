export interface Trend {
  percentage: number;
  wholeValue: number;
  direction: string;
}

function getTrend(historicalValue: number, currentValue: number): Trend {
  let percentage = 0;
  if (currentValue !== 0) {
    const p = Math.abs(((currentValue - historicalValue) / currentValue) * 100);
    percentage = Math.round(p);
  }

  const difference = currentValue - historicalValue;
  const wholeValue = Math.abs(currentValue - historicalValue);

  let direction = 'neutral';
  if (difference > 0) direction = 'positive';
  else if (difference < 0) direction = 'negative';

  return { percentage, wholeValue, direction };
}

export default getTrend;
