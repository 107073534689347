import { gql, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { NotamArgs, NotamsQuery } from './types';

const GET_NOTAMS = gql`
  query Notams($filter: notamFilter) {
    notams(filter: $filter) {
      id
      number
      description
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`;

export default function useNotams({ filter, refresh = true }: NotamArgs = {}): NotamsQuery {
  const variables = pickBy({ filter }, identity);

  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_NOTAMS, options);

  return {
    notams: query?.data?.notams || [],
    ...query,
  };
}
