import React, { useEffect } from 'react';

import { TILE_KEYS, TILES } from 'config/tiles';

import { showModal, updateModal, useModal } from 'providers/ModalProvider';
import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile } from 'components';
import { BathroomTraffic } from 'components/Tiles';

export const BathroomTrafficTile: React.FC = () => {
  const [optionsState, optionsDispatch] = useTileOptions();
  const [modalState, modalDispatch] = useModal();
  const {
    bathroomTraffic: { filters },
  } = optionsState;

  const tileConfig = TILES?.find(tile => tile.title === 'Bathroom Traffic');
  const headings = tileConfig?.headings || [];

  const modalProps = {
    title: 'Bathroom Traffic',
    icon: { name: 'sliders' },
    large: true,
    children: (
      <RequiredPermission
        permission="read:parking"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <BathroomTraffic
          fullscreen
          headings={headings}
          isReverseSort={filters?.isReverseSort}
          sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
        />
      </RequiredPermission>
    ),
  };

  // Update modal data if needed
  useEffect(() => {
    const isActiveModal = modalState?.activeModal?.props?.title === 'Bathroom Traffic';
    const needsUpdate = optionsState?.capacity?.filters?.needsUpdate;
    if (isActiveModal && needsUpdate) {
      modalDispatch(updateModal('Default', modalProps));
      optionsDispatch(
        updateOptions({
          bathroomTraffic: {
            ...optionsState.bathroomTraffic,
            filters: {
              ...optionsState.bathroomTraffic.filters,
              needsUpdate: false,
            },
          },
        })
      );
    }
  }, [modalDispatch, modalState, modalProps, optionsDispatch, optionsState]);
  if (process.env.REACT_APP_CONFIG_BATHROOMS !== 'true') return null;

  return (
    <Tile
      title="Bathroom Traffic"
      icon="toiletPaper"
      contentAlignment="top"
      contentPadding="0"
      modal="Default"
      modalProps={modalProps}
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Bathroom Traffic Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.BATHROOM_TRAFFIC,
              })
            ),
        },
      ]}
    >
      <RequiredPermission
        permission="read:parking"
        renderFallback={() => <NotAvailableBlankSlate />}
      >
        <BathroomTraffic
          filters={filters}
          headings={headings}
          isReverseSort={filters?.isReverseSort}
          sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
        />
      </RequiredPermission>
    </Tile>
  );
};

export default BathroomTrafficTile;
