import React from 'react';

import { useSentry } from 'hooks';

import { Button, Icon } from 'components';

import { Box } from 'styled';
import { SystemProps } from 'styled/lib/types';

export interface Props extends SystemProps {
  title?: string;
  icon?: string;
  showActions?: boolean;
  children?: React.ReactNode;
}

const ErrorMessage: React.FC<Props> = ({
  children,
  title,
  icon,
  showActions = true,
  ...rest
}: Props) => {
  const { handleFeedback } = useSentry();
  const defaults = {
    icon: 'i',
    title: 'The application has crashed',
  };

  return (
    <Box maxWidth="500px" {...rest}>
      <Icon name={icon || defaults.icon} color="error" mb="base" size="40" />
      <Box as="h1" fontWeight="bold">
        {title ? title : defaults.title}
      </Box>
      {children ? children : <FallbackContent />}
      {showActions ? (
        <Box display="flex" mt="l">
          <Button onClick={handleFeedback} variant="danger">
            Submit feedback
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

const FallbackContent: React.FC = () => (
  <>
    <Box as="p" color="subdued" fontSize="l" mb="base">
      The application has crashed due to an error. This has been reported to our error-monitoring
      service and we are looking into it.
    </Box>
    <Box as="p" color="subdued" fontSize="l">
      It would be a big help if you could give us some more feedback about what you were doing when
      the error occurred.
    </Box>
  </>
);

export default ErrorMessage;
