import React from 'react';

import { getAirline } from 'config/airlines';
import { getAirportCity } from 'config/airports';

import { Airport } from 'client/Airport/types';
import { ArrivalFlight, DepartureFlight } from 'data/Flights/types';

import { AirlineIcon, Tooltip } from 'components';

import { Box } from 'styled';

interface FlightCityProps {
  flight: ArrivalFlight | DepartureFlight;
  airportCode: string;
  airports: Airport[];
  statusColor?: string | null;
}

const FlightCity: React.FC<FlightCityProps> = ({
  flight,
  airportCode,
  airports,
  statusColor,
}: FlightCityProps) => {
  const airport = airports.find(
    airport =>
      airport.code === airportCode || airport.icao === airportCode || airport.faa === airportCode
  );
  const airportCity = getAirportCity(airportCode);

  if (!airport) {
    return (
      <Box alignItems="center" display="flex" color={`${statusColor}`}>
        <AirlineIcon airline={getAirline(flight.cargoCarrier || flight.marketingCarrier)} />
        {airportCity || airportCode}
      </Box>
    );
  }

  const airline =
    (flight.cargoCarrier !== 'unknown' && flight.cargoCarrier) || flight.marketingCarrier;

  return (
    <Box alignItems="center" display="flex" color={`${statusColor}`}>
      <AirlineIcon airline={getAirline(airline)} />
      <Tooltip label={`${airportCode} (${airport?.city}, ${airport?.state})`}>
        {airportCity}
      </Tooltip>
    </Box>
  );
};

export default FlightCity;
