import React, { useEffect, useState } from 'react';

import KEYS from 'config/keys';
import theme from 'config/theme';

import { useKeys } from 'hooks';

import { hideModal, useModalDispatch } from 'providers/ModalProvider';

import { Icon, LinkButton, Logo, UserMenu } from 'components';

import {
  Box,
  Modal as StyledModal,
  ModalClose,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from 'styled';

interface Icon {
  name: string;
  color?: string | undefined;
  stroke?: string;
  fill?: string;
}

interface PlaceholderContentProps {
  icon?: Icon;
  message?: string;
}

export interface Props {
  fullscreen?: boolean;
  large?: boolean;
  icon?: Icon;
  placeholder?: PlaceholderContentProps | boolean | {};
  title?: string;
  width?: string;
  children?: React.ReactNode;
  onClose?: Function;
}

const Modal: React.FC<Props> = (props: Props) => {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useModalDispatch();
  const defaults = {
    width: '800px',
  };

  function hide(): void {
    setLoaded(false);
    props.onClose?.();
    setTimeout(() => dispatch(hideModal()), 500);
  }

  useKeys([
    {
      key: KEYS['escape'],
      action: (): void => hide(),
    },
  ]);

  useEffect(() => {
    let mounted = true;

    // For some reason, this setTimeout makes the CSS transition work
    // TODO: Figure out a better way to do this. react-spring?
    setTimeout(() => {
      if (mounted) setLoaded(true);
    }, 1);

    return (): void => {
      mounted = false;
      setLoaded(false);
      dispatch(hideModal());
    };
  }, [dispatch]);

  function handleClose(event: { preventDefault: Function }): void {
    event.preventDefault();
    hide();
  }

  const placeholderProps = props.placeholder ? props.placeholder : {};

  const renderFullScreenHeader = () => (
    <Box
      flex="1"
      height="68px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pt="s"
      backgroundColor="neutralsGradient.12"
      borderBottom={`4px solid ${theme.colors.neutralsGradient[9]}`}
    >
      <Box
        ml="-xl"
        display="flex"
        flexDirection="row"
        zIndex={theme.zIndices.tooltip + theme.zIndices.leaflet}
      >
        <Box ml="base">
          <Logo hideAlerts={true} />
        </Box>
        <Box ml="s" mt="2px" mr="base">
          <UserMenu hideAvatar={true} side="center" />
        </Box>
      </Box>
      <Box as="h1" ml="-64px" fontWeight="bold">
        {props.icon && props.icon.name && (
          <Icon
            name={props.icon.name}
            color={props.icon?.color || 'primary'}
            mr="s"
            mt="-6px"
            size="24"
          />
        )}
        {props.title || 'Default'}
      </Box>
      <LinkButton as={ModalClose} onClick={handleClose} mr="24px" mt="-8px">
        <Icon
          name="arrowUpAndRight"
          color="neutralsGradient.6"
          size="16"
          mt="12px"
          flexShrink={0}
        />
        <Icon
          name="arrowDownAndLeft"
          color="neutralsGradient.6"
          size="16"
          mt="-12px"
          ml="-4px"
          flexShrink={0}
        />
      </LinkButton>
    </Box>
  );

  const renderHeader = () => (
    <>
      <Box as="h2" flex="1" mb="0">
        {props.icon && props.icon.name && (
          <Icon name={props.icon.name} color={props.icon?.color || 'primary'} mr="s" />
        )}
        {props.title || 'Default'}
      </Box>
      <LinkButton as={ModalClose} onClick={handleClose}>
        <Icon name="x" size="30" />
      </LinkButton>
    </>
  );

  return (
    <>
      <StyledModal
        loaded={loaded}
        width={props.fullscreen || props.large ? 'inherit' : props.width || defaults.width}
        fullscreen={props.fullscreen || false}
        large={props.large || false}
      >
        <ModalHeader>{props.fullscreen ? renderFullScreenHeader() : renderHeader()}</ModalHeader>
        <ModalContent>
          {props.placeholder ? <PlaceholderContent {...placeholderProps} /> : props.children}
        </ModalContent>
      </StyledModal>
      <ModalOverlay onClick={handleClose} loaded={loaded} />
    </>
  );
};

const PlaceholderContent: React.FC<PlaceholderContentProps> = ({
  icon,
  message,
}: PlaceholderContentProps) => {
  return (
    <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" pb="l">
      <Icon name={icon?.name || 'i'} color={icon?.color || 'warning'} size="40" mb="base" />
      <Box as="p" color="subdued">
        {message || 'There is no additional information to display right now.'}
      </Box>
    </Box>
  );
};

export default Modal;
