import { LatLng } from 'leaflet';

import { LINES, POINTS } from 'config/airfield';

import getCode from './getCode';

export default function getPositions(part: string): { positions?: LatLng[]; code?: string } {
  if (part.includes('BTN')) {
    const areas = part.split('BTN');
    const code = getCode(part);

    const lane = LINES.find(l => l.code === code);
    if (!lane) {
      return { code };
    }
    const direction = lane.direction;
    const latLngConstant = direction === 'horizontal' ? 'lat' : 'lng';
    const latLngDynamic = latLngConstant === 'lat' ? 'lng' : 'lat';
    const latLngFromLane = lane.positions[0][latLngConstant];

    const linePoints: LatLng[] = [];

    const compareas = areas[1].split('AND');

    compareas.forEach(comparea => {
      const compareCode = getCode(comparea);
      POINTS.forEach(p => {
        if (compareCode === p.code) {
          const latLngFromPoint = p.position[latLngDynamic];
          const latLng =
            latLngDynamic === 'lat'
              ? new LatLng(latLngFromPoint, latLngFromLane)
              : new LatLng(latLngFromLane, latLngFromPoint);
          linePoints.push(latLng);
        }
      });
      LINES.forEach(l => {
        if (compareCode === l.code) {
          // This is used if the two lines are parallel
          // If the start of the compare line is outside the affected line
          // Set it to the endpoint of the affected line
          // TODO: Is there another string for the other (departing) end?
          if (comparea.includes('APCH END')) {
            const approachEnd = l.positions[0][latLngDynamic];
            let _latLngFromLane: number = approachEnd;
            if (
              approachEnd > lane.positions[0][latLngDynamic] &&
              approachEnd > lane.positions[1][latLngDynamic]
            ) {
              _latLngFromLane = Math.max(
                lane.positions[0][latLngDynamic],
                lane.positions[1][latLngDynamic]
              );
            } else if (
              approachEnd < lane.positions[0][latLngDynamic] &&
              approachEnd < lane.positions[1][latLngDynamic]
            ) {
              _latLngFromLane = Math.min(
                lane.positions[0][latLngDynamic],
                lane.positions[1][latLngDynamic]
              );
            }
            const latLng =
              latLngDynamic === 'lat'
                ? new LatLng(_latLngFromLane, latLngFromLane)
                : new LatLng(latLngFromLane, _latLngFromLane);
            linePoints.push(latLng);
          } else {
            const latLngFromPoint = l.positions[0][latLngDynamic];
            const latLng =
              latLngDynamic === 'lat'
                ? new LatLng(latLngFromPoint, latLngFromLane)
                : new LatLng(latLngFromLane, latLngFromPoint);
            linePoints.push(latLng);
          }
        }
      });
    });

    return {
      positions: linePoints,
      code: lane.code,
    };
  } else {
    const code = getCode(part);
    const point = POINTS.find(p => p.code === code);
    const line = LINES.find(l => l.code === code);
    let positions: LatLng[] = [];

    if (point) {
      positions = [point.position];
    } else if (line) {
      positions = line.positions;
    }

    return {
      positions,
      code,
    };
  }
}
