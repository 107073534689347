import { weatherCodeMap } from 'config/weather';

import { Weather } from 'data/Weather/types';

export interface WeatherDetails {
  temperature: number;
  conditionCode: number;
  conditions: string;
  statusColor: 'error' | 'warning' | 'success';
}

const fakeWeatherObject: WeatherDetails = {
  temperature: NaN,
  conditionCode: 0,
  conditions: '',
  statusColor: 'success',
};

function getWeatherDetails(weather: Weather): WeatherDetails {
  if (!weather) return fakeWeatherObject;

  const temperature = Math.round(weather?.temperature);
  const conditionCode = weather?.conditionCode || 0;
  const conditions = weather?.weatherSummary;
  const statusColor = weatherCodeMap[conditionCode].status;

  return { temperature, conditionCode, conditions, statusColor };
}

export default getWeatherDetails;
