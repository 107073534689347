import React from 'react';

import { Box, TileGrid } from 'styled';
import { SystemProps } from 'styled/lib/types';

interface Props extends SystemProps {
  tiles: string[];
}

const TILE_HEIGHT = 128;

const GridVisualizer: React.FC<Props> = ({ tiles = [] }: Props) => (
  <Box>
    <TileGrid
      count={tiles?.length}
      dashboardMode={false}
      fullscreen={false}
      height={tiles?.length <= 4 ? `${TILE_HEIGHT * 2}px` : `${TILE_HEIGHT}px`}
    >
      {tiles?.map(tile => (
        <Box
          key={tile}
          py="l"
          backgroundColor="neutralsGradient.10"
          display="flex"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          border="1px solid"
          borderColor="neutralsGradient.8"
          borderRadius="base"
        >
          {tile}
        </Box>
      ))}
    </TileGrid>
  </Box>
);

export default GridVisualizer;
