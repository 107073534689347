import { gql, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { Issue, IssuesArgs } from './types';

export const GET_ISSUES = gql`
  query Issues(
    $between: [DateTime]
    $offset: Int
    $limit: Int
    $order: [[String]]
    $status: String
    $severity: String
    $assignee: String
  ) {
    issues(
      between: $between
      offset: $offset
      limit: $limit
      order: $order
      status: $status
      severity: $severity
      assignee: $assignee
    ) {
      id
      createdAt
      updatedAt
      status
      severity
      title
      description
      assignee
    }
  }
`;

export default function useIssues({
  created,
  offset,
  limit,
  order,
  status,
  severity,
  assignee,
  ids,
  skip,
}: IssuesArgs = {}) {
  const variables = pickBy(
    {
      between: created,
      offset,
      limit,
      order,
      status,
      severity,
      assignee,
      ids,
    },
    identity
  );

  // Passing between 'open' and 'closed' was getting too confusing while trying
  // to manage the cache properly, so refetching over the network works better.
  return useQuery<{ issues: Issue[] }>(GET_ISSUES, {
    variables,
    fetchPolicy: 'cache-and-network',
    skip,
  });
}
