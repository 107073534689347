import 'react-nice-dates/build/style.css';

import { addDays, endOfDay, isPast, isToday, startOfDay } from 'date-fns';
import { enUS } from 'date-fns/locale';
import concat from 'lodash/concat';
import uniq from 'lodash/uniq';
import React, { useEffect, useState } from 'react';
import { DatePicker } from 'react-nice-dates';

import { ChartOptions, TabItem } from 'config/tabs';
import theme from 'config/theme';

import { updateActiveChartsState, useActiveCharts } from 'providers/ActiveChartsProvider';
import { updateOptions, useChartOptions } from 'providers/ChartOptionsProvider';

import { getChartDetailsComponent } from 'statistics/charts/utils';
import { getStatTypes } from 'statistics/stats/utils';

import { LinkButton, TextInput } from 'components';

import { Box } from 'styled';

import { ChartsFunctionProps } from '../Chart';
import { getAirportTime } from 'utils';

interface Props {
  currentTab: string;
  tabs: TabItem[];
  renderStats?: Function;
}

let MAX_DAYS_AHEAD = 0;
if (process.env.REACT_APP_CONFIG_PROJECTIONS === 'true') {
  MAX_DAYS_AHEAD = 1;
}

const ChartControls: React.FC<Props> = ({ currentTab, tabs, renderStats, children }) => {
  const [state, dispatch] = useChartOptions();
  const { chartEndDate, frozen } = state;
  const [, activeChartsDispatch] = useActiveCharts();

  const chartFunctionOptions: ChartsFunctionProps = {
    endDate: chartEndDate,
    frozen,
  };

  function setInitialActiveComparisonCharts(): { [key: string]: ChartOptions[] } {
    const tabCharts: { [key: string]: ChartOptions[] } = {};
    tabs.forEach((tab: TabItem) => {
      tabCharts[tab.title] = tab.charts?.(chartFunctionOptions) || [];
    });
    return tabCharts;
  }

  const [activeComparisonCharts] = useState(setInitialActiveComparisonCharts);

  // TODO: Find a way to set activeCharts and activeChartDetails elsewhere
  // So we don't have multiple rerenders when tab is changed
  useEffect(() => {
    // filter out charts that don't have corresponding details or stats
    const activeDetailsComparisonCharts = activeComparisonCharts[currentTab].filter(
      c => Boolean(getChartDetailsComponent(c.type)) || Boolean(getStatTypes(c.type).length)
    );

    // Add selected gates to active flow chart
    let charts = activeComparisonCharts[currentTab];
    if (currentTab === 'Flow') {
      charts = state?.selectedGates?.map((gate: string, index: number) => ({
        type: `GATES${index + 1}`,
        key: gate,
      }));
    }

    activeChartsDispatch(
      updateActiveChartsState({
        activeCharts: charts,
        activeChartDetails: activeDetailsComparisonCharts[0]?.type || '',
      })
    );
  }, [currentTab, activeComparisonCharts, activeChartsDispatch, state]);

  const currentTabItem = tabs.find(tab => currentTab === tab.title);
  let comparableCharts: string[] = [];
  tabs.forEach(tab => {
    const tabCharts = tab.charts?.(chartFunctionOptions) || [];
    if (tab.comparable) {
      comparableCharts = uniq(
        concat(
          comparableCharts,
          tabCharts.map(chart => chart.type)
        )
      );
    }
  });

  const isFlowChart = currentTabItem?.title === 'Flow';
  const now = getAirportTime(new Date());

  return (
    <Box alignItems="center" display="grid" gridTemplateColumns="repeat(2, 1fr)" mb="l">
      <Box alignItems="center" display="flex">
        <Box flexBasis="125px" mr="s" zIndex={theme.zIndices.modalOverlay}>
          <DatePicker
            date={chartEndDate}
            maximumDate={endOfDay(addDays(now, MAX_DAYS_AHEAD))}
            onDateChange={(date: Date | null): void => {
              if (!date) return;
              const newDataStartDate = startOfDay(date);
              const newDataEndDate = endOfDay(date);
              const newChartStartDate = startOfDay(date);
              const newChartEndDate = endOfDay(date);
              const currentDate = isToday(date)
                ? now
                : isPast(date)
                ? newDataEndDate
                : newDataStartDate;
              dispatch(
                updateOptions({
                  dataStartDate: newDataStartDate,
                  dataEndDate: newDataEndDate,
                  chartStartDate: newChartStartDate,
                  chartEndDate: newChartEndDate,
                  currentDate,
                  frozen: false,
                })
              );
            }}
            locale={enUS}
          >
            {({ inputProps }): JSX.Element => <TextInput icon="calendar" {...inputProps} />}
          </DatePicker>
        </Box>

        <Box>{children}</Box>
        <Box ml="xs" zIndex={theme.zIndices.modalOverlay}>
          {isFlowChart && !!state?.selectedGates?.length && (
            <LinkButton
              onClick={(): void => {
                dispatch(updateOptions({ selectedGates: [] }));
              }}
              lineHeight="base"
            >
              Reset
            </LinkButton>
          )}
        </Box>
      </Box>
      <Box justifySelf="flex-end" zIndex={theme.zIndices.modalOverlay}>
        {renderStats && renderStats()}
      </Box>
    </Box>
  );
};

export default ChartControls;
