import React, { Fragment } from 'react';
import { Popup as LeafletPopup } from 'react-leaflet';

import { FacilityCondition } from 'client/FacilityConditions/types';
import { Issue } from 'client/Issue/types';

import { Box, Stack } from 'styled';

interface FacilityConditionsPopupProps {
  facilityConditions: FacilityCondition[];
  issues: Issue[];
  feed: string;
  className?: string;
}

const FacilityConditionsPopup: React.FC<FacilityConditionsPopupProps> = ({
  feed,
  facilityConditions,
  issues,
  className,
}: FacilityConditionsPopupProps) => {
  return (
    <LeafletPopup className={className}>
      <Stack direction="y" yspace="xs">
        <Box>
          <strong>{feed}</strong>
        </Box>
        {facilityConditions.map((facilityCondition, i) => (
          <Fragment key={`${feed}-${i}`}>
            <Box>
              <strong>{facilityCondition.assets}: </strong>
              {issues.some(issue => issue.id === facilityCondition.issueId) ? 'Alarm' : 'OK'}
            </Box>
          </Fragment>
        ))}
      </Stack>
    </LeafletPopup>
  );
};

export default FacilityConditionsPopup;
