import isEqual from 'lodash/isEqual';

import { getGlobalData } from 'utils/globalData';

import { Airport } from 'client/Airport/types';

// DCA = Washington - Reagan
// ORD = Chicago - O'Hare
// MDW = Chicago - Midway
// IAD = Washington - Dulles
// JFK = New York - JFK
// LGA = New York - LGA
const AIRPORT_CITY_OVERRIDES_IATA: { [key: string]: string } = {
  DCA: 'Washington - Reagan',
  ORD: `Chicago - O'Hare`,
  MDW: 'Chicago - Midway',
  IAD: 'Washington - Dulles',
  JFK: 'New York - JFK',
  LGA: 'New York - LGA',
  YYZ: 'Toronto - Pearson',
};

const AIRPORT_CITY_OVERRIDES_ICAO: { [key: string]: string } = {
  KDCA: 'Washington - Reagan',
  KORD: `Chicago - O'Hare`,
  KMDW: 'Chicago - Midway',
  KIAD: 'Washington - Dulles',
  KJFK: 'New York - JFK',
  KLGA: 'New York - LGA',
};

export const getAirport = (code: string): Airport | null => {
  const airports = getGlobalData('airports');
  //@ts-expect-error;
  const match = airports?.find((airport: Airport) => {
    return airport.code === code || airport.icao === code || airport.faa === code;
  });
  if (!match) return null;
  return match;
};

export const isAirportMatch = (bpCode: string, flightCode: string): boolean => {
  const bpAirport = getAirport(bpCode);
  const flightAirport = getAirport(flightCode);

  return isEqual(bpAirport, flightAirport);
};

export const getAirportCity = (code: string): string => {
  const airport = getAirport(code);
  return (
    AIRPORT_CITY_OVERRIDES_ICAO?.[code] ||
    AIRPORT_CITY_OVERRIDES_IATA?.[code] ||
    airport?.city ||
    code
  );
};
