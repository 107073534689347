import React from 'react';

import { useUsers } from 'client/User';
import { User } from 'client/User/types';

import { Search } from 'components';

import { Avatar, Box } from 'styled';

interface Props {
  id: string;
  name: string;
  onChange: Function;
  onClear: Function;
  onSelect: Function;
  value: string;
}

const UserSearch: React.FC<Props> = (props: Props) => {
  const { data, loading } = useUsers();

  return (
    <Search
      data={data?.users || []}
      matcherKeys={['name', 'email']}
      inputValue={props.value}
      renderResult={(user: User): JSX.Element => (
        <Box alignItems="center" display="flex">
          <Box mr="xs">
            <Avatar src={user.picture} alt="" height="20" width="20" />
          </Box>
          <Box flex="1">{user.name}</Box>
        </Box>
      )}
      side="left"
      resultsWidth="100%"
      disabled={loading}
      {...props}
    />
  );
};

export default UserSearch;
