import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useQueryParams } from 'hooks';
import { track } from 'utils';

import { showModal, useModalDispatch } from 'providers/ModalProvider';

import { Icon, Menu, Tab, Tabs, Tooltip } from 'components';
import { Props as IconProps } from 'components/Icon';
import { Option, TriggerArgs } from 'components/Menu';
import { Props as TabsProps, TabProps } from 'components/Tabs';

import {
  ResetButton,
  Tile as StyledTile,
  TileBackground,
  TileContent,
  TileContentContainer,
  TileContentInner,
  TileFullscreen,
  TileHeader,
  TileOptions,
  TileTab as StyledTileTab,
  TileTabs as StyledTileTabs,
} from 'styled';
import { SystemProps } from 'styled/lib/types';

interface ModalIcon {
  name: string;
}

interface ModalProps {
  stateKey?: string;
  fullscreen?: boolean;
  title?: string;
  icon?: ModalIcon;
  children?: JSX.Element | null;
  large?: boolean;
  onClose?: Function;
  width?: string;
}

interface Props {
  contentAlignment?: string;
  contentPadding?: string;
  floatingHeader?: boolean;
  icon?: string;
  title: string;
  menuWidth?: string;
  modal?: string;
  modalProps?: ModalProps;
  options?: Option[];
  tooltip?: string;
  tooltipIcon?: IconProps;
  tooltipClickTriggerModal?: boolean;
}

export const Tile: React.FC<Props & SystemProps> = ({
  children,
  contentAlignment,
  contentPadding,
  floatingHeader,
  icon,
  title,
  backgroundImage,
  menuWidth,
  modal,
  modalProps,
  options,
  tooltip,
  tooltipIcon,
  tooltipClickTriggerModal,
  gridColumn,
  gridRow,
}) => {
  const modalDispatch = useModalDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = useQueryParams();
  const tile = query.get('tile');

  // If query param tile is this tile go fullscreen
  useEffect(() => {
    const isFullScreenTile = tile === title?.toLowerCase();
    if (isFullScreenTile && modal) modalDispatch(showModal(modal, modalProps));
  }, [query, tile, title, modal, modalProps, modalDispatch]);

  function handleFullscreenClick(event: React.MouseEvent): void {
    event?.preventDefault();
    track('Open Fullscreen Tile');
    if (modal) modalDispatch(showModal(modal, modalProps));
    const isNewFullScreenTile = !tile || tile !== title?.toLowerCase();
    if (modalProps?.fullscreen && isNewFullScreenTile) {
      history.push({
        pathname: location.pathname,
        search: `?${query.toString() ? query.toString() + '&' : ''}tile=${title?.toLowerCase()}`,
      });
    }
  }

  return (
    <StyledTile gridColumn={gridColumn} gridRow={gridRow}>
      <TileContentContainer>
        <TileHeader floatingHeader={floatingHeader || false}>
          {icon && <Icon name={icon} color="primary" mr="s" />}
          {title}
          {tooltip && (
            <Tooltip label={tooltip}>
              <ResetButton
                onClick={(event: React.MouseEvent): void => {
                  if (tooltipClickTriggerModal) handleFullscreenClick(event);
                  return;
                }}
              >
                <Icon
                  name="questionMark"
                  color="subdued"
                  size="18"
                  ml="s"
                  position="relative"
                  top="-2px"
                  {...tooltipIcon}
                />
              </ResetButton>
            </Tooltip>
          )}
          {options && (
            <TileOptions>
              <Menu
                renderTrigger={({ getTriggerProps }: TriggerArgs): JSX.Element => (
                  <Tooltip label="View options for this tile">
                    <ResetButton color="subdued" {...getTriggerProps()}>
                      <Icon name="gear" size="16" mr="s" color="neutralsGradient.6" />
                    </ResetButton>
                  </Tooltip>
                )}
                options={options}
                width={menuWidth || '14.5rem'}
              />
            </TileOptions>
          )}
          {modal && (
            <Tooltip label="View more information">
              <TileFullscreen onClick={handleFullscreenClick}>
                <Icon name="maximize" size="16" color="neutralsGradient.6" />
              </TileFullscreen>
            </Tooltip>
          )}
        </TileHeader>
        <TileContent contentAlignment={contentAlignment} contentPadding={contentPadding}>
          <TileContentInner contentAlignment={contentAlignment}>{children}</TileContentInner>
        </TileContent>
      </TileContentContainer>
      {backgroundImage ? <TileBackground backgroundImage={backgroundImage} /> : null}
    </StyledTile>
  );
};

const TileTabs: React.FC<TabsProps> = ({ children, ...rest }: TabsProps) => {
  const count = React.Children.toArray(children).length;
  return (
    <StyledTileTabs as={Tabs} count={count} {...rest}>
      {children}
    </StyledTileTabs>
  );
};

const TileTab: React.FC<TabProps> = props => <StyledTileTab as={Tab} {...props} />;

export { Tile as default, TileTabs, TileTab };
