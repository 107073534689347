// import { isFuture, isToday } from 'date-fns';
import { SeriesOptionsType, YAxisOptions } from 'highcharts';

import { CHART_TYPES, FILTER_TYPES } from 'statistics/charts/constants';
import { AIRPORT_CODE } from './constants';
import { ChartsFunctionProps } from 'components/Dashboard/Chart';

export type ChartOptions = {
  type: string;
  seriesOptions?: SeriesOptionsType;
  yAxisOptions?: YAxisOptions;
};
export type ChartOptionsFilter = { type: string; label: string; chart?: string };

export interface TabItem {
  title: string;
  icon: string;
  permission: string;
  charts?: (options: ChartsFunctionProps) => ChartOptions[];
  colors?: string[];
  defaultFilterLabel?: string;
  filters?: ChartOptionsFilter[];
  /** Enables export menu */
  exportable?: boolean;
  /** Enables dropdown for filterable charts */
  filterable?: boolean;
  /** Enables dropdown for comparison charts */
  comparable?: boolean;
  /** Each chart is separated */
  exclusive?: boolean;
  /** Displays tab depending on config */
  configSetting?: boolean;
}

export const REPORT_TABS: TabItem[] = [
  {
    title: 'Security',
    icon: 'lock',
    permission: 'read:security',
    comparable: true,
    exportable: true,
    configSetting: process.env.REACT_APP_CONFIG_WAIT_TIMES === 'true',
    charts: (): ChartOptions[] =>
      [
        { type: CHART_TYPES.WAIT_TIMES.WAIT_TIMES1 },
        { type: CHART_TYPES.WAIT_TIMES.WAIT_TIMES2 },
        { type: CHART_TYPES.WAIT_TIMES.WAIT_TIMES3 },
        { type: CHART_TYPES.WAIT_TIMES.WAIT_TIMES4 },
        { type: CHART_TYPES.WAIT_TIMES.WAIT_TIMES5 },
      ].slice(0, parseInt(process.env.REACT_APP_CHECKPOINT_TOTAL || '5')),
  },
  {
    title: 'Departures',
    icon: 'arrowUpAndRight',
    permission: 'read:flight',
    comparable: true,
    exportable: true,
    configSetting: true,
    charts: (): ChartOptions[] => [
      { type: CHART_TYPES.DEPARTURES.CARGO },
      { type: CHART_TYPES.DEPARTURES.PASSENGER },
    ],
  },
  {
    title: 'Arrivals',
    icon: 'arrowUpAndRight',
    permission: 'read:flight',
    comparable: true,
    exportable: true,
    configSetting: true,
    charts: (): ChartOptions[] => [
      { type: CHART_TYPES.ARRIVALS.CARGO },
      { type: CHART_TYPES.ARRIVALS.PASSENGER },
    ],
  },
  {
    title: 'Flight Performance',
    icon: 'userCheck',
    permission: 'read:scans',
    comparable: true,
    exportable: true,
    configSetting: process.env.REACT_APP_CONFIG_BOOKINGS === 'true',
    charts: (): ChartOptions[] => [{ type: CHART_TYPES.FLIGHT_PERFORMANCE }],
  },
  {
    title: 'Cargo Revenue',
    icon: 'dollarSign',
    permission: 'read:flight',
    comparable: true,
    exportable: true,
    configSetting: process.env.REACT_APP_CONFIG_CARGO === 'true',
    charts: (): ChartOptions[] => [{ type: CHART_TYPES.CARGO_REVENUE }],
  },
  {
    title: 'Sales',
    icon: 'dollarSign',
    permission: 'read:sales',
    comparable: true,
    exportable: true,
    configSetting: process.env.REACT_APP_CONFIG_SALES === 'true',
    charts: (): ChartOptions[] => [
      { type: CHART_TYPES.SALES.CONCOURSE_A },
      { type: CHART_TYPES.SALES.CONCOURSE_B },
      { type: CHART_TYPES.SALES.MAIN_TERMINAL },
      { type: CHART_TYPES.SALES.SHORT_TERM },
      { type: CHART_TYPES.SALES.LONG_TERM },
      { type: CHART_TYPES.SALES.ECONO_LOT },
      { type: CHART_TYPES.SALES.VALET },
      { type: CHART_TYPES.SALES.TOTAL },
    ],
  },
  {
    title: 'Passengers',
    icon: 'users',
    permission: 'read:scans',
    exclusive: true,
    configSetting: process.env.REACT_APP_CONFIG_PASSENGERS === 'true',
    charts: (): ChartOptions[] => [
      { type: CHART_TYPES.PASSENGERS.AGE },
      { type: CHART_TYPES.PASSENGERS.GENDER },
      { type: CHART_TYPES.PASSENGERS.STATUS },
    ],
  },
  {
    title: 'Passenger Projection',
    icon: 'users',
    permission: 'read:projections',
    comparable: true,
    configSetting: process.env.REACT_APP_CONFIG_PROJECTIONS === 'true',
    charts: (): ChartOptions[] => [
      { type: CHART_TYPES.PROJECTION.AM },
      { type: CHART_TYPES.PROJECTION.PM },
    ],
  },
  {
    title: 'Daily Throughput',
    icon: 'sliders',
    permission: 'read:throughput',
    comparable: process.env.REACT_APP_CONFIG_REPORTS_V2 === 'true',
    filterable: true,
    configSetting: true,
    charts: (): ChartOptions[] =>
      [
        { type: CHART_TYPES.DAILY_THROUGHPUT.DAILY },
        { type: CHART_TYPES.DAILY_THROUGHPUT.ROLLING_AVG },
        { type: CHART_TYPES.DAILY_THROUGHPUT.ONE_YEAR_AGO },
        { type: CHART_TYPES.DAILY_THROUGHPUT.YEAR_2019 },
      ].filter(chart => {
        if (process.env.REACT_APP_CONFIG_REPORTS_V2 === 'true') {
          return true;
        }
        return chart.type === CHART_TYPES.DAILY_THROUGHPUT.DAILY;
      }),
    filters: [
      { type: FILTER_TYPES.DAILY_THROUGHPUT.LOCAL, label: `${AIRPORT_CODE} Only` },
      { type: FILTER_TYPES.DAILY_THROUGHPUT.ALL_AIRPORTS, label: 'All Airports' },
    ],
    defaultFilterLabel: 'Regional Airports',
  },
  {
    title: 'Bookings',
    icon: 'book',
    permission: 'read:bookings',
    comparable: true,
    configSetting: process.env.REACT_APP_CONFIG_BOOKINGS === 'true',
    charts: (): ChartOptions[] => [{ type: CHART_TYPES.BOOKINGS }],
  },
  {
    title: 'Baggage',
    icon: 'briefcase',
    permission: 'read:baggage',
    comparable: true,
    configSetting: process.env.REACT_APP_CONFIG_BAGGAGE === 'true',
    charts: (): ChartOptions[] => [
      { type: CHART_TYPES.BAGGAGE.B_BAGS },
      { type: CHART_TYPES.BAGGAGE.T_DRIVE },
      { type: CHART_TYPES.BAGGAGE.TOTAL },
      { type: CHART_TYPES.BAGGAGE.BAGS_RECEIVED },
      // Note: commenting this out until TSA fixes equipment https://3.basecamp.com/3926363/buckets/25328331/todos/4785271020
      // { type: CHART_TYPES.BAGGAGE.BAGS_CLEARED },
      { type: CHART_TYPES.BAGGAGE.ML1_READ_RATE },
      { type: CHART_TYPES.BAGGAGE.ML2_READ_RATE },
      { type: CHART_TYPES.BAGGAGE.CB1_READ_RATE },
    ],
  },
  {
    title: 'Common Use Bag Tags',
    icon: 'sliders',
    permission: 'read:common-use',
    comparable: true,
    filterable: true,
    defaultFilterLabel: 'Ticket Counters Only',
    configSetting: process.env.REACT_APP_CONFIG_COMMON_USE === 'true',
    charts: (): ChartOptions[] => [{ type: CHART_TYPES.COMMON_USE.BAG_TAGS }],
    filters: [
      { type: FILTER_TYPES.COMMON_USE.GATE_COUNTER, label: 'Gate Counters Only' },
      { type: FILTER_TYPES.COMMON_USE.SHOW_ALL, label: 'All Counters' },
    ],
  },
  {
    title: 'Common Use Workstations',
    icon: 'sliders',
    permission: 'read:common-use',
    comparable: true,
    filterable: true,
    configSetting: process.env.REACT_APP_CONFIG_COMMON_USE === 'true',
    charts: (): ChartOptions[] => [{ type: CHART_TYPES.COMMON_USE.WORKSTATIONS }],
    filters: [
      { type: FILTER_TYPES.COMMON_USE.GATE_COUNTER, label: 'Gate Counter' },
      { type: FILTER_TYPES.COMMON_USE.TICKET_COUNTER, label: 'Ticket Counter' },
    ],
  },
  {
    title: 'Common Use Kiosks',
    icon: 'sliders',
    permission: 'read:common-use',
    comparable: true,
    configSetting: process.env.REACT_APP_CONFIG_COMMON_USE === 'true',
    charts: (): ChartOptions[] => [
      { type: CHART_TYPES.COMMON_USE_KIOSKS.BAG_TAGS },
      { type: CHART_TYPES.COMMON_USE_KIOSKS.BOARDING_PASSES },
    ],
  },
  {
    title: 'TSA Counts',
    icon: 'users',
    permission: 'read:throughput',
    comparable: true,
    configSetting: process.env.REACT_APP_CONFIG_TSA_COUNTS === 'true',
    charts: (): ChartOptions[] => [
      { type: CHART_TYPES.TSA_COUNTS.CURRENT },
      { type: CHART_TYPES.TSA_COUNTS.YEAR_2019 },
    ],
  },
];

export const DASHBOARD_TABS: TabItem[] = [
  {
    title: 'Security',
    icon: 'lock',
    permission: 'read:security',
    comparable: true,
    exportable: true,
    configSetting: process.env.REACT_APP_CONFIG_WAIT_TIMES === 'true',
    charts: (): ChartOptions[] => {
      let chartTypes: ChartOptions[] = [];
      chartTypes.push({
        type: CHART_TYPES.WAIT_TIMES.WAIT_TIMES1,
      });
      chartTypes.push({
        type: CHART_TYPES.WAIT_TIMES.WAIT_TIMES2,
      });
      chartTypes.push({
        type: CHART_TYPES.WAIT_TIMES.WAIT_TIMES3,
      });
      chartTypes.push({
        type: CHART_TYPES.WAIT_TIMES.WAIT_TIMES4,
      });
      chartTypes.push({
        type: CHART_TYPES.WAIT_TIMES.WAIT_TIMES5,
      });
      chartTypes = chartTypes.slice(0, parseInt(process.env.REACT_APP_CHECKPOINT_TOTAL || '5'));
      return chartTypes;
    },
  },
  {
    title: 'Flights',
    icon: 'arrowUpAndRight',
    permission: 'read:flight',
    comparable: true,
    exportable: true,
    configSetting: true,
    charts: (): ChartOptions[] => [
      { type: CHART_TYPES.ARRIVALS.PASSENGER },
      { type: CHART_TYPES.DEPARTURES.PASSENGER },
      { type: CHART_TYPES.DEPARTURES.CARGO },
      { type: CHART_TYPES.ARRIVALS.CARGO },
    ],
  },
  {
    title: 'Flow',
    icon: 'users',
    permission: 'read:flow',
    comparable: true,
    exportable: true,
    configSetting: process.env.REACT_APP_CONFIG_BOOKINGS === 'true',
    charts: (): ChartOptions[] => [
      { type: CHART_TYPES.GATES.GATES1 },
      { type: CHART_TYPES.GATES.GATES2 },
      { type: CHART_TYPES.GATES.GATES3 },
      { type: CHART_TYPES.GATES.GATES4 },
    ],
  },
  {
    title: 'Projection',
    icon: 'users',
    permission: 'read:projections',
    comparable: true,
    exportable: true,
    configSetting: process.env.REACT_APP_CONFIG_PROJECTIONS === 'true',
    charts: (): ChartOptions[] => [{ type: CHART_TYPES.DAILY_PROJECTION }],
  },
];
