import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';

import { CHART_COLORS_ARRAY } from 'config/chartColors';

import { getDefaultSeriesOptions } from 'statistics/charts/utils';
import { DeparturesChartAirline } from 'statistics/types';

export default function getSeries(
  data: DeparturesChartAirline[],
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType,
  key: string,
  index: number
): SeriesOptionsType {
  return merge(getDefaultSeriesOptions(), {
    name: key,
    yAxis: 'passengers',
    stack: 'passengers',
    stacking: 'normal',
    data: data?.[0]?.data,
    borderWidth: 0,
    crisp: false,
    grouping: false,
    color: CHART_COLORS_ARRAY[index],
    visible: true,
    ...chartOptions,
    tooltip: {
      pointFormatter(): string {
        let { y } = this;
        const { color, series, index } = this;
        y = Math.ceil(y || 0);
        let total = 0;
        const seriesData = series?.chart?.series || [];
        const areaNames = [...new Set(seriesData?.map(datum => datum?.name))];
        series?.chart?.series?.forEach(s => {
          if (areaNames.includes(s.name)) total += s.yData[index];
        });
        return `<span style="color:${color}">●</span> ${
          series?.name
        }: <b>${y.toLocaleString()} / ${total.toLocaleString()}</b><br/>`;
      },
    },
    type: 'column',
  } as SeriesOptionsType);
}
