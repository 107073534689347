import { ApolloError } from '@apollo/client';
import React, { createContext, useContext, useMemo } from 'react';

import { useTrafficAlerts, useTrafficFlow } from 'tiles/Traffic/hooks';
import { TrafficAlert, TrafficFlow } from 'tiles/Traffic/types';

import { useRefreshedAt } from 'hooks';

interface Context {
  loading: boolean;
  error?: ApolloError;
  alerts: TrafficAlert[];
  flow?: TrafficFlow;
  refreshedAt: Date;
}

const TrafficProviderContext = createContext<Context>({
  loading: false,
  error: undefined,
  alerts: [],
  flow: undefined,
  refreshedAt: new Date(),
});

const TrafficProvider: React.FC = ({ children }) => {
  const trafficAlertsQuery = useTrafficAlerts();
  const trafficAlertsQueryData: TrafficAlert[] = trafficAlertsQuery.alerts || [];

  const trafficFlowQuery = useTrafficFlow();
  const trafficFlowQueryData: TrafficFlow = trafficFlowQuery.flow;

  const loading = trafficAlertsQuery.loading || trafficFlowQuery.loading;
  const error = trafficAlertsQuery.error || trafficFlowQuery.error;

  const { refreshedAt } = useRefreshedAt(loading);

  const state = useMemo(
    () => ({
      loading,
      error,
      alerts: trafficAlertsQueryData,
      flow: trafficFlowQueryData,
      refreshedAt,
    }),
    [loading, error, trafficAlertsQueryData, trafficFlowQueryData, refreshedAt]
  );

  return (
    <TrafficProviderContext.Provider value={state}>{children}</TrafficProviderContext.Provider>
  );
};

function useTraffic(): Context {
  const context = useContext(TrafficProviderContext);
  return context;
}

export { TrafficProvider as default, useTraffic };
