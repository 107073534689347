import { StatItem, StatsArgs } from 'statistics/types';

import { STAT_TYPES } from './constants';

function getAverageStatItem(area: string) {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: `Average ${area}`,
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  });
}

function getHighestStatItem(area: string) {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: `Highest ${area}`,
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  });
}

function getLowestStatItem(area: string) {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: `Lowest ${area}`,
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  });
}

export default {
  [STAT_TYPES.BAGGAGE.B_BAGS.AVERAGE]: getAverageStatItem('B-Bags'),
  [STAT_TYPES.BAGGAGE.B_BAGS.HIGHEST]: getHighestStatItem('B-Bags'),
  [STAT_TYPES.BAGGAGE.B_BAGS.LOWEST]: getLowestStatItem('B-Bags'),
  [STAT_TYPES.BAGGAGE.T_DRIVE.AVERAGE]: getAverageStatItem('T-Drive'),
  [STAT_TYPES.BAGGAGE.T_DRIVE.HIGHEST]: getHighestStatItem('T-Drive'),
  [STAT_TYPES.BAGGAGE.T_DRIVE.LOWEST]: getLowestStatItem('T-Drive'),
  [STAT_TYPES.BAGGAGE.TOTAL.AVERAGE]: getAverageStatItem('Baggage'),
  [STAT_TYPES.BAGGAGE.TOTAL.HIGHEST]: getHighestStatItem('Baggage'),
  [STAT_TYPES.BAGGAGE.TOTAL.LOWEST]: getLowestStatItem('Baggage'),
  [STAT_TYPES.BAGGAGE.BAGS_RECEIVED.AVERAGE]: getAverageStatItem('Bags Received'),
  [STAT_TYPES.BAGGAGE.BAGS_RECEIVED.HIGHEST]: getHighestStatItem('Bags Received'),
  [STAT_TYPES.BAGGAGE.BAGS_RECEIVED.LOWEST]: getLowestStatItem('Bags Received'),
  [STAT_TYPES.BAGGAGE.BAGS_CLEARED.AVERAGE]: getAverageStatItem('Bags Cleared'),
  [STAT_TYPES.BAGGAGE.BAGS_CLEARED.HIGHEST]: getHighestStatItem('Bags Cleared'),
  [STAT_TYPES.BAGGAGE.BAGS_CLEARED.LOWEST]: getLowestStatItem('Bags Cleared'),
  [STAT_TYPES.BAGGAGE.ML1_READ_RATE.AVERAGE]: getAverageStatItem('ML1 Read Rate %'),
  [STAT_TYPES.BAGGAGE.ML1_READ_RATE.HIGHEST]: getHighestStatItem('ML1 Read Rate %'),
  [STAT_TYPES.BAGGAGE.ML1_READ_RATE.LOWEST]: getLowestStatItem('ML1 Read Rate %'),
  [STAT_TYPES.BAGGAGE.ML2_READ_RATE.AVERAGE]: getAverageStatItem('ML2 Read Rate %'),
  [STAT_TYPES.BAGGAGE.ML2_READ_RATE.HIGHEST]: getHighestStatItem('ML2 Read Rate %'),
  [STAT_TYPES.BAGGAGE.ML2_READ_RATE.LOWEST]: getLowestStatItem('ML2 Read Rate %'),
  [STAT_TYPES.BAGGAGE.CB1_READ_RATE.AVERAGE]: getAverageStatItem('CB1 Read Rate %'),
  [STAT_TYPES.BAGGAGE.CB1_READ_RATE.HIGHEST]: getHighestStatItem('CB1 Read Rate %'),
  [STAT_TYPES.BAGGAGE.CB1_READ_RATE.LOWEST]: getLowestStatItem('CB1 Read Rate %'),
};
