import React from 'react';

// The <ProviderComposer> component is used to avoid the annoyance of
// having an endless nesting of Provider components wrapping the root-level
// <App> component, while still providing the flexibility of adding multiple
// Providers throughout the app for shared state management.
interface Props {
  contexts: React.ReactElement[];
}
const ProviderComposer: React.FC<Props> = ({ contexts, children }) => {
  return (
    <>
      {contexts.reduceRight(
        (kids, parent, idx) =>
          React.cloneElement(parent, {
            key: idx,
            children: kids,
          }),
        children
      )}
    </>
  );
};

export default ProviderComposer;
