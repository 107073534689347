import { gql, QueryResult, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { Airline, AirlineArgs } from './types';

const GET_AIRLINES = gql`
  query Airlines($filter: airlineFilter) {
    airlines(filter: $filter) {
      id
      name
      iata
      icao
      signatory
      marketing
      createdAt
      updatedAt
    }
  }
`;

export default function useAirlines({ filter }: AirlineArgs = {}): QueryResult<{
  airlines: Airline[];
}> {
  const variables = pickBy({ filter }, identity);
  return useQuery(GET_AIRLINES, { variables });
}
