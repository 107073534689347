import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const SUBMIT_USER_FEEDBACK = gql`
  mutation SubmitUserFeedback(
    $name: String
    $email: String!
    $feedback: String!
    $browserInfo: String
    $browserSize: String
  ) {
    submitUserFeedback(
      name: $name
      email: $email
      feedback: $feedback
      browserInfo: $browserInfo
      browserSize: $browserSize
    ) {
      id
    }
  }
`;

export default function useSubmitUserFeedback(options: MutationHookOptions = {}) {
  return useMutation(SUBMIT_USER_FEEDBACK, options);
}
