import React, { useState } from 'react';

import { User } from 'client/User/types';

import { showModal, useModalDispatch } from 'providers/ModalProvider';

import { updateFilter, useIssuesFilter } from 'providers/IssuesFilterProvider';

import {
  Button,
  FormField,
  Icon,
  RequiredPermission,
  Select,
  TextInput,
  UserSearch,
} from 'components';

import { Box } from 'styled';

const IssuesSidebar: React.FC = () => {
  const [state, dispatch] = useIssuesFilter();
  const modalDispatch = useModalDispatch();
  // We're tracking the `assignee` field separately because we only
  // care about updating the filter during the `onSelect` and
  // `onClear` events.
  const [assignee, setAssignee] = useState({ name: '', email: '' });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;
    if (name === 'created') dispatch(updateFilter({ [name]: value, createdValue: value }));
    dispatch(updateFilter({ [name]: value }));
  }

  // See note above about tracking the `assignee` field separately.
  function handleAssigneeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setAssignee({ name: value, email: '' });
  }

  return (
    <>
      <Box alignItems="center" display="flex" mb="l">
        <Box as="h2" mb="0">
          <Icon name="filter" color="primary" size="24" mr="s" />
          Filter
        </Box>
        <RequiredPermission permission="create:issues">
          <Box ml="auto">
            <Button onClick={() => modalDispatch(showModal('NewIssue'))} size="s">
              Create Issue
            </Button>
          </Box>
        </RequiredPermission>
      </Box>
      <Box as="form">
        <FormField label="Search" type="stacked" mb="l">
          <TextInput
            name="search"
            id="search"
            placeholder="Search for an issue..."
            icon="magnifyingGlass"
            onChange={handleChange}
            value={state.search}
          />
        </FormField>
        <FormField label="Assigned" type="stacked" mb="l">
          <UserSearch
            name="assigneeName"
            id="assignee"
            value={assignee.name || ''}
            onChange={handleAssigneeChange}
            onSelect={(selection: User): void => {
              setAssignee({
                email: selection.email,
                name: selection.name,
              });
              dispatch(updateFilter({ assignee: selection.email }));
            }}
            onClear={(): void => {
              setAssignee({ name: '', email: '' });
              dispatch(updateFilter({ assignee: '' }));
            }}
          />
        </FormField>
        <FormField label="Created" type="stacked" mb="l">
          <Select
            name="created"
            type="radio"
            options={[
              { value: '', label: 'Anytime' },
              { value: 'past-week', label: 'Past week' },
              { value: 'past-month', label: 'Past month' },
              { value: 'past-year', label: 'Past year' },
            ]}
            onChange={handleChange}
            value={state.createdValue}
          />
        </FormField>
        <FormField label="Severity" type="stacked" mb="l">
          <Select
            name="severity"
            type="radio"
            options={[
              { value: '', label: 'Any severity' },
              { value: 'severe', label: 'Severe' },
              { value: 'moderate', label: 'Moderate' },
              { value: 'light', label: 'Light' },
            ]}
            onChange={handleChange}
            value={state.severity}
          />
        </FormField>
        <FormField label="Status" type="stacked" mb="l">
          <Select
            name="status"
            type="radio"
            options={[
              { value: 'open', label: 'Open' },
              { value: 'closed', label: 'Closed' },
            ]}
            onChange={handleChange}
            value={state.status}
          />
        </FormField>
      </Box>
    </>
  );
};

export default IssuesSidebar;
