import { themeGet } from '@styled-system/theme-get';
import styled, { keyframes } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Popup = styled.div<SystemProps>`
  animation: ${fadeIn} 0.5s 1;
  animation-fill-mode: forwards;
  background-color: ${themeGet('colors.neutralsGradient.11')};
  border-radius: 4px;
  top: 80px;
  box-shadow: ${themeGet('shadows.l')};
  left: 50%;
  color: ${themeGet('colors.neutralsGradient.1')};
  padding: ${themeGet('space.s')} ${themeGet('space.base')};
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
  max-width: 37.5rem;
  z-index: ${themeGet('zIndices.toast')};

  ${system}
`;

export default Popup;
