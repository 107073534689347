import { gql, QueryResult, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { Airport, AirportArgs } from './types';

const GET_AIRPORTS = gql`
  query Airports($filter: airportFilter) {
    airports(filter: $filter) {
      id
      name
      code
      icao
      faa
      state
      city
      status
      description
      latitude
      longitude
      delayInfo
      weather {
        currently {
          conditionCode
          summary
          temperature
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export default function useAirports({ filter }: AirportArgs = {}): QueryResult<{
  airports: Airport[];
}> {
  const variables = pickBy({ filter }, identity);
  return useQuery(GET_AIRPORTS, { variables });
}
