import { StatItem, StatsArgs } from 'statistics/types';

import { STAT_TYPES } from './constants';

function getAverageStatItem(type: string) {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: `Average ${type} Duration`,
    value: Math.round(value as number).toLocaleString(),
    contextLabel,
  });
}

function getHighestStatItem(type: string) {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: `Highest ${type} Duration`,
    value: value.toLocaleString(),
    contextLabel,
  });
}

function getLowestStatItem(type: string) {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: `Lowest ${type} Duration`,
    value: value.toLocaleString(),
    contextLabel,
  });
}

export default {
  [STAT_TYPES.COMMON_USE.WORKSTATIONS.AVERAGE]: getAverageStatItem('Workstation'),
  [STAT_TYPES.COMMON_USE.WORKSTATIONS.HIGHEST]: getHighestStatItem('Workstation'),
  [STAT_TYPES.COMMON_USE.WORKSTATIONS.LOWEST]: getLowestStatItem('Workstation'),
  [STAT_TYPES.COMMON_USE.BAG_TAGS.AVERAGE]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Average Number Per Day',
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  }),
  [STAT_TYPES.COMMON_USE.BAG_TAGS.HIGHEST]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Highest Number of Bag Tags',
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  }),
  [STAT_TYPES.COMMON_USE.BAG_TAGS.LOWEST]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Lowest Number of Bag Tags',
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  }),
  [STAT_TYPES.COMMON_USE.BAG_TAGS.TOTAL]: ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Total Number of Bag Tags',
    value: Math.floor(value as number).toLocaleString(),
    contextLabel,
  }),
};
