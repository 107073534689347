import { MutableRefObject, useEffect, useRef, useState } from 'react';

type Callback = (...args: any) => any;

export default function useInterval(callback: Callback, delay: number): any {
  const [value, setValue] = useState();
  const savedCallback: MutableRefObject<any> = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick(): void {
      setValue(savedCallback.current());
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return (): void => clearInterval(id);
    }
  }, [delay]);

  return value;
}
