import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import { SystemProps } from './lib/types';

type Props = { disabled?: boolean } & SystemProps;

export const PAGINATION_BAR_HEIGHT = '63px';

const PaginationBar = styled.div`
  align-items: center;
  background-color: ${themeGet('colors.neutralsGradient.11')};
  border-top: 2px solid ${themeGet('colors.neutralsGradient.10')};
  bottom: 0;
  display: grid;
  grid-template-columns: 500px 1fr 500px;
  height: 63px;
  padding-left: ${themeGet('space.l')};
  padding-right: ${themeGet('space.l')};
  position: fixed;
  width: 100%;
  z-index: ${themeGet('zIndices.modal')};
`;

const Pagination = styled.div`
  align-items: center;
  display: flex;
  gap: ${themeGet('space.base')};
  justify-content: center;
`;

const PaginationGroup = styled.ul`
  align-items: center;
  display: flex;
  gap: ${themeGet('space.s')};
  justify-content: center;
`;

const PaginationOption = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const PaginationIcon = styled.div`
  transition-duration: 0.2s;
  &:active,
  &:focus,
  &:hover {
    filter: brightness(1.5);
  }
`;

export { PaginationBar as default, Pagination, PaginationGroup, PaginationOption, PaginationIcon };
