import React from 'react';

import { Box } from 'styled';

interface Content {
  title: string;
  description: string;
}

export interface Props {
  content: Content;
}

const TourContent: React.FC<Props> = ({ content }: Props) => {
  return (
    <Box textAlign="start" ml="-10px">
      <Box fontSize="s" color="neutralsGradient.10">
        {content?.description}
      </Box>
    </Box>
  );
};

export default TourContent;
