import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';

import { WAIT_TIMES_COLORS } from 'config/chartColors';

import { formatTime } from 'utils';

import { getDefaultSeriesOptions, getVisibility } from 'statistics/charts/utils';
import { ChartDatum } from 'statistics/types';

export default function getSeries(
  data: ChartDatum[],
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType,
  index: number
): SeriesOptionsType {
  return merge(getDefaultSeriesOptions(), {
    name: data.length > 0 ? data[0].name : '',
    yAxis: 'wait-times',
    data,
    tooltip: {
      pointFormatter(): string {
        let { y } = this;
        const { series } = this;
        y = Math.ceil(y || 0);
        return `${series?.name}: <b>${formatTime(y)}</b><br/>`;
      },
    },
    color: WAIT_TIMES_COLORS[index],
    fillColor: WAIT_TIMES_COLORS[index],
    ...chartOptions,
    type: 'line',
    visible: getVisibility('Wait Times'),
  } as SeriesOptionsType);
}
