import React from 'react';

import { SortByOptions } from 'components';

const RemoteParkingOptions: React.FC = () => {
  return (
    <>
      <SortByOptions
        stateKey="remoteParking"
        options={[
          { value: 'spotName', label: 'Spot' },
          { value: 'name', label: 'Name' },
          { value: 'start', label: 'Start' },
          { value: 'end', label: 'End' },
        ]}
      />
    </>
  );
};

export default RemoteParkingOptions;
