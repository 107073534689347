import React from 'react';

import { Box, Loader as StyledLoader, LoaderContainer, LoaderLabel } from 'styled';

interface Props {
  color?: string;
  label?: string;
}

const defaults: Props = {
  color: 'primary',
};

export const Loader: React.FC<Props> = ({ color, label }: Props) => {
  return (
    <LoaderContainer>
      <StyledLoader color={color ?? defaults.color} mx="auto" />
      {label ? <LoaderLabel>{label}</LoaderLabel> : null}
    </LoaderContainer>
  );
};

const CenteredLoader: React.FC<Props> = ({ color, label }: Props) => (
  <Box alignItems="center" display="flex" justifyContent="center" height="100vh">
    <Loader color={color ?? defaults.color} label={label} />
  </Box>
);

export { Loader as default, CenteredLoader };
