import React from 'react';

import { Notam } from 'tiles/Airfield/utils';

import { Tooltip } from 'components';

import { TextLabel } from 'styled';

import getStatusText from '../utils/getStatusText';
interface NotamStatusProps {
  notam: Notam;
}

const NotamStatus: React.FC<NotamStatusProps> = ({ notam }: NotamStatusProps) => {
  if (process.env.REACT_APP_CONFIG_NOTAMS !== 'true') return null;

  const status = getStatusText(notam.description);
  const description = notam.translation;
  const color = status === 'N/A' ? 'subdued' : notam.color;
  const hasDescription = description !== '';

  if (hasDescription) {
    return (
      <Tooltip label={description}>
        <TextLabel color={color}>{status}</TextLabel>
      </Tooltip>
    );
  }

  return <TextLabel color={color}>{status}</TextLabel>;
};

export default NotamStatus;
