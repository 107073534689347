import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Box = styled.div<SystemProps>`
  ${system}
`;

export default Box;
