import { isPast } from 'date-fns';

import { COMPLETED } from 'data/Flights/constants';

import CargoData, { Cargo } from './CargoData';

class CargoDeparturesData extends CargoData {
  get completedFlights(): Cargo[] {
    return this.flights.filter((flight: Cargo) => {
      const scheduledDeparture = new Date(flight.scheduledDepartureDate);
      const canceled = flight.cancelled;
      return (
        flight.statusText === COMPLETED ||
        (isPast(scheduledDeparture) && !canceled && !flight.departureDelay)
      );
    });
  }
}

export default CargoDeparturesData;
