import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

type TableRowProps = SystemProps & {
  grid?: string;
  numberOfColumns: number;
  gridGap?: string;
  clickable?: boolean;
};

const Table = styled.div<SystemProps>`
  text-align: left;
  width: 100%;

  ${system}
`;

const TableHead = styled.div<{ bordered?: boolean }>`
  border-bottom: 1px solid ${themeGet('colors.borderSolid')};

  ${props =>
    props.bordered &&
    css`
      border-top: 1px solid ${themeGet('colors.borderSolid')};
    `};
`;

const TableBody = styled.div<{ striped?: boolean; bordered?: boolean }>`
  ${props =>
    props.striped &&
    css`
      > div:nth-child(2n) {
        background-color: ${themeGet('colors.neutralsGradient.10')};
      }
    `};

  ${props =>
    props.bordered &&
    css`
      > div {
        border-bottom: 1px solid ${themeGet('colors.borderSolid')};
      }
    `};
`;

const TableRow = styled.div<TableRowProps>`
  align-items: center;
  display: grid;
  grid-template-columns: ${props => props.grid || `repeat(${props.numberOfColumns}, 1fr)`};
  position: relative;

  ${props =>
    props.clickable &&
    css`
      cursor: pointer;

      &:active,
      &:focus,
      &:hover {
        background-color: ${themeGet('colors.borderSolid')};
      }
    `}

  ${system}
`;

const TableHeading = styled.div<SystemProps>`
  color: ${themeGet('colors.neutralsGradient.5')};
  cursor: pointer;
  font-size: ${themeGet('fontSizes.xs')};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;

  ${system}
`;

const TableData = styled.div<SystemProps>`
  ${system}
`;

export { Table as default, TableHead, TableBody, TableRow, TableHeading, TableData };
