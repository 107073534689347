import maxBy from 'lodash/maxBy';
import stringSimilarity from 'string-similarity';

import { ArrivalFlight } from 'data/Flights/types';

export interface AircraftWeight {
  airline: string;
  iata: string;
  icao: string;
  agreementNumber: string;
  makeModel: string;
  manufacturer: string;
  weight: number;
  capacity: number;
  parkingGroup: string;
  type: string;
  valid: string;
  sortOrder: number;
}

const aircraft: AircraftWeight[] = [
  {
    airline: 'ABX Air Inc. - Amazon',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI003',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air Inc. - Amazon',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI003',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air Inc. - Amazon',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI003',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air Inc. - DHL',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air Inc. - DHL',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air Inc. - DHL',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air Inc. - DHL',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI002',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air Inc. - DHL',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI002',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air Inc. - DHL',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air, Inc',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air, Inc',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air, Inc',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI001',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air, Inc',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI001',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air, Inc',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'ABX Air, Inc',
    iata: 'GB',
    icao: 'ABX',
    agreementNumber: 'ABXAI001',
    makeModel: 'ABX DC8-61-0',
    manufacturer: 'Boeing',
    weight: 240_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: '',
    sortOrder: 20,
  },
  {
    airline: 'AeroLogic -DHL Carrier',
    iata: '3S',
    icao: 'BOX',
    agreementNumber: 'AEROL001',
    makeModel: '777-200-0',
    manufacturer: 'Boeing',
    weight: 460_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'AeroLogic -DHL Carrier',
    iata: '3S',
    icao: 'BOX',
    agreementNumber: 'AEROL001',
    makeModel: '777-FS',
    manufacturer: 'Boeing',
    weight: 575_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'AeroLogic -DHL Carrier',
    iata: '3S',
    icao: 'BOX',
    agreementNumber: 'AEROL001',
    makeModel: 'B777-200-0',
    manufacturer: 'Boeing',
    weight: 460_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Aerotransporte de Carga Union - DHL',
    iata: '6R',
    icao: 'TNO',
    agreementNumber: 'AEROT001',
    makeModel: 'A321-5',
    manufacturer: 'Airbus',
    weight: 171_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Aerotransporte de Carga Union - DHL',
    iata: '6R',
    icao: 'TNO',
    agreementNumber: 'AEROT001',
    makeModel: 'AIRBUS 300 A-0',
    manufacturer: 'Airbus',
    weight: 295_420,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Aerotransporte de Carga Union - DHL',
    iata: '6R',
    icao: 'TNO',
    agreementNumber: 'AEROT001',
    makeModel: 'A321-5',
    manufacturer: 'Airbus',
    weight: 171_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Aerotransporte de Carga Union - DHL',
    iata: '6R',
    icao: 'TNO',
    agreementNumber: 'AEROT001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Aerovias de Mexico, S.A. de C.V.-Vacation Express',
    iata: 'AM',
    icao: 'AMX',
    agreementNumber: 'AEROV001',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 128_000,
    capacity: 140,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Aerovias de Mexico, S.A. de C.V.-Vacation Express',
    iata: 'AM',
    icao: 'AMX',
    agreementNumber: 'AEROV001',
    makeModel: 'MD83-0',
    manufacturer: 'Boeing',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Aerovias de Mexico, S.A. de C.V.-Vacation Express',
    iata: 'AM',
    icao: 'AMX',
    agreementNumber: 'AEROV001',
    makeModel: 'MD 87',
    manufacturer: 'McDonnell Douglas',
    weight: 130_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Aerovias de Mexico, S.A. de C.V.-Vacation Express',
    iata: 'AM',
    icao: 'AMX',
    agreementNumber: 'AEROV001',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 119_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Aerovias de Mexico, S.A. de C.V.-Vacation Express',
    iata: 'AM',
    icao: 'AMX',
    agreementNumber: 'AEROV001',
    makeModel: 'MD 88-0',
    manufacturer: 'Boeing',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Aerovias de Mexico, S.A. de C.V.-Vacation Express',
    iata: 'AM',
    icao: 'AMX',
    agreementNumber: 'AEROV002',
    makeModel: 'B737-700',
    manufacturer: 'Boeing',
    weight: 128_800,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Bridge Cargo-DHL',
    iata: 'RU',
    icao: 'ABW',
    agreementNumber: 'AIRBR001',
    makeModel: '747-400 9',
    manufacturer: 'Boeing',
    weight: 762_985,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 10,
  },
  {
    airline: 'Air Canada Regional',
    iata: 'AC',
    icao: 'ACA',
    agreementNumber: 'AIRCA001',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IMTNN',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Canada Regional',
    iata: 'AC',
    icao: 'ACA',
    agreementNumber: 'JAZZA001',
    makeModel: 'CRJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Canada Regional',
    iata: 'AC',
    icao: 'ACA',
    agreementNumber: 'JAZZA001',
    makeModel: 'DH8-0',
    manufacturer: 'De Havilland',
    weight: 33_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Canada Regional',
    iata: 'AC',
    icao: 'ACA',
    agreementNumber: 'JAZZA001',
    makeModel: 'DH3-0',
    manufacturer: 'De Havilland',
    weight: 40_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Canada Regional',
    iata: 'AC',
    icao: 'ACA',
    agreementNumber: 'JAZZA001',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Canada Regional',
    iata: 'AC',
    icao: 'ACA',
    agreementNumber: 'JAZZA001',
    makeModel: 'DH3-0',
    manufacturer: 'De Havilland',
    weight: 40_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Canada Regional',
    iata: 'AC',
    icao: 'ACA',
    agreementNumber: 'JAZZA001',
    makeModel: 'CRJ-700',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Canada Regional',
    iata: 'AC',
    icao: 'ACA',
    agreementNumber: 'JAZZA001',
    makeModel: 'CRJ-700',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Cargo Carriers - DHL Carrier',
    iata: '2Q',
    icao: 'SNC',
    agreementNumber: 'AIRCAR001',
    makeModel: 'SH360-0',
    manufacturer: 'Shorts',
    weight: 26_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Cargo Carriers - DHL Carrier',
    iata: '2Q',
    icao: 'SNC',
    agreementNumber: 'AIRCAR001',
    makeModel: 'SH330-0',
    manufacturer: 'Shorts',
    weight: 22_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Commerce - DHL',
    iata: '',
    icao: '',
    agreementNumber: 'AIRCO001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Commerce - DHL',
    iata: '',
    icao: '',
    agreementNumber: 'AIRCO001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: '',
    sortOrder: 10,
  },
  {
    airline: 'Air France',
    iata: 'AF',
    icao: 'AFR',
    agreementNumber: 'AIRFR001',
    makeModel: 'A340--0',
    manufacturer: 'Airbus',
    weight: 418_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air France',
    iata: 'AF',
    icao: 'AFR',
    agreementNumber: 'AIRFR001',
    makeModel: 'A330-0',
    manufacturer: 'Airbus',
    weight: 414_465,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Georgian Limited',
    iata: 'ZX',
    icao: 'GGN',
    agreementNumber: 'AIRGE001',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: 'DC8-73F',
    manufacturer: 'McDonnell Douglas',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: 'DC-8-73F-0',
    manufacturer: 'Boeing',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: '',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: 'B767-300-0',
    manufacturer: 'Boeing',
    weight: 300_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: 'B767-300-0',
    manufacturer: 'Boeing',
    weight: 300_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 267_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: 'DC-863-0',
    manufacturer: 'McDonnell Douglas',
    weight: 258_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR001',
    makeModel: '757-200PF',
    manufacturer: 'Boeing',
    weight: 198_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 310_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 310_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: 'B757-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: 'DC8-73F',
    manufacturer: 'McDonnell Douglas',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: 'DC8-73F',
    manufacturer: 'McDonnell Douglas',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: 'B757-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int - DHL',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int-Amazon',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR003',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int-Amazon',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR003',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int-Amazon',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR003',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int-Amazon',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR003',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Transport Int-Amazon',
    iata: '8C',
    icao: 'ATN',
    agreementNumber: 'AIRTR003',
    makeModel: 'B767-223SF',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Wisconsin Airlines Corp.',
    iata: 'ZW',
    icao: 'AWI',
    agreementNumber: 'AIRWI003',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Wisconsin Airlines Corp.-American Airlines',
    iata: 'ZW',
    icao: 'AWI',
    agreementNumber: 'AIRWI003',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Wisconsin Airlines Corp.-United Express',
    iata: 'ZW',
    icao: 'AWI',
    agreementNumber: 'AIRWI005',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Air Wisconsin Airlines Corp.-United Express',
    iata: 'ZW',
    icao: 'AWI',
    agreementNumber: 'AIRWI002',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 220,
  },
  {
    airline: 'Air Wisconsin Airlines Corp.-US Airways Express',
    iata: 'ZW',
    icao: 'AWI',
    agreementNumber: 'AIRWI003',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Aircraft Service International, Inc.',
    iata: '',
    icao: '',
    agreementNumber: 'AIRCR001',
    makeModel: '124-100',
    manufacturer: 'Antonov',
    weight: 864_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Aircraft Service International, Inc.',
    iata: '',
    icao: '',
    agreementNumber: 'AIRCR001',
    makeModel: '124-100',
    manufacturer: 'Antonov',
    weight: 864_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'AirNet II LLC',
    iata: '',
    icao: 'USC',
    agreementNumber: 'AIRNE001',
    makeModel: 'PA-31-350-0',
    manufacturer: 'Piper',
    weight: 7_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'AirNet II LLC',
    iata: '',
    icao: 'USC',
    agreementNumber: 'AIRNE001',
    makeModel: 'LR-35-0',
    manufacturer: 'Lear',
    weight: 16_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'AirNet II LLC',
    iata: '',
    icao: 'USC',
    agreementNumber: 'AIRNE001',
    makeModel: 'C210',
    manufacturer: 'Cessna',
    weight: 4_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'AirNet II LLC',
    iata: '',
    icao: 'USC',
    agreementNumber: 'AIRNE001',
    makeModel: 'C-208A-0',
    manufacturer: 'Cessna',
    weight: 7_800,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'AirNet II LLC',
    iata: '',
    icao: 'USC',
    agreementNumber: 'AIRNE001',
    makeModel: '208-B',
    manufacturer: 'Cessna',
    weight: 8_500,
    capacity: 1,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'AirNet II LLC',
    iata: '',
    icao: 'USC',
    agreementNumber: 'AIRNE001',
    makeModel: 'LR-35-0',
    manufacturer: 'Lear',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'AirNet II LLC',
    iata: '',
    icao: 'USC',
    agreementNumber: 'AIRNE001',
    makeModel: 'BE-58',
    manufacturer: 'Beechcraft',
    weight: 5_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI006',
    makeModel: 'MD-88',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI006',
    makeModel: 'MD-83',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI006',
    makeModel: 'A319-100-0',
    manufacturer: 'Airbus',
    weight: 134_481,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI002',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI006',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI006',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI006',
    makeModel: 'A320-5',
    manufacturer: 'Airbus',
    weight: 145_505,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI006',
    makeModel: 'A319-4',
    manufacturer: 'Airbus',
    weight: 137_788,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI006',
    makeModel: '737-800-0',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI002',
    makeModel: 'MD-88',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI002',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI002',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI002',
    makeModel: 'MD-83',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI002',
    makeModel: 'A319-100-0',
    manufacturer: 'Airbus',
    weight: 134_481,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI002',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air, Inc-Charter',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air, Inc-Charter',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI001',
    makeModel: 'MD-80-87',
    manufacturer: 'McDonnell Douglas',
    weight: 128_000,
    capacity: 128,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air, Inc-Charter',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI001',
    makeModel: 'MD-83',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air, Inc-Charter',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI001',
    makeModel: 'DC9-82-0',
    manufacturer: 'Boeing',
    weight: 130_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air, Inc-Charter',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI001',
    makeModel: 'A320-6',
    manufacturer: 'Airbus',
    weight: 145_505,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air, Inc-Charter',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI001',
    makeModel: 'A320-6',
    manufacturer: 'Airbus',
    weight: 145_505,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air, Inc-Charter',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI001',
    makeModel: 'A-319-131B-0',
    manufacturer: 'Airbus',
    weight: 134_481,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air, Inc-Charter',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Allegiant Air, Inc-Charter',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLEGI001',
    makeModel: 'A-319-131B-0',
    manufacturer: 'Airbus',
    weight: 134_481,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Alliance Air Charter-DHL',
    iata: 'G4',
    icao: 'AAY',
    agreementNumber: 'ALLIA001',
    makeModel: 'SH330-0',
    manufacturer: 'Shorts',
    weight: 22_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: 'MD-80-0',
    manufacturer: 'McDonnell Douglas',
    weight: 130_000,
    capacity: 128,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: '757',
    manufacturer: 'Boeing',
    weight: 167_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: 'MD80',
    manufacturer: 'McDonnell Douglas',
    weight: 122_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 310_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: '737-800',
    manufacturer: 'Boeing',
    weight: 144_000,
    capacity: 154,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: 'A319-2',
    manufacturer: 'Airbus',
    weight: 135_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: 'MD-83 2',
    manufacturer: 'McDonnell Douglas',
    weight: 130_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: 'MD-83L',
    manufacturer: 'McDonnell Douglas',
    weight: 123_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: '737-800 3',
    manufacturer: 'Boeing',
    weight: 138_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: 'A320-3',
    manufacturer: 'Airbus',
    weight: 139_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: '737-Adj 2',
    manufacturer: 'Boeing',
    weight: 138_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: '737-4',
    manufacturer: 'Boeing',
    weight: 138_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: 'A319-4',
    manufacturer: 'Airbus',
    weight: 137_788,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: 'MD-80-0',
    manufacturer: 'McDonnell Douglas',
    weight: 130_000,
    capacity: 128,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: 'MD-80L',
    manufacturer: 'McDonnell Douglas',
    weight: 123_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: 'A330-200',
    manufacturer: 'Airbus',
    weight: 397_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI003',
    makeModel: 'ERJ-190',
    manufacturer: 'Embraer',
    weight: 94_800,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI003',
    makeModel: 'A320-3',
    manufacturer: 'Airbus',
    weight: 139_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: 'A320-1-0',
    manufacturer: 'Airbus',
    weight: 142_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: 'A321-0',
    manufacturer: 'Airbus',
    weight: 171_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: '777-200-0',
    manufacturer: 'Boeing',
    weight: 460_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI005',
    makeModel: 'A321-4',
    manufacturer: 'Airbus',
    weight: 172_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: 'A-320-2',
    manufacturer: 'Airbus',
    weight: 137_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: '767-R',
    manufacturer: 'Boeing',
    weight: 227_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: 'A320',
    manufacturer: 'Airbus',
    weight: 174_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: '777-AA',
    manufacturer: 'Boeing',
    weight: 580_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: '757',
    manufacturer: 'Boeing',
    weight: 167_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Airlines, Inc.',
    iata: 'AA',
    icao: 'AAL',
    agreementNumber: 'AMERI001',
    makeModel: 'A321-0',
    manufacturer: 'Airbus',
    weight: 171_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'American Trans Air (ATA)',
    iata: 'TZ',
    icao: 'AMT',
    agreementNumber: 'AMERIT001',
    makeModel: 'B757-200',
    manufacturer: 'Boeing',
    weight: 127_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ameriflight, Inc.',
    iata: 'A8',
    icao: 'AMF',
    agreementNumber: 'AMERIF002',
    makeModel: 'BE-99',
    manufacturer: 'Beechcraft',
    weight: 11_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'Ameriflight, Inc.',
    iata: 'A8',
    icao: 'AMF',
    agreementNumber: 'AMERIF002',
    makeModel: '227-AT',
    manufacturer: 'SAAB',
    weight: 15_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 20,
  },
  {
    airline: 'Ameriflight, Inc.',
    iata: 'A8',
    icao: 'AMF',
    agreementNumber: 'AMERIF002',
    makeModel: 'SA227-AT',
    manufacturer: 'SAAB',
    weight: 14_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 30,
  },
  {
    airline: 'Ameriflight, Inc.',
    iata: 'A8',
    icao: 'AMF',
    agreementNumber: 'AMERIF002',
    makeModel: 'B1900-0',
    manufacturer: 'Beechcraft',
    weight: 16_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 40,
  },
  {
    airline: 'Ameriflight, Inc.',
    iata: 'A8',
    icao: 'AMF',
    agreementNumber: 'AMERIF002',
    makeModel: '120ER-0',
    manufacturer: 'Embraer',
    weight: 25_794,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 50,
  },
  {
    airline: 'Ameriflight, Inc. - DHL',
    iata: 'A8',
    icao: 'AMF',
    agreementNumber: 'AMERIF003',
    makeModel: '120ER-0',
    manufacturer: 'Embraer',
    weight: 25_794,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ameriflight, Inc. - DHL',
    iata: 'A8',
    icao: 'AMF',
    agreementNumber: 'AMERIF003',
    makeModel: 'BE-99',
    manufacturer: 'Beechcraft',
    weight: 10_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ameriflight, Inc. - DHL',
    iata: 'A8',
    icao: 'AMF',
    agreementNumber: 'AMERIF003',
    makeModel: '120ER-0',
    manufacturer: 'Embraer',
    weight: 25_794,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ameriflight, Inc. - DHL',
    iata: 'A8',
    icao: 'AMF',
    agreementNumber: 'AMERIF003',
    makeModel: 'SA227-AT',
    manufacturer: 'SAAB',
    weight: 14_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ameriflight, Inc. - DHL',
    iata: 'A8',
    icao: 'AMF',
    agreementNumber: 'AMERIF003',
    makeModel: '1900-D',
    manufacturer: 'Beechcraft',
    weight: 16_100,
    capacity: 19,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ameriflight, Inc. - DHL',
    iata: 'A8',
    icao: 'AMF',
    agreementNumber: 'AMERIF003',
    makeModel: 'SA227-AT',
    manufacturer: 'SAAB',
    weight: 14_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ameristar Jet Charter-DHL',
    iata: '7Z',
    icao: 'AJI',
    agreementNumber: 'AMERIS001',
    makeModel: 'Falcon-20',
    manufacturer: 'Falcon',
    weight: 6_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ASTAR - DHL',
    iata: 'ER',
    icao: 'DHL',
    agreementNumber: 'ASTAR001',
    makeModel: 'DC8-73F',
    manufacturer: 'McDonnell Douglas',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ASTAR - DHL',
    iata: 'ER',
    icao: 'DHL',
    agreementNumber: 'ASTAR001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ASTAR - DHL',
    iata: 'ER',
    icao: 'DHL',
    agreementNumber: 'ASTAR001',
    makeModel: 'AIRBUS 300 A-0',
    manufacturer: 'Airbus',
    weight: 295_420,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ASTAR - DHL',
    iata: 'ER',
    icao: 'DHL',
    agreementNumber: 'ASTAR001',
    makeModel: 'DC-8-73F-0',
    manufacturer: 'Boeing',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-50-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-700-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-700-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-50-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CR 4-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-200ER-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT002',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT004',
    makeModel: 'EMB-145LR',
    manufacturer: 'Embraer',
    weight: 43_651,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT004',
    makeModel: 'EMB-145-XR',
    manufacturer: 'Embraer',
    weight: 44_092,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines-CO',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT004',
    makeModel: 'EMB-145LR',
    manufacturer: 'Embraer',
    weight: 43_651,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines-CO',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT004',
    makeModel: 'EMB-145-XR',
    manufacturer: 'Embraer',
    weight: 44_092,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines-DC',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-50-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines-DC',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-700-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines-DC',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines-DC',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines-DC',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-700-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines-DC',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-50-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines-DC',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CR 4-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines-DC',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT001',
    makeModel: 'CRJ-200ER-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlantic Southeast Airlines-UE',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'ATLANT002',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlas Air Cargo-DHL',
    iata: '5Y',
    icao: 'GTI',
    agreementNumber: 'ATLAS002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlas Air Cargo-DHL',
    iata: '5Y',
    icao: 'GTI',
    agreementNumber: 'ATLAS002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlas Air Cargo-DHL',
    iata: '5Y',
    icao: 'GTI',
    agreementNumber: 'ATLAS002',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlas Air Cargo-DHL',
    iata: '5Y',
    icao: 'GTI',
    agreementNumber: 'ATLAS002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlas Air Cargo-DHL',
    iata: '5Y',
    icao: 'GTI',
    agreementNumber: 'ATLAS002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlas Air Cargo-DHL',
    iata: '5Y',
    icao: 'GTI',
    agreementNumber: 'ATLAS002',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlas Air, Inc.',
    iata: '5Y',
    icao: 'GTI',
    agreementNumber: 'ATLAS001',
    makeModel: '747-400 11',
    manufacturer: 'Boeing',
    weight: 660_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlas Air, Inc.',
    iata: '5Y',
    icao: 'GTI',
    agreementNumber: 'ATLAS001',
    makeModel: 'B767-300-0',
    manufacturer: 'Boeing',
    weight: 295_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlas Air-Amazon',
    iata: '5Y',
    icao: 'GTI',
    agreementNumber: 'ATLAS003',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlas Air-Amazon',
    iata: '5Y',
    icao: 'GTI',
    agreementNumber: 'ATLAS003',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Atlas Air-Amazon',
    iata: '5Y',
    icao: 'GTI',
    agreementNumber: 'ATLAS003',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ATPAC',
    iata: '',
    icao: '',
    agreementNumber: 'ATPAC001',
    makeModel: '727-200',
    manufacturer: 'Boeing',
    weight: 161_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'Aviation Advantage',
    iata: '',
    icao: '',
    agreementNumber: 'AVIAT001',
    makeModel: '737-200-0',
    manufacturer: 'Boeing',
    weight: 107_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Aviation Advantage',
    iata: '',
    icao: '',
    agreementNumber: 'AVIAT001',
    makeModel: '737-300-0',
    manufacturer: 'Boeing',
    weight: 116_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Aviation Advantage',
    iata: '',
    icao: '',
    agreementNumber: 'AVIAT001',
    makeModel: '737-300-0',
    manufacturer: 'Boeing',
    weight: 116_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Aviation Advantage',
    iata: '',
    icao: '',
    agreementNumber: 'AVIAT001',
    makeModel: '737-300-0',
    manufacturer: 'Boeing',
    weight: 116_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Aviation Advantage',
    iata: '',
    icao: '',
    agreementNumber: 'AVIAT001',
    makeModel: '737-300-0',
    manufacturer: 'Boeing',
    weight: 116_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Aviation Advantage',
    iata: '',
    icao: '',
    agreementNumber: 'AVIAT001',
    makeModel: 'B737-300-0',
    manufacturer: 'Boeing',
    weight: 116_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Aviation Advantage',
    iata: '',
    icao: '',
    agreementNumber: 'AVIAT001',
    makeModel: 'B-737-200-0',
    manufacturer: 'Boeing',
    weight: 107_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Aviation Advantage',
    iata: '',
    icao: '',
    agreementNumber: 'AVIAT001',
    makeModel: '737-400-0',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Bahamasair Holdings, Limited',
    iata: 'UP',
    icao: 'BHS',
    agreementNumber: 'BAHAMA001',
    makeModel: '737-400',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'BankAir-DHL',
    iata: 'B4',
    icao: 'BKA',
    agreementNumber: 'BANKA001',
    makeModel: 'MU-2-0',
    manufacturer: 'Mitsubishi',
    weight: 11_025,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Berry Aviation-DHL',
    iata: '',
    icao: 'BYA',
    agreementNumber: 'BERRY001',
    makeModel: 'SA Merlin-4',
    manufacturer: 'Swearingen Fairchild',
    weight: 14_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Big Sky Airline',
    iata: 'GQ',
    icao: 'BSY',
    agreementNumber: 'BIGSKY001',
    makeModel: 'BE 1900-D',
    manufacturer: 'Beechcraft',
    weight: 17_120,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Boeing Commerical Airplane Group',
    iata: '',
    icao: 'BOE',
    agreementNumber: 'BOEIN001',
    makeModel: '777-222A',
    manufacturer: 'Boeing',
    weight: 445_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Boeing Commerical Airplane Group',
    iata: '',
    icao: 'BOE',
    agreementNumber: 'BOEIN001',
    makeModel: '747-8F',
    manufacturer: 'Boeing',
    weight: 757_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Brendan Airways dba USA3000 Airlines',
    iata: 'U5',
    icao: 'GWY',
    agreementNumber: 'BREND001',
    makeModel: 'A320-0',
    manufacturer: 'Airbus',
    weight: 149_195,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Brendan Airways dba USA3000 Airlines',
    iata: 'U5',
    icao: 'GWY',
    agreementNumber: 'BREND001',
    makeModel: 'A320-0',
    manufacturer: 'Airbus',
    weight: 149_195,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Brendan Airways dba USA3000 Airlines',
    iata: 'U5',
    icao: 'GWY',
    agreementNumber: 'BREND001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Brendan Airways dba USA3000 Airlines',
    iata: 'U5',
    icao: 'GWY',
    agreementNumber: 'BREND001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Capital Cargo International, Inc.',
    iata: '',
    icao: 'CCI',
    agreementNumber: 'CAPIT004',
    makeModel: '727-0',
    manufacturer: 'Boeing',
    weight: 161_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Capital Cargo International, Inc.',
    iata: '',
    icao: 'CCI',
    agreementNumber: 'CAPIT004',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Capital Cargo International, Inc.',
    iata: '',
    icao: 'CCI',
    agreementNumber: 'CAPIT004',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Capital Cargo Intl Airlines, Inc.',
    iata: '',
    icao: 'CCI',
    agreementNumber: 'CAPIT002',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Capital Cargo Intl Airlines, Inc. - DHL Carrier',
    iata: '',
    icao: 'CCI',
    agreementNumber: 'CAPIT001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Capital Cargo Intl Airlines, Inc. - DHL Carrier',
    iata: '',
    icao: 'CCI',
    agreementNumber: 'CAPIT001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Capital Cargo Intl Airlines, Inc. - DHL Carrier',
    iata: '',
    icao: 'CCI',
    agreementNumber: 'CAPIT001',
    makeModel: 'DC8-73F',
    manufacturer: 'McDonnell Douglas',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Capital Cargo Intl Airlines, Inc. - DHL Carrier',
    iata: '',
    icao: 'CCI',
    agreementNumber: 'CAPIT001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Capital Cargo Intl Airlines, Inc. - DHL Carrier',
    iata: '',
    icao: 'CCI',
    agreementNumber: 'CAPIT001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Capital Cargo Intl Airlines, Inc. - DHL Carrier',
    iata: '',
    icao: 'CCI',
    agreementNumber: 'CAPIT001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Capital Cargo Intl Airlines, Inc. - DHL Carrier',
    iata: '',
    icao: 'CCI',
    agreementNumber: 'CAPIT001',
    makeModel: '727-0',
    manufacturer: 'Boeing',
    weight: 161_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Cargolux',
    iata: 'CV',
    icao: 'CLX',
    agreementNumber: 'CARGL001',
    makeModel: 'B747-8',
    manufacturer: 'Boeing',
    weight: 722_272,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Cargolux',
    iata: 'CV',
    icao: 'CLX',
    agreementNumber: 'CARGL001',
    makeModel: '747-400 10',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Cargolux',
    iata: 'CV',
    icao: 'CLX',
    agreementNumber: 'CARGL001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Cargolux',
    iata: 'CV',
    icao: 'CLX',
    agreementNumber: 'CARGL001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Cargolux',
    iata: 'CV',
    icao: 'CLX',
    agreementNumber: 'CARGL001',
    makeModel: 'B747-400 6',
    manufacturer: 'Boeing',
    weight: 635_042,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Cargolux',
    iata: 'CV',
    icao: 'CLX',
    agreementNumber: 'CARGL001',
    makeModel: 'B747-400 7',
    manufacturer: 'Boeing',
    weight: 665_999,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CargoJet Airways-DHL Carrier',
    iata: 'W8',
    icao: 'CJT',
    agreementNumber: 'CARGO001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CargoJet Airways-DHL Carrier',
    iata: 'W8',
    icao: 'CJT',
    agreementNumber: 'CARGO001',
    makeModel: '727-0',
    manufacturer: 'Boeing',
    weight: 161_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CargoJet Airways-DHL Carrier',
    iata: 'W8',
    icao: 'CJT',
    agreementNumber: 'CARGO001',
    makeModel: '727-0',
    manufacturer: 'Boeing',
    weight: 161_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CargoJet Airways-DHL Carrier',
    iata: 'W8',
    icao: 'CJT',
    agreementNumber: 'CARGO001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CargoJet Airways-DHL Carrier',
    iata: 'W8',
    icao: 'CJT',
    agreementNumber: 'CARGO001',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CargoJet Airways-DHL Carrier',
    iata: 'W8',
    icao: 'CJT',
    agreementNumber: 'CARGO001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CargoJet Airways-DHL Carrier',
    iata: 'W8',
    icao: 'CJT',
    agreementNumber: 'CARGO001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CargoJet Airways-DHL Carrier',
    iata: 'W8',
    icao: 'CJT',
    agreementNumber: 'CARGO001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CargoJet Airways-DHL Carrier',
    iata: 'W8',
    icao: 'CJT',
    agreementNumber: 'CARGO001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CargoJet Airways-DHL Carrier',
    iata: 'W8',
    icao: 'CJT',
    agreementNumber: 'CARGO001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Castle Aviation, Inc.',
    iata: '',
    icao: 'CSJ',
    agreementNumber: 'CASTL001',
    makeModel: 'CE-208A-0',
    manufacturer: 'Cessna',
    weight: 8_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Castle Aviation, Inc.',
    iata: '',
    icao: 'CSJ',
    agreementNumber: 'CASTL001',
    makeModel: 'PA-60-600-0',
    manufacturer: 'Piper',
    weight: 5_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Castle Aviation, Inc.',
    iata: '',
    icao: 'CSJ',
    agreementNumber: 'CASTL001',
    makeModel: 'PA-60-600-0',
    manufacturer: 'Piper',
    weight: 6_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Castle Aviation, Inc.',
    iata: '',
    icao: 'CSJ',
    agreementNumber: 'CASTL001',
    makeModel: 'PA -60-600',
    manufacturer: 'Piper',
    weight: 8_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Castle Aviation, Inc.',
    iata: '',
    icao: 'CSJ',
    agreementNumber: 'CASTL001',
    makeModel: 'CE208A',
    manufacturer: 'Cessna',
    weight: 8_750,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Castle Aviation, Inc.',
    iata: '',
    icao: 'CSJ',
    agreementNumber: 'CASTL001',
    makeModel: 'CE 525 B',
    manufacturer: 'Cessna',
    weight: 9_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Castle Aviation, Inc. - DHL',
    iata: '',
    icao: 'CSJ',
    agreementNumber: 'CASTL002',
    makeModel: 'LR-35A-0',
    manufacturer: 'Lear',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Castle Aviation, Inc. - DHL',
    iata: '',
    icao: 'CSJ',
    agreementNumber: 'CASTL002',
    makeModel: 'CNC-1',
    manufacturer: 'Cessna',
    weight: 8_497,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Castle Aviation, Inc. - DHL',
    iata: '',
    icao: 'CSJ',
    agreementNumber: 'CASTL002',
    makeModel: 'LR-35-0',
    manufacturer: 'Lear',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Castle Aviation, Inc. - DHL',
    iata: '',
    icao: 'CSJ',
    agreementNumber: 'CASTL002',
    makeModel: 'PA-60-600-0',
    manufacturer: 'Piper',
    weight: 5_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Cathay Pacific Ltd',
    iata: 'CX',
    icao: 'CPA',
    agreementNumber: 'CATHA001',
    makeModel: '747-400F',
    manufacturer: 'Boeing',
    weight: 665_992,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Cayman Airways, LTD',
    iata: 'KX',
    icao: 'CAY',
    agreementNumber: 'CAYMA001',
    makeModel: '737-2',
    manufacturer: 'Boeing',
    weight: 112_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Champion Air',
    iata: 'MG',
    icao: 'CCP',
    agreementNumber: 'CHAMPI001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 160_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Champion Air',
    iata: 'MG',
    icao: 'CCP',
    agreementNumber: 'CHAMPI001',
    makeModel: '727-0',
    manufacturer: 'Boeing',
    weight: 160_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Champion Air',
    iata: 'MG',
    icao: 'CCP',
    agreementNumber: 'CHAMPI001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 158_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT004',
    makeModel: 'EMB 135-0',
    manufacturer: 'Embraer',
    weight: 40_785,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT004',
    makeModel: 'EMB-145-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT004',
    makeModel: 'EMB-145-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT006',
    makeModel: 'ERJ-145ER',
    manufacturer: 'Embraer',
    weight: 41_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT006',
    makeModel: 'ERJ-135',
    manufacturer: 'Embraer',
    weight: 40_785,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT006',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT003',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT003',
    makeModel: 'RJ',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT003',
    makeModel: 'ERJ 2-0',
    manufacturer: 'Embraer',
    weight: 42_550,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT004',
    makeModel: 'CRJ-200ER-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc. AA',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT007',
    makeModel: 'EMB-145-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc. AA',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT007',
    makeModel: 'EMB-140-0',
    manufacturer: 'Embraer',
    weight: 41_226,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.-Continental Express',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT006',
    makeModel: 'ERJ-145ER',
    manufacturer: 'Embraer',
    weight: 41_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.-Continental Express',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT006',
    makeModel: 'ERJ-135',
    manufacturer: 'Embraer',
    weight: 40_785,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.-Continental Express',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT006',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.-Delta Connection',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT004',
    makeModel: 'CRJ-200ER-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.-Delta Connection',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT004',
    makeModel: 'EMB 135-0',
    manufacturer: 'Embraer',
    weight: 40_785,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.-Delta Connection',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT004',
    makeModel: 'EMB-145-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.-Delta Connection',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT004',
    makeModel: 'EMB-145-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.-US Air Express',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT003',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.-US Air Express',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT003',
    makeModel: 'RJ',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Chautauqua Airlines, Inc.-US Air Express',
    iata: 'RP',
    icao: 'CHQ',
    agreementNumber: 'CHAUT003',
    makeModel: 'ERJ 2-0',
    manufacturer: 'Embraer',
    weight: 42_550,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Comair, Inc. - Delta Connection',
    iata: 'OH',
    icao: 'COM',
    agreementNumber: 'COMAI001',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Comair, Inc. - Delta Connection',
    iata: 'OH',
    icao: 'COM',
    agreementNumber: 'COMAI001',
    makeModel: 'CR9-0',
    manufacturer: 'Bombardier',
    weight: 75_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Comair, Inc. - Delta Connection',
    iata: 'OH',
    icao: 'COM',
    agreementNumber: 'COMAI001',
    makeModel: 'CR9-0',
    manufacturer: 'Bombardier',
    weight: 75_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Comair, Inc. - Delta Connection',
    iata: 'OH',
    icao: 'COM',
    agreementNumber: 'COMAI001',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Comair, Inc. - Delta Connection',
    iata: 'OH',
    icao: 'COM',
    agreementNumber: 'COMAI001',
    makeModel: 'CRJ7-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Comair, Inc. - Delta Connection',
    iata: 'OH',
    icao: 'COM',
    agreementNumber: 'COMAI001',
    makeModel: 'CRJ7-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Comair, Inc. - Delta Connection',
    iata: 'OH',
    icao: 'COM',
    agreementNumber: 'COMAI001',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Comair, Inc. - Delta Connection',
    iata: 'OH',
    icao: 'COM',
    agreementNumber: 'COMAI001',
    makeModel: 'CR 4-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Comair, Inc. - Delta Connection',
    iata: 'OH',
    icao: 'COM',
    agreementNumber: 'COMAI001',
    makeModel: 'CR 4-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CommutAir-Continental Connection',
    iata: 'C5',
    icao: 'UCA',
    agreementNumber: 'CHAMP001',
    makeModel: 'DASH 8-0',
    manufacturer: 'De Havilland',
    weight: 34_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CommutAir-Continental Connection',
    iata: 'C5',
    icao: 'UCA',
    agreementNumber: 'CHAMP001',
    makeModel: '1900-D',
    manufacturer: 'Beechcraft',
    weight: 16_600,
    capacity: 19,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CommutAir-Continental Connection',
    iata: 'C5',
    icao: 'UCA',
    agreementNumber: 'CHAMP001',
    makeModel: 'Dash 8-300-0',
    manufacturer: 'De Havilland',
    weight: 42_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CommutAir-United',
    iata: 'C5',
    icao: 'UCA',
    agreementNumber: 'COMMUT001',
    makeModel: 'ERJ-145XR',
    manufacturer: 'Embraer',
    weight: 44_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'CommutAir-United',
    iata: 'C5',
    icao: 'UCA',
    agreementNumber: 'COMMUT001',
    makeModel: 'EMB-145 C',
    manufacturer: 'Embraer',
    weight: 44_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'Compass Airline-Delta',
    iata: 'CP',
    icao: 'CPZ',
    agreementNumber: 'COMPA001',
    makeModel: 'EMB-75',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Compass Airline-Delta',
    iata: 'CP',
    icao: 'CPZ',
    agreementNumber: 'COMPA001',
    makeModel: 'EMB-75',
    manufacturer: 'Embraer',
    weight: 74_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Compass Airline-Delta',
    iata: 'CP',
    icao: 'CPZ',
    agreementNumber: 'COMPA001',
    makeModel: 'EMB-75',
    manufacturer: 'Embraer',
    weight: 74_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Compass Airline-Delta',
    iata: 'CP',
    icao: 'CPZ',
    agreementNumber: 'COMPA001',
    makeModel: 'EMB-70',
    manufacturer: 'Embraer',
    weight: 72_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Compass Airline-Delta',
    iata: 'CP',
    icao: 'CPZ',
    agreementNumber: 'COMPA001',
    makeModel: 'EC0',
    manufacturer: 'Embraer',
    weight: 72_311,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Compass Airline-Delta',
    iata: 'CP',
    icao: 'CPZ',
    agreementNumber: 'COMPA001',
    makeModel: 'EC5',
    manufacturer: 'Embraer',
    weight: 75_177,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI001',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 114_000,
    capacity: 134,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI001',
    makeModel: '737-900',
    manufacturer: 'Boeing',
    weight: 146_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI001',
    makeModel: '737-500',
    manufacturer: 'Boeing',
    weight: 110_000,
    capacity: 120,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI001',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI001',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 224_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI001',
    makeModel: '737-800-0',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'EXPRE002',
    makeModel: 'SRJ-135',
    manufacturer: 'Embraer',
    weight: 40_800,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'EXPRE002',
    makeModel: 'SRJ-135LR',
    manufacturer: 'Embraer',
    weight: 40_800,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'EXPRE002',
    makeModel: 'ERJ-145LR',
    manufacturer: 'Embraer',
    weight: 42_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'EXPRE002',
    makeModel: 'EMB-145-XR',
    manufacturer: 'Embraer',
    weight: 44_092,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'EXPRE002',
    makeModel: 'DASH 8-0',
    manufacturer: 'De Havilland',
    weight: 34_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'EXPRE002',
    makeModel: 'EMB-145LR',
    manufacturer: 'Embraer',
    weight: 43_651,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI002',
    makeModel: 'ERJ-145ER',
    manufacturer: 'Embraer',
    weight: 41_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI002',
    makeModel: 'SRJ-135',
    manufacturer: 'Embraer',
    weight: 40_800,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI002',
    makeModel: 'SRJ-135-LR-0',
    manufacturer: 'Embraer',
    weight: 40_800,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'EXPRE002',
    makeModel: 'ERJ-145XR',
    manufacturer: 'Embraer',
    weight: 44_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'EXPRE002',
    makeModel: 'ERJ-145ER',
    manufacturer: 'Embraer',
    weight: 41_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI001',
    makeModel: '737-800',
    manufacturer: 'Boeing',
    weight: 144_000,
    capacity: 154,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI001',
    makeModel: '767-400',
    manufacturer: 'Boeing',
    weight: 350_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI001',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 128_000,
    capacity: 140,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI001',
    makeModel: '737-900',
    manufacturer: 'Boeing',
    weight: 157_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI002',
    makeModel: 'ERJ-145XR',
    manufacturer: 'Embraer',
    weight: 44_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Continental Airlines, Inc.',
    iata: 'CO',
    icao: 'COA',
    agreementNumber: 'CONTI002',
    makeModel: 'ERJ-145-LR-0',
    manufacturer: 'Embraer',
    weight: 42_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'MD-88-0',
    manufacturer: 'Boeing',
    weight: 130_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'B767-300-0',
    manufacturer: 'Boeing',
    weight: 295_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'MD-88-0',
    manufacturer: 'Boeing',
    weight: 130_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: 'B-767-300-0',
    manufacturer: 'Boeing',
    weight: 295_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: 'A333',
    manufacturer: 'Airbus',
    weight: 412_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: 'A319-114-0',
    manufacturer: 'Airbus',
    weight: 134_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '737-900 3',
    manufacturer: 'Boeing',
    weight: 157_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: 'MD-90-0',
    manufacturer: 'McDonnell Douglas',
    weight: 142_000,
    capacity: 128,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: 'MD-83',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '717-0',
    manufacturer: 'Boeing',
    weight: 110_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '767-400',
    manufacturer: 'Boeing',
    weight: 350_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '747F A-0',
    manufacturer: 'Boeing',
    weight: 630_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '717-0',
    manufacturer: 'Boeing',
    weight: 171_519,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '737-900 3',
    manufacturer: 'Boeing',
    weight: 157_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 129_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: 'CS1',
    manufacturer: 'Bombardier',
    weight: 115_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: 'MD-83',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '757-300',
    manufacturer: 'Boeing',
    weight: 224_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'COMPA001',
    makeModel: 'EC0',
    manufacturer: 'Embraer',
    weight: 72_311,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'COMPA001',
    makeModel: 'EC5',
    manufacturer: 'Embraer',
    weight: 75_177,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: 'A350-900',
    manufacturer: 'Airbus',
    weight: 456_357,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: '767-400',
    manufacturer: 'Boeing',
    weight: 350_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: 'A333',
    manufacturer: 'Airbus',
    weight: 412_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA017',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'NORTH001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'COMPA001',
    makeModel: 'EMB-75',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'COMPA001',
    makeModel: 'EMB-75',
    manufacturer: 'Embraer',
    weight: 74_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'COMPA001',
    makeModel: 'EMB-75',
    manufacturer: 'Embraer',
    weight: 74_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'COMPA001',
    makeModel: 'EMB-70',
    manufacturer: 'Embraer',
    weight: 72_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'NORTH001',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 181_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'NORTH001',
    makeModel: 'DC-9',
    manufacturer: 'McDonnell Douglas',
    weight: 98_840,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'NORTH001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 126_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'NORTH001',
    makeModel: 'DC-9-31B',
    manufacturer: 'McDonnell Douglas',
    weight: 99_000,
    capacity: 1,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'NORTH001',
    makeModel: 'A330',
    manufacturer: 'Airbus',
    weight: 412_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'NORTH001',
    makeModel: 'A319-114-0',
    manufacturer: 'Airbus',
    weight: 134_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '717-0',
    manufacturer: 'Boeing',
    weight: 110_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '747F A-0',
    manufacturer: 'Boeing',
    weight: 630_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'NORTH001',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 224_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'NORTH001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'NORTH001',
    makeModel: 'A320',
    manufacturer: 'Airbus',
    weight: 141_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'A330',
    manufacturer: 'Airbus',
    weight: 412_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '737-W',
    manufacturer: 'Boeing',
    weight: 129_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'A319',
    manufacturer: 'Airbus',
    weight: 134_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'A320',
    manufacturer: 'Airbus',
    weight: 142_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'MD-88',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'A330',
    manufacturer: 'Airbus',
    weight: 412_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'A319',
    manufacturer: 'Airbus',
    weight: 134_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '747F A-0',
    manufacturer: 'Boeing',
    weight: 630_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '737-900 3',
    manufacturer: 'Boeing',
    weight: 157_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'B767-400-0',
    manufacturer: 'Boeing',
    weight: 350_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'MD-90-0',
    manufacturer: 'McDonnell Douglas',
    weight: 142_000,
    capacity: 128,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'A319-131-0',
    manufacturer: 'Airbus',
    weight: 134_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '737-800',
    manufacturer: 'Boeing',
    weight: 144_000,
    capacity: 154,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'D-93',
    manufacturer: 'McDonnell Douglas',
    weight: 100_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'A320',
    manufacturer: 'Airbus',
    weight: 142_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '757-300',
    manufacturer: 'Boeing',
    weight: 224_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'A330',
    manufacturer: 'Airbus',
    weight: 412_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'MD-88',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 129_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '737-200-0',
    manufacturer: 'Boeing',
    weight: 107_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'MD90-0',
    manufacturer: 'Boeing',
    weight: 142_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '737-800',
    manufacturer: 'Boeing',
    weight: 144_000,
    capacity: 154,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'DC9-50',
    manufacturer: 'McDonnell Douglas',
    weight: 110_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'D-94',
    manufacturer: 'McDonnell Douglas',
    weight: 102_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '737-800-0',
    manufacturer: 'Boeing',
    weight: 144_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: 'B767-400-0',
    manufacturer: 'Boeing',
    weight: 350_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '777-200-0',
    manufacturer: 'Boeing',
    weight: 460_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '737-200',
    manufacturer: 'Boeing',
    weight: 103_000,
    capacity: 134,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Air Lines, Inc.',
    iata: 'DL',
    icao: 'DAL',
    agreementNumber: 'DELTA001',
    makeModel: '777-200-0',
    manufacturer: 'Boeing',
    weight: 460_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'DC-9-30E',
    manufacturer: 'McDonnell Douglas',
    weight: 110_000,
    capacity: 110,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '737-200-0',
    manufacturer: 'Boeing',
    weight: 107_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'GE-BBJ-0',
    manufacturer: 'Boeing',
    weight: 107_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '727-0',
    manufacturer: 'Boeing',
    weight: 161_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'A320',
    manufacturer: 'Airbus',
    weight: 146_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'FOKKER-0',
    manufacturer: 'Fokker',
    weight: 81_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'G-V-A',
    manufacturer: 'Gulfstream',
    weight: 75_298,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-135 6',
    manufacturer: 'Embraer',
    weight: 40_820,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'G-5',
    manufacturer: 'Gulfstream',
    weight: 35_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'CE208A',
    manufacturer: 'Cessna',
    weight: 8_750,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '737-300-0',
    manufacturer: 'Boeing',
    weight: 116_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'DC-9-11',
    manufacturer: 'McDonnell Douglas',
    weight: 90_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-135 4',
    manufacturer: 'Embraer',
    weight: 40_784,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'G-IV',
    manufacturer: 'Gulfstream',
    weight: 58_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'CU-580',
    manufacturer: 'Unknown',
    weight: 52_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'CRJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'Falcon-7X',
    manufacturer: 'Falcon',
    weight: 62_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'MD-80-87',
    manufacturer: 'McDonnell Douglas',
    weight: 128_000,
    capacity: 128,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'CRJ-200 1',
    manufacturer: 'Bombardier',
    weight: 58_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'B757-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'A-319-131-0',
    manufacturer: 'Airbus',
    weight: 134_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'Global-Express',
    manufacturer: 'Gulfstream',
    weight: 78_639,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-135 11',
    manufacturer: 'Embraer',
    weight: 40_765,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'B-767-200-0',
    manufacturer: 'Boeing',
    weight: 270_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'Metro',
    manufacturer: 'Swearingen Fairchild',
    weight: 12_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'SA Merlin-4',
    manufacturer: 'Swearingen Fairchild',
    weight: 14_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'B737-400 12',
    manufacturer: 'Boeing',
    weight: 121_750,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'DC-9 3',
    manufacturer: 'McDonnell Douglas',
    weight: 100_980,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '737-300 2',
    manufacturer: 'Boeing',
    weight: 103_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'Aerostar-3',
    manufacturer: 'Piper',
    weight: 5_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '737-200',
    manufacturer: 'Boeing',
    weight: 95_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 114_000,
    capacity: 134,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'DC-9-30',
    manufacturer: 'McDonnell Douglas',
    weight: 99_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '727-A',
    manufacturer: 'Boeing',
    weight: 150_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-145-XR',
    manufacturer: 'Embraer',
    weight: 44_092,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '737-700 2',
    manufacturer: 'Boeing',
    weight: 154_216,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'D9-200',
    manufacturer: 'McDonnell Douglas',
    weight: 95_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'DC-9-2',
    manufacturer: 'McDonnell Douglas',
    weight: 100_971,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'G-IV 2',
    manufacturer: 'Gulfstream',
    weight: 58_507,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'G-550',
    manufacturer: 'Gulfstream',
    weight: 75_299,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'CD 9-300',
    manufacturer: 'McDonnell Douglas',
    weight: 100_973,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-145 D',
    manufacturer: 'Embraer',
    weight: 42_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'G-IV 3',
    manufacturer: 'Gulfstream',
    weight: 72_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-145 4',
    manufacturer: 'Embraer',
    weight: 42_089,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-135 10',
    manufacturer: 'Embraer',
    weight: 40_875,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'B767-300-0',
    manufacturer: 'Boeing',
    weight: 300_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'G-650 2',
    manufacturer: 'Gulfstream',
    weight: 101_340,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'GV-G',
    manufacturer: 'Gulfstream',
    weight: 75_301,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'B-727-100C-0',
    manufacturer: 'Boeing',
    weight: 137_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'G-650 2',
    manufacturer: 'Gulfstream',
    weight: 101_343,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'B-737-400 1',
    manufacturer: 'Boeing',
    weight: 138_354,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '737-400 6',
    manufacturer: 'Boeing',
    weight: 138_355,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '737-400 7',
    manufacturer: 'Boeing',
    weight: 150_490,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'B737-800 5',
    manufacturer: 'Boeing',
    weight: 177_555,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '737-200 1',
    manufacturer: 'Boeing',
    weight: 98_015,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'BBJ-2',
    manufacturer: 'Boeing',
    weight: 134_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-135 8',
    manufacturer: 'Embraer',
    weight: 40_045,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-135 9',
    manufacturer: 'Embraer',
    weight: 40_788,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-145 6',
    manufacturer: 'Embraer',
    weight: 41_229,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-145 7',
    manufacturer: 'Embraer',
    weight: 50_030,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-145 8',
    manufacturer: 'Embraer',
    weight: 50_033,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-145 9',
    manufacturer: 'Embraer',
    weight: 50_039,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'Cit-XL',
    manufacturer: 'Cessna',
    weight: 108_702,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'DC 9-2',
    manufacturer: 'McDonnell Douglas',
    weight: 81_800,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'MD-83 4',
    manufacturer: 'McDonnell Douglas',
    weight: 128_800,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-135',
    manufacturer: 'Embraer',
    weight: 40_786,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-135 7',
    manufacturer: 'Embraer',
    weight: 40_789,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'G-450',
    manufacturer: 'Gulfstream',
    weight: 66_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'G-V-B',
    manufacturer: 'Gulfstream',
    weight: 75_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'Global',
    manufacturer: 'Gulfstream',
    weight: 78_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'GV-F',
    manufacturer: 'Gulfstream',
    weight: 75_296,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'MD82',
    manufacturer: 'McDonnell Douglas',
    weight: 130_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 129_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-145 5',
    manufacturer: 'Embraer',
    weight: 41_266,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'C-208 D',
    manufacturer: 'Cessna',
    weight: 8_752,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-Legacy',
    manufacturer: 'Embraer',
    weight: 40_785,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'ERJ-140',
    manufacturer: 'Embraer',
    weight: 41_226,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'Falcon-900',
    manufacturer: 'Falcon',
    weight: 42_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-170 1',
    manufacturer: 'Embraer',
    weight: 72_313,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMB-135 5',
    manufacturer: 'Embraer',
    weight: 50_410,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'C-208 E',
    manufacturer: 'Cessna',
    weight: 8_705,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'A320-7',
    manufacturer: 'Airbus',
    weight: 175_773,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'EMP-190',
    manufacturer: 'Embraer',
    weight: 97_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'BBJ-3',
    manufacturer: 'Boeing',
    weight: 140_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'Metro-V',
    manufacturer: 'Cessna',
    weight: 15_675,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'G-VI',
    manufacturer: 'Gulfstream',
    weight: 83_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 150_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'Convair-440',
    manufacturer: 'Convair',
    weight: 47_650,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'Merlin III',
    manufacturer: 'Swearingen Fairchild',
    weight: 11_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'B-737-400-0',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '737-800',
    manufacturer: 'Boeing',
    weight: 144_000,
    capacity: 154,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'CRJ-70-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'DC-9-15',
    manufacturer: 'McDonnell Douglas',
    weight: 81_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'Meridian',
    manufacturer: 'Piper',
    weight: 5_134,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Falcon-7',
    manufacturer: 'Falcon',
    weight: 62_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Merlin',
    manufacturer: 'Cessna',
    weight: 9_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'EMB-135',
    manufacturer: 'Embraer',
    weight: 41_888,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '727-200',
    manufacturer: 'Boeing',
    weight: 154_500,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 210_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'DELTAE001',
    makeModel: 'DC-9-30E',
    manufacturer: 'McDonnell Douglas',
    weight: 110_000,
    capacity: 110,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'B-737-800',
    manufacturer: 'Boeing',
    weight: 146_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'G-IV',
    manufacturer: 'Gulfstream',
    weight: 58_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'G-V-D',
    manufacturer: 'Gulfstream',
    weight: 72_298,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'A319',
    manufacturer: 'Airbus',
    weight: 138_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'EMB-145',
    manufacturer: 'Embraer',
    weight: 41_226,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'G-V-C',
    manufacturer: 'Gulfstream',
    weight: 75_598,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'DC-9',
    manufacturer: 'McDonnell Douglas',
    weight: 109_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'G-V-E',
    manufacturer: 'Gulfstream',
    weight: 75_290,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'G-III',
    manufacturer: 'Gulfstream',
    weight: 58_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'MD-81',
    manufacturer: 'McDonnell Douglas',
    weight: 122_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Metro',
    manufacturer: 'Cessna',
    weight: 14_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'BBJ-2',
    manufacturer: 'Boeing',
    weight: 134_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Falcon-7X',
    manufacturer: 'Falcon',
    weight: 62_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'B-737',
    manufacturer: 'Boeing',
    weight: 99_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'B-727-200',
    manufacturer: 'Boeing',
    weight: 166_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '737-800-0',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Merlin-2',
    manufacturer: 'Cessna',
    weight: 11_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'G-650',
    manufacturer: 'Gulfstream',
    weight: 83_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Global',
    manufacturer: 'Gulfstream',
    weight: 78_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '727-100-0',
    manufacturer: 'Boeing',
    weight: 137_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '757-300',
    manufacturer: 'Boeing',
    weight: 224_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'BBJ',
    manufacturer: 'Boeing',
    weight: 95_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'EMB-135',
    manufacturer: 'Embraer',
    weight: 40_786,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'CE208A',
    manufacturer: 'Cessna',
    weight: 8_750,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'ERJ-190',
    manufacturer: 'Embraer',
    weight: 94_800,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'G-450',
    manufacturer: 'Gulfstream',
    weight: 66_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'PA-60-600-0',
    manufacturer: 'Piper',
    weight: 5_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'EMB-135',
    manufacturer: 'Embraer',
    weight: 68_210,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '737-200',
    manufacturer: 'Boeing',
    weight: 103_000,
    capacity: 134,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Convair IFL-0',
    manufacturer: 'Convair',
    weight: 58_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 129_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '757-300',
    manufacturer: 'Boeing',
    weight: 255_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'A-319-131-0',
    manufacturer: 'Airbus',
    weight: 134_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'CE208B',
    manufacturer: 'Cessna',
    weight: 12_750,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'CONVAIR-0',
    manufacturer: 'Convair',
    weight: 52_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'DC-9-15',
    manufacturer: 'McDonnell Douglas',
    weight: 81_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '208-B',
    manufacturer: 'Cessna',
    weight: 8_500,
    capacity: 1,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Falcon-900',
    manufacturer: 'Falcon',
    weight: 42_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'EMB-Legacy',
    manufacturer: 'Embraer',
    weight: 40_785,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Aerostar-2',
    manufacturer: 'Piper',
    weight: 5_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'SAAB-2000',
    manufacturer: 'SAAB',
    weight: 48_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 150_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'B737-400-0',
    manufacturer: 'Boeing',
    weight: 121_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'MD-83',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'PA-60-600-0',
    manufacturer: 'Piper',
    weight: 5_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '737-300-0',
    manufacturer: 'Boeing',
    weight: 116_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'G-V-B',
    manufacturer: 'Gulfstream',
    weight: 75_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 129_199,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Airbus-320',
    manufacturer: 'Airbus',
    weight: 145_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'A319',
    manufacturer: 'Airbus',
    weight: 137_801,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Aerostar',
    manufacturer: 'Piper',
    weight: 6_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Global-Express',
    manufacturer: 'Bombardier',
    weight: 78_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'MD 87-0',
    manufacturer: 'Boeing',
    weight: 128_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '737-800',
    manufacturer: 'Boeing',
    weight: 144_000,
    capacity: 154,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: '737 A-0',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'DC-9-30',
    manufacturer: 'McDonnell Douglas',
    weight: 99_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'Falcon-900',
    manufacturer: 'Falcon',
    weight: 42_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Delta Private jets',
    iata: '',
    icao: 'DPJ',
    agreementNumber: 'CVGAV001',
    makeModel: 'G-V-A',
    manufacturer: 'Gulfstream',
    weight: 75_298,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Air Limited (UK)-DHL',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Air Limited (UK)-DHL',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Air Limited (UK)-DHL',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Air Limited (UK)-DHL',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Air Limited (UK)-DHL',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Air Limited (UK)-DHL',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL EU Carrier - DHL',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLEU001',
    makeModel: 'AIRBUS 300 A-0',
    manufacturer: 'Airbus',
    weight: 295_420,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: 'DC-9-31B',
    manufacturer: 'McDonnell Douglas',
    weight: 99_000,
    capacity: 1,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: 'DC-9-32A',
    manufacturer: 'McDonnell Douglas',
    weight: 101_000,
    capacity: 1,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: 'DC-10-30',
    manufacturer: 'McDonnell Douglas',
    weight: 436_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: 'DC863F-0',
    manufacturer: 'McDonnell Douglas',
    weight: 267_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: 'DC-863-0',
    manufacturer: 'McDonnell Douglas',
    weight: 258_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: '727R-0',
    manufacturer: 'Boeing',
    weight: 197_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'BERRY001',
    makeModel: 'SA Merlin-4',
    manufacturer: 'Swearingen Fairchild',
    weight: 14_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'KOLOB001',
    makeModel: '120ER-0',
    manufacturer: 'Embraer',
    weight: 25_794,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'SINGA002',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ALLIA001',
    makeModel: 'SH330-0',
    manufacturer: 'Shorts',
    weight: 22_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ATLAS002',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AEROT001',
    makeModel: 'A321-5',
    manufacturer: 'Airbus',
    weight: 171_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AEROT001',
    makeModel: 'AIRBUS 300 A-0',
    manufacturer: 'Airbus',
    weight: 295_420,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AEROT001',
    makeModel: 'A321-5',
    manufacturer: 'Airbus',
    weight: 171_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AEROT001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLEU001',
    makeModel: 'AIRBUS 300 A-0',
    manufacturer: 'Airbus',
    weight: 295_420,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'FREIG001',
    makeModel: '1900-D',
    manufacturer: 'Beechcraft',
    weight: 16_100,
    capacity: 19,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ATLAS002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ATLAS002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ATLAS002',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ATLAS002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ATLAS002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AEROL001',
    makeModel: 'B777-200-0',
    manufacturer: 'Boeing',
    weight: 460_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AEROL001',
    makeModel: '777-200-0',
    manufacturer: 'Boeing',
    weight: 460_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ROYAL001',
    makeModel: 'LR-35-0',
    manufacturer: 'Lear',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ROYAL001',
    makeModel: 'Dassault Falcon-2',
    manufacturer: 'Falcon',
    weight: 16_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'FREIG001',
    makeModel: '120ER-0',
    manufacturer: 'Embraer',
    weight: 25_794,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'FREIG001',
    makeModel: 'BE-99',
    manufacturer: 'Beechcraft',
    weight: 10_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CASTL002',
    makeModel: 'CNC-1',
    manufacturer: 'Cessna',
    weight: 8_497,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CASTL002',
    makeModel: 'LR-35-0',
    manufacturer: 'Lear',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CASTL002',
    makeModel: 'PA-60-600-0',
    manufacturer: 'Piper',
    weight: 5_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'BANKA001',
    makeModel: 'MU-2-0',
    manufacturer: 'Mitsubishi',
    weight: 11_025,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'NIPPO001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DMTNN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AEROL001',
    makeModel: '777-FS',
    manufacturer: 'Boeing',
    weight: 575_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AMERIS001',
    makeModel: 'Falcon-20',
    manufacturer: 'Falcon',
    weight: 6_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CASTL002',
    makeModel: 'LR-35A-0',
    manufacturer: 'Lear',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AMERIF003',
    makeModel: '120ER-0',
    manufacturer: 'Embraer',
    weight: 25_794,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AMERIF003',
    makeModel: 'SA227-AT',
    manufacturer: 'SAAB',
    weight: 14_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AMERIF003',
    makeModel: '1900-D',
    manufacturer: 'Beechcraft',
    weight: 16_100,
    capacity: 19,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AMERIF003',
    makeModel: 'SA227-AT',
    manufacturer: 'SAAB',
    weight: 14_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAL001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: 'B757-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: 'DC8-73F',
    manufacturer: 'McDonnell Douglas',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 310_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AMERIF003',
    makeModel: '120ER-0',
    manufacturer: 'Embraer',
    weight: 25_794,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AMERIF003',
    makeModel: 'BE-99',
    manufacturer: 'Beechcraft',
    weight: 10_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: 'B757-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 310_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ASTAR001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ASTAR001',
    makeModel: 'AIRBUS 300 A-0',
    manufacturer: 'Airbus',
    weight: 295_420,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ASTAR001',
    makeModel: 'DC-8-73F-0',
    manufacturer: 'Boeing',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: 'DC8-73F',
    manufacturer: 'McDonnell Douglas',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRTR002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ABXAI002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ABXAI002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ABXAI002',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ABXAI002',
    makeModel: '767-ER-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ABXAI002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ASTAR001',
    makeModel: 'DC8-73F',
    manufacturer: 'McDonnell Douglas',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'SUBUR001',
    makeModel: 'BE-99',
    manufacturer: 'Beechcraft',
    weight: 10_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRCAR001',
    makeModel: 'SH360-0',
    manufacturer: 'Shorts',
    weight: 26_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'AIRCAR001',
    makeModel: 'SH330-0',
    manufacturer: 'Shorts',
    weight: 22_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'LUFTH002',
    makeModel: 'MD11',
    manufacturer: 'Boeing',
    weight: 491_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'LUFTH002',
    makeModel: 'MD11',
    manufacturer: 'Boeing',
    weight: 491_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'ABXAI002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CARGO001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CARGO001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'SUBUR001',
    makeModel: '1900-D',
    manufacturer: 'Beechcraft',
    weight: 16_100,
    capacity: 19,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'SUBUR001',
    makeModel: 'BES',
    manufacturer: 'Beechcraft',
    weight: 22_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'SUBUR001',
    makeModel: 'C208-CNC',
    manufacturer: 'Cessna',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'SUBUR001',
    makeModel: '1900-D',
    manufacturer: 'Beechcraft',
    weight: 16_100,
    capacity: 19,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CARGO001',
    makeModel: '727-0',
    manufacturer: 'Boeing',
    weight: 161_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CARGO001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CARGO001',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CARGO001',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CARGO001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CARGO001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'POLAR001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'POLAR001',
    makeModel: '747F A-0',
    manufacturer: 'Boeing',
    weight: 630_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'POLAR001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'POLAR001',
    makeModel: '747-400 9',
    manufacturer: 'Boeing',
    weight: 762_985,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CARGO001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CARGO001',
    makeModel: '727-0',
    manufacturer: 'Boeing',
    weight: 161_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'NOLIN001',
    makeModel: 'CV-580-0',
    manufacturer: 'Convair',
    weight: 52_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'POLAR001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'POLAR001',
    makeModel: '747-200-0',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'POLAR001',
    makeModel: '747-200-0',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'POLAR001',
    makeModel: '747F A-0',
    manufacturer: 'Boeing',
    weight: 630_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'POLAR001',
    makeModel: '747-400 9',
    manufacturer: 'Boeing',
    weight: 762_985,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CAPIT001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CAPIT001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CAPIT001',
    makeModel: '727-0',
    manufacturer: 'Boeing',
    weight: 161_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'NOLIN001',
    makeModel: '737-200F',
    manufacturer: 'Boeing',
    weight: 115_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'NOLIN001',
    makeModel: '737-200F',
    manufacturer: 'Boeing',
    weight: 115_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'NOLIN001',
    makeModel: 'CV-580-0',
    manufacturer: 'Convair',
    weight: 52_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: 'DC-8-61',
    manufacturer: 'McDonnell Douglas',
    weight: 240_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CAPIT001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CAPIT001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CAPIT001',
    makeModel: 'DC8-73F',
    manufacturer: 'McDonnell Douglas',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'CAPIT001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: 'DC931A',
    manufacturer: 'McDonnell Douglas',
    weight: 98_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: 'DC-863-0',
    manufacturer: 'Boeing',
    weight: 245_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: 'DC932A-0',
    manufacturer: 'McDonnell Douglas',
    weight: 105_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: 'DC931',
    manufacturer: 'McDonnell Douglas',
    weight: 102_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'DHL Worldwide Express',
    iata: 'D0',
    icao: 'DHK',
    agreementNumber: 'DHLAI001',
    makeModel: 'DC933',
    manufacturer: 'McDonnell Douglas',
    weight: 114_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dubai Air Wing',
    iata: '',
    icao: 'DUB',
    agreementNumber: 'DUBAI001',
    makeModel: '747-400 10',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dubai Air Wing',
    iata: '',
    icao: 'DUB',
    agreementNumber: 'DUBAI001',
    makeModel: '747-400 8',
    manufacturer: 'Boeing',
    weight: 442_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dubai Air Wing',
    iata: '',
    icao: 'DUB',
    agreementNumber: 'DUBAI001',
    makeModel: 'B747-400F 3',
    manufacturer: 'Boeing',
    weight: 440_897,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dubai Air Wing',
    iata: '',
    icao: 'DUB',
    agreementNumber: 'DUBAI001',
    makeModel: 'B747-400F 4',
    manufacturer: 'Boeing',
    weight: 440_949,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dubai Air Wing',
    iata: '',
    icao: 'DUB',
    agreementNumber: 'DUBAI001',
    makeModel: 'B747-400F 5',
    manufacturer: 'Boeing',
    weight: 438_953,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dubai Air Wing',
    iata: '',
    icao: 'DUB',
    agreementNumber: 'DUBAI001',
    makeModel: 'B747-400F 6',
    manufacturer: 'Boeing',
    weight: 443_110,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dubai Air Wing',
    iata: '',
    icao: 'DUB',
    agreementNumber: 'DUBAI001',
    makeModel: '747-400 8',
    manufacturer: 'Boeing',
    weight: 442_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dubai Air Wing',
    iata: '',
    icao: 'DUB',
    agreementNumber: 'DUBAI001',
    makeModel: '747-400 9',
    manufacturer: 'Boeing',
    weight: 458_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dubai Air Wing',
    iata: '',
    icao: 'DUB',
    agreementNumber: 'DUBAI001',
    makeModel: '747-400M',
    manufacturer: 'Boeing',
    weight: 600_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dubai Air Wing',
    iata: '',
    icao: 'DUB',
    agreementNumber: 'DUBAI001',
    makeModel: 'B747-400F',
    manufacturer: 'Boeing',
    weight: 460_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dubai Air Wing',
    iata: '',
    icao: 'DUB',
    agreementNumber: 'DUBAI001',
    makeModel: 'B747-400F 2',
    manufacturer: 'Boeing',
    weight: 441_552,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Dynamic International Airways',
    iata: '2D',
    icao: 'EAL',
    agreementNumber: 'DYNAM001',
    makeModel: '767-300 3',
    manufacturer: 'Boeing',
    weight: 299_829,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Elan Express, Inc.',
    iata: '',
    icao: 'ELX',
    agreementNumber: 'ELANE001',
    makeModel: 'B727-200',
    manufacturer: 'Boeing',
    weight: 167_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Elan Express, Inc.',
    iata: '',
    icao: 'ELX',
    agreementNumber: 'ELANE001',
    makeModel: 'B727-200',
    manufacturer: 'Boeing',
    weight: 166_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Elan Express, Inc.',
    iata: '',
    icao: 'ELX',
    agreementNumber: 'ELANE001',
    makeModel: 'B727-200',
    manufacturer: 'Boeing',
    weight: 155_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Elite Airways LLC',
    iata: '7Q',
    icao: 'MNU',
    agreementNumber: 'ELITE001',
    makeModel: 'CRJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Elite Airways LLC',
    iata: '7Q',
    icao: 'MNU',
    agreementNumber: 'ELITE001',
    makeModel: 'CRJ-70-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Elite Airways LLC',
    iata: '7Q',
    icao: 'MNU',
    agreementNumber: 'ELITE001',
    makeModel: 'CRJ-700-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Emirates SkyCargo',
    iata: 'EK',
    icao: 'UAE',
    agreementNumber: 'EMIRA001',
    makeModel: '777-200F',
    manufacturer: 'Boeing',
    weight: 574_993,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 1,
  },
  {
    airline: 'Encore Air Cargo-DHL',
    iata: '',
    icao: 'DKT',
    agreementNumber: 'ENCOR001',
    makeModel: 'SA227-AT',
    manufacturer: 'SAAB',
    weight: 14_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Endeavor Air, Inc-DC. fka Pinnacle Airlines',
    iata: '9E',
    icao: 'EDV',
    agreementNumber: 'ENDEA002',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Endeavor Air, Inc-DC. fka Pinnacle Airlines',
    iata: '9E',
    icao: 'EDV',
    agreementNumber: 'ENDEA002',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Endeavor Air, Inc-DC. fka Pinnacle Airlines',
    iata: '9E',
    icao: 'EDV',
    agreementNumber: 'ENDEA002',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Endeavor Air, Inc-DC. fka Pinnacle Airlines',
    iata: '9E',
    icao: 'EDV',
    agreementNumber: 'ENDEA002',
    makeModel: 'CRJ-700',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Endeavor Air, Inc-DC. fka Pinnacle Airlines',
    iata: '9E',
    icao: 'EDV',
    agreementNumber: 'ENDEA002',
    makeModel: 'CRJ-70-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Endeavor Air, Inc-DC. fka Pinnacle Airlines',
    iata: '9E',
    icao: 'EDV',
    agreementNumber: 'ENDEA002',
    makeModel: 'CRJ-70',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 70,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Envoy Airlines, Inc-AA fka American Eagle Airlines, Inc.',
    iata: 'MQ',
    icao: 'ENY',
    agreementNumber: 'ENVOY001',
    makeModel: 'EMB-140-0',
    manufacturer: 'Embraer',
    weight: 41_226,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Envoy Airlines, Inc-AA fka American Eagle Airlines, Inc.',
    iata: 'MQ',
    icao: 'ENY',
    agreementNumber: 'ENVOY001',
    makeModel: 'EMB-145-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Envoy Airlines, Inc-AA fka American Eagle Airlines, Inc.',
    iata: 'MQ',
    icao: 'ENY',
    agreementNumber: 'ENVOY001',
    makeModel: 'EMB 135-0',
    manufacturer: 'Embraer',
    weight: 40_785,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Envoy Airlines, Inc-AA fka American Eagle Airlines, Inc.',
    iata: 'MQ',
    icao: 'ENY',
    agreementNumber: 'ENVOY001',
    makeModel: 'CRJ-70',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 70,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Envoy Airlines, Inc-AA fka American Eagle Airlines, Inc.',
    iata: 'MQ',
    icao: 'ENY',
    agreementNumber: 'ENVOY001',
    makeModel: 'EM4',
    manufacturer: 'Embraer',
    weight: 42_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Envoy Airlines, Inc-AA fka American Eagle Airlines, Inc.',
    iata: 'MQ',
    icao: 'ENY',
    agreementNumber: 'ENVOY001',
    makeModel: 'ERD',
    manufacturer: 'Embraer',
    weight: 41_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Envoy Airlines, Inc-AA fka American Eagle Airlines, Inc.',
    iata: 'MQ',
    icao: 'ENY',
    agreementNumber: 'ENVOY001',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 73_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Envoy Airlines, Inc-AA fka American Eagle Airlines, Inc.',
    iata: 'MQ',
    icao: 'ENY',
    agreementNumber: 'ENVOY001',
    makeModel: 'ETS-145 2',
    manufacturer: 'Embraer',
    weight: 43_650,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Envoy Airlines, Inc-AA fka American Eagle Airlines, Inc.',
    iata: 'MQ',
    icao: 'ENY',
    agreementNumber: 'ENVOY001',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_950,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Envoy Airlines, Inc-AA fka American Eagle Airlines, Inc.',
    iata: 'MQ',
    icao: 'ENY',
    agreementNumber: 'ENVOY001',
    makeModel: 'ERD-2',
    manufacturer: 'Embraer',
    weight: 41_220,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'European Air Transport - DHL',
    iata: 'QY',
    icao: 'BCS',
    agreementNumber: 'EUROP001',
    makeModel: 'A333-2',
    manufacturer: 'Airbus',
    weight: 412_264,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'European Air Transport - DHL',
    iata: 'QY',
    icao: 'BCS',
    agreementNumber: 'EUROP001',
    makeModel: 'A333-2',
    manufacturer: 'Airbus',
    weight: 412_264,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'European Air Transport - DHL',
    iata: 'QY',
    icao: 'BCS',
    agreementNumber: 'EUROP001',
    makeModel: 'A333-2',
    manufacturer: 'Airbus',
    weight: 412_264,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc-American',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE006',
    makeModel: 'CRJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE002',
    makeModel: 'ERJ-145XR',
    manufacturer: 'Embraer',
    weight: 44_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE002',
    makeModel: 'EMB-145LR',
    manufacturer: 'Embraer',
    weight: 43_651,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE002',
    makeModel: 'ERJ-145ER',
    manufacturer: 'Embraer',
    weight: 41_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE002',
    makeModel: 'SRJ-135',
    manufacturer: 'Embraer',
    weight: 40_800,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE002',
    makeModel: 'SRJ-135LR',
    manufacturer: 'Embraer',
    weight: 40_800,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE002',
    makeModel: 'ERJ-145LR',
    manufacturer: 'Embraer',
    weight: 42_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE002',
    makeModel: 'EMB-145-XR',
    manufacturer: 'Embraer',
    weight: 44_092,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE002',
    makeModel: 'DASH 8-0',
    manufacturer: 'De Havilland',
    weight: 34_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE001',
    makeModel: 'SRJ-135',
    manufacturer: 'Embraer',
    weight: 40_800,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE001',
    makeModel: 'SRJ-135LR',
    manufacturer: 'Embraer',
    weight: 40_800,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 20,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE001',
    makeModel: 'ERJ-145XR',
    manufacturer: 'Embraer',
    weight: 44_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 30,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE001',
    makeModel: 'ERJ-145LR',
    manufacturer: 'Embraer',
    weight: 42_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 40,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Continental Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE001',
    makeModel: 'ERJ-145ER',
    manufacturer: 'Embraer',
    weight: 41_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 50,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Delta Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE004',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Delta Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE004',
    makeModel: 'CRJ-50-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Delta Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE004',
    makeModel: 'CRJ-70',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 70,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Delta Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE004',
    makeModel: 'CRJ-50-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Express Jet Airlines, Inc.-Delta Connection',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE004',
    makeModel: 'CRJ-700',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ExpressJet-United Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE003',
    makeModel: 'EMB-145-XR',
    manufacturer: 'Embraer',
    weight: 44_092,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ExpressJet-United Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE003',
    makeModel: 'EMBRAER 145-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ExpressJet-United Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE003',
    makeModel: 'EMB-145LR',
    manufacturer: 'Embraer',
    weight: 43_651,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ExpressJet-United Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE003',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ExpressJet-United Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE003',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ExpressJet-United Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE003',
    makeModel: 'ERJ',
    manufacturer: 'Embraer',
    weight: 41_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'ExpressJet-United Airlines',
    iata: 'EV',
    icao: 'ASQ',
    agreementNumber: 'EXPRE003',
    makeModel: 'SLJ',
    manufacturer: 'Embraer',
    weight: 40_785,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Falcon Air Express, Inc.',
    iata: '',
    icao: 'FAO',
    agreementNumber: 'FALCO001',
    makeModel: 'MD-83',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'A-300-0',
    manufacturer: 'Airbus',
    weight: 308_650,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'A310-A12-0',
    manufacturer: 'Airbus',
    weight: 267_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 161_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'A-310-0',
    manufacturer: 'Airbus',
    weight: 267_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'B-727-200',
    manufacturer: 'Boeing',
    weight: 166_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER005',
    makeModel: 'A310-0',
    manufacturer: 'Airbus',
    weight: 261_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER005',
    makeModel: 'A300-600',
    manufacturer: 'Airbus',
    weight: 304_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER005',
    makeModel: 'A-310-0',
    manufacturer: 'Airbus',
    weight: 267_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER005',
    makeModel: 'A310-300',
    manufacturer: 'Airbus',
    weight: 273_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: '727-200',
    manufacturer: 'Boeing',
    weight: 161_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER005',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER005',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER005',
    makeModel: 'MD-11F-0',
    manufacturer: 'Boeing',
    weight: 481_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER005',
    makeModel: 'MD10-30',
    manufacturer: 'McDonnell Douglas',
    weight: 424_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER005',
    makeModel: 'A-300',
    manufacturer: 'Airbus',
    weight: 308_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'MD-11F-0',
    manufacturer: 'Boeing',
    weight: 481_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'A310-0',
    manufacturer: 'Airbus',
    weight: 261_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'MD10-30',
    manufacturer: 'McDonnell Douglas',
    weight: 424_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'MD10-10',
    manufacturer: 'McDonnell Douglas',
    weight: 374_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: '727-100-0',
    manufacturer: 'Boeing',
    weight: 137_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'A-300-60',
    manufacturer: 'Airbus',
    weight: 308_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'MD-11F-0',
    manufacturer: 'Boeing',
    weight: 481_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'A300-600',
    manufacturer: 'Airbus',
    weight: 308_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'A310-300',
    manufacturer: 'Airbus',
    weight: 273_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Express',
    iata: 'FX',
    icao: 'FDX',
    agreementNumber: 'FEDER002',
    makeModel: 'A300-600',
    manufacturer: 'Airbus',
    weight: 304_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Reserve Bank of Atlanta',
    iata: '',
    icao: '',
    agreementNumber: 'FEDERA001',
    makeModel: 'LR-35-0',
    manufacturer: 'Lear',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Reserve Bank of Atlanta',
    iata: '',
    icao: '',
    agreementNumber: 'FEDERA001',
    makeModel: 'C310-0',
    manufacturer: 'Cessna',
    weight: 5_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Reserve Bank of Atlanta',
    iata: '',
    icao: '',
    agreementNumber: 'FEDERA001',
    makeModel: 'PA-31 NAVAJO-0',
    manufacturer: 'Piper',
    weight: 6_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Reserve Bank of Atlanta',
    iata: '',
    icao: '',
    agreementNumber: 'FEDERA001',
    makeModel: '208-B',
    manufacturer: 'Cessna',
    weight: 8_500,
    capacity: 1,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Reserve Bank of Atlanta',
    iata: '',
    icao: '',
    agreementNumber: 'FEDERA001',
    makeModel: 'PA-31-310-0',
    manufacturer: 'Piper',
    weight: 6_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Reserve Bank of Atlanta',
    iata: '',
    icao: '',
    agreementNumber: 'FEDERA001',
    makeModel: 'LR-25-0',
    manufacturer: 'Lear',
    weight: 13_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Federal Reserve Bank of Atlanta',
    iata: '',
    icao: '',
    agreementNumber: 'FEDERA001',
    makeModel: 'MU-2-0',
    manufacturer: 'Mitsubishi',
    weight: 11_025,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Freedom Airlines, Inc.',
    iata: '',
    icao: 'FRL',
    agreementNumber: 'FREED001',
    makeModel: 'ER4-0',
    manufacturer: 'Embraer',
    weight: 42_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Freedom Airlines, Inc.',
    iata: '',
    icao: 'FRL',
    agreementNumber: 'FREED001',
    makeModel: 'EMB-145-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Freedom Airlines, Inc.',
    iata: '',
    icao: 'FRL',
    agreementNumber: 'FREED001',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 73_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Freedom Airlines, Inc.',
    iata: '',
    icao: 'FRL',
    agreementNumber: 'FREED001',
    makeModel: 'EMB-145',
    manufacturer: 'Embraer',
    weight: 42_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Freedom Airlines, Inc.',
    iata: '',
    icao: 'FRL',
    agreementNumber: 'FREED001',
    makeModel: 'CR9-0',
    manufacturer: 'Bombardier',
    weight: 75_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Freight Runners Express - DHL',
    iata: '',
    icao: 'FRG',
    agreementNumber: 'FREIG001',
    makeModel: '120ER-0',
    manufacturer: 'Embraer',
    weight: 25_794,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Freight Runners Express - DHL',
    iata: '',
    icao: 'FRG',
    agreementNumber: 'FREIG001',
    makeModel: 'BE-99',
    manufacturer: 'Beechcraft',
    weight: 10_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Freight Runners Express - DHL',
    iata: '',
    icao: 'FRG',
    agreementNumber: 'FREIG001',
    makeModel: '1900-D',
    manufacturer: 'Beechcraft',
    weight: 16_100,
    capacity: 19,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Charter',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT003',
    makeModel: 'A320',
    manufacturer: 'Airbus',
    weight: 142_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Charter',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT003',
    makeModel: 'A320',
    manufacturer: 'Airbus',
    weight: 142_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Charter',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT003',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Charter',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT003',
    makeModel: 'A319',
    manufacturer: 'Airbus',
    weight: 134_480,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT001',
    makeModel: 'A319',
    manufacturer: 'Airbus',
    weight: 134_480,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT002',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT004',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT004',
    makeModel: 'A321-3',
    manufacturer: 'Airbus',
    weight: 171_518,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT002',
    makeModel: 'A320',
    manufacturer: 'Airbus',
    weight: 142_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT002',
    makeModel: 'A319',
    manufacturer: 'Airbus',
    weight: 134_480,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT002',
    makeModel: 'A321-3',
    manufacturer: 'Airbus',
    weight: 171_518,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT004',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT004',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Frontier Airlines, Inc. - Dom Scheduled',
    iata: 'F9',
    icao: 'FFT',
    agreementNumber: 'FRONT004',
    makeModel: 'A319',
    manufacturer: 'Airbus',
    weight: 134_480,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Global Western-DHL',
    iata: 'KD',
    icao: 'WGN',
    agreementNumber: 'GLOBA001',
    makeModel: 'MD-11 F 2',
    manufacturer: 'McDonnell Douglas',
    weight: 491_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'GoJet Airlines-Delta',
    iata: 'G7',
    icao: 'GJS',
    agreementNumber: 'GOJET002',
    makeModel: 'CRJ-70',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 70,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'GoJet Airlines-Delta',
    iata: 'G7',
    icao: 'GJS',
    agreementNumber: 'GOJET002',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'GoJet Airlines-United Express',
    iata: 'G7',
    icao: 'GJS',
    agreementNumber: 'GOJET001',
    makeModel: 'CRJ-700-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'GoJet Airlines-United Express',
    iata: 'G7',
    icao: 'GJS',
    agreementNumber: 'GOJET001',
    makeModel: 'CRJ-550',
    manufacturer: 'Bombardier',
    weight: 61_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Gold Transportation',
    iata: '',
    icao: '',
    agreementNumber: 'GOLDT001',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 114_000,
    capacity: 134,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Gold Transportation',
    iata: '',
    icao: '',
    agreementNumber: 'GOLDT001',
    makeModel: 'B737-200',
    manufacturer: 'Boeing',
    weight: 104_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Gold Transportation',
    iata: '',
    icao: '',
    agreementNumber: 'GOLDT001',
    makeModel: '737-200',
    manufacturer: 'Boeing',
    weight: 103_000,
    capacity: 134,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Gold Transportation',
    iata: '',
    icao: '',
    agreementNumber: 'GOLDT001',
    makeModel: '737-200-0',
    manufacturer: 'Boeing',
    weight: 107_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Gold Transportation',
    iata: '',
    icao: '',
    agreementNumber: 'GOLDT001',
    makeModel: 'MD-83',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Hawaiian Airlines, Inc.',
    iata: 'HA',
    icao: 'HAL',
    agreementNumber: 'HAWAI001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Hawaiian Airlines, Inc.',
    iata: 'HA',
    icao: 'HAL',
    agreementNumber: 'HAWAI001',
    makeModel: 'A330-200 2',
    manufacturer: 'Airbus',
    weight: 263_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Icelandair',
    iata: 'FI',
    icao: 'ICE',
    agreementNumber: 'ICELA001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 210_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'IFL Group-DHL',
    iata: '',
    icao: 'IFL',
    agreementNumber: 'IFLGR001',
    makeModel: 'Dassault Falcon-2',
    manufacturer: 'Falcon',
    weight: 16_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Jazz Air LP',
    iata: 'QK',
    icao: 'JZA',
    agreementNumber: 'AIRCA001',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IMTNN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Jazz Air LP',
    iata: 'QK',
    icao: 'JZA',
    agreementNumber: 'JAZZA001',
    makeModel: 'CRJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Jazz Air LP',
    iata: 'QK',
    icao: 'JZA',
    agreementNumber: 'JAZZA001',
    makeModel: 'DH8-0',
    manufacturer: 'De Havilland',
    weight: 33_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Jazz Air LP',
    iata: 'QK',
    icao: 'JZA',
    agreementNumber: 'JAZZA001',
    makeModel: 'DH3-0',
    manufacturer: 'De Havilland',
    weight: 40_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Jazz Air LP',
    iata: 'QK',
    icao: 'JZA',
    agreementNumber: 'JAZZA001',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Jazz Air LP',
    iata: 'QK',
    icao: 'JZA',
    agreementNumber: 'JAZZA001',
    makeModel: 'DH3-0',
    manufacturer: 'De Havilland',
    weight: 40_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Jazz Air LP',
    iata: 'QK',
    icao: 'JZA',
    agreementNumber: 'JAZZA001',
    makeModel: 'CRJ-700',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Jazz Air LP',
    iata: 'QK',
    icao: 'JZA',
    agreementNumber: 'JAZZA001',
    makeModel: 'CRJ-700',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Jazz Air LP',
    iata: 'QK',
    icao: 'JZA',
    agreementNumber: 'JAZZA003',
    makeModel: 'CRJ-700',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Jazz Air LP',
    iata: 'QK',
    icao: 'JZA',
    agreementNumber: 'JAZZA003',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'JetBlue Airlines',
    iata: 'B6',
    icao: 'JBU',
    agreementNumber: 'JETBL001',
    makeModel: 'A320-0',
    manufacturer: 'Airbus',
    weight: 130_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'JetBlue Airlines',
    iata: 'B6',
    icao: 'JBU',
    agreementNumber: 'JETBL001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'JetBlue Airlines',
    iata: 'B6',
    icao: 'JBU',
    agreementNumber: 'JETBL001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: '777-FS',
    manufacturer: 'Boeing',
    weight: 575_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: 'B747-400 7',
    manufacturer: 'Boeing',
    weight: 653_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: '777-FS',
    manufacturer: 'Boeing',
    weight: 575_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: '747F A-0',
    manufacturer: 'Boeing',
    weight: 630_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: '747F A-0',
    manufacturer: 'Boeing',
    weight: 630_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: 'B747-400 7',
    manufacturer: 'Boeing',
    weight: 653_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: '747-400 10',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT002',
    makeModel: '747-400 10',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '747F A-0',
    manufacturer: 'Boeing',
    weight: 630_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '727-200 2-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '737',
    manufacturer: 'Boeing',
    weight: 119_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '777-FS',
    manufacturer: 'Boeing',
    weight: 575_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '777-FS',
    manufacturer: 'Boeing',
    weight: 575_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '777-FS',
    manufacturer: 'Boeing',
    weight: 575_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '747-200-0',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '747F A-0',
    manufacturer: 'Boeing',
    weight: 630_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '727-200-0',
    manufacturer: 'Boeing',
    weight: 164_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '73P',
    manufacturer: 'Boeing',
    weight: 121_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kalitta Air Inc - DHL',
    iata: 'K4',
    icao: 'CKS',
    agreementNumber: 'KALIT001',
    makeModel: '737',
    manufacturer: 'Boeing',
    weight: 119_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Kolob Canyon Air-DHL',
    iata: '',
    icao: 'KCR',
    agreementNumber: 'KOLOB001',
    makeModel: '120ER-0',
    manufacturer: 'Embraer',
    weight: 25_794,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Lufthansa Cargo AG',
    iata: 'LH',
    icao: 'GEC',
    agreementNumber: 'LUFTH001',
    makeModel: 'MD 11',
    manufacturer: 'Boeing',
    weight: 408_005,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Lufthansa Cargo AG',
    iata: 'LH',
    icao: 'GEC',
    agreementNumber: 'LUFTH001',
    makeModel: 'B77F',
    manufacturer: 'Boeing',
    weight: 574_999,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Lufthansa Cargo AG',
    iata: 'LH',
    icao: 'GEC',
    agreementNumber: 'LUFTH001',
    makeModel: 'MD-11',
    manufacturer: 'Boeing',
    weight: 407_544,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Lufthansa Cargo AG',
    iata: 'LH',
    icao: 'GEC',
    agreementNumber: 'LUFTH001',
    makeModel: 'MD11-F',
    manufacturer: 'McDonnell Douglas',
    weight: 491_498,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Lufthansa Cargo AG',
    iata: 'LH',
    icao: 'GEC',
    agreementNumber: 'LUFTH001',
    makeModel: 'B77F',
    manufacturer: 'Boeing',
    weight: 574_999,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Lufthansa Cargo AG',
    iata: 'LH',
    icao: 'GEC',
    agreementNumber: 'LUFTH001',
    makeModel: 'MD11-F',
    manufacturer: 'McDonnell Douglas',
    weight: 491_498,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Lufthansa Cargo AG - DHL',
    iata: 'LH',
    icao: 'GEC',
    agreementNumber: 'LUFTH002',
    makeModel: 'MD11',
    manufacturer: 'Boeing',
    weight: 491_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Lufthansa Cargo AG - DHL',
    iata: 'LH',
    icao: 'GEC',
    agreementNumber: 'LUFTH002',
    makeModel: 'MD11',
    manufacturer: 'Boeing',
    weight: 491_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'M&N Aviation - DHL',
    iata: '',
    icao: 'JNH',
    agreementNumber: 'M&NAV001',
    makeModel: 'SH330-0',
    manufacturer: 'Shorts',
    weight: 22_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'MartinAir Holland-DHL',
    iata: 'MP',
    icao: 'MPH',
    agreementNumber: 'MARTI001',
    makeModel: 'LR-35A-0',
    manufacturer: 'Lear',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Matheson Flight Extenders, Inc.',
    iata: '',
    icao: 'CD49',
    agreementNumber: 'MATHE001',
    makeModel: 'Brasilia',
    manufacturer: 'Embraer',
    weight: 40_785,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DOTH',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA003',
    makeModel: 'EMB-175 2',
    manufacturer: 'Embraer',
    weight: 75_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA003',
    makeModel: 'CRJ7-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA002',
    makeModel: 'CRJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA002',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA002',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 73_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.- United Express',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA003',
    makeModel: 'EMB-175 2',
    manufacturer: 'Embraer',
    weight: 75_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.- United Express',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA003',
    makeModel: 'CRJ7-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.-AA',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA005',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 73_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.-AA',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA005',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.-Us Air Express',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA002',
    makeModel: 'CRJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.-Us Air Express',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA002',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesa Airlines, Inc.-Us Air Express',
    iata: 'YV',
    icao: 'ASH',
    agreementNumber: 'MESAA002',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 73_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesaba Aviation, Inc. -Delta',
    iata: 'XJ',
    icao: 'MES',
    agreementNumber: 'MESAB001',
    makeModel: 'CXJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesaba Aviation, Inc. -Delta',
    iata: 'XJ',
    icao: 'MES',
    agreementNumber: 'MESAB001',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 74_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesaba Aviation, Inc. -Delta',
    iata: 'XJ',
    icao: 'MES',
    agreementNumber: 'MESAB001',
    makeModel: 'CRJ900',
    manufacturer: 'Bombardier',
    weight: 74_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesaba Aviation, Inc. -Delta',
    iata: 'XJ',
    icao: 'MES',
    agreementNumber: 'MESAB001',
    makeModel: 'ARJ 85-0',
    manufacturer: 'Avro',
    weight: 85_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesaba Aviation, Inc. -Delta',
    iata: 'XJ',
    icao: 'MES',
    agreementNumber: 'MESAB001',
    makeModel: 'SFC-0',
    manufacturer: 'SAAB-Fairchild',
    weight: 28_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesaba Aviation, Inc. -Delta',
    iata: 'XJ',
    icao: 'MES',
    agreementNumber: 'MESAB003',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesaba Aviation, Inc. -Delta',
    iata: 'XJ',
    icao: 'MES',
    agreementNumber: 'MESAB003',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesaba Aviation, Inc. -Delta',
    iata: 'XJ',
    icao: 'MES',
    agreementNumber: 'MESAB003',
    makeModel: 'CRJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mesaba Aviation, Inc. -Delta',
    iata: 'XJ',
    icao: 'MES',
    agreementNumber: 'MESAB003',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 74_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DMTNN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI001',
    makeModel: '737-800-0',
    manufacturer: 'Boeing',
    weight: 145_992,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI001',
    makeModel: 'B727-410BN-0',
    manufacturer: 'Boeing',
    weight: 154_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI001',
    makeModel: '752MA-0',
    manufacturer: 'Boeing',
    weight: 120_997,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI001',
    makeModel: '737-800-0',
    manufacturer: 'Boeing',
    weight: 145_992,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI001',
    makeModel: '752MA-0',
    manufacturer: 'Boeing',
    weight: 120_997,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI003',
    makeModel: '737-800-0',
    manufacturer: 'Boeing',
    weight: 145_992,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI002',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI002',
    makeModel: 'B737-400-0',
    manufacturer: 'Boeing',
    weight: 121_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI002',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI003',
    makeModel: '752MA-0',
    manufacturer: 'Boeing',
    weight: 120_997,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI003',
    makeModel: '752MA-0',
    manufacturer: 'Boeing',
    weight: 120_997,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miami Air International',
    iata: 'LL',
    icao: 'BSK',
    agreementNumber: 'MIAMI003',
    makeModel: '737-800-0',
    manufacturer: 'Boeing',
    weight: 145_992,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Midwest Airlines',
    iata: '',
    icao: 'MEP',
    agreementNumber: 'MIDWE001',
    makeModel: 'MD80-0',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: 'MW MD-80 804-0',
    manufacturer: 'Boeing',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: 'TAG-727-200-0',
    manufacturer: 'Boeing',
    weight: 153_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: '767-300ER',
    manufacturer: 'Boeing',
    weight: 290_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: '727-100-0',
    manufacturer: 'Boeing',
    weight: 142_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: 'DC8-71',
    manufacturer: 'McDonnell Douglas',
    weight: 258_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: 'CHB727-200A-0',
    manufacturer: 'Boeing',
    weight: 160_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 210_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: 'A300-600',
    manufacturer: 'Airbus',
    weight: 315_920,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: 'BM B-727-200-0',
    manufacturer: 'Boeing',
    weight: 154_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: 'B747-100-0',
    manufacturer: 'Boeing',
    weight: 585_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: 'DC8-73F',
    manufacturer: 'McDonnell Douglas',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Miscellaneous Charters pre ABM',
    iata: '',
    icao: '',
    agreementNumber: 'MISCH001',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 128_000,
    capacity: 140,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Mountain Air Cargo',
    iata: '',
    icao: 'MTN',
    agreementNumber: 'MOUNT001',
    makeModel: '208-B',
    manufacturer: 'Cessna',
    weight: 8_500,
    capacity: 1,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'National Air Cargo Group LTD',
    iata: 'N8',
    icao: 'NCR',
    agreementNumber: 'NATIO001',
    makeModel: 'DC8-73F',
    manufacturer: 'McDonnell Douglas',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'National Air Cargo Group LTD',
    iata: 'N8',
    icao: 'NCR',
    agreementNumber: 'NATIO001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'National Air Cargo Group LTD - DHL',
    iata: 'N8',
    icao: 'NCR',
    agreementNumber: 'NATIO002',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'National Air Cargo Group LTD - DHL',
    iata: 'N8',
    icao: 'NCR',
    agreementNumber: 'NATIO002',
    makeModel: '747-400 9',
    manufacturer: 'Boeing',
    weight: 762_985,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Nippon Cargo Airlines-DHL',
    iata: 'KZ',
    icao: 'NCA',
    agreementNumber: 'NIPPO001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DMTNN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Nolinor Aviation - DHL Carrier',
    iata: '',
    icao: 'NRL',
    agreementNumber: 'NOLIN001',
    makeModel: '737-200F',
    manufacturer: 'Boeing',
    weight: 115_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Nolinor Aviation - DHL Carrier',
    iata: '',
    icao: 'NRL',
    agreementNumber: 'NOLIN001',
    makeModel: '737-200F',
    manufacturer: 'Boeing',
    weight: 115_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Nolinor Aviation - DHL Carrier',
    iata: '',
    icao: 'NRL',
    agreementNumber: 'NOLIN001',
    makeModel: 'CV-580-0',
    manufacturer: 'Convair',
    weight: 52_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Nolinor Aviation - DHL Carrier',
    iata: '',
    icao: 'NRL',
    agreementNumber: 'NOLIN001',
    makeModel: 'CV-580-0',
    manufacturer: 'Convair',
    weight: 52_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'North American Airlines',
    iata: 'NA',
    icao: 'NAO',
    agreementNumber: 'NORTHA001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 210_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Northern Air Cargo - DHL',
    iata: 'NC',
    icao: 'NAC',
    agreementNumber: 'NORTHER001',
    makeModel: '737',
    manufacturer: 'Boeing',
    weight: 119_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Northwest Airlines, Inc.',
    iata: 'NW',
    icao: 'NWA',
    agreementNumber: 'NORTH001',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 224_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Northwest Airlines, Inc.',
    iata: 'NW',
    icao: 'NWA',
    agreementNumber: 'NORTH001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Northwest Airlines, Inc.',
    iata: 'NW',
    icao: 'NWA',
    agreementNumber: 'NORTH001',
    makeModel: 'A320',
    manufacturer: 'Airbus',
    weight: 141_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Northwest Airlines, Inc.',
    iata: 'NW',
    icao: 'NWA',
    agreementNumber: 'NORTH001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Northwest Airlines, Inc.',
    iata: 'NW',
    icao: 'NWA',
    agreementNumber: 'NORTH001',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 181_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Northwest Airlines, Inc.',
    iata: 'NW',
    icao: 'NWA',
    agreementNumber: 'NORTH001',
    makeModel: 'DC-9',
    manufacturer: 'McDonnell Douglas',
    weight: 98_840,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Northwest Airlines, Inc.',
    iata: 'NW',
    icao: 'NWA',
    agreementNumber: 'NORTH001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 126_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Northwest Airlines, Inc.',
    iata: 'NW',
    icao: 'NWA',
    agreementNumber: 'NORTH001',
    makeModel: 'DC-9-31B',
    manufacturer: 'McDonnell Douglas',
    weight: 99_000,
    capacity: 1,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Northwest Airlines, Inc.',
    iata: 'NW',
    icao: 'NWA',
    agreementNumber: 'NORTH001',
    makeModel: 'A330',
    manufacturer: 'Airbus',
    weight: 412_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Northwest Airlines, Inc.',
    iata: 'NW',
    icao: 'NWA',
    agreementNumber: 'NORTH001',
    makeModel: 'A319-114-0',
    manufacturer: 'Airbus',
    weight: 134_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Omni Air International',
    iata: 'OY',
    icao: 'OAE',
    agreementNumber: 'OMNIA001',
    makeModel: 'B767-2',
    manufacturer: 'Boeing',
    weight: 442_360,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Omni Air International',
    iata: 'OY',
    icao: 'OAE',
    agreementNumber: 'OMNIA001',
    makeModel: '767-2',
    manufacturer: 'Boeing',
    weight: 395_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Omni Air International',
    iata: 'OY',
    icao: 'OAE',
    agreementNumber: 'OMNIA001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'OneJet, Inc.',
    iata: 'J1',
    icao: '',
    agreementNumber: 'ONEJET001',
    makeModel: 'Hawker-800XP',
    manufacturer: 'Beechcraft',
    weight: 23_350,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'OneJet, Inc.',
    iata: 'J1',
    icao: '',
    agreementNumber: 'ONEJET001',
    makeModel: 'Hawker-400',
    manufacturer: 'Beechcraft',
    weight: 14_535,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'OneJet, Inc.',
    iata: 'J1',
    icao: '',
    agreementNumber: 'ONEJET001',
    makeModel: 'LR-35-0',
    manufacturer: 'Lear',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 20,
  },
  {
    airline: 'OneJet, Inc.',
    iata: 'J1',
    icao: '',
    agreementNumber: 'ONEJET001',
    makeModel: 'Westwind',
    manufacturer: 'Unknown',
    weight: 19_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 30,
  },
  {
    airline: 'Orange Air',
    iata: '',
    icao: 'ORN',
    agreementNumber: 'ORANG001',
    makeModel: 'MD82',
    manufacturer: 'McDonnell Douglas',
    weight: 130_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Orange Air',
    iata: '',
    icao: 'ORN',
    agreementNumber: 'ORANG001',
    makeModel: 'MD83-N926AV',
    manufacturer: 'McDonnell Douglas',
    weight: 160_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Orange Air',
    iata: '',
    icao: 'ORN',
    agreementNumber: 'ORANG001',
    makeModel: 'MD-83',
    manufacturer: 'McDonnell Douglas',
    weight: 139_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Orange Air',
    iata: '',
    icao: 'ORN',
    agreementNumber: 'ORANG001',
    makeModel: 'MD82',
    manufacturer: 'McDonnell Douglas',
    weight: 130_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'Pace Airlines',
    iata: 'Y5',
    icao: 'PCE',
    agreementNumber: 'PACEA001',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 116_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Pace Airlines',
    iata: 'Y5',
    icao: 'PCE',
    agreementNumber: 'PACEA001',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 184,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Paradigm Air',
    iata: '',
    icao: 'PMM',
    agreementNumber: 'PARADI001',
    makeModel: '727-200',
    manufacturer: 'Boeing',
    weight: 154_500,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Park West Airlines-DHL',
    iata: '',
    icao: '',
    agreementNumber: 'PARKW001',
    makeModel: 'SWM',
    manufacturer: 'Swearingen Fairchild',
    weight: 14_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Piedmont Airlines, Inc.-American Airlines',
    iata: 'PT',
    icao: 'PDT',
    agreementNumber: 'PIEDM005',
    makeModel: 'ETS-145 2',
    manufacturer: 'Embraer',
    weight: 43_650,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Pinnacle Airlines Inc.-Delta Connection',
    iata: '9E',
    icao: 'FLG',
    agreementNumber: 'PINNA001',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Pinnacle Airlines Inc.-Delta Connection',
    iata: '9E',
    icao: 'FLG',
    agreementNumber: 'PINNA001',
    makeModel: 'CXJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Pinnacle Airlines Inc.-NW Airlink',
    iata: '9E',
    icao: 'FLG',
    agreementNumber: 'PINNA001',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Pinnacle Airlines Inc.-NW Airlink',
    iata: '9E',
    icao: 'FLG',
    agreementNumber: 'PINNA001',
    makeModel: 'CXJ-200',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Polar Air Cargo-DHL',
    iata: 'PO',
    icao: 'PAC',
    agreementNumber: 'POLAR001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Polar Air Cargo-DHL',
    iata: 'PO',
    icao: 'PAC',
    agreementNumber: 'POLAR001',
    makeModel: '747-200-0',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Polar Air Cargo-DHL',
    iata: 'PO',
    icao: 'PAC',
    agreementNumber: 'POLAR001',
    makeModel: '747-200-0',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Polar Air Cargo-DHL',
    iata: 'PO',
    icao: 'PAC',
    agreementNumber: 'POLAR001',
    makeModel: '747F A-0',
    manufacturer: 'Boeing',
    weight: 630_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Polar Air Cargo-DHL',
    iata: 'PO',
    icao: 'PAC',
    agreementNumber: 'POLAR001',
    makeModel: '747-400 9',
    manufacturer: 'Boeing',
    weight: 762_985,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Polar Air Cargo-DHL',
    iata: 'PO',
    icao: 'PAC',
    agreementNumber: 'POLAR001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 272_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Polar Air Cargo-DHL',
    iata: 'PO',
    icao: 'PAC',
    agreementNumber: 'POLAR001',
    makeModel: '747F A-0',
    manufacturer: 'Boeing',
    weight: 630_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Polar Air Cargo-DHL',
    iata: 'PO',
    icao: 'PAC',
    agreementNumber: 'POLAR001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Polar Air Cargo-DHL',
    iata: 'PO',
    icao: 'PAC',
    agreementNumber: 'POLAR001',
    makeModel: '747-400 9',
    manufacturer: 'Boeing',
    weight: 762_985,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'PSA Airlines, Inc.-American Airlines',
    iata: 'OH',
    icao: 'JIA',
    agreementNumber: 'PSAAI001',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'PSA Airlines, Inc.-American Airlines',
    iata: 'OH',
    icao: 'JIA',
    agreementNumber: 'PSAAI001',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 73_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'PSA Airlines, Inc.-American Airlines',
    iata: 'OH',
    icao: 'JIA',
    agreementNumber: 'PSAAI001',
    makeModel: 'CR9-0',
    manufacturer: 'Bombardier',
    weight: 75_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'PSA Airlines, Inc.-American Airlines',
    iata: 'OH',
    icao: 'JIA',
    agreementNumber: 'PSAAI001',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'PSA Airlines, Inc.-American Airlines',
    iata: 'OH',
    icao: 'JIA',
    agreementNumber: 'PSAAI001',
    makeModel: 'CR 700-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Repbulic-AA',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB004',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_950,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Repbulic-AA',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB004',
    makeModel: 'EMB-175 2',
    manufacturer: 'Embraer',
    weight: 75_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airline-United Express',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB003',
    makeModel: 'EMB-170-0',
    manufacturer: 'Embraer',
    weight: 72_312,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airline-United Express',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB003',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airways-Charter',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB001',
    makeModel: 'ERJ-170',
    manufacturer: 'Embraer',
    weight: 72_310,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airways-Charter',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB001',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airways-Charter',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB001',
    makeModel: 'EMB-190 2',
    manufacturer: 'Embraer',
    weight: 98_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airways-Charter',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB001',
    makeModel: 'EMB-190',
    manufacturer: 'Embraer',
    weight: 97_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airways-US Airways',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB002',
    makeModel: 'EMB-170',
    manufacturer: 'Embraer',
    weight: 72_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airways-US Airways',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB002',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_950,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airways-US Airways',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB002',
    makeModel: 'EMB-170-0',
    manufacturer: 'Embraer',
    weight: 72_312,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airways-US Airways',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB002',
    makeModel: 'CR9-0',
    manufacturer: 'Bombardier',
    weight: 75_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airways-US Airways',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB002',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 73_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airways-US Airways',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB002',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic Airways-US Airways',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB002',
    makeModel: 'EMB-190',
    manufacturer: 'Embraer',
    weight: 97_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic-DL',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB005',
    makeModel: 'EC0',
    manufacturer: 'Embraer',
    weight: 72_311,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Republic-DL',
    iata: 'YX',
    icao: 'RPA',
    agreementNumber: 'REPUB005',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'Royal Air Charter-DHL',
    iata: '',
    icao: 'RAX',
    agreementNumber: 'ROYAL001',
    makeModel: 'LR-35-0',
    manufacturer: 'Lear',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Royal Air Charter-DHL',
    iata: '',
    icao: 'RAX',
    agreementNumber: 'ROYAL001',
    makeModel: 'Dassault Falcon-2',
    manufacturer: 'Falcon',
    weight: 16_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Shuttle America Corporation - United Express',
    iata: 'S5',
    icao: 'TCF',
    agreementNumber: 'SHUTT002',
    makeModel: 'ERJ-170',
    manufacturer: 'Embraer',
    weight: 72_310,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Shuttle America Corporation - United Express',
    iata: 'S5',
    icao: 'TCF',
    agreementNumber: 'SHUTT002',
    makeModel: 'EMB-170-0',
    manufacturer: 'Embraer',
    weight: 72_312,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Shuttle America Corporation -Delta Connection',
    iata: 'S5',
    icao: 'TCF',
    agreementNumber: 'SHUTT001',
    makeModel: 'ERJ-170',
    manufacturer: 'Embraer',
    weight: 72_310,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Shuttle America Corporation -Delta Connection',
    iata: 'S5',
    icao: 'TCF',
    agreementNumber: 'SHUTT001',
    makeModel: 'EMB-170-0',
    manufacturer: 'Embraer',
    weight: 72_312,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Shuttle America Corporation -Delta Connection',
    iata: 'S5',
    icao: 'TCF',
    agreementNumber: 'SHUTT001',
    makeModel: 'EMB-70',
    manufacturer: 'Embraer',
    weight: 72_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Shuttle America Corporation -Delta Connection',
    iata: 'S5',
    icao: 'TCF',
    agreementNumber: 'SHUTT001',
    makeModel: 'EC0',
    manufacturer: 'Embraer',
    weight: 72_311,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Shuttle America Corporation -Delta Connection',
    iata: 'S5',
    icao: 'TCF',
    agreementNumber: 'SHUTT001',
    makeModel: 'E45-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Shuttle America Corporation -Delta Connection',
    iata: 'S5',
    icao: 'TCF',
    agreementNumber: 'SHUTT001',
    makeModel: 'EMB-75',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Shuttle America Corporation -Delta Connection',
    iata: 'S5',
    icao: 'TCF',
    agreementNumber: 'SHUTT001',
    makeModel: 'E45-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Silk Way West - DHL',
    iata: '7L',
    icao: 'AZG',
    agreementNumber: 'SILKWA001',
    makeModel: '747-400 9',
    manufacturer: 'Boeing',
    weight: 762_985,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'Singapore Airlines Cargo',
    iata: 'SQ',
    icao: 'SIA',
    agreementNumber: 'SINGA001',
    makeModel: '747-400F',
    manufacturer: 'Boeing',
    weight: 665_992,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Singapore Airlines Cargo',
    iata: 'SQ',
    icao: 'SIA',
    agreementNumber: 'SINGA001',
    makeModel: '747-400F',
    manufacturer: 'Boeing',
    weight: 665_992,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Singapore Airlines Cargo',
    iata: 'SQ',
    icao: 'SIA',
    agreementNumber: 'SINGA001',
    makeModel: '747-400 10',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Singapore Airlines-DHL',
    iata: 'SQ',
    icao: 'SIA',
    agreementNumber: 'SINGA002',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Sky King, Inc.',
    iata: '',
    icao: 'SGB',
    agreementNumber: 'SKYKI001',
    makeModel: '737-200',
    manufacturer: 'Boeing',
    weight: 103_000,
    capacity: 134,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Sky West Airlines - Continental',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE005',
    makeModel: 'CRJ7-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Sky West Airlines - Continental',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE005',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Sky West Airlines - Continental',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE005',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'Sky West Airlines, Inc. - Air Tran',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE004',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Skylink Express, Inc.',
    iata: '',
    icao: 'SLQ',
    agreementNumber: 'SKYLI001',
    makeModel: 'B1900-UB',
    manufacturer: 'Beechcraft',
    weight: 9_338,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Skyservice Airlines',
    iata: '5G',
    icao: 'SSV',
    agreementNumber: 'SKYSE001',
    makeModel: 'A320-0',
    manufacturer: 'Airbus',
    weight: 169_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc - AK',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE006',
    makeModel: 'CRJ70-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE002',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE002',
    makeModel: 'CRJ 6-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE002',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'CRJ 6-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'CR9-0',
    manufacturer: 'Bombardier',
    weight: 75_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'CRJ-70',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 70,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE007',
    makeModel: 'CRJ-50-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE007',
    makeModel: 'CS-ADJ',
    manufacturer: 'Bombardier',
    weight: 87_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE007',
    makeModel: 'CRJ-70-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.-DC',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.-DC',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'CRJ 6-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.-DC',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'CR9-0',
    manufacturer: 'Bombardier',
    weight: 75_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.-DC',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'CRJ-900',
    manufacturer: 'Bombardier',
    weight: 75_100,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.-DC',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'CRJ-70',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 70,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.-DC',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE003',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.-United Express',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE002',
    makeModel: 'CRJ-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 50,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.-United Express',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE002',
    makeModel: 'CRJ 6-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines, Inc.-United Express',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE002',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines-AA',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE007',
    makeModel: 'CRJ-50-0',
    manufacturer: 'Bombardier',
    weight: 47_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines-AA',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE007',
    makeModel: 'CS-ADJ',
    manufacturer: 'Bombardier',
    weight: 87_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'SkyWest Airlines-AA',
    iata: 'OO',
    icao: 'SKW',
    agreementNumber: 'SKYWE007',
    makeModel: 'CRJ-70-0',
    manufacturer: 'Bombardier',
    weight: 67_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air Freight, Inc. - Amazon',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTHA002',
    makeModel: '737-5',
    manufacturer: 'Boeing',
    weight: 174_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air Freight, Inc. -DHL',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH001',
    makeModel: '777-FS',
    manufacturer: 'Boeing',
    weight: 575_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air Freight, Inc. -DHL',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH001',
    makeModel: '777-FS',
    manufacturer: 'Boeing',
    weight: 575_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air Freight, Inc. -DHL',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH001',
    makeModel: '777-200-0',
    manufacturer: 'Boeing',
    weight: 460_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air Freight, Inc. -DHL',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH001',
    makeModel: '777-200-0',
    manufacturer: 'Boeing',
    weight: 460_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air Freight, Inc. -DHL',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air Freight, Inc. -DHL',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH001',
    makeModel: '737',
    manufacturer: 'Boeing',
    weight: 119_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air Freight, Inc. -DHL',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH001',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air Freight, Inc. -DHL',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH001',
    makeModel: '737',
    manufacturer: 'Boeing',
    weight: 119_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air Freight, Inc. -DHL',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH001',
    makeModel: '73P',
    manufacturer: 'Boeing',
    weight: 121_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air Freight, Inc. -DHL',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH001',
    makeModel: '73P',
    manufacturer: 'Boeing',
    weight: 121_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air, Inc.',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH002',
    makeModel: '777-FS',
    manufacturer: 'Boeing',
    weight: 575_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air, Inc.',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH002',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air, Inc.',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH002',
    makeModel: '747-400 10',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air, Inc.',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH002',
    makeModel: '777-FS',
    manufacturer: 'Boeing',
    weight: 575_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air, Inc.',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH002',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southern Air, Inc.',
    iata: '',
    icao: 'SOO',
    agreementNumber: 'SOUTH002',
    makeModel: 'B737-400-0',
    manufacturer: 'Boeing',
    weight: 121_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southwest Airlines',
    iata: 'WN',
    icao: 'SWA',
    agreementNumber: 'SOUTHW001',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 121_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southwest Airlines',
    iata: 'WN',
    icao: 'SWA',
    agreementNumber: 'SOUTHW001',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 128_000,
    capacity: 140,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southwest Airlines',
    iata: 'WN',
    icao: 'SWA',
    agreementNumber: 'SOUTHW002',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 114_000,
    capacity: 134,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southwest Airlines',
    iata: 'WN',
    icao: 'SWA',
    agreementNumber: 'SOUTHW002',
    makeModel: '737-800',
    manufacturer: 'Boeing',
    weight: 144_000,
    capacity: 154,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southwest Airlines',
    iata: 'WN',
    icao: 'SWA',
    agreementNumber: 'SOUTHW002',
    makeModel: '737-Max8',
    manufacturer: 'Boeing',
    weight: 150_800,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Southwest Airlines',
    iata: 'WN',
    icao: 'SWA',
    agreementNumber: 'SOUTHW002',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 128_000,
    capacity: 140,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Spirit Airlines',
    iata: 'NK',
    icao: 'NKS',
    agreementNumber: 'SPIRIT001',
    makeModel: 'A319',
    manufacturer: 'Airbus',
    weight: 137_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Spirit Airlines',
    iata: 'NK',
    icao: 'NKS',
    agreementNumber: 'SPIRIT001',
    makeModel: 'Airbus-320',
    manufacturer: 'Airbus',
    weight: 145_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Suburban Air Freight - DHL Carrier',
    iata: '',
    icao: 'SUB',
    agreementNumber: 'SUBUR001',
    makeModel: '1900-D',
    manufacturer: 'Beechcraft',
    weight: 16_100,
    capacity: 19,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Suburban Air Freight - DHL Carrier',
    iata: '',
    icao: 'SUB',
    agreementNumber: 'SUBUR001',
    makeModel: 'BES',
    manufacturer: 'Beechcraft',
    weight: 22_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Suburban Air Freight - DHL Carrier',
    iata: '',
    icao: 'SUB',
    agreementNumber: 'SUBUR001',
    makeModel: 'C208-CNC',
    manufacturer: 'Cessna',
    weight: 15_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Suburban Air Freight - DHL Carrier',
    iata: '',
    icao: 'SUB',
    agreementNumber: 'SUBUR001',
    makeModel: '1900-D',
    manufacturer: 'Beechcraft',
    weight: 16_100,
    capacity: 19,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Suburban Air Freight - DHL Carrier',
    iata: '',
    icao: 'SUB',
    agreementNumber: 'SUBUR001',
    makeModel: 'BE-99',
    manufacturer: 'Beechcraft',
    weight: 10_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Suncountry-MN Airlines Inc',
    iata: 'SY',
    icao: 'SCX',
    agreementNumber: 'SUNCO001',
    makeModel: '737-900',
    manufacturer: 'Boeing',
    weight: 146_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Suncountry-MN Airlines Inc',
    iata: 'SY',
    icao: 'SCX',
    agreementNumber: 'SUNCO001',
    makeModel: '737-800-0',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Suncountry-MN Airlines Inc',
    iata: 'SY',
    icao: 'SCX',
    agreementNumber: 'SUNCO001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Suncountry-MN Airlines Inc',
    iata: 'SY',
    icao: 'SCX',
    agreementNumber: 'SUNCO001',
    makeModel: '737-700',
    manufacturer: 'Boeing',
    weight: 129_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Suncountry-MN Airlines Inc',
    iata: 'SY',
    icao: 'SCX',
    agreementNumber: 'SUNCO001',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Suncountry-MN Airlines Inc',
    iata: 'SY',
    icao: 'SCX',
    agreementNumber: 'SUNCO001',
    makeModel: 'B737-400-0',
    manufacturer: 'Boeing',
    weight: 121_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Sunwing Airlines',
    iata: 'WG',
    icao: 'SWG',
    agreementNumber: 'SUNWI001',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Sunwing Airlines',
    iata: 'WG',
    icao: 'SWG',
    agreementNumber: 'SUNWI001',
    makeModel: '737-800-0',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFTA001',
    makeModel: 'B737-400',
    manufacturer: 'Boeing',
    weight: 120_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFTA001',
    makeModel: '737-400-0',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFTA001',
    makeModel: 'B737-400',
    manufacturer: 'Boeing',
    weight: 142_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFTA001',
    makeModel: '737-400 5',
    manufacturer: 'Boeing',
    weight: 130_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFTA001',
    makeModel: 'B737-400-0',
    manufacturer: 'Boeing',
    weight: 121_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFTA001',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFTA001',
    makeModel: 'B737-400-0',
    manufacturer: 'Boeing',
    weight: 121_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFTA001',
    makeModel: '737-200F',
    manufacturer: 'Boeing',
    weight: 115_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC-Vac Exp',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFT001',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC-Vac Exp',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFT001',
    makeModel: 'B737-400-0',
    manufacturer: 'Boeing',
    weight: 121_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC-Vac Exp',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFT001',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 114_000,
    capacity: 134,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC-Vac Exp',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFT001',
    makeModel: '737-2',
    manufacturer: 'Boeing',
    weight: 112_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC-Vac Exp',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFT001',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 114_000,
    capacity: 134,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC-Vac Exp',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFT001',
    makeModel: 'B737-400-0',
    manufacturer: 'Boeing',
    weight: 121_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC-Vac Exp',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFT001',
    makeModel: '767-300 3',
    manufacturer: 'Boeing',
    weight: 299_829,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Swift Air LLC-Vac Exp',
    iata: 'WQ',
    icao: 'SWQ',
    agreementNumber: 'SWIFT001',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Trans States Airlines, Inc.',
    iata: 'AX',
    icao: 'LOF',
    agreementNumber: 'TRANS001',
    makeModel: 'EMB-145-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'Trans States Airlines, Inc. dba-United Express',
    iata: 'AX',
    icao: 'LOF',
    agreementNumber: 'TRANS004',
    makeModel: 'EMB-145',
    manufacturer: 'Embraer',
    weight: 41_226,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Trans States Airlines, Inc. dba-United Express',
    iata: 'AX',
    icao: 'LOF',
    agreementNumber: 'TRANS004',
    makeModel: 'E45-0',
    manufacturer: 'Embraer',
    weight: 42_549,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Trans States Airlines, Inc. dba-United Express',
    iata: 'AX',
    icao: 'LOF',
    agreementNumber: 'TRANS004',
    makeModel: 'EMB-145-XR',
    manufacturer: 'Embraer',
    weight: 44_092,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Trans States Airlines, Inc.-American Network',
    iata: 'AX',
    icao: 'LOF',
    agreementNumber: 'TRANS002',
    makeModel: 'ETS-145',
    manufacturer: 'Embraer',
    weight: 42_600,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Trans States Airlines, Inc.-American Network',
    iata: 'AX',
    icao: 'LOF',
    agreementNumber: 'TRANS002',
    makeModel: 'ETS-145 2',
    manufacturer: 'Embraer',
    weight: 43_650,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Trego-Dugan Aviation',
    iata: '',
    icao: '',
    agreementNumber: 'TREGO003',
    makeModel: 'B767-4',
    manufacturer: 'Boeing',
    weight: 136_078,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ultimate Air Charters',
    iata: 'UE',
    icao: 'UJC',
    agreementNumber: 'ULTIM001',
    makeModel: '328-J',
    manufacturer: 'Fairchild Dornier',
    weight: 34_254,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ultimate Air Charters',
    iata: 'UE',
    icao: 'UJC',
    agreementNumber: 'ULTIM002',
    makeModel: '310',
    manufacturer: 'Fairchild Dornier',
    weight: 32_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ultimate Air Charters',
    iata: 'UE',
    icao: 'UJC',
    agreementNumber: 'ULTIM002',
    makeModel: '310',
    manufacturer: 'Fairchild Dornier',
    weight: 32_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ultimate Air Charters',
    iata: 'UE',
    icao: 'UJC',
    agreementNumber: 'ULTIM002',
    makeModel: 'EMB-Legacy',
    manufacturer: 'Embraer',
    weight: 40_785,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ultimate Air Charters',
    iata: 'UE',
    icao: 'UJC',
    agreementNumber: 'ULTIM005',
    makeModel: 'EMB 135-0',
    manufacturer: 'Embraer',
    weight: 40_785,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ultimate Air Charters',
    iata: 'UE',
    icao: 'UJC',
    agreementNumber: 'ULTIM005',
    makeModel: '310',
    manufacturer: 'Fairchild Dornier',
    weight: 32_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Ultimate Air Charters',
    iata: 'UE',
    icao: 'UJC',
    agreementNumber: 'ULTIM005',
    makeModel: '310',
    manufacturer: 'Fairchild Dornier',
    weight: 32_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'A319131-0',
    manufacturer: 'Airbus',
    weight: 137_787,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'B-737-322',
    manufacturer: 'Boeing',
    weight: 114_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'B-737-522',
    manufacturer: 'Boeing',
    weight: 110_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'A320-232-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'B764-UA',
    manufacturer: 'Boeing',
    weight: 349_360,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: 'EMB-145-XR',
    manufacturer: 'Embraer',
    weight: 44_092,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: '767-400',
    manufacturer: 'Boeing',
    weight: 350_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: '777-200-0',
    manufacturer: 'Boeing',
    weight: 460_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: '737-900 3',
    manufacturer: 'Boeing',
    weight: 157_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: 'A321-2',
    manufacturer: 'Airbus',
    weight: 166_400,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: 'A320-4',
    manufacturer: 'Airbus',
    weight: 169_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: 'B787-8',
    manufacturer: 'Boeing',
    weight: 380_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: 'EMB-175',
    manufacturer: 'Embraer',
    weight: 74_957,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: '737-800 L',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: '737-900 3',
    manufacturer: 'Boeing',
    weight: 157_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: '757-300',
    manufacturer: 'Boeing',
    weight: 224_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: '737-900 4',
    manufacturer: 'Boeing',
    weight: 157_900,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: '727R-0',
    manufacturer: 'Boeing',
    weight: 154_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED007',
    makeModel: 'B757-300',
    manufacturer: 'Boeing',
    weight: 273_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '767-400',
    manufacturer: 'Boeing',
    weight: 350_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '737-800',
    manufacturer: 'Boeing',
    weight: 146_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '757-300',
    manufacturer: 'Boeing',
    weight: 270_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'B753-UA',
    manufacturer: 'Boeing',
    weight: 223_520,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '737-900',
    manufacturer: 'Boeing',
    weight: 155_760,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '737-900 3',
    manufacturer: 'Boeing',
    weight: 157_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'XRJ-0',
    manufacturer: 'Embraer',
    weight: 18_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '757-300',
    manufacturer: 'Boeing',
    weight: 224_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'A320',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'A319',
    manufacturer: 'Airbus',
    weight: 137_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '737-800-0',
    manufacturer: 'Boeing',
    weight: 146_300,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '757-300',
    manufacturer: 'Boeing',
    weight: 224_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '767-0',
    manufacturer: 'Boeing',
    weight: 320_000,
    capacity: 160,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'B767-300-0',
    manufacturer: 'Boeing',
    weight: 300_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: 'A319-131',
    manufacturer: 'Airbus',
    weight: 137_987,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'United Airlines, Inc.',
    iata: 'UA',
    icao: 'UAL',
    agreementNumber: 'UNITED001',
    makeModel: '777-222A',
    manufacturer: 'Boeing',
    weight: 445_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: 'B747-100-0',
    manufacturer: 'Boeing',
    weight: 585_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: 'DC-8-73F-0',
    manufacturer: 'Boeing',
    weight: 275_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: 'A300-600',
    manufacturer: 'Airbus',
    weight: 315_920,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: 'MD11',
    manufacturer: 'Boeing',
    weight: 491_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: '747-400 10',
    manufacturer: 'Boeing',
    weight: 666_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: '74Y',
    manufacturer: 'Boeing',
    weight: 652_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: '747-800F',
    manufacturer: 'Boeing',
    weight: 763_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: 'MD-11 F 2',
    manufacturer: 'McDonnell Douglas',
    weight: 491_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: '727R-0',
    manufacturer: 'Boeing',
    weight: 154_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: 'B767-0',
    manufacturer: 'Boeing',
    weight: 283_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: '767-300',
    manufacturer: 'Boeing',
    weight: 326_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: 'A300-600',
    manufacturer: 'Airbus',
    weight: 315_920,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: '757-200',
    manufacturer: 'Boeing',
    weight: 210_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICARG',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: 'B767-300-0',
    manufacturer: 'Boeing',
    weight: 300_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'UPS Airlines',
    iata: '5X',
    icao: 'UPS',
    agreementNumber: 'UNITEDP002',
    makeModel: '767-200 2',
    manufacturer: 'Boeing',
    weight: 276_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCARN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'US Airways (Non-Signatory Mainline)',
    iata: 'US',
    icao: 'AWE',
    agreementNumber: 'USAIR003',
    makeModel: 'A321-0',
    manufacturer: 'Airbus',
    weight: 171_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'US Airways (Non-Signatory Mainline)',
    iata: 'US',
    icao: 'AWE',
    agreementNumber: 'USAIR003',
    makeModel: 'A319',
    manufacturer: 'Airbus',
    weight: 137_700,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'US Airways (Non-Signatory Mainline)',
    iata: 'US',
    icao: 'AWE',
    agreementNumber: 'USAIR003',
    makeModel: 'A320',
    manufacturer: 'Airbus',
    weight: 142_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'US Airways (Non-Signatory Mainline)',
    iata: 'US',
    icao: 'AWE',
    agreementNumber: 'USAIR003',
    makeModel: '767-200',
    manufacturer: 'Boeing',
    weight: 278_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'US Airways (Non-Signatory Mainline)',
    iata: 'US',
    icao: 'AWE',
    agreementNumber: 'USAIR003',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'US Airways (Non-Signatory Mainline)',
    iata: 'US',
    icao: 'AWE',
    agreementNumber: 'USAIR003',
    makeModel: 'EMB-190',
    manufacturer: 'Embraer',
    weight: 97_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'US Airways (Non-Signatory Mainline)',
    iata: 'US',
    icao: 'AWE',
    agreementNumber: 'USAIR003',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 590,
  },
  {
    airline: 'US Airways, Inc.',
    iata: 'US',
    icao: 'AWE',
    agreementNumber: 'USAIR001',
    makeModel: '757-200-0',
    manufacturer: 'Boeing',
    weight: 198_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'USA Jet/Active Aero',
    iata: 'UJ',
    icao: 'JUS',
    agreementNumber: 'USAJET001',
    makeModel: 'DC-9 A',
    manufacturer: 'McDonnell Douglas',
    weight: 103_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Virgin Atlantic Airways, Ltd',
    iata: 'VS',
    icao: 'VIR',
    agreementNumber: 'VIRGI001',
    makeModel: 'A340-600',
    manufacturer: 'Airbus',
    weight: 570_991,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Virgin Atlantic Airways, Ltd',
    iata: 'VS',
    icao: 'VIR',
    agreementNumber: 'VIRGI001',
    makeModel: 'A340-600 2',
    manufacturer: 'Airbus',
    weight: 570_997,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Virgin Atlantic Airways, Ltd',
    iata: 'VS',
    icao: 'VIR',
    agreementNumber: 'VIRGI001',
    makeModel: 'A340-600 2',
    manufacturer: 'Airbus',
    weight: 570_997,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Vision Airlines',
    iata: 'V2',
    icao: 'RBY',
    agreementNumber: 'VISIO001',
    makeModel: '737-800',
    manufacturer: 'Boeing',
    weight: 144_000,
    capacity: 154,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Vision Airlines',
    iata: 'V2',
    icao: 'RBY',
    agreementNumber: 'VISIO001',
    makeModel: '737-400-0',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Vision Airlines',
    iata: 'V2',
    icao: 'RBY',
    agreementNumber: 'VISIO001',
    makeModel: '737-400',
    manufacturer: 'Boeing',
    weight: 142_998,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Vision Airlines',
    iata: 'V2',
    icao: 'RBY',
    agreementNumber: 'VISIO001',
    makeModel: '737-400',
    manufacturer: 'Boeing',
    weight: 122_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Vision Airlines',
    iata: 'V2',
    icao: 'RBY',
    agreementNumber: 'VISIO001',
    makeModel: 'MD-83',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Vision Airlines',
    iata: 'V2',
    icao: 'RBY',
    agreementNumber: 'VISIO001',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Vision Airlines',
    iata: 'V2',
    icao: 'RBY',
    agreementNumber: 'VISIO002',
    makeModel: '737-300',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Vision Airlines',
    iata: 'V2',
    icao: 'RBY',
    agreementNumber: 'VISIO002',
    makeModel: 'MD-80-0',
    manufacturer: 'McDonnell Douglas',
    weight: 130_000,
    capacity: 128,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Vision Airlines',
    iata: 'V2',
    icao: 'RBY',
    agreementNumber: 'VISIO002',
    makeModel: 'DC-9-31B',
    manufacturer: 'McDonnell Douglas',
    weight: 99_000,
    capacity: 1,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Vision Airlines',
    iata: 'V2',
    icao: 'RBY',
    agreementNumber: 'VISIO002',
    makeModel: '737-400',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Viva Aerobus',
    iata: 'VB',
    icao: 'VIV',
    agreementNumber: 'VIVAAE001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Viva Aerobus',
    iata: 'VB',
    icao: 'VIV',
    agreementNumber: 'VIVAAE001',
    makeModel: 'A320-8',
    manufacturer: 'Airbus',
    weight: 142_196,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Viva Aerobus',
    iata: 'VB',
    icao: 'VIV',
    agreementNumber: 'VIVAAE001',
    makeModel: 'A319-3',
    manufacturer: 'Airbus',
    weight: 142_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Viva Aerobus',
    iata: 'VB',
    icao: 'VIV',
    agreementNumber: 'VIVAAE001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_200,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 20,
  },
  {
    airline: 'Volaris Airlines',
    iata: 'Y4',
    icao: 'VOI',
    agreementNumber: 'VOLAR001',
    makeModel: 'A319-3',
    manufacturer: 'Airbus',
    weight: 142_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Volaris Airlines',
    iata: 'Y4',
    icao: 'VOI',
    agreementNumber: 'VOLAR001',
    makeModel: 'A-320-0',
    manufacturer: 'Airbus',
    weight: 142_198,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Volaris Airlines',
    iata: 'Y4',
    icao: 'VOI',
    agreementNumber: 'VOLAR001',
    makeModel: 'A319-3',
    manufacturer: 'Airbus',
    weight: 142_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'World Atlantic Airlines',
    iata: 'WL',
    icao: 'WAL',
    agreementNumber: 'WORLDA001',
    makeModel: 'MD-83 3',
    manufacturer: 'McDonnell Douglas',
    weight: 129_727,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'World Atlantic Airlines',
    iata: 'WL',
    icao: 'WAL',
    agreementNumber: 'WORLDA001',
    makeModel: '737-400',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 10,
  },
  {
    airline: 'World Atlantic Airlines',
    iata: 'WL',
    icao: 'WAL',
    agreementNumber: 'WORLDA001',
    makeModel: 'MD80-0',
    manufacturer: 'McDonnell Douglas',
    weight: 139_500,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 20,
  },
  {
    airline: 'WOW air ehf',
    iata: 'WW',
    icao: 'WOW',
    agreementNumber: 'WOWAI001',
    makeModel: 'A321-6',
    manufacturer: 'Airbus',
    weight: 171_520,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'WOW air ehf',
    iata: 'WW',
    icao: 'WOW',
    agreementNumber: 'WOWAI001',
    makeModel: 'A321-7',
    manufacturer: 'Airbus',
    weight: 174_606,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'IPS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Xtra Airways (TEM)',
    iata: 'XP',
    icao: 'CXP',
    agreementNumber: 'TEMEN001',
    makeModel: '737-400-0',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DPSN',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Xtra Airways (TEM)',
    iata: 'XP',
    icao: 'CXP',
    agreementNumber: 'TEMEN002',
    makeModel: '737-400 XTRA',
    manufacturer: 'Boeing',
    weight: 155_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'ICHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
  {
    airline: 'Xtra Airways (TEM)',
    iata: 'XP',
    icao: 'CXP',
    agreementNumber: 'TEMEN002',
    makeModel: '737-400',
    manufacturer: 'Boeing',
    weight: 124_000,
    capacity: 0,
    parkingGroup: 'Deferred Landing Fee Cred',
    type: 'DCHNS',
    valid: 'Yes',
    sortOrder: 0,
  },
];

const unknownAircraft: AircraftWeight = {
  airline: '',
  iata: '',
  icao: '',
  agreementNumber: '',
  makeModel: '',
  manufacturer: '',
  weight: 0,
  capacity: 0,
  parkingGroup: '',
  type: '',
  valid: '',
  sortOrder: 0,
};

export function findWeight(flight: ArrivalFlight): AircraftWeight {
  const carrierCode = flight.marketingCarrier;
  const codeshareCodes = flight?.codeshares || [];
  const aircraftType = flight.aircraftType || '';
  const matchedAirlines = aircraft.filter(
    ac =>
      (ac.iata && (ac.iata === carrierCode || codeshareCodes.includes(ac.iata))) ||
      (ac.icao && (ac.icao === carrierCode || codeshareCodes.includes(ac.icao)))
  );
  let craft =
    // Exact match for actual aircraft
    matchedAirlines.find(ac => {
      return ac.makeModel === aircraftType;
    });
  if (!craft && matchedAirlines.length) {
    // Similar match for actual aircraft
    const aircraftMatches = stringSimilarity.findBestMatch(
      aircraftType,
      matchedAirlines.map(ac => ac.makeModel)
    );
    const match = maxBy([aircraftMatches], m => m.bestMatch.rating);
    const matchingIndex = match?.bestMatchIndex;

    if (matchingIndex !== undefined) {
      craft = matchedAirlines[matchingIndex];
    }
  }
  if (!craft && !matchedAirlines.length) {
    const aircraftModels = aircraft.map(ac => ac.makeModel);
    const match = stringSimilarity.findBestMatch(aircraftType, aircraftModels);
    const matchingIndex = match?.bestMatchIndex;
    if (matchingIndex !== undefined) {
      craft = aircraft[matchingIndex];
    }
  }

  return craft || unknownAircraft;
}

export default aircraft;
