import { SeriesOptionsType } from 'highcharts';

import { ChartDatum } from 'statistics/types';

import { getXAxis, getYAxis } from './axes';
import { default as getOptions } from './options';
import { default as getSeries } from './series';

export default {
  am: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'AM', chartOptions),
  },
  pm: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'PM', chartOptions),
  },
  total: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Total', chartOptions),
  },
};
