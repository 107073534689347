import { SeriesOptionsType } from 'highcharts';

import { ChartDatum } from 'statistics/types';

import { getXAxis, getYAxis } from './axes';
import { default as getOptions } from './options';
import { default as getSeries } from './series';

export default {
  bagTags: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Bag Tags Printed', chartOptions),
  },
  boardingPasses: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (data: ChartDatum[], chartOptions?: SeriesOptionsType): SeriesOptionsType =>
      getSeries(data, 'Boarding Passes Printed', chartOptions),
  },
};
