import { useAuth0 } from '@auth0/auth0-react';
import pick from 'lodash/pick';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useUpdateUserMetadata } from 'client/User';
import { track } from 'utils';

import { hideModal, showModal, useModalDispatch } from 'providers/ModalProvider';
import { updateOnboarding, useOnboardingDispatch } from 'providers/OnboardingProvider';
import { updateSettings, useSettings } from 'providers/SettingsProvider';
import { useUserData } from 'providers/UserDataProvider';

import { Icon, Menu } from 'components';
import { TriggerArgs } from 'components/Menu';

import { Avatar, Box } from 'styled';

interface Props {
  hideAvatar?: boolean;
  side?: string;
}

const UserMenu: React.FC<Props> = ({ hideAvatar, side }: Props) => {
  const { isAuthenticated, user } = useAuth0();
  const { permissions } = useUserData();
  const history = useHistory();
  const onboardingDispatch = useOnboardingDispatch();
  const modalDispatch = useModalDispatch();
  const [updateMetadata] = useUpdateUserMetadata();
  const [settingsState, settingsDispatch] = useSettings();
  const hasAvatar = user?.picture;

  // Modal dispatch doesn't exist in error/not found routes.
  // In this case, don't show user menu
  if (!modalDispatch) return null;
  const hasFeedPermission = !!permissions?.find(p => p === 'read:feed-status');
  const initialStatus = settingsState?.isUserMenuOpen ? 'open' : 'closed';

  return (
    <Menu
      side={side || 'right'}
      initialStatus={initialStatus}
      width="15.625rem"
      renderTrigger={({ status, getTriggerProps }: TriggerArgs) => {
        if (!hasAvatar) return null;
        const icon = status === 'closed' ? 'chevronDown' : 'chevronUp';
        return (
          <Box as="a" href="#0" {...getTriggerProps()} alignItems="center" display="flex">
            {!hideAvatar && <Avatar src={user?.picture} mr="xs" width="35" height="35" />}
            <Icon name={icon} size="14" />
          </Box>
        );
      }}
      options={[
        {
          label: 'Settings',
          icon: { name: 'gear' },
          action: (event: React.MouseEvent) => {
            event.preventDefault();
            settingsDispatch(
              updateSettings({
                initialSettingsTab: 0,
              })
            );
            modalDispatch(showModal('Settings'));
            track('View Settings');
          },
        },
        {
          label: hasFeedPermission ? 'Feed Status' : '',
          icon: { name: 'feedStatus' },
          action: (event: React.MouseEvent) => {
            event.preventDefault();
            modalDispatch(showModal('FeedStatus'));
            track('View Feed Status');
          },
        },
        {
          label: 'Submit Feedback',
          icon: { name: 'messageSquare' },
          action: (event: React.MouseEvent) => {
            event.preventDefault();
            modalDispatch(showModal('Feedback'));
          },
          id: 'feedback',
        },
        {
          label: 'Restart Tour',
          icon: { name: 'rotateCWW' },
          action: (event: React.MouseEvent) => {
            event.preventDefault();
            // Go to the dashboard page
            history.push('/');

            // Reset the tour
            onboardingDispatch(updateOnboarding({ stepIndex: 0, run: false }));
            settingsDispatch(updateSettings({ hasSeenTour: false }));
            updateMetadata({
              variables: {
                key: 'settings',
                value: JSON.stringify({
                  hasSeenTour: false,
                  ...pick(settingsState, ['emailAlerts', 'emailInsights', 'dashboardLayout']),
                }),
              },
            });

            // Hide other modals and open the onboarding modal
            track('Restart Tour');
            modalDispatch(hideModal());
            setTimeout(() => {
              modalDispatch(showModal('Onboarding'));
            }, 500);
          },
        },
        {
          label: isAuthenticated ? 'Log out' : 'Log in',
          icon: isAuthenticated ? { name: 'logout' } : { name: 'circle ' },
          to: isAuthenticated ? '/logout' : '/login',
        },
      ]}
    />
  );
};

export default UserMenu;
