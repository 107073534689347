import React from 'react';

import { areas, severities, statuses } from 'config/facilityConditions';

import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { Checkbox, FormField, SortByOptions } from 'components';

const FacilityConditionsOptions: React.FC = () => {
  const [state, dispatch] = useTileOptions();
  const {
    facilityConditions: { filters },
  } = state;

  function filterAreaChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const area = event.target.name;
    const checked = filters.areas.includes(area);
    let newAreas: string[] = [];

    if (checked) {
      newAreas = filters.areas.filter((a: string) => a !== area);
    } else {
      newAreas = [...filters.areas, area];
    }

    dispatch(
      updateOptions({
        facilityConditions: {
          filters: {
            ...filters,
            areas: newAreas,
          },
        },
      })
    );
  }

  function filterSeverityChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const severity = event.target.name;
    const checked = filters.severities.includes(severity);
    let newSeverities: string[] = [];

    if (checked) {
      newSeverities = filters.severities.filter((s: string) => s !== severity);
    } else {
      newSeverities = [...filters.severities, severity];
    }

    dispatch(
      updateOptions({
        facilityConditions: {
          filters: {
            ...filters,
            severities: newSeverities,
          },
        },
      })
    );
  }

  function filterStatusChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const status = event.target.name;
    const checked = filters.status.includes(status);
    let newStatuses: string[] = [];

    if (checked) {
      newStatuses = filters.status.filter((s: string) => s !== status);
    } else {
      newStatuses = [...filters.status, status];
    }

    dispatch(
      updateOptions({
        facilityConditions: {
          filters: {
            ...filters,
            status: newStatuses,
          },
        },
      })
    );
  }

  return (
    <>
      <SortByOptions
        stateKey="facilityConditions"
        options={[
          { value: 'feed', label: 'Feed' },
          { value: 'assets', label: 'Assets' },
        ]}
      />
      <FormField label="Feeds to show" type="stacked" mb="base">
        {areas.map(area => (
          <Checkbox
            key={area}
            label={area}
            name={area}
            checked={!filters.areas.length || filters.areas.includes(area)}
            onChange={filterAreaChange}
            mb="s"
          />
        ))}
      </FormField>
      <FormField label="Severities to show" type="stacked" mb="base">
        {severities.map(severity => (
          <Checkbox
            key={severity}
            label={severity}
            name={severity}
            checked={!filters.severities.length || filters.severities.includes(severity)}
            onChange={filterSeverityChange}
            mb="s"
          />
        ))}
      </FormField>
      <FormField label="Statuses to show" type="stacked" mb="base">
        {statuses.map(status => (
          <Checkbox
            key={status}
            label={status}
            name={status}
            checked={filters.status.includes(status)}
            onChange={filterStatusChange}
            mb="s"
          />
        ))}
      </FormField>
    </>
  );
};

export default FacilityConditionsOptions;
