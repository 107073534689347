import React from 'react';

import { useRemoteParking } from 'client/RemoteParking';
import { RemoteParkingSpot } from 'client/RemoteParking/types';

import { useRefreshedAt } from 'hooks';

import { SelectedTableItemProvider } from 'providers';

import { QueryCell, UpdatedAt } from 'components';

import { Box, TileContent, TileContentInner } from 'styled';

import RemoteParkingMap from './RemoteParkingMap';
import RemoteParkingTable from './RemoteParkingTable';

interface Props {
  showTable?: boolean;
  showMap?: boolean;
  mapHeight?: string;
}

const RemoteParking: React.FC<Props> = ({ showTable, showMap, mapHeight = '100%' }: Props) => {
  const { spots, error, loading } = useRemoteParking();
  const { refreshedAt } = useRefreshedAt(loading);
  if (process.env.REACT_APP_CONFIG_REMOTE_PARKING !== 'true') return null;
  const filteredSpots = spots.filter(spot => {
    const hasLatitude = spot.asset.latitude;
    const hasLongitude = spot.asset.longitude;
    const hasEquipmentNumber = spot.asset.equipmentNumber; // Parking spot

    return hasLatitude && hasLongitude && hasEquipmentNumber;
  });

  function render(): JSX.Element {
    if (showMap && !showTable) {
      return (
        <>
          <Map mapHeight={mapHeight} spots={filteredSpots} />
          <RefreshedAt refreshedAt={refreshedAt} />
        </>
      );
    }

    if (!showMap && showTable) {
      return (
        <>
          <RemoteParkingTable spots={filteredSpots} />
          <RefreshedAt refreshedAt={refreshedAt} />
        </>
      );
    }

    return (
      <SelectedTableItemProvider>
        <Box
          display={{ md: 'grid' }}
          gridTemplateColumns={{ md: '0.75fr 1.25fr', lg: '0.75fr 1.25fr' }}
        >
          <Box position="relative">
            <Map
              mapHeight={{ _: mapHeight, md: '100%' }}
              mapMinHeight="750px"
              spots={filteredSpots}
            />
            <RefreshedAt refreshedAt={refreshedAt} />
          </Box>
          <RemoteParkingTable spots={filteredSpots} />
        </Box>
      </SelectedTableItemProvider>
    );
  }

  return (
    <QueryCell content="Remote Parking" loading={loading} error={error} data={spots}>
      {render()}
    </QueryCell>
  );
};

interface MapProps {
  mapHeight?: string | object;
  mapMinHeight?: string;
  spots: RemoteParkingSpot[];
}

const Map: React.FC<MapProps> = ({ mapHeight, mapMinHeight, spots }: MapProps) => (
  <TileContent overflowY="hidden">
    <TileContentInner>
      <Box height={mapHeight} minHeight={mapMinHeight} width="100%">
        <RemoteParkingMap spots={spots} />
      </Box>
    </TileContentInner>
  </TileContent>
);

interface UpdatedAtProps {
  refreshedAt: Date;
}

const RefreshedAt: React.FC<UpdatedAtProps> = ({ refreshedAt }: UpdatedAtProps) => (
  <Box bottom="0" left="0" position="absolute" right="0" zIndex={800}>
    <UpdatedAt date={refreshedAt} />
  </Box>
);

export default RemoteParking;
