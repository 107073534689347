import React from 'react';

import { SortByOptions } from 'components';

const CapacitySummaryOptions: React.FC = () => {
  return (
    <>
      <SortByOptions
        stateKey="capacitySummary"
        options={[
          { value: 'name', label: 'Airline' },
          { value: 'bookings', label: 'Status' },
          { value: 'load', label: 'Load' },
        ]}
      />
    </>
  );
};

export default CapacitySummaryOptions;
