import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  opacity,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';

const system = compose(
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  opacity,
  position,
  shadow,
  space,
  typography
);

export default system;
