import React from 'react';

import { CANCELED, DELAY_THRESHOLD } from 'data/Flights/constants';

import { Icon, Tooltip } from 'components';

import { Box } from 'styled';

interface FlightStatusTextProps {
  delayTime: string;
  delayed: boolean;
  delayMinutes: number;
  statusColor: string;
  statusText: string;
}

export const FlightStatusText: React.FC<FlightStatusTextProps> = ({
  delayed,
  delayMinutes,
  delayTime,
  statusColor,
  statusText,
}: FlightStatusTextProps) => {
  if (delayed && delayMinutes) {
    return (
      <Tooltip label={delayTime}>
        <Box color={statusColor} fontSize="xs" fontWeight="bold">
          +{delayMinutes} MIN
        </Box>
      </Tooltip>
    );
  }

  // We'll show the delayed icon if there was a real delay and
  // the flight was not canceled.
  const shouldShowDelayedIcon = delayMinutes >= DELAY_THRESHOLD && statusText !== CANCELED;

  return (
    <Box color={statusColor} fontSize="xs" fontWeight="bold">
      {statusText}{' '}
      {shouldShowDelayedIcon ? (
        <Tooltip label={delayTime}>
          <Icon name="clock" color="warning" size="12" ml="2px" position="relative" top="-1px" />
        </Tooltip>
      ) : null}
    </Box>
  );
};

export default FlightStatusText;
