import { themeGet } from '@styled-system/theme-get';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import startCase from 'lodash/startCase';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { REPORT_TABS } from 'config/tabs';
import { track } from 'utils';

import { useReportQueryParams } from 'hooks';

import { unlockOptions, updateOptions, useChartOptions } from 'providers/ChartOptionsProvider';

import {
  AirlineSearch,
  AirportSearch,
  Button,
  Checkbox,
  FormField,
  Icon,
  LinkButton,
} from 'components';

import { Box, Divider, ResetButton, Stack } from 'styled';

import ReportsDateSelector, { getNewSelectedDates } from './ReportsDateSelector';

export const TAB_TITLES = REPORT_TABS.filter(t => t.configSetting).map(t => t.title);

const ReportsSidebar: React.FC = () => {
  const [state, dispatch] = useChartOptions();
  const history = useHistory();
  const {
    dataStartDate,
    dataEndDate,
    chartStartDate,
    chartEndDate,
    activeReport,
    airline,
    destinationAirportInputValue: airport,
    tabs,
    dateRangeDays,
    weather,
  } = state;
  const { getQueryParams } = useReportQueryParams();
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    if (allSelected) dispatch(updateOptions({ tabs: TAB_TITLES }));
  }, [allSelected, dispatch]);

  function handleRunReportClick(event: React.MouseEvent): void {
    event.preventDefault();
    dispatch(updateOptions({ activeReport: true, activeFilters: [], tabs }));

    const data = {
      tabs,
      startDate: dataStartDate,
      endDate: dataEndDate,
      days: dateRangeDays,
      airline: airline.join(','),
      airport,
      weather: weather.join(','),
    };

    track('Run Report', {
      data,
    });

    const newQuery = getQueryParams({
      data,
    });
    if (history.location.search !== newQuery) {
      history.push({
        pathname: '/reports',
        search: newQuery,
      });
    }
  }

  function handleUnlockClick(event: React.MouseEvent): void {
    event.preventDefault();
    history.push('/reports');
    dispatch(unlockOptions());
  }

  function handleViewChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const tab = startCase(event.target.name);
    const checked = tabs.includes(tab);

    const newDates = getNewSelectedDates({ tabs, chartStartDate, chartEndDate });
    let newTabs: string[] = [];

    if (checked) {
      // Remove it from the active tabs.
      newTabs = tabs.filter((tabTitle: string) => tabTitle !== tab);
      setAllSelected(false);
    } else {
      // Add it to the active tabs.
      newTabs = [...tabs, tab];
    }
    dispatch(updateOptions({ ...newDates, tabs: newTabs }));
  }

  const showAirlineFilters =
    tabs.includes('Scans') ||
    tabs.includes('Departures') ||
    tabs.includes('Arrivals') ||
    tabs.includes('Bookings') ||
    tabs.includes('Flight Performance') ||
    tabs.includes('Common Use Workstations') ||
    tabs.includes('Common Use Kiosks') ||
    tabs.includes('Common Use Bag Tags');

  const showAirportFilters =
    tabs.includes('Scans') ||
    tabs.includes('Departures') ||
    tabs.includes('Arrivals') ||
    tabs.includes('Flight Performance');

  const _allSelected = allSelected || isEqual(TAB_TITLES.sort(), tabs.sort());

  return (
    <Box display="grid" gridTemplateRows="1fr auto" minHeight="100%">
      <Box>
        <ReportsDateSelector />
        <Box mb="base">
          <FormField label="Reports" type="stacked">
            <Stack direction="x" xspace="0" mb="s">
              <Checkbox
                label={`Select All`}
                name="selectAll"
                onChange={(): void => {
                  const newDates = getNewSelectedDates({ tabs, chartStartDate, chartEndDate });
                  let newTabs: string[] = [];
                  if (!allSelected) {
                    setAllSelected(true);
                    newTabs = [...TAB_TITLES];
                  } else {
                    setAllSelected(false);
                    newTabs = [];
                  }
                  dispatch(updateOptions({ ...newDates, tabs: newTabs }));
                }}
                checked={_allSelected}
                disabled={activeReport}
              />
              {tabs.length > 0 ? (
                <ClearLinkButton
                  as={ResetButton}
                  disabled={activeReport}
                  onClick={(event: React.MouseEvent): void => {
                    event.preventDefault();
                    const newDates = getNewSelectedDates({ tabs, chartStartDate, chartEndDate });
                    setAllSelected(false);
                    dispatch(updateOptions({ ...newDates, tabs: [] }));
                  }}
                >
                  Clear
                </ClearLinkButton>
              ) : null}
            </Stack>
            <Divider mb="s" />
            {TAB_TITLES.sort().map((tab: string, idx: number) => (
              <Box key={idx} mb={last(TAB_TITLES) === tab ? '0' : 's'}>
                <Checkbox
                  label={tab}
                  name={tab.toLowerCase()}
                  onChange={handleViewChange}
                  checked={tabs.includes(tab)}
                  disabled={activeReport}
                />
              </Box>
            ))}
          </FormField>
        </Box>
        {showAirportFilters ? (
          <Box mb="base">
            <FormField label="Airport" labelHorizontalAlign="left" labelWidth="65px">
              <AirportSearch placeholder="Search..." resultsWidth="17.8125rem" />
            </FormField>
          </Box>
        ) : null}
        {showAirlineFilters ? (
          <Box mb="base">
            <FormField
              label="Airline"
              labelHorizontalAlign="left"
              labelVerticalAlign="top"
              labelWidth="65px"
            >
              <AirlineSearch placeholder="Search..." resultsWidth="17.8125rem" />
            </FormField>
          </Box>
        ) : null}
      </Box>
      <Box alignItems="center" display="flex">
        <Button
          onClick={handleRunReportClick}
          display="block"
          flex="1"
          width="100%"
          disabled={tabs.length === 0 || activeReport}
        >
          <Icon name="check" size="16" mr="xs" />
          Run Report
        </Button>
        {activeReport && (
          <LinkButton onClick={handleUnlockClick} display="block">
            <Icon name="corneredArrowUpLeft" size="14" mr="xs" />
            Edit filter
          </LinkButton>
        )}
      </Box>
    </Box>
  );
};

const ClearLinkButton = styled.button`
  color: ${themeGet('colors.subdued')};
  font-size: ${themeGet('fontSizes.s')};
  margin-left: auto;
  position: relative;
  text-decoration: underline;
  top: 1px;
  transition: 0.3s ease-in-out;

  &:active,
  &:focus,
  &:hover {
    color: ${themeGet('colors.fg')};
  }

  &:disabled {
    opacity: 0.4;
  }
`;

export default ReportsSidebar;
