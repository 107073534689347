import { COMPLETED } from 'data/Flights/constants';
import { ArrivalDepartureFlightType, DepartureFlight } from 'data/Flights/types';

import FlightData from './FlightData';

export function passengerFlights(
  flights: DepartureFlight[],
  { type }: { type: ArrivalDepartureFlightType }
) {
  return flights?.filter((f: DepartureFlight) => {
    // Remove flight if it is passenger and has no bookings or is not in daily bank
    const isPassenger = f.isPassenger;
    const airportCode = process.env.REACT_APP_AIRPORT_CODE || '';
    const circularAirport =
      type === 'departures' ? f.destination === airportCode : f.origin === airportCode;

    const isValidPassenger = !circularAirport && isPassenger;

    // If we want only passenger flights, make sure it's a valid passenger flight
    // If we want aren't asking specifically for passenger flights
    // Only check if it's a valid passenger flight if we have determined it's a passenger flight
    return isValidPassenger;
  });
}

class PassengerFlightData extends FlightData {
  constructor({ flight }: { flight: DepartureFlight }) {
    super({ flight });
  }

  get completed(): boolean {
    return this.flight.statusText === COMPLETED;
  }
}

export default PassengerFlightData;
