import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Card = styled.div<SystemProps>`
  border: 1px solid ${themeGet('colors.border')};
  border-radius: ${themeGet('radii.l')};
  box-shadow: ${themeGet('shadows.base')};
  padding: ${themeGet('space.base')};
  position: relative;

  ${system}
`;

export default Card;
