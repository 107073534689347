import React from 'react';

import { Icon } from 'components';

import { CircleIcon as StyledCircleIcon } from 'styled';
import { SystemProps } from 'styled/lib/types';

export interface Props extends SystemProps {
  icon: string;
  iconProps?: object;
  size?: number;
  spinner?: boolean;
}

const CircleIcon: React.FC<Props> = ({ icon, iconProps, size, spinner, ...rest }) => {
  const defaults = {
    size: 20,
  };

  const _size = size || defaults.size;
  const iconScaleFactor = 6;
  const iconSize = _size - iconScaleFactor;

  return (
    <StyledCircleIcon size={_size} spinner={spinner || false} {...rest}>
      <Icon name={icon} size={`${iconSize}`} {...iconProps} />
    </StyledCircleIcon>
  );
};

export default CircleIcon;
