import { gql, QueryResult, useQuery } from '@apollo/client';

import { User } from './types';

const USERS_DATA = gql`
  query UsersData {
    users {
      user_id
      name
      email
      picture
    }
  }
`;

export default function useUsers(): QueryResult<{ users: User[] }> {
  return useQuery(USERS_DATA);
}
