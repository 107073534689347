import { SeriesOptionsType } from 'highcharts';

import { ArrivalsChartAirline } from 'statistics/types';

import { getXAxis, getYAxis } from './axes';
import { default as getOptions } from './options';
import { default as getSeries } from './series';

export default {
  passenger: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (
      startDate: Date,
      endDate: Date,
      data: ArrivalsChartAirline[],
      chartOptions?: SeriesOptionsType
    ): SeriesOptionsType[] => getSeries(startDate, endDate, data, 'passenger', chartOptions),
  },
  cargo: {
    getXAxis,
    getYAxis,
    getOptions,
    getSeries: (
      startDate: Date,
      endDate: Date,
      data: ArrivalsChartAirline[],
      chartOptions?: SeriesOptionsType
    ): SeriesOptionsType[] => getSeries(startDate, endDate, data, 'cargo', chartOptions),
  },
};
