import React from 'react';

import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { Checkbox, FormField, SortByOptions } from 'components';

const SalesOptions: React.FC = () => {
  const [state, dispatch] = useTileOptions();
  const {
    sales: { filters },
  } = state;

  function filterAreaChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const area = event.target.name.toUpperCase();
    const checked = filters.areas.includes(area);
    let newAreas: string[] = [];

    if (checked) {
      newAreas = filters.areas.filter((a: string) => a !== area);
    } else {
      newAreas = [...filters.areas, area];
    }

    dispatch(
      updateOptions({
        sales: {
          filters: {
            ...filters,
            areas: newAreas,
          },
        },
      })
    );
  }

  return (
    <>
      <SortByOptions
        stateKey="sales"
        options={[
          { value: 'amount', label: 'Amount' },
          { value: 'date', label: 'Date' },
          { value: 'description', label: 'Description' },
          { value: 'title', label: 'Title' },
        ]}
      />
      <FormField label="Areas to show" type="stacked" mb="base">
        <Checkbox
          label="Concession"
          name="concession"
          checked={filters.areas.includes('CONCESSION')}
          onChange={filterAreaChange}
          mb="s"
        />
        <Checkbox
          label="Parking"
          name="parking"
          checked={filters.areas.includes('PARKING')}
          onChange={filterAreaChange}
          mb="s"
        />
      </FormField>
    </>
  );
};

export default SalesOptions;
