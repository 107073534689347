import { gql, useQuery } from '@apollo/client';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { ProjectionsArgs, ProjectionsQuery } from './types';

const GET_PROJECTIONS = gql`
  query Projections($date: String, $between: [DateTime]) {
    projections(date: $date, between: $between) {
      id
      hour
      count
      date
    }
  }
`;

export default function useProjections({
  date,
  between,
  refresh = true,
  skip = false,
}: ProjectionsArgs): ProjectionsQuery {
  const variables = pickBy({ date, between }, identity);
  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(GET_PROJECTIONS, options);

  return {
    projections: query?.data?.projections || [],
    ...query,
  };
}
