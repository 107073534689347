import Highcharts from 'highcharts';
import merge from 'lodash/merge';

import theme from 'config/theme';

import { getDollars } from 'utils';

import { getDefaultYAxisOptions } from 'statistics/charts/utils';

const axis: Highcharts.YAxisOptions = merge(getDefaultYAxisOptions(), {
  id: 'amount',
  title: {
    text: 'Amount',
  },
  stackLabels: {
    enabled: true,
    style: {
      color: theme?.colors?.fg,
      textOutline: 'none',
    },
    formatter: function (): string {
      return getDollars(this.total);
    },
  },
  labels: {
    formatter: function (): string {
      return getDollars(this.value);
    },
  },
} as Highcharts.YAxisOptions);

export default function getAxis(): Highcharts.YAxisOptions {
  return axis;
}
