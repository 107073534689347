export const severities = ['Critical', 'Sub-Critical', 'Non-Critical'];
export const areas = [
  'Constance 41',
  'Constance 43',
  'Donaldson 43',
  'Hebron 45',
  'Limaburg 41',
  'Limaburg 43',
  'Oakbrook 41',
  'Owen Electric',
];
export const statuses = ['OK', 'Issue'];
