import { LatLngBoundsLiteral, LatLngTuple } from 'leaflet';
import { isEqual } from 'lodash';
import React from 'react';
import { Map as LeafletMap, Polygon, Rectangle, TileLayer } from 'react-leaflet';

import theme from 'config/theme';

import { FacilityCondition } from 'client/FacilityConditions/types';
import { Issue } from 'client/Issue/types';

import { usePopupWatcher } from 'providers/SelectedTableItemProvider';

import { Box } from 'styled';

import FacilityConditionsPopup from './FacilityConditionsPopup';

type FacilityConditionsMapProps = {
  facilityConditions: FacilityCondition[];
  issues: Issue[];
};

const FacilityConditionsMap: React.FC<FacilityConditionsMapProps> = ({
  facilityConditions,
  issues,
}: FacilityConditionsMapProps) => {
  const options = {
    lat: Number(process.env.REACT_APP_AIRPORT_LATITUDE) as number,
    lng: Number(process.env.REACT_APP_AIRPORT_LONGITUDE) as number,
  };

  const rectangleBounds: LatLngBoundsLiteral = [
    [options.lat - 1000, options.lng - 1000],
    [options.lat + 1000, options.lng + 1000],
  ];

  const groupedFacilityConditions: { [key: string]: FacilityCondition[] } = {};

  facilityConditions.forEach(facilityCondition => {
    const { feed } = facilityCondition;
    if (!(feed in groupedFacilityConditions)) {
      groupedFacilityConditions[feed] = [];
    }

    groupedFacilityConditions[feed].push(facilityCondition);
  });

  return (
    <Box height="100%" position="relative" zIndex={0}>
      <LeafletMap
        center={[options.lat, options.lng]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png" />
        <Rectangle
          bounds={rectangleBounds}
          fillColor={theme.colors.fg}
          fillOpacity={0.25}
          stroke={false}
        />
        {Object.entries(groupedFacilityConditions).map(([feed, facilityConditions]) => (
          <FeedPolygons
            key={feed}
            feed={feed}
            facilityConditions={facilityConditions}
            issues={issues}
          />
        ))}
      </LeafletMap>
    </Box>
  );
};

interface FeedPolygonsProps {
  feed: string;
  facilityConditions: FacilityCondition[];
  issues: Issue[];
}

type PolygonType = {
  coords: LatLngTuple[];
  facilityConditions: FacilityCondition[];
};

const FeedPolygons: React.FC<FeedPolygonsProps> = ({
  feed,
  facilityConditions,
  issues,
}: FeedPolygonsProps) => {
  const polygons: PolygonType[] = [];

  facilityConditions.forEach(facilityCondition => {
    let polygon = polygons.find(polygon => isEqual(polygon.coords, facilityCondition.coordinates));
    if (!polygon) {
      polygon = { coords: facilityCondition.coordinates, facilityConditions: [] };
      polygons.push(polygon);
    }

    polygon.facilityConditions.push(facilityCondition);
  });

  return (
    <>
      {polygons.map((polygon, i) => (
        <FeedPolygon key={`${feed}-${i}`} polygon={polygon} issues={issues} feed={feed} />
      ))}
    </>
  );
};

interface FeedPolygonProps {
  feed: string;
  polygon: PolygonType;
  issues: Issue[];
}

const FeedPolygon: React.FC<FeedPolygonProps> = ({ feed, polygon, issues }: FeedPolygonProps) => {
  const { ref } = usePopupWatcher(JSON.stringify(polygon.coords));
  const hasIssue = polygon.facilityConditions.some(({ issueId }) =>
    issues.some(issue => issue.id === issueId)
  );
  const colorKey = hasIssue ? 'error' : 'success';
  const color = theme.colors[colorKey];

  return (
    <Polygon ref={ref} weight={6} positions={polygon.coords} color={color}>
      <FacilityConditionsPopup
        className={`leaflet-popup-${colorKey}`}
        feed={feed}
        facilityConditions={polygon.facilityConditions}
        issues={issues}
      />
    </Polygon>
  );
};

export default FacilityConditionsMap;
