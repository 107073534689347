import theme from 'config/theme';
import { AIRPORT_CODE, REGIONAL_AIRPORTS } from './constants';

export type ColorName = keyof typeof theme.colors.chart;

const getColor = (key: ColorName): string => theme?.colors?.chart[key] || theme?.colors?.chart?.red;

const getThroughputColors = () => {
  const colors = {
    [`Daily Throughput - ${AIRPORT_CODE}`]: getColor('green'),
    'Daily Throughput - 7 Day Rolling Average': getColor('teal'),
    'Daily Throughput - 1 year ago': getColor('blue'),
    'Daily Throughput - 2019': getColor('pink'),
  };

  const stockColors: ColorName[] = ['teal', 'blue', 'pink', 'purple', 'orange'];

  REGIONAL_AIRPORTS.forEach((airport, index) => {
    colors[`Daily Throughput - ${airport}`] = getColor(stockColors[index] || 'gray');
  });

  return colors;
};

const getTSACountsColors = () => {
  return {
    'TSA Counts - Terminal A': getColor('pink'),
    'TSA Counts - Terminal B': getColor('orange'),
    'TSA Counts - Terminal A (2019)': getColor('teal'),
    'TSA Counts - Terminal B (2019)': getColor('blue'),
  };
};

export const WAIT_TIMES_COLORS = [
  getColor('teal'),
  getColor('purple'),
  getColor('orange'),
  getColor('blue'),
  getColor('pink'),
];

export const AIRLINES_COLORS: { [key: string]: string } = theme.colors.airlines;

const CHART_COLORS = {
  'Baggage - B-Bags': getColor('teal'),
  'Baggage - T-Drive': getColor('purple'),
  'Baggage - Bags Received': getColor('blue'),
  'Baggage - Bags Cleared': getColor('orange'),
  'ML1 Read Rate %': getColor('pink'),
  'ML2 Read Rate %': getColor('yellow'),
  'CB1 Read Rate %': getColor('green'),
  Capacity: getColor('orange'),
  'Cargo Arrivals': getColor('orange'),
  'Cargo Departures': getColor('purple'),
  'Cargo Revenue': getColor('gray'),
  'Current Scans': getColor('green'),
  ...getThroughputColors(),
  Flights: getColor('gray'),
  'Passenger Arrivals': getColor('teal'),
  'Passenger Departures': getColor('gray'),
  'Sales - Concourse A': getColor('gray'),
  'Sales - Concourse B': getColor('teal'),
  'Sales - Main Terminal': getColor('orange'),
  'Sales - Valet': getColor('purple'),
  'Sales - Short Term': getColor('green'),
  'Sales - Long Term': getColor('blue'),
  'Sales - Econo Lot': getColor('pink'),
  'Wait Times - General': getColor('teal'),
  'Wait Times - TSA': getColor('purple'),
  'Passenger Projection - AM': getColor('pink'),
  'Passenger Projection - PM': getColor('yellow'),
  ...getTSACountsColors(),
};

export const CHART_COLORS_ARRAY = Object.values(theme.colors.chart);

export type ChartColorsKey = keyof typeof CHART_COLORS;

export default CHART_COLORS;
