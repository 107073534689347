import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';

import { WAIT_TIMES_COLORS } from 'config/chartColors';

import { getDefaultSeriesOptions, getVisibility } from 'statistics/charts/utils';
import { ChartDatum } from 'statistics/types';

export default function getSeries(
  data: ChartDatum[],
  label: string,
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType {
  return merge(getDefaultSeriesOptions(), {
    name: label,
    yAxis: 'bag-tags',
    borderWidth: 0,
    data,
    color: WAIT_TIMES_COLORS[label === 'Bag Tags Printed' ? 0 : 1],
    crisp: false,
    grouping: true,
    ...chartOptions,
    type: 'column',
    visible: getVisibility(label),
  });
}
