import { themeGet } from '@styled-system/theme-get';
import {
  addDays,
  differenceInDays,
  endOfDay,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
  subDays,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import React, { useEffect } from 'react';
import { DatePicker } from 'react-nice-dates';
import styled from 'styled-components';

import {
  DATE_RANGE_DAYS,
  DEFAULT_DATE_RANGE,
  updateOptions,
  useChartOptions,
} from 'providers/ChartOptionsProvider';

import { Checkbox, FormField, HideAndShow, Icon, Select, TextInput } from 'components';

import { Box, ResetButton } from 'styled';

interface GetNewSelectedDatesArgs {
  tabs: string[];
  chartStartDate: Date;
  chartEndDate: Date;
}

interface GetNewSelectedDatesReturn {
  chartStartDate?: Date;
  dataStartDate?: Date;
  chartEndDate?: Date;
  dataEndDate?: Date;
  currentDate?: Date;
}

export const MAX_RANGE_DAYS = 42;
export const MIN_DATE = new Date('1/1/20');
export const SCANS_MIN_DATE = new Date('6/11/19');

// If 'Scans' is the only selected tab, the min date will be 6/11/19, so when we make a view change
// The selected dates could be outside the minimum date for other tabs
// We need to automatically change those dates if that's the case
export function getNewSelectedDates({
  tabs,
  chartStartDate,
  chartEndDate,
}: GetNewSelectedDatesArgs): GetNewSelectedDatesReturn {
  const newDates: GetNewSelectedDatesReturn = {};

  if (tabs.length === 1 && tabs[0] === 'Scans' && isBefore(chartStartDate, MIN_DATE)) {
    const dateDiff = differenceInDays(chartEndDate, chartStartDate);
    const _chartStartDate = MIN_DATE;
    let _chartEndDate = chartEndDate;
    if (isBefore(chartEndDate, _chartStartDate)) {
      _chartEndDate = addDays(_chartStartDate, dateDiff);
    } else if (dateDiff > MAX_RANGE_DAYS) {
      _chartEndDate = addDays(_chartStartDate, MAX_RANGE_DAYS - 1);
    }

    newDates.chartStartDate = startOfDay(_chartStartDate);
    newDates.dataStartDate = startOfDay(_chartStartDate);
    newDates.chartEndDate = endOfDay(_chartEndDate);
    newDates.dataEndDate = endOfDay(_chartEndDate);
    newDates.currentDate = endOfDay(_chartEndDate);
  }

  return newDates;
}

const ReportsDateSelector: React.FC = () => {
  const [state, dispatch] = useChartOptions();
  const { chartStartDate, chartEndDate, activeReport, tabs, dateRange, dateRangeDays } = state;
  const minDate = tabs.length === 1 && tabs[0] === 'Scans' ? SCANS_MIN_DATE : MIN_DATE;

  let lastDate = new Date();
  if (tabs.includes('Bookings')) {
    lastDate = addDays(lastDate, 1);
  }

  const startDatePickerModifiers: {
    disabled: Function;
  } = {
    disabled: (date: Date) => isAfter(date, chartEndDate) || isBefore(date, new Date(minDate)),
  };

  const endDatePickerModifiers: {
    disabled: Function;
  } = {
    disabled: (date: Date) => isBefore(date, chartStartDate),
  };

  function updateDates(startDate: Date, endDate: Date): void {
    const _chartStartDate = startOfDay(startDate);
    const _dataStartDate = startOfDay(startDate);
    const _chartEndDate = endOfDay(endDate);
    const _dataEndDate = endOfDay(endDate);
    const currentDate = endOfDay(endDate);
    dispatch(
      updateOptions({
        chartStartDate: _chartStartDate,
        dataStartDate: _dataStartDate,
        chartEndDate: _chartEndDate,
        dataEndDate: _dataEndDate,
        currentDate,
        dateRange: null,
      })
    );
  }

  function handleEndDateChange(date: Date | null): void {
    if (!date) return;
    const startDate = isBefore(chartStartDate, subDays(date, MAX_RANGE_DAYS - 1))
      ? subDays(date, MAX_RANGE_DAYS - 1)
      : chartStartDate;

    updateDates(startDate, date);
  }

  function handleStartDateChange(date: Date | null): void {
    if (!date) return;
    const endDate = isAfter(chartEndDate, addDays(date, MAX_RANGE_DAYS))
      ? addDays(date, MAX_RANGE_DAYS)
      : chartEndDate;

    updateDates(date, endDate);
  }

  function handleDateRangeDayChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const day = event.target.name;
    const checked = dateRangeDays.includes(day);

    let newDays: string[] = [];

    if (checked) {
      newDays = dateRangeDays.filter((d: string) => d !== day);
    } else {
      newDays = [...dateRangeDays, day];
    }

    dispatch(updateOptions({ dateRangeDays: newDays }));
  }

  useEffect(() => {
    const _range = !dateRange ? String(DEFAULT_DATE_RANGE) : dateRange;
    const startDate = subDays(new Date(), Number(_range));
    const endDate = new Date();

    const _chartStartDate = startOfDay(startDate);
    const _dataStartDate = startOfDay(startDate);
    const _chartEndDate = endOfDay(endDate);
    const _dataEndDate = endOfDay(endDate);
    const currentDate = endOfDay(endDate);

    // We only want to run the date update if:
    // 1. The start or end date are different.
    // 2. The report is not currently active.
    // 3. There is a valid date range set.
    // That way we're only changing the date values when the select is updated.
    const isDifferentStart = !isSameDay(startDate, chartStartDate);
    const isDifferentEnd = !isSameDay(endDate, chartEndDate);
    const shouldRun = (isDifferentStart || isDifferentEnd) && !activeReport && dateRange;

    if (shouldRun) {
      dispatch(
        updateOptions({
          chartStartDate: _chartStartDate,
          dataStartDate: _dataStartDate,
          chartEndDate: _chartEndDate,
          dataEndDate: _dataEndDate,
          currentDate,
        })
      );
    }
  }, [dateRange, chartStartDate, chartEndDate, dispatch, activeReport]);

  useEffect(() => {
    // We're going to run this code when there is an active report and the
    // `dateRange` is not in line with the actual number of days that were
    // selected, which means we need to update the range.
    const days = differenceInDays(chartEndDate, chartStartDate);
    const shouldRun = dateRange !== days && activeReport;
    if (shouldRun) dispatch(updateOptions({ dateRange: days }));
  }, [dateRange, chartStartDate, chartEndDate, activeReport, dispatch]);

  const rangeOptions = [
    { value: '1', label: 'Yesterday' },
    { value: '7', label: 'Last 7 days' },
    { value: '14', label: 'Last 14 days' },
    { value: '30', label: 'Last 30 days' },
    { value: '42', label: 'Last 6 weeks' },
  ];
  const isCustomRange = !rangeOptions?.find(option => `${option?.value}` === `${dateRange}`);

  // If custom date range selected add custom option
  if (isCustomRange) rangeOptions.push({ value: '0', label: 'Custom' });

  return (
    <Box mb="l">
      <Box mb="base">
        <FormField label="Range" type="stacked">
          <Select
            name="range"
            value={isCustomRange ? '0' : String(dateRange)}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>): void =>
              dispatch(updateOptions({ dateRange: event.target.value }))
            }
            options={rangeOptions}
            disabled={activeReport}
          />
        </FormField>
      </Box>
      <Box alignItems="center" display="flex" mb="base">
        <DatePicker
          date={chartStartDate}
          onDateChange={handleStartDateChange}
          locale={enUS}
          modifiers={startDatePickerModifiers}
        >
          {({ inputProps }): JSX.Element => (
            <TextInput icon="calendar" textAlign="center" disabled={activeReport} {...inputProps} />
          )}
        </DatePicker>
        <Box mx="xs">
          <Icon name="arrowRight" color="subdued" size="16" />
        </Box>
        <DatePicker
          date={chartEndDate}
          maximumDate={endOfDay(lastDate)}
          onDateChange={handleEndDateChange}
          locale={enUS}
          modifiers={endDatePickerModifiers}
        >
          {({ inputProps }): JSX.Element => (
            <TextInput icon="calendar" textAlign="center" disabled={activeReport} {...inputProps} />
          )}
        </DatePicker>
      </Box>
      <Box>
        <HideAndShow
          renderToggle={(visible: boolean, setVisible: Function): JSX.Element => (
            <AddFilterLink onClick={(): void => setVisible(!visible)} disabled={activeReport}>
              <Icon
                name={visible ? 'minus' : 'plus'}
                size="16"
                mr="xs"
                position="relative"
                top="-1px"
              />
              Filter by day of week
            </AddFilterLink>
          )}
        >
          <Box mt="s">
            {DATE_RANGE_DAYS.map((day: string, idx: number) => (
              <Checkbox
                key={idx}
                label={day}
                name={day}
                onChange={handleDateRangeDayChange}
                checked={dateRangeDays.includes(day)}
                mb="s"
              />
            ))}
          </Box>
        </HideAndShow>
      </Box>
    </Box>
  );
};

const AddFilterLink = styled(ResetButton)`
  color: ${themeGet('colors.subdued')};
  font-size: ${themeGet('fontSizes.s')};
  font-weight: ${themeGet('fontWeights.bold')};
  transition: 0.3s ease-in-out;

  &:active,
  &:focus,
  &:hover {
    color: ${themeGet('colors.fg')};
  }
`;

export default ReportsDateSelector;
