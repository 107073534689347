import {
  addDays,
  addMinutes,
  differenceInCalendarDays,
  endOfDay,
  format,
  startOfDay,
  startOfHour,
} from 'date-fns';
import range from 'lodash/range';
import upperCase from 'lodash/upperCase';

import { getAirline } from 'config/airlines';

import { CargoData, PassengerData } from 'utils/arrivals';
import { passengerFlights } from 'utils/arrivals/PassengerFlightData';

import { CANCELED } from 'data/Flights/constants';
import { ArrivalFlight } from 'data/Flights/types';
import { ArrivalFlightsData } from 'data/Flights/data';

import {
  filterByDayRange,
  filterByWeatherConditions,
  getFilteredChartData,
  getFilteredDataFromTime,
  isDaily,
} from 'statistics/charts/utils';
import { STAT_TYPES } from 'statistics/stats/constants';
import {
  ArrivalsChartAirline,
  ArrivalsChartDatum,
  DataObject,
  FilteredArrivalData,
  ArrivalCounts,
  FlightTypeKey,
  StatsData,
} from 'statistics/types';
import { getQuarterHourMultiplier } from 'statistics/utils';

import { ProcessedDataObject } from './utils';

function getFilteredData(
  startDate: Date,
  endDate: Date,
  arrivals: ArrivalFlight[],
  departureAirport = '',
  airline: string[] = [],
  flightType?: string
): FilteredArrivalData {
  let flightTypeData: PassengerData | CargoData | ArrivalFlightsData | undefined;
  switch (flightType) {
    case 'passenger':
      flightTypeData = new PassengerData({
        flights: passengerFlights(arrivals, { type: 'arrivals' }),
      });
      break;
    case 'cargo':
      flightTypeData = new CargoData({ flights: arrivals });
      break;
    default:
      flightTypeData = new ArrivalFlightsData({ flights: arrivals });
      break;
  }

  const flights = flightTypeData?.flights || [];
  const filteredData: FilteredArrivalData = { Total: [] };
  airline.forEach(a => {
    const _airline = getAirline(a);
    if (_airline) {
      filteredData[_airline.iata] = [];
    }
  });

  flights.forEach(flight => {
    if (flight.statusText === CANCELED) return;
    const date = new Date(flight.actualArrivalDate || flight.scheduledArrivalDate);
    const withinTimeframe =
      date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime();
    const validAirport = departureAirport ? flight.origin === departureAirport : true;

    const flightAirlineCode = getAirline(flight.marketingCarrier)?.iata || 'N/A';

    // Flight has a valid airline if the airline code is in the filteredData object
    // Or there aren't any airlines specified in the filter
    const validAirline =
      (flightAirlineCode && flightAirlineCode in filteredData) || !airline.length;

    if (!validAirport || !validAirline || !withinTimeframe) return;

    if (airline.length > 1 && flightAirlineCode && flightAirlineCode in filteredData) {
      filteredData[flightAirlineCode].push(flight);
    }
    filteredData.Total.push(flight);
  });

  Object.entries(filteredData).forEach(([airlineCode, data]) => {
    if (!(airline.length > 1) && data.length === 0 && airlineCode !== 'Total')
      delete filteredData[airlineCode];
  });

  return filteredData;
}

function getArrivalCounts(
  startDate: Date,
  endDate: Date,
  filteredData: ArrivalFlight[]
): ArrivalCounts {
  const timeCounts: ArrivalCounts = {};

  const numDays = differenceInCalendarDays(endDate, startDate);

  let multiples = getQuarterHourMultiplier(startDate, endDate);
  let initializer = (multiple: number): void => {
    const time = new Date(startDate);
    time.setMinutes(time.getMinutes() + multiple * 15);
    timeCounts[time.getTime()] = {
      count: 0,
      flights: [],
    };
  };
  let aggregator = (flight: ArrivalFlight): void => {
    const time = new Date(flight.actualArrivalDate || flight.scheduledArrivalDate);
    // If the flight arrives within ARRIVAL_TIME_RANGE of a given time, add to that time's tally
    let timeSlot = startOfHour(time);
    if (time.getMinutes() >= 45) timeSlot = addMinutes(timeSlot, 45);
    if (time.getMinutes() >= 30) timeSlot = addMinutes(timeSlot, 30);
    if (time.getMinutes() >= 15) timeSlot = addMinutes(timeSlot, 15);
    const timeKey = timeSlot.getTime();
    if (timeCounts[timeKey] !== undefined) {
      timeCounts[timeKey].count += 1;
      timeCounts[timeKey].flights.push(flight);
    }
  };

  if (isDaily(startDate, endDate)) {
    multiples = range(numDays + 1);
    initializer = (multiple: number): void => {
      let time = startOfDay(startDate);
      time = addDays(time, multiple);
      timeCounts[time.getTime()] = {
        count: 0,
        flights: [],
      };
    };
    aggregator = (flight: ArrivalFlight): void => {
      const time = startOfDay(new Date(flight.actualArrivalDate || flight.scheduledArrivalDate));
      const timeKey = time.getTime();

      if (timeCounts[timeKey] !== undefined) {
        timeCounts[timeKey].count += 1;
        timeCounts[timeKey].flights.push(flight);
      }
    };
  }

  multiples.forEach(initializer);

  filteredData.forEach(aggregator);

  return timeCounts;
}

type ProcessedArrivals = { [key: string]: number };

function getChartData(data: ProcessedArrivals, arrivalCounts: ArrivalCounts): ArrivalsChartDatum[] {
  return Object.entries(data).map(([time, count]: [string, number]) => ({
    x: Number(time),
    y: count,
    flights: arrivalCounts[time]?.flights,
  }));
}

function getStatsData(data: ProcessedArrivals, flightType?: 'passenger' | 'cargo'): StatsData {
  const length = Object.keys(data).length;
  const { highest, sum } = Object.entries(data).reduce(
    (value, [key, count]: [string, number]) => {
      value.sum += count;
      if (count > value.highest.value) {
        value.highest = {
          context: format(new Date(Number(key)), 'LL/dd/yy'),
          value: count,
        };
      }
      return value;
    },
    { sum: 0, highest: { context: '', value: 0 } }
  );
  const average = Math.ceil(sum / length);

  const key: FlightTypeKey = flightType ? (upperCase(flightType) as FlightTypeKey) : 'PASSENGER';

  return {
    [STAT_TYPES.ARRIVALS[key].AVERAGE]: {
      value: isNaN(average) ? 0 : average,
      contextLabel: `All ${flightType} flights`,
    },
    [STAT_TYPES.ARRIVALS[key].HIGHEST]: { value: highest.value, contextLabel: highest.context },
  };
}

function getProcessedData(
  startDate: Date,
  endDate: Date,
  arrivalsData: DataObject,
  weatherData: DataObject,
  dateRangeDays: string[],
  departureAirport?: string,
  airline?: string[],
  weather?: string[],
  flightType?: 'passenger' | 'cargo'
): ProcessedDataObject {
  const { loading, error, data: arrivals } = arrivalsData;
  const { loading: weatherLoading, error: weatherError, data: _weatherData } = weatherData;
  const hasWeatherDataAndLoading = weather && weather.length > 0 && weatherLoading;
  const hasWeatherDataAndError = weather && weather.length > 0 && weatherError;

  if (loading || error || hasWeatherDataAndLoading || hasWeatherDataAndError)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
      details: {
        loading,
        error,
        data: [],
      },
    };

  // TODO: This should be handled elsewhere!
  // We need to make sure the `endDate` is set to the end of the
  // day in terms of hours to avoid it being set to the same hour
  // as the `startDate`, which breaks when changing between days.
  const _endDate = endOfDay(endDate);

  const filteredData = getFilteredData(
    startDate,
    _endDate,
    arrivals as ArrivalFlight[],
    departureAirport,
    airline,
    flightType
  );

  const { byDayRange } = filterByDayRange(dateRangeDays);
  const { byWeatherConditions, filterChartDataByWeatherConditions } = filterByWeatherConditions(
    _weatherData,
    weather
  );
  const _filteredData: { [key: string]: ArrivalFlight[] } = {};
  Object.keys(filteredData).forEach((key: string) => {
    _filteredData[key] = filteredData[key].filter((datum: ArrivalFlight) =>
      byDayRange(datum.scheduledArrivalDate)
    );
  });

  let totalProcessedData: ProcessedArrivals = {};
  const chartData: ArrivalsChartAirline[] = Object.entries(_filteredData).map(
    ([airlineCode, fd]) => {
      const arrivalCounts = getArrivalCounts(startDate, _endDate, fd);
      const airlineData: ProcessedArrivals = {};
      const numDays = differenceInCalendarDays(_endDate, startDate);

      let multiples = getQuarterHourMultiplier(startDate, _endDate);
      let aggregator = (multiple: number): void => {
        const time = new Date(startDate);
        time.setMinutes(time.getMinutes() + multiple * 15);
        const timeKey = time.getTime();
        airlineData[time.getTime()] = arrivalCounts[timeKey]?.count || 0;
      };

      if (isDaily(startDate, _endDate)) {
        multiples = range(numDays + 1);
        aggregator = (multiple: number): void => {
          let time = startOfDay(startDate);
          time = addDays(time, multiple);
          const timeKey = time.getTime();
          airlineData[timeKey] = arrivalCounts[timeKey]?.count || 0;
        };
      }

      multiples.forEach(aggregator);

      const data = getChartData(airlineData, arrivalCounts);

      if (airlineCode === 'Total') totalProcessedData = airlineData;

      return {
        airline: airlineCode,
        data: getFilteredChartData(data, dateRangeDays) as ArrivalsChartDatum[],
      };
    }
  );

  const filteredChartData = chartData[0].data.filter((datum: ArrivalsChartDatum) =>
    byWeatherConditions(datum.x)
  );
  const filteredStatsData = filterChartDataByWeatherConditions(
    getFilteredDataFromTime(totalProcessedData, dateRangeDays)
  );
  const filteredDetailsData = _filteredData.Total.filter(
    (datum: ArrivalFlight) =>
      byDayRange(datum.scheduledArrivalDate) && byWeatherConditions(datum.scheduledArrivalDate)
  );

  // There's probably a better way to do this...
  chartData[0].data = filteredChartData;

  return {
    chart: {
      loading,
      error,
      data: chartData,
    },
    stats: {
      loading,
      error,
      data: getStatsData(filteredStatsData, flightType),
    },
    details: {
      loading,
      error,
      data: filteredDetailsData,
    },
  };
}

export default {
  passenger: {
    getProcessedData: (
      startDate: Date,
      endDate: Date,
      arrivals: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      departureAirport?: string,
      airline?: string[],
      weather?: string[]
    ): ProcessedDataObject =>
      getProcessedData(
        startDate,
        endDate,
        arrivals,
        weatherData,
        dateRangeDays,
        departureAirport,
        airline,
        weather,
        'passenger'
      ),
  },
  cargo: {
    getProcessedData: (
      startDate: Date,
      endDate: Date,
      arrivals: DataObject,
      weatherData: DataObject,
      dateRangeDays: string[],
      departureAirport?: string,
      airline?: string[],
      weather?: string[]
    ): ProcessedDataObject =>
      getProcessedData(
        startDate,
        endDate,
        arrivals,
        weatherData,
        dateRangeDays,
        departureAirport,
        airline,
        weather,
        'cargo'
      ),
  },
};
