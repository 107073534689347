import chunk from 'lodash/chunk';
import isEqual from 'lodash/isEqual';
import { useEffect, useReducer } from 'react';

export interface DataLimiter {
  currentData: any;
  firstPage: boolean;
  handleNext: Function;
  handlePrev: Function;
  lastPage: boolean;
  noData: boolean;
  pageDescription: string;
  resultsDescription: string;
  setData: Function;
  status: string;
}

interface State {
  data: [];
  displayData: [];
  offset: number;
  page: number;
  status: string;
  updateDisplayData: boolean;
}

interface Action {
  type: string;
  payload: any;
}

const DEFAULT_LIMIT = 20;

const initialState: State = {
  data: [],
  displayData: [],
  offset: DEFAULT_LIMIT,
  page: 0,
  status: 'loading',
  updateDisplayData: false,
};

function reducer(state: State, action: Action): State {
  const { type, payload } = action;

  switch (type) {
    case 'update': {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

function useDataLimiter(limit: number = DEFAULT_LIMIT): DataLimiter {
  const [state, dispatch] = useReducer(reducer, initialState, (state: State) => ({
    ...state,
    limit,
  }));

  useEffect(() => {
    const hasData = state.data.length !== 0;
    const noDisplayData = state.displayData.length === 0;

    if (hasData && (noDisplayData || state.updateDisplayData)) {
      const chunkedData = chunk(state.data, limit);
      dispatch({
        type: 'update',
        payload: {
          updateDisplayData: false,
          displayData: chunkedData,
          status: 'success',
        },
      });
    }
  }, [state, limit]);

  const count = state.data?.length;
  const noData = count === 0;
  const numPages = state.displayData.length;
  const resultsDescription = `${count} Results`;
  const pageDescription = `Page ${state.page + 1} of ${numPages}`;
  const firstPage = state.page === 0;
  const lastPage = state.page === numPages - 1;
  const currentData = state.displayData[state.page] || [];

  function handleNext(event: React.MouseEvent): void {
    event.preventDefault();

    if (lastPage) return;

    const newPage = state.page + 1;
    dispatch({ type: 'update', payload: { page: newPage } });
  }

  function handlePrev(event: React.MouseEvent): void {
    event.preventDefault();

    if (firstPage) return;

    const newPage = state.page - 1;
    dispatch({ type: 'update', payload: { page: newPage } });
  }

  function setData(data: any): void {
    if (!isEqual(data, state.data)) {
      dispatch({
        type: 'update',
        payload: {
          data,
          updateDisplayData: true,
          page: 0,
        },
      });
    }
  }

  return {
    currentData,
    firstPage,
    handleNext,
    handlePrev,
    lastPage,
    noData,
    pageDescription,
    resultsDescription,
    setData,
    status: state.status,
  };
}

export default useDataLimiter;
