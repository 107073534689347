import { useEffect, useState } from 'react';

interface Data {
  refreshedAt: Date;
}

function useRefreshedAt(loading: boolean): Data {
  const [refreshedAt, setRefreshedAt] = useState(new Date());

  useEffect(() => {
    if (loading) setRefreshedAt(new Date());
  }, [loading]);

  return { refreshedAt };
}

export default useRefreshedAt;
