import { gql, useQuery } from '@apollo/client';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { RVRQuery } from './types';

const GET_RVRS = gql`
  query RVRs {
    rvrs {
      id
      airport
      value
      updatedAt
    }
  }
`;

export default function useRVRs(): RVRQuery {
  const options = {
    pollInterval: DEFAULT_POLL_INTERVAL,
  };

  const query = useQuery(GET_RVRS, options);

  return {
    rvrs: query?.data?.rvrs || [],
    ...query,
  };
}
