import { format } from 'date-fns';
import sortBy from 'lodash/sortBy';

import { Checkpoint, WaitTime } from 'data/Security/types';

import { getFilteredChartData } from 'statistics/charts/utils';
import { STAT_TYPES } from 'statistics/stats/constants';
import { ChartDatum, DataObject, StatsData } from 'statistics/types';

import { ProcessedDataObject } from './utils';

function getChartData(data: WaitTime[], label: string): ChartDatum[] {
  return sortBy(
    data.map((d: WaitTime) => ({
      x: new Date(d.timestamp).getTime(),
      y: d.value * 60 * 1000, // convert to milliseconds
      name: label,
    })),
    'x'
  );
}

type ValidIndex = 1 | 2 | 3 | 4 | 5;
type WaitTimesLabel = 'WAIT_TIMES1' | 'WAIT_TIMES2' | 'WAIT_TIMES3' | 'WAIT_TIMES4' | 'WAIT_TIMES5';

function getStatsData(chartData: ChartDatum[], label: string, indexNumber: ValidIndex): StatsData {
  const { highest, sum } = chartData.reduce(
    (value, waitTime) => {
      value.sum += waitTime.y;
      if (waitTime.y > value.highest.value) {
        value.highest.value = waitTime.y;
        value.highest.context = format(new Date(waitTime.x), 'LL/dd/yy');
      }
      return value;
    },
    { sum: 0, highest: { value: 0, context: '' } }
  );

  return {
    [STAT_TYPES.WAIT_TIMES[`WAIT_TIMES${indexNumber}` as WaitTimesLabel].AVERAGE]: {
      value: Math.ceil(sum / (chartData.length * 60 * 1000)),
      contextLabel: `${label} wait times`,
    },
    [STAT_TYPES.WAIT_TIMES[`WAIT_TIMES${indexNumber}` as WaitTimesLabel].HIGHEST]: {
      value: Math.ceil(highest.value / (60 * 1000)),
      contextLabel: highest.context,
    },
  };
}

function getProcessedData(
  checkpointsData: DataObject,
  dateRangeDays: string[],
  type: string
): ProcessedDataObject {
  const { loading, error } = checkpointsData;

  const checkpoints = (checkpointsData?.data as Checkpoint[]) || [];
  const indexNumber = parseInt(type?.substring(type.length - 1));
  const checkpoint = checkpoints[indexNumber - 1];

  if (loading || error || !checkpoint)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
    };

  const chartData = getChartData(checkpoint.waitTimes as WaitTime[], checkpoint.name);
  const filteredChartData = getFilteredChartData(chartData, dateRangeDays);

  return {
    chart: {
      loading,
      error,
      data: filteredChartData,
    },
    stats: {
      loading,
      error,
      data: getStatsData(filteredChartData, checkpoint.name, indexNumber as ValidIndex),
    },
  };
}

export default {
  getProcessedData: (
    checkpointsData: DataObject,
    dateRangeDays: string[],
    type: string
  ): ProcessedDataObject => getProcessedData(checkpointsData, dateRangeDays, type),
};
