import { format } from 'date-fns';
import React from 'react';
import { Popup as LeafletPopup } from 'react-leaflet';

import { getAirline } from 'config/airlines';

import { RemoteParkingSpot } from 'client/RemoteParking/types';

import { Box, Stack, TextLabel } from 'styled';

interface RemoteParkingPopupProps {
  spot: RemoteParkingSpot;
  className?: string;
}

const OccupiedParkingSpot: React.FC<{ data: RemoteParkingSpot }> = ({ data }) => {
  if (process.env.REACT_APP_CONFIG_REMOTE_PARKING !== 'true') return null;
  const timeIn = data.reservation.issueDate ? new Date(data.reservation.issueDate) : null;
  const timeOut = data.reservation.estimatedReturnDate
    ? new Date(data.reservation.estimatedReturnDate)
    : null;
  const tailNumber = data.reservation.tailNumber;
  const aircraftType = data.reservation.aircraftType;
  const airline = getAirline(data.reservation.airlineCode);
  const name = data.reservation.udfs.find(udf => Boolean(udf));
  const phone = data.reservation.phoneNumber;
  const purpose = data.reservation.purpose;
  const reservationId = data.reservation.customerRentalCode;

  return (
    <>
      <TextLabel color="subduedLight" mb="xs" mt="s">
        Occupant Details
      </TextLabel>
      <Stack direction="y" yspace="xs">
        {reservationId ? (
          <Box>
            <strong>Reservation ID: </strong>
            {reservationId}
          </Box>
        ) : null}
        {timeIn ? (
          <Box>
            <strong>Time In: </strong>
            {format(timeIn, 'LL/dd/yyyy KK:mm aa')}
          </Box>
        ) : null}
        {timeOut ? (
          <Box>
            <strong>Est. Time Out: </strong>
            {format(timeOut, 'LL/dd/yyyy KK:mm aa')}
          </Box>
        ) : null}
        {tailNumber ? (
          <Box>
            <strong>Tail Number: </strong>
            {tailNumber}
          </Box>
        ) : null}
        {aircraftType ? (
          <Box>
            <strong>Aircraft Type: </strong>
            {aircraftType}
          </Box>
        ) : null}
        {airline ? (
          <Box>
            <strong>Airline: </strong>
            {airline?.name || data.reservation.airline}
          </Box>
        ) : null}
        {name ? (
          <Box>
            <strong>Name: </strong>
            {name}
          </Box>
        ) : null}
        {phone ? (
          <Box>
            <strong>Phone: </strong>
            {phone}
          </Box>
        ) : null}
        {purpose ? (
          <Box>
            <strong>Purpose: </strong>
            {purpose}
          </Box>
        ) : null}
      </Stack>
    </>
  );
};

const ReservedParkingSpot: React.FC<{ data: RemoteParkingSpot }> = ({ data }) => {
  const estimatedIn = data.reservation.estimatedIssueDate
    ? new Date(data.reservation.estimatedIssueDate)
    : null;

  const estimatedOut = data.reservation.estimatedReturnDate
    ? new Date(data.reservation.estimatedReturnDate)
    : null;

  const tailNumber = data.reservation.tailNumber;
  const aircraftType = data.reservation.aircraftType;
  const airline = getAirline(data.reservation.airlineCode);
  const name = data.reservation.udfs.find(udf => Boolean(udf));
  const phone = data.reservation.phoneNumber;
  const purpose = data.reservation.purpose;
  const reservationId = data.reservation.customerRentalCode;

  return (
    <>
      <TextLabel color="subduedLight" mb="xs" mt="s">
        Next Reservation
      </TextLabel>
      <Stack direction="y" yspace="xs">
        {reservationId ? (
          <Box>
            <strong>Reservation ID: </strong>
            {reservationId}
          </Box>
        ) : null}
        {estimatedIn ? (
          <Box>
            <strong>Est. Time In: </strong>
            {format(estimatedIn, 'LL/dd/yyyy KK:mm aa')}
          </Box>
        ) : null}
        {estimatedOut ? (
          <Box>
            <strong>Est. Time Out: </strong>
            {format(estimatedOut, 'LL/dd/yyyy KK:mm aa')}
          </Box>
        ) : null}
        {tailNumber ? (
          <Box>
            <strong>Tail Number: </strong>
            {tailNumber}
          </Box>
        ) : null}
        {aircraftType ? (
          <Box>
            <strong>Aircraft Type: </strong>
            {aircraftType}
          </Box>
        ) : null}
        {airline ? (
          <Box>
            <strong>Airline: </strong>
            {airline?.name || data.reservation.airline}
          </Box>
        ) : null}
        {name ? (
          <Box>
            <strong>Name: </strong>
            {name}
          </Box>
        ) : null}
        {phone ? (
          <Box>
            <strong>Phone: </strong>
            {phone}
          </Box>
        ) : null}
        {purpose ? (
          <Box>
            <strong>Purpose: </strong>
            {purpose}
          </Box>
        ) : null}
      </Stack>
    </>
  );
};

const VacantParkingSpot: React.FC<{ data: RemoteParkingSpot }> = () => {
  return <></>;
};

const RemoteParkingPopup: React.FC<RemoteParkingPopupProps> = ({
  spot,
  className,
}: RemoteParkingPopupProps) => {
  const isOccupied = spot.reservation.rentalStatus === 'O';
  const isReserved = spot.reservation.rentalStatus === 'U';
  const status = isOccupied ? 'Occupied' : isReserved ? 'Reserved' : 'Vacant';
  const spotName = spot.asset.equipmentNumber.split('.');

  return (
    <LeafletPopup className={className}>
      <Stack direction="y" yspace="xs">
        <Box>
          <strong>Spot: </strong>
          {spotName.length ? spotName[spotName.length - 1] : 'N/A'}
        </Box>
        <Box>
          <strong>Status: </strong>
          {status}
        </Box>
      </Stack>
      {isOccupied ? (
        <OccupiedParkingSpot data={spot} />
      ) : isReserved ? (
        <ReservedParkingSpot data={spot} />
      ) : (
        <VacantParkingSpot data={spot} />
      )}
    </LeafletPopup>
  );
};

export default RemoteParkingPopup;
