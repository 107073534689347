import { themeGet } from '@styled-system/theme-get';
import { differenceInMinutes } from 'date-fns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ALERT_AUTO_CLOSE_DURATION } from 'config/alerts';

import { useLocalStorage } from 'hooks';

import { AlertIcon, Icon } from 'components';

import { Box, Popup as StyledPopup } from 'styled';
import { getAirportTime } from 'utils';

interface Alert {
  category?: string;
  description?: string;
  status?: 'warning' | 'error';
  triggerStat?: string;
  type?: 'scans' | 'traffic' | 'parking' | 'security';
  updatedAt?: Date;
}

export interface Props {
  activeAlert?: Alert;
  category?: string;
  description?: string;
  status?: string;
  updatedAt?: Date;
}

interface StatusAreaProps {
  statusColor: string;
}

const StatusArea = styled.div<StatusAreaProps>`
  border: 2px solid ${props => themeGet(props.statusColor)};
  border-radius: 8px;
  min-height: 70px;
  display: flex;
  padding: 16px 12px;
`;

const ActiveAlertsPopup: React.FC<Props> = ({ activeAlert }: Props) => {
  const now = getAirportTime(new Date());
  const status = activeAlert?.status;
  const updatedAt = activeAlert?.updatedAt || now;
  const category = activeAlert?.category || '';
  const description = activeAlert?.description || '';
  const color = status?.toLowerCase() === 'warning' ? 'warning' : 'error';
  const [timeLeft, setTimeLeft] = useState(ALERT_AUTO_CLOSE_DURATION / 1000 - 1);
  const minutesSinceLastSeen = differenceInMinutes(now, updatedAt);
  const statusColor = status?.toLowerCase() === 'warning' ? `colors.warning` : 'colors.error';
  const closeText = timeLeft ? `CLOSING IN ${Math.round(timeLeft)}...` : 'CLOSING...';
  const lastSeenText = minutesSinceLastSeen ? `${minutesSinceLastSeen} MIN AGO` : 'MOMENTS AGO';
  const { storedValue: lastSeenAlertTime, setValue: setlastSeenAlertTime } = useLocalStorage(
    'lastSeenAlertTime',
    now
  );

  const handleOnClose = () => setTimeLeft(0);

  // Count down auto close timer
  useEffect(() => {
    if (timeLeft <= 0) {
      setlastSeenAlertTime(updatedAt);
      return;
    }
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearTimeout(timer);
  });

  // Hide popup if timer is expired or alert is old
  const isTimerExpired = !timeLeft || timeLeft <= 0;
  const isOldAlert = lastSeenAlertTime === updatedAt;
  if (isTimerExpired || isOldAlert) return null;

  return (
    <StyledPopup width="370px" minHeight="124px" p="12px">
      <Box display="flex" alignItems="center" mb="16px">
        <Box fontSize="base" fontWeight="bold">
          Alerts
        </Box>
        <Box display="flex" ml="auto">
          <Box fontSize="xs" letterSpacing="0.16em" color="neutralsGradient.4">
            {closeText}
          </Box>
          <Icon
            name="x"
            size="16"
            color="neutralsGradient.1"
            ml="l"
            onClick={handleOnClose}
            cursor="pointer"
          />
        </Box>
      </Box>

      <StatusArea statusColor={statusColor}>
        <Box mr="12px" mt="4px">
          <AlertIcon name={category} color={color} description={description} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box
            fontSize="base"
            textAlign="left"
            letterSpacing="-0.01em"
            mb="4px"
            dangerouslySetInnerHTML={{ __html: description }}
          ></Box>
          <Box fontSize="xs" letterSpacing="0.16em" color="neutralsGradient.3">
            {lastSeenText}
          </Box>
        </Box>
      </StatusArea>
    </StyledPopup>
  );
};

export default ActiveAlertsPopup;
