import { LatLng } from 'leaflet';

import theme from 'config/theme';

import Marker from './marker';

interface LineProps {
  code: string;
  positions: LatLng[];
  number: string;
  description: string;
  color: keyof typeof theme.colors;
  translation: string;
}

export default class Line extends Marker {
  notamId?: string;
  positions: LatLng[];

  constructor({ code, positions, number, description, color, translation }: LineProps) {
    super({ code, number, description, color, translation });

    this.positions = positions;
  }
}
