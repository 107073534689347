import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Checkbox = styled.input<SystemProps>`
  accent-color: ${themeGet('colors.inputs')};
  margin-right: ${themeGet('space.xs')};
  margin-top: ${themeGet('space.xs')};

  ${system}
`;

const CheckboxContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: start;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

const CheckboxLabel = styled.label`
  font-size: ${themeGet('fontSizes.s')};
`;

const CheckboxDescription = styled.span`
  color: ${themeGet('colors.subdued')};
  display: block;
  font-size: ${themeGet('fontSizes.s')};
  margin-top: ${themeGet('space.xs')};
`;

export { Checkbox as default, CheckboxContainer, CheckboxLabel, CheckboxDescription };
