export default function getStatusText(area: string): string {
  let status = 'N/A';
  if (area.match(/wip/gi)) status = 'WIP';
  if (area.match(/clsd/gi)) status = 'CLSD';
  if (area.match(/dly/gi)) status = `${status} (DLY)`;
  if (area.match(/u\/s/gi)) status = 'U/S';
  if (area.match(/std/gi)) status = 'NOT STD';
  if (area.match(/removed/gi)) status = 'REMOVED';
  if (area.match(/ficon/gi)) status = 'FICON';
  if (area.match(/obsc/gi)) status = 'OBSC';
  if (area.match(/faded/gi)) status = 'FADED';
  if (area.match(/missing/gi)) status = 'MISSING';
  if (area.match(/sfc/gi)) status = 'ISFC';
  return status;
}
