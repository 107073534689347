import { themeGet } from '@styled-system/theme-get';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

export const HEADER_HEIGHT = '67px';

const Header = styled.header<SystemProps>`
  align-items: center;
  background-color: ${themeGet('colors.neutralsGradient.12')};
  display: grid;
  grid-template-columns: 500px 1fr 500px;
  height: ${HEADER_HEIGHT};
  padding-left: ${themeGet('space.base')};
  padding-right: ${themeGet('space.base')};

  @media screen and (min-width: ${themeGet('breakpoints.lg')}) {
    padding-left: ${themeGet('space.l')};
    padding-right: ${themeGet('space.l')};
  }

  > :nth-child(2n) {
    margin: 0 auto;
  }

  ${system}
`;

const HeaderNav = styled.nav<SystemProps>`
  display: flex;
  height: ${HEADER_HEIGHT};
  line-height: ${HEADER_HEIGHT};

  > *:not(:last-child) {
    margin-right: ${themeGet('space.base')};
  }

  ${system}
`;

const LINK_BORDER_SIZE = '3px';

interface HeaderNavLinkProps extends SystemProps {
  active?: string; // DOM element active prop needs to be a string 😣
}

const HeaderNavLink = styled(Link)<HeaderNavLinkProps>`
  color: ${themeGet('colors.neutralsGradient.5')};
  font-weight: bold;
  margin-top: -${LINK_BORDER_SIZE};
  padding-left: ${themeGet('space.s')};
  padding-right: ${themeGet('space.s')};
  position: relative;
  text-decoration: none;
  transition: 0.2s ease-in-out;

  &:active,
  &:focus,
  &:hover {
    color: ${themeGet('colors.fg')};
  }

  &::after {
    background-color: transparent;
    content: '';
    display: block;
    height: ${LINK_BORDER_SIZE};
    left: 0;
    margin-top: -${LINK_BORDER_SIZE};
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
  }

  ${props =>
    props.active === 'true' &&
    css`
      color: ${themeGet('colors.fg')};

      &::after {
        background-color: ${themeGet('colors.primary')};
      }
    `}

  ${system}
`;

export { Header as default, HeaderNav, HeaderNavLink };
