import { gql, useQuery } from '@apollo/client';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { FacilityCondition } from './types';

const FACILITY_CONDITIONS = gql`
  query FacilityConditions {
    facilityConditions {
      id
      coordinates
      feed
      assets
      importance
      generator
      condition
      value
      timestamp
      issueId
    }
  }
`;

export default function useFacilityConditions({ refresh = true }: { refresh?: boolean } = {}) {
  const options = {
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery<{ facilityConditions: FacilityCondition[] }>(FACILITY_CONDITIONS, options);
  const facilityConditions: FacilityCondition[] = query?.data?.facilityConditions || [];

  return {
    facilityConditions,
    ...query,
  };
}
