import { format } from 'date-fns';
import { maxBy, orderBy, sortBy, sumBy } from 'lodash';
import React, { useState } from 'react';

import { useBathroom, useBathrooms } from 'client/Bathroom';
import { Bathroom as BathroomType, BathroomArea } from 'client/Bathroom/types';

import { useRefreshedAt } from 'hooks';

import { useTileOptionsState } from 'providers/TileOptionsProvider';

import {
  CapacityStatus,
  CircularLoading,
  Icon,
  LinkButton,
  Loader,
  QueryCell,
  Table,
  Tooltip,
  UpdatedAt,
} from 'components';
import { Heading } from 'components/Table';

import { Box, TextLabel, Uppercase } from 'styled';
import { formatAirportTime } from 'utils';

interface OptionFilters {
  types: string[];
  selectedTypes: string[];
}

interface Props {
  filters?: OptionFilters;
  headings: Heading[];
  fullscreen?: boolean;
  sortByColumn?: string;
  isReverseSort?: boolean;
  label?: string;
}

interface RowPropsData {
  data?: RowProps;
}

interface RowProps {
  id?: string;
}

const HIGH_THRESHOLD = 75;
const NORMAL_THRESHOLD = 50;

const BathroomTraffic: React.FC<Props> = ({
  fullscreen = false,
  headings,
  isReverseSort,
  sortByColumn,
}) => {
  const bathroomsData = useBathrooms();
  const bathrooms = bathroomsData?.bathrooms || [];
  const optionsState = useTileOptionsState();
  const [selectedBathroom, setSelectedBathroom] = useState<BathroomType>();
  const [selectedCode, setSelectedCode] = useState<string>();
  const bathroomDetailsResult = useBathroom({ code: selectedCode });
  const {
    loading: detailsLoading,
    error: detailsError,
    bathroom: bathroomDetails,
  } = bathroomDetailsResult;
  const { refreshedAt } = useRefreshedAt(bathroomsData?.loading);
  if (process.env.REACT_APP_CONFIG_BATHROOMS !== 'true') return null;

  const grid = '1.25fr repeat(2, 1fr) 0.75fr';

  // Sort bathroom data
  let sortedBathrooms = sortBy(bathrooms, sortByColumn || '');
  if (isReverseSort) sortedBathrooms = sortedBathrooms.reverse();

  const getBathroomStatusColor = (count?: number | undefined, max?: number | undefined): string => {
    if (!count || !max) return 'neutralsGradient.1';
    const percent = (count / max) * 100;
    if (percent < NORMAL_THRESHOLD) return 'neutralsGradient.1';
    return percent < HIGH_THRESHOLD ? 'warning' : 'error';
  };

  const handleRowClick = (data: RowPropsData): void => {
    const bathroomData = data?.data;
    const id = bathroomData?.id;
    const bathroom = bathrooms?.find(bt => bt?.id === id);
    if (bathroom) {
      setSelectedBathroom(bathroom);
      setSelectedCode(bathroom.id);
    }
  };

  const renderCountCards = () => {
    const areas = optionsState?.bathroomTraffic?.filters?.types;
    const mappedAreas = areas
      ?.map((area: string) => {
        const currentAreas = bathrooms?.filter(bt => bt?.area === area);
        const bathroomsOverThreshold = currentAreas?.filter(area => {
          if (!area?.count || !area?.threshold) return false;
          return area?.count >= area?.threshold * (HIGH_THRESHOLD * 0.01);
        });
        const count = sumBy(currentAreas, 'count');
        return {
          area,
          bathroomsOverThreshold,
          count,
          updatedAt: currentAreas?.[0]?.updatedAt,
          hasError: !!bathroomsOverThreshold?.length,
        };
      })
      .filter(bt => !!bt?.updatedAt);

    return mappedAreas?.map((area: BathroomArea, i: number) => (
      <Box
        key={`${area}-${i}`}
        border="1px solid"
        borderColor={area?.hasError ? 'error' : 'neutralsGradient.7'}
        borderRadius="l"
        px="m"
        pt="base"
        pb="xs"
      >
        <Tooltip
          label={
            area?.bathroomsOverThreshold?.length ? (
              <Box display="flex" flexDirection="column">
                {area?.bathroomsOverThreshold?.map(a => (
                  <Box as="span" key={a?.id}>
                    {a?.id}
                  </Box>
                ))}
              </Box>
            ) : (
              'No issues'
            )
          }
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" height="42px">
            <Box as="h2" fontWeight="normal">
              {area?.area}
            </Box>
            <Icon
              name={area?.hasError ? 'xCircle' : 'checkCircle'}
              color={area?.hasError ? 'error' : 'success'}
              size="24"
            />
          </Box>
          <Box fontSize="66px" fontWeight="bold" lineHeight="55px" height="55px">
            {area?.count}
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" height="42px">
            <Box fontSize="xs" color="neutralsGradient.3" letterSpacing="0.16em">
              <Uppercase>in the past hour</Uppercase>
            </Box>
            <Box fontSize="xs" color="neutralsGradient.3" letterSpacing="0.16em" display="flex">
              <Uppercase fontSize="xs" letterSpacing="0.16em" color="neutralsGradient.3" mr="xs">
                last checked
              </Uppercase>
              {` ${area?.updatedAt ? format(area?.updatedAt, 'p') : 'N/A'}`}
            </Box>
          </Box>
        </Tooltip>
      </Box>
    ));
  };

  const renderHighestTrafficBathroomCard = () => {
    const bathroom = maxBy(bathrooms, 'count');
    const { id = '', count = 0, threshold = 100 } = bathroom as BathroomType;
    const genderLetter = id?.slice(-1);
    const gender = genderLetter === 'f' ? 'Female' : 'Male';
    const name = id?.slice(0, -1);
    const title = `${name} (${gender})`;
    const hasError = count >= threshold;

    return (
      <Box
        border="1px solid"
        borderColor={hasError ? 'error' : 'neutralsGradient.7'}
        borderRadius="l"
        px="m"
        pt="base"
        pb="xs"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" height="42px">
          <Box as="h2" fontWeight="normal">
            {`Highest Traffic: ${title}`}
          </Box>
          <Icon
            name={hasError ? 'xCircle' : 'checkCircle'}
            color={hasError ? 'error' : 'success'}
            size="24"
          />
        </Box>
        <Box fontSize="66px" fontWeight="bold" lineHeight="55px" height="55px">
          {bathroom?.count}
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" height="42px">
          <Box fontSize="xs" color="neutralsGradient.3" letterSpacing="0.16em">
            <Uppercase>in the past hour</Uppercase>
          </Box>
          <Box fontSize="xs" color="neutralsGradient.3" letterSpacing="0.16em" display="flex">
            <Uppercase fontSize="xs" letterSpacing="0.16em" color="neutralsGradient.3" mr="xs">
              last checked
            </Uppercase>
            {` ${bathroom?.updatedAt ? format(bathroom?.updatedAt, 'p') : 'N/A'}`}
          </Box>
        </Box>
      </Box>
    );
  };

  const filterBathroomTraffic = (bathroomTraffic: BathroomType): boolean => {
    if (fullscreen) return true;
    const selectedTypes = optionsState?.bathroomTraffic?.filters?.selectedTypes;
    const area = bathroomTraffic?.area;
    const isConcourseA = area === 'Concourse A' && selectedTypes?.includes('Concourse A');
    const isConcourseB = area === 'Concourse B' && selectedTypes?.includes('Concourse B');
    const isBaggage = area === 'Baggage Claim' && selectedTypes?.includes('Baggage Claim');
    const isCustoms = area === 'Customs' && selectedTypes?.includes('Customs');
    const isTicketing = area === 'Ticketing' && selectedTypes?.includes('Ticketing');

    return isConcourseA || isConcourseB || isBaggage || isCustoms || isTicketing;
  };

  const renderSelectedBathroom = () => {
    if (detailsLoading) {
      return (
        <Box mt="xl">
          <Loader />
        </Box>
      );
    }

    const inspections = bathroomDetails?.inspections || [];
    const hasNoData = detailsError || inspections.length === 0;
    let inspection;
    if (!hasNoData) {
      inspection = orderBy(inspections, 'updatedAt', 'desc')?.[0];
    }

    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="neutralsGradient.10"
        >
          <LinkButton
            display="flex"
            alignItems="center"
            onClick={() => setSelectedBathroom(undefined)}
          >
            <Icon name="chevronLeft" size="24" />
            Back
          </LinkButton>
          <Box m="0 auto" display="flex" mt="xs">
            <Icon
              name={
                selectedBathroom?.id && selectedBathroom?.id?.slice(-1) === 'F' ? 'female' : 'male'
              }
              size="28"
              fill={getBathroomStatusColor(selectedBathroom?.count, selectedBathroom?.threshold)}
              stroke={getBathroomStatusColor(selectedBathroom?.count, selectedBathroom?.threshold)}
            />
            <Box as="h2">{`${selectedBathroom?.id?.slice(0, -1)} • ${selectedBathroom?.area}`}</Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mt="xl">
          {hasNoData || !inspection ? (
            <Box as="h3">Sorry, no details available.</Box>
          ) : (
            <>
              <Box position="relative" height="170px" width="170px">
                <CircularLoading percent={Math.round(inspection?.score || 0)} />
                <Box position="absolute" top="30%" width="100%" fontSize="h1" fontWeight="bold">
                  <Box textAlign="center">{`${Math.round(inspection?.score || 0)}%`}</Box>
                </Box>
                <Box
                  position="absolute"
                  color="neutralsGradient.4"
                  left="17%"
                  top="52%"
                  width="155px"
                  fontSize="base"
                  fontWeight="bold"
                >
                  <Uppercase>overall score</Uppercase>
                </Box>
              </Box>
              <Box mx="xl">
                <Box display="flex" flexDirection="column">
                  <Box fontSize="xs" color="neutralsGradient.4" fontWeight="bold" mt="base">
                    <Uppercase>inspector</Uppercase>
                  </Box>
                  <Box fontSize="h2">{inspection?.inspector || 'N/A'}</Box>
                  <Box fontSize="xs" color="neutralsGradient.4" fontWeight="bold" mt="l">
                    <Uppercase>duration</Uppercase>
                  </Box>
                  <Box fontSize="h2">{`${inspection?.durationMinutes || 'N/A'} Minutes`}</Box>
                </Box>
              </Box>
              <Box>
                <Box fontSize="xs" color="neutralsGradient.4" fontWeight="bold" mt="base">
                  <Uppercase>completed</Uppercase>
                </Box>
                {inspection?.updatedAt ? (
                  <Box fontSize="h2">
                    {formatAirportTime(new Date(inspection?.updatedAt), 'Pp')}
                  </Box>
                ) : (
                  <Box fontSize="h2">N/A</Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </>
    );
  };

  const renderData = (): {
    [key: string]: JSX.Element | string | number | undefined | BathroomType;
  }[] => {
    return sortedBathrooms?.filter(filterBathroomTraffic)?.map((data: BathroomType) => ({
      bathroom: (
        <Box position="relative">
          <Icon
            name={data?.id && data?.id?.slice(-1) === 'F' ? 'female' : 'male'}
            size="26"
            position="absolute"
            top="-1px"
            fill={getBathroomStatusColor(data?.count, data?.threshold)}
            stroke={getBathroomStatusColor(data?.count, data?.threshold)}
          />
          <Box ml="base">{data?.id?.slice(0, -1)}</Box>
        </Box>
      ),
      area: <Tooltip label={data?.area || ''}>{data?.area}</Tooltip>,
      count:
        data?.count && data?.threshold && data?.count > 0 ? (
          <CapacityStatus
            occupancy={data?.count}
            capacity={data?.threshold || 0}
            load={(data?.count / data?.threshold) * 100}
            hideCapacityNumber={true}
          />
        ) : (
          <TextLabel color="subdued">N/A</TextLabel>
        ),
      updated: data?.updatedAt ? format(data?.updatedAt, 'p') : 'N/A',
      data,
    }));
  };

  const renderTable = () => (
    <>
      <Table
        name="bathroomTraffic"
        headings={headings}
        data={renderData()}
        bordered={true}
        cellPaddingX="s"
        cellPaddingY="s"
        dataFontSize="s"
        grid={grid}
        gridGap="xs"
        striped={false}
        onRowClick={fullscreen ? handleRowClick : undefined}
      />
      <UpdatedAt date={refreshedAt} loading={bathroomsData?.loading} />
    </>
  );

  return (
    <QueryCell
      content="bathroom traffic"
      data={bathrooms || []}
      error={bathroomsData?.error}
      loading={bathroomsData?.loading}
    >
      {fullscreen && (
        <Box
          alignItems="center"
          display="grid"
          justifyContent="center"
          gridRowGap="l"
          gridColumnGap="l"
          gridTemplateColumns="repeat(3, 1fr)"
          pb="l"
        >
          {renderCountCards()}
          {renderHighestTrafficBathroomCard()}
        </Box>
      )}
      {selectedBathroom ? renderSelectedBathroom() : renderTable()}
    </QueryCell>
  );
};

export default BathroomTraffic;
