import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const ChartOverlay = styled.div<SystemProps>`
  background-color: transparent;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${themeGet('zIndices.baseOverlay')};
  ${system}
`;

export const ChartOverlayBackground = styled.div<SystemProps>`
  background-image: url(${props => props.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  left: 50px;
  opacity: 15%;
  pointer-events: none;
  position: absolute;
  right: 50px;
  top: 90px;
  z-index: 0;
`;

export default ChartOverlay;
