import React, { useEffect } from 'react';

import { getAirline } from 'config/airlines';

import { Booking } from 'client/Bookings/types';

import { useDataLimiter } from 'hooks';

import { AirlineIcon, Table, Tile, Tooltip } from 'components';
import { DetailsComponentProps } from 'components/ChartDetails';

import { Box } from 'styled';

import DetailsNav from './DetailsNav';

const LIMIT = 20;

const BookingDetails: React.FC<DetailsComponentProps> = ({ data }: DetailsComponentProps) => {
  const dataLimiter = useDataLimiter(LIMIT);

  useEffect(() => {
    dataLimiter.setData(data);
  }, [data, dataLimiter]);

  if (!data) return null;

  if (dataLimiter.status === 'loading') return null;

  return (
    <Tile
      title={`Bookings (${dataLimiter.resultsDescription})`}
      icon="arrowUpAndRight"
      contentAlignment="top"
      contentPadding="0"
    >
      <Table
        grid="repeat(5, 1fr)"
        striped={false}
        bordered={true}
        cellPaddingX="s"
        cellPaddingY="s"
        dataFontSize="s"
        gridGap="xs"
        headings={[
          { key: 'airline', label: 'Airline' },
          { key: 'flightNumber', label: 'Flight Number' },
          { key: 'date', label: 'Date' },
          { key: 'time', label: 'Time' },
          { key: 'count', label: 'Bookings' },
        ]}
        data={dataLimiter.currentData.map((d: Booking) => {
          const airline = getAirline(d.airline);
          return {
            ...d,
            airline: (
              <Box>
                <AirlineIcon airline={airline} />
                <Tooltip label={airline?.name || d.airline}>{d.airline}</Tooltip>
              </Box>
            ),
          };
        })}
      />
      <DetailsNav dataLimiter={dataLimiter} />
    </Tile>
  );
};

export default BookingDetails;
