import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const Tabs = styled.div<SystemProps>`
  border: 1px solid ${themeGet('colors.border')};
  border-radius: 100px;
  display: flex;

  ${system}
`;

const Tab = styled.button<{ selected?: boolean; secondary?: boolean }>`
  background-color: transparent;
  border: 0;
  border-radius: 40px;
  color: ${themeGet('colors.subdued')};
  cursor: pointer;
  display: block;
  font-size: ${themeGet('fontSizes.s')};
  font-weight: ${themeGet('fontWeights.bold')};
  margin: -1px;
  padding: ${themeGet('space.s')} ${themeGet('space.base')};
  transition: 0.2s ease-in-out;

  &:active,
  &:focus,
  &:hover {
    color: ${themeGet('colors.fg')};
    outline: none;
  }

  ${props =>
    props.selected &&
    css`
      background-color: ${themeGet('colors.fg')};
      box-shadow: ${themeGet('shadows.base')};
      color: ${themeGet('colors.bg')};

      &:active,
      &:focus,
      &:hover {
        color: ${themeGet('colors.bg')};
      }
    `};

  ${props =>
    props.selected &&
    props.secondary &&
    css`
      background-color: ${themeGet('colors.neutralsGradient.10')};
      box-shadow: inset 0px 0px 0px 1px ${themeGet('colors.neutralsGradient.8')};
      color: ${themeGet('colors.fg')};

      &:active,
      &:focus,
      &:hover {
        color: ${themeGet('colors.fg')};
      }
    `};
`;

const TabsContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;

export { Tabs as default, Tab, TabsContainer };
