import { LatLng } from 'leaflet';

import theme from 'config/theme';

import Marker from './marker';

interface PointProps {
  code: string;
  position: LatLng;
  number: string;
  description: string;
  color: keyof typeof theme.colors;
  translation: string;
}

export default class Point extends Marker {
  notamId?: string;
  position: LatLng;

  constructor({ code, position, number, description, color, translation }: PointProps) {
    super({ code, number, description, color, translation });

    this.position = position;
  }
}
