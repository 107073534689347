import React, { useState } from 'react';

interface Props {
  children: JSX.Element;
  renderToggle: Function;
}

const HideAndShow: React.FC<Props> = ({ children, renderToggle }: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      {renderToggle(visible, setVisible)}
      {visible ? children : null}
    </>
  );
};

export default HideAndShow;
