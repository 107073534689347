import React from 'react';
import { useLocation } from 'react-router-dom';

import { Icon } from 'components';

import { Toast as StyledToast } from 'styled';

export interface Props {
  type?: string;
  text?: string;
}

const Toast: React.FC<Props> = ({ type, text }: Props) => {
  const location = useLocation();
  function getIcon(): string {
    if (type === 'success') return 'check';
    if (type === 'error') return 'x';
    return 'i';
  }

  if (location.pathname === '/') {
    return (
      <StyledToast text={text} type={type}>
        <Icon name={getIcon()} color={type || 'subduedLight'} mr="s" />
        {text}
      </StyledToast>
    );
  } else {
    return (
      <StyledToast text={text} type={type} bottom="base">
        <Icon name={getIcon()} color={type || 'subduedLight'} mr="s" />
        {text}
      </StyledToast>
    );
  }
};

export default Toast;
