import { themeGet } from '@styled-system/theme-get';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

import { Loader } from 'components';

Highcharts.setOptions({
  lang: {
    numericSymbols: [],
  },
  chart: {
    style: {
      fontFamily: themeGet('fontFamily') as any,
    },
  },
});

interface Props {
  highchartsProps: HighchartsReact.Props;
  dataLoading?: boolean;
  dataProcessing?: boolean;
}

const HighchartsCustom = ({ highchartsProps, dataLoading, dataProcessing }: Props): JSX.Element => {
  // TODO: Remove this once https://github.com/highcharts/highcharts/issues/13710 is fixed
  if (highchartsProps?.options?.series?.find(c => c.type === 'pie' && !c.data?.length)) {
    return <></>;
  }

  if (dataLoading) {
    return <Loader color="primary" label="Loading data..." />;
  } else if (dataProcessing) {
    return <Loader color="primary" label="Processing data..." />;
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { width: '100%', height: '100%' } }}
      {...highchartsProps}
    />
  );
};

export default HighchartsCustom;
