import sortBy from 'lodash/sortBy';

import { FlowTime, FlowGate } from 'data/Flow/types';
import { ChartDatum, DataObject } from 'statistics/types';

import { ProcessedDataObject } from './utils';

function getChartData(data: FlowTime[]): ChartDatum[] {
  const flowData = data.map((flowTime: FlowTime) => {
    const total = flowTime.gates.reduce((a: number, b: FlowGate) => a + b.count, 0);
    return {
      x: Number(flowTime.time),
      y: total,
    };
  });
  return sortBy(flowData, 'x');
}

function getProcessedData(
  flowData: DataObject,
  gates: string[],
  type: string
): ProcessedDataObject {
  const data = (flowData?.data as FlowTime[]) || [];
  const indexNumber = parseInt(type?.substring(type.length - 1));
  const gate = gates[indexNumber - 1];

  const filteredData = data.map((flowTime: FlowTime) => {
    if (flowTime.gates.length === 0) return flowTime;
    const gates = flowTime.gates;
    const filteredGates = gates.filter((flowGate: FlowGate) => flowGate.gate === gate);
    return { ...flowTime, gates: filteredGates };
  });

  const loading = flowData?.loading;
  const error = flowData?.error;

  if (loading || error || !gates?.length)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
      stats: {
        loading,
        error,
        data: {},
      },
      details: {
        loading,
        error,
        data: [],
      },
    };

  const chartData = getChartData(filteredData);
  const formattedChartData = [{ airline: 'Total', data: chartData }];

  return {
    chart: {
      loading,
      error,
      data: formattedChartData,
    },
    stats: {
      loading,
      error,
      data: {},
    },
    details: {
      loading,
      error,
      data: [],
    },
  };
}

export default { getProcessedData };
