import React from 'react';

import { weatherCodeMap } from 'config/weather';

import { Icon } from 'components';
import { Props as IconProps } from 'components/Icon';

interface Props {
  conditionCode: number;
  icon?: IconProps;
}

const WeatherConditionIcon: React.FC<Props> = ({ conditionCode, icon: _icon }) => {
  const iconProps = { size: '16', ..._icon };
  return <Icon {...iconProps} name={weatherCodeMap[conditionCode].icon} />;
};

export default WeatherConditionIcon;
