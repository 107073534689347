// @ts-nocheck
import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';
import sample from 'lodash/sample';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import CHART_COLORS from 'config/chartColors';
import theme from 'config/theme';

import { getDefaultSeriesOptions, getVisibility } from 'statistics/charts/utils';
import { ChartDatum } from 'statistics/types';

function getColor(terminal?: string): string {
  const randomColor = sample(Object.values(theme.colors.chart)) as string;
  if (!terminal) return randomColor;
  return CHART_COLORS[`TSA Counts - ${terminal}`] || randomColor;
}

export default function getSeries(
  data: ChartDatum[],
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType[] {
  const terminals = uniq(data.map(d => d.name));
  return terminals.map(terminal => {
    const _data: ChartDatum[] = [];
    data.forEach(d => d.name === terminal && _data.push(d));
    return merge(getDefaultSeriesOptions(), {
      yAxis: 'dailyThroughputs',
      name: terminal,
      color: getColor(terminal),
      data: sortBy(_data, 'x'),
      tooltip: {
        pointFormatter(): string {
          let { y } = this;
          const { color, series } = this;
          y = Math.ceil(y || 0);
          return `<span style="color:${color}">●</span> ${
            series?.name
          }: <b>${y.toLocaleString()}</b><br/>`;
        },
      },
      ...chartOptions,
      type: 'line',
      visible: getVisibility(terminal),
    } as SeriesOptionsType);
  });
}
