import { Passenger } from 'data/Passengers/types';

import { filterByDayRange } from 'statistics/charts/utils';
import { DataObject, PassengerChartDatum } from 'statistics/types';

import { ProcessedDataObject } from './utils';
import { groupBy } from 'lodash';

interface Options {
  filterValuesLessThanOne: boolean;
}
const greaterThanOne = (i: PassengerChartDatum): boolean => i.y > 0;

function getChartData(
  passengers: Passenger[],
  field: 'age' | 'gender' | 'passengerStatus',
  options: Options = { filterValuesLessThanOne: true }
): PassengerChartDatum[] {
  const groupedPassengers =
    field === 'age' ? groupBy(passengers, p => Math.floor(p.age / 10)) : groupBy(passengers, field);
  const data = Object.keys(groupedPassengers).map(key => ({
    name: field === 'age' ? `${parseInt(key) * 10}-${10 + parseInt(key) * 10}` : key,
    y: groupedPassengers[key].length,
    total: passengers.length,
  }));

  return data.filter(i => (options.filterValuesLessThanOne ? greaterThanOne(i) : i));
}

function getProcessedData(
  passengersData: DataObject,
  dateRangeDays: string[],
  options: Options = { filterValuesLessThanOne: true },
  field: 'age' | 'gender' | 'passengerStatus'
): ProcessedDataObject {
  const { data: passengers, loading, error } = passengersData;
  if (loading || error || !passengers)
    return {
      chart: {
        loading,
        error,
        data: [],
      },
    };
  const { byDayRange } = filterByDayRange(dateRangeDays);
  const filteredPassengers = passengers.filter(datum =>
    byDayRange((datum as Passenger).transactionTime)
  );
  const chartData = getChartData(filteredPassengers as Passenger[], field, options);

  return {
    chart: {
      loading,
      error,
      data: chartData,
    },
  };
}

export default {
  age: {
    getProcessedData: (
      passengersData: DataObject,
      dateRangeDays: string[],
      options: Options = { filterValuesLessThanOne: true }
    ): ProcessedDataObject => getProcessedData(passengersData, dateRangeDays, options, 'age'),
  },
  gender: {
    getProcessedData: (
      passengersData: DataObject,
      dateRangeDays: string[],
      options: Options = { filterValuesLessThanOne: true }
    ): ProcessedDataObject => getProcessedData(passengersData, dateRangeDays, options, 'gender'),
  },
  status: {
    getProcessedData: (
      passengersData: DataObject,
      dateRangeDays: string[],
      options: Options = { filterValuesLessThanOne: true }
    ): ProcessedDataObject =>
      getProcessedData(passengersData, dateRangeDays, options, 'passengerStatus'),
  },
};
