// @ts-nocheck
import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';

import CHART_COLORS, { ChartColorsKey } from 'config/chartColors';

import { getDefaultSeriesOptions, getVisibility } from 'statistics/charts/utils';
import { BaggageArea, ChartDatum } from 'statistics/types';

export default function getSeries(
  data: ChartDatum[],
  area: BaggageArea,
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType {
  switch (area) {
    case 'B-Bags':
    case 'T-Drive':
      return merge(getDefaultSeriesOptions(), {
        ...chartOptions,
        name: area,
        yAxis: 'baggage',
        borderWidth: 0,
        data,
        color: CHART_COLORS[`Baggage - ${area}` as ChartColorsKey],
        stacking: 'normal',
        stack: 'baggage',
        tooltip: {
          pointFormatter(): string {
            let { y } = this;
            const { color, series } = this;
            y = Math.ceil(y || 0);
            return `<span style="color:${color}">●</span> ${
              series?.name
            }: <b>${y.toLocaleString()}</b><br/>`;
          },
        },
        type: 'column',
        visible: getVisibility(area),
      });
    case 'Bags Received':
    case 'Bags Cleared':
      return merge(getDefaultSeriesOptions(), {
        ...chartOptions,
        name: area,
        yAxis: 'baggage',
        borderWidth: 0,
        data,
        color: CHART_COLORS[`Baggage - ${area}` as ChartColorsKey],
        stacking: 'normal',
        stack: 'cbra',
        tooltip: {
          pointFormatter(): string {
            let { y } = this;
            const { color, series } = this;
            y = Math.ceil(y || 0);
            return `<span style="color:${color}">●</span> ${
              series?.name
            }: <b>${y.toLocaleString()}</b><br/>`;
          },
        },
        type: 'column',
        visible: getVisibility(area),
      });
    case 'ML1 Read Rate %':
    case 'ML2 Read Rate %':
    case 'CB1 Read Rate %':
      return merge(getDefaultSeriesOptions(), {
        ...chartOptions,
        data,
        name: area,
        yAxis: 'readRate',
        borderWidth: 0,
        color: CHART_COLORS[area as ChartColorsKey],
        tooltip: {
          pointFormatter(): string {
            let { y } = this;
            const { color, series } = this;
            y = Math.ceil(y || 0);
            return `<span style="color:${color}">●</span> ${
              series?.name
            }: <b>${y.toLocaleString()}</b><br/>`;
          },
        },
        type: 'line',
        visible: getVisibility(area),
      });
    case 'Total':
    default:
      return merge(getDefaultSeriesOptions(), {
        ...chartOptions,
        visible: false,
        yAxis: 'baggage',
        showInLegend: false,
        stacking: 'normal',
        stack: 'baggage',
        tooltip: {
          pointFormatter(): string {
            let { y } = this;
            const { color, series } = this;
            y = Math.ceil(y || 0);
            return `<span style="color:${color}">●</span> ${
              series?.name
            }: <b>${y.toLocaleString()}</b><br/>`;
          },
        },
        type: 'column',
      } as SeriesOptionsType);
  }
}
