import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { useIssuesFilterState } from 'providers/IssuesFilterProvider';

import { Issue } from './types';
import { GET_ISSUES } from './useIssues';
import { getAirportTime } from 'utils';

const CREATE_ISSUE = gql`
  mutation CreateIssue(
    $assignee: String
    $status: String
    $severity: String
    $title: String
    $description: String
  ) {
    createIssue(
      assignee: $assignee
      status: $status
      severity: $severity
      title: $title
      description: $description
    ) {
      id
      createdAt
      updatedAt
      status
      severity
      title
      description
      assignee
    }
  }
`;

export function getOptimisticResponse(data: Issue) {
  const now = getAirportTime(new Date());
  return {
    optimisticResponse: {
      __typename: 'Mutation',
      createIssue: {
        __typename: 'Issue',
        ...data,
        id: Math.random() * 100,
        createdAt: now,
        updatedAt: now,
        status: 'open',
      },
    },
  };
}

export default function useCreateIssue(options: MutationHookOptions = {}) {
  const { order, status } = useIssuesFilterState();

  return useMutation(CREATE_ISSUE, {
    update(cache, { data: { createIssue } }) {
      const queryResult = cache.readQuery<{ issues: Issue[] }>({
        query: GET_ISSUES,
        variables: { order, status },
      });
      const issues = queryResult?.issues || [];
      cache.writeQuery({
        query: GET_ISSUES,
        data: { issues: issues.concat([createIssue]) },
        variables: { order, status },
      });
    },
    ...options,
  });
}
