import { Airport } from 'client/Airport/types';
import { Delay } from 'client/Delay/types';

export function getAirportDelays(airport: Airport, delays: Delay[]): Delay[] | [] {
  if (delays.length === 0) return [];
  // We're just going to return the latest one.
  return delays.filter((d: Delay) => d.airportCode === airport.code).slice(0, 1);
}

export default getAirportDelays;
