import React from 'react';

import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { Checkbox, FormField, Select, SortByOptions } from 'components';

interface Props {
  type: 'arrivals' | 'departures';
}

const FlightsOptions: React.FC<Props> = ({ type }: Props) => {
  const cargoEnabled = process.env.REACT_APP_CONFIG_CARGO === 'true';

  const [state, dispatch] = useTileOptions();
  const filters = state[type].filters;

  function handleStatusChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const status = event.target.name.toUpperCase();
    const checked = filters.status.includes(status);
    let newStatuses: string[] = [];

    if (checked) {
      newStatuses = filters.status.filter((s: string) => s !== status);
    } else {
      newStatuses = [...filters.status, status];
    }

    dispatch(
      updateOptions({
        [type]: {
          filters: {
            ...filters,
            status: newStatuses,
          },
        },
      })
    );
  }

  function handleLimitChange(event: React.ChangeEvent<HTMLInputElement>): void {
    dispatch(
      updateOptions({
        [type]: {
          filters: {
            ...filters,
            limit: Number(event.target.value),
          },
        },
      })
    );
  }

  function handleTypeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const flightType = event.target.name.toLowerCase();
    const checked = filters?.types?.includes(flightType);
    let newTypes: string[] = [];

    if (checked) {
      newTypes = filters?.types?.filter((t: string) => t !== flightType);
    } else {
      newTypes = filters?.types ? [...filters?.types, flightType] : [flightType];
    }

    dispatch(
      updateOptions({
        [type]: {
          filters: {
            ...filters,
            types: newTypes,
          },
        },
      })
    );
  }

  function handleCarrierTypeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const carrierType = event.target.name.toLowerCase();
    const checked = filters?.carrierType?.includes(carrierType);
    let newTypes: string[] = [];

    if (checked) {
      newTypes = filters?.carrierType?.filter((t: string) => t !== carrierType);
    } else {
      newTypes = filters?.carrierType ? [...filters?.carrierType, carrierType] : [carrierType];
    }

    dispatch(
      updateOptions({
        [type]: {
          filters: {
            ...filters,
            carrierType: newTypes,
          },
        },
      })
    );
  }

  return (
    <>
      <SortByOptions
        stateKey={type}
        options={[
          { value: 'city', label: 'City' },
          { value: 'flightNum', label: 'Flight' },
          { value: 'time', label: 'Time' },
          { value: 'statusText', label: 'Status' },
        ]}
      />
      <FormField label={`Types of flights to show`} type="stacked" mb="base">
        <Checkbox
          label="Passenger"
          name="passenger"
          checked={filters?.types?.includes('passenger')}
          onChange={handleTypeChange}
          mb="s"
        />
        <Checkbox
          label="Cargo"
          name="cargo"
          checked={filters?.types?.includes('cargo')}
          onChange={handleTypeChange}
          mb="s"
        />
      </FormField>
      {cargoEnabled && (
        <FormField label={`Types of carriers to show`} type="stacked" mb="base">
          <Checkbox
            label="Amazon"
            name="amazon"
            checked={filters?.carrierType?.includes('amazon')}
            onChange={handleCarrierTypeChange}
            mb="s"
          />
          <Checkbox
            label="DHL"
            name="dhl"
            checked={filters?.carrierType?.includes('dhl')}
            onChange={handleCarrierTypeChange}
            mb="s"
          />
          <Checkbox
            label="Other"
            name="nondhl"
            checked={filters?.carrierType?.includes('nondhl')}
            onChange={handleCarrierTypeChange}
            mb="s"
          />
        </FormField>
      )}
      <FormField label={`Types of ${type} to show`} type="stacked" mb="base">
        <Checkbox
          label="On Time"
          name="onTime"
          checked={filters.status.includes('ONTIME')}
          onChange={handleStatusChange}
          mb="s"
        />
        <Checkbox
          label="Delayed"
          name="delayed"
          checked={filters.status.includes('DELAYED')}
          onChange={handleStatusChange}
          mb="s"
        />
        <Checkbox
          label="Canceled"
          name="canceled"
          checked={filters.status.includes('CANCELED')}
          onChange={handleStatusChange}
          mb="s"
        />
      </FormField>
      <FormField label={`Number of ${type} to show`} type="stacked">
        <Select
          type="radio"
          name="limit"
          value={String(filters.limit)}
          options={[
            { value: '5', label: `5 ${type}` },
            { value: '10', label: `10 ${type}` },
            { value: '20', label: `20 ${type}` },
          ]}
          onChange={handleLimitChange}
        />
      </FormField>
    </>
  );
};

export default FlightsOptions;
