import { format, isSameMonth } from 'date-fns';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';

import { useFeedStatusData, useFeedStatusHistoryData } from 'client/FeedStatus';
import { DateOption, FeedStatus, FeedStatusHistory, TypeOption } from 'client/FeedStatus/types';

import { useFeedStatus } from 'hooks';

import { Icon, LinkButton, Loader, Modal, Select, Tooltip } from 'components';

import { Box, Title } from 'styled';

export const feedConfigs: { [key: string]: boolean } = {
  baggage: process.env.REACT_APP_CONFIG_BAGGAGE === 'true' || false,
  bookings: process.env.REACT_APP_CONFIG_BOOKINGS === 'true' || false,
  delays: true, //not optional
  facilityConditions: process.env.REACT_APP_CONFIG_FACILITY === 'true' || false,
  flight: true, //not optional
  parking: process.env.REACT_APP_CONFIG_PARKING_SALES === 'true' || false,
  remoteParking: process.env.REACT_APP_CONFIG_REMOTE_PARKING === 'true' || false,
  sales: process.env.REACT_APP_CONFIG_SALES === 'true' || false,
  security: process.env.REACT_APP_CONFIG_WAIT_TIMES === 'true' || false,
  throughput: true, //not optional
  traffic: true, //not optional
  weather: true, //not optional
};

const FeedStatusModal: React.FC = props => {
  const [isHistoryMode, setIsHistoryMode] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  // History filter select options
  const [selectedDateOption, setSelectedDateOption] = useState<DateOption>({
    label: format(new Date(), 'LLLL yyyy'),
    value: new Date().toString(),
  });
  const [selectedTypeOption, setSelectedTypeOption] = useState<TypeOption>({
    label: 'All',
    value: 'All',
  });

  // Feed Status Data
  const feedStatusData = useFeedStatusData();
  const feedStatus =
    sortBy(feedStatusData?.data?.feedStatus, 'feed').filter(
      feed => feedConfigs[feed.feed] === true
    ) || [];

  const feedStatusHistoryData = useFeedStatusHistoryData(new Date(selectedDateOption.value));
  const feedStatusHistory = feedStatusHistoryData?.data?.feedStatusHistory || [];
  const feedsWithIssues = feedStatus?.length
    ? feedStatus?.filter(feed => feed.status !== 'NORMAL')
    : [];

  const {
    filterHistoryData,
    getFeedIssuesTitle,
    getFeedStatusTitle,
    getHistoryResults,
    getStatusColor,
    getStatusBorderColor,
    getStatusIcon,
    historyDateOptions,
    historyTypeOptions,
    INCIDENTS_PER_PAGE,
  } = useFeedStatus(feedStatusHistory);

  // History Filter
  const filteredHistoryData = filterHistoryData(
    feedStatusHistory,
    selectedDateOption,
    selectedTypeOption
  );

  const renderIssuesHeader = () =>
    !feedsWithIssues.length ? (
      <Box
        display="flex"
        border="1px solid"
        borderColor="success"
        borderRadius="base"
        py="m"
        px="s"
        backgroundColor="neutralsGradient.10"
        mb="base"
      >
        <Icon name="checkCircle" color="success" mx="m" />
        All Systems Operational
      </Box>
    ) : (
      <Box
        display="flex"
        border="1px solid"
        borderColor="warning"
        borderRadius="base"
        py="m"
        px="s"
        backgroundColor="neutralsGradient.10"
        mb="base"
      >
        <Icon name="alertTriangle" color="warning" mx="m" />
        {getFeedIssuesTitle(feedsWithIssues)}
      </Box>
    );

  const renderFeedCard = (feedStatus: FeedStatus) => {
    const { description, feed, status, lastChecked, tooltip } = feedStatus;
    return (
      <Box
        key={`${feed}-${lastChecked}`}
        display="flex"
        flexDirection="column"
        border="1px solid"
        borderColor={getStatusBorderColor(status)}
        borderRadius="base"
        px="s"
        py="m"
      >
        <Box display="flex" justifyContent="flex-start">
          <Title as="h3">{getFeedStatusTitle(feed)}</Title>
          <Box>
            <Tooltip label={tooltip || feed} backgroundColor="success">
              <Icon name="questionMark" size="16" color="neutralsGradient.4" mt="xs" ml="s" />
            </Tooltip>
          </Box>
          <Icon name={getStatusIcon(status)} color={getStatusColor(status)} mb="base" ml="auto" />
        </Box>
        <Box as="p" color="neutralsGradient.1" mb="base">
          {description}
        </Box>
        <Box as="p" fontSize="xs" color="neutralsGradient.3" mt="auto">{`LAST CHECKED ${format(
          new Date(lastChecked),
          'h:mm a'
        )}`}</Box>
      </Box>
    );
  };

  const renderHistoryRow = (feedStatus: FeedStatusHistory, index: number) => {
    const { description, feed, updatedAt } = feedStatus;
    return (
      <Box
        key={`${feed}-${updatedAt}-${index}-history`}
        width="100%"
        minHeight="80px"
        display="flex"
        borderBottom="1px solid"
        borderColor="neutralsGradient.7"
        borderRadius="base"
        px="s"
        py="m"
      >
        <Icon name="xCircle" color="error" size="18" mr="m" flexShrink={0} />
        <Box display="flex">
          <Box display="flex" flexDirection="column">
            <Title as="p" fontSize="base" fontWeight="700" mb="xs">
              {getFeedStatusTitle(feed)}
            </Title>
            <Box as="p" fontSize="base" color="neutralsGradient.3" letterSpacing="-0.01em">
              {description}
            </Box>
          </Box>
        </Box>
        <Box as="p" fontSize="s" ml="auto" color="neutralsGradient.3" mb="auto">{`${format(
          new Date(updatedAt),
          'PPPpp'
        )}`}</Box>
      </Box>
    );
  };

  const renderFeedStatus = () => {
    if (!feedStatus.length) return <Loader label="Loading..." />;
    return (
      <>
        {renderIssuesHeader()}
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gridGap="base">
          {feedStatus?.length
            ? feedStatus?.map((feedStatus: FeedStatus) => renderFeedCard(feedStatus))
            : []}
        </Box>
        <LinkButton display="flex" mx="auto" mt="base" onClick={() => setIsHistoryMode(true)}>
          View Past Incidents
          <Icon name="chevronRight" color="neutralsGradient.1" ml="xxs" />
        </LinkButton>
      </>
    );
  };

  const renderHistory = () => {
    const history = getHistoryResults(filteredHistoryData, pageIndex);
    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedDateOption = historyDateOptions?.find(o =>
        isSameMonth(new Date(o?.value), new Date(e.target.value))
      );
      if (selectedDateOption) {
        setPageIndex(0);
        setSelectedDateOption(selectedDateOption);
      }
    };
    const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedTypeOption = historyTypeOptions?.find(o => o.value === e.target.value);
      if (selectedTypeOption) {
        setPageIndex(0);
        setSelectedTypeOption(selectedTypeOption);
      }
    };
    const isNextDisabled = (pageIndex + 1) * INCIDENTS_PER_PAGE > filteredHistoryData.length;

    const renderHistoryBody = () => {
      if (feedStatusHistoryData?.loading) {
        return (
          <Box width="100%" display="flex" justifyContent="center" mt="l">
            <Loader label="Loading..." />
          </Box>
        );
      }
      if (!feedStatusHistory?.length)
        return (
          <Box as="h4" mt="l">
            No results with selected options...
          </Box>
        );
      return history.map((feedStatus: FeedStatusHistory, i) => renderHistoryRow(feedStatus, i));
    };

    return (
      <>
        <Box display="flex" mb="base">
          <LinkButton display="flex" mb="s" ml="-16px" onClick={() => setIsHistoryMode(false)}>
            <Icon name="chevronLeft" color="neutralsGradient.1" mr="xxs" />
            Back
          </LinkButton>
          <Box display="flex" ml="auto">
            <Box width="180px" mr="m">
              <Select
                name="type"
                options={sortBy(historyTypeOptions, 'value')}
                onChange={handleTypeChange}
                value={selectedTypeOption?.value}
              />
            </Box>
            <Box width="180px">
              <Select
                name="date"
                options={historyDateOptions.map((option: DateOption) => ({
                  label: option.label,
                  value: option.value,
                }))}
                onChange={handleDateChange}
                value={
                  historyDateOptions?.find((option: DateOption) =>
                    isSameMonth(new Date(option?.value), new Date(selectedDateOption?.value))
                  )?.value
                }
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          {renderHistoryBody()}
        </Box>
        <Box display="flex" justifyContent="center" mt="l" mb="s">
          <LinkButton
            display="flex"
            onClick={() => pageIndex > 0 && setPageIndex(pageIndex - 1)}
            disabled={!pageIndex}
          >
            <Icon name="chevronLeft" color="neutralsGradient.1" />
            Newer
          </LinkButton>
          <LinkButton
            display="flex"
            onClick={() => setPageIndex(pageIndex + 1)}
            disabled={isNextDisabled}
          >
            Older
            <Icon name="chevronRight" color="neutralsGradient.1" />
          </LinkButton>
        </Box>
      </>
    );
  };

  return (
    <Modal
      title="Feed Status"
      icon={{ name: 'feedStatus', stroke: '1px', fill: 'neutralsGradient.1' }}
      {...props}
    >
      {isHistoryMode ? renderHistory() : renderFeedStatus()}
    </Modal>
  );
};

export default FeedStatusModal;
