import React, { useEffect } from 'react';

import { DepartureFlight } from 'data/Flights/types';
import { DepartureFlightsData } from 'data/Flights/data';
import { useDataLimiter } from 'hooks';

import { Tile } from 'components';
import { DetailsComponentProps } from 'components/ChartDetails';
import DepartureFlights from 'tiles/Departures/DepartureFlights';

import DetailsNav from './DetailsNav';

const LIMIT = 20;

const DepartureDetails: React.FC<DetailsComponentProps> = ({ data }: DetailsComponentProps) => {
  const dataLimiter = useDataLimiter(LIMIT);

  useEffect(() => {
    const flightsData = new DepartureFlightsData({
      flights: data as DepartureFlight[],
    });
    dataLimiter.setData(flightsData?.flights);
  }, [data, dataLimiter]);

  if (!data) return null;

  if (dataLimiter.status === 'loading') return null;

  const isCargo = data?.some((datum: any) => datum.isPassenger === false);

  const gridStyle = isCargo ? 'repeat(7, 1fr)' : 'repeat(5, 1fr)';
  const headingValues = [
    { key: 'city', label: 'City' },
    { key: 'flight', label: 'Flight' },
    { key: 'date', label: 'Date' },
    { key: 'time', label: 'Time' },
    { key: 'status', label: 'Status' },
  ];

  if (isCargo) {
    headingValues.push({ key: 'cargoCarrier', label: 'Carrier' });
    headingValues.push({ key: 'netWeight', label: 'Net Weight (LBS)' });
  }

  return (
    <Tile
      title={`Departures (${dataLimiter.resultsDescription})`}
      icon="arrowUpAndRight"
      contentAlignment="top"
      contentPadding="0"
    >
      <DepartureFlights
        flights={dataLimiter.currentData}
        grid={gridStyle}
        headings={headingValues}
      />
      <DetailsNav dataLimiter={dataLimiter} />
    </Tile>
  );
};

export default DepartureDetails;
