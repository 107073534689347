import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

const FormLabel = styled.label<SystemProps>`
  font-size: ${themeGet('fontSizes.s')};
  font-weight: ${themeGet('fontWeights.bold')};

  ${system}
`;

export default FormLabel;
