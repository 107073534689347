import { startOfDay, endOfDay } from 'date-fns';
import sortBy from 'lodash/sortBy';
import React, { useRef } from 'react';

import { useCapacityDepartures } from 'data/Flights';

import { useRefreshedAt } from 'hooks';

import { BlankSlate, UpdatedAt } from 'components';
import { DefaultErrorMessageWithError, DefaultLoader } from 'components/QueryCell';

import { Box } from 'styled';
import { Props as IconProps } from 'components/Icon';
import { Heading } from 'components/Table';

import { getAirportTime } from 'utils';

import DepartureFlights from './CapacityFlights';
import { CapacityDepartureFlight } from 'data/Flights/types';

const defaults = {
  filterBlankSlateIcon: { name: 'magnifyingGlass', color: 'warning', size: '30' },
  filterEmptyMessage: 'No capacity data matching parameters.',
};

export interface Props {
  filter?: Function;
  filterEmptyMessage?: string;
  filterBlankSlateIcon?: IconProps;
  sortByColumn?: string;
  isReverseSort?: boolean;
  grid?: string;
  headings?: Heading[];
  limit?: number;
}

const Capacity: React.FC<Props> = props => {
  const containerRef = useRef<HTMLInputElement>(null);
  const now = getAirportTime(new Date());
  const start = startOfDay(now);
  const end = endOfDay(now);
  const {
    departures,
    loading: departuresLoading,
    error: departuresError,
  } = useCapacityDepartures({
    between: [start.getTime(), end.getTime()],
    fetchPolicy: 'cache-and-network',
  });
  const passengerDepartures = departures.filter((f: CapacityDepartureFlight) => f.isPassenger);
  const loading = departuresLoading;
  const error = departuresError;
  const { refreshedAt } = useRefreshedAt(loading);

  // Map capacity data
  const allFlights = passengerDepartures.map(flight => {
    return {
      ...flight,
      flightNum: parseInt(flight?.flightNumber),
      load: flight?.capacityData?.load,
      capacity: flight?.capacityData?.occupancy,
    };
  });

  // Sort capacity data
  let sortedFlights = sortBy(allFlights, props.sortByColumn || 'time');
  if (props?.isReverseSort) sortedFlights = sortedFlights.reverse();

  // Handle loading state
  const noData = !passengerDepartures.length;
  if (loading && noData) return <DefaultLoader />;

  // Handle error state
  if (error) {
    return <DefaultErrorMessageWithError error={error} action="useCapacityDepartures()" />;
  }

  // Handle empty state
  if (noData) {
    return (
      <Box display="grid" height="100%" gridTemplateRows="1fr auto">
        <BlankSlate
          display="flex"
          flexDirection="column"
          title="No capacity"
          justifyContent="center"
          icon={props?.filterBlankSlateIcon || defaults.filterBlankSlateIcon}
        >
          {props?.filterEmptyMessage || defaults.filterEmptyMessage}
        </BlankSlate>
        <UpdatedAt date={refreshedAt} />
      </Box>
    );
  }

  return (
    <>
      <Box ref={containerRef} height="100%" width="100%">
        <DepartureFlights
          {...props}
          flights={sortedFlights}
          headings={props.headings}
          grid={props.grid}
        />
      </Box>
      <UpdatedAt date={refreshedAt} loading={loading} />
    </>
  );
};

export default Capacity;
