import React, { useEffect } from 'react';

import { TILE_KEYS, TILES } from 'config/tiles';

import { showModal, updateModal, useModal } from 'providers/ModalProvider';
import { updateOptions, useTileOptions } from 'providers/TileOptionsProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile, TileTab, TileTabs } from 'components';
import CapacitySummary, { Data, TableData } from './CapacitySummary';

import { TileTabContent } from 'styled';

export const CapacitySummaryTile: React.FC = () => {
  const [optionsState, optionsDispatch] = useTileOptions();
  const [modalState, modalDispatch] = useModal();

  const {
    capacitySummary: { filters },
  } = optionsState;

  const tileConfig = TILES?.find(tile => tile.title === 'Capacity Summary');

  const headings = tileConfig?.headings || [];
  const additionalHeadings = tileConfig?.additionalHeadings || [];

  const modalProps = {
    title: 'Capacity Summary',
    icon: { name: 'list' },
    large: true,
    children: (
      <TileTabs>
        <TileTab count={1} title="Departures" icon="arrowUpAndRight">
          <TileTabContent hasTable={true}>
            <RequiredPermission
              permission="read:flight"
              renderFallback={() => <NotAvailableBlankSlate />}
            >
              <CapacitySummary
                grid="2fr repeat(6, 1fr)"
                headings={[...headings, ...additionalHeadings]}
                renderExternalData={({ data }: { data: Data }): TableData => ({
                  airline: data.airline,
                  onTime: data.onTime,
                  delayed: data.delayed,
                  canceled: data.canceled,
                  total: data.total,
                  status: data.status,
                  load: data.load,
                  bookings: data.bookings,
                })}
                renderExternalDataTotal={({ data }: { data: Data }): TableData => ({
                  airline: data.airline,
                  onTime: data.onTime,
                  delayed: data.delayed,
                  canceled: data.canceled,
                  total: data.total,
                  status: data.status,
                  load: data.load,
                  bookings: data.bookings,
                })}
                filterBlankSlateIcon={{
                  name: 'magnifyingGlass',
                  color: 'warning',
                  size: '30',
                }}
                filterEmptyMessage="No capacity summary data matching parameters."
                isReverseSort={filters?.isReverseSort}
                sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
              />
            </RequiredPermission>
          </TileTabContent>
        </TileTab>
      </TileTabs>
    ),
  };

  // Update modal data if needed
  useEffect(() => {
    const isActiveModal = modalState?.activeModal?.props?.title === 'Capacity Summary';
    const needsUpdate = optionsState?.capacitySummary?.filters?.needsUpdate;
    if (isActiveModal && needsUpdate) {
      modalDispatch(updateModal('Default', modalProps));
      optionsDispatch(
        updateOptions({
          capacitySummary: {
            ...optionsState.capacitySummary,
            filters: {
              ...optionsState.capacitySummary.filters,
              needsUpdate: false,
            },
          },
        })
      );
    }
  }, [modalDispatch, modalState, modalProps, optionsDispatch, optionsState]);
  if (process.env.REACT_APP_CONFIG_BOOKINGS !== 'true') return null;

  return (
    <Tile
      title="Capacity Summary"
      icon="list"
      contentAlignment="top"
      contentPadding="0"
      modal="Default"
      modalProps={modalProps}
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Capacity Summary Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.CAPACITY_SUMMARY,
              })
            ),
        },
      ]}
    >
      <TileTabs>
        <TileTab count={1} title="Departures" icon="arrowUpAndRight">
          <TileTabContent hasTable={true}>
            <RequiredPermission
              permission="read:flight"
              renderFallback={() => <NotAvailableBlankSlate />}
            >
              <CapacitySummary
                headings={headings}
                isReverseSort={filters?.isReverseSort}
                sortByColumn={filters?.sortByColumn || filters?.defaultSortByColumn}
              />
            </RequiredPermission>
          </TileTabContent>
        </TileTab>
      </TileTabs>
    </Tile>
  );
};

export default CapacitySummaryTile;
