import { format } from 'date-fns';
import sortBy from 'lodash/sortBy';
import React from 'react';

import { useAlerts } from 'data/Alert';
import { Alert } from 'data/Alert/types';

import { useRefreshedAt } from 'hooks';

import { useTileOptionsState } from 'providers/TileOptionsProvider';

import { AlertIcon, QueryCell, Table, UpdatedAt } from 'components';
import { Heading } from 'components/Table';

import { Box } from 'styled';

interface Props {
  grid?: string;
  headings?: Heading[];
  sortByColumn?: string;
  isReverseSort?: boolean;
}

interface Data {
  alert: string | JSX.Element;
  time: string;
}

const defaults = {
  grid: '1.75fr 0.5fr',
  headings: [
    { key: 'alert', label: 'Alert' },
    { key: 'time', label: 'Time' },
  ],
};

const Alerts: React.FC<Props> = ({ grid, headings }: Props) => {
  const { alerts, loading, error } = useAlerts();
  const { refreshedAt } = useRefreshedAt(loading);
  const {
    alerts: { filters },
  } = useTileOptionsState();
  const isReverseSort = filters?.isReverseSort;
  const sortByColumn = filters?.sortByColumn || filters?.defaultSortByColumn;

  function getData(): Data[] {
    let sortedData = sortBy(alerts, sortByColumn || 'time');
    if (isReverseSort) sortedData = sortedData.reverse();

    const activeAlerts = sortedData
      .filter((alert: Alert) => alert.isActive)
      .map((alert: Alert) => {
        const color = alert.status?.toLowerCase() === 'warning' ? `warning` : 'error';
        return {
          alert: (
            <Box display="flex" alignItems="center">
              <Box mr="s">
                <AlertIcon
                  name={alert.category || ''}
                  color={color}
                  description={alert.description}
                  size="16"
                />
              </Box>
              <Box dangerouslySetInnerHTML={{ __html: alert.description || '' }} />
            </Box>
          ),
          time: format(alert.updatedAt || new Date(), 'p'),
        };
      }) as Data[];

    return activeAlerts;
  }

  return (
    <QueryCell content="alerts" loading={loading} error={error} data={alerts}>
      <Table
        name="alerts"
        striped={false}
        bordered={true}
        dataFontSize="s"
        grid={grid || defaults.grid}
        headings={headings || defaults.headings}
        data={getData()}
      />
      <UpdatedAt date={refreshedAt} loading={loading} />
    </QueryCell>
  );
};

export default Alerts;
