import React, { useEffect } from 'react';

import { ArrivalFlight } from 'data/Flights/types';

import { useDataLimiter } from 'hooks';

import { Tile } from 'components';
import { DetailsComponentProps } from 'components/ChartDetails';
import CargoTable from 'tiles/Cargo/details/CargoTable';

import DetailsNav from './DetailsNav';
import { CargoData } from 'utils/arrivals';

const LIMIT = 20;

const CargoRevenueDetails: React.FC<DetailsComponentProps> = ({ data }: DetailsComponentProps) => {
  const dataLimiter = useDataLimiter(LIMIT);

  useEffect(() => {
    const cargoData = new CargoData({ flights: data as ArrivalFlight[] });
    dataLimiter.setData(cargoData.flights);
  }, [data, dataLimiter]);

  if (!data) return null;

  if (dataLimiter.status === 'loading') return null;

  return (
    <Tile
      title={`Cargo Revenue (${dataLimiter.resultsDescription})`}
      icon="arrowUpAndRight"
      contentAlignment="top"
      contentPadding="0"
    >
      <CargoTable
        flights={dataLimiter.currentData}
        grid="repeat(6, 1fr)"
        headings={[
          { key: 'flight', label: 'Flight' },
          { key: 'time', label: 'Time' },
          { key: 'weight', label: 'Weight (lbs)' },
          { key: 'cost', label: 'Revenue' },
          { key: 'cargoCarrier', label: 'Carrier' },
          { key: 'netWeight', label: ' Net Weight' },
        ]}
      />
      <DetailsNav dataLimiter={dataLimiter} />
    </Tile>
  );
};

export default CargoRevenueDetails;
