// @ts-nocheck
import { CapacityDepartureFlight } from 'data/Flights/types';

interface ConstructorArgs {
  flights: CapacityDepartureFlight[];
}

class CapacityFlightsData {
  flights: CapacityDepartureFlight[];

  constructor({ flights }: ConstructorArgs) {
    this.flights = flights;
  }

  get isArrivals(): boolean {
    return true;
  }

  get isDepartures(): boolean {
    return false;
  }
}

export default CapacityFlightsData;
