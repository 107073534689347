import sortBy from 'lodash/sortBy';
import React from 'react';

import { getAirline } from 'config/airlines';
import colorsTheme from 'config/theme';

import { getCargoDelayString, toLocaleCurrency } from 'utils';
import { AircraftWeight } from 'config/weights';
import { ArrivalFlight } from 'data/Flights/types';

import { AirlineIcon, BlankSlate, Table, Tooltip } from 'components';
import { Props as IconProps } from 'components/Icon';
import { Heading } from 'components/Table';

import { Box } from 'styled';

import { CargoArrivalFlightData } from '.';

type CargoProps = ArrivalFlight & AircraftWeight & { cost?: number };

export interface Props {
  flights: CargoProps[];
  filter?: Function;
  limit?: number;
  grid?: string;
  headings?: Heading[];
  renderData?: Function;
  filterEmptyMessage?: string;
  filterBlankSlateIcon?: IconProps;
}

const defaults = {
  headings: [
    { key: 'airline', label: 'Airline' },
    { key: 'flight', label: 'Flight' },
    { key: 'time', label: 'Time' },
    { key: 'weight', label: 'Weight (lbs)' },
    { key: 'aircraft', label: 'Aircraft' },
  ],
  grid: '1.5fr repeat(3, 1fr) 1.5fr',
  filterEmptyMessage: 'There is currently no data.',
  filterBlankSlateIcon: { name: 'magnifyingGlass', color: 'warning', size: '30' },
};

const CargoTable: React.FC<Props> = ({
  flights,
  filter,
  limit,
  headings,
  grid,
  filterEmptyMessage,
  filterBlankSlateIcon,
}: Props) => {
  function getNumberToShow(cargoes: CargoProps[]): number {
    return limit || cargoes.length;
  }

  // !NOTE: For paginated data, e.g. from Details components, this will make some pages shorter than others
  // TODO: Figure out how to retain page length for pagination while still using filter
  function filterData(cargo: CargoProps): CargoProps {
    if (filter) return filter(cargo);
    return cargo;
  }

  const filteredFlights = sortBy(flights.filter(filterData), [
    (cargo: CargoProps): Date => {
      const scheduledDate = cargo.scheduledArrivalDate;
      const estimatedDate = cargo.estimatedArrivalDate;
      const actualDate = cargo.actualArrivalDate;
      return new Date(actualDate || estimatedDate || scheduledDate);
    },
  ]);

  if (filteredFlights.length === 0)
    return (
      <BlankSlate
        display="flex"
        flexDirection="column"
        justifyContent="center"
        icon={filterBlankSlateIcon || defaults.filterBlankSlateIcon}
      >
        {filterEmptyMessage || defaults.filterEmptyMessage}
      </BlankSlate>
    );

  return (
    <Table
      striped={false}
      bordered={true}
      cellPaddingX="s"
      cellPaddingY="s"
      dataFontSize="s"
      gridGap="xs"
      grid={grid || defaults.grid}
      headings={headings || defaults.headings}
      data={filteredFlights.slice(0, getNumberToShow(flights)).map((cargo: CargoProps) => {
        const cargoArrivalFlightData = new CargoArrivalFlightData({ flight: cargo });
        const time = cargoArrivalFlightData.arrivalTime;
        const airline = getAirline(cargo.marketingCarrier);
        const delayMinutes = cargo?.arrivalDelay;
        const scheduledTime = cargo?.scheduledArrivalDate;
        const returnData = {
          airline: (
            <>
              <AirlineIcon airline={airline} />
              {airline?.name}
            </>
          ),
          netWeight: cargo?.netWeight ? parseInt(cargo?.netWeight).toLocaleString() : '',
          cargoCarrier: cargo?.cargoCarrier?.toUpperCase(),
          flight: `${cargo.iata}-${cargo.flightNumber}`,
          time: (
            <Box as="span" color={colorsTheme.colors.subdued}>
              <Tooltip
                label={`${cargo.statusText} ${
                  getCargoDelayString(delayMinutes, scheduledTime) ?? ''
                }`}
              >
                {time}
              </Tooltip>
            </Box>
          ),
          weight: parseInt(cargo?.weight)?.toLocaleString(),
          cost: cargo?.cost ? toLocaleCurrency(cargo.cost) : 'N/A',
          aircraft: `${cargo.manufacturer} ${cargo.makeModel}`,
        };

        return returnData;
      })}
    />
  );
};

export default CargoTable;
