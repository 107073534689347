import { pluralize } from 'utils';

import { StatItem, StatsArgs } from 'statistics/types';

import { STAT_TYPES } from './constants';

function getAverageStatItem() {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Average Departures',
    suffix: ` ${pluralize('flight', value as number).toLocaleString()}`,
    value: value as number,
    contextLabel,
  });
}

function getHighestStatItem() {
  return ({ value, contextLabel }: StatsArgs): StatItem => ({
    label: 'Highest Departures',
    suffix: ` ${pluralize('flight', value as number).toLocaleString()}`,
    value: value as number,
    contextLabel,
  });
}

export default {
  [STAT_TYPES.DEPARTURES.PASSENGER.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.DEPARTURES.PASSENGER.HIGHEST]: getHighestStatItem(),
  [STAT_TYPES.DEPARTURES.CARGO.AVERAGE]: getAverageStatItem(),
  [STAT_TYPES.DEPARTURES.CARGO.HIGHEST]: getHighestStatItem(),
};
