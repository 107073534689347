import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ChartOptions, TabItem } from 'config/tabs';
import theme from 'config/theme';

import { useDashboardMode } from 'hooks';
import useProcessedData from 'hooks/useProcessedData';

import { updateActiveChartsState, useActiveCharts } from 'providers/ActiveChartsProvider';
import { useChartOptions } from 'providers/ChartOptionsProvider';
import { useUserData } from 'providers/UserDataProvider';

import { getChartOptions } from 'statistics/charts/utils';
import { getProcessorTypes } from 'statistics/processors/utils';
import { ProvidersContexts } from 'statistics/types';

import {
  ChartOverlay,
  Icon,
  LinkButton,
  Menu,
  NotAvailableBlankSlate,
  Tab,
  Tabs,
} from 'components';

import { TriggerArgs } from 'components/Menu';
import { DefaultErrorMessage } from 'components/QueryCell';

import { Box } from 'styled';

import { ChartControls, ChartControlStats, HighchartsCustom } from './components';

export interface ChartsFunctionProps {
  endDate?: Date;
  frozen?: boolean;
}

interface ChartTabsProps {
  tabs: TabItem[];
  tabsLimit?: number;
  contexts: ProvidersContexts;
  contextsLoading?: boolean;
}

const defaults = {
  tabsLimit: 5,
};

const Chart: React.FC<ChartTabsProps> = ({
  tabs,
  tabsLimit,
  contexts,
  contextsLoading = false,
}: ChartTabsProps) => {
  const [state, dispatch] = useChartOptions();
  const { chartEndDate, frozen, height } = state;
  const [activeChartsState, activeChartsDispatch] = useActiveCharts();
  const { activeCharts, activeTab } = activeChartsState;
  const { permissions } = useUserData();

  const count = useRef<number>(0);

  useDashboardMode(() => {
    if (count.current === tabs.length) count.current = 0;
    count.current++;
    activeChartsDispatch(updateActiveChartsState({ activeTab: tabs[count.current]?.title }));
  });

  const filterTabs = (tabs: TabItem[]): TabItem[] =>
    tabs.filter((t: TabItem) => {
      if (!permissions?.includes(t.permission)) return false;
      return t;
    });
  // This previously used `useMemo`, but was causing some issues. There doesn't
  // seem to be a performance hit, but something worth noting.
  const filteredTabs = filterTabs(tabs);
  const filteredTabTitles = filteredTabs.map(t => t.title);
  const tab = !filteredTabTitles.includes(activeTab) ? 0 : filteredTabTitles.indexOf(activeTab);

  // They don't have permission to view anything.
  const noPermission = filteredTabs.length === 0;

  const tabItem: TabItem = useMemo(() => filteredTabs[tab], [tab, filteredTabs]);
  const [charts, setCharts] = useState<ChartOptions[]>([]);
  const chartTypes = useMemo(() => charts.map(chart => chart.type), [charts]);
  const processedData = useProcessedData(chartTypes, contexts, state, noPermission);

  useEffect(() => {
    const _charts: ChartOptions[] = [];
    tabs.forEach(t => _charts.push(...(t.charts?.({ endDate: chartEndDate, frozen }) || [])));
    setCharts(_charts);
  }, [tabs, chartEndDate, frozen]);

  if (noPermission) return <NotAvailableBlankSlate />;

  function renderCharts(): JSX.Element[] | JSX.Element {
    const chartProcessorTypes = getProcessorTypes(activeCharts.map(chart => chart.type));
    const dataProcessing =
      !processedData ||
      chartProcessorTypes.some(processorType => processedData?.[processorType]?.chart?.loading);
    for (const processorType of chartProcessorTypes) {
      if (processedData?.[processorType]?.chart?.error) return <DefaultErrorMessage />;
    }

    const chartOptions = tabItem.exclusive
      ? activeCharts.map(chart => {
          return {
            key: chart.type,
            options: getChartOptions([chart], processedData, state, dispatch),
          };
        })
      : [
          {
            key: tabItem.title,
            options: getChartOptions(activeCharts, processedData, state, dispatch),
          },
        ];
    // We don't want to absolutely position multiple charts
    // because they will be stacked on top of each other.
    const hasMultipleCharts = chartOptions.length > 1;

    return chartOptions.map(({ key, options }) => (
      <Box
        key={key}
        flex={hasMultipleCharts ? '0 32%' : '1'}
        position={hasMultipleCharts ? 'static' : 'absolute'}
        left="0"
        right="0"
        top="0"
        bottom="0"
      >
        <HighchartsCustom
          key={key}
          highchartsProps={{
            options,
          }}
          dataLoading={contextsLoading}
          dataProcessing={dataProcessing}
        />
      </Box>
    ));
  }

  const TABS_LIMIT = tabsLimit || defaults.tabsLimit;

  const tabsCount = filteredTabs.length;
  const tabsOverLimit = tabsCount > TABS_LIMIT;
  const currentTab = tabItem.title;

  return (
    <Box display="flex" flexDirection="column" mx="auto" width="100%">
      <ChartControls
        tabs={tabs}
        currentTab={currentTab}
        renderStats={(): JSX.Element => (
          <ChartControlStats currentTab={currentTab} data={processedData} />
        )}
      >
        <ChartOverlay />
        <Box display="flex" alignItems="center" justifyContent="center">
          {tabsOverLimit ? (
            <Menu
              side="center"
              renderTrigger={({ status, getTriggerProps }: TriggerArgs): JSX.Element => (
                <LinkButton
                  display="block"
                  fontSize="base"
                  lineHeight="0"
                  px="0"
                  {...getTriggerProps()}
                >
                  <Icon name={tabItem.icon} color="subdued" size="16" mr="xs" />
                  {tabItem.title}
                  <Icon
                    name={status === 'closed' ? 'chevronDown' : 'chevronUp'}
                    size="12"
                    ml="xs"
                  />
                </LinkButton>
              )}
              options={filteredTabs.map((t: TabItem, i: number) => ({
                label: t.title,
                icon: { name: t.icon },
                showActive: true,
                action: (): void => {
                  activeChartsDispatch(
                    updateActiveChartsState({ activeTab: filteredTabs[i].title })
                  );
                },
              }))}
            />
          ) : (
            <Box zIndex={theme.zIndices.baseOverlay}>
              <Tabs>
                {filteredTabs.map((t: TabItem, i: number) => (
                  <Tab
                    key={i}
                    selected={i === tab}
                    title={t.title}
                    icon={t.icon}
                    onClick={(): void => {
                      activeChartsDispatch(
                        updateActiveChartsState({ activeTab: filteredTabs[i].title })
                      );
                    }}
                  />
                ))}
              </Tabs>
            </Box>
          )}
        </Box>
      </ChartControls>
      <Box minHeight={height} position="relative">
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
          alignItems="center"
          position="absolute"
          left="0"
          right="0"
          top="0"
          bottom="0"
        >
          {renderCharts()}
        </Box>
      </Box>
    </Box>
  );
};

export default Chart;
