import { gql, useQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { DEFAULT_POLL_INTERVAL } from 'config/constants';

import { DeparturesArgs, DepartureFlightsQuery } from './types';
import { getAirportTime } from 'utils';

const DEPARTURE_FLIGHTS = gql`
  query DepartureFlights($between: [DateTime]!) {
    departureFlights(between: $between) {
      id
      actualDepartureDate
      aircraftType
      bookings
      cancelled
      cargoCarrier
      departureDelay
      departureGate
      destination
      diverted
      estimatedDepartureDate
      flightNumber
      isPassenger
      marketingCarrier
      netWeight
      origin
      scheduledDepartureDate
      statusText
      departureTime
      departureDate
    }
  }
`;
const now = getAirportTime(new Date());
const start = startOfDay(now);
const end = endOfDay(now);

export default function useDepartureFlights({
  between = [start.getTime(), end.getTime()],
  refresh = true,
  skip = false,
}: DeparturesArgs = {}): DepartureFlightsQuery {
  const variables = pickBy(
    {
      between,
    },
    identity
  );

  const options = {
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL,
    notifyOnNetworkStatusChange: Boolean(refresh),
    skip,
  };

  if (!refresh) options.pollInterval = 0;

  const query = useQuery(DEPARTURE_FLIGHTS, options);
  const departures = query?.data?.departureFlights || [];

  return {
    departures,
    ...query,
  };
}
