import Highcharts from 'highcharts';

import { ColorName } from 'config/chartColors';
import theme from 'config/theme';

function getTooltip(ctx: Highcharts.TooltipFormatterContextObject): string {
  const point = ctx.points?.[0]?.point;
  const y = point?.y || 0;
  const total = point?.total;
  const percentage = total ? ` (${Math.round((y / total) * 100)}%)` : '';
  return `
      <span style="font-size: 10px">${point?.name}</span>
      <br/>
      <b>${ctx.y.toLocaleString()}</b>${percentage}
    `;
}

export default function getOptions(title: string): Highcharts.Options {
  const EXCLUDED_COLORS = ['green', 'yellow', 'red'];
  const colors: string[] = [];
  const chartColors = Object.keys(theme.colors.chart) as ColorName[];
  chartColors.forEach((color: ColorName) => {
    if (!EXCLUDED_COLORS.includes(color)) colors.push(theme?.colors?.chart[color]);
  });

  const options: Highcharts.Options = {
    title: {
      text: title,
      style: {
        color: theme?.colors?.subdued,
        fontSize: '12px',
      },
    },
    exporting: {
      enabled: false,
    },
    legend: {
      itemMarginBottom: 6,
    },
    tooltip: {
      formatter(): string {
        return getTooltip(this);
      },
    },
  };

  return options;
}
