import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';

import { useSentry } from 'hooks';

import { AppError } from 'screens';

import { AppProvider } from 'providers';

import { GlobalStyles } from 'styled';

import BrowserValidator from './BrowserValidator';
import Router from './Router';

const App: React.FC = () => {
  const { handleError } = useSentry();

  return (
    <AppProvider>
      <GlobalStyles />
      <BrowserValidator>
        <BrowserRouter>
          <ErrorBoundary fallback={<AppError />} onError={handleError}>
            <Router />
          </ErrorBoundary>
        </BrowserRouter>
      </BrowserValidator>
    </AppProvider>
  );
};

export default App;
