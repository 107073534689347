// @ts-nocheck
import groupBy from 'lodash/groupBy';

import { ArrivalFlight } from 'data/Flights/types';

interface PassengerAirline {
  [key: string]: {
    operations: number;
  };
}

export type PassengerFlight = ArrivalFlight & { weight?: number };

class PassengerData {
  flights: ArrivalFlight[] | DepartureFlight[];
  airlines: PassengerAirline;
  totalOperations: number;

  constructor({ flights }: { flights: ArrivalFlight[] }) {
    this.flights = this.normalizeFlights(flights);
    this.airlines = {};
    this.totalOperations = 0;

    this.normalizeAirlines();
  }

  normalizeFlights(flights: ArrivalFlight[]): ArrivalFlight[] {
    return flights.filter((flight: Flight) => {
      return flight.isPassenger;
    });
  }

  normalizeAirlines(): void {
    const filteredAirlines: PassengerAirline = {};

    this.completedFlights.forEach((passengerFlight: PassengerFlight) => {
      const airline = passengerFlight.marketingCarrier;
      if (!(airline in filteredAirlines)) {
        filteredAirlines[airline] = {
          operations: 0,
        };
      }
    });

    const flightsByAirline = groupBy(this.completedFlights, flight => flight.marketingCarrier);
    Object.keys(flightsByAirline).forEach((airline: string) => {
      const count = flightsByAirline[airline].length;
      filteredAirlines[airline].operations = count;
      this.totalOperations += count;
    });

    this.airlines = filteredAirlines;
  }

  get completedFlights(): ArrivalFlight[] {
    return this.flights;
  }
}

export default PassengerData;
