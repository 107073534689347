import { themeGet } from '@styled-system/theme-get';
import styled, { css, keyframes } from 'styled-components';

import system from './lib/system';
import { SystemProps } from './lib/types';

type Props = SystemProps & { size?: string; variant?: string; cursor?: string };

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const size =
  ({ size }: Props) =>
  () => {
    if (size === 's') {
      return css`
        font-size: ${themeGet('fontSizes.xs')};
        line-height: 3.25;
        padding-left: ${themeGet('space.base')};
        padding-right: ${themeGet('space.base')};
      `;
    }

    if (size === 'l') {
      return css`
        font-size: ${themeGet('fontSizes.s')};
        line-height: 3.5;
        padding-left: ${themeGet('space.xl')};
        padding-right: ${themeGet('space.xl')};
      `;
    }

    return css`
      font-size: ${themeGet('fontSizes.s')};
      line-height: 3.5;
      padding-left: ${themeGet('space.l')};
      padding-right: ${themeGet('space.l')};
    `;
  };

const variant =
  ({ variant }: Props) =>
  () => {
    if (variant === 'danger') {
      return css`
        background: ${themeGet('colors.error')};

        &:active,
        &:focus,
        &:hover {
          background: ${themeGet('colors.fg')};
        }
      `;
    }

    if (variant === 'secondary') {
      return css`
        background: transparent;
        border-color: ${themeGet('colors.border')};
        color: ${themeGet('colors.fg')};

        &:active,
        &:focus,
        &:hover {
          border-color: ${themeGet('colors.fg')};
        }
      `;
    }

    return css`
      background-color: ${themeGet('colors.greenLight')};

      &:active,
      &:focus,
      &:hover {
        background-color: ${themeGet('colors.primary')};
      }
    `;
  };

const Button = styled.button<Props>`
  background-clip: padding-box;
  border: 0;
  border: 1px solid transparent;
  border-radius: 40px;
  box-shadow: ${themeGet('shadows.base')};
  color: ${themeGet('colors.bg')};
  cursor: pointer;
  display: inline-block;
  font-weight: ${themeGet('fontWeights.bold')};
  text-align: center;
  text-decoration: none;
  transition: 0.2s ease-in-out;

  &:active,
  &:focus {
    box-shadow: 0 0 0 0.1rem ${themeGet('colors.secondary')}, 0 0 0 0.2rem ${themeGet('colors.bg')};
    outline: none;
  }

  &:active {
    position: relative;
    top: 2px;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `};

  ${variant}
  ${size}
  ${system}
`;

const ButtonLoader = styled.div`
  animation: ${spin} 1s infinite;
`;

export { Button as default, ButtonLoader };
