import React from 'react';

import { showModal, useModalDispatch } from 'providers/ModalProvider';

import { ResetButton } from 'styled';

import theme from 'config/theme';
import { track } from 'utils';
import Icon from './Icon';

export interface Props {
  count: number;
}

const AlertBadge: React.FC<Props> = ({ count }: Props) => {
  const modalDispatch = useModalDispatch();

  // Modal dispatch doesn't exist in error/not found routes.
  // In this case, don't show alert icon
  if (!modalDispatch) return null;

  const hasAlerts = count > 0;

  function handleAlertClick(event: { preventDefault: Function }): void {
    event.preventDefault();
    modalDispatch(showModal('Alerts'));
    track('View Alerts');
  }

  const red = theme.colors.red;

  const gray = theme.colors.neutralsGradient[7];

  // If alerts show alert count instead of bell icon
  if (hasAlerts) {
    return (
      <ResetButton
        onClick={handleAlertClick}
        left="100%"
        ml="s"
        position="absolute"
        top="-3px"
        width="24px"
        height="24px"
        backgroundColor="error"
        borderRadius="28px"
        color="neutralsGradient.1"
        hover={red}
      >
        {count}
      </ResetButton>
    );
  }

  // No alerts so show bell icon
  return (
    <ResetButton
      onClick={handleAlertClick}
      left="100%"
      ml="s"
      position="absolute"
      top="-3px"
      width="24px"
      height="24px"
      backgroundColor="neutralsGradient.9"
      borderRadius="28px"
      hover={gray}
    >
      <Icon name="bell" color={'neutralsGradient.1'} p="3px" />
    </ResetButton>
  );
};

export default AlertBadge;
