import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/browser';

// Creating this type in case we want to extend the Error to include things other than ApolloError
export type ErrorType = ApolloError | { message: string };

function useSentry() {
  function handleError(error: ErrorType) {
    Sentry.configureScope(scope => {
      Object.keys(error).forEach(key => {
        scope.setExtra(key, error[key as keyof ErrorType]);
      });
    });

    Sentry.captureException(error);
  }

  function handleFeedback(event: React.MouseEvent) {
    event.preventDefault();
    Sentry.showReportDialog();
  }

  function handleRefresh(event: React.MouseEvent) {
    event.preventDefault();
    window.location.reload();
    return false;
  }

  function sendError({
    prefix = 'Manual error report',
    error,
    action,
  }: {
    prefix?: string;
    error: ErrorType;
    action: string;
  }) {
    Sentry.configureScope(scope => {
      Object.keys(error).forEach(key => {
        scope.setExtra(key, error[key as keyof ErrorType]);
      });
    });
    Sentry.captureMessage(`${prefix}: ${action}`);
  }

  return { handleError, handleFeedback, handleRefresh, sendError };
}

export default useSentry;
