import React from 'react';

import { Icon, Menu, LinkButton } from 'components';
import { Props as IconProps } from 'components/Icon';
import { Option, TriggerArgs } from 'components/Menu';

import {
  Tile as StyledTile,
  TileBackground,
  TileContent,
  TileContentContainer,
  TileContentInner,
  TileFullscreen,
  TileHeader,
} from 'styled';
import { SystemProps } from 'styled/lib/types';

interface ModalIcon {
  name: string;
}

interface ModalProps {
  stateKey?: string;
  fullscreen?: boolean;
  title?: string;
  icon?: ModalIcon;
  children?: JSX.Element | null;
  large?: boolean;
  onClose?: Function;
  width?: string;
}

interface Props {
  contentAlignment?: string;
  contentPadding?: string;
  floatingHeader?: boolean;
  icon?: string;
  title: string;
  menuWidth?: string;
  modal?: string;
  modalProps?: ModalProps;
  options?: Option[];
  tooltip?: string;
  tooltipIcon?: IconProps;
  tooltipClickTriggerModal?: boolean;
}

export const ExportableTile: React.FC<Props & SystemProps> = ({
  children,
  contentAlignment,
  contentPadding,
  floatingHeader,
  icon,
  title,
  backgroundImage,
  menuWidth,
  options,
  gridColumn,
  gridRow,
}) => {
  return (
    <StyledTile gridColumn={gridColumn} gridRow={gridRow}>
      <TileContentContainer>
        <TileHeader floatingHeader={floatingHeader || false}>
          {icon && <Icon name={icon} color="primary" mr="s" />}
          {title}
          {options && (
            <TileFullscreen>
              <Menu
                renderTrigger={({ getTriggerProps }: TriggerArgs): JSX.Element => (
                  <LinkButton {...getTriggerProps()}>
                    Export
                    <Icon name={'chevronDown'} size="12" ml="xs" />
                  </LinkButton>
                )}
                side="right"
                options={options}
                width={menuWidth || '14.5rem'}
              />
            </TileFullscreen>
          )}
        </TileHeader>
        <TileContent contentAlignment={contentAlignment} contentPadding={contentPadding}>
          <TileContentInner contentAlignment={contentAlignment}>{children}</TileContentInner>
        </TileContent>
      </TileContentContainer>
      {backgroundImage ? <TileBackground backgroundImage={backgroundImage} /> : null}
    </StyledTile>
  );
};

export { ExportableTile as default };
