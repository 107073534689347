import React, { useState } from 'react';

import { TILE_KEYS } from 'config/tiles';

import { useDashboardMode } from 'hooks';

import { showModal, useModalDispatch } from 'providers/ModalProvider';
import { useTileOptionsState } from 'providers/TileOptionsProvider';

import { NotAvailableBlankSlate, RequiredPermission, Tile, TileTab, TileTabs } from 'components';
import { ArrivalSummary, DepartureSummary } from './components';

import { TileTabContent } from 'styled';

export const FlightSummaryTile: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const {
    flightSummary: { filters },
  } = useTileOptionsState();

  const modalDispatch = useModalDispatch();
  useDashboardMode(() => {
    currentTab === 0 ? setCurrentTab(1) : setCurrentTab(0);
  });

  return (
    <Tile
      title="Flight Summary"
      icon="list"
      contentAlignment="top"
      options={[
        {
          label: 'Settings',
          icon: {
            name: 'gear',
          },
          action: (): void =>
            modalDispatch(
              showModal('TileOptions', {
                title: 'Flight Summary Settings',
                icon: { name: 'gear' },
                optionKey: TILE_KEYS.FLIGHT_SUMMARY,
              })
            ),
        },
      ]}
    >
      <TileTabs initialTab={currentTab}>
        <TileTab count={2} title="Departures" icon="arrowUpAndRight">
          <TileTabContent>
            <RequiredPermission
              permission="read:flight"
              renderFallback={() => <NotAvailableBlankSlate />}
            >
              <DepartureSummary filters={filters} />
            </RequiredPermission>
          </TileTabContent>
        </TileTab>
        <TileTab count={2} title="Arrivals" icon="arrowDownAndLeft">
          <TileTabContent>
            <RequiredPermission
              permission="read:flight"
              renderFallback={() => <NotAvailableBlankSlate />}
            >
              <ArrivalSummary filters={filters} />
            </RequiredPermission>
          </TileTabContent>
        </TileTab>
      </TileTabs>
    </Tile>
  );
};

export default FlightSummaryTile;
