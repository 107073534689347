import React from 'react';

import { Icon } from 'components';

import { TextLabel } from 'styled';

interface Props {
  level: 'severe' | 'moderate' | 'light';
}

export const SEVERITY_COLOR_MAP = {
  severe: 'error',
  moderate: 'warning',
  light: 'success',
};

const IssueSeverity: React.FC<Props> = ({ level }) => {
  return (
    <TextLabel color={SEVERITY_COLOR_MAP[level]}>
      <Icon name="i" color={SEVERITY_COLOR_MAP[level]} size="12" mr="xs" />
      {level}
    </TextLabel>
  );
};

export default IssueSeverity;
