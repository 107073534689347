import { SeriesOptionsType } from 'highcharts';
import merge from 'lodash/merge';

import CHART_COLORS from 'config/chartColors';

import {
  amountPointFormatter,
  getDefaultSeriesOptions,
  getVisibility,
} from 'statistics/charts/utils';
import { ChartDatum } from 'statistics/types';

export default function getSeries(
  data: ChartDatum[],
  chartOptions: SeriesOptionsType = {} as SeriesOptionsType
): SeriesOptionsType {
  return merge(getDefaultSeriesOptions(), {
    name: 'Cargo Revenue',
    yAxis: 'amount',
    borderWidth: 0,
    data,
    tooltip: { pointFormatter: amountPointFormatter },
    color: CHART_COLORS['Cargo Revenue'],
    ...chartOptions,
    type: 'column',
    visible: getVisibility('Cargo Revenue'),
  } as SeriesOptionsType);
}
