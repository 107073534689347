export interface SupportedBrowsers {
  [key: string]: number;
}

export interface BrowserLinks {
  [key: string]: string;
}

export const SUPPORTED_BROWSERS: SupportedBrowsers = {
  Chrome: 83,
  Edge: 83,
  Firefox: 77,
  Safari: 13,
};

export const BROWSER_LINKS: BrowserLinks = {
  Chrome: 'https://www.google.com/chrome/',
  Edge: 'https://www.microsoft.com/en-us/edge',
  Firefox: 'https://www.mozilla.org/en-US/firefox/new/',
  Safari: 'https://support.apple.com/en-us/HT204416',
};
