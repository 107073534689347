import { useEffect } from 'react';

import { updateSettings, useSettings } from 'providers/SettingsProvider';

import useInterval from './useInterval';
import useQueryParams from './useQueryParams';

function useDashboardMode(onChange: Function): void {
  const [settings, dispatch] = useSettings();
  const query = useQueryParams();

  useEffect(() => {
    const shouldTurnOn = query.get('dashboardMode') && !settings.dashboardMode;
    if (shouldTurnOn) dispatch(updateSettings({ dashboardMode: true }));
  }, [query, settings.dashboardMode, dispatch]);

  function handleChange(): void {
    onChange();
  }

  useInterval(
    () => (settings.dashboardMode ? handleChange() : null),
    settings.dashboardModeDuration
  );
}

export default useDashboardMode;
