import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'components';

import { Box, Menu as StyledMenu, MenuList, MenuListItem } from 'styled';

export interface Option {
  label: string;
  action?: Function;
  icon?: {
    name: string;
    color?: string;
    size?: string;
  };
  showActive?: boolean;
  to?: string;
  render?: Function;
  closeMenuOnItemClick?: boolean;
  active?: boolean;
  id?: string;
}

interface Props {
  options: Option[];
  renderTrigger: Function;
  closeOnDocumentClick?: boolean;
  onTrigger?: Function;
  side?: string;
  width?: string;
  initialStatus?: 'closed' | 'open';
}

export interface TriggerArgs {
  getTriggerProps: Function;
  currentOption?: Option;
  setCurrentOption?: Function;
  status?: string;
}

const Menu: React.FC<Props> = ({
  options,
  renderTrigger,
  closeOnDocumentClick,
  onTrigger,
  side,
  width,
  initialStatus,
  ...rest
}: Props) => {
  const [status, setStatus] = useState(initialStatus || 'closed');
  const [currentOption, setCurrentOption] = useState(options[0]);
  const containerRef = useRef<HTMLDivElement>(null);
  const isClosed = status === 'closed';
  const defaults = {
    closeOnDocumentClick: true,
    side: 'left',
    width: '20rem',
  };
  const _closeOnDocumentClick =
    closeOnDocumentClick === undefined ? defaults.closeOnDocumentClick : closeOnDocumentClick;

  useEffect(() => {
    if (_closeOnDocumentClick) {
      const close = (event: MouseEvent): void => {
        if (!containerRef?.current?.contains(event.target as Node)) setStatus('closed');
      };
      document.addEventListener('mousedown', close);
      return (): void => document.removeEventListener('mousedown', close);
    }
  }, [_closeOnDocumentClick]);

  function handleTrigger(event: React.MouseEvent): void {
    event.preventDefault();
    const newStatus = isClosed ? 'open' : 'closed';
    setStatus(newStatus);
    onTrigger?.(status);
  }

  function renderOption(option: Option): JSX.Element | string {
    const onClickFn = (event: React.MouseEvent): void => {
      event.preventDefault();
      const _closeMenuOnItemClick =
        option.closeMenuOnItemClick === undefined ? true : option.closeMenuOnItemClick;

      setCurrentOption(option);
      option.action && option.action(event);
      if (_closeMenuOnItemClick) setStatus('closed');
    };

    if (option.render) return <Box as="span">{option.render({ label: option.label })}</Box>;

    function renderIcon(): JSX.Element | null {
      if (!option.icon) return null;
      return <Icon mr="s" {...option.icon} />;
    }

    if (option.action && typeof option.action === 'function') {
      return (
        <Box as="a" href="#0" onClick={onClickFn} {...option}>
          {renderIcon()}
          {option.label}
        </Box>
      );
    }

    if (option.to) {
      return (
        <Link to={option.to}>
          {renderIcon()}
          {option.label}
        </Link>
      );
    }

    return option.label;
  }

  function getTriggerProps(): { onClick: Function } {
    const onClickFn = (event: React.MouseEvent) => handleTrigger(event);

    return {
      onClick: onClickFn,
    };
  }

  function isActive(option: Option): boolean {
    if (option.active) return true;
    if (!option.showActive) return false;
    return currentOption.label === option.label;
  }

  function getOptionId(option: Option): string {
    return option.id || option.label;
  }

  return (
    <StyledMenu ref={containerRef} maxWidth={width || defaults.width} {...rest}>
      {renderTrigger({ status, getTriggerProps, currentOption, setCurrentOption })}
      <MenuList side={side || defaults.side} status={status} width={width || defaults.width}>
        {options
          .filter(o => o.label)
          .map((option: Option, idx: number) => (
            <MenuListItem key={idx} active={isActive(option)} id={getOptionId(option)}>
              {renderOption(option)}
            </MenuListItem>
          ))}
      </MenuList>
    </StyledMenu>
  );
};

export default Menu;
