import React, { useEffect, useState } from 'react';

import { Icon } from 'components';

import { Tab as StyledTab, Tabs as StyledTabs, TabsContainer as StyledTabsContainer } from 'styled';

export interface Props {
  children?: React.ReactNode;
  initialTab?: number;
  secondary?: boolean;
}

export interface TabProps {
  title: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selected?: boolean;
  icon?: string;
  id?: string;
  count?: number;
  secondary?: boolean;
}

const Tabs: React.FC<Props> = ({ children, initialTab, secondary, ...rest }: Props) => {
  const [activeTab, setActiveTab] = useState<number>(initialTab || 0);

  useEffect(() => {
    if (initialTab !== undefined) setActiveTab(initialTab);
  }, [initialTab, setActiveTab]);

  const handleClick =
    (idx: number) =>
    (event: React.MouseEvent): void => {
      event.preventDefault();
      setActiveTab(idx);
    };

  if (!children) return null;

  const _children = React.Children.toArray(children).filter(child => !!child);

  function renderTabs(): React.ReactNode {
    return _children.map((child, idx: number) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child as React.ReactElement<{ props: TabProps }>, {
          key: idx,
          title: child.props.title,
          onClick: handleClick(idx),
          selected: activeTab === idx,
          secondary: secondary,
          children: null,
          ...child.props,
        });
      }
      return null;
    });
  }

  function renderContent(): React.ReactNode | null {
    const isActive = (idx: number): boolean => activeTab === idx;
    return _children.map((child, idx: number) => {
      if (!isActive(idx)) return null;
      if (React.isValidElement(child)) return child.props.children;
      return null;
    });
  }

  return (
    <StyledTabsContainer>
      <StyledTabs {...rest}>{renderTabs()}</StyledTabs>
      {renderContent() !== null ? renderContent() : null}
    </StyledTabsContainer>
  );
};

const Tab: React.FC<TabProps> = ({
  onClick,
  title,
  selected,
  icon,
  secondary,
  id,
  ...rest
}: TabProps) => {
  return (
    <StyledTab onClick={onClick} selected={selected} secondary={secondary} id={id} {...rest}>
      {icon && <Icon name={icon} size="14px" mr="xs" position="relative" top="-1px" />}
      {title}
    </StyledTab>
  );
};

export { Tabs as default, Tab };
